import { extendObservable, action, computed } from 'mobx'
import decodeJwt from 'jwt-decode'
import humps from 'lodash-humps'

import { handleError } from '@/api'
import SentryReplay from '@/SentryReplay'
import localeRequest from '@/utils/locale'
import request from '@/utils/request'

import User from './models/user'
import Session from './models/session'

import { DEFAULT_LOCALE, PASSWORD_TRIM_REGEX, THEMES } from '@/constants'

const initialState = {
  session: null,
  user: null,
  error: null,
  isLoading: false,
  isLoadingCourses: true,
  isLoadingLocaleMessages: false,
  isLoggingOut: false,
  isScorm: false,
  isSwitchingCourse: false,
  isUnderMaintenance: false,
  isUpdatingLocale: false,
  localeMessages: {},
  loginWithDelayTimer: null,
  courses: null,
  cookieHeader: null,
}

function _resetAppStore(appStore, logoutUrl, useHistoryApi = false) {
  if (logoutUrl) {
    // Redirect to logout URL
    appStore.reset(logoutUrl, useHistoryApi)
  } else {
    appStore.reset()
  }
}

function _removeBodyAttributes() {
  // Remove client theme from body
  if (document.body.getAttribute('data-theme')) {
    document.body.removeAttribute('data-theme')
  }
  // Remove contrast settings from body
  if (document.body.hasAttribute('data-contrast')) {
    document.body.removeAttribute('data-contrast')
  }
}

function _getJwtExpirationDate(tokenExp) {
  if (!tokenExp) return null
  const date = new Date(0)
  date.setUTCSeconds(tokenExp)
  return date
}

function _getJwtOffsetTime(tokenIat) {
  if (!tokenIat) return 0
  const now = Date.now()
  const date = new Date(0)
  date.setUTCSeconds(tokenIat)
  return date.getTime() - now
}

export default class SessionStore {
  constructor(appStore) {
    extendObservable(this, { ...initialState })
    this.appStore = appStore
  }

  loginWithDelayIntervalId = null

  @computed get isLoggedIn() {
    return this.session && this.session.isLoggedIn
  }

  @action reset() {
    extendObservable(this, { ...initialState, isScorm: this.isScorm })
  }

  @action login({ username, password, orgAlias, ldapKey }) {
    const path = ldapKey
      ? `/auth/ldap/${orgAlias}/${ldapKey}/login`
      : '/users/login'

    // Strip non-ASCII leading and trailing characters
    // https://stackoverflow.com/questions/24229262/match-non-printable-non-ascii-characters-and-remove-from-text/24231346#24231346
    // http://www.asciitable.com/
    // Match char code 32 to 126
    const passwordAscii = password.replace(PASSWORD_TRIM_REGEX, '')

    this.isLoading = true

    return request
      .post(path, {
        username,
        password: passwordAscii,
      })
      .then((response) => this.handleLoginSuccess(response))
      .catch((response) => this.handleLoginError(response))
  }
  @action loginWithToken(token) {
    this.isLoading = true
    return request
      .post(
        '/auth/extend',
        {},
        {
          headers: { Authorization: `JWT ${token}` },
        },
      )
      .then((response) => this.handleLoginSuccess(response))
      .catch((error) => this.handleLoginError(error))
  }

  @action loginWithDelay(
    { logoutUrl, token, user },
    callback,
    delayInSeconds = 3,
  ) {
    const session = this.createSession({
      logoutUrl,
      token,
      user,
    })
    this.appStore.saveToLocalStorage(session)
    if (this.loginWithDelayIntervalId) {
      this.resetLoginWithDelay()
    }
    this.loginWithDelayTimer = delayInSeconds
    this.loginWithDelayIntervalId = setInterval(() => {
      if (this.loginWithDelayTimer === 0) {
        this.resetLoginWithDelay()
        this.appStore.init(session)
        if (callback) {
          callback()
        }
      } else if (this.loginWithDelayTimer) {
        this.loginWithDelayTimer--
      } else {
        // NOTE: unhandled scenario - disable interval and reset
        this.resetLoginWithDelay()
      }
    }, 1000)
  }
  @action resetLoginWithDelay() {
    clearInterval(this.loginWithDelayIntervalId)
    this.loginWithDelayTimer = null
    this.loginWithDelayIntervalId = null
  }

  @action handleLoginSuccess(response) {
    this.isLoading = false
    this.error = null
    const { data, headers } = response
    const { auth_token: token, logout_url: logoutUrl, user } = data
    const session = this.createSession({
      token,
      logoutUrl,
      user,
    })
    this.cookieHeader = headers['x-fulcrum-cookie']
    this.appStore.init(session)
    return response
  }
  @action handleLoginError(error) {
    const { data: errorData, response } = error || {}
    const { message: errorDataMessage } = errorData || {}

    const { data: responseData, status: responseStatus } = response || {}
    const { errors: responseDataErrors, message: responseDataMessage } =
      responseData || {}

    const DEFAULT_LOGIN_ERROR_MESSAGE =
      'There was an error processing your request. Please check your network connectivity and contact an administrator if the issue persists'

    this.isLoading = false
    this.error = errorDataMessage || DEFAULT_LOGIN_ERROR_MESSAGE

    if (responseStatus === 423) {
      return Promise.reject(new Error(responseStatus))
    }

    if (responseDataErrors || responseDataMessage) {
      return Promise.reject(
        new Error(responseDataErrors || responseDataMessage),
      )
    }

    return Promise.reject(new Error(DEFAULT_LOGIN_ERROR_MESSAGE))
  }

  @action resetPassword(email) {
    return request
      .post('/users/password_reset', {
        email,
      })
      .catch(handleError)
  }
  @action confirmResetPassword(password, token) {
    return request
      .post('/users/password_reset_confirmation', {
        password,
        token,
      })
      .then((response) => {
        const { data, headers } = response
        const { auth_token: token, logout_url: logoutUrl, user } = data
        this.cookieHeader = headers['x-fulcrum-cookie']
        return {
          logoutUrl,
          token,
          user,
        }
      })
      .catch(handleError)
  }

  @action loadCourses() {
    this.isLoadingCourses = true
    return request
      .get(`users/courses?d=${Date.now()}`)
      .then((response) => this.handleLoadCourses(response))
      .catch((error) => this.handleLoadCoursesError(error))
  }
  @action handleLoadCourses(response) {
    this.isLoadingCourses = false
    this.courses = humps(response.data.courses)
  }
  @action handleLoadCoursesError(error) {
    SentryReplay.captureException(error)
    this.courses = null
    this.isLoadingCourses = false
  }

  @action validatePasswordToken(token) {
    return request
      .post('/users/validate_password_token', { token })
      .then((response) => {
        const { data } = response
        const { email, tokenExpired } = humps(data)
        return { email, tokenExpired }
      })
      .catch((error) => {
        const { response } = error
        const { status } = response || {}
        if (status === 404) {
          return Promise.reject(
            new Error('The password token is invalid or does not exist'),
          )
        }
        return Promise.reject(new Error('Could not validate password token'))
      })
  }

  @action switchCourse(newCourseId) {
    const { courseId } = this.session
    this.isSwitchingCourse = true
    return request
      .get(`/users/${courseId}/switch/${newCourseId}?d=${Date.now()}`)
      .then((response) => this.handleSwitchCourse(response))
      .catch((error) => this.handleSwitchCourseError(error))
  }
  @action handleSwitchCourse(response) {
    const { stores } = this.appStore
    const { logoutUrl } = this.session
    const { contentStore, messagesStore } = stores
    const { data, headers } = response
    const { auth_token: authToken } = data
    const session = this.switchSession({
      token: authToken,
      logoutUrl: logoutUrl,
    })
    // reset contentStore to clear settings from previous course
    contentStore.reset()
    // reset messagesStore to clear threads from previous course
    messagesStore.reset()
    this.cookieHeader = headers['x-fulcrum-cookie']
    this.appStore.init(session)
    this.isSwitchingCourse = false
    return Promise.resolve(session)
  }
  @action handleSwitchCourseError(error) {
    SentryReplay.captureException(error)
    this.isSwitchingCourse = false
    return Promise.reject(error)
  }

  @action updatePassword(newPassword, newPasswordConfirmation) {
    return request
      .post('/users/update_password', {
        current_password: this.temporaryUser.password,
        password: newPassword,
        password_confirmation: newPasswordConfirmation,
        username: this.temporaryUser.username,
      })
      .then((response) => {
        const { data, headers } = response
        const { auth_token: token, logout_url: logoutUrl, user } = data
        this.cookieHeader = headers['x-fulcrum-cookie']
        return {
          logoutUrl,
          token,
          user,
        }
      })
      .catch(handleError)
  }

  get isCurrentRoleAdmin() {
    return (
      this.session && this.session.user && this.session.user.isCurrentRoleAdmin
    )
  }

  @action logout(logoutUrl, useHistoryApi = false) {
    const { logoutUrl: sessionLogoutUrl } = this.session || {}
    if (this.isLoggingOut) return
    if (!this.session || !request.authorizationHeader) {
      return this.handleLogout({}, logoutUrl, useHistoryApi)
    }
    this.isLoggingOut = true
    if (sessionLogoutUrl) {
      useHistoryApi = false
    }
    // if current role is Learner
    // terminate session before logging out
    if (!this.isCurrentRoleAdmin) {
      return this.appStore.stores.xapiStore
        .terminateSession()
        .then(() => request.post('/users/logout'))
        .then((response) =>
          this.handleLogout(
            response,
            sessionLogoutUrl || logoutUrl,
            useHistoryApi,
          ),
        )
        .catch((error) =>
          this.handleLogoutError(
            error,
            sessionLogoutUrl || logoutUrl,
            useHistoryApi,
          ),
        )
    }
    return request
      .post('/users/logout')
      .then((response) =>
        this.handleLogout(
          response,
          sessionLogoutUrl || logoutUrl,
          useHistoryApi,
        ),
      )
      .catch((error) =>
        this.handleLogoutError(
          error,
          sessionLogoutUrl || logoutUrl,
          useHistoryApi,
        ),
      )
  }
  @action handleLogout = (response, logoutUrl, useHistoryApi = false) => {
    _resetAppStore(this.appStore, logoutUrl, useHistoryApi)
    _removeBodyAttributes()
    SentryReplay.resetUser()
    this.isLoggingOut = false
    return Promise.resolve(logoutUrl)
  }
  @action handleLogoutError = (error, logoutUrl, useHistoryApi = false) => {
    _resetAppStore(this.appStore, logoutUrl, useHistoryApi)
    _removeBodyAttributes()
    SentryReplay.resetUser()
    SentryReplay.captureException(error)
    this.isLoggingOut = false
    return Promise.resolve(logoutUrl)
  }

  @action createTemporaryUser(username, password) {
    this.temporaryUser = {
      username,
      password,
    }
  }
  @action cleanTemporaryUser() {
    this.temporaryUser = {}
  }

  @action createSession(sessionData) {
    if (!sessionData) return

    const {
      logoutUrl,
      showCoursePicker = true,
      token,
      tokenOffsetTime = null,
      user,
    } = sessionData
    this._createSession(
      token,
      user,
      logoutUrl,
      showCoursePicker,
      tokenOffsetTime,
    )
    if (!this.session || !this.user) {
      return
    }
    const { id: userId, username } = this.user || {}
    SentryReplay.setUser(userId, username)
    this._setTheme()
    return this.session
  }
  @action _createSession(
    token,
    user,
    logoutUrl,
    showCoursePicker,
    tokenOffsetTime = null,
  ) {
    const {
      courseId,
      courses,
      exp: tokenExp,
      iat: tokenIat,
      internalCourseId,
      isStudent,
      isInstructor,
      isAdmin,
      isObserver,
      locale: userLocale,
      orgId,
      preview,
      roleId,
      snapshot,
    } = humps(decodeJwt(token))
    const tokenExpirationDate = _getJwtExpirationDate(tokenExp)
    const offsetTime =
      (tokenOffsetTime &&
        !isNaN(tokenOffsetTime) &&
        parseInt(tokenOffsetTime)) ||
      _getJwtOffsetTime(tokenIat)
    const previewUrl = preview && preview.previewRedirectUrl
    const currentRole =
      user.currentRole ||
      this.appStore.validateSession(
        isAdmin,
        isInstructor,
        isObserver,
        isStudent,
      ) ||
      null
    if (!currentRole) return
    const userObj = new User({
      ...user,
      currentRole,
      isStudent,
      isInstructor,
      isAdmin,
      isObserver,
      roleId,
    })
    const session = new Session({
      courseId,
      courses,
      internalCourseId,
      locale: userLocale || DEFAULT_LOCALE,
      logoutUrl,
      offsetTime,
      orgId,
      preview,
      previewUrl,
      showCoursePicker,
      snapshot,
      token,
      tokenExpirationDate,
      user: userObj,
    })
    if (session.isTokenExpired()) {
      this.appStore.clearLocalStorage()
      return
    }
    this.session = session
    this.user = userObj
  }
  @action switchSession({ token, logoutUrl }) {
    if (!token) return

    const {
      courseId,
      courses,
      exp: tokenExp,
      iat: tokenIat,
      internalCourseId,
      locale: userLocale,
      orgId,
      preview,
      snapshot,
    } = humps(decodeJwt(token))

    const tokenExpirationDate = _getJwtExpirationDate(tokenExp)
    const offsetTime =
      (this.session &&
        this.session.offsetTime &&
        !isNaN(this.session.offsetTime) &&
        parseInt(this.session.offsetTime)) ||
      _getJwtOffsetTime(tokenIat)
    const previewUrl = preview && preview.previewRedirectUrl

    this.session = new Session({
      courseId,
      courses,
      internalCourseId,
      locale: userLocale || DEFAULT_LOCALE,
      logoutUrl,
      offsetTime,
      orgId,
      preview,
      previewUrl,
      showCoursePicker: false,
      snapshot,
      token,
      tokenExpirationDate,
      user: this.user,
    })

    return this.session
  }

  @action refreshJwt() {
    return request
      .post('/auth/refresh')
      .then(this.handleRefreshJwt)
      .catch(this.handleRefreshJwtError)
  }
  @action handleRefreshJwt = (response) => {
    const { showCoursePicker } = this.session
    const { data } = response
    const { auth_token: token, logout_url: logoutUrl, user } = data
    if (this.user && this.user.currentRole) {
      user.currentRole = this.user.currentRole
    }
    this._createSession(token, user, logoutUrl, showCoursePicker)
    if (!this.session || !this.user) {
      return
    }
    this._setTheme()
    this.appStore.init(this.session)
    return Promise.resolve()
  }
  @action handleRefreshJwtError = (error) => {
    SentryReplay.captureException(error)
    return Promise.reject(error)
  }

  @action loadLocaleMessages() {
    const { locale } = this.session || {}
    this.isLoadingLocaleMessages = true
    return SessionStore.getLocaleMessages(locale)
      .then(this.handleLoadLocaleMessages)
      .catch(this.handleLoadLocaleMessagesError)
  }
  @action handleLoadLocaleMessages = (localeMessagesData) => {
    this.localeMessages = localeMessagesData
    this.isLoadingLocaleMessages = false
    return Promise.resolve(localeMessagesData)
  }
  @action handleLoadLocaleMessagesError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingLocaleMessages = false
    return Promise.reject(error)
  }

  @action updateLocale(locale) {
    const { locale: currentLocale } = this.session
    if (currentLocale === locale) return Promise.resolve()
    this.isUpdatingLocale = true
    return Promise.all([
      request.post('/users/update-locale', { locale }),
      SessionStore.getLocaleMessages(locale),
    ])
      .then(this.handleUpdateLocale)
      .catch(this.handleUpdateLocaleError)
  }
  @action handleUpdateLocale = ([updateLocaleResponse, localeMessagesData]) => {
    const { data: updateLocaleData } = updateLocaleResponse
    const { authToken } = humps(updateLocaleData)
    const { logoutUrl } = this.session
    const session = this.switchSession({
      token: authToken,
      logoutUrl: logoutUrl,
    })
    this.appStore.init(session)
    this.localeMessages = localeMessagesData
    this.isUpdatingLocale = false
    return Promise.resolve()
  }
  @action handleUpdateLocaleError = (error) => {
    SentryReplay.captureException(error)
    this.isUpdatingLocale = false
    return Promise.reject(error)
  }

  @action setMaintenanceStatus = (isUnderMaintenance) => {
    this.isUnderMaintenance = isUnderMaintenance
  }
  @action _setTheme() {
    // NOTE - apply theme to body if user is student, else remove theme
    const { orgId } = this.session
    const { isStudent } = this.user
    if (isStudent && THEMES[orgId]) {
      document.body.setAttribute('data-theme', THEMES[orgId])
    } else if (document.body.getAttribute('data-theme')) {
      document.body.removeAttribute('data-theme')
    }
  }

  @action switchPlatform() {
    if (!this.user || !this.session.user) return false
    const nextRole = this.user.currentRole === 'admin' ? 'student' : 'admin'
    this.user.currentRole = nextRole
    this.session.user.currentRole = nextRole
    this.appStore.saveToLocalStorage(this.session)
    return true
  }

  static getLocaleMessages(locale = DEFAULT_LOCALE) {
    return localeRequest
      .get(`/${locale}`, {
        params: {
          d: Date.now(),
        },
      })
      .then((localeMessagesResponse) => {
        const { data: localeMessagesData } = localeMessagesResponse
        return localeMessagesData
      })
      .catch((error) => {
        const { response } = error || {}
        const { status } = response || {}
        if (status === 404) return Promise.resolve({})
        return Promise.reject(error)
      })
  }
}
