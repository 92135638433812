import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { defineMessages, injectIntl } from 'react-intl'

import './CourseProgress.scss'

const CourseProgressMessages = defineMessages({
  sectionsText: {
    defaultMessage:
      '({sectionsCompleted}/{sectionsTotal} {sectionsTotal, plural, one {Section} other {Sections}})',
    description: 'Number of sections completed out of total sections',
    id: 'CourseProgress.sectionsText',
  },
  title: {
    defaultMessage: 'Course Progress',
    description: 'Course Progress title',
    id: 'CourseProgress.title',
  },
})

@injectIntl
class CourseProgress extends PureComponent {
  render() {
    const { intl, progress, sectionsCompleted, sectionsTotal } = this.props
    const { formatMessage } = intl
    const meterStyle = { width: `${progress}%` }
    if (progress === 100) {
      meterStyle['borderRadius'] = '5px'
    }
    return (
      <div className="CourseProgress">
        <div className="CourseProgress-text">
          <span className="CourseProgress-title">
            {formatMessage(CourseProgressMessages.title)}
          </span>
          <span
            className="CourseProgress-sections"
            qa-id="learner-course-progress-text"
          >
            {formatMessage(CourseProgressMessages.sectionsText, {
              sectionsCompleted,
              sectionsTotal,
            })}
          </span>
        </div>
        <div className="CourseProgress-meterWrapper">
          <div className="CourseProgress-meter" style={meterStyle} />
          <div
            className="CourseProgress-progressTooltip"
            style={{ left: `${progress}%` }}
          >
            <span>{progress}%</span>
            <i aria-hidden="true" className="fa fa-caret-up" />
          </div>
        </div>
      </div>
    )
  }
}

CourseProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  sectionsCompleted: PropTypes.number.isRequired,
  sectionsTotal: PropTypes.number.isRequired,
}

export default CourseProgress
