import React, { Component } from 'react'
import { Link, Button } from '@/components'
import { Input } from '@/components/form'
import form from '../form'
import './LoginForm.scss'

const isEmpty = (value) => value == null || value === ''

const errors = {
  overall: {
    username:
      'The email and password fields are empty. Please enter your email and password. An email should follow the format username@domain.com.',
    password: true,
  },
  username: {
    username:
      'The email field is empty. Please enter your email or username. An email should follow the format username@domain.com.',
  },
  password: {
    password: 'The password field is empty. Please enter your password.',
  },
}

const formOptions = {
  reset: () => ({ username: '', password: '' }),
  values: { username: '', password: '' },
  validate: (values) => {
    if (isEmpty(values.username) && isEmpty(values.password)) {
      return { ...errors.overall }
    }
    if (isEmpty(values.username)) {
      return { ...errors.username }
    }
    if (isEmpty(values.password)) {
      return { ...errors.password }
    }
    return null
  },
}

@form(formOptions)
export default class LoginForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    if (!form.isValid()) return
    const { username, password } = form.values()
    this.props.onSubmit(username, password, form.reset)
  }
  render() {
    const { form } = this.props
    return (
      <form className="LoginForm" onSubmit={this.handleSubmit}>
        <div className="field">
          <div className="control">
            <Input
              {...form.inputProps('username')}
              id="LoginForm__username"
              label="Email"
              qa-id="login-email-input"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <Input
              {...form.inputProps('password')}
              id="LoginForm__password"
              label="Password"
              type="password"
              qa-id="login-password-input"
            />
          </div>
        </div>
        <div className="help-group" qa-id="login-error-text">
          {form.renderErrors()}
        </div>
        <div className="field field-action">
          <div className="control">
            <Button
              className="LoginForm__submit"
              disabled={this.props.isLoading}
              isFullWidth
              isLoading={this.props.isLoading}
              isSuccess
              submit
              qa-id="login-submit-button"
            >
              Login
            </Button>
          </div>
        </div>
        <div className="field field-forgot">
          <div className="control">
            <Link to="/forgot-password" qa-id="login-forgot-password-button">
              Forgot password?
            </Link>
          </div>
        </div>
      </form>
    )
  }
}
