import React from 'react'

import {
  DISABLE_DASHBOARD,
  ENCOURAGE_MASTERY,
  REQUIRE_MASTERY,
} from '@/constants'
import { CheckboxCustom, Heading } from '@/components'

import { DASHBOARD_NAME } from './InstanceSettingsPageConstants'
import InstanceSettingsPageSavedLabel from './InstanceSettingsPageSavedLabel'

const LEARNING_MASTERY_REQUIREMENTS_CHOICES = [
  {
    id: DISABLE_DASHBOARD,
    content:
      'Learners may complete sections at a low level if they are not highly engaged. They will not receive feedback on their engagement ranking. Admins will see these scores on the Learner Data tab.',
    disabled: true,
    text: 'Hide Mastery Information from Learners',
  },
  {
    id: ENCOURAGE_MASTERY,
    content:
      'Learners will know where they are falling behind and will be encouraged to reattempt sections where they underperformed. They will be guided with the Pathway to Mastery component.',
    recommended: true,
    text: 'Encourage Mastery',
  },
  {
    id: REQUIRE_MASTERY,
    content:
      'Learners will know where they are falling behind and will be forced to reattempt sections until they have attained a Proficient ranking. They will be guided with the Pathway to Mastery component.',
    text: 'Require Mastery',
  },
]

const InstanceSettingsPageDashboard = ({
  errors,
  isChecked,
  isDisabledDashboard,
  isLoading,
  onChange,
  onChangeDisabled,
  value,
}) => {
  return (
    <div className="InstanceSettingsPage__setting">
      <Heading is4 className="InstanceSettingsPage__setting-title">
        Pathway to Mastery
      </Heading>
      <span className="InstanceSettingsPage__setting-subtitle">
        Our adaptive system changes difficulty for Learners as they work through
        the material. When Learners perform poorly in a section, it is ranked
        &quot;Unengaged.&quot; These settings allow you to customize how the
        system supports achieving mastery when Learners fall behind.
      </span>
      {LEARNING_MASTERY_REQUIREMENTS_CHOICES.map((choice, i) => {
        const { id: choiceId } = choice
        if (choiceId === DISABLE_DASHBOARD && !isDisabledDashboard) return null
        return (
          <div key={`InstanceSettingsPage-learningMasteryRequirement-${i}`}>
            <CheckboxCustom
              className="InstanceSettingsPage-value--is-bold"
              disabled={choice.disabled}
              isRadio
              name={DASHBOARD_NAME}
              onChange={
                isDisabledDashboard && choiceId !== DISABLE_DASHBOARD
                  ? onChangeDisabled
                  : onChange
              }
              value={choice.id}
              checked={isChecked('learningMasteryRequirements', choice.id)}
            >
              <span className="InstanceSettingsPage__value-label">
                <span className="InstanceSettingsPage__value-text">
                  {choice.text}
                </span>
                {choice.recommended && (
                  <span className="InstanceSettingsPage__setting-recommended">
                    &nbsp;(recommended)
                  </span>
                )}
                {isLoading === false && value === choice.id && !errors[name] ? (
                  <InstanceSettingsPageSavedLabel />
                ) : null}
              </span>
            </CheckboxCustom>
            <span className="InstanceSettingsPage__setting-content">
              {choice.content}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default InstanceSettingsPageDashboard
