import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { Heading, Modal } from '@/components'
import './UserAssistanceModal.scss'

const UserAssistanceModalMessages = defineMessages({
  confirm: {
    defaultMessage: 'OK',
    description: `Button to close the user assistance modal.`,
    id: 'UserAssistanceModal.confirm',
  },
  copy: {
    defaultMessage: `Learning is not attained by chance.  Keep working at it and you will persevere.`,
    description: 'Description displayed within the user assistance modal.',
    id: 'UserAssistanceModal.copy',
  },
  stuckCta: {
    defaultMessage: `If you're stuck on a question`,
    description:
      'Call to actionn displayed within the subCopy of the user assistance modal.',
    id: 'UserAssistanceModal.stuckCta',
  },
  subCopy: {
    defaultMessage: `{stuckCta}, go back to the Read and Watch tabs. Take note of Hints and Feedback, too.`,
    description: 'Subcopy displayed within the user assistance modal.',
    id: 'UserAssistanceModalMessages.subCopy',
  },
})

@injectIntl
@inject('uiStore', 'contentStore')
export default class UserAssistanceModal extends Component {
  onClose = () => {
    const { onClose, uiStore } = this.props
    if (onClose) {
      onClose()
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  render() {
    const { intl, isOpen } = this.props
    const { formatMessage } = intl
    return (
      <Modal
        className="UserAssistanceModal"
        contentLabel="User Assistance Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>Need Help?</Heading>
        </Modal.Header>
        <Modal.Body>
          <p>{formatMessage(UserAssistanceModalMessages.copy)}</p>
          <p>
            <FormattedMessage
              {...UserAssistanceModalMessages.subCopy}
              values={{
                stuckCta: (
                  <strong>
                    {formatMessage(UserAssistanceModalMessages.stuckCta)}
                  </strong>
                ),
              }}
            />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button onClick={this.onClose}>
            {formatMessage(UserAssistanceModalMessages.confirm)}
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
