import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import _ from 'lodash'
import cx from 'classnames'

import { ACTIVITY_TYPES } from '@/constants'

import Activity from '../Activity'
import './ActivitySpot.scss'

const ActivitySpotMessages = defineMessages({
  label: {
    defaultMessage:
      'Click/Tap on the area of the image that corresponds with the answer to the question.',
    description: 'Instructions label for hot spot activity.',
    id: 'ActivitySpot.label',
  },
})

// image: { src, width, height }
// regions: [{ id: x, path: ''}...]
// number of pins is equal to regions length
@injectIntl
export default class ActivitySpot extends Component {
  static defaultProps = {
    // label: 'Click/Tap on the area of the image that corresponds with the answer to the question.',
    labelIcon: 'map-pin',
  }

  regions = {}
  svg = null

  get label() {
    const { intl, label } = this.props
    const { formatMessage } = intl
    if (label) {
      return label
    }
    return formatMessage(ActivitySpotMessages.label)
  }

  addPin = (e) => {
    const { hasNextActivity } = this.props
    if (hasNextActivity) return
    const pt = this.svg.createSVGPoint()
    pt.x = e.clientX
    pt.y = e.clientY
    const cursorpt = pt.matrixTransform(this.svg.getScreenCTM().inverse())
    const region = _.find(this.regions, (region) => region === e.target)
    const newPin = {
      id: _.uniqueId('pin'),
      x: cursorpt.x,
      y: cursorpt.y,
      regionId: (region && region.id) || _.uniqueId('defaultRegion'),
    }

    const currPins = this.props.value || []
    if (currPins.length >= Number(this.props.numberOfPins)) return
    const pins = currPins.concat(newPin)
    this.props.onChange(pins)
  }

  removePin = (e) => {
    const { hasNextActivity, isSavingActivity } = this.props
    if (hasNextActivity || isSavingActivity) return

    e.preventDefault()
    e.stopPropagation()

    const pinId = e.target.id
    const pins = this.props.value
    const pin = _.find(pins, (pin) => pin.id === pinId)
    pin && pins.splice(pins.indexOf(pin), 1)

    this.props.onChange(pins)
    return false
  }

  onChange = (pins) => {
    pins = pins.map((pin) => {
      return pin.regionId || ' '
    })

    this.props.onChange(pins)
  }

  renderBody = ({ renderTitle }) => {
    const {
      imageHeight,
      imageSrc,
      imageTransform,
      imageWidth,
      imageX,
      imageY,
      regions,
      value,
    } = this.props

    return (
      <div className="ActivitySpot" onClick={this.addPin}>
        {renderTitle()}
        <div className="ActivitySpot-body">
          <div
            className="ActivitySpot-image"
            style={{ paddingBottom: (imageHeight * 100) / imageWidth + '%' }}
          />
          <svg
            height="100%"
            width="100%"
            className="ActivitySpot-map"
            viewBox={`${imageX} ${imageY} ${imageWidth} ${imageHeight}`}
            ref={(el) => {
              this.svg = el
            }}
          >
            <image
              x={imageX}
              y={imageY}
              width={imageWidth}
              height={imageHeight}
              preserveAspectRatio="none"
              xlinkHref={imageSrc}
              transform={imageTransform}
            />
            {regions &&
              regions.map((region) => (
                <path
                  key={region.id}
                  id={region.id}
                  d={region.path}
                  fillOpacity="0"
                  transform={region.transform}
                  stroke={region.stroke}
                  strokeWidth={region.strokeWidth}
                  strokeLinecap={region.strokeLinecap}
                  strokeLinejoin={region.strokeLinejoin}
                  strokeOpacity="0"
                  ref={(el) => {
                    this.regions[region.id] = el
                  }}
                />
              ))}
            {value &&
              value.map((pin) => (
                <g
                  transform={`translate(${pin.x - 10}, ${pin.y - 10})`}
                  key={pin.id}
                >
                  <circle
                    className="ActivitySpot-mapCircle"
                    strokeWidth="2"
                    fillOpacity="0.7"
                    fill="#FFFFFF"
                    cx="10"
                    cy="15"
                    r="10"
                  />
                  <path
                    className="ActivitySpot-mapPath"
                    d="M10.8571429,10.75 C11.2745557,10.75 11.6793135,10.7025674 12.0714286,10.6077009 L12.0714286,16.8214286 C12.0714286,16.9858639 12.0113473,17.1281616 11.891183,17.2483259 C11.7710187,17.3684902 11.6287211,17.4285714 11.4642857,17.4285714 L10.25,17.4285714 C10.0855647,17.4285714 9.94326697,17.3684902 9.82310268,17.2483259 C9.70293839,17.1281616 9.64285714,16.9858639 9.64285714,16.8214286 L9.64285714,10.6077009 C10.0349722,10.7025674 10.4397301,10.75 10.8571429,10.75 Z M10.8571429,0.428571429 C12.1979234,0.428571429 13.3426292,0.902897042 14.2912946,1.8515625 C15.2399601,2.80022796 15.7142857,3.94493377 15.7142857,5.28571429 C15.7142857,6.6264948 15.2399601,7.77120061 14.2912946,8.71986607 C13.3426292,9.66853153 12.1979234,10.1428571 10.8571429,10.1428571 C9.51636234,10.1428571 8.37165653,9.66853153 7.42299107,8.71986607 C6.47432561,7.77120061 6,6.6264948 6,5.28571429 C6,3.94493377 6.47432561,2.80022796 7.42299107,1.8515625 C8.37165653,0.902897042 9.51636234,0.428571429 10.8571429,0.428571429 Z M10.8571429,2.55357143 C10.945685,2.55357143 11.0184149,2.52511189 11.0753348,2.46819196 C11.1322547,2.41127204 11.1607143,2.33854211 11.1607143,2.25 C11.1607143,2.16145789 11.1322547,2.08872796 11.0753348,2.03180804 C11.0184149,1.97488811 10.945685,1.94642857 10.8571429,1.94642857 C9.93377515,1.94642857 9.14639463,2.27213216 8.49497768,2.92354911 C7.84356073,3.57496605 7.51785714,4.36234657 7.51785714,5.28571429 C7.51785714,5.3742564 7.54631668,5.44698632 7.60323661,5.50390625 C7.66015653,5.56082618 7.73288646,5.58928571 7.82142857,5.58928571 C7.90997068,5.58928571 7.98270061,5.56082618 8.03962054,5.50390625 C8.09654046,5.44698632 8.125,5.3742564 8.125,5.28571429 C8.125,4.53310636 8.39220343,3.88960461 8.9266183,3.35518973 C9.46103318,2.82077486 10.1045349,2.55357143 10.8571429,2.55357143 Z"
                  />
                  <rect
                    x="0"
                    y="0"
                    width="20"
                    height="28"
                    fillOpacity="0"
                    onClick={this.removePin}
                    id={pin.id}
                  />
                </g>
              ))}
          </svg>
        </div>
      </div>
    )
  }

  render() {
    const { label, ...restProps } = this.props
    return (
      <Activity
        {...restProps}
        className={cx('is-spot', this.props.className)}
        label={this.label}
        render={this.renderBody}
        type={ACTIVITY_TYPES.hotspot}
      />
    )
  }
}
