import { extendObservable, action } from 'mobx'

import SentryReplay from '@/SentryReplay'
import request from '@/utils/request'
import UserProfile from './models/userProfile'

const initialState = {
  session: null,
  userProfile: null,
  isLoadingUserProfile: false,
  error: null,
}

export default class ProfileStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...initialState })
  }

  @action init(session) {
    this.session = session
  }

  @action loadUserProfile(userId, contentfulId) {
    this.isLoadingUserProfile = true
    this.userProfile = null
    this.error = null

    return request
      .get(
        `/users/${
          contentfulId || this.session.courseId
        }/profile/${userId}?d=${Date.now()}`,
      )
      .then((response) => this.handleLoadUserProfile(response))
      .catch((error) => this.handleLoadUserProfileError(error))
  }

  @action handleLoadUserProfile(response) {
    this.userProfile = new UserProfile({
      ...response.data.profile,
      currentUser: this.session.user,
    })
    this.isLoadingUserProfile = false
    return this.userProfile
  }

  @action handleLoadUserProfileError(error) {
    SentryReplay.captureException(error)
    this.userProfile = null
    this.error = error.response.data.message
    this.isLoadingUserProfile = false
  }
}
