import React, { Component, Fragment } from 'react'
import ReactDOMServer from 'react-dom/server'
import { defineMessages, injectIntl } from 'react-intl'
import _ from 'lodash'
import cx from 'classnames'

import { Button, RichTextWrapper, Video } from '@/components'
import { Input } from '@/components/form'
import { ACTIVITY_TYPES, VIDEO_TYPES } from '@/constants'

import Activity from '../Activity'
import CorrectIncorrectIcon from '../CorrectIncorrectIcon'
import './ActivityInput.scss'

const ActivityInputMessages = defineMessages({
  answerLabel: {
    defaultMessage: 'Your answer:',
    description: 'Label for input activity answer field.',
    id: 'ActivityInput.answerLabel',
  },
  errorRequired: {
    defaultMessage: 'Answer is required',
    description: 'Error label for empty input activity answer field.',
    id: 'ActivityInput.errorRequired',
  },
  errorRequiredMultiple: {
    defaultMessage: 'Answers are required',
    description: 'Error label for empty multiple input activity answer fields.',
    id: 'ActivityInput.errorRequiredMultiple',
  },
  label: {
    defaultMessage: 'Type in the correct answer to complete the statement.',
    description: 'Instructions label for text input activity.',
    id: 'ActivityInput.label',
  },
  labelNumeric: {
    defaultMessage:
      'Type in the correct answer to complete the statement. The answer must be a number.',
    description: 'Instructions label for numeric input activity',
    id: 'ActivityInput.labelNumeric',
  },
  loadingActivityTitleContent: {
    defaultMessage: 'Loading question...',
    description:
      'Description displayed when the Categorization Activity Question content is loading upon receiving an Activity.',
    id: 'ActivityInput.loadingActivityTitleContent',
  },
})

const QuestionStem = ({ id, title }) => {
  return title
    .split(/(__+)/)
    .filter((segment) => !!segment)
    .map((segment, index) => {
      if (segment.match(/__+/)) {
        return (
          <label
            className="ActivityInput-placeholder"
            htmlFor={id}
            key={`ActivityInput__title-underscore-segment-${index}`}
          />
        )
      }
      return (
        <span
          qa-id="learner-activity-input-text"
          dangerouslySetInnerHTML={{ __html: segment }}
          key={`ActivityInput__title-segment-${index}`}
        />
      )
    })
}

const QuestionStemMultipleInput = ({
  adminCorrectAnswers,
  adminIncorrectAnswers,
  answerResponse,
  getMultipleInputAdminAnswer,
  inputIndex,
  isAnswerChanged,
  isErrorEmptySubmit,
  onChangeMultipleInput,
  onSubmit,
  // renderCorrectIncorrectIcon,
  title,
  value,
}) => {
  return (
    <form
      className="Activity-title Activity-title--is-multiple-input Activity__question-stem Activity__multiple-input-form"
      onSubmit={onSubmit}
    >
      {title
        .split(/(__+)/)
        .filter((segment) => !!segment)
        .map((segment, index) => {
          if (segment.match(/__+/)) {
            const answerIndex = ++inputIndex
            let isCorrect = null
            if (answerResponse) {
              isCorrect = answerResponse.filter((answer) => {
                const {
                  index: answerResponseIndex,
                  isCorrect: answerResponseIsCorrect,
                } = answer
                return (
                  !isNaN(answerResponseIndex) &&
                  parseInt(answerResponseIndex) === answerIndex &&
                  answerResponseIsCorrect
                )
              }).length
            }
            return (
              <div
                className="ActivityInput-multipleInput__wrapper"
                key={`ActivityInput-multipleInput-${index}`}
              >
                <Input
                  autoComplete="off"
                  className="ActivityInput-multipleInput"
                  id={`ActivityInput-multipleInput-${answerIndex}`}
                  isDanger={
                    (!value || !value[answerIndex]) &&
                    ((isAnswerChanged && isAnswerChanged[answerIndex]) ||
                      isErrorEmptySubmit)
                  }
                  label={`Field ${answerIndex}`}
                  labelPosition="after"
                  name={answerIndex}
                  onChange={
                    adminCorrectAnswers || adminIncorrectAnswers || isCorrect
                      ? (event) => {
                          event.preventDefault()
                        }
                      : (event) => onChangeMultipleInput(event, answerIndex)
                  }
                  readOnly={isCorrect}
                  value={
                    adminIncorrectAnswers || adminCorrectAnswers
                      ? getMultipleInputAdminAnswer(
                          adminIncorrectAnswers || adminCorrectAnswers,
                          answerIndex,
                        )
                      : value && value[answerIndex]
                      ? value[answerIndex]
                      : ''
                  }
                />
                {/* renderCorrectIncorrectIcon(isCorrect) */}
              </div>
            )
          }
          return (
            <span
              dangerouslySetInnerHTML={{ __html: segment }}
              key={`ActivityInput__multiple-input-segment-${index}`}
            />
          )
        })}
      <Button isHiddenSubmit type="submit" />
    </form>
  )
}

@injectIntl
export default class ActivityInput extends Component {
  static defaultProps = {
    isNumeric: false,
    labelIcon: 'keyboard-o',
  }
  state = {
    isAnswerChanged: false,
    isErrorEmptySubmit: false,
  }
  inputId = _.uniqueId('ActivityInput')
  get label() {
    const { intl, isNumeric, label } = this.props
    const { formatMessage } = intl
    if (label) {
      return label
    }
    if (isNumeric) {
      return formatMessage(ActivityInputMessages.labelNumeric)
    }
    return formatMessage(ActivityInputMessages.label)
  }
  componentDidUpdate(prevProps) {
    const { isNewActivity } = this.props
    const { isNewActivity: wasNewActivity } = prevProps
    if (!wasNewActivity && isNewActivity) {
      this.setState({ isAnswerChanged: false, isErrorEmptySubmit: false })
    }
  }
  onChange = ({ target }) => {
    const { onChange, isSavingActivity } = this.props
    const { isAnswerChanged } = this.state
    const { value } = target
    if (isSavingActivity) return
    if (!isAnswerChanged) {
      this.setState({ isAnswerChanged: true, isErrorEmptySubmit: false })
    }
    onChange(value)
  }
  onChangeMultipleInput = ({ name, value }) => {
    const { onChange, isSavingActivity, value: answer } = this.props
    const { isAnswerChanged } = this.state
    let newAnswer = { ...answer }
    if (isSavingActivity) return
    if (value) {
      newAnswer[name] = value
      this.setState({
        isAnswerChanged: { ...isAnswerChanged, [name]: true },
        isErrorEmptySubmit: false,
      })
    } else if (newAnswer[name]) {
      delete newAnswer[name]
    }
    onChange({ ...newAnswer })
  }
  onSubmit = (event) => {
    const {
      isConfirmDisabled,
      isMultipleInput,
      isSavingActivity,
      isSkippingActivity,
      onSubmit,
      // NOTE - if we want to make sure all blanks have values
      // title,
      value,
    } = this.props
    if (event) {
      event.preventDefault()
    }
    if (isConfirmDisabled) {
      return
    }
    // NOTE - if we want to make sure all blanks have values
    // if (!value || (isMultipleInput && Object.keys(value).length !== title.split(/(__+)/).filter((segment) => !!segment && segment.match(/__+/)).length)) {
    if (!value || (isMultipleInput && !Object.keys(value).length)) {
      this.setState({ isErrorEmptySubmit: true })
      return
    }
    if (isSavingActivity || isSkippingActivity) {
      return
    }
    if (onSubmit) {
      onSubmit()
    }
  }
  renderCorrectIncorrectIcon = (isCorrect) => {
    const { answerResponse } = this.props
    if (!answerResponse || isCorrect === null) return null
    return (
      <div
        className={cx('ActivityInput-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderFooter() {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      intl,
      isNumeric,
      value,
    } = this.props
    const { isAnswerChanged, isErrorEmptySubmit } = this.state
    const { formatMessage } = intl
    const isEmpty = (!value && isAnswerChanged) || isErrorEmptySubmit
    return (
      <div className="ActivityInput-footer">
        <div
          className={cx('ActivityInput-field', {
            'is-empty': isEmpty,
          })}
        >
          <label className="is-danger" htmlFor={this.inputId}>
            {formatMessage(ActivityInputMessages.answerLabel)}
          </label>
          <div className="control">
            <form onSubmit={this.onSubmit}>
              <Input
                autoComplete="off"
                className="ActivityInput-input"
                id={this.inputId}
                isDanger={isEmpty}
                name="name"
                onChange={
                  adminCorrectAnswers || adminIncorrectAnswers
                    ? (event) => {
                        event.preventDefault()
                      }
                    : this.onChange
                }
                qa-id={
                  isNumeric
                    ? 'learner-activity-input-numeric'
                    : 'learner-activity-input-text'
                }
                value={
                  adminIncorrectAnswers || adminCorrectAnswers
                    ? adminIncorrectAnswers
                      ? adminIncorrectAnswers[0].value
                      : adminCorrectAnswers[0].answer
                    : value || ''
                }
              />
              <Button isHiddenSubmit type="submit" />
            </form>
          </div>
        </div>
        {isEmpty ? (
          <span className="ActivityInput-empty">
            * {formatMessage(ActivityInputMessages.errorRequired)}
          </span>
        ) : null}
      </div>
    )
  }
  getMultipleInputAdminAnswer = (answers, answerIndex) => {
    let match = answers.filter((answer) => {
      const { index } = answer
      return parseInt(index) === answerIndex
    })[0]
    return match ? match.value || match.answer : ''
  }
  onLoadIframe = () => {
    this.sendMessage({ action: 'handleMultipleInput' })
  }
  sendMessage = (message) => {
    if (!this.$iframe) return
    this.$iframe.contentWindow.postMessage(JSON.stringify(message), '*')
  }
  setRef = (el) => {
    this.$iframe = el
  }
  renderMultipleInput = () => {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      answerResponse,
      id: activityId,
      intl,
      onOpenActivityModal,
      title,
      value,
    } = this.props
    const { isAnswerChanged, isErrorEmptySubmit } = this.state
    const { formatMessage } = intl
    const isEmpty = (!value && isAnswerChanged) || isErrorEmptySubmit
    let inputIndex = 0
    const el = (
      <QuestionStemMultipleInput
        adminCorrectAnswers={adminCorrectAnswers}
        adminIncorrectAnswers={adminIncorrectAnswers}
        answerResponse={answerResponse}
        getMultipleInputAdminAnswer={this.getMultipleInputAdminAnswer}
        inputIndex={inputIndex}
        isAnswerChanged={isAnswerChanged}
        isErrorEmptySubmit={isErrorEmptySubmit}
        onChangeMultipleInput={this.onChangeMultipleInput}
        onSubmit={this.onSubmit}
        // renderCorrectIncorrectIcon={this.renderCorrectIncorrectIcon}
        title={title}
        value={value}
      />
    )
    const html = ReactDOMServer.renderToString(el)
    return (
      <Fragment>
        <RichTextWrapper
          className="ActivityInput__rich-text-wrapper"
          content={html}
          id={`ActivityInput__${activityId}`}
          key={`ActivityInput__${activityId}`}
          loadingText={formatMessage(
            ActivityInputMessages.loadingActivityTitleContent,
          )}
          onChangeMultipleInput={this.onChangeMultipleInput}
          onLoad={this.onLoadIframe}
          onOpenImageModal={onOpenActivityModal}
          onSubmitMultipleInput={this.onSubmit}
          setRef={this.setRef}
        />
        {isEmpty ? (
          <span className="ActivityInput-empty">
            * {formatMessage(ActivityInputMessages.errorRequiredMultiple)}
          </span>
        ) : null}
      </Fragment>
    )
  }
  renderTitle() {
    const {
      id: activityId,
      intl,
      onOpenActivityModal,
      textAfter,
      textBefore,
      title,
    } = this.props
    const { formatMessage } = intl

    if (title) {
      const el = <QuestionStem id={this.inputId} title={title} />
      const html = ReactDOMServer.renderToString(el)
      return (
        <div className="Activity-title Activity__question-stem">
          <RichTextWrapper
            className="ActivityInput__rich-text-wrapper"
            content={html}
            id={`ActivityInput__${activityId}`}
            key={`ActivityInput__${activityId}`}
            loadingText={formatMessage(
              ActivityInputMessages.loadingActivityTitleContent,
            )}
            onOpenImageModal={onOpenActivityModal}
          />
        </div>
      )
    }

    return (
      <h1 className="Activity-title Activity__question-stem">
        {textBefore && (
          <span dangerouslySetInnerHTML={{ __html: textBefore }} />
        )}
        {textBefore && textAfter && (
          <label htmlFor={this.inputId} className="ActivityInput-placeholder" />
        )}
        {textAfter && <span dangerouslySetInnerHTML={{ __html: textAfter }} />}
      </h1>
    )
  }
  renderVideo() {
    const { video } = this.props
    return (
      <div className="Activity-video">
        <Video type={VIDEO_TYPES.KALTURA_VIDEO} entryId={video} />
      </div>
    )
  }
  renderBody = ({ renderImageAfterQuestion }) => {
    const { imageAlignment, isMultipleInput, video } = this.props
    return (
      <div className="ActivityInput">
        {isMultipleInput ? (
          <div className="ActivityInput-body">
            {video && this.renderVideo()}
            {this.renderMultipleInput()}
          </div>
        ) : (
          <div className="ActivityInput-body">
            {this.renderTitle()}
            {video && this.renderVideo()}
          </div>
        )}
        {imageAlignment === 'bottom' ? renderImageAfterQuestion() : null}
        {!isMultipleInput ? this.renderFooter() : null}
      </div>
    )
  }
  render() {
    const { label, ...restProps } = this.props
    const { isNumeric } = restProps || {}
    return (
      <Activity
        {...restProps}
        label={this.label}
        className={cx('is-input', this.props.className)}
        render={this.renderBody}
        type={
          isNumeric ? ACTIVITY_TYPES.numericInput : ACTIVITY_TYPES.textInput
        }
      />
    )
  }
}
