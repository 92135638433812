import React, { Component } from 'react'
import _ from 'lodash'
import cx from 'classnames'
import { Button, Heading, Loader, Modal } from '@/components'
import { CheckboxCustom, Input } from '@/components/form'
import { SELECT_ROLES } from '@/constants'
import './EditUserModal.scss'

const EditUserModalDefaultValues = function (values) {
  if (!values || typeof values !== 'object') {
    values = {}
  }
  return {
    confirmPassword: values.confirmPassword || '',
    dstInstructor: null,
    email: values.email || '',
    field1: values.field1 || '', // location
    field2: values.field2 || '', // job code / roles
    field3: values.field3 || '', // custom label
    firstName: values.firstName || '',
    instructor: values.instructor || '',
    lastName: values.lastName || '',
    password: values.password || '',
    prevInstructorId: values.prevInstructorId || '',
    removeInstructorId: values.removeInstructorId || '',
    roles: values.roles || [],
    userId: values.userId || null,
  }
}

export default class EditUserModal extends Component {
  static defaultProps = {
    values: new EditUserModalDefaultValues(),
  }
  constructor(props) {
    super(props)
    this.state = {
      dstInstructorName: '',
      dstInstructorOptions: [],
      instructorName: '',
      instructorOptions: [],
    }
  }
  get isTransfer() {
    const { isTransfer, values } = this.props
    const { roles } = values || new EditUserModalDefaultValues()
    let isInstructor = false
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].type === 'instructor') {
        isInstructor = true
        break
      }
    }
    return isTransfer && !isInstructor
  }
  componentDidMount() {
    const { loadInstructors } = this.props
    if (loadInstructors) {
      loadInstructors()
        .then((result) =>
          this.setState({
            dstInstructorOptions: result,
            instructorOptions: result,
          }),
        )
        .catch(() =>
          this.setState({ dstInstructorOptions: [], instructorOptions: [] }),
        )
    }
  }
  componentDidUpdate(prevProps) {
    const { isOpen, values } = this.props
    const { isOpen: wasOpen } = prevProps
    const { prevInstructorId } = values || {}
    if (wasOpen && !isOpen) {
      this.onChangeInstructorName({ target: { value: '' } })
    }
    if (!wasOpen && isOpen) {
      if (prevInstructorId) {
        this.displayPrevInstructor(prevInstructorId)
      }
    }
  }
  displayPrevInstructor = (prevInstructorId) => {
    const { instructorOptions } = this.state
    if (instructorOptions.length) {
      for (let i = 0; i < instructorOptions.length; i++) {
        if (instructorOptions[i].instructorId === prevInstructorId) {
          this.onChange('instructor', instructorOptions[i])
          break
        }
      }
    }
  }
  onChange = (field, value) => {
    if (this.props.onChange) {
      this.props.onChange(
        field,
        typeof value === 'string' ? value.trim() : value,
      )
    }
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  onSubmit = (event) => {
    const { onConfirm } = this.props
    if (onConfirm) {
      event.preventDefault()
      onConfirm()
    }
  }
  onChangeDstInstructorName = (event) => {
    const { dstInstructorName } = this.state
    const { target } = event
    const { value } = target
    if (dstInstructorName !== value) {
      this.setState({ dstInstructorName: value }, () =>
        this.searchInstructors(value, true),
      )
    }
  }
  onChangeInstructorName = (event) => {
    const { instructorName } = this.state
    const { target } = event
    const { value } = target
    if (instructorName !== value) {
      this.setState({ instructorName: value }, () =>
        this.searchInstructors(value),
      )
    }
  }
  onChangeRole = (roleClicked) => {
    const { adminUserId, values } = this.props
    const { roles, removeInstructorId } = values
    if (
      roles.length === 1 &&
      roles.filter((role) => role.type === roleClicked.value).length
    )
      return
    if (roles.filter((role) => role.type === roleClicked.value).length === 0) {
      if (removeInstructorId && roleClicked.value === 'instructor') {
        this.onChange(
          'roles',
          roles.concat({ id: removeInstructorId, type: 'instructor' }),
        )
      } else {
        this.onChange('roles', roles.concat({ type: roleClicked.value }))
      }
    } else {
      if (values.userId === adminUserId && roleClicked.value === 'admin') return
      this.onChange(
        'roles',
        roles.filter((role) => role.type !== roleClicked.value),
      )
    }
  }
  searchInstructors = _.debounce(
    (value, isTransfer = false) => {
      const { loadInstructors } = this.props
      loadInstructors(value, [], isTransfer)
        .then((result) => {
          if (!isTransfer) {
            let { instructorName: instructorNameValidation } = this.state
            if (instructorNameValidation === value) {
              this.setState({ instructorOptions: result })
            }
          } else {
            let { dstInstructorName: instructorNameValidation } = this.state
            if (instructorNameValidation === value) {
              this.setState({ dstInstructorOptions: result })
            }
          }
        })
        .catch(() => {
          if (!isTransfer) {
            let { instructorName: instructorNameValidation } = this.state
            if (instructorNameValidation === value) {
              this.setState({ instructorOptions: [] })
            }
          } else {
            let { dstInstructorName: instructorNameValidation } = this.state
            if (instructorNameValidation === value) {
              this.setState({ dstInstructorOptions: [] })
            }
          }
        })
    },
    250,
    { leading: true, trailing: true },
  )
  renderTransfer = () => {
    const { error, isLoadingDstInstructors, values } = this.props
    const { dstInstructorName, dstInstructorOptions } = this.state
    const { dstInstructor, userId } = values || {}
    const errorMessageDstInstructorId =
      error && error['dstInstructorId'] ? error['dstInstructorId'][0] : null
    const classNameInstructorId = cx('field', {
      'is-error': !!errorMessageDstInstructorId,
    })
    return (
      <div className={classNameInstructorId}>
        <p>
          The user is an instructor with existing students assigned to them.
          Please select a new instructor to transfer existing students to:
        </p>
        <div className="EditUserModal-instructorField">
          <div className="EditUserModal-instructorInput">
            <Input
              isSuccess={!!dstInstructor}
              placeholder="Enter instructor name..."
              onChange={this.onChangeDstInstructorName}
              value={dstInstructor ? dstInstructor.fullName : dstInstructorName}
              readOnly={!!dstInstructor}
            />
            {dstInstructor ? (
              <Button onClick={() => this.onChange('dstInstructor', null)}>
                x
              </Button>
            ) : null}
          </div>
          <div className="EditUserModal-instructorResults">
            <div
              className={cx('EditUserModal-instructorOptions', {
                'is-loading': isLoadingDstInstructors,
              })}
            >
              {dstInstructorOptions.length ? (
                dstInstructorOptions.map((instructorOption) =>
                  instructorOption.id !== userId ? (
                    <Button
                      className={cx({
                        'is-selected':
                          dstInstructor &&
                          dstInstructor.instructorId ===
                            instructorOption.instructorId,
                      })}
                      key={`EditUserModal-dstInstructorOptions-${instructorOption.instructorId}-${instructorOption.fullName}`}
                      onClick={() =>
                        this.onChange('dstInstructor', instructorOption)
                      }
                    >
                      {instructorOption.fullName}
                    </Button>
                  ) : null,
                )
              ) : (
                <span className="EditUserModal-instructorNoResults">
                  No results...
                </span>
              )}
            </div>
            {isLoadingDstInstructors ? (
              <div className="EditUserModal-instructorLoader">
                <Loader />
              </div>
            ) : null}
          </div>
        </div>
        {errorMessageDstInstructorId ? (
          <span className="EditUserModal-error">{`* ${errorMessageDstInstructorId}`}</span>
        ) : null}
      </div>
    )
  }
  renderBody = () => {
    const { error, isLoadingInstructors, values } = this.props
    const { instructorName, instructorOptions } = this.state
    const {
      confirmPassword,
      email,
      field1,
      field2,
      field3,
      firstName,
      instructor,
      lastName,
      password,
      roles,
    } = values || new EditUserModalDefaultValues()
    const errorMessageEmail = error && error['email'] ? error['email'][0] : null
    const errorMessageFirstName =
      error && error['firstName'] ? error['firstName'][0] : null
    const errorMessageField1 =
      error && error['field1'] ? error['field1'][0] : null
    const errorMessageField2 =
      error && error['field2'] ? error['field2'][0] : null
    const errorMessageField3 =
      error && error['field3'] ? error['field3'][0] : null
    const errorMessageLastName =
      error && error['lastName'] ? error['lastName'][0] : null
    const errorMessagePassword =
      error && error['password'] ? error['password'][0] : null
    const errorMessageConfirmPassword =
      error && error['confirmPassword'] ? error['confirmPassword'][0] : null
    const isErrorInstructorId =
      error && error['instructorId'] ? error['instructorId'][0] : null
    const hasFieldErrors =
      errorMessageEmail ||
      errorMessageFirstName ||
      errorMessageField1 ||
      errorMessageField2 ||
      errorMessageField3 ||
      errorMessageLastName ||
      errorMessagePassword ||
      errorMessageConfirmPassword ||
      isErrorInstructorId
    const classNameEmail = cx('field', {
      'is-error': !!errorMessageEmail,
    })
    const classNameField1 = cx('column', {
      'is-error': !!errorMessageField1,
    })
    const classNameField2 = cx('column', {
      'is-error': !!errorMessageField2,
    })
    const classNameField3 = cx('column', {
      'is-error': !!errorMessageField3,
    })
    const classNameFirstName = cx('column', {
      'is-error': !!errorMessageFirstName,
    })
    const classNameLastName = cx('column', {
      'is-error': !!errorMessageLastName,
    })
    const classNamePassword = cx('field', {
      'is-error': !!errorMessagePassword,
    })
    const classNameConfirmPassword = cx('field', {
      'is-error': !!errorMessageConfirmPassword,
    })
    const classNameInstructorId = cx('field', {
      'is-error': isErrorInstructorId,
    })
    const options = SELECT_ROLES.map(({ label, value }) => ({ label, value }))
    let isStudent = false
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].type === 'student') {
        isStudent = true
        break
      }
    }
    return (
      <form className="EditUserModal-body" onSubmit={this.onSubmit}>
        <div className="field columns">
          <div className={classNameFirstName}>
            <Heading is2>First name (Required)</Heading>
            <Input
              name="EditUserModal-firstName"
              placeholder="Enter first name..."
              onChange={(event) =>
                this.onChange('firstName', event.target.value || '')
              }
              value={firstName || ''}
            />
            {errorMessageFirstName ? (
              <span className="EditUserModal-error">{`* ${errorMessageFirstName}`}</span>
            ) : null}
          </div>
          <div className={classNameLastName}>
            <Heading is2>Last name (Required)</Heading>
            <Input
              name="EditUserModal-lastName"
              placeholder="Enter last name..."
              onChange={(event) =>
                this.onChange('lastName', event.target.value || '')
              }
              value={lastName || ''}
            />
            {errorMessageLastName ? (
              <span className="EditUserModal-error">{`* ${errorMessageLastName}`}</span>
            ) : null}
          </div>
        </div>
        <div className={classNameEmail}>
          <Heading is2>Email (Required)</Heading>
          <Input
            name="EditUserModal-email"
            placeholder="Enter email..."
            onChange={(event) =>
              this.onChange('email', event.target.value || '')
            }
            value={email || ''}
          />
          {errorMessageEmail ? (
            <span className="EditUserModal-error">{`* ${errorMessageEmail}`}</span>
          ) : null}
        </div>
        <div className="field columns">
          <div className={classNameField1}>
            <Heading is2>Location</Heading>
            <Input
              name="EditUserModal-field1"
              placeholder="Enter location..."
              onChange={(event) =>
                this.onChange('field1', event.target.value || '')
              }
              value={field1 || ''}
            />
            {errorMessageField1 ? (
              <span className="EditUserModal-error">{`* ${errorMessageField1}`}</span>
            ) : null}
          </div>
          <div className={classNameField2}>
            <Heading is2>Job Code / Role</Heading>
            <Input
              name="EditUserModal-field2"
              placeholder="Enter job code or role..."
              onChange={(event) =>
                this.onChange('field2', event.target.value || '')
              }
              value={field2 || ''}
            />
            {errorMessageField2 ? (
              <span className="EditUserModal-error">{`* ${errorMessageField2}`}</span>
            ) : null}
          </div>
          <div className={classNameField3}>
            <Heading is2>Other Label</Heading>
            <Input
              name="EditUserModal-field3"
              placeholder="Enter custom label..."
              onChange={(event) =>
                this.onChange('field3', event.target.value || '')
              }
              value={field3 || ''}
            />
            {errorMessageField3 ? (
              <span className="EditUserModal-error">{`* ${errorMessageField3}`}</span>
            ) : null}
          </div>
        </div>
        <div className={classNamePassword}>
          <Heading is2>Set New Password</Heading>
          <Input
            name="EditUserModal-password"
            placeholder="Enter password..."
            onChange={(event) =>
              this.onChange('password', event.target.value || '')
            }
            type="password"
            value={password || ''}
          />
          {errorMessagePassword ? (
            <span className="EditUserModal-error">{`* ${errorMessagePassword}`}</span>
          ) : null}
        </div>
        <div className={classNameConfirmPassword}>
          <Heading is2>Confirm New Password</Heading>
          <Input
            name="EditUserModal-confirmPassword"
            placeholder="Enter password confirmation..."
            onChange={(event) =>
              this.onChange('confirmPassword', event.target.value || '')
            }
            type="password"
            value={confirmPassword || ''}
          />
          {errorMessageConfirmPassword ? (
            <span className="EditUserModal-error">{`* ${errorMessageConfirmPassword}`}</span>
          ) : null}
        </div>
        <div className="field">
          <Heading is2>Account Type (Required)</Heading>
          <div className="EditUserModal-accountTypeButtons">
            {options.map((option) => (
              <CheckboxCustom
                checked={
                  roles.filter((role) => role.type === option.value).length
                }
                name={`EditUserModal-accountTypeButton-${option.value}`}
                key={`EditUserModal-accountTypeButton-${option.value}`}
                onChange={() => this.onChangeRole(option)}
              >
                {option.label}
              </CheckboxCustom>
            ))}
          </div>
        </div>
        {isStudent ? (
          <div className={classNameInstructorId}>
            <Heading is2>Instructor</Heading>
            <div className="EditUserModal-instructorField">
              <div className="EditUserModal-instructorInput">
                <Input
                  name="EditUserModal-instructorFieldName"
                  isSuccess={!!instructor}
                  placeholder="Enter instructor name..."
                  onChange={this.onChangeInstructorName}
                  value={instructor ? instructor.fullName : instructorName}
                  readOnly={!!instructor}
                />
                {instructor ? (
                  <Button onClick={() => this.onChange('instructor', null)}>
                    x
                  </Button>
                ) : null}
              </div>
              <div className="EditUserModal-instructorResults">
                <div
                  className={cx('EditUserModal-instructorOptions', {
                    'is-loading': isLoadingInstructors,
                  })}
                >
                  {instructorOptions.length ? (
                    instructorOptions.map((instructorOption) => (
                      <Button
                        className={cx({
                          'is-selected':
                            instructor &&
                            instructor.instructorId ===
                              instructorOption.instructorId,
                        })}
                        key={`EditUserModal-instructorOptions-${instructorOption.instructorId}-${instructorOption.fullName}`}
                        onClick={() =>
                          this.onChange('instructor', instructorOption)
                        }
                      >
                        {instructorOption.fullName}
                      </Button>
                    ))
                  ) : (
                    <span className="EditUserModal-instructorNoResults">
                      No results...
                    </span>
                  )}
                </div>
                {isLoadingInstructors ? (
                  <div className="EditUserModal-instructorLoader">
                    <Loader />
                  </div>
                ) : null}
              </div>
            </div>
            {isErrorInstructorId ? (
              <span>{`* ${isErrorInstructorId}`}</span>
            ) : null}
          </div>
        ) : null}
        {this.isTransfer ? this.renderTransfer() : null}
        {hasFieldErrors ? (
          <div className="EditUserModal__fieldError">
            <span>
              <span className="EditUserModal__fieldErrorIcon">!</span>The above
              must be completed before proceeding.
            </span>
          </div>
        ) : null}
        <Button className="EditUserModal-submit" isHiddenSubmit type="submit" />
      </form>
    )
  }
  render() {
    const { error, isLoading, isOpen, onConfirm, values } = this.props
    const errorMessageConfirm =
      error && error['confirm'] ? error['confirm'][0] : null
    const { dstInstructor } = values || {}
    return (
      <Modal
        className="EditUserModal"
        contentLabel="Edit User Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>Edit User</Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button disabled={isLoading} isSecondary onClick={this.onClose}>
            Cancel
          </Modal.Button>
          <Modal.Button
            disabled={this.isTransfer && !dstInstructor}
            isLoading={isLoading}
            onClick={() => onConfirm(this.isTransfer)}
          >
            Save
          </Modal.Button>
          {errorMessageConfirm ? (
            <span className="EditUserModal-confirmError">{`* ${errorMessageConfirm}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { EditUserModalDefaultValues }
