import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'

import { Video } from '@/components'
import { ACTIVITY_TYPES, VIDEO_TYPES } from '@/constants'
import { CheckboxCustom } from '@/components/form'
import { hasOwnProperty } from '@/utils/helpers'

import Activity from '../Activity'
import CorrectIncorrectIcon from '../CorrectIncorrectIcon'
import './ActivityChoice.scss'

const ActivityChoiceMessages = defineMessages({
  label: {
    defaultMessage: 'Select the correct answer.',
    description: 'Instructions label for multiple choice activity.',
    id: 'ActivityChoice.label',
  },
  labelMulti: {
    defaultMessage: 'Select the correct answers.',
    description: 'Instructions label for multiple response activity.',
    id: 'ActivityChoice.labelMulti',
  },
})

@injectIntl
export default class ActivityChoice extends Component {
  static defaultProps = {
    isMulti: false,
    labelIcon: 'mouse-pointer',
  }
  get label() {
    const { intl, isMulti, label } = this.props
    const { formatMessage } = intl
    if (label) {
      return label
    }
    if (isMulti) {
      return formatMessage(ActivityChoiceMessages.labelMulti)
    }
    return formatMessage(ActivityChoiceMessages.label)
  }
  isChecked(choiceId) {
    const { value } = this.props
    if (!value) return false
    return value.indexOf(choiceId) !== -1
  }
  onChange = ({ target }) => {
    const { onChange, isMulti, isSavingActivity, value } = this.props
    if (!onChange || isSavingActivity) return
    if (!value) return onChange([target.value])
    // when radio, it can be only one item
    if (!isMulti) {
      return onChange(value.indexOf(target.value) !== -1 ? [] : [target.value])
    }
    // remove or add current value to the list
    return onChange(
      value.indexOf(target.value) !== -1
        ? value.filter((v) => v !== target.value)
        : value.concat(target.value),
    )
  }
  renderImageChoices() {
    // TODO: fix, not being trigger properly when we have an image as a possible content answer
    const placeholderImage = 'https://placeimg.com/400/400/tech'
    const colsClassName = cx('column', {
      'is-6-mobile is-6-tablet is-3-desktop':
        this.props.isImage || this.props.isLabeled,
      'is-12-mobile is-6-tablet is-12-desktop is-6-widescreen':
        this.props.isFullLabeled,
    })
    return (
      <div className="columns is-multiline is-mobile">
        {this.props.choices.map((choice, index) => (
          <div
            className={colsClassName}
            key={`ActivityChoice-option-image-${index}`}
          >
            <div className="ActivityChoice-option">
              <CheckboxCustom
                isLabeled={this.props.isLabeled}
                isFullLabeled={this.props.isFullLabeled}
                imageUrl={placeholderImage}
                text={choice.content}
                name="choice-images"
                onChange={this.onChange}
                value={choice.id}
                checked={this.isChecked(choice.id)}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
  renderAdminCorrectIncorrectIcon(isCorrect) {
    return (
      <div
        className={cx('ActivityChoice-answerResponse', {
          'is-choice': true,
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderCorrectIncorrectIcon(choice) {
    const { answerHasChanged, answerResponse, isMulti } = this.props
    if (
      answerHasChanged ||
      !answerResponse ||
      !isMulti ||
      !this.isChecked(choice.id)
    )
      return null
    let isCorrect = null

    for (let i = 0; i < answerResponse.length; i++) {
      if (choice.id === answerResponse[i].value) {
        isCorrect = answerResponse[i].isCorrect
        break
      }
    }

    if (isCorrect === null) return null

    return (
      <div
        className={cx('ActivityChoice-answerResponse', {
          'is-choice': true,
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderChoices() {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      choices,
      onOpenActivityModal,
    } = this.props
    let hasImages = false

    // TODO: fix, this is not very elegant, see renderImageChoices
    choices.forEach((choice) => {
      if (choice.image) hasImages = true
    })

    return (
      <div className={cx('', { 'columns is-multiline is-mobile': hasImages })}>
        {choices.map((choice, index) => {
          let isCorrect = false
          let isChecked = false
          if (adminCorrectAnswers) {
            for (let i = 0; i < adminCorrectAnswers.length; i++) {
              if (choice.id === adminCorrectAnswers[i]) {
                isCorrect = true
                isChecked = true
                break
              }
            }
          } else if (adminIncorrectAnswers) {
            if (hasOwnProperty(adminIncorrectAnswers, choice.id)) {
              if (adminIncorrectAnswers[choice.id]) {
                isCorrect = true
                isChecked = true
              } else {
                isCorrect = false
                isChecked = true
              }
            }
          }
          return (
            <div
              key={`ActivityChoice-option-${index}`}
              className={cx('', {
                'column is-6-mobile is-6-tablet is-3-desktop': choice.image,
              })}
            >
              <div className="ActivityChoice-option">
                <div
                  className={cx('control', {
                    'is-images': hasImages,
                  })}
                >
                  <CheckboxCustom
                    adminPreventDefault={adminCorrectAnswers}
                    isRadio={!this.props.isMulti}
                    text={choice.content}
                    name="choice-questions"
                    imageUrl={choice.image}
                    onChange={this.onChange}
                    onOpenActivityModal={onOpenActivityModal}
                    value={choice.id}
                    checked={
                      adminCorrectAnswers || adminIncorrectAnswers
                        ? isCorrect || isChecked
                        : this.isChecked(choice.id)
                    }
                  />
                  {isChecked && (adminCorrectAnswers || adminIncorrectAnswers)
                    ? this.renderAdminCorrectIncorrectIcon(isCorrect)
                    : this.renderCorrectIncorrectIcon(choice)}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  renderVideo() {
    return (
      <div className="Activity-video">
        <Video type={VIDEO_TYPES.KALTURA_VIDEO} entryId={this.props.video} />
      </div>
    )
  }
  renderBody = ({ renderTitle, renderImageAfterQuestion }) => {
    const { imageAlignment } = this.props
    return (
      <div className="ActivityChoice">
        <div className="ActivityChoice-body">
          <form
            onSubmit={(event) => {
              event.preventDefault()
            }}
          >
            <fieldset>
              <legend>{renderTitle()}</legend>
              {this.props.video && this.renderVideo()}
              {imageAlignment === 'bottom' ? (
                <div className="ActivityChoice-imageBottom">
                  {renderImageAfterQuestion()}
                </div>
              ) : null}
              {this.props.isImage
                ? this.renderImageChoices()
                : this.renderChoices()}
            </fieldset>
          </form>
        </div>
      </div>
    )
  }
  render() {
    const { label, ...restProps } = this.props
    const { isMulti } = restProps || {}
    return (
      <Activity
        {...restProps}
        label={this.label}
        className={cx('is-choice', this.props.className)}
        render={this.renderBody}
        type={
          isMulti ? ACTIVITY_TYPES.multipleChoice : ACTIVITY_TYPES.singleChoice
        }
      />
    )
  }
}
