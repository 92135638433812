export const handleError = (error) => {
  if (error.response.status === 423) {
    return Promise.reject(new Error(error.response.status))
  }

  if (error.response && error.response.data) {
    return Promise.reject(
      new Error(
        error.response.data.errors
          ? error.response.data.errors
          : error.response.data.message,
      ),
    )
  }

  if (process.env.NODE_ENV === 'development') {
    return Promise.reject(error)
  }

  return Promise.reject(new Error('Error communicating with the server'))
}
