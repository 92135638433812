import React, { Component } from 'react'
import cx from 'classnames'

import './AdminLayout.scss'

const Sidebar = ({ children }) => (
  <div className="AdminLayout-sidebar column">
    <div className="AdminLayout-box">{children}</div>
  </div>
)

const Box = ({ children }) => <div className="AdminLayout-box">{children}</div>

const Main = ({ children, id, isFullWidth }) => (
  <div
    className={cx('AdminLayout-content column', {
      'is-two-thirds': !isFullWidth,
      'is-three-quarters-widescreen': !isFullWidth,
    })}
    id={id}
  >
    <Box>{children}</Box>
  </div>
)

export default class AdminLayout extends Component {
  static Sidebar = Sidebar
  static Main = Main
  static Box = Box

  render() {
    const className = cx('AdminLayout columns is-gapless', this.props.className)
    return <div className={className}>{this.props.children}</div>
  }
}
