import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { Button, CourseProgress, Link } from '@/components'
import './MobileNavMenu.scss'

const MobileNavMenuMessages = defineMessages({
  admin: {
    defaultMessage: 'Admin',
    description:
      'Button to navigate to the admin platform from the mobile nav menu.',
    id: 'MobileNavMenu.admin',
  },
  close: {
    defaultMessage: 'Close navigation menu',
    description: 'Button to close the mobile nav menu.',
    id: 'MobileNavMenu.close',
  },
  courses: {
    defaultMessage: 'Courses',
    description:
      'Button to navigate to the course picker page from the mobile nav menu.',
    id: 'MobileNavMenu.courses',
  },
  glossary: {
    defaultMessage: 'Glossary',
    description: 'Button to open the glossary from the mobile nav menu.',
    id: 'MobileNavMenu.glossary',
  },
  faq: {
    defaultMessage: 'FAQ',
    description: 'Button to navigate to the FAQ page from the mobile nav menu.',
    id: 'MobileNavMenu.faq',
  },
  home: {
    defaultMessage: 'Home',
    description:
      'Button to navigate to the home page from the mobile nav menu.',
    id: 'MobileNavMenu.home',
  },
  learner: {
    defaultMessage: 'Learner',
    description:
      'Button to navigate to the learner platform from the mobile nav menu.',
    id: 'MobileNavMenu.learner',
  },
  logout: {
    defaultMessage: 'Logout',
    description: 'Button to log the user out from the mobile nav menu.',
    id: 'MobileNavMenu.logout',
  },
  messages: {
    defaultMessage: 'Messages',
    description:
      'Button to navigate to the messages page from the mobile nav menu.',
    id: 'MobileNavMenu.messages',
  },
  profile: {
    defaultMessage: 'My Profile',
    description:
      'Button to navigate to the user profile page from the mobile nav menu.',
    id: 'MobileNavMenu.profile',
  },
})

@injectIntl
@withRouter
@inject('contentStore', 'messagesStore', 'sessionStore', 'uiStore')
@observer
export default class MobileNavMenu extends Component {
  state = { isHidden: true }
  componentDidMount() {
    this.$refWrapper.addEventListener('transitionstart', this.showVisibility)
  }
  componentDidUpdate() {
    const { uiStore } = this.props
    const { showMobileNavMenu } = uiStore
    if (this.$ref && showMobileNavMenu) {
      this.$ref.focus()
    }
  }
  componentWillUnmount() {
    this.$refWrapper.removeEventListener('transitionstart', this.showVisibility)
  }
  logout = (event) => {
    const { history, sessionStore, uiStore } = this.props
    const { session } = sessionStore
    const { logoutUrl } = session || {}
    event.preventDefault()
    uiStore.toggleMobileNavMenu()
    sessionStore.logout().then(() => {
      if (!logoutUrl) {
        history.push('/login')
      }
    })
  }
  closeMenu = () => {
    const { uiStore } = this.props
    uiStore.toggleMobileNavMenu()
  }
  hideVisibility = (e) => {
    const { uiStore } = this.props
    const { isHidden } = this.state
    const { target } = e
    const { showMobileNavMenu } = uiStore
    const isWrapper = target === this.$refWrapper
    if (isWrapper) {
      this.setState({ isHidden: !isHidden && !showMobileNavMenu })
    }
  }
  showVisibility = (e) => {
    const { uiStore } = this.props
    const { isHidden } = this.state
    const { target } = e
    const { showMobileNavMenu } = uiStore
    const isWrapper = target === this.$refWrapper
    if (isWrapper) {
      this.setState({ isHidden: isHidden && !showMobileNavMenu })
    }
  }
  menuClick = (e) => {
    e.stopPropagation()
  }
  onClickSwitchPlatform = () => {
    const { sessionStore } = this.props
    sessionStore.switchPlatform()
    this.closeMenu()
  }
  setRef = (el) => {
    this.$ref = el
  }
  setRefWrapper = (el) => {
    this.$refWrapper = el
  }
  renderCoursesListItem(hasMoreCourses, isCourses) {
    const { intl } = this.props
    const { formatMessage } = intl
    return hasMoreCourses ? (
      <li>
        <Link
          className={cx('MobileNavMenu__list-item', { active: isCourses })}
          onClick={this.closeMenu}
          to="/courses"
        >
          <div className="MobileNavMenu__list-item-icon">
            <i aria-hidden="true" className="fa fa-lightbulb-o" />
          </div>
          <span>{formatMessage(MobileNavMenuMessages.courses)}</span>
        </Link>
      </li>
    ) : null
  }
  renderLearnerStatus = () => {
    const { contentStore } = this.props
    const { courseProgress, sectionsProgress } = contentStore
    const { completedSections, nSections } = sectionsProgress || {}
    return (
      <div className="MobileNavMenu-LearnerStatus">
        <CourseProgress
          progress={courseProgress}
          sectionsCompleted={completedSections}
          sectionsTotal={nSections}
        />
      </div>
    )
  }
  renderMenuAdminListItems = () => {
    const { location, sessionStore } = this.props
    const { pathname } = location
    const { user } = sessionStore
    const { isCurrentRoleAdmin } = user
    const isNotifications = pathname === '/admin/notifications'
    if (!isCurrentRoleAdmin) return null
    return (
      <Fragment>
        <li>
          <Link
            className={cx('MobileNavMenu__list-item', {
              active: isNotifications,
            })}
            onClick={this.closeMenu}
            to="/admin/notifications"
          >
            <div className="MobileNavMenu__list-item-icon">
              <i aria-hidden="true" className="fa fa-bell" />
            </div>
            <span>Notifications</span>
          </Link>
        </li>
      </Fragment>
    )
  }
  renderMenuLearnerListItems = () => {
    const { contentStore, intl, location, messagesStore, sessionStore } =
      this.props
    const { course, settings, snapshot } = contentStore
    const { formatMessage } = intl
    const { unreadMessagesCount } = messagesStore
    const { session, user } = sessionStore
    const { pathname } = location
    const { hasMoreCourses } = session
    const { isDisabledMessaging } = settings || {}
    const { glossary } = course || {}
    const { isOpen: isOpenSnapshot } = snapshot || {}
    const { isCurrentRoleAdmin } = user
    const isCourses = pathname === '/courses'
    const isFAQ = pathname === '/faq'
    const isMessages = pathname === '/messages' || pathname.match('/messages')
    const isProfile = pathname === '/account'
    if (isCurrentRoleAdmin) return null
    return (
      <Fragment>
        {!isOpenSnapshot ? (
          <li>
            <Link
              className={cx('MobileNavMenu__list-item', { active: isProfile })}
              onClick={this.closeMenu}
              to="/account"
            >
              <div className="MobileNavMenu__list-item-icon">
                <i aria-hidden="true" className="fa fa-user" />
              </div>
              <span>{formatMessage(MobileNavMenuMessages.profile)}</span>
            </Link>
          </li>
        ) : null}
        {!isDisabledMessaging && !isOpenSnapshot ? (
          <li>
            <Link
              className={cx('MobileNavMenu__list-item', { active: isMessages })}
              onClick={this.closeMenu}
              to="/messages"
            >
              <div className="MobileNavMenu__list-item-icon">
                <i aria-hidden="true" className="fa fa-comment" />
              </div>
              <span>{formatMessage(MobileNavMenuMessages.messages)}</span>
              {unreadMessagesCount ? (
                <span className="MobileNavMenu-UnreadMessageCount">
                  {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                </span>
              ) : null}
            </Link>
          </li>
        ) : null}
        {this.renderCoursesListItem(hasMoreCourses, isCourses)}
        {!isOpenSnapshot ? (
          <li>
            <Link
              className={cx('MobileNavMenu__list-item', { active: isFAQ })}
              onClick={this.closeMenu}
              to="/faq"
            >
              <div className="MobileNavMenu__list-item-icon">
                <i aria-hidden="true" className="fa fa-info-circle" />
              </div>
              <span>{formatMessage(MobileNavMenuMessages.faq)}</span>
            </Link>
          </li>
        ) : null}
        {glossary && !isOpenSnapshot ? (
          <li>
            <Link
              className="MobileNavMenu__list-item"
              target="_blank"
              href={glossary}
            >
              <div className="MobileNavMenu__list-item-icon">
                <i aria-hidden="true" className="fa fa-book" />
              </div>
              <span>{formatMessage(MobileNavMenuMessages.glossary)}</span>
            </Link>
          </li>
        ) : null}
      </Fragment>
    )
  }
  render() {
    const { contentStore, intl, location, sessionStore, uiStore } = this.props
    const { isHidden } = this.state
    const { formatMessage } = intl
    const { user } = sessionStore
    if (!user) return null
    const { showMobileNavMenu } = uiStore
    const { firstName, isAdminAndLearner, isCurrentRoleAdmin, lastName } = user
    const name = `${firstName} ${lastName}`
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`
    const { course, snapshot } = contentStore
    const { title: courseTitle } = course || {}
    const { isOpen: isOpenSnapshot } = snapshot || {}
    const { pathname } = location
    const isHome =
      pathname === '/' ||
      pathname === '/admin/courses' ||
      pathname.match('dashboard')
    return (
      <div
        className={cx('MobileNavMenu__wrapper', {
          'MobileNavMenu__wrapper--is-hidden': isHidden,
          'slide-left': showMobileNavMenu,
        })}
        onClick={this.closeMenu}
        onTransitionEnd={this.hideVisibility}
        ref={this.setRefWrapper}
      >
        <div className="MobileNavMenu" onClick={this.menuClick}>
          <Button
            aria-label={formatMessage(MobileNavMenuMessages.close)}
            className="MobileNavMenu-Close"
            isText
            onClick={this.closeMenu}
            setRef={this.setRef}
          >
            <i aria-hidden="true" className="fa fa-angle-right" />
          </Button>
          <div className="MobileNavMenu-Info">
            <div className="MobileNavMenu-initials">
              <Link
                aria-label={formatMessage(MobileNavMenuMessages.profile)}
                to={isOpenSnapshot ? '/' : '/account'}
                onClick={this.closeMenu}
              >
                {initials}
              </Link>
            </div>
            <div className="MobileNavMenu-UserDetails">
              <p className="MobileNavMenu-Name">{name}</p>
              {!isCurrentRoleAdmin && (
                <p className="MobileNavMenu-CourseTitle">{courseTitle}</p>
              )}
            </div>
          </div>
          {!isCurrentRoleAdmin && !isOpenSnapshot && this.renderLearnerStatus()}
          <ul>
            <li>
              <Link
                className={cx('MobileNavMenu__list-item', { active: isHome })}
                onClick={this.closeMenu}
                to="/"
              >
                <div className="MobileNavMenu__list-item-icon">
                  <i aria-hidden="true" className="fa fa-home" />
                </div>
                <span>{formatMessage(MobileNavMenuMessages.home)}</span>
              </Link>
            </li>
            {this.renderMenuAdminListItems()}
            {this.renderMenuLearnerListItems()}
            {isAdminAndLearner ? (
              <li>
                <Link
                  className="MobileNavMenu__list-item"
                  onClick={this.onClickSwitchPlatform}
                  to="/"
                >
                  <div className="MobileNavMenu__list-item-icon">
                    <i aria-hidden="true" className="fa fa-exchange" />
                  </div>
                  <span>
                    {isCurrentRoleAdmin
                      ? formatMessage(MobileNavMenuMessages.learner)
                      : formatMessage(MobileNavMenuMessages.admin)}
                  </span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                className="MobileNavMenu__list-item"
                onClick={this.logout}
                to="#"
              >
                <div className="MobileNavMenu__list-item-icon">
                  <i aria-hidden="true" className="fa fa-sign-out" />
                </div>
                <span>{formatMessage(MobileNavMenuMessages.logout)}</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
