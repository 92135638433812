import React, { Component } from 'react'
import cx from 'classnames'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'

import { Button, Heading, Modal } from '@/components'

import './LocaleSelectorModal.scss'

const LocaleSelectorModalMessages = defineMessages({
  cancelButton: {
    defaultMessage: 'Cancel',
    description:
      'Close button displayed within the footer of the locale selector modal.',
    id: 'LocaleSelectorModal.cancelButton',
  },
  heading: {
    defaultMessage: 'Select a Language',
    description: 'Heading displayed within the locale selector modal.',
    id: 'LocaleSelectorModal.heading',
  },
})

const localeOptions = [
  'zh-Hans-CN',
  'zh-Hant-TW',
  'da-DK',
  'en-US',
  'fr-FR',
  'de-DE',
  'ja-JP',
  'ko-KR',
  'nb-NO',
  'pl-PL',
  'pt-BR',
  'ro-RO',
  'ru-RU',
  'si-LK',
  'es-419',
  'tr-TR',
  'uk-UA',
]
const localeMapping = {
  'zh-Hans-CN': 'Chinese, Simplified - 简体中文',
  'zh-Hant-TW': 'Chinese, Traditional (Taiwan) - 繁體中文 (台灣)',
  'da-DK': 'Danish - dansk',
  'en-US': 'English',
  'fr-FR': 'French - français',
  'de-DE': 'German - Deutsch',
  'ja-JP': 'Japanese - 日本語',
  'ko-KR': 'Korean - 한국어',
  'nb-NO': 'Norwegian (Bokmål) - norsk (bokmål)',
  'pl-PL': 'Polish - polski',
  'pt-BR': 'Portuguese (Brazil) - português (Brasil)',
  'ro-RO': 'Romanian - română',
  'ru-RU': 'Russian - русский',
  'si-LK': 'Sinhalese - සිංහල',
  'es-419': 'Spanish (Latin America)',
  'tr-TR': 'Turkish - Türkçe',
  'uk-UA': 'Ukrainian - українська',
}

@injectIntl
@inject('contentStore', 'sessionStore', 'uiStore')
@observer
export default class LocaleSelectorModal extends Component {
  onClickLocale = (event) => {
    const { contentStore, sessionStore, uiStore } = this.props
    const { target } = event
    const { value: locale } = target || {}
    sessionStore.updateLocale(locale).then(contentStore.loadCourse)
    uiStore.closeLocaleSelectorModal()
  }
  onClose = () => {
    const { uiStore } = this.props
    uiStore.closeLocaleSelectorModal()
  }
  render() {
    const { intl, sessionStore, uiStore } = this.props
    const { session } = sessionStore || {}
    const { isOpenLocaleSelectorModal } = uiStore || {}
    const { formatMessage } = intl
    const { locale: currentLocale } = session || {}
    return (
      <Modal
        className={cx('LocaleSelectorModal')}
        contentLabel="Locale Selector Modal"
        isOpen={isOpenLocaleSelectorModal}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>
            {formatMessage(LocaleSelectorModalMessages.heading)}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          {localeOptions.map((locale) => {
            return (
              <Button
                className="LocaleSelectorModal__locale-button"
                disabled={currentLocale === locale}
                isInfo={currentLocale === locale}
                key={`LocaleSelectorModal__locale-button-${locale}`}
                onClick={this.onClickLocale}
                value={locale}
              >
                {localeMapping[locale]}
              </Button>
            )
          })}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button onClick={this.onClose}>
            {formatMessage(LocaleSelectorModalMessages.cancelButton)}
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
