import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import cx from 'classnames'

import {
  Button,
  CheckboxCustom,
  Heading,
  Loader,
  LocaleSelectorButton,
  PageCard,
  PageCardBody,
  PageCardFoot,
  UserAvatar,
} from '@/components'
import { Input } from '@/components/form'
import { GENDERS, US_STATES, EMPTY_VALUE } from '@/constants'

import './AccountPage.scss'

const AccountPageMessages = defineMessages({
  defaultLanguage: {
    defaultMessage: 'Default Language:',
    description:
      'Heading for the button to open the language modal on the account page.',
    id: 'AccountPage.defaultLanguage',
  },
  labelAbout: {
    defaultMessage: 'About me',
    description:
      'Label for the input field to enter a description about the user in the account page.',
    id: 'AccountPage.labelAbout',
  },
  labelAboutCharacterCount: {
    defaultMessage: '{characterCount} / {characterLimit} characters',
    description:
      'Label for the characters used / available in the input field to enter a description about the user in the account page.',
    id: 'AccountPage.labelAboutCharacterCount',
  },
  labelContrastMode: {
    defaultMessage: 'Contrast Mode:',
    description:
      'Heading for the checkbox to activate high contrast mode for users in the account page.',
    id: 'AccountPage.labelContrastMode',
  },
  labelContrastModeEnabled: {
    defaultMessage: 'Enabled',
    description:
      'Label for the checkbox to activate high contrast mode for users in the account page.',
    id: 'AccountPage.labelContrastModeEnabled',
  },
  labelSave: {
    defaultMessage: 'Save',
    description:
      'Button to submit changes to the user profile in the account page.',
    id: 'AccountPage.labelSave',
  },
  labelSaving: {
    defaultMessage: 'Saving...',
    description:
      'Button text displayed while changes to the user profile are being saved in the account page.',
    id: 'AccountPage.labelSaving',
  },
  placeholderAbout: {
    defaultMessage: 'Add a description for your profile',
    description:
      'Placeholder for the field to enter a description about the user in the account page.',
    id: 'AccountPage.placeholderAbout',
  },
  saveSuccess: {
    defaultMessage: 'Saved Profile Successfully!',
    description:
      'Description displayed after changes to the user profile are successfully saved in the account page.',
    id: 'AccountPage.saveSuccess',
  },
  title: {
    defaultMessage: 'My Profile',
    description: 'Title displayed on the account page.',
    id: 'AccountPage.title',
  },
})

const stateOptions = US_STATES.map((state) => ({
  value: state.value,
  text: state.code,
}))
const genderOptions = GENDERS.map((gender) => ({
  value: gender,
  text: gender,
}))

const DESCRIPTION_CHAR_LIMIT = 300

@injectIntl
@inject('accountStore', 'uiStore')
@observer
export default class AccountPage extends Component {
  state = {
    contrastMode: false,
    form: {
      longDescription: '',
      city: '',
      state: stateOptions[0].value,
      gender: genderOptions[0].value,
      birthdateMonth: EMPTY_VALUE,
      birthdateDay: EMPTY_VALUE,
      birthdateYear: EMPTY_VALUE,
    },
    longDescriptionCharCount: 0,
    savedProfileSuccessfully: false,
  }
  componentDidMount() {
    const { accountStore, uiStore } = this.props
    uiStore.updateTitle('Account Page')

    accountStore.loadUserProfile().then((userProfile) => {
      const {
        birthdate: userProfileBirthDate,
        city: userProfileCity,
        gender: userProfileGender,
        longDescription: userProfileLongDescription,
        state: userProfileState,
      } = userProfile
      const longDescriptionCharCount =
        (userProfileLongDescription && userProfileLongDescription.length) || 0

      this.setState((state) => {
        const newState = {
          isFormChanged: false,
          longDescriptionCharCount,
          form: { ...state.form },
        }

        if (userProfileLongDescription)
          newState.form.longDescription = userProfileLongDescription
        if (userProfileCity) newState.form.city = userProfileCity
        if (userProfileState) newState.form.state = userProfileState
        if (userProfileGender) newState.form.gender = userProfileGender
        if (userProfileBirthDate && userProfileBirthDate !== '0000-00-00') {
          const birthdate = moment(userProfileBirthDate, 'YYYY-MM-DD')
          newState.form.birthdateDay = birthdate.date()
          newState.form.birthdateMonth = birthdate.month()
          newState.form.birthdateYear = birthdate.year()
        }

        newState.contrastMode = uiStore.getContrastMode()

        return newState
      })
    })
  }
  handleChange = (e) => {
    let { name, value } = e.target
    const isChangingDescription = name === 'longDescription'
    if (isChangingDescription) {
      value = value.substring(0, DESCRIPTION_CHAR_LIMIT)
    }
    this.setState((state) => {
      return {
        isFormChanged: state[name] !== value,
        savedProfileSuccessfully: false,
        longDescriptionCharCount: isChangingDescription
          ? value.length
          : state.longDescriptionCharCount,
        form: {
          ...state.form,
          [name]: value,
        },
      }
    })
  }
  getBirthdate() {
    const yyyy = this.state.form.birthdateYear
    const mm = this.state.form.birthdateMonth
    const dd = this.state.form.birthdateDay
    let birthdate
    if (yyyy !== EMPTY_VALUE && mm !== EMPTY_VALUE && dd !== EMPTY_VALUE) {
      birthdate = moment(`${yyyy}-${Number(mm) + 1}-${dd}`, 'YYYY-MM-DD')
      if (birthdate.isValid()) {
        return birthdate.format('YYYY-MM-DD')
      }
    }
  }
  onContrastChange = () => {
    const { uiStore } = this.props
    const { contrastMode } = this.state
    this.setState({
      contrastMode: !contrastMode,
    })
    uiStore.setContrastMode(!contrastMode)
  }
  save = () => {
    const { accountStore } = this.props
    const { form, isFormChanged } = this.state
    if (isFormChanged) {
      accountStore
        .updateUserProfile({
          ...form,
          birthdate: this.getBirthdate(),
        })
        .then(() => {
          this.setState({
            isFormChanged: false,
            savedProfileSuccessfully: true,
          })
        })
    }
  }
  renderUserProfile() {
    const { accountStore, intl } = this.props
    const { isFormChanged, savedProfileSuccessfully } = this.state
    const { isUpdatingUserProfile, userProfile } = accountStore
    const { formatMessage } = intl

    if (accountStore.isLoadingUserProfile) return <Loader />
    if (!userProfile) return null

    const isApproachingCharLimitForDescription =
      this.state.longDescriptionCharCount >= DESCRIPTION_CHAR_LIMIT - 10

    return (
      <div className="AccountPage-bodyColumns columns">
        <div className="column">
          <div className="AccountPage-container">
            <div className="columns is-smallgap">
              <div className="column">
                <div className="columns is-mobile">
                  <div className="column is-narrow">
                    <UserAvatar size={60} name={userProfile.user.fullName} />
                  </div>
                  <div className="column">
                    <Heading is2>{userProfile.user.fullName}</Heading>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns is-smallgap">
              <div className="column">
                <div className="AccountPage-aboutLevel level is-mobile">
                  <label className="label" htmlFor="AccountPage__about">
                    {formatMessage(AccountPageMessages.labelAbout)}
                  </label>
                  <div
                    className={cx('help is-top', {
                      'is-danger': isApproachingCharLimitForDescription,
                    })}
                  >
                    {formatMessage(
                      AccountPageMessages.labelAboutCharacterCount,
                      {
                        characterCount:
                          this.state.longDescriptionCharCount || 0,
                        characterLimit: DESCRIPTION_CHAR_LIMIT,
                      },
                    )}
                  </div>
                </div>
                <Input
                  className="AccountPage-about"
                  id="AccountPage__about"
                  isMultiline
                  name="longDescription"
                  onChange={!isUpdatingUserProfile && this.handleChange}
                  placeholder={formatMessage(
                    AccountPageMessages.placeholderAbout,
                  )}
                  value={this.state.form.longDescription}
                />
                <div>
                  <Button
                    className="AccountPage-button"
                    disabled={isUpdatingUserProfile || !isFormChanged}
                    isLoading={isUpdatingUserProfile}
                    isSuccess
                    isWide
                    onClick={this.save}
                    text={
                      isUpdatingUserProfile
                        ? formatMessage(AccountPageMessages.labelSaving)
                        : formatMessage(AccountPageMessages.labelSave)
                    }
                  />
                  {savedProfileSuccessfully ? (
                    <p className="AccountPage-savedProfileText">
                      {formatMessage(AccountPageMessages.saveSuccess)}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    const { contrastMode } = this.state
    return (
      <PageCard
        className="AccountPage"
        id="main-content"
        title={formatMessage(AccountPageMessages.title)}
      >
        <PageCardBody className="AccountPage-pageCardBody">
          {this.renderUserProfile()}
        </PageCardBody>
        <PageCardFoot>
          <div className="AccountPage-container">
            <div className="AccountPage-container__locale">
              <Heading className="AccountPage-container__locale-heading" is5>
                {formatMessage(AccountPageMessages.defaultLanguage)}
              </Heading>
              <LocaleSelectorButton className="AccountPage-container__locale-button" />
            </div>
            <div className="AccountPage-container__contrast">
              <Heading className="AccountPage-container__contrast-heading" is5>
                {formatMessage(AccountPageMessages.labelContrastMode)}
              </Heading>
              <CheckboxCustom
                checked={contrastMode}
                onChange={this.onContrastChange}
                text={formatMessage(
                  AccountPageMessages.labelContrastModeEnabled,
                )}
              />
            </div>
          </div>
        </PageCardFoot>
      </PageCard>
    )
  }
}
