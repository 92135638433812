import React, { Component } from 'react'
import InstanceContentPageUnit from './InstanceContentPageUnit'

export default class InstanceContentPageUnits extends Component {
  render() {
    const { onClickOpenActivityModal, units } = this.props
    return (
      <div className="InstanceContentPage__units">
        {units.length ? (
          units.map((unit, index) => {
            const { id: unitId } = unit
            return (
              <InstanceContentPageUnit
                key={`InstanceContentPage__unit-${unitId}-${index}`}
                onClickOpenActivityModal={onClickOpenActivityModal}
                unit={unit}
              />
            )
          })
        ) : (
          <h1>No results.</h1>
        )}
      </div>
    )
  }
}
