import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import LoginLayout from '../LoginLayout'
import LoginForm from './LoginForm'
import redirectToHome from '../redirectToHome'
import {
  MaintenanceBanner,
  SessionExpiredModal,
  ScormLogoutBanner,
} from '@/components'
@inject('sessionStore', 'uiStore')
@redirectToHome()
@observer
export default class LoginPage extends Component {
  state = { error: null, isLoading: false, sessionExpired: null }
  componentDidMount() {
    const { location, sessionStore, uiStore } = this.props
    const { search } = location
    const querystring = qs.parse(search)
    const { sessionExpired, underMaintenance } = querystring
    uiStore.updateTitle('Login Page')
    if (underMaintenance || process.env.UNDER_MAINTENANCE) {
      sessionStore.setMaintenanceStatus(true)
    }
    if (sessionExpired) this.setState({ sessionExpired })
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps
    const { search } = location
    const { search: prevSearch } = prevLocation
    const querystring = qs.parse(search)
    const prevQuerystring = qs.parse(prevSearch)
    const { sessionExpired } = querystring
    const { sessionExpired: prevSessionExpired } = prevQuerystring
    if (!prevSessionExpired && sessionExpired) this.setState({ sessionExpired })
  }
  login = (username, password) => {
    const { sessionStore, history } = this.props
    this.setState({ isLoading: true, error: null })

    const { ldapKey } = this.props.match.params
    const { orgAlias } = this.props.match.params

    this.setState({ isLoading: true })
    sessionStore
      .login({ username, password, orgAlias, ldapKey })
      .then(() => {
        const { session } = sessionStore
        sessionStore.cleanTemporaryUser()
        if (session) {
          history.push('/')
        }
      })
      .catch((error) => {
        if (parseInt(error.message, 10) === 423) {
          sessionStore.createTemporaryUser(username, password)
          history.push('/update-password')
        } else {
          this.setState({
            isLoading: false,
            error: error.message,
          })
        }
      })
  }
  onCloseSessionExpiredModal = () => {
    const { history } = this.props
    this.setState({ sessionExpired: null })
    history.push('/login')
  }
  // clear api error on form validation
  onFormValidate = () => {
    this.setState({ error: null })
  }
  render() {
    const { sessionStore } = this.props
    const { sessionExpired } = this.state
    const { isScorm, isUnderMaintenance } = sessionStore
    if (isUnderMaintenance) return <MaintenanceBanner />
    if (isScorm) return <ScormLogoutBanner />
    return (
      <LoginLayout className="LoginPage" isSignIn>
        <div className="title">Login</div>
        <LoginForm
          onSubmit={this.login}
          onValidate={this.onFormValidate}
          isLoading={this.state.isLoading}
          error={this.state.error}
        />
        {sessionExpired && (
          <SessionExpiredModal
            isOpen={!!sessionExpired}
            onClose={this.onCloseSessionExpiredModal}
          />
        )}
      </LoginLayout>
    )
  }
}
