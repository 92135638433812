import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import blueRocketImage from '@/assets/blue-rocket.png'
import yellowHornImage from '@/assets/yellow-horn.png'
import {
  Button,
  Heading,
  Loader,
  MemoryBoosterModal,
  Modal,
} from '@/components'
import './MemoryBoosterPanel.scss'

const MemoryBoosterPanelMessages = defineMessages({
  completeDescription: {
    defaultMessage:
      'Excellent job! Keep up the great work and come back for more.',
    description:
      'Description for when all currently available memory boosters have been answered.',
    id: 'MemoryBoosterPanel.completeDescription',
  },
  completeHeader: {
    defaultMessage: 'You completed all your current Memory Boosters!',
    description:
      'Label for when all currently available memory boosters have been answered.',
    id: 'MemoryBoosterPanel.completeHeader',
  },
  emptyDescription: {
    defaultMessage:
      'Memory Boosters help improve your performance score and knowledge retention. They become available as you complete Sections.',
    description: 'Description for when there are no available memory boosters.',
    id: 'MemoryBoosterPanel.emptyDescription',
  },
  emptyHeader: {
    defaultMessage: 'You have no Memory Boosters available at the moment.',
    description: 'Label for when there are no available memory boosters.',
    id: 'MemoryBoosterPanel.emptyHeader',
  },
  listDescription: {
    defaultMessage:
      'Practice with Memory Boosters to improve your performance score and knowledge retention.',
    description:
      'Description for memory boosters when there are sections with memory boosters available.',
    id: 'MemoryBoosterPanel.listDescription',
  },
  listLabel: {
    defaultMessage: 'Memory Boosters Completed: {numMemoryBoostersCompleted}',
    description:
      'Label for memory boosters when there are sections with memory boosters available.',
    id: 'MemoryBoosterPanel.listLabel',
  },
  listNotice: {
    defaultMessage: 'Click a link below to launch one',
    description:
      'Label to make the user aware that the memory booster section list items are clickable.',
    id: 'MemoryBoosterPanel.listNotice',
  },
  sectionLastReviewed: {
    defaultMessage: 'Last reviewed: {lastAnsweredMemoryBoosterSection}',
    description:
      'Label for the date when a memory booster from the given section was last answered.',
    id: 'MemoryBoosterPanel.sectionLastReviewed',
  },
  title: {
    defaultMessage: 'Memory Boosters',
    description: 'Title for the memory booster panel.',
    id: 'MemoryBoosterPanel.title',
  },
  closeMemoryBoosterModal: {
    defaultMessage: 'Close Memory Booster Modal',
    description: 'ARIA label for the header to close the memory booster modal.',
    id: 'MemoryBoosterPanel.closeMemoryBoosterModal',
  },
  openMemoryBoosterModal: {
    defaultMessage: 'Open Memory Booster Modal',
    description: 'ARIA label for the header to open the memory booster modal.',
    id: 'MemoryBoosterPanel.openMemoryBoosterModal',
  },
  closeMemoryBoosterPanel: {
    defaultMessage: 'Collapse Memory Booster Panel',
    description:
      'ARIA label for the header to collapse the memory booster panel.',
    id: 'MemoryBoosterPanel.closeMemoryBoosterPanel',
  },
  openMemoryBoosterPanel: {
    defaultMessage: 'Expand Memory Booster Panel',
    description:
      'ARIA label for the header to expand the memory booster panel.',
    id: 'MemoryBoosterPanel.openMemoryBoosterPanel',
  },
})

@injectIntl
@inject('contentStore', 'uiStore')
@observer
export default class MemoryBoosterPanel extends Component {
  get memoryBoostersAvailable() {
    let memoryBoostersAvailable = 0
    this.memoryBoostersByUnit.forEach((unit) => {
      const { sections } = unit
      memoryBoostersAvailable += sections.length
    })
    return memoryBoostersAvailable
  }
  get memoryBoostersByUnit() {
    const { contentStore } = this.props
    const { memoryBoosters } = contentStore
    const { units: memoryBoostersByUnit } = memoryBoosters
    return memoryBoostersByUnit || []
  }
  componentDidMount() {
    const { contentStore } = this.props
    contentStore.loadMemoryBoosters(true)
  }
  onCloseMemoryBooster = () => {
    const { uiStore } = this.props
    uiStore.closeMemoryBoosterModal()
  }
  onCloseModal = () => {
    const { onClose, uiStore } = this.props
    if (onClose) {
      onClose()
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  onLoadMemoryBooster = ({ sectionId, unitId }) => {
    const { uiStore } = this.props
    uiStore.openMemoryBoosterModal({ sectionId, unitId })
  }
  onToggle = () => {
    const { isOpen, isSidebarToggled, onToggle } = this.props
    if (onToggle) {
      if (!isOpen && isSidebarToggled) {
        // NOTE: this is for ADA, need to reset display from none
        // the timeout allows for the CSS transition to function
        this.$refMain.style.display = ''
        setTimeout(() => {
          onToggle()
        }, 100)
      } else {
        onToggle()
      }
    }
  }
  hideMain = () => {
    const { isOpen, isSidebarToggled } = this.props
    if (!isOpen || !isSidebarToggled) {
      // NOTE: this is for ADA, need to reset display none for screenreaders
      this.$refMain.style.display = 'none'
    }
  }
  setRefMain = (el) => {
    this.$refMain = el
  }
  renderComplete() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="MemoryBoosterPanel-complete">
        <img
          alt=""
          className="MemoryBoosterPanel-image"
          src={yellowHornImage}
        />
        <h2 className="MemoryBoosterPanel-subtitle">
          {formatMessage(MemoryBoosterPanelMessages.completeHeader)}
        </h2>
        <p className="MemoryBoosterPanel-description">
          {formatMessage(MemoryBoosterPanelMessages.completeDescription)}
        </p>
      </div>
    )
  }
  renderList() {
    const { contentStore, intl } = this.props
    const { memoryBoosters } = contentStore
    const { formatMessage } = intl
    const { finishedMemoryBoosters } = memoryBoosters
    return (
      <div className="MemoryBoosterPanel-list">
        <div className="MemoryBoosterPanel-listDescription">
          <p>{formatMessage(MemoryBoosterPanelMessages.listDescription)}</p>
          <span className="MemoryBoosterPanel-listNotice">
            {formatMessage(MemoryBoosterPanelMessages.listNotice)}
          </span>
          <span className="MemoryBoosterPanel-listCompleted">
            <FormattedMessage
              {...MemoryBoosterPanelMessages.listLabel}
              values={{
                numMemoryBoostersCompleted: (
                  <span className="is-highlight">
                    {finishedMemoryBoosters || 0}
                  </span>
                ),
              }}
            />
          </span>
        </div>
        <div className="MemoryBoosterPanel-lineBreak" />
        <ul className="MemoryBoosterPanel-listItems">
          {this.memoryBoostersByUnit.map((unit) => {
            const { sections, unitId } = unit
            return (
              <Fragment key={`MemoryBoosterPanel-units-${unitId}`}>
                {sections.map((section) => {
                  const {
                    lastAnswered: lastAnsweredMemoryBoosterSection,
                    sectionId,
                    sectionTitle,
                  } = section
                  return (
                    <li
                      className="MemoryBoosterPanel-listItem"
                      key={`MemoryBoosterPanel-section-${sectionId}`}
                    >
                      <Button
                        className="MemoryBoosterPanel-section"
                        isText
                        onClick={() =>
                          this.onLoadMemoryBooster({ sectionId, unitId })
                        }
                      >
                        <h2 className="MemoryBoosterPanel-memoryBoosterTitle">
                          {sectionTitle}
                        </h2>
                        <span>
                          {formatMessage(
                            MemoryBoosterPanelMessages.sectionLastReviewed,
                            {
                              lastAnsweredMemoryBoosterSection,
                            },
                          )}
                        </span>
                        <img
                          className="MemoryBoosterPanel-section__rocket"
                          src={blueRocketImage}
                        />
                      </Button>
                    </li>
                  )
                })}
              </Fragment>
            )
          })}
        </ul>
      </div>
    )
  }
  renderEmpty() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="MemoryBoosterPanel-empty">
        <img
          alt=""
          className="MemoryBoosterPanel-image"
          src={blueRocketImage}
        />
        <h2 className="MemoryBoosterPanel-subtitle">
          {formatMessage(MemoryBoosterPanelMessages.emptyHeader)}
        </h2>
        <p className="MemoryBoosterPanel-description">
          {formatMessage(MemoryBoosterPanelMessages.emptyDescription)}
        </p>
      </div>
    )
  }
  renderMain() {
    const { contentStore } = this.props
    const { isLoadingMemoryBoosters, memoryBoosters } = contentStore
    const { lastAnswered } = memoryBoosters
    if (isLoadingMemoryBoosters)
      return <Loader className="MemoryBoosterPanel-loader" />
    if (!this.memoryBoostersByUnit.length) {
      if (!lastAnswered) {
        return this.renderEmpty()
      } else {
        return this.renderComplete()
      }
    }
    return this.renderList()
  }
  renderMemoryBooster() {
    const { uiStore } = this.props
    const { memoryBoosterModal } = uiStore
    const {
      isOpen: isOpenMemoryBoosterModal,
      sectionId: memoryBoosterSectionId,
      unitId: memoryBoosterUnitId,
    } = memoryBoosterModal
    return (
      <MemoryBoosterModal
        onClose={this.onCloseMemoryBooster}
        isOpen={isOpenMemoryBoosterModal}
        memoryBoosterSectionId={memoryBoosterSectionId}
        memoryBoosterUnitId={memoryBoosterUnitId}
      />
    )
  }
  renderModal() {
    const { intl, isOpen, onClose, uiStore } = this.props
    const { formatMessage } = intl
    const { memoryBoosterModal } = uiStore
    const { isOpen: isOpenMemoryBoosterModal } = memoryBoosterModal
    return (
      <Fragment>
        {!isOpenMemoryBoosterModal ? (
          <Modal
            className="MemoryBoosterPanelModal"
            isCard
            isOpen={isOpen}
            onClose={onClose}
          >
            <Modal.Header>
              <Heading>
                {formatMessage(MemoryBoosterPanelMessages.title)}
              </Heading>
            </Modal.Header>
            <Modal.Body>{this.renderMain()}</Modal.Body>
          </Modal>
        ) : (
          this.renderMemoryBooster()
        )}
      </Fragment>
    )
  }
  render() {
    const { contentStore, intl, isModal, isOpen, isSidebarToggled } = this.props
    const { memoryBoosters } = contentStore
    const { formatMessage } = intl
    if (isModal) return this.renderModal()
    return (
      <Fragment>
        <div
          className={cx('MemoryBoosterPanel', {
            'is-active': isOpen,
          })}
          id="MemoryBoosterPanel"
        >
          {isSidebarToggled ? (
            <Button
              aria-controls="MemoryBoosterPanel"
              aria-expanded={isOpen}
              aria-label={
                isOpen
                  ? formatMessage(
                      MemoryBoosterPanelMessages.closeMemoryBoosterPanel,
                    )
                  : formatMessage(
                      MemoryBoosterPanelMessages.openMemoryBoosterPanel,
                    )
              }
              className="MemoryBoosterPanel__header"
              isText
              onClick={this.onToggle}
            >
              <span className="MemoryBoosterPanel__header-text">
                <h1>{formatMessage(MemoryBoosterPanelMessages.title)}</h1>
                {memoryBoosters && this.memoryBoostersAvailable > 0 ? (
                  <span className="MemoryBoosterPanel__header-count is-tablet">
                    {this.memoryBoostersAvailable}
                  </span>
                ) : null}
                <i
                  aria-hidden="true"
                  className={cx('MemoryBoosterPanel__header-icon is-desktop', {
                    'fa fa-angle-up': !isOpen,
                    'fa fa-angle-down': isOpen,
                  })}
                />
              </span>
            </Button>
          ) : (
            <Button
              aria-label={
                isOpen
                  ? formatMessage(
                      MemoryBoosterPanelMessages.closeMemoryBoosterModal,
                    )
                  : formatMessage(
                      MemoryBoosterPanelMessages.openMemoryBoosterModal,
                    )
              }
              className="MemoryBoosterPanel__header MemoryBoosterPanel__header--is-small"
              isText
              onClick={this.onToggle}
            >
              <span className="MemoryBoosterPanel__header-rocket">
                <i className="MemoryBoosterPanel__header-icon fa fa-rocket" />
              </span>
            </Button>
          )}
          <div
            className="MemoryBoosterPanel-main"
            onTransitionEnd={this.hideMain}
            ref={this.setRefMain}
            style={{ display: 'none' }}
          >
            {this.renderMain()}
          </div>
        </div>
      </Fragment>
    )
  }
}
