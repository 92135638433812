import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import './Counter.scss'

class Counter extends Component {
  static defaultProps = {
    duration: 500, // ms
    onComplete: null,
    suffix: '',
    value: 0,
  }
  constructor(props) {
    super(props)
    const { value } = props
    this.state = {
      prevValue: value,
      value,
    }
  }
  calculateFreq() {
    const { duration, value } = this.props
    const { prevValue } = this.state
    this.increasing = null
    if (prevValue < value) {
      this.freq = Math.round(duration / (value - prevValue))
      this.increasing = true
    } else if (prevValue > value) {
      this.freq = Math.round(duration / (prevValue - value))
      this.increasing = false
    }
  }
  componentDidUpdate(prevProps) {
    const { value } = this.props
    const { value: prevValue } = prevProps
    if (value === null || isNaN(value)) return
    if (prevValue !== value) {
      this.setState({ prevValue }, () => {
        this.calculateFreq()
        if (this.freq) {
          this.start()
        }
      })
    }
  }
  checkCompletion = () => {
    const { value: end } = this.props
    const { value } = this.state
    if (this.increasing && value >= end) {
      this.stop()
      this.onComplete()
    } else if (this.increasing === false && value <= end) {
      this.stop()
      this.onComplete()
    }
  }
  componentWillUnmount() {
    this.stop()
  }
  start = () => {
    const { value } = this.props
    const { prevValue } = this.state
    if (this.interval) {
      this.stop()
    }
    const diff = Math.abs(value - prevValue)
    let i = 1
    if (diff >= 50 && diff % 2 === 0) {
      i = 2
      this.freq *= 2
    }
    this.interval = setInterval(
      this.increasing ? this.increment(i) : this.increment(-i),
      this.freq,
    )
  }
  stop = () => {
    clearInterval(this.interval)
    this.interval = null
  }
  increment = (i) => {
    return () => {
      const { value } = this.state
      this.checkCompletion()
      if (this.interval) {
        let newValue = value + i >= 0 ? value + i : 0
        if (newValue > 100) {
          newValue = 100
        }
        this.setState({ value: newValue })
      }
    }
  }
  onComplete = () => {
    const { onComplete } = this.props
    if (onComplete) {
      onComplete()
    }
  }
  render() {
    const { className, suffix } = this.props
    const { value } = this.state
    return (
      <div className={cx('Counter', className)}>
        {value}
        {suffix && <span>{suffix}</span>}
      </div>
    )
  }
}

Counter.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number, // ms
  end: PropTypes.number,
  onComplete: PropTypes.func,
  value: PropTypes.number.isRequired,
  suffix: PropTypes.string,
}

export default Counter
