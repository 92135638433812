import { observable, computed } from 'mobx'
import _ from 'lodash'
import moment from 'moment'

import { toCamelCase, timestamp } from '@/utils/helpers'

import User from './user'

// Note: for some reason, messages don't have a created prop
export default class Message {
  @observable id
  @observable author
  @observable message
  @observable modified
  @observable readStatus

  constructor(data = {}) {
    this.init(data)
    this.id = data.id ? data.id : _.uniqueId('message_')
    this.modified = moment(data.modified)

    if (!(data.author instanceof User)) {
      this.author = new User(data.author)
    }
    // current user
    if (data.user && !(data.user instanceof User)) {
      this.user = new User(data.user)
    }
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  // formatted modified
  @computed get fmodified() {
    return timestamp(this.modified)
  }

  @computed get isMine() {
    return this.author.id === this.user.id
  }

  @computed get displayName() {
    return this.isMine ? 'You' : this.author.firstName
  }
}
