import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { VIDEO_TYPES } from '@/constants'
import './Video.scss'
const VideoMessages = defineMessages({
  noVideo: {
    defaultMessage: 'No Video',
    description: 'Description displayed when no video is available.',
    id: 'Video.noVideo',
  },
})
@injectIntl
export default class Video extends Component {
  getVideoEmbedUrl() {
    let regExpYoutube =
      /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/
    let regExpVimeo =
      /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/

    let match, url

    if (!this.props.type) return
    if (this.props.type === VIDEO_TYPES.VIMEO && !this.props.url) return
    if (this.props.type === VIDEO_TYPES.YOUTUBE && !this.props.url) return
    if (this.props.type === VIDEO_TYPES.KALTURA && !this.props.entryId) return
    if (this.props.type === VIDEO_TYPES.VIDEO && !this.props.entrypoint) return

    if (this.props.type === VIDEO_TYPES.YOUTUBE) {
      match = this.props.url.match(regExpYoutube)
      url =
        '//www.youtube.com/embed/' +
        (match && match[1].length === 11 ? match[1] : false)
    }

    if (this.props.type === VIDEO_TYPES.VIMEO) {
      match = this.props.url.match(regExpVimeo)
      url = '//player.vimeo.com/video/' + (match && match[4] ? match[4] : false)
    }

    if (
      this.props.type === VIDEO_TYPES.KALTURA ||
      this.props.type === VIDEO_TYPES.KALTURA_VIDEO
    ) {
      url = `//www.kaltura.com/p/${process.env.KALTURA_PARTNER_ID}/sp/${process.env.KALTURA_PARTNER_ID}00/embedIframeJs/uiconf_id/${process.env.KALTURA_UICONF_ID}/partner_id/${process.env.KALTURA_PARTNER_ID}?iframeembed=true&playerId=kaltura&entry_id=${this.props.entryId}&flashvars[sourceSelector.plugin]=true&flashvars[sourceSelector.switchOnResize]=true&flashvars[sourceSelector.simpleFormat]=true&flashvars[sourceSelector.displayMode]=size&flashvars[playbackRateSelector.position]=after&flashvars[playbackRateSelector.defaultSpeed]=1&flashvars[playbackRateSelector.speeds]=.5,1,1.25,1.5,2`
    }

    if (this.props.type === VIDEO_TYPES.VIDEO) {
      // NOTE: currently only supports URL as entrypoint
      // once video_entry Entry supports multiple types,
      // entrypoint will be an ID or URL and should be handled
      // based off the secondary type field
      url = this.props.entrypoint
    }

    return url
  }

  render() {
    const { intl } = this.props
    const videoUrl = this.getVideoEmbedUrl()
    const { formatMessage } = intl
    if (!videoUrl) {
      return <div className="Video">{formatMessage(VideoMessages.noVideo)}</div>
    }
    return (
      <div className="Video">
        <iframe
          allowFullScreen
          className="Video-frame"
          frameBorder="0"
          src={videoUrl}
        />
      </div>
    )
  }
}
