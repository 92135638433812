import React from 'react'
import ReactTooltip from 'rc-tooltip'
import './HoverTooltip.scss'
import 'rc-tooltip/assets/bootstrap.css'

const HoverTooltip = (props) => {
  return (
    <ReactTooltip
      getTooltipContainer={props.getTooltipContainer}
      id={props.id}
      overlay={<div className="HoverTooltip-text">{props.text}</div>}
      placement={props.placement || 'bottom'}
      trigger={props.trigger || ['hover']}
    >
      {props.children}
    </ReactTooltip>
  )
}

export default HoverTooltip
