import React from 'react'
import cx from 'classnames'
import { Heading } from '@/components'

import './PageCard.scss'

const PageCard = (props) => {
  const className = cx('PageCard', props.className)

  return (
    <div id={props.id || ''} className="container" qa-id={'learner-page-card'}>
      <div className={className}>
        <div className="PageCard-head">
          <Heading className="title">{props.title}</Heading>
        </div>
        {props.children}
      </div>
    </div>
  )
}

export const PageCardBody = (props) => {
  return (
    <div className={cx('PageCard-body', props.className)}>
      <div className="PageCard-container">{props.children}</div>
    </div>
  )
}

export const PageCardFoot = (props) => {
  return (
    <div className={cx('PageCard-foot', props.className)}>
      <div className="PageCard-container">{props.children}</div>
    </div>
  )
}

export default PageCard
