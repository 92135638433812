import React, { Component } from 'react'

export default function redirectToHome() {
  return (Wrapped) =>
    class PageWrapper extends Component {
      componentDidMount() {
        const { history, sessionStore } = this.props
        const { isLoggedIn } = sessionStore || {}
        if (isLoggedIn) return history.replace('/')
      }

      render() {
        return <Wrapped {...this.props} />
      }
    }
}
