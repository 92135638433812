import React from 'react'
import cx from 'classnames'

import './Spot.scss'

const Spot = (props) => {
  const className = cx(
    'Spot',
    {
      'is-small': props.isSmall,
      'is-warning': props.isWarning,
      'is-success': props.isSuccess,
      'is-info': props.isInfo,
      'is-orange': props.isOrange,
      'in-progress': props.inProgress,
      'has-label': props.label,
      'align-right': props.alignRight,
    },
    props.className,
  )

  return (
    <div className={className}>
      {props.label && props.alignRight && (
        <div className="Spot-label">{props.label}</div>
      )}
      <div className="Spot-glyph" />
      {props.label && !props.alignRight && (
        <div className="Spot-label">{props.label}</div>
      )}
    </div>
  )
}

export default Spot
