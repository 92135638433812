import cx from 'classnames'
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import CoursePickerPage from '@/pages/CoursePickerPage'
import {
  // NOTE: temp disabled - https://headware4.jira.com/browse/FM-4463
  // Button,
  CourseSettingsError,
  Header,
  Loader,
  MobileNavMenu,
  SkipNavigation,
  Snapshot,
} from '@/components'
import StudentLayoutSidebar from './StudentLayoutSidebar'
import './StudentLayout.scss'

const IGNORE_ERROR_SETTINGS_PATHNAMES = ['/courses', '/messages']

class StudentLayoutContent extends Component {
  render() {
    const { children, className } = this.props
    return (
      <div className={cx('StudentLayoutContent', className)}>{children}</div>
    )
  }
}

class StudentLayoutPage extends Component {
  render() {
    const { children, className, setRef } = this.props
    return (
      <div className={cx('StudentLayoutPage', className)}>
        <div
          className="StudentLayoutPage__scroll"
          ref={(el) => {
            if (setRef) setRef(el)
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

@withRouter
// NOTE / TEMP / TODO / WIP - uiStore will be used if we need to get rid of the IE11 scrollbar
// @inject('contentStore', 'sessionStore', 'uiStore')
// NOTE / TEMP / TODO / WIP - reportinStore will be reimplemented if performance meter is returned to sidebar
// @inject('contentStore', 'reportingStore', 'sessionStore')
@inject('contentStore', 'sessionStore')
@observer
class StudentLayout extends Component {
  static Content = StudentLayoutContent
  static Page = StudentLayoutPage
  static Sidebar = StudentLayoutSidebar
  get isErrorSettingsIgnored() {
    const { location } = this.props
    const { pathname } = location
    let willIgnore = false
    IGNORE_ERROR_SETTINGS_PATHNAMES.forEach((exception) => {
      let regex = new RegExp(`^${exception}`)
      if (pathname.match(regex)) {
        willIgnore = true
      }
    })
    return willIgnore
  }
  componentDidMount() {
    const {
      contentStore,
      // NOTE / TEMP / TODO / WIP - will reimplement if performance meter is returned to sidebar
      // reportingStore,
      sessionStore,
      // NOTE / TEMP / TODO / WIP - uiStore will be used if we need to get rid of the IE11 scrollbar
      // uiStore
    } = this.props
    const { session } = sessionStore
    const { hasMoreCourses, showCoursePicker } = session || {}
    if (!session) return null
    // NOTE / TEMP / TODO / WIP - uiStore will be used if we need to get rid of the IE11 scrollbar
    // uiStore.addClass(document.documentElement, 'is-student')
    const shouldShowCoursePicker = hasMoreCourses && showCoursePicker
    if (!shouldShowCoursePicker) {
      contentStore.initCourse()
    }
  }
  // NOTE / TEMP / TODO / WIP - uiStore will be used if we need to get rid of the IE11 scrollbar
  // componentWillUnmount () {
  //   const { uiStore } = this.props
  //   uiStore.removeClass(document.documentElement, 'is-student')
  //   uiStore.removeClass(document.body, 'is-student')
  // }
  renderMain() {
    const { children, contentStore, location, sessionStore } = this.props
    const {
      course,
      isErrorSettings,
      isInitializingCourse,
      isLoadingCourse,
      isLoadingCourseSettings,
      settings,
      snapshot,
    } = contentStore
    const { pathname } = location
    const { isLoggingOut, session } = sessionStore
    const { hasMoreCourses, showCoursePicker } = session || {}
    const { isDone: isDoneSnapshot, isOpen: isOpenSnapshot } = snapshot || {}
    const isCoursePage = pathname.match(/\/units/)
    const isCoursesPage = pathname.match(/\/courses/)
    const isDashboardPage = pathname.match(/\/dashboard/)
    const { isDisabledDashboard, isSnapshot, isSnapshotStandalone } =
      settings || {}
    const shouldRenderSidebar =
      (!isDisabledDashboard && (isCoursePage || isDashboardPage)) ||
      (isDisabledDashboard && isCoursePage)
    if (hasMoreCourses && showCoursePicker) {
      return (
        <StudentLayoutContent>
          <CoursePickerPage />
        </StudentLayoutContent>
      )
    }
    if (
      !this.isErrorSettingsIgnored &&
      isErrorSettings &&
      !isLoadingCourseSettings
    ) {
      return (
        <Fragment>
          <Header className="StudentLayout__header" />
          <CourseSettingsError />
          <MobileNavMenu />
        </Fragment>
      )
    } else if (
      !isInitializingCourse &&
      ((isSnapshot && (!isDoneSnapshot || isOpenSnapshot)) ||
        isSnapshotStandalone) &&
      !isCoursesPage
    ) {
      return (
        <Fragment>
          <Header className="StudentLayout__header" />
          <MobileNavMenu />
          {(isLoadingCourse || isLoadingCourseSettings) &&
          (!course || !settings) ? (
            <Loader className="StudentLayout__loader" />
          ) : (
            <Snapshot />
          )}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <SkipNavigation />
        <Header className="StudentLayout__header" />
        {isInitializingCourse ||
        isLoggingOut ||
        isLoadingCourse ||
        isLoadingCourseSettings ||
        !settings ||
        !course ? (
          <Loader className="StudentLayout__loader" />
        ) : (
          <Fragment>
            {shouldRenderSidebar ? <StudentLayoutSidebar /> : null}
            <StudentLayoutContent>{children}</StudentLayoutContent>
          </Fragment>
        )}
        <MobileNavMenu />
      </Fragment>
    )
  }
  render() {
    return (
      <div className="StudentLayout" qa-id="learner-app-layout">
        {this.renderMain()}
      </div>
    )
  }
}

export default StudentLayout
