import cx from 'classnames'
import React, { Component } from 'react'
import { Button, Heading } from '@/components'
import { NOTIFICATION_ACTION_TITLE_MAPPING } from '@/constants'
import './AdminNotificationDetailsPage.scss'
export default class AdminNotificationDetailsPage extends Component {
  onClickBack = () => {
    const { onClickBack } = this.props
    if (onClickBack) {
      onClickBack()
    }
  }
  renderErrors = () => {
    const { notification } = this.props
    const { response } = notification
    const { errors: responseErrors } = response
    const errorMessages = {}
    const errorMessagesGlobal = []
    responseErrors.forEach((errorMessage) => {
      if (typeof errorMessage === 'string') {
        errorMessagesGlobal.push(errorMessage)
      } else {
        let { exception, rowIndex, username } = errorMessage
        let { instructor, instructorRowIndex, message } = exception
        if (!errorMessages[rowIndex]) {
          errorMessages[rowIndex] = { errors: [], username }
        }
        if (instructor) {
          errorMessages[rowIndex].errors.push({
            instructor: {
              message:
                typeof message === 'string' ? { default: message } : message,
              rowIndex: instructorRowIndex,
            },
          })
        } else {
          errorMessages[rowIndex].errors.push(message)
        }
      }
    })
    return (
      <div className="AdminNotificationDetailsPage-errors">
        <Heading is3>
          The following issues must be resolved in order to successfully add
          bulk users:
        </Heading>
        {errorMessagesGlobal.map((errorMessageGlobal, index) => (
          <div
            className="AdminNotificationDetailsPage-errorsRow"
            key={`AdminNotificationDetailsPage__errorMessagesGlobal-${index}`}
          >
            <span>{errorMessageGlobal}</span>
          </div>
        ))}
        {Object.keys(errorMessages)
          .sort((a, b) => parseInt(a) - parseInt(b))
          .map((errorMessageIndex) => {
            let errorMessage = errorMessages[errorMessageIndex]
            let { errors, username } = errorMessage
            return (
              <div
                className="AdminNotificationDetailsPage-errorsRow"
                key={`AdminNotificationDetailsPage-errorsRow-${errorMessageIndex}`}
              >
                <Heading
                  className="AdminNotificationDetailsPage-errorsRowIndex"
                  is4
                >{`Row: ${errorMessageIndex}`}</Heading>
                <span className="AdminNotificationDetailsPage-errorsRowUsername">{`Username: ${username}`}</span>
                <div className="AdminNotificationDetailsPage-errorsRowList">
                  <Heading is4>Errors:</Heading>
                  {errors.map((error) => {
                    if (error.$mobx && error.$mobx.array) {
                      return error.map((error, index) => (
                        <div
                          key={`AdminNotificationDetailsPage__error-${errorMessageIndex}-${index}`}
                        >
                          <span>{`- ${error}`}</span>
                        </div>
                      ))
                    }
                    return Object.keys(error).map((key, index) => {
                      let keyFormatted = key
                        .split('_')
                        .map(
                          (str) =>
                            `${str.charAt(0).toUpperCase()}${str.slice(1)}`,
                        )
                        .join(' ')
                      if (key === 'instructor' && error[key]) {
                        let instructorError = error[key]
                        let { message, rowIndex: instructorRowIndex } =
                          instructorError
                        let instructorErrorString = `${keyFormatted}: ${
                          message['default']
                            ? message['default']
                            : `There was an issue with the instructor${
                                instructorRowIndex
                                  ? `, on row ${instructorRowIndex},`
                                  : ''
                              } assigned to the user.`
                        }`
                        if (
                          instructorErrorString.charAt(
                            instructorErrorString.length - 1,
                          ) !== '.'
                        ) {
                          instructorErrorString += '.'
                        }
                        return (
                          <div
                            key={`AdminNotificationDetailsPage__error-${index}`}
                          >
                            <span>{instructorErrorString}</span>
                          </div>
                        )
                      }
                      return (
                        <div
                          key={`AdminNotificationDetailsPage__error-${index}`}
                        >
                          <span>{`${keyFormatted}: ${error[key]}`}</span>
                        </div>
                      )
                    })
                  })}
                </div>
              </div>
            )
          })}
      </div>
    )
  }
  renderProcessing = () => {
    return (
      <div className="AdminNotificationDetailsPage-processing">
        <Heading is3>Task is still processing...</Heading>
      </div>
    )
  }
  renderResults = () => {
    const { notification } = this.props
    const { response } = notification
    const { alreadyExisting, usersAdded, usersAssigned } = response
    const alreadyExistText =
      alreadyExisting > 1
        ? `${alreadyExisting} users already exist`
        : alreadyExisting > 0
        ? `${alreadyExisting} user already exists`
        : ''
    const usersAddedText =
      usersAdded > 1
        ? `${usersAdded} users have`
        : usersAdded > 0
        ? `${usersAdded} user has`
        : ''
    const usersAssignedText =
      usersAssigned > 1
        ? `${usersAssigned} existing users have`
        : usersAssigned > 0
        ? `${usersAssigned} existing user has`
        : ''
    return (
      <div className="AdminNotificationDetailsPage-results">
        <Heading is3>
          The bulk upload process was completed with the following results:
        </Heading>
        <div className="AdminNotificationDetailsPage-resultsDetails">
          {alreadyExistText ? <p>{`${alreadyExistText}.`}</p> : null}
          {usersAddedText ? <p>{`${usersAddedText} been created.`}</p> : null}
          {usersAssignedText ? (
            <p>{`${usersAssignedText} been assigned.`}</p>
          ) : null}
        </div>
      </div>
    )
  }
  render() {
    const { notification } = this.props
    const { action, addedAt, completed, response } = notification
    const { errors } = response || {}
    return (
      <div
        className={cx('AdminNotificationDetailsPage', {
          'is-error': !!errors,
          'is-success': completed && !errors,
        })}
      >
        <div className="AdminNotificationDetailsPage-back">
          <Button onClick={this.onClickBack}>&lt; Back</Button>
        </div>
        <div className="AdminNotificationDetailsPage-content">
          <div className="AdminNotificationDetailsPage-header">
            <Heading is2>{NOTIFICATION_ACTION_TITLE_MAPPING[action]}</Heading>
            <div className="AdminNotificationDetailsPage-details">
              <span className="is-column">
                <span className="is-bold">Started:&nbsp;</span>
                <span className="is-block">{addedAt}&nbsp;</span>
              </span>
              <span className="is-column">
                <span className="is-bold">Completed:&nbsp;</span>
                <span className="is-block">{completed || 'N/A'}&nbsp;</span>
              </span>
              <span className="is-column">
                <span className="is-bold">Status:&nbsp;</span>
                <span className="is-block">
                  {!completed ? 'Processing' : errors ? 'Error' : 'Success'}
                </span>
              </span>
            </div>
          </div>
          <div className="AdminNotificationDetailsPage-response">
            {!completed
              ? this.renderProcessing()
              : errors
              ? this.renderErrors()
              : this.renderResults()}
          </div>
        </div>
      </div>
    )
  }
}
