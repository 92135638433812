import React, { Fragment } from 'react'

import './HorizontalStackedBar.scss'

const classNames = {
  'all-star': 'is-success',
  good: 'is-info',
  'at-risk': 'is-danger',
  'is-empty-1': 'is-grey-1',
  'is-empty-2': 'is-grey-2',
  'is-empty-3': 'is-grey-3',
}

const HorizontalStackedBar = (props) => {
  const { data } = props
  return (
    <Fragment>
      <div className="HorizontalStackedBar">
        {data.map((n, i) => {
          return (
            <div
              key={`HorizontalStackedBar-${i}`}
              className={classNames[n.rank]}
              style={{ width: `${n.value}%` }}
            />
          )
        })}
      </div>
      <div className="HorizontalStackedBar-labels">
        {data.map((n, i) => {
          let marginTop = 0
          if (i > 0 && n.value <= 20 && data[i - 1].value <= 35) {
            marginTop = '-72px'
          }
          return !n.rank.includes('is-empty') ? (
            <div
              key={`HorizontalStackedBar-label-${i}`}
              style={{ width: `${n.value}%`, marginTop: marginTop }}
            >
              {`${n.value}%`}{' '}
              <span
                style={{
                  display: 'block',
                  height: '1px',
                  opacity: 0,
                  width: '1px',
                }}
              >
                {n.rank}
              </span>
            </div>
          ) : null
        })}
      </div>
    </Fragment>
  )
}

export default HorizontalStackedBar
