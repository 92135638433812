import * as Sentry from '@sentry/react'

import { ENV, IS_DEV, NODE_ENV, RELEASE_NAME, SENTRY_KEY } from '@/constants'

function _scrubString(str) {
  const length = str.length
  if (length <= 2) return str

  const padding = []
  const prefix = str.slice(0, 2)
  const suffix = length >= 5 ? str.slice(length - 2) : ''
  const paddingAmount = length - prefix.length - suffix.length
  while (paddingAmount && padding.length < paddingAmount) {
    padding.push('*')
  }
  return `${prefix}${padding.join('')}${suffix}`
}

function _scrubEmail(username) {
  const splitUsername = username.split('@')
  const isEmail = splitUsername.length === 2
  if (isEmail) {
    return `${_scrubString(splitUsername[0])}@${splitUsername[1]}`
  }
  return username
}

class SentryReplay {
  levels = ['fatal', 'critical', 'error', 'warning', 'log', 'info', 'debug']

  replay = new Sentry.Replay({
    blockAllMedia: false,
    maskAllText: false,
    maskAllInputs: false,
  })

  is_enabled = !IS_DEV && SENTRY_KEY && SENTRY_KEY !== ''

  constructor() {
    if (this.is_enabled) {
      Sentry.init({
        dsn: SENTRY_KEY,
        environment: ENV && ENV !== '' ? ENV : NODE_ENV,

        // https://docs.sentry.io/platforms/javascript/configuration/filtering/
        ignoreErrors: [
          'Request failed with status code 401',
          'Request failed with status code 406',
          // 'timeout of 50000ms exceeded',
          // 'Network Error',
        ],

        integrations: [
          new Sentry.Integrations.Breadcrumbs({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            sentry: true,
            xhr: true,
          }),
          new Sentry.Integrations.GlobalHandlers({
            onerror: false,
            onunhandledrejection: true,
          }),
          this.replay,
        ],

        release: RELEASE_NAME,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.01,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      })
    }
  }

  addBreadcrumb({ category, data, message, level, type }) {
    if (!this.is_enabled) return
    Sentry.addBreadcrumb({
      category,
      data,
      message,
      level,
      type,
    })
  }
  captureException(error, level = 'error', tags = {}) {
    if (!this.is_enabled) return
    Sentry.withScope(
      function (scope) {
        // NOTE:
        // Currently does not seem to change within
        // the Sentry UI, and is fixed as 'fatal'.
        scope.setLevel(level)
        this.setTags(tags, scope)
        Sentry.captureException(error)
      }.bind(this),
    )
  }
  captureMessage(message, level = 'error', tags = {}) {
    if (!this.is_enabled) return
    Sentry.withScope(
      function (scope) {
        this.setTags(tags, scope)
        Sentry.captureMessage(message, level)
      }.bind(this),
    )
  }
  setLevel(level, scope) {
    if (!this.is_enabled) return
    if (!scope || !level || this.levels.indexOf(level) === -1) return
    scope.setLevel(level)
  }
  setTags(tags = {}, scope = null) {
    if (!this.is_enabled) return
    Object.keys(tags)
      .filter((key) => tags[key] && tags[key] !== '')
      .forEach((key) => {
        const s = scope || Sentry
        s.setTag(key, tags[key])
      })
  }
  resetUser() {
    if (!this.is_enabled) return
    Sentry.setUser(null)
  }
  setUser(userId, username) {
    if (!this.is_enabled) return
    Sentry.setUser({ email: _scrubEmail(username), id: userId })
  }
}

export default new SentryReplay()
