import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import './CourseSettingsError.scss'

const CourseSettingsErrorMessages = defineMessages({
  description: {
    defaultMessage: 'Please check again at a later time.',
    description:
      'Description displayed on the course settings error page when the course has not started or is not ready.',
    id: 'CourseSettingsError.description',
  },
  title: {
    defaultMessage: 'The course you are trying to access is not ready yet.',
    description:
      'Title displayed on the course settings error page when the course has not started or is not ready.',
    id: 'CourseSettingsError.title',
  },
})

@injectIntl
export default class CourseSettingsError extends Component {
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="CourseSettingsError">
        <h1>{formatMessage(CourseSettingsErrorMessages.title)}</h1>
        <p>{formatMessage(CourseSettingsErrorMessages.description)}</p>
      </div>
    )
  }
}
