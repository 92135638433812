import React, { Component } from 'react'
import { withRouter } from 'react-router'
import url from 'url'

import { RichTextWrapper } from '@/components'

import './VideoDescription.scss'

@withRouter
class VideoDescription extends Component {
  get key() {
    const { match } = this.props
    const { params } = match
    const { sectionId, unitId } = params
    return `VideoDescription__${unitId}-${sectionId}`
  }
  onClickVideoLink = (href) => {
    const { history, match } = this.props
    const { params } = match
    const { sectionId, unitId } = params
    const { hash } = url.parse(href)
    history.push(`/units/${unitId}/sections/${sectionId}/read${hash}`)
  }
  onLoadIframe = () => {
    this.sendMessage({ action: 'handleVideoDescription' })
  }
  sendMessage = (message) => {
    if (!this.$iframe) return
    this.$iframe.contentWindow.postMessage(JSON.stringify(message), '*')
  }
  setRef = (el) => {
    this.$iframe = el
  }
  render() {
    const { innerHtml, loadingText, onOpenImageModal } = this.props
    return (
      <RichTextWrapper
        className="VideoDescription"
        content={innerHtml}
        id={this.key}
        key={this.key}
        loadingText={loadingText}
        onClickAnchor={this.onClickVideoLink}
        onLoad={this.onLoadIframe}
        onOpenImageModal={onOpenImageModal}
        setRef={this.setRef}
      />
    )
  }
}

export default VideoDescription
