import cx from 'classnames'
import React from 'react'
import './Label.scss'
const Label = (props) => {
  const { children, className, text, ...restProps } = props
  return (
    <label className={cx('Label', className)} {...restProps}>
      {text ? <span>{text}</span> : children}
    </label>
  )
}
export default Label
