import cx from 'classnames'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Dropdown, Link } from '@/components'
import './AdminHeader.scss'
@inject('uiStore')
@observer
export default class AdminHeader extends Component {
  toggleMobileNavMenu = () => {
    const { uiStore } = this.props
    uiStore.toggleMobileNavMenu()
  }
  render() {
    const {
      isAdminAndLearner,
      onClickSwitchPlatform,
      onLogout,
      pathname,
      shortName,
      uiStore,
    } = this.props
    const { showMobileNavMenu } = uiStore
    const isHome =
      pathname === '/' ||
      pathname === '/admin/courses' ||
      pathname === '/dashboard/learners'
    const isNotifications = pathname === '/admin/notifications'
    return (
      <div className="AdminHeader">
        <div className="Header-iconLinks">
          <div className="Header-iconLinkContainer Header-hideMobile">
            <Link
              alt="Home"
              aria-label="Go to the Home page"
              className={cx('Header-iconLink', {
                active: isHome,
              })}
              to="/"
            >
              <i className="fa fa-home" aria-hidden="true" title="Home" />
            </Link>
          </div>
          <div className="Header-iconLinkContainer Header-hideMobile">
            <Link
              alt="Notifications"
              aria-label="Go to the Notifications page"
              className={cx('Header-iconLink', {
                active: isNotifications,
              })}
              to="/admin/notifications"
            >
              <i className="fa fa-bell" />
            </Link>
          </div>
          <div className="Header-iconLinkContainer Header-hamburger">
            <Button
              aria-label={
                showMobileNavMenu
                  ? 'Close navigation menu'
                  : 'Open navigation menu'
              }
              className="Header-iconLink"
              isText
              onClick={this.toggleMobileNavMenu}
            >
              <i aria-hidden="true" className="fa fa-bars" />
            </Button>
          </div>
        </div>
        <div className="Header-metaContainer Header-hideMobile">
          <div
            className="Header-userContainer"
            qa-id="admin-header-dropdown-button"
          >
            <Dropdown ariaLabel="profile menu" label={shortName} isSimple>
              <Link to="/account">My profile</Link>
              {isAdminAndLearner ? (
                <Link
                  onClick={onClickSwitchPlatform}
                  to="/"
                  qa-id="admin-go-to-learner-link"
                >
                  Go to Learner
                </Link>
              ) : null}
              <Link to="#" onClick={onLogout}>
                Logout
              </Link>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }
}
