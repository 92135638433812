import React, { Component } from 'react'
import cx from 'classnames'
import { Heading, Modal } from '@/components'
import './ConfirmationModal.scss'

export default class ConfirmationModal extends Component {
  static defaultProps = {
    title: 'Confirmation Modal Title',
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  render() {
    const {
      children,
      className,
      confirmText,
      description,
      error,
      isCancelHidden,
      isDanger,
      isLoading,
      isOpen,
      onClose,
      onConfirm,
      title,
      prompt,
    } = this.props
    return (
      <Modal
        className={cx(
          'ConfirmationModal',
          {
            'is-error': !!error,
          },
          className,
        )}
        contentLabel={title}
        isDanger={isDanger}
        isOpen={isOpen}
        onClose={onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>{title}</Heading>
        </Modal.Header>
        <Modal.Body>
          {children ? (
            <div className="ConfirmationModal__body">{children}</div>
          ) : (
            <div className="ConfirmationModal__body">
              {prompt ? (
                <p className="ConfirmationModal__prompt">{prompt}</p>
              ) : null}
              {description ? (
                <p className="ConfirmationModal__description">{description}</p>
              ) : null}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isCancelHidden ? (
            <Modal.Button isSecondary onClick={this.onClose}>
              Cancel
            </Modal.Button>
          ) : null}
          <Modal.Button
            isDanger={isDanger}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            {confirmText || 'Confirm'}
          </Modal.Button>
          {error ? (
            <span className="ConfirmationModal__footer-error">{`* ${error}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}
