import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { CoursePicker } from '@/components'

@inject('contentStore', 'sessionStore', 'uiStore')
@observer
export default class CoursePickerPage extends Component {
  componentDidMount() {
    const { sessionStore, uiStore } = this.props
    sessionStore.loadCourses()

    uiStore.updateTitle('Course Picker Page')
  }

  switchCourse = (course) => {
    const { contentStore, history, location, sessionStore } = this.props
    const { courseId } = course
    sessionStore.switchCourse(courseId).then(() => {
      contentStore.initCourse()
      if (location) {
        const { pathname } = location
        if (pathname === '/courses') {
          history.push('/')
        }
      }
    })
  }

  render() {
    const { sessionStore } = this.props
    const { user, courses, isLoadingCourses, isSwitchingCourse } = sessionStore
    return (
      <div className="CoursePickerPage">
        <CoursePicker
          switchCourse={this.switchCourse}
          user={user}
          courses={courses}
          isLoadingCourses={isLoadingCourses}
          isSwitchingCourse={isSwitchingCourse}
        />
      </div>
    )
  }
}
