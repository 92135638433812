import cx from 'classnames'
import _ from 'lodash'
import React, { Component } from 'react'
import { Button, Heading, Input, Loader, Modal } from '@/components'
import './WithdrawUserModal.scss'

const WithdrawUserModalDefaultValues = function (values) {
  if (!values || typeof values !== 'object') {
    values = {}
  }
  return {
    dstInstructor: null,
    email: values.email || '',
    firstName: values.firstName || '',
    id: values.id || null,
    lastName: values.lastName || '',
    roleId: values.roleId || null,
    roles: values.roles || [],
  }
}

export default class WithdrawUserModal extends Component {
  static defaultProps = {
    values: new WithdrawUserModalDefaultValues(),
  }
  constructor(props) {
    super(props)
    this.state = {
      instructorName: '',
      instructorOptions: [],
    }
  }
  componentDidMount() {
    this.loadInstructors()
  }
  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    const { isOpen: wasOpen } = prevProps
    if (wasOpen && !isOpen) {
      this.onChangeInstructorName({ target: { value: '' } })
    } else if (!wasOpen && isOpen) {
      this.loadInstructors()
    }
  }
  loadInstructors = () => {
    const { loadInstructors } = this.props
    if (loadInstructors) {
      loadInstructors()
        .then((result) => this.setState({ instructorOptions: result }))
        .catch(() => this.setState({ instructorOptions: [] }))
    }
  }
  onChange = (field, value) => {
    if (this.props.onChange) {
      this.props.onChange(field, value)
    }
  }
  onChangeInstructorName = (event) => {
    const { instructorName } = this.state
    const { target } = event
    const { value } = target
    if (instructorName !== value) {
      this.setState({ instructorName: value }, () =>
        this.searchInstructors(value),
      )
    }
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  searchInstructors = _.debounce(
    (value) => {
      const { loadInstructors } = this.props
      loadInstructors(value)
        .then((result) => {
          let { instructorName: instructorNameValidation } = this.state
          if (instructorNameValidation === value) {
            this.setState({
              instructorName: value || '',
              instructorOptions: result,
            })
          }
        })
        .catch(() => {
          let { instructorName: instructorNameValidation } = this.state
          if (instructorNameValidation === value) {
            this.setState({ instructorOptions: [] })
          }
        })
    },
    250,
    { leading: true, trailing: true },
  )
  renderWithdraw = () => {
    const { courseTitle, values } = this.props
    const { email, firstName, lastName } = values || {}
    return (
      <div>
        <p>
          <span>Are you sure you would like to withdraw&nbsp;</span>
          <span className="is-bold">{`${firstName} ${lastName} (${email})`}</span>
          <span>&nbsp;from&nbsp;</span>
          <span className="is-bold">{courseTitle}</span>
          <span>?</span>
        </p>
      </div>
    )
  }
  renderTransfer = () => {
    const { error, isLoadingInstructors, values } = this.props
    const { instructorName, instructorOptions } = this.state
    const { dstInstructor, id } = values || {}
    const errorMessageDstInstructorId =
      error && error['dstInstructorId'] ? error['dstInstructorId'][0] : null
    const classNameInstructorId = cx('field', {
      'is-error': !!errorMessageDstInstructorId,
    })
    return (
      <div className={classNameInstructorId}>
        <p>
          The user is an instructor with existing students assigned to them.
          Please select a new instructor to transfer existing students to:
        </p>
        <div className="WithdrawUserModal-instructorField">
          <div className="WithdrawUserModal-instructorInput">
            <Input
              isSuccess={!!dstInstructor}
              placeholder="Enter instructor name..."
              onChange={this.onChangeInstructorName}
              value={dstInstructor ? dstInstructor.fullName : instructorName}
              readOnly={!!dstInstructor}
            />
            {dstInstructor ? (
              <Button onClick={() => this.onChange('dstInstructor', null)}>
                x
              </Button>
            ) : null}
          </div>
          <div className="WithdrawUserModal-instructorResults">
            <div
              className={cx('WithdrawUserModal-instructorOptions', {
                'is-loading': isLoadingInstructors,
              })}
            >
              {instructorOptions.length ? (
                instructorOptions.map((instructorOption) =>
                  instructorOption.id !== id ? (
                    <Button
                      className={cx({
                        'is-selected':
                          dstInstructor &&
                          dstInstructor.instructorId ===
                            instructorOption.instructorId,
                      })}
                      key={`WithdrawUserModal-instructorOptions-${instructorOption.instructorId}-${instructorOption.fullName}`}
                      onClick={() =>
                        this.onChange('dstInstructor', instructorOption)
                      }
                    >
                      {instructorOption.fullName}
                    </Button>
                  ) : null,
                )
              ) : (
                <span className="WithdrawUserModal-instructorNoResults">
                  No results...
                </span>
              )}
            </div>
            {isLoadingInstructors ? (
              <div className="WithdrawUserModal-instructorLoader">
                <Loader />
              </div>
            ) : null}
          </div>
        </div>
        {errorMessageDstInstructorId ? (
          <span className="WithdrawUserModal-error">{`* ${errorMessageDstInstructorId}`}</span>
        ) : null}
      </div>
    )
  }
  renderBody = () => {
    const { courseTitle, isTransfer, values } = this.props
    const { email, firstName, lastName } = values || {}
    return (
      <div className="WithdrawUserModal-body">
        <p>
          <span>Are you sure you would like to withdraw&nbsp;</span>
          <span className="is-bold">{`${firstName} ${lastName} (${email})`}</span>
          <span>&nbsp;from&nbsp;</span>
          <span className="is-bold">{courseTitle}</span>
          <span>?</span>
        </p>
        {isTransfer ? this.renderTransfer() : null}
      </div>
    )
  }
  render() {
    const { error, isLoading, isOpen, isTransfer, onConfirm, values } =
      this.props
    const { dstInstructor, firstName, lastName } = values || {}
    const errorMessageConfirm =
      error && error['confirm'] ? error['confirm'][0] : null
    return (
      <Modal
        className="WithdrawUserModal"
        contentLabel="Withdraw User Modal"
        isDanger
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>
            Withdraw {firstName} {lastName}
          </Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button disabled={isLoading} isSecondary onClick={this.onClose}>
            Cancel
          </Modal.Button>
          <Modal.Button
            disabled={isTransfer && !dstInstructor}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Withdraw
          </Modal.Button>
          {errorMessageConfirm ? (
            <span className="WithdrawUserModal-confirmError">{`* ${errorMessageConfirm}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { WithdrawUserModalDefaultValues }
