import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import { Loader } from '@/components'
import LoginLayout from '../LoginLayout'

@inject('sessionStore', 'uiStore')
@observer
export default class TokenLoginPage extends Component {
  componentDidMount() {
    const { history, sessionStore, uiStore } = this.props
    const { token } = this.props.match.params

    sessionStore.loginWithToken(token).then(() => {
      const { session } = sessionStore
      if (session && session.previewUrl) {
        const urlParser = document.createElement('a')
        urlParser.href = session.previewUrl
        return history.push(urlParser.pathname)
      }
      history.push('/')
    })

    uiStore.updateTitle('Logging In...')
  }

  render() {
    const { isLoading, error } = this.props.sessionStore
    return (
      <LoginLayout className="TokenLoginPage">
        {isLoading && <Loader />}
        {error && <div className="help is-danger">{error}</div>}
      </LoginLayout>
    )
  }
}
