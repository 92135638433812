import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { Heading, ImageModal } from '@/components'
import CourseProgressImg from '@/assets/course-progress.png'
import LogoutImg from '@/assets/logout.png'
import PathwayDoneImg from '@/assets/pathway-done.png'
import PathwayToMasteryImg from '@/assets/pathway-to-mastery.png'
import PostCourseImg from '@/assets/post-course.png'

import './FAQPage.scss'

const FAQPageMessages = defineMessages({
  adaptiveHeader: {
    defaultMessage: 'Adaptive Learning & Course Navigation',
    description: 'FAQ page subheader',
    id: 'FAQPage.adaptiveHeader',
  },
  adaptiveSubHeader: {
    defaultMessage: 'What are adaptive learning courses?',
    description: 'FAQ page subheader',
    id: 'FAQPage.adaptiveSubHeader',
  },
  adaptiveDescription1: {
    defaultMessage:
      'Our adaptive learning courses leverage Artificial Intelligence (A.I.), Machine Learning and advanced algorithms to create a unique learning pathway for each learner based on their interactions with the system. It is unlike any other course you may have taken in the past, which simply introduced a concept and then quizzed you on whether or not you understood it.',
    description: 'Description of adaptive learning 1',
    id: 'FAQPage.adaptiveDescription1',
  },
  adaptiveDescription1Bullet1: {
    defaultMessage: `Adaptive learning combines “bite-sized” pieces of learning content. The platform’s A.I. adjusts to your specific needs, like a one-on-one Coach.`,
    description: 'Description of adaptive learning bullet point 1',
    id: 'FAQPage.adaptiveDescription1Bullet1',
  },
  adaptiveDescription1Bullet2: {
    defaultMessage: `You are in the driver's seat. You can choose to Watch (think videos), Read (text with accompanying visuals) or Practice (interactive exercises), in any order, at any time. The choice is always yours.  If you’re already familiar with some of the subject matter, feel free to jump right into Practice and skip Read or Watch.  If you’re struggling with a particular question in Practice, jump back into Read or Watch to research and then head back to Practice to better inform your answer choice.`,
    description: 'Description of adaptive learning bullet point 2',
    id: 'FAQPage.adaptiveDescription1Bullet2',
  },
  adaptiveDescription1Bullet3: {
    defaultMessage: `Remember, you’ll master one thing at a time – at your own pace – until you’ve mastered the entire course.`,
    description: 'Description of adaptive learning bullet point 3',
    id: 'FAQPage.adaptiveDescription1Bullet3',
  },
  adaptiveDescription2: {
    defaultMessage:
      'This go-at-your-own-pace, adaptive approach significantly reduces learning times and helps people learn more, remember more, and apply more back to their respective work. The result is an increase in knowledge focused on your personal needs and efficiency of your time.',
    description: 'Description of adaptive learning 2',
    id: 'FAQPage.adaptiveDescription2',
  },
  completeCourseSubHeader: {
    defaultMessage: 'How do I successfully complete the course?',
    description: 'FAQ page subheader',
    id: 'FAQPage.completeCourseSubHeader',
  },
  completeCourseDescription1: {
    defaultMessage: `You must achieve mastery with all Units and Sections.  Make sure to check your Pathway to Mastery on your Learner Dashboard.  If there are Sections that need to be reviewed again, you’ll need to go back and answer additional questions.`,
    description: 'Description of complete course section 1',
    id: 'FAQPage.completeCourseDescription1',
  },
  completeCourseDescription2: {
    defaultMessage:
      'Once you have cleared all the Sections out of your Pathway to Mastery, you have successfully completed the course and your Course Progress will move to 100%.',
    description: 'Description of complete course section 2',
    id: 'FAQPage.completeCourseDescription2',
  },
  completeCourseDescription3: {
    defaultMessage:
      'Check with your organization if there are other items you must complete outside of this platform, for example, a post-course exam and/or post-course evaluation, which may able to be accessed from your Learner Dashboard upon successful course completion.',
    description: 'Description of complete course section 3',
    id: 'FAQPage.completeCourseDescription3',
  },
  courseProgressSubHeader: {
    defaultMessage: 'How do I know my progress in the course?',
    description: 'FAQ page subheader',
    id: 'FAQPage.courseProgressSubHeader',
  },
  courseProgressDescription: {
    defaultMessage:
      'You can always view your overall Course Progress in the table of contents navigation on the left-hand side.  Progress is saved as you work through the course. Your progress will automatically update as you complete Units and Sections.',
    description: 'Description of course progress section',
    id: 'FAQPage.courseProgressDescription',
  },
  logoutSubHeader: {
    defaultMessage: 'How do I logout of the course?',
    description: 'FAQ page subheader',
    id: 'FAQPage.logoutSubHeader',
  },
  logoutDescription: {
    defaultMessage:
      'To logout of the course, click the dropdown arrow next to your username in the upper right-hand corner of your screen and select Logout.  You progress is always saved instantaneously and when you log back in to resume your learning, simply click the green button titled, Continue, in the upper right-hand corner and you will pick up exactly where you last left off.',
    description: 'Description for the logout section',
    id: 'FAQPage.logoutDescription',
  },
  mobileSubHeader: {
    defaultMessage: 'Can I take this course using my mobile device?',
    description: 'FAQ page subheader',
    id: 'FAQPage.mobileSubHeader',
  },
  mobileDescription: {
    defaultMessage:
      'Yes, the course can be accessed and completed using a mobile or tablet device. However, if you need to complete a post-course exam and/or course evaluation, check with your organization to see if you need to be connected to your internal network.',
    description: 'Description for mobile section',
    id: 'FAQPage.mobileDescription',
  },
  operatingSystemSubheader: {
    defaultMessage:
      'Does this adaptive course work on all operating systems, browsers and devices?',
    description: 'FAQ page subheader',
    id: 'FAQPage.operatingSystemSubheader',
  },
  operatingSystemDescription: {
    defaultMessage:
      'Our adaptive learning platform will work on Mac and Windows operating systems. It is recommended that you use the latest version of Google Chrome.  The latest versions of Safari, Firefox, and Internet Explorer (IE 11+) are also supported. Update your browser regularly for maximum stability.',
    description: 'Description for operating system section',
    id: 'FAQPage.operatingSystemDescription',
  },
  postCourseSubHeader: {
    defaultMessage:
      'If a post-course exam and/or evaluation is required, where do I find it?',
    description: 'FAQ page subheader',
    id: 'FAQPage.postCourseSubHeader',
  },
  postCourseDescription: {
    defaultMessage: `Upon successfully completing the course by achieving a mastery level in each Section, you may see a link to the Exam and Evaluation on the top of the dashboard. You may also need to use a laptop that is connected to your organization’s network in order to complete the exam and evaluation.`,
    description: 'Description of post course section',
    id: 'FAQPage.postCourseDescription',
  },
  preferenceSubHeader: {
    defaultMessage: 'Can I complete the course in order of preference?',
    description: 'FAQ page subheader',
    id: 'FAQPage.preferenceSubHeader',
  },
  preferenceDescription1: {
    defaultMessage:
      'We suggest you click on the “Start Learning” button in the right-hand corner of your Learner Dashboard.  You can also access the course content in the table of contents area in the left-hand side.',
    description: 'Description of preference section 1',
    id: 'FAQPage.preferenceDescription1',
  },
  preferenceDescription2: {
    defaultMessage:
      'Depending on how your organization set-up this course, you may have to take Units and Sections sequentially, which would be indicated with a “lock” icon ({lockIcon}) next to each Unit or Section.',
    description: 'Description of preference section 2',
    id: 'FAQPage.preferenceDescription2',
  },
  preferenceDescription3: {
    defaultMessage:
      'To unlock your next Unit or Section, you will have to successfully complete a Section by answering a number of Practice questions. The green “Next” button will guide you through the course.',
    description: 'Description of preference section 3',
    id: 'FAQPage.preferenceDescription3',
  },
  preferenceDescription4: {
    defaultMessage:
      'If there are no “lock” icons ({lockIcon}) you can freely jump into any Unit or Section as you desire.',
    description: 'Description of preference section 4',
    id: 'FAQPage.preferenceDescription4',
  },
  questionIncorrectSubHeader: {
    defaultMessage: 'What happens when I get a question incorrect?',
    description: 'FAQ page subheader',
    id: 'FAQPage.questionIncorrectSubHeader',
  },
  questionIncorrectDescription1: {
    defaultMessage: `Our adaptive learning platform is constantly trying to challenge you with higher level concepts and understanding so it’s normal to get questions incorrect. When you get a question incorrect, you have the option to view the “Hint” to learn as to why you got it incorrect.`,
    description: 'Description of question incorrect section 1',
    id: 'FAQPage.questionIncorrectDescription1',
  },
  questionIncorrectDescription2: {
    defaultMessage:
      'Research shows that instant feedback is incredibly effective. Additionally, you are more likely to remember the information next time.  At times you may also get pop-up windows from our A.I. coach that will adjust the course based on your needs as well as offer recommendations to get through the course more efficiently.',
    description: 'Description of question incorrect section 2',
    id: 'FAQPage.questionIncorrectDescription2',
  },
  questionContactSubHeader: {
    defaultMessage: 'Questions or Technical Support?',
    description: 'FAQ page subheader',
    id: 'FAQPage.questionContactSubHeader',
  },
  questionContactDescription: {
    defaultMessage:
      'Contact your organization for questions or technical support based on the normal communication procedures.',
    description: 'FAQ page subheader',
    id: 'FAQPage.questionContactDescription',
  },
  sectionCompletionSubheader: {
    defaultMessage: 'How do I complete a Section?',
    description: 'FAQ page subheader',
    id: 'FAQPage.sectionCompletionSubheader',
  },
  sectionCompletionDescription1: {
    defaultMessage: `Inside each Section you'll typically find three learning choices: Read, Watch, and Practice.`,
    description: 'Description of section completion (1)',
    id: 'FAQPage.sectionCompletionDescription1',
  },
  sectionCompletionDescription2: {
    defaultMessage:
      'To learn and research, dig into the Read and Watch tabs. Once you are ready to apply your confidence with the knowledge, attempt some questions in the Practice tab. You can always jump between Read, Watch and Practice tabs, at any point.',
    description: 'Description of section completion (2)',
    id: 'FAQPage.sectionCompletionDescription2',
  },
  sectionCompletionDescription3: {
    defaultMessage: `For example, if you’re struggling with a particular question in Practice, jump back into Read or Watch to research and then head back into Practice to better inform your answer choice.  To complete a Section, answer enough questions (volume varies by topic and learner) to demonstrate confidence and mastery of the material at hand.`,
    description: 'Description of section completion (3)',
    id: 'FAQPage.sectionCompletionDescription3',
  },
  structureSubHeader: {
    defaultMessage: 'How is the learning experience structured?',
    description: 'FAQ page subheader',
    id: 'FAQPage.structureSubHeader',
  },
  structureDescription: {
    defaultMessage:
      'The course consists of Units, which represent larger themes. Inside of Units are Sections, which are small, bite-sized learning challenges. Take your time and learn at your own pace.',
    description: 'Description of structure section',
    id: 'FAQPage.structureDescription',
  },
  technicalSupportHeader: {
    defaultMessage: 'Technical Support',
    description: 'FAQ page subheader',
    id: 'FAQPage.technicalSupportHeader',
  },
  timeSubHeader: {
    defaultMessage:
      'Is there a minimum or maximum time allocated to complete the Units, Sections or the entire course?',
    description: 'FAQ page subheader',
    id: 'FAQPage.timeSubHeader',
  },
  timeDescription: {
    defaultMessage:
      'There is no set minimum or maximum time allocated and the course completion time will vary by learner.',
    description: 'Description of time section',
    id: 'FAQPage.timeDescription',
  },
  title: {
    defaultMessage: 'Frequently Asked Questions',
    description: 'FAQ page title',
    id: 'FAQPage.title',
  },
  userDataSubHeader: {
    defaultMessage: 'What user data is collected?',
    description: 'FAQ page subheader',
    id: 'FAQPage.userDataSubHeader',
  },
  userDataDescription: {
    defaultMessage: `Our platform’s A.I. uses a number of anonymous data points (eg. progress, time spent, subject awareness, etc) to build your personalized learning path through the content of the course. User credentials are anonymous. You can access all of your anonymous user data in your Learner Dashboard which can be accessed by clicking on the Home icon.`,
    description: 'Description of user data section',
    id: 'FAQPage.userDataDescription',
  },
})

const FAQPageLinks = {
  [FAQPageMessages.adaptiveSubHeader.id]: [
    FAQPageMessages.adaptiveSubHeader,
    'adaptive',
  ],
  [FAQPageMessages.structureSubHeader.id]: [
    FAQPageMessages.structureSubHeader,
    'structure',
  ],
  [FAQPageMessages.timeSubHeader.id]: [FAQPageMessages.timeSubHeader, 'time'],
  [FAQPageMessages.preferenceSubHeader.id]: [
    FAQPageMessages.preferenceSubHeader,
    'preference',
  ],
  [FAQPageMessages.sectionCompletionSubheader.id]: [
    FAQPageMessages.sectionCompletionSubheader,
    'section-completion',
  ],
  [FAQPageMessages.courseProgressSubHeader.id]: [
    FAQPageMessages.courseProgressSubHeader,
    'course-progress',
  ],
  [FAQPageMessages.questionIncorrectSubHeader.id]: [
    FAQPageMessages.questionIncorrectSubHeader,
    'question-incorrect',
  ],
  [FAQPageMessages.completeCourseSubHeader.id]: [
    FAQPageMessages.completeCourseSubHeader,
    'complete-course',
  ],
  [FAQPageMessages.postCourseSubHeader.id]: [
    FAQPageMessages.postCourseSubHeader,
    'post-course',
  ],
  [FAQPageMessages.logoutSubHeader.id]: [
    FAQPageMessages.logoutSubHeader,
    'logout',
  ],
  [FAQPageMessages.userDataSubHeader.id]: [
    FAQPageMessages.userDataSubHeader,
    'user-data',
  ],
  [FAQPageMessages.operatingSystemSubheader.id]: [
    FAQPageMessages.operatingSystemSubheader,
    'operating-systems',
  ],
  [FAQPageMessages.mobileSubHeader.id]: [
    FAQPageMessages.mobileSubHeader,
    'mobile',
  ],
  [FAQPageMessages.questionContactSubHeader.id]: [
    FAQPageMessages.questionContactSubHeader,
    'support',
  ],
}

@injectIntl
@inject('sessionStore', 'uiStore')
@observer
export default class FAQPage extends Component {
  state = {
    modalImage: null,
    isImageModalOpen: false,
  }
  componentDidMount() {
    const { uiStore } = this.props
    const $images = this.$FAQPage.querySelectorAll('img')
    for (let i = 0; i < $images.length; i++) {
      $images[i].addEventListener('click', (e) => {
        this.onOpenImageModal(e, $images[i].src)
      })
    }

    uiStore.updateTitle('FAQ Page')
  }
  handleJumpLink = (e) => {
    const { history, match, uiStore } = this.props
    const { scrollY } = uiStore
    e.preventDefault()
    const href = e.target.getAttribute('href')
    scrollY(null, this[href].offsetTop - 10)
    history.push(`${match.url}${href}`)
  }
  onCloseImageModal = () => {
    const { uiStore } = this.props
    const { scrollTop, scrollY } = uiStore
    scrollY(null, scrollTop)
    this.setState({
      isImageModalOpen: false,
      modalImage: null,
    })
  }
  onOpenImageModal = (e, image) => {
    const { uiStore } = this.props
    uiStore.setScrollTop()
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      isImageModalOpen: true,
      modalImage: image,
    })
  }
  setPageRef = (el) => {
    this.$FAQPage = el
  }
  setRef = (el) => {
    if (el) {
      this[el.id] = el
    }
  }
  renderSubHeader = ({ id: msgObjId }) => {
    const { intl } = this.props
    const { formatMessage } = intl
    const [msgObj, hrefId] = FAQPageLinks[msgObjId]
    return (
      <div id={`#${hrefId}`} ref={this.setRef}>
        <Heading is4>{formatMessage(msgObj)}</Heading>
      </div>
    )
  }
  renderAdaptiveSection() {
    const { intl } = this.props
    const { formatMessage } = intl
    const lockIcon = <i aria-hidden="true" className="fa fa-lock" />
    return (
      <Fragment>
        <Heading is2>{formatMessage(FAQPageMessages.adaptiveHeader)}</Heading>
        {this.renderSubHeader(FAQPageMessages.adaptiveSubHeader)}
        <p>{formatMessage(FAQPageMessages.adaptiveDescription1)}</p>
        <ul>
          <li>{formatMessage(FAQPageMessages.adaptiveDescription1Bullet1)}</li>
          <li>{formatMessage(FAQPageMessages.adaptiveDescription1Bullet2)}</li>
          <li>{formatMessage(FAQPageMessages.adaptiveDescription1Bullet3)}</li>
        </ul>
        <p>{formatMessage(FAQPageMessages.adaptiveDescription2)}</p>

        {this.renderSubHeader(FAQPageMessages.structureSubHeader)}
        <p>{formatMessage(FAQPageMessages.structureDescription)}</p>

        {this.renderSubHeader(FAQPageMessages.timeSubHeader)}

        <p>{formatMessage(FAQPageMessages.timeDescription)}</p>

        {this.renderSubHeader(FAQPageMessages.preferenceSubHeader)}
        <p>{formatMessage(FAQPageMessages.preferenceDescription1)}</p>
        <p>
          <FormattedMessage
            {...FAQPageMessages.preferenceDescription2}
            values={{ lockIcon }}
          />
        </p>
        <p>{formatMessage(FAQPageMessages.preferenceDescription3)}</p>
        <p>
          <FormattedMessage
            {...FAQPageMessages.preferenceDescription4}
            values={{ lockIcon }}
          />
        </p>

        {this.renderSubHeader(FAQPageMessages.sectionCompletionSubheader)}
        <p>{formatMessage(FAQPageMessages.sectionCompletionDescription1)}</p>
        <p>{formatMessage(FAQPageMessages.sectionCompletionDescription2)}</p>
        <p>{formatMessage(FAQPageMessages.sectionCompletionDescription3)}</p>

        {this.renderSubHeader(FAQPageMessages.courseProgressSubHeader)}
        <p>{formatMessage(FAQPageMessages.courseProgressDescription)}</p>
        <img src={CourseProgressImg} alt="Course Progress Screenshot" />

        {this.renderSubHeader(FAQPageMessages.questionIncorrectSubHeader)}
        <p>{formatMessage(FAQPageMessages.questionIncorrectDescription1)}</p>
        <p>{formatMessage(FAQPageMessages.questionIncorrectDescription2)}</p>

        {this.renderSubHeader(FAQPageMessages.completeCourseSubHeader)}
        <p>{formatMessage(FAQPageMessages.completeCourseDescription1)}</p>
        <img alt="Pathway to Mastery Screenshot" src={PathwayToMasteryImg} />
        <p>{formatMessage(FAQPageMessages.completeCourseDescription2)}</p>
        <img alt="Pathway Done Screenshot" src={PathwayDoneImg} />
        <p>{formatMessage(FAQPageMessages.completeCourseDescription3)}</p>

        {this.renderSubHeader(FAQPageMessages.postCourseSubHeader)}
        <p>{formatMessage(FAQPageMessages.postCourseDescription)}</p>
        <img alt="Post Course Screenshot" src={PostCourseImg} />

        {this.renderSubHeader(FAQPageMessages.logoutSubHeader)}
        <p>{formatMessage(FAQPageMessages.logoutDescription)}</p>
        <img alt="Log Out Screenshot" src={LogoutImg} />

        {this.renderSubHeader(FAQPageMessages.userDataSubHeader)}
        <p>{formatMessage(FAQPageMessages.userDataDescription)}</p>
      </Fragment>
    )
  }
  renderJumpLink(msgObj, hrefId) {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="FAQPage__jumpLink" key={`FAQPage__jumpLink-${hrefId}`}>
        <a href={`#${hrefId}`} onClick={this.handleJumpLink}>
          {formatMessage(msgObj)}
        </a>
      </div>
    )
  }
  renderJumpLinks() {
    return (
      <div className="FAQPage__Content__jumpLinks">
        {Object.keys(FAQPageLinks).map((key) => {
          const [msgObj, hrefId] = FAQPageLinks[key]
          return this.renderJumpLink(msgObj, hrefId)
        })}
      </div>
    )
  }
  renderTechnicalSupportSection() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <Fragment>
        <Heading is2>
          {formatMessage(FAQPageMessages.technicalSupportHeader)}
        </Heading>
        {this.renderSubHeader(FAQPageMessages.operatingSystemSubheader)}
        <p>{formatMessage(FAQPageMessages.operatingSystemDescription)}</p>
        {this.renderSubHeader(FAQPageMessages.mobileSubHeader)}
        <p>{formatMessage(FAQPageMessages.mobileDescription)}</p>
        {this.renderSubHeader(FAQPageMessages.questionContactSubHeader)}
        <p>{formatMessage(FAQPageMessages.questionContactDescription)}</p>
      </Fragment>
    )
  }
  render() {
    const { intl, sessionStore } = this.props
    const { modalImage, isImageModalOpen } = this.state
    const { formatMessage } = intl
    const { user } = sessionStore
    const { isCurrentRoleAdmin } = user || {}
    return (
      <Fragment>
        <div className="FAQPage PageWrapper" qa-id="learner-faq-page">
          <div className="container Container">
            <div className="FAQPage__Content" ref={this.setPageRef}>
              {!isCurrentRoleAdmin && (
                <Fragment>
                  <Heading>{formatMessage(FAQPageMessages.title)}</Heading>
                  {this.renderJumpLinks()}
                  {this.renderAdaptiveSection()}
                  {this.renderTechnicalSupportSection()}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <ImageModal
          image={modalImage}
          isOpen={isImageModalOpen}
          onClose={this.onCloseImageModal}
        />
      </Fragment>
    )
  }
}
