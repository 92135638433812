import React, { Component } from 'react'
import cx from 'classnames'

import './ButtonGroup.scss'

export default class ButtonGroup extends Component {
  onChange = (e, item) => {
    e.preventDefault()
    const active = this.props.active !== item.value ? item.value : null
    this.props.onChange(active)
  }

  getItemClassName = (item) => {
    const { active } = this.props
    return cx('ButtonGroup-item button', {
      'is-selected': active === item.value,
      'is-inverted': active !== item.value,
      'is-info': true,
      'is-danger': item.isDanger,
      'is-success': item.isSuccess,
    })
  }

  render() {
    const className = cx(
      'Button-group buttons has-addons',
      this.props.className,
    )

    return (
      <div className={className}>
        {this.props.items.map((item) => (
          <button
            className={this.getItemClassName(item)}
            disabled={item.isDisabled}
            key={item.value}
            onClick={(e) => this.onChange(e, item)}
          >
            <span className="ButtonGroup-text">{item.text}</span>
            <span className="ButtonGroup-count">{item.count}</span>
          </button>
        ))}
      </div>
    )
  }
}
