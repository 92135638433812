import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import cx from 'classnames'

import './TransitionHeight.scss'

export default class TransitionHeight extends Component {
  constructor(props) {
    super(props)
    this.isInitiallyActive = props.in
  }

  render() {
    return (
      <CSSTransition
        {...this.props}
        className={cx('TransitionHeight', this.props.className)}
        classNames="TransitionHeight"
        timeout={400}
        onEnter={(node) => {
          node.style.display = 'block'
        }}
        onEntering={(node) => {
          node.style.maxHeight = `${node.scrollHeight}px`
        }}
        onEntered={(node) => {
          node.style.maxHeight = null
        }}
        onExit={(node) => {
          node.style.maxHeight = `${node.scrollHeight}px`
        }}
        onExiting={(node) => {
          node.style.maxHeight = null
        }}
        onExited={(node) => {
          node.style.display = 'none'
        }}
      >
        <div style={!this.isInitiallyActive ? { display: 'none' } : null}>
          {this.props.children}
        </div>
      </CSSTransition>
    )
  }
}
