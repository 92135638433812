import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import cx from 'classnames'
import { Button, Link } from '@/components'

import './SearchResults.scss'

const SearchResultsMessages = defineMessages({
  noMoreElements: {
    defaultMessage: 'No more elements match your search.',
    description:
      'Description for when there are no matching search items within the search results.',
    id: 'SearchResults.noMoreElements',
  },
  readMore: {
    defaultMessage: 'Read more',
    description:
      'Button to navigate to matched search content from the search results.',
    id: 'SearchResults.readMore',
  },
  searchResultSection: {
    defaultMessage: 'From {sectionTitle}',
    description:
      'Label to display the section the matched search content belongs to.',
    id: 'SearchResults.searchResultSection',
  },
})

@injectIntl
export default class SearchResults extends Component {
  renderCategory = (result) => {
    const sectionUrl = `units/${result.unitId}/sections/${result.sectionId}`
    return (
      <ul>
        {result.category.map((c) => (
          <li style={{ paddingBottom: 5 }} key={c.value}>
            <Button isSmall isInfo to={`${sectionUrl}/${c.value}`}>
              {c.title}
            </Button>
          </li>
        ))}
      </ul>
    )
  }

  renderLockedResult = (result) => (
    <Fragment>
      <div className="media-left is-hidden-mobile" />
      <div className="media-content SearchResults-content">
        <div>
          <div className="SearchResults-from">
            <FormattedMessage
              {...SearchResultsMessages.searchResultSection}
              values={{
                sectionTitle: (
                  <strong>
                    <i className="fa fa-lock" />
                    &nbsp;{result.title}
                  </strong>
                ),
              }}
            />
          </div>
          <p className="SearchResults-desc">{result.description}</p>
        </div>
      </div>
    </Fragment>
  )

  renderUnlockedResult = (result, sectionUrl) => {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <Fragment>
        <div className="media-left is-hidden-mobile">
          {this.renderCategory(result)}
          {result.videoThumbnailUrl && (
            <div className="SearchResults-thumb">
              <img src={result.videoThumbnailUrl} />
            </div>
          )}
        </div>
        <div className="media-content SearchResults-content">
          <div>
            <div className="SearchResults-from">
              <FormattedMessage
                {...SearchResultsMessages.searchResultSection}
                values={{
                  sectionTitle: (
                    <Link to={sectionUrl}>
                      <strong>{result.title}</strong>
                    </Link>
                  ),
                }}
              />
            </div>
            <p className="SearchResults-desc">{result.description}</p>
            <Link className="SearchResults-more" to={sectionUrl}>
              {formatMessage(SearchResultsMessages.readMore)}
            </Link>
          </div>
        </div>
      </Fragment>
    )
  }

  renderResult = (result, index) => {
    const subPage =
      result.category.length > 0 ? `${result.category[0].value}` : ''
    const sectionUrl = `units/${result.unitId}/sections/${result.sectionId}/${subPage}`

    return (
      <div
        className={cx('SearchResults-item media', {
          'is-locked': result.locked,
        })}
        key={`search-result-${index}`}
      >
        {result.locked
          ? this.renderLockedResult(result)
          : this.renderUnlockedResult(result, sectionUrl)}
      </div>
    )
  }

  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="SearchResults">
        {this.props.results.map(this.renderResult)}
        <p className="SearchResults-end">
          {formatMessage(SearchResultsMessages.noMoreElements)}
        </p>
      </div>
    )
  }
}
