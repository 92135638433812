import { action, computed, extendObservable } from 'mobx'
import request from '@/utils/request'
import _ from 'lodash'
import humps from 'lodash-humps'
import Activity from './models/activity'
import Course from './models/course'
import Exam from './models/exam'
import Settings from './models/settings'
import Snapshot from './models/snapshot'
import {
  RANK_UNENGAGED,
  REATTEMPT_STATUS_FINISHED,
  REATTEMPT_STATUS_STARTED,
} from '@/constants'
import SentryReplay from '@/SentryReplay'
import { fromUTCToLocale } from '@/utils/helpers'

const initialState = {
  course: null,
  exam: false,
  hasLoadedMemoryBoostersOnMount: false,
  isErrorSettings: false,
  isInitializingCourse: false,
  isLoadingCourse: false,
  isLoadingCourseSettings: false,
  isLoadingExamStatus: false,
  isLoadingMemoryBooster: false,
  isLoadingMemoryBoosters: false,
  isLoadingSnapshot: false,
  isSavingMemoryBooster: false,
  isSkippingMemoryBooster: false,
  isUnlockingUnit: false,
  isUpdatingCourseProgress: false,
  memoryBooster: null,
  memoryBoosterAnswer: null,
  memoryBoosters: [],
  nextMemoryBooster: null,
  pretestMasteredSectionIds: null,
  quiz: null,
  session: null,
  settings: new Settings({}),
  snapshot: null,
}

export default class ContentStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...initialState })
  }
  @action init(session) {
    this.session = session
  }

  @action initCourse() {
    if (this.isInitializingCourse) return
    this.isInitializingCourse = true
    return this.loadCourseSettings()
      .then(this.loadCourse)
      .then(this.handleInitCourse)
      .catch(this.handleInitCourseError)
  }
  @action handleInitCourse = () => {
    this.sendCourseStatusMessage()
    this.isInitializingCourse = false
    return Promise.resolve()
  }
  @action handleInitCourseError = (error) => {
    let ex = null
    try {
      if (error.exception) {
        ex = new Error(error.exception)
      }
    } catch (e) {
      ex = new Error(e)
    }
    SentryReplay.captureException(ex || error)
    this.isInitializingCourse = false
    return Promise.reject(error)
  }

  @action loadCourse = () => {
    const { courseId } = this.session
    this.isLoadingCourse = true
    return Promise.all([
      request.get(`/content/${courseId}`, { params: { d: Date.now() } }),
      ContentStore.getCourseProgress(courseId),
    ])
      .then(([courseResponse, progressResponse]) =>
        ContentStore.convertFullCourseResponse(
          courseResponse,
          progressResponse,
          this.settings,
        ),
      )
      .then(this.handleLoadCourse)
      .catch(this.handleLoadCourseError)
  }
  @action handleLoadCourse = ({ courseData, progressData }) => {
    const { user } = this.session
    this.course = new Course({ ...courseData, user })
    if (progressData) {
      const { pretestMasteredSectionIds } = progressData
      this.pretestMasteredSectionIds = pretestMasteredSectionIds
    }
    this.isErrorSettings = false
    this.isLoadingCourse = false
    return Promise.resolve()
  }
  @action handleLoadCourseError = (error) => {
    const { message } = error
    const errorJSON = ContentStore.getErrorJSON(message)
    const { settings: errorSettings } = errorJSON || {}
    SentryReplay.captureException(error)
    if (errorSettings) {
      this.isErrorSettings = true
    }
    this.isLoadingCourse = false
    return Promise.reject(errorJSON)
  }

  @action loadCourseSettings = () => {
    const { courseId } = this.session
    this.isLoadingCourseSettings = true
    return request
      .get(`/content/${courseId}/settings`, { params: { d: Date.now() } })
      .then(this.handleLoadCourseSettings)
      .catch(this.handleLoadCourseSettingsError)
  }
  @action handleLoadCourseSettings = (response) => {
    const { data } = response || {}
    const { settings } = data || {}
    if (!settings) {
      return Promise.reject(
        new Error(
          JSON.stringify({
            settings: [
              'The course you are trying to access is not ready yet. Please check again at a later time.',
            ],
          }),
        ),
      )
    }
    const {
      activityAttempts,
      courseCompletionCopy,
      courseIntro,
      courseMasterCopy: courseMasteryCopy,
      disableMessaging: isDisabledMessaging,
      exam,
      examCertificate,
      learnerDashboard,
      practiceLearningObjectives,
      quiz,
      quizLockout,
      quizMastery,
      quizPass,
      requiredMasteryLevel,
      reviewActivitiesType,
      snapshot: isSnapshot,
      snapshotSkip,
      snapshotStandalone: isSnapshotStandalone,
      snapshotTestOut,
    } = humps(settings) || {}
    this.settings = new Settings({
      activityAttempts,
      courseCompletionCopy,
      courseIntro,
      courseMasteryCopy,
      exam,
      examCertificate,
      isDisabledMessaging,
      isSnapshot,
      isSnapshotStandalone,
      learnerDashboard,
      practiceLearningObjectives,
      quiz,
      quizLockout,
      quizMastery,
      quizPass,
      requiredMasteryLevel,
      reviewActivitiesType,
      snapshotSkip,
      snapshotTestOut,
    })
    this.isErrorSettings = false
    this.isLoadingCourseSettings = false
    return Promise.resolve()
  }
  @action handleLoadCourseSettingsError = (error) => {
    const { message } = error
    const errorJSON = ContentStore.getErrorJSON(message)
    const { settings: errorSettings } = errorJSON || {}
    SentryReplay.captureException(error)
    if (errorSettings) {
      this.isErrorSettings = true
    }
    this.isLoadingCourseSettings = false
    return Promise.reject(errorJSON)
  }

  @action loadExamStatus() {
    const { courseId } = this.session
    this.isLoadingExamStatus = true
    return ContentStore.getExamStatus(courseId)
      .then(this.handleLoadExamStatus)
      .catch(this.handleLoadExamStatusError)
  }
  @action handleLoadExamStatus = (response) => {
    const { data } = response
    const examData = humps(data)
    const { displayTitle, id: courseId, title } = this.course
    if (!this.exam) {
      this.exam = new Exam({
        courseId: courseId,
        courseTitle: displayTitle || title,
        ...examData,
      })
    } else {
      this.exam = new Exam({ ...this.exam, ...examData })
    }
    this.isLoadingExamStatus = false
    return this.exam
  }
  @action handleLoadExamStatusError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingExamStatus = false
  }

  get isDisabledFeedbackForm() {
    return !!(!this.feedbackFormId === null || this.feedbackFormId === '0')
  }

  @action updateCourseProgress() {
    const { id } = this.course
    this.isUpdatingCourseProgress = true
    return ContentStore.getCourseProgress(id)
      .then((response) => this.handleUpdateCourseProgress(response))
      .catch((response) => this.handleUpdateCourseProgressError(response))
  }
  @action handleUpdateCourseProgress(response) {
    const { data } = response

    const { nextUp, practice, preTest: pretestResponse } = humps(data)

    const { sectionId: nextSectionId, unitId: nextUnitId } = nextUp
    this.course.nextUnitId = nextUnitId
    this.course.nextSectionId = nextSectionId

    const {
      course: pretestMasteredCourse,
      section: pretestSections,
      units: pretestUnits,
    } = pretestResponse
    const pretestMasteredSectionIds = {}
    const pretestMasteredUnitIds = {}
    pretestSections.forEach((pretestSectionId) => {
      pretestMasteredSectionIds[pretestSectionId] = true
    })
    pretestUnits.forEach((pretestUnitId) => {
      pretestMasteredUnitIds[pretestUnitId] = true
    })

    this.course.pretestMastered = !!pretestMasteredCourse

    practice.units.forEach((pUnit) => {
      const { id: pUitId, sections: pUnitSections } = pUnit
      const unit = this.course.findUnitById(pUitId)
      if (unit) {
        unit.pretestMastered = !!pretestMasteredUnitIds[pUitId]
      }
      pUnitSections.forEach((pSection) => {
        const { id: pSectionId } = pSection
        if (!unit) return
        pSection.pretestMastered = !!pretestMasteredSectionIds[pSectionId]
        unit.findSectionAndUpdate(pSection)
      })
    })

    this.pretestMasteredSectionIds = pretestMasteredSectionIds

    this.isUpdatingCourseProgress = false

    return Promise.resolve(this.course)
  }
  @action handleUpdateCourseProgressError(error) {
    SentryReplay.captureException(error)
    this.isUpdatingCourseProgress = false
  }

  @action sendCourseCompletionMessage() {
    // NOTE - send course completion for SCORM - https://headware4.jira.com/browse/FM-2801
    this.appStore.sendMessage('course_completed', 'completed')
  }
  @action sendCourseStatusMessage() {
    if (!this.session || !this.course || !this.settings) return
    const { courseId } = this.session
    const { exam: examSetting } = this.settings
    const courseCompleted = this.courseProgress === 100
    let status = courseCompleted ? 'completed' : 'incomplete'
    if (courseCompleted) {
      if (examSetting) {
        ContentStore.getExamStatus(courseId)
          .then((response) => {
            const { data } = response || { data: {} }
            const examData = humps(data)
            const { passed: examPassed } = examData || {}
            if (examPassed) {
              this.appStore.sendMessage('status', status)
            }
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.appStore.sendMessage('status', status)
      }
    }
  }

  @action loadMemoryBooster({ sectionId, unitId }) {
    const { courseId } = this.session
    this.isLoadingMemoryBooster = true
    return request
      .get(
        `/activities/${courseId}/units/${unitId}/sections/${sectionId}/memory_booster`,
        {
          params: {
            d: Date.now(),
          },
        },
      )
      .then(this.handleLoadMemoryBooster)
      .catch(this.handleLoadMemoryBoosterError)
  }
  @action handleLoadMemoryBooster = (response) => {
    const { data } = response
    const { activity: memoryBooster } = humps(data)
    this.memoryBooster = new Activity({ ...memoryBooster })
    this.isLoadingMemoryBooster = false
    return this.memoryBooster
  }
  @action handleLoadMemoryBoosterError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingMemoryBooster = false
    return Promise.reject(error)
  }

  @action loadMemoryBoosters(isOnMount) {
    if (
      (isOnMount && this.hasLoadedMemoryBoostersOnMount) ||
      this.isLoadingMemoryBoosters
    )
      return
    const { courseId } = this.session
    this.isLoadingMemoryBoosters = true
    return request
      .get(`/activities/${courseId}/memory_booster_sections`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadMemoryBoosters)
      .catch(this.handleLoadMemoryBoostersError)
  }
  @action handleLoadMemoryBoosters = (response) => {
    const { data } = response
    const {
      lastAnswered: lastAnsweredMemoryBooster,
      units: memoryBoosterUnits,
      ...restMemoryBoosters
    } = humps(data) || { lastAnswered: null, units: [] }
    this.memoryBoosters = {
      ...restMemoryBoosters,
      lastAnswered: lastAnsweredMemoryBooster,
      units: memoryBoosterUnits.map((memoryBoosterUnit) => {
        const { sections: memoryBoosterSections, ...restMemoryBoosterUnit } =
          memoryBoosterUnit
        return {
          ...restMemoryBoosterUnit,
          sections: memoryBoosterSections.map((memoryBoosterSection) => {
            const {
              lastAnswered: lastAnsweredMemoryBoosterSection,
              ...restMemoryBoosterSection
            } = memoryBoosterSection
            return {
              ...restMemoryBoosterSection,
              lastAnswered: fromUTCToLocale(
                lastAnsweredMemoryBoosterSection,
                'M/D/YYYY',
              ),
            }
          }),
        }
      }),
    }
    if (!this.hasLoadedMemoryBoostersOnMount) {
      this.hasLoadedMemoryBoostersOnMount = true
    }
    this.isLoadingMemoryBoosters = false
    return this.memoryBoosters
  }
  @action handleLoadMemoryBoostersError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingMemoryBoosters = false
    return Promise.reject(error)
  }

  @action resetMemoryBooster() {
    this.memoryBooster = null
    this.memoryBoosterAnswer = null
    this.nextMemoryBooster = null
  }

  @action setMemoryBoosterAnswer(answer) {
    if (this.hasNextMemoryBooster) return
    this.memoryBoosterAnswer = answer
    return this.memoryBoosterAnswer
  }

  @action loadSnapshot() {
    const { courseId } = this.session
    this.isLoadingSnapshot = true
    return ContentStore.getCourseSnapshot(courseId)
      .then(this.handleLoadSnapshot)
      .catch(this.handleLoadSnapshotError)
  }
  @action handleLoadSnapshot = (response) => {
    this.isLoadingSnapshot = false
    const { data: responseData } = response
    const { displayTitle, id: courseId, title } = this.course
    const { isSnapshotStandalone, snapshotSkip, snapshotTestOut } =
      this.settings
    const {
      activity: activityData,
      activityCounter,
      pretestDone,
      sectionId: activitySectionId,
      totalActivities,
      unitId: activityUnitId,
    } = humps(responseData)
    let activity = null
    if (activityData) {
      activity = new Activity({ ...activityData })
    }
    this.snapshot = new Snapshot({
      activity,
      activityCounter,
      activitySectionId,
      activityUnitId,
      canSkip: snapshotSkip,
      canTestOut: snapshotTestOut,
      courseId,
      courseTitle: displayTitle || title,
      isDone: pretestDone,
      isIntro: activityCounter === 1,
      isOpen: !pretestDone || isSnapshotStandalone,
      isStandalone: isSnapshotStandalone,
      totalActivities,
    })
    return this.snapshot
  }
  @action handleLoadSnapshotError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingSnapshot = false
    return error
  }

  @action saveMemoryBooster(
    { memoryBoosterSectionId, memoryBoosterUnitId },
    skip = false,
  ) {
    const { courseId } = this.session
    if (this.isSavingMemoryBooster)
      return Promise.reject(new Error('Already saving activity;'))
    if (this.isSkippingMemoryBooster)
      return Promise.reject(new Error('Already skipping activity;'))
    if (!skip) {
      this.isSavingMemoryBooster = true
    } else {
      this.isSkippingMemoryBooster = true
    }
    const { id: memoryBoosterId, isMultipleInput } = this.memoryBooster || {}
    if (!this.memoryBooster || !memoryBoosterId) {
      return Promise.reject(new Error('No Memory Booster;'))
    }
    if (!this.memoryBoosterAnswer && !skip) {
      this.isSavingMemoryBooster = false
      this.isSkippingMemoryBooster = false
      return Promise.reject(new Error('No answer;'))
    }
    let POST = {}
    if (skip) {
      POST = { ...POST, skip: true }
    } else if (isMultipleInput) {
      POST = {
        ...POST,
        answers: this.memoryBooster.adaptAnswer(this.memoryBoosterAnswer),
        current_activity_id: memoryBoosterId,
      }
    } else {
      POST = {
        ...POST,
        answer: this.memoryBooster.adaptAnswer(this.memoryBoosterAnswer),
        current_activity_id: memoryBoosterId,
      }
    }
    this.error = null
    this.memoryBooster.validationError = null
    return request
      .post(
        `/activities/${courseId}/units/${memoryBoosterUnitId}/sections/${memoryBoosterSectionId}/activities/${memoryBoosterId}/validate_memory_booster`,
        POST,
      )
      .then((response) => this.handleSaveMemoryBooster(response, skip))
      .catch(this.handleSaveMemoryBoosterError)
  }
  @action handleSaveMemoryBooster = (response, skipped) => {
    const data = humps(response.data)

    if (data.activity) {
      this.memoryBooster.attempt = data.activity.attempt
      this.memoryBooster.skipped = skipped
      this.nextMemoryBooster = new Activity({ ...data.activity })
    } else {
      this.nextMemoryBooster = new Activity({ isNewActivity: true })
    }

    if (this.memoryBooster.skipped) {
      this.memoryBooster.isNewActivity = false
      this.memoryBooster.passed = false
    } else if (data.response) {
      this.memoryBooster.answerResponse = data.response.answer
      this.memoryBooster.correctAnswers = data.response.correctAnswers
      this.memoryBooster.hint = data.response.hint
      this.memoryBooster.incorrectAnswers = data.response.incorrectAnswers
      this.memoryBooster.isNewActivity = false
      this.memoryBooster.passed = data.response.passed
    }

    if (this.memoryBooster.goodToKnow)
      this.memoryBooster.replaceGoodToKnowMathFormulas()
    if (this.memoryBooster.hint) this.memoryBooster.replaceHintMathFormulas()

    this.isSavingMemoryBooster = false
    this.isSkippingMemoryBooster = false

    return this.memoryBooster.hasPassed
  }
  @action handleSaveMemoryBoosterError = (error) => {
    SentryReplay.captureException(error)
    this.nextMemoryBooster = null
    this.isSavingMemoryBooster = false
    this.isSkippingMemoryBooster = false
    // this.error = {
    //   message: error.message || 'Failed to load activity.',
    //   title: error.title || 'Something went wrong',
    //   status: error.response && error.response.status
    // }
    return this.handleSaveMemoryBoosterValidationError(error)
  }
  @action handleSaveMemoryBoosterValidationError(error) {
    // if (error.response) {
    //   if (error.response.status === 400 && error.response.data && error.response.data.message === 'Validation Error') {
    //     this.memoryBooster.passed = false
    //     this.memoryBooster.validationError = error.response.data.errors
    //   }
    // }
    return Promise.reject(error)
  }

  @action unlockUnit(unitId, code) {
    if (this.isUnlockingUnit) return
    const { courseId } = this.session
    this.isUnlockingUnit = true
    return request
      .post(`/activities/${courseId}/units/${unitId}/unlock`, { code })
      .then((response) => this.handleUnlockUnit(response))
      .catch((error) => this.handleUnlockUnitError(error))
  }
  @action handleUnlockUnit(response) {
    this.isUnlockingUnit = false
    return Promise.resolve(response.data)
  }
  @action handleUnlockUnitError(error) {
    const message =
      error && error.response && error.response.data
        ? error.response.data.errors || error.response.data.message
        : 'Something went wrong'
    SentryReplay.captureException(error)
    this.isUnlockingUnit = false
    return Promise.reject(message)
  }

  @computed get hasNextMemoryBooster() {
    return this.nextMemoryBooster && this.nextMemoryBooster.isNewActivity
  }

  @computed get sectionsProgress() {
    const { units } = this.course || {}
    const { isRequireMastery } = this.settings || {}
    let completedSections = 0
    let nSections = 0
    if (units) {
      units.forEach((unit) => {
        let { sections } = unit
        sections.forEach((section) => {
          const {
            isMasteredWithPretest,
            pretestMastered,
            rank,
            reattemptRank,
          } = section
          const requiredMasteryIncompletion =
            isRequireMastery &&
            !pretestMastered &&
            (!rank ||
              (rank === RANK_UNENGAGED && !reattemptRank) ||
              reattemptRank === RANK_UNENGAGED)
          if (isMasteredWithPretest && !requiredMasteryIncompletion) {
            completedSections++
          }
        })
        nSections += sections.length
      })
    }
    return { completedSections, nSections }
  }

  @computed get courseProgress() {
    const { units } = this.course || {}
    const { isRequireMastery, snapshotTestOut } = this.settings || {}
    let totalMastery = 0
    let totalSections = 0
    if (!units) return 0
    units.forEach((unit) => {
      unit.sections.forEach((section) => {
        const {
          mastered: sectionMastered,
          mastery: sectionMastery,
          pretestMastered: pretestMasteredSection,
          rank,
          reattemptRank,
          reattemptStatus,
        } = section
        let mastery = sectionMastered ? 100 : sectionMastery || 0
        if (
          isRequireMastery &&
          (!pretestMasteredSection || !snapshotTestOut) &&
          (!rank ||
            (rank === RANK_UNENGAGED && !reattemptRank) ||
            reattemptRank === RANK_UNENGAGED ||
            reattemptStatus === REATTEMPT_STATUS_STARTED)
        ) {
          mastery = 0
        }
        totalMastery += mastery
        totalSections++
      })
    })
    return Math.round(totalMastery / totalSections)
  }

  static getCourseSnapshot(courseId) {
    return request.get(`/activities/${courseId}/snapshot`, {
      params: {
        d: Date.now(),
      },
    })
  }
  static getCourseProgress(courseId) {
    return request.get(`/activities/${courseId}`, {
      params: {
        d: Date.now(),
      },
    })
  }
  static getErrorJSON(message) {
    let errorJSON
    try {
      errorJSON = JSON.parse(message)
    } catch (exception) {
      errorJSON = { exception: [message] }
    }
    return errorJSON
  }
  static getExamStatus(courseId) {
    return request.get(`/activities/${courseId}/exam`, {
      params: {
        d: Date.now(),
      },
    })
  }

  static convertFullCourseResponse(courseResponse, progressResponse, settings) {
    const { learnerDashboard, snapshotTestOut, requiredMasteryLevel } = settings

    // NOTE / TODO / WIP - apply pretest response to course / units / sections
    const { data: progressResponseData } = progressResponse || {}
    const {
      nextUp: nextUpResponse,
      practice: practiceResponse,
      preTest: pretestResponse,
    } = humps(progressResponseData)
    const { units: practiceUnits } = humps(practiceResponse)
    const { sectionId: nextUpSectionId, unitId: nextUpUnitId } =
      humps(nextUpResponse)

    const {
      course: pretestMasteredCourse,
      section: pretestSections,
      units: pretestUnits,
    } = pretestResponse
    const pretestMasteredSectionIds = {}
    const pretestMasteredUnitIds = {}
    pretestSections.forEach((pretestSectionId) => {
      pretestMasteredSectionIds[pretestSectionId] = true
    })
    pretestUnits.forEach((pretestUnitId) => {
      pretestMasteredUnitIds[pretestUnitId] = true
    })

    const { data: courseResponseData } = courseResponse || {}
    const { course: courseResponseCourse } = courseResponseData || {}
    const {
      customAccessCodeMessage: courseCustomAccessCodeMessage,
      displayTitle: courseDisplayTitle,
      faq: courseFaq,
      glossary: courseGlossary,
      id: courseId,
      logo: courseLogo,
      title: courseTitle,
      units: courseUnits,
    } = humps(courseResponseCourse)

    return {
      courseData: {
        customAccessCodeMessage: courseCustomAccessCodeMessage,
        displayTitle: courseDisplayTitle,
        id: courseId,
        faq: courseFaq,
        glossary: courseGlossary,
        logo: courseLogo,
        title: courseTitle,

        // from settings
        learnerDashboard,
        snapshotTestOut,

        pretestMastered: !!pretestMasteredCourse,

        // save nextup unit and section ids
        nextSectionId: nextUpSectionId,
        nextUnitId: nextUpUnitId,

        units: _.compact(
          courseUnits.map((unit, unitIndex) => {
            const {
              id: unitId,
              sections: unitSections,
              title: unitTitle,
            } = humps(unit)

            const {
              completed: unitCompleted,
              mastered: unitMastered,
              needsAccessCode: unitNeedsAccessCode,
              sections: unitPracticeSections,
            } = humps(
              (practiceUnits &&
                _.find(practiceUnits, (u) => u.id === unit.id)) ||
                {},
            )

            return {
              id: unitId,
              title: unitTitle,

              sections: _.compact(
                // unitSections.map((section, sectionIndex) => {
                unitSections.map((section) => {
                  const {
                    activityIds: sectionActivityIds,
                    id: sectionId,
                    lectureNotes: sectionLectureNotes,
                    title: sectionTitle,
                    type: sectionType,
                    video: sectionVideo,
                    videoId: sectionVideoId,
                  } = humps(section)

                  // also get locked from here when we can
                  const {
                    activityCounters: sectionActivitiesCounters,
                    completed: sectionCompleted,
                    hasQuiz: sectionHasQuiz,
                    locked: sectionLocked,
                    mastered: sectionMastered,
                    masteredQuiz: sectionMasteredQuiz,
                    rank: sectionRank,
                    reattemptRank: sectionReattemptRank,
                    reattemptStatus: sectionReattemptStatus,
                    // reattemptStatusUpdatedAt,
                    score: sectionScore,
                    totalScore: sectionTotalScore,
                  } = humps(
                    (unitPracticeSections &&
                      _.find(
                        unitPracticeSections,
                        (s) => s.id === sectionId,
                      )) || {
                      locked: true,
                      score: 0,
                      totalScore: 0,
                    },
                  )

                  return {
                    // from fields
                    activityIds: sectionActivityIds,
                    id: sectionId,

                    lectureNotes: sectionLectureNotes,
                    title: sectionTitle,
                    video: sectionVideo,
                    videoId: sectionVideoId,

                    // from settings
                    requiredMasteryLevel,

                    // if we have a progress section
                    sectionActivitiesCounters:
                      sectionActivitiesCounters &&
                      sectionReattemptStatus &&
                      sectionReattemptStatus !== REATTEMPT_STATUS_FINISHED
                        ? humps(sectionActivitiesCounters)
                        : null,
                    completed: sectionCompleted,
                    hasQuiz: sectionHasQuiz,
                    isReview:
                      !pretestMasteredSectionIds[sectionId] &&
                      (sectionReattemptStatus === REATTEMPT_STATUS_STARTED ||
                        sectionReattemptStatus === REATTEMPT_STATUS_FINISHED),
                    isReviewDone:
                      !pretestMasteredSectionIds[sectionId] &&
                      sectionReattemptStatus === REATTEMPT_STATUS_FINISHED,
                    locked: sectionLocked,
                    mastered: sectionMastered,
                    masteredQuiz: sectionMasteredQuiz,
                    pretestMastered: !!pretestMasteredSectionIds[sectionId],
                    rank: sectionRank,
                    reattemptRank: sectionReattemptRank,
                    reattemptStatus: sectionReattemptStatus,
                    score: sectionScore || 0, // it can be null,
                    totalScore: sectionTotalScore || 0,
                    type: sectionType,
                  }
                }),
              ),

              completed: unitCompleted,
              index: unitIndex,
              mastered: unitMastered,
              needsAccessCode: unitNeedsAccessCode,
              pretestMastered: !!pretestMasteredUnitIds[unitId],
            }
          }),
        ),
      },
      progressData: { pretestMasteredSectionIds },
    }
  }
}
