import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import cx from 'classnames'
import _ from 'lodash'
import { Loader, Button } from '@/components'
import SearchResults from './SearchResults/SearchResults'
import { Input } from '@/components/form'
import './SearchPage.scss'

const SearchPageMessages = defineMessages({
  descriptionEmpty: {
    defaultMessage: 'Everything in the course is available for you to search.',
    description:
      'Description displayed when a search term has not been set on the search page.',
    id: 'SearchPage.descriptionEmpty',
  },
  descriptionNoResults: {
    defaultMessage: 'Sorry, nothing in this course matches that keyword.',
    description:
      'Description displayed when there are no matching items on the search page.',
    id: 'SearchPage.descriptionNoResults',
  },
  placeholderSearch: {
    defaultMessage: 'Search this course',
    description: 'Placeholder text for the search field on the search page.',
    id: 'SearchPage.placeholderSearch',
  },
  resultCount: {
    defaultMessage: '{resultCount} results for {searchTerm}',
    description:
      'Label to display the number of matching results for a search term on the search page.',
    id: 'SearchPage.resultCount',
  },
  search: {
    defaultMessage: 'Search',
    description: 'Button to submit a search term on the search page.',
    id: 'SearchPage.search',
  },
  searching: {
    defaultMessage: 'Searching...',
    description:
      'Button to submit a search term while a search is being conducted on the search page.',
    id: 'SearchPage.searching',
  },
  titleEmpty: {
    defaultMessage:
      'Search is a resource for finding information relevant to a keyword.',
    description:
      'Title displayed when a search term has not been set on the search page.',
    id: 'SearchPage.titleEmpty',
  },
  titleNoResults: {
    defaultMessage: `We couldn't find any results.`,
    description:
      'Title displayed when there are no matching items on the search page.',
    id: 'SearchPage.titleNoResults',
  },
})

@injectIntl
@inject('searchStore', 'uiStore')
@observer
export default class SearchPage extends Component {
  constructor(props) {
    super(props)
    const queries = qs.parse(props.location.search)
    this.state = { queries, term: queries.term || '' }

    this.props.searchStore.search(this.state.queries)
  }
  componentDidMount() {
    const { uiStore } = this.props
    uiStore.updateTitle('Search Page')
  }
  componentDidUpdate(prevProps) {
    const { location, searchStore } = this.props
    const { search } = location || {}
    const { location: prevLocation } = prevProps
    const { search: prevSearch } = prevLocation || {}
    const parsedSearch = qs.parse(search)
    const { term: parsedSearchTerm } = parsedSearch || { term: '' }
    if (search !== prevSearch) {
      this.setState({ queries: parsedSearch, term: parsedSearchTerm })
      searchStore.search(parsedSearch)
    }
  }
  componentWillUnmount() {
    const { searchStore } = this.props
    searchStore.clean()
  }
  onChange = ({ target }) => {
    this.setState({ term: target.value })
  }
  onKeyPress = (e) => {
    if (e.key !== 'Enter') return
    this.onSearch()
  }
  onSearch = () => {
    this.search({ term: this.state.term })
  }
  onChangeCategory = (event, category) => {
    event.preventDefault()
    this.search({ category })
  }
  search(query) {
    const queries = {
      ...this.state.queries,
      ...query,
    }
    if (query.category === '__NONE__') {
      delete queries.category
    }
    this.props.history.push(`/search?${qs.stringify(queries)}`)
  }
  renderResults() {
    const { result } = this.props.searchStore

    if (!result) {
      return this.renderEmptyState()
    }

    if (result.results.length === 0) {
      return this.renderNoResults()
    }

    return (
      <div className="columns is-tablet">
        <div className="column is-3">
          {this.renderCategories(result.categories)}
        </div>
        <div className="column">
          <SearchResults results={result.results} />
        </div>
      </div>
    )
  }
  renderCategories(categories) {
    return (
      <ul className="Search-tabs">
        {categories.map((tab, index) => (
          <li key={`search-tab-${index}`}>
            <a
              className={cx('Search-tab level is-mobile', {
                'is-active': tab.selected,
              })}
              href="#"
              onClick={(event) => this.onChangeCategory(event, tab.value)}
            >
              <div className="level-left">{tab.title}</div>
              <div className="level-right">{tab.count}</div>
            </a>
          </li>
        ))}
      </ul>
    )
  }
  renderEmptyState() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="Search-message is-success">
        <div className="title">
          {formatMessage(SearchPageMessages.titleEmpty)}
        </div>
        <p>{formatMessage(SearchPageMessages.descriptionEmpty)}</p>
      </div>
    )
  }
  renderNoResults() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="Search-message">
        <div className="title">
          {formatMessage(SearchPageMessages.titleNoResults)}
        </div>
        <p>{formatMessage(SearchPageMessages.descriptionNoResults)}</p>
      </div>
    )
  }
  renderSearchField() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="field has-addons">
        <div className="is-expanded">
          <Input
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            placeholder={formatMessage(SearchPageMessages.placeholderSearch)}
            value={this.state.term}
          />
        </div>
        <div>
          <Button isSuccess onClick={this.onSearch}>
            {formatMessage(SearchPageMessages.search)}
          </Button>
        </div>
      </div>
    )
  }
  render() {
    const { intl, searchStore } = this.props
    const { formatMessage } = intl
    const { isLoading } = searchStore

    const { result } = searchStore

    const { category } = this.state.queries
    let resultCount = 0
    let currCategory = null

    if (result) {
      resultCount = result.count

      if (category !== '__NONE__') {
        currCategory = _.find(result.categories, (c) => c.value === category)
        if (currCategory) resultCount = currCategory.count
      }
    }

    return (
      <div id="main-content" className="PageWrapper">
        <div className="container Container">
          <div className="Search">
            <div className="level Search-header is-left">
              <div className="level-left">{this.renderSearchField()}</div>
              <div className="level-item">
                {result && this.state.queries.term && !isLoading && (
                  <p className="Search-count">
                    <FormattedMessage
                      {...SearchPageMessages.resultCount}
                      values={{
                        resultCount,
                        searchTerm: (
                          <span className="Search-term">
                            &quot;{this.state.queries.term}&quot;
                          </span>
                        ),
                      }}
                    />
                  </p>
                )}
              </div>
            </div>
            <div className="Search-content">
              {isLoading && (
                <Loader
                  isOverlay
                  text={formatMessage(SearchPageMessages.searching)}
                />
              )}
              {this.renderResults()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
