import React, { Fragment } from 'react'

const InstanceSettingsPageSavedLabel = () => {
  return (
    <Fragment>
      &nbsp; &nbsp;
      <span className="InstanceSettingsPage__saved-label">
        <i className="fa fa-check" />
        Saved
      </span>
    </Fragment>
  )
}

export default InstanceSettingsPageSavedLabel
