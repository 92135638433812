import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import {
  RANK_ALL_STAR,
  RANK_PROFICIENT,
  RANK_UNENGAGED,
  REATTEMPT_STATUS_FINISHED,
  // REATTEMPT_STATUS_READY,
  REATTEMPT_STATUS_STARTED,
  // REQUIRE_MASTERY,
} from '@/constants'
import {
  Button,
  CourseCompletionMessageBanner,
  CourseIntroBanner,
  ExamBanner,
  Heading,
  // NOTE / TODO / WIP - make first login message banner client specific
  // FirstLoginMessageBanner,
  Link,
  Loader,
  MemoryBoosterPanel,
  ProgressChart,
  RadialProgress,
  ReattemptIntroModal,
} from '@/components'
import { StudentLayout } from '@/layouts'
import SummaryPage from '../SummaryPage/SummaryPage'
import Pathway from './Pathway'
import './DashboardPage.scss'
const DashboardPageMessages = defineMessages({
  activityPerformanceEmpty: {
    defaultMessage: `Please check back after you've completed a Section.`,
    description: `Description displayed for a user's activity performance when the user has not any sections yet on the dashboard page.`,
    id: 'DashboardPage.activityPerformanceEmpty',
  },
  activityPerformanceTitle: {
    defaultMessage: 'Performance',
    description: `Title displayed for a user's activity performance on the dashboard page.`,
    id: 'DashboardPage.activityPerformanceTitle',
  },
  courseHeader: {
    defaultMessage: 'Course:',
    description: 'Label displayed for course title on the dashboard page.',
    id: 'DashboardPage.courseHeader',
  },
  continueButton: {
    defaultMessage: 'Continue',
    description:
      'Button to navigate to the next section to continue progress in the course from the dashboard page.',
    id: 'DashboardPage.continueButton',
  },
  continueButtonNoProgress: {
    defaultMessage: 'Start Learning',
    description:
      'Button to navigate to the first section to begin the course from the dashboard page.',
    id: 'DashboardPage.continueButtonNoProgress',
  },
  demonstrateMastery: {
    defaultMessage:
      'Demonstrating consistent mastery of the material will significantly impact your time savings',
    description:
      'Last sentence of noProgress intro text describing the ability to save time.',
    id: 'DashboardPage.demonstrateMastery',
  },
  inAnyOrderCta: {
    defaultMessage: 'in any order you want',
    description:
      'Call to action within the noProgress text displayed when a user has not made any progress in the course on the dashboard page.',
    id: 'DashboardPage.inAnyOrderCta',
  },
  learningTipAdaptive: {
    defaultMessage:
      'This course skips over material you already know once you have shown consistent understanding.',
    description:
      'Learning tip to describe the adaptivity of the platform displayed on the dashboard page.',
    id: 'DashboardPage.learningTipAdaptive',
  },
  learningTipApply: {
    defaultMessage:
      'Think about how the ideas you are learning apply to your own experiences.',
    description: `Learning tip to describe how to relate course material to a user's personal experiences on the dashboard page.`,
    id: 'DashboardPage.learningTipApply',
  },
  learningTipDeconstruct: {
    defaultMessage:
      'The key to learning anything is breaking the material down into simple concepts.',
    description:
      'Learning tip to describe effective methods of deconstructing learning material into consumable chunks on the dashboard page.',
    id: 'DashboardPage.learningTipDeconstruct',
  },
  learningTipExercise: {
    defaultMessage:
      'Exercising after learning helps some learners remember the knowledge more accurately.',
    description:
      'Learning tip to describe methods that may help with memory retention of course material on the dashboard page.',
    id: 'DashboardPage.learningTipExercise',
  },
  learningTipFun: {
    defaultMessage:
      'We learn best while having fun and being engaged and challenged.',
    description:
      'Learning tip to describe factors that can contribute to learning more effectively on the dashboard page.',
    id: 'DashboardPage.learningTipFun',
  },
  learningTipMaster: {
    defaultMessage: `You've mastered a concept when you can teach it to someone else.`,
    description:
      'Learning tip to describe the concept of mastery in a subject on the dashboard page.',
    id: 'DashboardPage.learningTipMaster',
  },
  learningTipPeak: {
    defaultMessage:
      'Is your focus sharper in the morning or the evening? Try learning during your peak focus times.',
    description:
      'Learning tip to describe how focus can be affected by the time learning is done on the dashboard page.',
    id: 'DashboardPage.learningTipPeak',
  },
  learningTipRelax: {
    defaultMessage: `Make sure you are feeling relaxed before starting a learning session. A distracted mind cannot learn well.`,
    description:
      'Learning tip to describe how state of mind can assist with a more productive learning session on the dashboard page.',
    id: 'DashboardPage.learningTipRelax',
  },
  learningTipRemember: {
    defaultMessage: `Remember, you're in control. You can jump into any learning choice you see fit: Watch, Read or Practice. The choice is always yours whenever, wherever.`,
    description:
      'Learning tip to describe how story telling can help memory retention on the dashboard page.',
    id: 'DashboardPage.learningTipRemember',
  },
  learningTipSleep: {
    defaultMessage:
      'Work on getting 7 to 8 hours of quality sleep. Long-term memory actually happens while you sleep.',
    description:
      'Learning tip to describe how quality of sleep can affect long-term memory on the dashboard page.',
    id: 'DashboardPage.learningTipSleep',
  },
  learningTipStuck: {
    defaultMessage:
      'When you feel stuck, slow down and research the material before going back to Practice.',
    description:
      'Learning tip to describe how to help avoid getting stuck on material dislpayed on the dashboard page.',
    id: 'DashboardPage.learningTipStuck',
  },
  learningTipsDescription: {
    defaultMessage:
      'This course will save you time and energy. Here are some tips to get the most out of it.',
    description:
      'Description for the learning tips displayed on the dashboard page.',
    id: 'DashboardPage.learningTipsDescription',
  },
  learningTipsNext: {
    defaultMessage: 'Next Learning Tip',
    description: 'ARIA label for the next learning tip button.',
    id: 'DashboardPage.learningTipsNext',
  },
  learningTipsPrevious: {
    defaultMessage: 'Previous Learning Tip',
    description: 'ARIA label for the previous learning tip button.',
    id: 'DashboardPage.learningTipsPrevious',
  },
  learningTipsTitle: {
    defaultMessage: 'Tips',
    description: 'Title for the learning tips displayed on the dashboard page.',
    id: 'DashboardPage.learningTipsTitle',
  },
  memoryBoostersCompleted: {
    defaultMessage: 'Memory Boosters Completed: {numMemoryBoostersCompleted}',
    id: 'DashboardPage.memoryBoostersCompleted',
  },
  memoryBoostersDescription: {
    defaultMessage:
      'Review Sections you already completed to refresh your knowledge.',
    id: 'DashboardPage.memoryBoostersDescription',
  },
  memoryBoostersOpenButton: {
    defaultMessage: 'Refresh Memory',
    id: 'DashboardPage.memoryBoostersOpenButton',
  },
  memoryBoostersTitle: {
    defaultMessage: 'Memory Boosters',
    id: 'DashboardPage.memoryBoostersTitle',
  },
  noProgress: {
    defaultMessage:
      'By clicking {continueButtonNoProgress} below, you will enter your first section. Within each Section, you can {watchTab}, {readTab}, or {practiceTab}. The choice is always yours - do any or all of them, {inAnyOrderCta}. Work through the {practiceTab} questions until you reach 100% completion. {demonstrateMastery}.',
    description:
      'Description for how to proceed with the course when a user first logs in and has not completed any sections on the dashboard page.',
    id: 'DashboardPage.noProgress',
  },
  noProgressSubtext: {
    defaultMessage: `This page is your Learner Dashboard. Return here after you've completed some Sections to see your performance summary.`,
    description:
      'Subtext for how to proceed with the course when a user first logs in and has not completed any sections on the dashboard page.',
    id: 'DashboardPage.noProgressSubtext',
  },
  performanceDescriptionAtRisk: {
    defaultMessage:
      'It appears you may be rushing. As a result, you will need to spend more time answering additional questions. Focus on getting a streak of correct answers per Section. Your performance score and confidence will improve, and you will complete the course faster.',
    description:
      'Description for user performance in the course is at risk on the dashboard page.',
    id: 'DashboardPage.performanceDescriptionAtRisk',
  },
  performanceDescriptionGood: {
    defaultMessage: `Nice work! Research your answers and keep building up those correct answer streaks! We are personalizing your learning by skipping material you already know.`,
    description:
      'Description for when a user performance in the course is proficient on the dashboard page.',
    id: 'DashboardPage.performanceDescriptionGood',
  },
  performanceDescriptionAllStar: {
    defaultMessage: `You're performing at very high levels! The system is moving you through fast and keeping you challenged. Keep up your excellent performance score.`,
    description:
      'Description for when a user performance in the course is all star level on the dashboard page.',
    id: 'DashboardPage.performanceDescriptionAllStar',
  },
  practiceTab: {
    defaultMessage: 'Practice',
    description: 'Label for the practice tab displayed on the dashboard page.',
    id: 'DashboardPage.practiceTab',
  },
  progressChartAverageLabel: {
    defaultMessage: 'Average',
    description:
      'Label for the minimum progress for all users in a course displayed on the dashboard page.',
    id: 'DashboardPage.progressChartAverageLabel',
  },
  progressChartValueLabel: {
    defaultMessage: 'You',
    description: `Label for the current user's progress for all users in a course displayed on the dashboard page.`,
    id: 'DashboardPage.progressChartValueLabel',
  },
  progressHeader: {
    defaultMessage: 'Course Progress',
    description: 'Label for course progress displayed on the dashboard page.',
    id: 'DashboardPage.progressHeader',
  },
  readTab: {
    defaultMessage: 'Read',
    description: 'Label for the practice tab displayed on the dashboard page.',
    id: 'DashboardPage.readTab',
  },
  sectionsProgress: {
    defaultMessage: `{completedSections}/{nSections} {nSections, plural,
      one {Section}
      other {Sections}
    } Completed`,
    description:
      'Label for the number of sections completed in a course displayed on the dashboard page.',
    id: 'DashboardPage.sectionsProgress',
  },
  topSectionsDescription: {
    defaultMessage:
      'To date, you have performed the best on the following Sections. Nice work!',
    description: 'Description for Top Sections section on the dashboard page.',
    id: 'DashboardPage.topSectionsDescription',
  },
  topSectionsTitle: {
    defaultMessage: 'Top Performing Sections',
    description: 'Title for Top Sections section on the dashboard page.',
    id: 'DashboardPage.topSectionsTitle',
  },
  upNextText: {
    defaultMessage: 'Next Section:',
    description:
      'Label for the next section to continue course progress displayed on the dashboard page.',
    id: 'DashboardPage.upNextText',
  },
  upNextTextCourseMastered: {
    defaultMessage: `You've covered all of the material!`,
    description:
      'Description for when all sections in a course have been completed displayed on the dashboard page.',
    id: 'DashboardPage.upNextTextCourseMastered',
  },
  upNextTextNoProgress: {
    defaultMessage: 'First Section:',
    description:
      'Label for the first section in a course when starting learning on the dashboard page.',
    id: 'DashboardPage.upNextTextNoProgress',
  },
  viewCourseButtonText: {
    defaultMessage: 'View Course',
    description:
      'Button to navigate to the course summary page to view the entire structure of the course from the dashboard page.',
    id: 'DashboardPage.viewCourseButtonText',
  },
  watchTab: {
    defaultMessage: 'Watch',
    description: 'Label for the watch tab displayed on the dashboard page.',
    id: 'DashboardPage.watchTab',
  },
})

const SummaryPageWithRouter = withRouter(SummaryPage)

const performanceDescriptionCopy = {
  [RANK_UNENGAGED]: DashboardPageMessages.performanceDescriptionAtRisk,
  [RANK_PROFICIENT]: DashboardPageMessages.performanceDescriptionGood,
  [RANK_ALL_STAR]: DashboardPageMessages.performanceDescriptionAllStar,
}

const learningTips = [
  DashboardPageMessages.learningTipRelax,
  DashboardPageMessages.learningTipApply,
  DashboardPageMessages.learningTipExercise,
  DashboardPageMessages.learningTipSleep,
  DashboardPageMessages.learningTipPeak,
  DashboardPageMessages.learningTipDeconstruct,
  DashboardPageMessages.learningTipRemember,
  DashboardPageMessages.learningTipFun,
  DashboardPageMessages.learningTipMaster,
  DashboardPageMessages.learningTipStuck,
  DashboardPageMessages.learningTipAdaptive,
]

@injectIntl
@inject('contentStore', 'reportingStore', 'sessionStore', 'uiStore')
@observer
export default class DashboardPage extends Component {
  state = {
    activeAccordionUnit: null,
    isLoading: true,
    learningTipsIndex: Math.floor(Math.random() * learningTips.length),
  }
  get memoryBoostersAvailable() {
    let memoryBoostersAvailable = 0
    this.memoryBoostersByUnit.forEach((unit) => {
      const { sections } = unit
      memoryBoostersAvailable += sections.length
    })
    return memoryBoostersAvailable
  }
  get memoryBoostersByUnit() {
    const { contentStore } = this.props
    const { memoryBoosters } = contentStore
    const { units: memoryBoostersByUnit } = memoryBoosters
    return memoryBoostersByUnit || []
  }
  get sectionsAsObject() {
    const { contentStore } = this.props
    const { course } = contentStore
    const { units } = course
    const sections = {}
    units.forEach((unit) => {
      unit.sections.forEach((section) => {
        sections[section.id] = section
      })
    })
    return sections
  }
  get hasMasteredSection() {
    const { reportingStore } = this.props
    const { learner } = reportingStore
    const { sections } = learner || {}
    if (sections && sections.length) {
      let i = 0
      for (; i < sections.length; i++) {
        const {
          pretestMastered: pretestMasteredSection,
          rank,
          reattemptRank,
        } = sections[i]
        if (!pretestMasteredSection && (rank || reattemptRank)) return true
      }
    }
    return false
  }
  get hasProgress() {
    const { contentStore } = this.props
    const { course, courseProgress } = contentStore
    return course && courseProgress > 0
  }
  get requiredMasteryWithIncompleteSections() {
    const { contentStore, reportingStore } = this.props
    const { learner } = reportingStore
    const { settings } = contentStore
    const { isRequireMastery, snapshotTestOut } = settings || {}
    const { sections } = learner || {}
    if (sections && sections.length) {
      let i = 0
      for (; i < sections.length; i++) {
        const {
          rank,
          reattemptRank,
          reattemptStatus,
          testedOut: testedOutSection,
        } = sections[i]
        if (
          isRequireMastery &&
          (!testedOutSection || !snapshotTestOut) &&
          (!rank ||
            (rank === RANK_UNENGAGED && !reattemptRank) ||
            reattemptRank === RANK_UNENGAGED ||
            reattemptStatus === REATTEMPT_STATUS_STARTED)
        ) {
          return true
        }
      }
    }
    return false
  }
  get sectionsProgress() {
    const { contentStore } = this.props
    const { sectionsProgress } = contentStore
    const { completedSections, nSections } = sectionsProgress
    return { completedSections, nSections }
  }
  componentDidMount() {
    const { contentStore, reportingStore, sessionStore, uiStore } = this.props
    const { settings } = contentStore
    const { isDisabledDashboard } = settings || {}
    if (!isDisabledDashboard) {
      reportingStore.createLearner({
        firstName: sessionStore.user.firstName,
        lastName: sessionStore.user.lastName,
      })
      reportingStore.refreshReport()
      reportingStore
        .loadCourse()
        .then(() => reportingStore.loadLearner(sessionStore.user.roleId))
        .then(() => {
          this.setState({ isLoading: false })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    } else {
      this.setState({ isLoading: false })
    }

    uiStore.updateTitle('Dashboard Page')
  }
  onAccordionUnitChange = (activeAccordionUnit) => {
    this.setState({ activeAccordionUnit })
  }
  onConfirmReattemptIntroModal = () => {
    const { uiStore } = this.props
    uiStore.closeReattemptIntroModal()
  }
  // onCloseFirstLoginMessage = () => {
  //   const { uiStore } = this.props
  //   uiStore.closeFirstLoginMessage()
  // }
  onContinue = () => {
    const { course } = this.props.contentStore
    const firstTabHref = course.nextSection.tabs[0].href
    this.props.history.push(firstTabHref)
  }
  onNextLearningTip = () => {
    const { learningTipsIndex } = this.state
    if (learningTipsIndex === learningTips.length - 1) {
      this.setState({ learningTipsIndex: 0 })
    } else {
      this.setState({ learningTipsIndex: learningTipsIndex + 1 })
    }
  }
  onPrevLearningTip = () => {
    const { learningTipsIndex } = this.state
    if (learningTipsIndex === 0) {
      this.setState({ learningTipsIndex: learningTips.length - 1 })
    } else {
      this.setState({ learningTipsIndex: learningTipsIndex - 1 })
    }
  }
  renderMemoryBoosters() {
    const { contentStore, intl, uiStore } = this.props
    const { memoryBoosters } = contentStore
    const { openMemoryBoosterPanelModal } = uiStore
    const { formatMessage } = intl
    const { finishedMemoryBoosters } = memoryBoosters || {}
    return (
      <div className="DashboardPage-memoryBoosters">
        <div className="columns">
          <div className="column">
            <h3 className="DashboardPage-memoryBoostersTitle">
              {formatMessage(DashboardPageMessages.memoryBoostersTitle)}
            </h3>
            <p className="DashboardPage-memoryBoostersDescription">
              {formatMessage(DashboardPageMessages.memoryBoostersDescription)}
            </p>
          </div>
          <div className="column is-right">
            <h4>
              {formatMessage(DashboardPageMessages.memoryBoostersCompleted, {
                numMemoryBoostersCompleted: !memoryBoosters
                  ? '-'
                  : finishedMemoryBoosters || 0,
              })}
            </h4>
            <Button
              className="DashboardPage-memoryBoostersButton"
              isInfo
              onClick={openMemoryBoosterPanelModal}
            >
              <Fragment>
                <span>
                  {formatMessage(
                    DashboardPageMessages.memoryBoostersOpenButton,
                  )}
                </span>
                {memoryBoosters && this.memoryBoostersAvailable > 0 ? (
                  <span className="DashboardPage-memoryBoostersButton__count">
                    {this.memoryBoostersAvailable}
                  </span>
                ) : null}
              </Fragment>
            </Button>
          </div>
        </div>
      </div>
    )
  }
  renderProgress(isMobile = false) {
    const { contentStore, intl } = this.props
    const { completedSections, nSections } = this.sectionsProgress
    const { courseProgress } = contentStore
    const { formatMessage } = intl
    return (
      <div className={cx('DashboardPage-progress', { 'is-mobile': isMobile })}>
        <RadialProgress
          className="DashboardPage-radialProgress"
          isAlt
          value={courseProgress}
        />
        <div className="DashboardPage-progressCopy">
          <h3 className="DashboardPage-progressHeader">
            {formatMessage(DashboardPageMessages.progressHeader)}
          </h3>
          <span className="DashboardPage-sectionsProgress">
            {formatMessage(DashboardPageMessages.sectionsProgress, {
              completedSections,
              nSections,
            })}
          </span>
        </div>
      </div>
    )
  }
  renderContinue() {
    const { contentStore, history, intl } = this.props
    const hasProgress =
      this.hasProgress ||
      this.hasMasteredSection ||
      this.requiredMasteryWithIncompleteSections
    const { course } = contentStore
    const { formatMessage } = intl
    const { isMasteredWithPretest: isMasteredWithPretestCourse } = course
    const isMastered = hasProgress && isMasteredWithPretestCourse
    const upNextText = hasProgress
      ? DashboardPageMessages.upNextText
      : DashboardPageMessages.upNextTextNoProgress
    const continueButtonText = hasProgress
      ? DashboardPageMessages.continueButton
      : DashboardPageMessages.continueButtonNoProgress
    return (
      <div className="DashboardPage-continue">
        <div />
        <div className="DashboardPage-courseInfo">
          <Heading className="DashboardPage__title">
            <span className="DashboardPage-headerCourse">
              {formatMessage(DashboardPageMessages.courseHeader)}&nbsp;
            </span>
            <span className="is-bold">
              {course.displayTitle || course.title}
            </span>
          </Heading>
          <h2 className="DashboardPage-upNext">
            {isMastered ? (
              <span>
                {formatMessage(DashboardPageMessages.upNextTextCourseMastered)}
              </span>
            ) : (
              <Fragment>
                <span>{formatMessage(upNextText)}&nbsp;</span>
                <span>{course.nextSection.title}</span>
              </Fragment>
            )}
          </h2>
        </div>
        <div className="DashboardPage-continueButtons">
          <Button
            className="DashboardPage-viewCourse"
            onClick={() => history.push('/dashboard/course')}
          >
            {formatMessage(DashboardPageMessages.viewCourseButtonText)}
          </Button>
          {!isMastered ? (
            <Button
              className="DashboardPage-continueBtn"
              isPrimary={!hasProgress}
              isSuccess={hasProgress}
              onClick={this.onContinue}
            >
              {formatMessage(continueButtonText)}
            </Button>
          ) : null}
        </div>
      </div>
    )
  }
  renderPathway() {
    const { contentStore, reportingStore, intl } = this.props
    const courseSections = this.sectionsAsObject
    const { course, pretestMasteredSectionIds, sectionsProgress, settings } =
      contentStore
    const { isRequireMastery } = settings || {}
    const { nSections: numSections } = sectionsProgress || {}
    const { isLoadingLearner, isNotFoundLearner, learner } = reportingStore
    const {
      isMasteredWithPretestAndReattempts:
        isMasteredWithPretestAndReattemptsCourse,
      pretestMastered: pretestMasteredCourse,
      isMasteredWithPretest: isMasteredWithPretestCourse,
    } = course || {}
    const { sections } = learner
    const sectionsAtRisk = sections.filter((section) => {
      const {
        contentfulId: sectionContentfulId,
        rank: sectionRank,
        reattemptRank,
        reattemptStatus,
      } = section
      const { mastered } = courseSections[sectionContentfulId] || {}
      return (
        !pretestMasteredSectionIds[sectionContentfulId] &&
        mastered &&
        sectionRank === RANK_UNENGAGED &&
        (reattemptStatus !== REATTEMPT_STATUS_FINISHED ||
          (reattemptStatus === REATTEMPT_STATUS_FINISHED &&
            reattemptRank !== RANK_PROFICIENT &&
            reattemptRank !== RANK_ALL_STAR))
      )
    })
    return (
      <Pathway
        courseSections={courseSections}
        intl={intl}
        isLoadingLearner={isLoadingLearner}
        isMasteredWithPretestAndReattemptsCourse={
          isMasteredWithPretestAndReattemptsCourse
        }
        isMasteredWithPretestCourse={isMasteredWithPretestCourse}
        isNotFoundLearner={isNotFoundLearner}
        isRequireMastery={isRequireMastery}
        numSections={numSections}
        onClickPathway={this.onClickPathway}
        pretestMasteredCourse={pretestMasteredCourse}
        sectionsAtRisk={sectionsAtRisk}
      />
    )
  }
  onClickPathway = (unitContentfulId, sectionContentfulId) => {
    const { contentStore } = this.props
    const { course } = contentStore
    const unit = course.findUnitById(unitContentfulId)
    if (!unit) return
    const section = unit.findSectionById(sectionContentfulId)
    if (!section) return
    section.startReview()
  }
  renderPerformance() {
    const { intl, reportingStore } = this.props
    const { formatMessage } = intl
    const {
      activityPerformanceAverage,
      // NOTE / TEMP / TODO / WIP - replace average with minimum if required
      // activityPerformanceMinimum,
      activityPerformanceTheme,
      isNotFoundLearner,
      learner,
    } = reportingStore
    const { firstAttempt } = learner
    const hasNoActivity = !firstAttempt && firstAttempt !== 0
    return (
      <div className="DashboardPage-activityPerformance">
        <Heading is3>
          {formatMessage(DashboardPageMessages.activityPerformanceTitle)}
        </Heading>
        <div className="DashboardPage-activityPerformanceDescription">
          {!isNotFoundLearner && !hasNoActivity ? (
            <p>
              {formatMessage(
                performanceDescriptionCopy[activityPerformanceTheme],
              )}
            </p>
          ) : null}
        </div>
        <div className="DashboardPage-activityPerformanceBar">
          {isNotFoundLearner || hasNoActivity ? (
            <div className="DashboardPage-emptyMessage">
              <p>
                {formatMessage(DashboardPageMessages.activityPerformanceEmpty)}
              </p>
            </div>
          ) : (
            <ProgressChart
              average={activityPerformanceAverage}
              averageLabel={formatMessage(
                DashboardPageMessages.progressChartAverageLabel,
              )}
              isHiddenAverage={
                !activityPerformanceAverage || activityPerformanceAverage === 0
              }
              isSimple
              theme={activityPerformanceTheme}
              value={firstAttempt}
              valueLabel={formatMessage(
                DashboardPageMessages.progressChartValueLabel,
              )}
            />
          )}
        </div>
      </div>
    )
  }
  renderLearningTips() {
    const { intl } = this.props
    const { learningTipsIndex } = this.state
    const { formatMessage } = intl
    return (
      <div className="DashboardPage-learningTips">
        <Heading is3>
          {formatMessage(DashboardPageMessages.learningTipsTitle)}
        </Heading>
        <p className="DashboardPage-learningTipsDescription">
          {formatMessage(DashboardPageMessages.learningTipsDescription)}
        </p>
        <div className="DashboardPage-learningTipsBlueTip">
          <span className="DashboardPage-learningTip">
            {formatMessage(learningTips[learningTipsIndex])}
          </span>
          <Button
            aria-label={formatMessage(
              DashboardPageMessages.learningTipsPrevious,
            )}
            className="DashboardPage-learningTipControl DashboardPage-learningTipPrevious"
            onClick={this.onPrevLearningTip}
          >
            <i aria-hidden="true" className="fa fa-angle-left" />
          </Button>
          <Button
            aria-label={formatMessage(DashboardPageMessages.learningTipsNext)}
            className="DashboardPage-learningTipControl DashboardPage-learningTipNext"
            onClick={this.onNextLearningTip}
          >
            <i aria-hidden="true" className="fa fa-angle-right" />
          </Button>
        </div>
      </div>
    )
  }
  renderNoProgress() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="DashboardPage-noProgress">
        <p>
          <FormattedMessage
            {...DashboardPageMessages.noProgress}
            values={{
              continueButtonNoProgress: (
                <span className="is-bold">
                  {formatMessage(
                    DashboardPageMessages.continueButtonNoProgress,
                  )}
                </span>
              ),
              demonstrateMastery: (
                <span className="is-bold">
                  {formatMessage(DashboardPageMessages.demonstrateMastery)}
                </span>
              ),
              inAnyOrderCta: (
                <span className="is-underlined">
                  {formatMessage(DashboardPageMessages.inAnyOrderCta)}
                </span>
              ),
              practiceTab: (
                <span className="is-bold">
                  {formatMessage(DashboardPageMessages.practiceTab)}
                </span>
              ),
              readTab: (
                <span className="is-bold">
                  {formatMessage(DashboardPageMessages.readTab)}
                </span>
              ),
              watchTab: (
                <span className="is-bold">
                  {formatMessage(DashboardPageMessages.watchTab)}
                </span>
              ),
            }}
          />
        </p>
        <br />
        <p>{formatMessage(DashboardPageMessages.noProgressSubtext)}</p>
      </div>
    )
  }
  renderBanner() {
    const {
      contentStore,
      reportingStore,
      // uiStore
    } = this.props
    const { course, isLoadingCourseSettings, settings } = contentStore
    const { isLoadingLearner, isNotFoundLearner, learner } = reportingStore
    // NOTE / TODO / WIP - make first login message banner client specific
    // const { isOpenFirstLoginMessage } = uiStore
    // NOTE / TODO / WIP - make first login message banner client specific
    // const { mastery: courseMastery, numSections, title: courseTitle } = course || {}
    const {
      isMasteredWithPretest: isMasteredWithPretestCourse,
      numSections,
      pretestMastered: pretestMasteredCourse,
    } = course || {}
    const { sections } = learner || {}
    const {
      courseCompletionCopy,
      courseIntro,
      courseMasteryCopy,
      exam,
      isRequireMastery,
    } = settings || {}
    if (isLoadingCourseSettings || isLoadingLearner) return null
    const requiredMasteryIncompletion =
      isRequireMastery && this.requiredMasteryWithIncompleteSections
    if (!exam && isMasteredWithPretestCourse && !requiredMasteryIncompletion) {
      const courseSections = this.sectionsAsObject
      return (
        <CourseCompletionMessageBanner
          courseCompletionCopy={courseCompletionCopy}
          courseMasteryCopy={courseMasteryCopy}
          isNotFoundLearner={isNotFoundLearner}
          isRequireMastery={isRequireMastery}
          numSections={numSections}
          pretestMasteredCourse={pretestMasteredCourse}
          sectionsCompleted={sections.filter((section) => {
            const { contentfulId: sectionContentfulId } = section
            const { isMasteredWithPretest: isMasteredWithPretestSection } =
              courseSections[sectionContentfulId] || {}
            return !!isMasteredWithPretestSection
          })}
        />
      )
    }
    return (
      <Fragment>
        <CourseIntroBanner
          courseIntro={courseIntro}
          isOpen={!this.hasProgress && courseIntro && courseIntro.trim()}
        />
        {/* NOTE / TODO / WIP - make first login message banner client specific */}
        {/* <FirstLoginMessageBanner courseTitle={courseTitle} isOpen={isOpenFirstLoginMessage} onClick={this.onCloseFirstLoginMessage} /> */}
      </Fragment>
    )
  }
  renderDashboard() {
    const { contentStore } = this.props
    const { settings } = contentStore
    const { reviewActivitiesType } = settings || {}
    return (
      <div className="DashboardPage-wrapperLearnerDashboard">
        <div
          id="main-content"
          className="DashboardPage-main"
          qa-id="learner-dashboard"
        >
          {this.hasProgress ||
          this.hasMasteredSection ||
          this.requiredMasteryWithIncompleteSections ? (
            <Fragment>
              {this.renderExamLinks()}
              {this.renderContinue()}
              {this.renderProgress(true)}
              {this.renderPathway()}
              {this.renderTopSections()}
              {reviewActivitiesType ? this.renderMemoryBoosters() : null}
              <div className="DashboardPage-performanceAndTipsFlex">
                {this.renderPerformance()}
                {this.renderLearningTips()}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {this.renderNoProgress()}
              {this.renderContinue()}
              {this.renderProgress(true)}
            </Fragment>
          )}
        </div>
      </div>
    )
  }
  renderExamLinks() {
    const { contentStore } = this.props
    const { course, settings } = contentStore
    // const { isMasteredWithPretestAndReattempts } = course
    const {
      displayTitle: courseDisplayTitle,
      isMasteredWithPretest: isMasteredWithPretestCourse,
      title: courseTitle,
    } = course
    const { exam, isRequireMastery } = settings || {}
    // TODO BEFORE RELEASE - Uncomment and test rendering logic

    // Need requiredMasteryIncompletion?
    const requiredMasteryIncompletion =
      isRequireMastery && this.requiredMasteryWithIncompleteSections
    // if (isMasteredWithPretestCourse && !requiredMasteryIncompletion) {

    const shouldDisplayExamBanner =
      exam && isMasteredWithPretestCourse && !requiredMasteryIncompletion

    if (!shouldDisplayExamBanner) return

    return <ExamBanner courseTitle={courseDisplayTitle || courseTitle} />
  }
  renderTopSections() {
    const { contentStore, intl, reportingStore } = this.props
    const courseSections = this.sectionsAsObject
    const { course, pretestMasteredSectionIds } = contentStore
    const { formatMessage } = intl
    const { learner } = reportingStore
    const { pretestMastered: pretestMasteredCourse } = course || {}
    const { sections } = learner
    let topSections = sections.filter((section) => {
      const {
        contentfulId: sectionContentfulId,
        rank: sectionRank,
        // NOTE / TODO / WIP - check reattempt rank to display on top sections
        // reattemptRank,
        // reattemptStatus
      } = section
      const { mastered } = courseSections[sectionContentfulId] || {}
      const contentfulSection = course.findSectionById(sectionContentfulId)
      // NOTE / TODO / WIP - check reattempt rank to display on top sections
      // return mastered && sectionRank !== 'at-risk' && (reattemptStatus !== REATTEMPT_STATUS_FINISHED || (reattemptStatus === REATTEMPT_STATUS_FINISHED && reattemptRank !== 'at-risk'))
      return (
        !pretestMasteredSectionIds[sectionContentfulId] &&
        mastered &&
        sectionRank !== 'at-risk' &&
        contentfulSection &&
        !contentfulSection.isIntro
      )
    })
    if (topSections.length < 2 || pretestMasteredCourse) return null
    let allStarSections = topSections.filter(
      (section) => section.rank === 'all-star',
    )
    let proficientSections = topSections.filter(
      (section) => section.rank === 'good',
    )
    allStarSections.sort((sectionA, sectionB) => {
      const { contentfulId: cIdA } = sectionA
      const { contentfulId: cIdB } = sectionB
      const { mastered: masteredA } = courseSections[cIdA]
      const { mastered: masteredB } = courseSections[cIdB]
      const dateA = new Date(masteredA)
      const dateB = new Date(masteredB)
      if (dateA > dateB) return -1
      if (dateA < dateB) return 1
      return 0
    })
    proficientSections.sort((sectionA, sectionB) => {
      const { contentfulId: cIdA } = sectionA
      const { contentfulId: cIdB } = sectionB
      const { mastered: masteredA } = courseSections[cIdA]
      const { mastered: masteredB } = courseSections[cIdB]
      const dateA = new Date(masteredA)
      const dateB = new Date(masteredB)
      if (dateA > dateB) return -1
      if (dateA < dateB) return 1
      return 0
    })
    topSections = allStarSections.concat(proficientSections)
    if (topSections.length > 5) {
      topSections = topSections.slice(0, 5)
    }

    return (
      <div className="DashboardPage__topSections">
        <h3 className="DashboardPage__topSections-title">
          {formatMessage(DashboardPageMessages.topSectionsTitle)}
        </h3>
        <div className="DashboardPage__topSections-description">
          {formatMessage(DashboardPageMessages.topSectionsDescription)}
        </div>
        <table className="DashboardPage__topSections-table table is-striped">
          <tbody>
            {topSections.map((topSection) => {
              const {
                contentfulId: sectionContentfulId,
                id: sectionId,
                unitContentfulId: sectionUnitContentfulId,
              } = topSection || {}
              const section = courseSections[sectionContentfulId]
              const { title: sectionTitle } = section || {}
              return section ? (
                <tr
                  className="DashboardPage__topSections-table-row"
                  key={`topSection-${sectionId}`}
                >
                  <td>
                    <Link
                      to={`/units/${sectionUnitContentfulId}/sections/${sectionContentfulId}/${section.tabs[0].id}`}
                    >
                      {sectionTitle}
                    </Link>
                  </td>
                </tr>
              ) : null
            })}
          </tbody>
        </table>
      </div>
    )
  }
  render() {
    const { contentStore, reportingStore, uiStore } = this.props
    const { isLoading } = this.state
    const { isLoadingCourse } = reportingStore
    const { courseProgress, settings } = contentStore
    const {
      closeMemoryBoosterPanelModal,
      isOpenMemoryBoosterPanelModal,
      isOpenReattemptIntroModal,
    } = uiStore
    const { isDisabledDashboard } = settings || {}
    if (isLoading) return <Loader />
    if (isDisabledDashboard) return <SummaryPageWithRouter />
    return isLoadingCourse ? (
      <Loader />
    ) : (
      <StudentLayout.Page className="DashboardPage">
        <div className="DashboardPage__inner-wrapper">
          {this.renderBanner()}
          {this.renderDashboard()}
        </div>
        {courseProgress > 0 ? (
          <ReattemptIntroModal
            isOpen={isOpenReattemptIntroModal}
            onConfirm={this.onConfirmReattemptIntroModal}
          />
        ) : null}
        <MemoryBoosterPanel
          isModal
          isOpen={isOpenMemoryBoosterPanelModal}
          onClose={closeMemoryBoosterPanelModal}
        />
      </StudentLayout.Page>
    )
  }
}
