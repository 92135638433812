import cx from 'classnames'
import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { Link } from '@/components'
import AdminHeader from './AdminHeader/AdminHeader'
import LearnerHeader from './LearnerHeader/LearnerHeader'
import './Header.scss'
import FulcrumLogo from '@/assets/fulcrum-logomark.png'

const HeaderMessages = defineMessages({
  goToHome: {
    defaultMessage: 'Go to the Home page',
    description:
      'Description for the button to navigate to the home page for screen readers from the general header.',
    id: 'Header.goToHome',
  },
  home: {
    defaultMessage: 'Home',
    description: 'Button to navigate to the home page from the general header.',
    id: 'Header.home',
  },
})

@injectIntl
@withRouter
@inject('contentStore', 'sessionStore')
@observer
export default class Header extends Component {
  logout = (event) => {
    const { history, sessionStore } = this.props
    const { isLoggingOut, session } = sessionStore
    const { logoutUrl } = session || {}
    event.preventDefault()
    if (isLoggingOut) return
    sessionStore.logout().then(() => {
      if (!logoutUrl) {
        history.push('/login')
      }
    })
  }
  onClickSwitchPlatform = () => {
    const { sessionStore } = this.props
    sessionStore.switchPlatform()
  }
  renderAdminHeader() {
    const { location, sessionStore } = this.props
    const { pathname } = location
    const { session } = sessionStore
    const { user } = session
    const { isAdminAndLearner, shortName } = user
    return (
      <AdminHeader
        isAdminAndLearner={isAdminAndLearner}
        onClickSwitchPlatform={this.onClickSwitchPlatform}
        onLogout={this.logout}
        pathname={pathname}
        shortName={shortName}
      />
    )
  }
  renderLearnerHeader() {
    const { location, sessionStore } = this.props
    const { pathname } = location
    const { session } = sessionStore
    const { user } = session
    const { isAdminAndLearner, shortName } = user
    const showCoursePicker =
      session &&
      session.hasMoreCourses &&
      session.user &&
      session.user.isStudent
    return (
      <LearnerHeader
        isAdminAndLearner={isAdminAndLearner}
        onClickSwitchPlatform={this.onClickSwitchPlatform}
        onLogout={this.logout}
        pathname={pathname}
        shortName={shortName}
        showCoursePicker={showCoursePicker}
      />
    )
  }
  render() {
    const { className, contentStore, intl, sessionStore } = this.props
    const { course } = contentStore
    const { formatMessage } = intl
    const { session } = sessionStore
    const logo = course ? course.logo : ''
    if (!session) {
      return (
        <div className={cx('Header is-clearfix no-user', className)}>
          <div className="container">
            <div className="columns is-mobile Header-topnav">
              <div className="column is-half">
                <Link to="/" className="Header-brand">
                  <img
                    alt={formatMessage(HeaderMessages.home)}
                    className="Header-logomark"
                    src={logo}
                    title={formatMessage(HeaderMessages.goToHome)}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }
    const { user } = session
    const { isCurrentRoleAdmin } = user
    return (
      <div className={cx('Header is-clearfix', className)}>
        <div className="container">
          <div className="Header-topNavContainer">
            <div className="Header-logoContainer">
              <Link
                className="Header-logoLink"
                to={isCurrentRoleAdmin ? '/' : '/dashboard'}
              >
                <img
                  alt={formatMessage(HeaderMessages.home)}
                  className="Header-logoImage"
                  src={isCurrentRoleAdmin ? FulcrumLogo : logo || FulcrumLogo}
                  title={formatMessage(HeaderMessages.goToHome)}
                  qa-id="learner-header-logo-button"
                />
              </Link>
            </div>
            <div className="Header-actionsContainer">
              {isCurrentRoleAdmin
                ? this.renderAdminHeader()
                : this.renderLearnerHeader()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
