import React from 'react'
import cx from 'classnames'
import './ActivityLabel.scss'

const ACTIVITY_MULTIPLE_ATTEMPTS_TEXT = 'Try Again.'

const ACTIVITY_SHOW_HINT_TEXT = 'You can view the Hint below.'

const renderActivityIcon = (icon, isDanger) => {
  if (isDanger) return null
  return (
    <div className="media-left">
      <i className={`fa fa-${icon}`} aria-hidden="true" />
    </div>
  )
}

const renderLabel = (
  id,
  text,
  shouldRenderTryAgain,
  shouldRenderShowHint,
  attempt = 0,
) => {
  const className = 'ActivityLabel-content'
  if (shouldRenderTryAgain && shouldRenderShowHint)
    return (
      <span
        aria-live="assertive"
        className={className}
        key={`ActivityLabel-shouldRenderTryAgain-shouldRenderShowHint-${id}-${attempt}`}
        role="alert"
        qa-id="learner-activity-label-prompt"
      >
        {text}
        <br />
        {ACTIVITY_MULTIPLE_ATTEMPTS_TEXT}&nbsp;{ACTIVITY_SHOW_HINT_TEXT}
      </span>
    )
  if (shouldRenderShowHint)
    return (
      <span
        aria-live="assertive"
        className={className}
        key={`ActivityLabel-shouldRenderShowHint-${id}-${attempt}`}
        role="alert"
        qa-id="learner-activity-label-prompt"
      >
        {text}
        <br />
        {ACTIVITY_SHOW_HINT_TEXT}
      </span>
    )
  if (shouldRenderTryAgain)
    return (
      <span
        aria-live="assertive"
        className={className}
        key={`ActivityLabel-shouldRenderTryAgain-${id}-${attempt}`}
        role="alert"
        qa-id="learner-activity-label-prompt"
      >
        {text}
        <br />
        {ACTIVITY_MULTIPLE_ATTEMPTS_TEXT}
      </span>
    )
  return (
    <span
      aria-live="assertive"
      className={className}
      key={`ActivityLabel-text-${id}-${attempt}`}
      role="alert"
      qa-id="learner-activity-label-prompt"
    >
      {text}
    </span>
  )
}

const ActivityLabel = (props) => {
  const {
    attempt,
    className,
    hasHint,
    hasNextActivity,
    icon,
    id,
    isDanger,
    text,
  } = props
  const classNameLabel = cx(
    'media ActivityLabel',
    {
      'is-danger': isDanger,
      'no-hint': !hasHint,
    },
    className,
  )
  return (
    <div className={classNameLabel}>
      {renderActivityIcon(icon, isDanger)}
      <div className="media-content">
        {renderLabel(id, text, isDanger && !hasNextActivity, hasHint, attempt)}
      </div>
    </div>
  )
}

export default ActivityLabel
