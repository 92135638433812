import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { defineMessages, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './MoreContentBelowBanner.scss'

const MoreContentBelowBannerMessages = defineMessages({
  text: {
    defaultMessage: 'Scroll For More Content',
    description:
      'Banner to show that there is hidden content below the visible area.',
    id: 'MoreContentBelowBanner.text',
  },
})

@injectIntl
@inject('uiStore')
@observer
class MoreContentBelowBanner extends Component {
  handleClick = (event) => {
    const { scrollableEl, uiStore, visibleHeight } = this.props
    const scrollTop = uiStore.scrollTop + visibleHeight / 2
    event.preventDefault()
    if (scrollableEl) {
      uiStore.setScrollTop(scrollTop)
      uiStore.scrollY(scrollableEl, scrollTop, false, 'smooth')
    }
  }
  render() {
    const { intl, isScrollable, isScrolledToBottom } = this.props
    const { formatMessage } = intl
    if (isScrollable) {
      let additionalAttributes = {}
      if (isScrolledToBottom) {
        return null
      }
      return (
        <a
          className="MoreContentBelowBanner__wrapper"
          href="#"
          onClick={this.handleClick}
          {...additionalAttributes}
        >
          <div
            className={cx('MoreContentBelowBanner', {
              'is-scrolled-to-bottom': isScrolledToBottom,
            })}
          >
            <i className="fa fa-angle-double-down" />
            <span>{formatMessage(MoreContentBelowBannerMessages.text)}</span>
            <i className="fa fa-angle-double-down" />
          </div>
        </a>
      )
    }
    return null
  }
}

MoreContentBelowBanner.propTypes = {
  isScrollable: PropTypes.bool.isRequired,
  isScrolledToBottom: PropTypes.bool.isRequired,
  scrollableEl: PropTypes.object,
  visibleHeight: PropTypes.number.isRequired,
}

export const checkIfScrollable = (
  wasScrollable,
  totalHeight,
  visibleHeight,
  cb,
) => {
  let isScrollable = false
  if (
    totalHeight &&
    visibleHeight &&
    Math.abs(totalHeight - visibleHeight >= 30)
  ) {
    isScrollable = true
    cb(isScrollable)
  } else if (wasScrollable) {
    cb(isScrollable)
  }
}

export const checkIfScrolledToBottom = (scrollTop, totalHeight) => {
  return Math.abs(scrollTop - totalHeight) <= 30
}

export default MoreContentBelowBanner
