import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import {
  Accordion,
  AccordionItem,
  Button,
  CourseProgress,
  Heading,
  MemoryBoosterPanel,
  SectionList,
} from '@/components'
import debounce from 'lodash/debounce'
import './StudentLayoutSidebar.scss'

const StudentLayoutSidebarMessages = defineMessages({
  collapseSidebar: {
    defaultMessage: 'Collapse Sidebar',
    description: 'ARIA label for the Button to collapse the sidebar.',
    id: 'MemoryBoosterPanel.collapseSidebar',
  },
  expandSidebar: {
    defaultMessage: 'Expand Sidebar',
    description: 'ARIA label for the Button to expand the sidebar.',
    id: 'MemoryBoosterPanel.toggleSidebar',
  },
})

@injectIntl
@withRouter
@inject('contentStore', 'sessionStore', 'uiStore', 'xapiStore')
@observer
export default class StudentLayoutSidebar extends Component {
  state = {
    activeAccordionUnit: null,
    isToggled: true,
    statusMinHeight: 265,
  }
  get sectionsProgress() {
    const { contentStore } = this.props
    const { sectionsProgress } = contentStore
    const { completedSections, nSections } = sectionsProgress
    return { completedSections, nSections }
  }
  componentDidMount() {
    this.onResizeStatus()
    window.addEventListener('resize', this.onResizeStatus)
  }
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const { location: prevLocation } = prevProps
    const { pathname } = location
    const { pathname: prevPathname } = prevLocation
    const isCoursePage = pathname.match(/units\/.+\/sections\/(.+)\//)
    const wasCoursePage = prevPathname.match(/units\/.+\/sections\/(.+)\//)
    if (prevPathname !== '/dashboard' && pathname === '/dashboard') {
      this.setState({ activeAccordionUnit: null })
    } else if (
      isCoursePage &&
      (!wasCoursePage || isCoursePage[1] !== wasCoursePage[1])
    ) {
      this.setState({ activeAccordionUnit: isCoursePage[0] })
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeStatus)
  }
  onAccordionUnitChange = (activeAccordionUnit) => {
    this.setState({ activeAccordionUnit })
  }
  onResizeStatus = debounce(() => {
    if (!this.$status) return
    this.setState({ statusMinHeight: this.$status.scrollHeight })
  }, 200)
  onSectionChange = (section) => {
    const { history, xapiStore } = this.props
    const { isLaunchingSection } = xapiStore
    const { locked, tabs } = section
    if (locked || isLaunchingSection) return
    const firstTab = tabs[0]
    const { href: firstTabHref } = firstTab
    history.push(firstTabHref)
  }
  onToggleMemoryBoosterPanel = () => {
    const { uiStore } = this.props
    uiStore.toggleMemoryBoosterPanel()
  }
  onToggleSidebar = () => {
    const { uiStore } = this.props
    const { isToggled } = this.state
    const { isOpenMemoryBoosterPanel } = uiStore
    if (isOpenMemoryBoosterPanel && isToggled) {
      uiStore.toggleMemoryBoosterPanel()
    }
    this.setState({ isToggled: !isToggled })
  }
  setStatusRef = (el) => {
    this.$status = el
  }
  renderStatus() {
    const { contentStore } = this.props
    const { completedSections, nSections } = this.sectionsProgress
    const { course, courseProgress } = contentStore
    const { displayTitle: courseDisplayTitle, title: courseTitle } = course
    return (
      <div className="StudentLayoutSidebar__status" ref={this.setStatusRef}>
        <Heading className="StudentLayoutSidebar__course-title">
          {courseDisplayTitle || courseTitle}
        </Heading>
        <CourseProgress
          progress={courseProgress}
          sectionsCompleted={completedSections}
          sectionsTotal={nSections}
        />
      </div>
    )
  }
  renderUnitsAccordion() {
    const { contentStore, location } = this.props
    const { activeAccordionUnit } = this.state
    const { course, settings } = contentStore
    const { pathname } = location
    const {
      // NOTE - next section id was only used for selection on Dashboard
      // nextSectionId,
      nextUnitId,
      units,
    } = course
    const {
      quiz: isQuizEnabled,
      quizMastery: isQuizMastery,
      isRequireMastery,
    } = settings || {}
    const unit = units[0]
    const { id: firstUnitId } = unit
    const isCoursePage = pathname.match(/units\/.+\/sections\/(.+)\//)
    const activeAccordionItems = activeAccordionUnit ||
      nextUnitId || [firstUnitId]
    const activeAccordionSection = isCoursePage ? isCoursePage[1] : null
    return (
      <div className="StudentLayoutSidebar__units">
        <Accordion
          active={activeAccordionItems}
          className="StudentLayoutSidebar__units-accordion"
          onChange={this.onAccordionUnitChange}
        >
          {units.map((unit) => {
            const { cid, id, sections, title } = unit
            return (
              <AccordionItem
                className="StudentLayoutSidebar__unit"
                cid={cid}
                id={id}
                key={id}
                primaryLabel={
                  <Heading className="StudentLayoutSidebar__unit-title" is2>
                    {title}
                  </Heading>
                }
              >
                <div className="StudentLayoutSidebar__unit-line-break" />
                <Heading className="StudentLayoutSidebar__sections-label" is3>
                  Sections:
                </Heading>
                <SectionList
                  active={isCoursePage ? activeAccordionSection : null}
                  // NOTE - next section id was only used for selection on Dashboard
                  // active={isCoursePage ? activeAccordionSection || nextSectionId : null}
                  className="StudentLayoutSidebar__sections"
                  isQuizEnabled={isQuizEnabled}
                  isQuizMastery={isQuizMastery}
                  isRequireMastery={isRequireMastery}
                  onChange={this.onSectionChange}
                  sections={sections}
                />
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    )
  }
  render() {
    const { contentStore, intl, uiStore } = this.props
    const { isToggled, statusMinHeight } = this.state
    const { settings } = contentStore
    const { formatMessage } = intl
    const { isMobile, isOpenMemoryBoosterPanel, openMemoryBoosterPanelModal } =
      uiStore
    const { reviewActivitiesType } = settings || {}
    return (
      <Fragment>
        <div
          className={cx('StudentLayoutSidebar', {
            'StudentLayoutSidebar--is-enabled-memory-boosters':
              reviewActivitiesType,
            'StudentLayoutSidebar--is-toggled': isToggled,
          })}
        >
          <div className="StudentLayoutSidebar__content-wrapper">
            <div className="StudentLayoutSidebar__content">
              <div
                className="StudentLayoutSidebar__content-status"
                style={{ minHeight: `${statusMinHeight}px` }}
              >
                {this.renderStatus()}
              </div>
              <div className="StudentLayoutSidebar__content-units">
                {this.renderUnitsAccordion()}
              </div>
            </div>
          </div>
          {reviewActivitiesType && !isMobile ? (
            <MemoryBoosterPanel
              isOpen={isOpenMemoryBoosterPanel && isToggled}
              isSidebarToggled={isToggled}
              onToggle={
                isToggled
                  ? this.onToggleMemoryBoosterPanel
                  : openMemoryBoosterPanelModal
              }
            />
          ) : null}
          <Button
            aria-label={
              !isToggled
                ? formatMessage(StudentLayoutSidebarMessages.expandSidebar)
                : formatMessage(StudentLayoutSidebarMessages.collapseSidebar)
            }
            className="StudentLayoutSidebar__toggler"
            isText
            onClick={this.onToggleSidebar}
          >
            <i
              aria-hidden="true"
              className={cx('fa', {
                'fa-angle-left': isToggled,
                'fa-angle-right': !isToggled,
              })}
            />
          </Button>
        </div>
      </Fragment>
    )
  }
}
