import React, { Component } from 'react'
import cx from 'classnames'
import { Button } from '@/components'
import { TransitionHeight } from '@/components/animations'
import InstanceContentPageSections from './InstanceContentPageSections'

export default class InstanceContentPageUnit extends Component {
  state = { isActive: true }
  componentDidUpdate(prevProps) {
    const { unit } = this.props
    const { isActive } = this.state
    const { unit: prevUnit } = prevProps
    const { id: unitId } = unit
    const { id: prevUnitId } = prevUnit
    if (!isActive && unitId !== prevUnitId) {
      this.setState({ isActive: true })
    }
  }
  onAccordionChange = (event) => {
    const { isActive } = this.state
    event.preventDefault()
    this.setState({ isActive: !isActive })
  }
  render() {
    const { onClickOpenActivityModal, unit } = this.props
    const { isActive } = this.state
    const { sections, title: unitTitle } = unit
    return (
      <div className="InstanceContentPage__unit">
        <Button
          className="InstanceContentPage__unit-title"
          onClick={this.onAccordionChange}
          isText
        >
          <i
            className={cx('fa', {
              'fa-caret-down': isActive,
              'fa-caret-up': !isActive,
            })}
          />
          <span>{unitTitle}</span>
        </Button>
        <TransitionHeight in={isActive}>
          <InstanceContentPageSections
            onClickOpenActivityModal={onClickOpenActivityModal}
            sections={sections}
          />
        </TransitionHeight>
      </div>
    )
  }
}
