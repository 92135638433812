import { computed, observable } from 'mobx'
import { toCamelCase, toSnakeCase, upperFirst } from '@/utils/helpers'

export default class User {
  @observable id
  @observable instructorId
  @observable email
  @observable username
  @observable firstName
  @observable lastName

  @observable currentRole
  @observable isAdmin
  @observable isInstructor
  @observable isObserver
  @observable isStudent

  @observable roleId

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  @computed get shortName() {
    return `${this.firstName} ${upperFirst(this.lastName)}.`
  }

  @computed get isAdminLike() {
    return this.isAdmin || this.isInstructor || this.isObserver
  }

  @computed get isAdminAndLearner() {
    return (
      (this.isAdmin || this.isInstructor) && (this.isObserver || this.isStudent)
    )
  }

  @computed get isCurrentRoleAdmin() {
    return this.currentRole === 'admin'
  }

  toSnakeCase() {
    const { id, email, username, firstName, lastName } = this
    return toSnakeCase({
      id,
      email,
      username,
      firstName,
      lastName,
    })
  }
}
