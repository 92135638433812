import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { inject } from 'mobx-react'

@inject('sessionStore')
export default class PrivateRoute extends Component {
  render() {
    const { component: Component, sessionStore, ...rest } = this.props
    const path = {
      pathname: '/login',
      state: {
        from: this.props.location,
      },
    }
    const render = (props) =>
      sessionStore.isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to={path} />
      )

    return <Route {...rest} render={render} />
  }
}
