import React, { Fragment } from 'react'
import cx from 'classnames'
import './Input.scss'
import { Label } from '@/components'

const Input = (props) => {
  const {
    id,
    isPrimary,
    isInfo,
    isSuccess,
    isWarning,
    isDanger,
    isSmall,
    isMedium,
    isLarge,
    isMultiline,
    isSearch,
    label,
    labelPosition,
    ...restProps
  } = props

  const className = cx(
    'Input',
    {
      input: !isMultiline,
      textarea: isMultiline,
      'is-primary': isPrimary,
      'is-info': isInfo,
      'is-success': isSuccess,
      'is-warning': isWarning,
      'is-danger': isDanger,
      'is-small': isSmall,
      'is-medium': isMedium,
      'is-large': isLarge,
      'is-search': isSearch,
    },
    props.className,
  )

  const defaultProps = {
    type: 'text',
  }

  // by default id === name
  if (props.name) defaultProps.id = props.name

  const tag = isMultiline ? 'textarea' : 'input'
  const el = React.createElement(
    tag,
    {
      ...defaultProps,
      ...restProps,
      className,
      id,
    },
    props.children,
  )
  if (label) {
    return (
      <Fragment>
        {(!labelPosition || labelPosition === 'before') && (
          <Label htmlFor={id} text={label} />
        )}
        {el}
        {labelPosition === 'after' && <Label htmlFor={id} text={label} />}
      </Fragment>
    )
  }
  return el
}

export default Input
