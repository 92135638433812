import React, { Component, Fragment } from 'react'
import { Pie, PieChart as ReactPieChart } from 'recharts'

import './PieChart.scss'

const offsetLabel = (name, x, y) => {
  let offsetX
  let offsetY
  if (name === '100%') return [22, 5]
  if (x <= 145) {
    offsetX = 14
  } else {
    offsetX = 22
  }
  if (y <= 105) {
    offsetY = 11
  } else {
    offsetY = 5
  }
  return [offsetX, offsetY]
}

const renderLabelContent = (props, hideSmallPercentages) => {
  const { name, x, y, midAngle, payload, percent } = props
  const { rank } = payload || {}
  const [offsetX, offsetY] = offsetLabel(name, x, y)
  const isHidden = (hideSmallPercentages && percent < 0.045) || name === '0%'
  return (
    <Fragment>
      {!isHidden && (
        <g
          transform={`translate(${x}, ${y})`}
          textAnchor={midAngle < -90 || midAngle >= 90 ? 'end' : 'start'}
        >
          <text x={offsetX} y={offsetY}>
            {name} {rank && <text style={{ opacity: 0 }}>{rank}</text>}
          </text>
        </g>
      )}
    </Fragment>
  )
}

const dataEmpty = [
  { fill: '#FAFAFA', name: '', value: 1 },
  { fill: '#EEEEEE', name: '', value: 1 },
  { fill: '#F5F5F5', name: '', value: 1 },
]

export default class PieChart extends Component {
  render() {
    const { data, innerRadius } = this.props
    let stroke = '#fff'
    let paddingAngle = 1
    let percentagesLessThanFive = 0
    let hideSmallPercentages = false

    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].name === '100%') {
          stroke = null
          paddingAngle = null
          break
        }
        if (parseInt(data[i].name.slice(0, -1)) < 5) {
          percentagesLessThanFive++
        }
      }
    }

    hideSmallPercentages = percentagesLessThanFive === 2

    return (
      <ReactPieChart width={260} height={260}>
        <Pie
          startAngle={90}
          endAngle={450}
          innerRadius={innerRadius || 0}
          isAnimationActive={!!data.length}
          data={data.length ? data : dataEmpty}
          paddingAngle={paddingAngle}
          stroke={stroke}
          label={(props) => renderLabelContent(props, hideSmallPercentages)}
          labelLine={false}
          dataKey="value"
        />
      </ReactPieChart>
    )
  }
}
