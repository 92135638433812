import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import {
  Activity,
  ActivityCompleted,
  ActivityModal,
  Button,
  Heading,
  IdleModal,
  IncompleteQuestionModal,
  Loader,
  Modal,
  MoreContentBelowBanner,
  ReadTabLectureNotes,
  Tabs,
  Video,
} from '@/components'
import {
  checkIfScrollable,
  checkIfScrolledToBottom,
} from '@/components/MoreContentBelowBanner/MoreContentBelowBanner'
import TimeTracking, {
  TYPE_MEMORY_BOOSTER_ACTIVITY,
  TYPE_MEMORY_BOOSTER_READ,
  TYPE_MEMORY_BOOSTER_WATCH,
} from '@/components/TimeTracking/TimeTracking'
import debounce from 'lodash/debounce'
import IdleTimer from 'react-idle-timer'
import { ACTIVITY_COMPLETED_CORRECT } from '@/constants'
import {
  PRACTICE_TAB,
  READ_TAB,
  QUIZ_TAB,
  WATCH_TAB,
} from '@/stores/models/section'
import { getIdleTimeThreshold } from '@/utils/helpers'
import './MemoryBoosterModal.scss'

const MemoryBoosterModalMessages = defineMessages({
  confirmAnswer: {
    defaultMessage: 'Confirm Answer',
    description: 'Button to submit an answer to a memory booster.',
    id: 'MemoryBoosterModal.confirmAnswer',
  },
  done: {
    defaultMessage: 'Done',
    description: 'Button to exit from a memory booster after answering.',
    id: 'MemoryBoosterModal.done',
  },
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to enlarge an image within a memory booster.',
    id: 'MemoryBoosterModal.enlargeImage',
  },
  loadingMemoryBooster: {
    defaultMessage: 'Loading Memory Booster...',
    description: 'Label displayed while loading a memory booster.',
    id: 'MemoryBoosterModal.loadingMemoryBooster',
  },
  next: {
    defaultMessage: 'Next',
    description:
      'Button to move to the next tab or step within a memory booster.',
    id: 'MemoryBoosterModal.next',
  },
  noLectureNotes: {
    defaultMessage: 'No Read content available.',
    description:
      'Description displayed when there are no Read content available within a memory booster.',
    id: 'MemoryBoosterModal.noLectureNotes',
  },
  printLectureNotes: {
    defaultMessage: 'Print Read Content',
    description:
      'Button to allow printing of Read content from within a memory booster.',
    id: 'MemoryBoosterModal.printLectureNotes',
  },
  saveForLater: {
    defaultMessage: 'Save For Later',
    description:
      'Button to exit the memory booster to allow users to return to it at a later time.',
    id: 'MemoryBoosterModal.saveForLater',
  },
  title: {
    defaultMessage: 'Memory Booster: {memoryBoosterSectionTitle}',
    description:
      'Title containing the originating section title for a memory booster.',
    id: 'MemoryBoosterModal.title',
  },
})

const initialState = {
  $content: null,
  isDisabledConfirm: false,
  isOpenMemoryBoosterFeedback: false,
  isOpenMemoryBoosterImage: false,
  isOpenMemoryBoosterQuestion: false,
  isScrollable: false,
  isScrolledToBottom: false,
  memoryBoosterImage: null,
  read: false,
  // views
  // - practice
  // - read
  // - watch
  view: 'practice',
  watch: false,
}

@injectIntl
@inject('contentStore', 'uiStore')
@observer
export default class MemoryBoosterModal extends Component {
  state = {
    ...initialState,
  }
  el = null
  get course() {
    const { contentStore } = this.props
    const { course } = contentStore
    if (!course) return null
    return course
  }
  get memoryBoosterSection() {
    const { contentStore } = this.props
    const { memoryBooster } = contentStore
    if (!memoryBooster) return null
    const { id: memoryBoosterId } = memoryBooster
    return this.course && memoryBoosterId
      ? this.course.findSectionByActivityId(memoryBoosterId)
      : null
  }
  componentDidMount() {
    const { contentStore, memoryBoosterSectionId, memoryBoosterUnitId } =
      this.props
    contentStore
      .loadMemoryBooster({
        sectionId: memoryBoosterSectionId,
        unitId: memoryBoosterUnitId,
      })
      .then(() => {
        this.checkIfScrollable()
      })
    window.addEventListener('resize', this.debouncedCheckIfScrollable)
  }

  componentDidUpdate(prevProps, prevState) {
    const { view: prevView, $content: prevContent } = prevState
    const { view: currentView, $content: currentContent } = this.state
    if (prevView !== currentView || prevContent !== currentContent) {
      this.setState({ isScrolledToBottom: false })
      this.checkIfScrollable()
    }
  }

  componentWillUnmount() {
    const { $content } = this.state
    const { contentStore, uiStore } = this.props
    contentStore.resetMemoryBooster()
    uiStore.scrollToTop()
    window.removeEventListener('resize', this.debouncedCheckIfScrollable)
    if ($content) {
      $content.removeEventListener('scroll', this.onScroll)
    }
  }
  checkIfScrollable = () => {
    const { $content } = this.state
    const { isScrollable: wasScrollable } = this.state
    const totalHeight = this.$innerContent
      ? this.$innerContent.clientHeight
      : null
    const visibleHeight = $content ? $content.clientHeight : null
    checkIfScrollable(
      wasScrollable,
      totalHeight,
      visibleHeight,
      (isScrollable) => {
        this.setState({ isScrollable })
      },
    )
  }
  debouncedCheckIfScrollable = debounce(this.checkIfScrollable, 250)
  onChange = (value) => {
    const { contentStore } = this.props
    const { isOpenMemoryBoosterFeedback } = this.state
    if (isOpenMemoryBoosterFeedback) return
    this.setState({ isDisabledConfirm: false })
    contentStore.setMemoryBoosterAnswer(value)
  }
  onChangeTab = (tab) => {
    const { id: tabId } = tab
    this.setState({ view: tabId })
  }
  onCloseIdleModal = () => {
    const { uiStore } = this.props
    uiStore.closeIdleModal()
  }
  onCloseMemoryBoosterImage = () => {
    this.setState({
      isOpenMemoryBoosterImage: false,
      memoryBoosterImage: null,
    })
  }
  onConfirmIncomplete = (event) => {
    this.onSubmit(event, true)
  }
  onDisableConfirm = () => {
    this.setState({ isDisabledConfirm: true })
  }
  onIdle = () => {
    const { uiStore } = this.props
    uiStore.openIdleModal()
  }
  onOpenMemoryBoosterImage = (e, image) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      isOpenMemoryBoosterImage: true,
      memoryBoosterImage: image,
    })
  }
  onScroll = debounce(() => {
    const { $content } = this.state
    const { contentStore } = this.props
    const { isScrolledToBottom } = this.state
    const { isLoadingMemoryBooster } = contentStore
    if ($content && this.$innerContent) {
      const scrollTop = $content.scrollTop + $content.clientHeight
      if (
        !isScrolledToBottom &&
        !isLoadingMemoryBooster &&
        checkIfScrolledToBottom(scrollTop, this.$innerContent.clientHeight)
      ) {
        this.setState({ isScrolledToBottom: true })
      }
    }
  }, 200)
  onSkip = () => {
    const { onClose } = this.props
    onClose()
  }
  onSubmit = (event, confirmedIncomplete = false) => {
    const {
      contentStore,
      memoryBoosterSectionId,
      memoryBoosterUnitId,
      uiStore,
    } = this.props
    const { isOpenMemoryBoosterFeedback } = this.state
    const {
      hasNextMemoryBooster,
      isSavingMemoryBooster,
      isSkippingMemoryBooster,
      memoryBooster,
      memoryBoosterAnswer,
    } = contentStore
    const { openIncompleteQuestionModal } = uiStore
    if (
      isOpenMemoryBoosterFeedback ||
      isSavingMemoryBooster ||
      isSkippingMemoryBooster
    )
      return
    if (!confirmedIncomplete && !hasNextMemoryBooster) {
      const isValidAnswer = memoryBooster.validateAnswer(
        memoryBoosterAnswer,
        openIncompleteQuestionModal,
      )
      if (!isValidAnswer) return
    }
    contentStore
      .saveMemoryBooster({ memoryBoosterSectionId, memoryBoosterUnitId })
      .then((hasPassed) => {
        const { hasNextMemoryBooster } = contentStore
        if (!hasPassed) {
          this.onDisableConfirm()
        } else {
          this.setState({ isOpenMemoryBoosterFeedback: true })
        }
        if (hasNextMemoryBooster) {
          contentStore.loadMemoryBoosters()
        }
      })
      .catch((error) => {
        console.error(
          error && error.message
            ? error.message
            : 'Error: could not submit memory booster;',
        )
      })
  }
  onToggleMemoryBoosterQuestion = () => {
    const { isOpenMemoryBoosterQuestion } = this.state
    this.setState({
      isOpenMemoryBoosterQuestion: !isOpenMemoryBoosterQuestion,
    })
  }
  resetScrollPosition = () => {
    this.setState({ isScrolledToBottom: false }, () => {
      this.checkIfScrollable()
    })
  }
  setContentRef = (el) => {
    this.setState({ $content: el })
    if (el) {
      el.addEventListener('scroll', this.onScroll)
    }
  }
  setInnerContentRef = (el) => {
    this.$innerContent = el
  }
  renderBody() {
    const { isOpenMemoryBoosterFeedback, view } = this.state
    if (view === 'read') {
      return this.renderRead()
    } else if (view === 'watch') {
      return this.renderWatch()
    } else if (view === 'practice') {
      return isOpenMemoryBoosterFeedback
        ? this.renderMemoryBoosterFeedback()
        : this.renderMemoryBooster()
    }
  }
  renderButtons() {
    const { contentStore, intl, onClose } = this.props
    const { isDisabledConfirm, view } = this.state
    const {
      hasNextMemoryBooster,
      isSavingMemoryBooster,
      isSkippingMemoryBooster,
      memoryBoosterAnswer,
    } = contentStore
    const { formatMessage } = intl
    const isDisabled =
      !hasNextMemoryBooster &&
      (isSavingMemoryBooster ||
        isSkippingMemoryBooster ||
        !memoryBoosterAnswer ||
        _.isEmpty(memoryBoosterAnswer) ||
        isDisabledConfirm)
    return (
      <Fragment>
        {!hasNextMemoryBooster ? (
          <Modal.Button
            className="MemoryBoosterModal-buttonsCancel"
            disabled={isSavingMemoryBooster || isSkippingMemoryBooster}
            isLoading={isSkippingMemoryBooster}
            isSecondary
            key="MemoryBoosterModal-buttonsCancel"
            onClick={this.onSkip}
          >
            {formatMessage(MemoryBoosterModalMessages.saveForLater)}
          </Modal.Button>
        ) : null}
        {view === 'practice' ? (
          <Modal.Button
            disabled={isDisabled}
            isLoading={isSavingMemoryBooster}
            key="MemoryBoosterModal-buttonsConfirm"
            onClick={hasNextMemoryBooster ? onClose : this.onSubmit}
            qa-id="learner-activity-confirm-button"
            text={
              hasNextMemoryBooster
                ? formatMessage(MemoryBoosterModalMessages.done)
                : formatMessage(MemoryBoosterModalMessages.confirmAnswer)
            }
          />
        ) : (
          <Modal.Button
            key="MemoryBoosterModal-buttonsConfirm"
            onClick={() =>
              this.onChangeTab({ id: view === 'watch' ? 'read' : 'practice' })
            }
            text={formatMessage(MemoryBoosterModalMessages.next)}
          />
        )}
      </Fragment>
    )
  }
  renderImageAfterQuestion = (image, imageAlt, imageTitle) => {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div
        className="Activity-colImage"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <img
          className="Activity-image"
          src={image}
          alt={imageAlt}
          title={imageTitle}
        />
        <Button
          aria-label={formatMessage(MemoryBoosterModalMessages.enlargeImage)}
          className="Activity-zoomImage"
          onClick={(e) => this.onOpenMemoryBoosterImage(e, image)}
        >
          <span>{formatMessage(MemoryBoosterModalMessages.enlargeImage)}</span>
          <i
            aria-hidden="true"
            className="fa fa-search-plus"
            title={formatMessage(MemoryBoosterModalMessages.enlargeImage)}
          />
        </Button>
      </div>
    )
  }
  renderMemoryBoosterFeedback() {
    const { contentStore } = this.props
    const { isOpenMemoryBoosterQuestion } = this.state
    const { memoryBooster } = contentStore
    const { goodToKnow: memoryBoosterGoodToKnow } = memoryBooster || {}
    return (
      <ActivityCompleted
        goodToKnow={memoryBoosterGoodToKnow}
        onClickShowQuestion={this.onToggleMemoryBoosterQuestion}
        renderActivity={
          isOpenMemoryBoosterQuestion ? this.renderMemoryBooster() : null
        }
        type={ACTIVITY_COMPLETED_CORRECT}
      />
    )
  }
  renderMain() {
    const { $content, isScrollable, isScrolledToBottom, view } = this.state
    const { tabs: memoryBoosterSectionTabs, title: memoryBoosterSectionTitle } =
      this.memoryBoosterSection
    const numTabs = memoryBoosterSectionTabs
      ? memoryBoosterSectionTabs.length
      : 0
    return (
      <Fragment>
        <div
          className="MemoryBoosterModal__activity"
          key={`MemoryBoosterModal__activity--is-${view}`}
          ref={this.setContentRef}
        >
          <div ref={this.setInnerContentRef}>
            <Modal.Header className="MemoryBoosterModal__header">
              <Heading>
                <FormattedMessage
                  {...MemoryBoosterModalMessages.title}
                  values={{
                    memoryBoosterSectionTitle: (
                      <Heading is2>{memoryBoosterSectionTitle}</Heading>
                    ),
                  }}
                />
              </Heading>
              <Tabs
                active={view}
                className={cx('MemoryBoosterModal-tabs', {
                  'is-one': numTabs === 1,
                  'is-two': numTabs === 2,
                  'is-three': numTabs === 3,
                })}
                isAlt
                items={memoryBoosterSectionTabs.filter(
                  (tab) => tab.id !== QUIZ_TAB,
                )}
                onChange={this.onChangeTab}
              />
            </Modal.Header>
            <Modal.Body>{this.renderBody()}</Modal.Body>
          </div>
          <Modal.Footer className="MemoryBoosterModal__footer">
            {view === 'practice' ? (
              <MoreContentBelowBanner
                isScrollable={isScrollable}
                isScrolledToBottom={isScrolledToBottom}
                scrollableEl={$content}
                visibleHeight={$content ? $content.clientHeight : 240}
              />
            ) : null}
            {this.renderButtons()}
          </Modal.Footer>
        </div>
      </Fragment>
    )
  }
  renderMemoryBooster() {
    const { contentStore, uiStore } = this.props
    const {
      $content,
      isDisabledConfirm,
      isOpenMemoryBoosterFeedback,
      isOpenMemoryBoosterQuestion,
    } = this.state
    const {
      hasNextMemoryBooster,
      isSavingMemoryBooster,
      isSkippingMemoryBooster,
      memoryBooster,
      memoryBoosterAnswer,
      settings,
    } = contentStore
    const {
      closeIncompleteQuestionModal,
      isOpenIdleModal,
      isOpenIncompleteQuestionModal,
      scrollY,
    } = uiStore
    const { activityAttempts: courseActivityAttempts } = settings
    const {
      activityEmbed,
      activityType,
      answerResponse,
      answersCounter,
      attempt: activityAttempt,
      correctAnswers,
      currentHint: currentActivityHint,
      hasHint,
      hasNotPassed,
      id: activityId,
      imageAlignment,
      incorrectAnswers,
      isNewActivity,
      isReview: isReviewActivity,
      question,
      questionImage,
      questionImageDescription,
      questionImageTitle,
      questionVideo,
      replaceActivityMathFormulas,
      skipped: isSkippedActivity,
      title: activityTitle,
      validationError: activityValidationError,
    } = memoryBooster || {}
    const answersCountTotals =
      memoryBooster &&
      memoryBooster.getAnswersCountTotals(
        memoryBoosterAnswer,
        isOpenIncompleteQuestionModal,
      )
    const { numAnswersIncomplete, numAnswersTotal } = answersCountTotals || {}
    return (
      <Fragment>
        {!isOpenMemoryBoosterFeedback ? (
          <Fragment>
            <IdleTimer
              onIdle={this.onIdle}
              startOnLoad
              timeout={getIdleTimeThreshold(PRACTICE_TAB)}
            />
            <TimeTracking
              entityId={activityId}
              isIdle={isOpenIdleModal}
              isMemoryBooster
              section={this.memoryBoosterSection}
              what={TYPE_MEMORY_BOOSTER_ACTIVITY}
            />
          </Fragment>
        ) : null}
        <Activity
          id={activityId}
          activityEmbed={activityEmbed}
          answerResponse={answerResponse || null}
          answersCounter={answersCounter}
          attempt={activityAttempt}
          courseActivityAttempts={courseActivityAttempts}
          correctAnswers={correctAnswers || null}
          currentActivityHint={currentActivityHint}
          currentActivityId={activityId}
          dangerLabel={activityValidationError}
          hasHint={hasHint}
          hasNextActivity={hasNextMemoryBooster}
          image={questionImage}
          imageAlignment={imageAlignment}
          imageAlt={questionImageDescription || activityTitle}
          imageTitle={questionImageTitle || activityTitle}
          incorrectAnswers={incorrectAnswers || null}
          isActivityFeedbackOpen={isOpenMemoryBoosterFeedback}
          isActivityQuestionOpen={isOpenMemoryBoosterQuestion}
          isConfirmDisabled={isDisabledConfirm}
          isDanger={hasNotPassed}
          isNewActivity={isNewActivity}
          isReview={isReviewActivity}
          isSavingActivity={isSavingMemoryBooster}
          isSkippingActivity={isSkippingMemoryBooster}
          onChange={this.onChange}
          onOpenActivityModal={this.onOpenMemoryBoosterImage}
          onSubmit={this.onSubmit}
          pageRef={$content}
          renderImageAfterQuestion={() =>
            this.renderImageAfterQuestion(
              questionImage,
              questionImageDescription || activityTitle,
              questionImageTitle || activityTitle,
            )
          }
          replaceActivityMathFormulas={replaceActivityMathFormulas}
          scrollY={scrollY}
          skipped={isSkippedActivity}
          title={question}
          type={activityType}
          value={
            memoryBoosterAnswer &&
            typeof memoryBoosterAnswer === 'object' &&
            memoryBoosterAnswer['$mobx']
              ? memoryBoosterAnswer['$mobx']['values']
              : memoryBoosterAnswer
          }
          video={questionVideo}
          // choices activities
          choice={{
            choices: memoryBooster.cleanedChoices,
          }}
          // input activities
          input={{
            isMultipleInput: memoryBooster.isMultipleInput,
            textBefore: memoryBooster.textBeforeInput,
            textAfter: memoryBooster.textAfterInput,
          }}
          // matching activity
          matching={{
            definitions: memoryBooster.pairs && memoryBooster.pairs.definitions,
            labels: memoryBooster.pairs && memoryBooster.pairs.labels,
          }}
          // sorting activity
          sortStack={{
            items: memoryBooster.pairs && memoryBooster.pairs.labels,
          }}
          // hotspot activity
          hotspot={memoryBooster.hotSpotData}
          // dragPhrase activity
          dragPhrase={{
            content: memoryBooster.cleanedContent,
            categories: memoryBooster.categories,
            items: memoryBooster.draggableItems,
          }}
        />
        <IncompleteQuestionModal
          isOpen={isOpenIncompleteQuestionModal}
          numAnswersIncomplete={numAnswersIncomplete}
          numAnswersTotal={numAnswersTotal}
          onClose={closeIncompleteQuestionModal}
          onConfirm={this.onConfirmIncomplete}
        />
      </Fragment>
    )
  }
  renderRead() {
    const { $content } = this.state
    const { intl, uiStore } = this.props
    const { formatMessage } = intl
    const { isOpenIdleModal } = uiStore
    const { lectureNotes: memoryBoosterSectionLectureNotes } =
      this.memoryBoosterSection
    return (
      <div className="MemoryBoosterModal-read">
        <div className="MemoryBoosterModal-sectionContent" key="course-read">
          <IdleTimer
            onIdle={this.onIdle}
            startOnLoad
            timeout={getIdleTimeThreshold(READ_TAB)}
          />
          <TimeTracking
            isIdle={isOpenIdleModal}
            isMemoryBooster
            section={this.memoryBoosterSection}
            what={TYPE_MEMORY_BOOSTER_READ}
          />
          <div className="Course-read content">
            {memoryBoosterSectionLectureNotes ? (
              <ReadTabLectureNotes
                isReview
                lectureNotes={memoryBoosterSectionLectureNotes}
                scrollableEl={$content}
                onOpenActivityModal={this.onOpenMemoryBoosterImage}
              />
            ) : (
              <div className="Course-noNotes title">
                {formatMessage(MemoryBoosterModalMessages.noLectureNotes)}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
  renderWatch() {
    const { uiStore } = this.props
    const { isOpenIdleModal } = uiStore
    return (
      <div className="MemoryBoosterModal-watch">
        <IdleTimer
          onIdle={this.onIdle}
          startOnLoad
          timeout={getIdleTimeThreshold(WATCH_TAB)}
        />
        <TimeTracking
          isIdle={isOpenIdleModal}
          isMemoryBooster
          section={this.memoryBoosterSection}
          what={TYPE_MEMORY_BOOSTER_WATCH}
        />
        <div className="MemoryBoosterModal-sectionContent" key="course-watch">
          <div className="Course-watch">{this.renderVideo()}</div>
        </div>
      </div>
    )
  }
  renderVideo() {
    const { video: memoryBoosterSectionVideo } = this.memoryBoosterSection || {}
    return (
      <Video
        entryId={memoryBoosterSectionVideo.entryId}
        entrypoint={memoryBoosterSectionVideo.entrypoint}
        type={memoryBoosterSectionVideo.type}
        url={memoryBoosterSectionVideo.url}
      />
    )
  }
  render() {
    const { contentStore, intl, isOpen, uiStore } = this.props
    const { isOpenMemoryBoosterImage, memoryBoosterImage, view } = this.state
    const { title: memoryBoosterSectionTitle } = this.memoryBoosterSection || {}
    const { isLoadingMemoryBooster, memoryBooster } = contentStore
    const { isOpenIdleModal, isOpenIdleSessionModal } = uiStore
    const { formatMessage } = intl
    if (isOpenMemoryBoosterImage) {
      return (
        <ActivityModal
          image={memoryBoosterImage}
          isOpen={isOpenMemoryBoosterImage}
          isReview
          onClose={this.onCloseMemoryBoosterImage}
        />
      )
    }
    return (
      <div className="MemoryBoosterModalWrapper">
        <IdleModal
          isOpen={isOpenIdleModal && !isOpenIdleSessionModal}
          onClose={this.onCloseIdleModal}
          sectionTitle={memoryBoosterSectionTitle}
          tab={view}
        />
        {!isOpenIdleModal &&
        !isOpenIdleSessionModal &&
        isOpenMemoryBoosterImage ? (
          <ActivityModal
            image={memoryBoosterImage}
            isOpen={isOpenMemoryBoosterImage}
            isReview
            onClose={this.onCloseMemoryBoosterImage}
          />
        ) : null}
        {!isOpenIdleModal &&
        !isOpenIdleSessionModal &&
        !isOpenMemoryBoosterImage ? (
          <Modal
            className="MemoryBoosterModal"
            contentLabel="Memory Booster Modal"
            isOpen={isOpen}
            overlayClassName="MemoryBoosterModal__overlay"
          >
            <div
              className={cx('MemoryBoosterModal__main', {
                'is-loading': isLoadingMemoryBooster || !memoryBooster,
              })}
            >
              {isLoadingMemoryBooster || !memoryBooster ? (
                <Loader
                  className="MemoryBoosterModal__loader"
                  text={formatMessage(
                    MemoryBoosterModalMessages.loadingMemoryBooster,
                  )}
                />
              ) : (
                this.renderMain()
              )}
            </div>
          </Modal>
        ) : null}
      </div>
    )
  }
}
