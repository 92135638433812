import cx from 'classnames'
import _ from 'lodash'
import React, { Component } from 'react'
import { Button, Heading, Loader, Modal } from '@/components'
import { CheckboxCustom, Input } from '@/components/form'
import { SELECT_ROLES } from '@/constants'
import './NewUserModal.scss'

const NewUserModalDefaultValues = function () {
  return {
    email: '',
    field1: '', // location
    field2: '', // job code / roles
    field3: '', // custom label
    firstName: '',
    instructor: null,
    lastName: '',
    roles: [],
  }
}

export default class NewUserModal extends Component {
  static defaultProps = {
    values: new NewUserModalDefaultValues(),
  }
  constructor(props) {
    super(props)
    this.state = {
      instructorName: '',
      instructorOptions: [],
    }
  }
  componentDidMount() {
    const { loadInstructors } = this.props
    if (loadInstructors) {
      loadInstructors()
        .then((result) => this.setState({ instructorOptions: result }))
        .catch(() => this.setState({ instructorOptions: [] }))
    }
  }
  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    const { isOpen: wasOpen } = prevProps
    if (wasOpen && !isOpen) {
      this.onChangeInstructorName({ target: { value: '' } })
    }
  }
  onChange = (field, value) => {
    const { onChange, values } = this.props
    const { roles } = values
    if (onChange) {
      if (field === 'roles') {
        onChange(
          'roles',
          roles.indexOf(value) === -1
            ? [value, ...roles]
            : roles.filter((role) => role !== value),
        )
      } else {
        onChange(field, value)
      }
    }
  }
  onChangeInstructorName = (event) => {
    const { instructorName } = this.state
    const { target } = event
    const { value } = target
    if (instructorName !== value) {
      this.setState({ instructorName: value }, () =>
        this.searchInstructors(value),
      )
    }
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  onSubmit = (event) => {
    const { onConfirm } = this.props
    if (onConfirm) {
      event.preventDefault()
      onConfirm()
    }
  }
  searchInstructors = _.debounce(
    (value) => {
      const { loadInstructors } = this.props
      loadInstructors(value, [])
        .then((result) => {
          let { instructorName: instructorNameValidation } = this.state
          if (instructorNameValidation === value) {
            this.setState({ instructorOptions: result })
          }
        })
        .catch(() => {
          let { instructorName: instructorNameValidation } = this.state
          if (instructorNameValidation === value) {
            this.setState({ instructorOptions: [] })
          }
        })
    },
    250,
    { leading: true, trailing: true },
  )
  renderBody = () => {
    const { error, isLoadingInstructors, values } = this.props
    const { instructorName, instructorOptions } = this.state
    const {
      email,
      field1,
      field2,
      field3,
      firstName,
      instructor,
      lastName,
      roles,
    } = values || { roles: [] }
    const isErrorFirstName =
      error && error['user'] && error['user']['firstName']
        ? error['user']['firstName'][0]
        : null
    const isErrorLastName =
      error && error['user'] && error['user']['lastName']
        ? error['user']['lastName'][0]
        : null
    const isErrorEmail =
      error && error['user'] && error['user']['email']
        ? error['user']['email'][0]
        : null
    const isErrorRoles = error && error['roles'] ? error['roles'][0] : null
    const isErrorInstructorId =
      error && error['instructorId'] ? error['instructorId'][0] : null
    const isErrorUsername =
      error && error['user'] && error['user']['username']
        ? error['user']['username'][0]
        : null
    const hasFieldErrors =
      isErrorFirstName ||
      isErrorLastName ||
      isErrorEmail ||
      isErrorRoles ||
      isErrorInstructorId ||
      isErrorUsername
    const classNameFirstName = cx('column', {
      'is-error': isErrorFirstName,
    })
    const classNameLastName = cx('column', {
      'is-error': isErrorLastName,
    })
    const classNameEmail = cx('field', {
      'is-error': isErrorEmail || isErrorUsername,
    })
    const classNameRoles = cx('NewUserModal-accountTypeField field', {
      'is-error': isErrorRoles,
    })
    const classNameInstructorId = cx('field', {
      'is-error': isErrorInstructorId,
    })
    let options = SELECT_ROLES.map(({ label, value }) => ({ label, value }))
    return (
      <form className="NewUserModal-body" onSubmit={this.onSubmit}>
        <div>
          <div className="field columns">
            <div className={classNameFirstName}>
              <Heading is2>First name (Required)</Heading>
              <Input
                name="NewUserModal-firstNameFieldName"
                onChange={(event) =>
                  this.onChange('firstName', event.target.value || '')
                }
                value={firstName || ''}
              />
              {isErrorFirstName ? <span>{`* ${isErrorFirstName}`}</span> : null}
            </div>
            <div className={classNameLastName}>
              <Heading is2>Last name (Required)</Heading>
              <Input
                name="NewUserModal-lastNameFieldName"
                onChange={(event) =>
                  this.onChange('lastName', event.target.value || '')
                }
                value={lastName || ''}
              />
              {isErrorLastName ? <span>{`* ${isErrorLastName}`}</span> : null}
            </div>
          </div>
          <div className={classNameEmail}>
            <Heading is2>Email (Required)</Heading>
            <Input
              name="NewUserModal-emailFieldName"
              onChange={(event) =>
                this.onChange('email', event.target.value || '')
              }
              value={email || ''}
            />
            {isErrorEmail ? <span>{`* ${isErrorEmail}`}</span> : null}
            {!isErrorEmail && isErrorUsername ? (
              <span>{`* ${isErrorUsername}`}</span>
            ) : null}
          </div>
          <div className="field columns">
            <div className="column">
              <Heading is2>Location</Heading>
              <Input
                name="NewUserModal-field1"
                placeholder="Enter location..."
                onChange={(event) =>
                  this.onChange('field1', event.target.value || '')
                }
                value={field1 || ''}
              />
            </div>
            <div className="column">
              <Heading is2>Job Code / Role</Heading>
              <Input
                name="NewUserModal-field2"
                placeholder="Enter job code or role..."
                onChange={(event) =>
                  this.onChange('field2', event.target.value || '')
                }
                value={field2 || ''}
              />
            </div>
            <div className="column">
              <Heading is2>Other Label</Heading>
              <Input
                name="NewUserModal-field3"
                placeholder="Enter custom label..."
                onChange={(event) =>
                  this.onChange('field3', event.target.value || '')
                }
                value={field3 || ''}
              />
            </div>
          </div>
          <div className={classNameRoles}>
            <Heading is2>Account Type (Required)</Heading>
            <div className="NewUserModal-accountTypeButtons">
              {options.map((option) => (
                <CheckboxCustom
                  checked={roles.filter((role) => role === option.value).length}
                  name={`NewUserModal-accountTypeButton-${option.value}`}
                  key={`NewUserModal-accountTypeButton-${option.value}`}
                  onChange={() => this.onChange('roles', option.value)}
                >
                  {option.label}
                </CheckboxCustom>
              ))}
            </div>
            {isErrorRoles ? <span>{`* ${isErrorRoles}`}</span> : null}
          </div>
          {roles.indexOf('student') !== -1 ? (
            <div className={classNameInstructorId}>
              <Heading is2>Instructor</Heading>
              <div className="NewUserModal-instructorField">
                <div className="NewUserModal-instructorInput">
                  <Input
                    name="NewUserModal-instructorFieldName"
                    isSuccess={!!instructor}
                    placeholder="Enter instructor name..."
                    onChange={this.onChangeInstructorName}
                    value={instructor ? instructor.fullName : instructorName}
                    readOnly={!!instructor}
                  />
                  {instructor ? (
                    <Button onClick={() => this.onChange('instructor', null)}>
                      x
                    </Button>
                  ) : null}
                </div>
                <div className="NewUserModal-instructorResults">
                  <div
                    className={cx('NewUserModal-instructorOptions', {
                      'is-loading': isLoadingInstructors,
                    })}
                  >
                    {instructorOptions.length ? (
                      instructorOptions.map((instructorOption) => (
                        <Button
                          className={cx({
                            'is-selected':
                              instructor &&
                              instructor.instructorId ===
                                instructorOption.instructorId,
                          })}
                          key={`NewUserModal-instructorOptions-${instructorOption.instructorId}-${instructorOption.fullName}`}
                          onClick={() =>
                            this.onChange('instructor', instructorOption)
                          }
                        >
                          {instructorOption.fullName}
                        </Button>
                      ))
                    ) : (
                      <span className="NewUserModal-instructorNoResults">
                        No results...
                      </span>
                    )}
                  </div>
                  {isLoadingInstructors ? (
                    <div className="NewUserModal-instructorLoader">
                      <Loader />
                    </div>
                  ) : null}
                </div>
              </div>
              {isErrorInstructorId ? (
                <span>{`* ${isErrorInstructorId}`}</span>
              ) : null}
            </div>
          ) : null}
        </div>
        {hasFieldErrors ? (
          <div className="NewUserModal__fieldError">
            <span>
              <span className="NewUserModal__fieldErrorIcon">!</span>The above
              must be completed before proceeding.
            </span>
          </div>
        ) : null}
        <Button className="NewUserModal-submit" isHiddenSubmit type="submit" />
      </form>
    )
  }
  render() {
    const { error, isLoading, isOpen, onConfirm } = this.props
    const errorMessageConfirm =
      error && error['confirm'] ? error['confirm'][0] : null
    return (
      <Modal
        className="NewUserModal"
        contentLabel="New User Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>Add Single User</Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button isSecondary onClick={this.onClose}>
            Cancel
          </Modal.Button>
          <Modal.Button isLoading={isLoading} onClick={onConfirm}>
            Save
          </Modal.Button>
          {errorMessageConfirm ? (
            <span className="NewUserModal-confirmError">{`* ${errorMessageConfirm}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { NewUserModalDefaultValues }
