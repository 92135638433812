import React, { Component } from 'react'

import { ACTIVITY_TYPES } from '@/constants'

import ActivityChoice from './ActivityChoice/ActivityChoice'
import ActivityInput from './ActivityInput/ActivityInput'
import ActivityMatch from './ActivityMatch/ActivityMatch'
import ActivitySort from './ActivitySort/ActivitySort'
import ActivitySpot from './ActivitySpot/ActivitySpot'
import ActivityPhrase from './ActivityPhrase/ActivityPhrase'

export default class Activity extends Component {
  renderActivity() {
    const {
      type,
      title,
      choice,
      input,
      matching,
      sortStack,
      hotspot,
      dragPhrase,
      ...restProps
    } = this.props

    if (type === ACTIVITY_TYPES.singleChoice) {
      return <ActivityChoice {...restProps} {...choice} title={title} />
    }
    if (type === ACTIVITY_TYPES.multipleChoice) {
      return <ActivityChoice {...restProps} {...choice} title={title} isMulti />
    }
    if (type === ACTIVITY_TYPES.textInput) {
      return <ActivityInput {...restProps} {...input} title={title} />
    }
    if (type === ACTIVITY_TYPES.numericInput) {
      const { textBefore: numericInputTitle } = input
      return (
        <ActivityInput
          {...restProps}
          {...input}
          title={numericInputTitle}
          isNumeric
        />
      )
    }
    if (type === ACTIVITY_TYPES.matching) {
      return <ActivityMatch {...restProps} {...matching} title={title} />
    }
    if (type === ACTIVITY_TYPES.hotspot) {
      return (
        <ActivitySpot {...restProps} {...hotspot} forceOnlyBody title={title} />
      )
    }
    if (type === ACTIVITY_TYPES.sortStack) {
      return <ActivitySort {...restProps} {...sortStack} title={title} />
    }
    if (type === ACTIVITY_TYPES.dragPhrase) {
      return <ActivityPhrase {...restProps} {...dragPhrase} />
    }
  }

  render() {
    return this.renderActivity()
  }
}
