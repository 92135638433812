import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import qs from 'query-string'
import debounce from 'lodash/debounce'
import AccessCodePage from '../AccessCodePage/AccessCodePage'
import {
  ActivityModal,
  Button,
  Counter,
  CourseCompleted,
  FirstSectionMessageModal,
  Heading,
  IdleModal,
  Loader,
  MemoryBoosterPanel,
  MoreContentBelowBanner,
  SectionCompleted,
  Tabs,
  Video,
  VideoDescription,
} from '@/components'
import TimeTracking, {
  TYPE_VIDEO,
} from '@/components/TimeTracking/TimeTracking'
import { ScrollToTop, ScrollToTopOnMount } from '@/components/ScrollRestoration'
import {
  checkIfScrollable,
  checkIfScrolledToBottom,
} from '@/components/MoreContentBelowBanner/MoreContentBelowBanner'
import { StudentLayout } from '@/layouts'
import CoursePagePractice from './CoursePagePractice'
import CoursePageQuiz from './CoursePageQuiz'
import CoursePageRead from './CoursePageRead'
import IdleTimer from 'react-idle-timer'
import {
  WATCH_TAB,
  READ_TAB,
  PRACTICE_TAB,
  QUIZ_TAB,
} from '@/stores/models/section'
import {
  RANK_UNENGAGED,
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET,
  REATTEMPT_STATUS_FINISHED,
} from '@/constants'
import { capitalizeFirstLetter, getIdleTimeThreshold } from '@/utils/helpers'
import './CoursePage.scss'

const CoursePageMessages = defineMessages({
  backToDashboard: {
    defaultMessage: 'Learner Dashboard',
    description:
      'Button to navigate to the learner dashboard from the section mastered screen on the course page',
    id: 'CoursePage.backToDashboard',
  },
  confirmAnswer: {
    defaultMessage: 'Confirm Answer',
    description:
      'Button to submit an answer for an activity from the course page.',
    id: 'CoursePage.confirmAnswer',
  },
  goToNextUnit: {
    defaultMessage: 'Go to Next Unit',
    description:
      'Button to navigate to the next unit from the course page for screen readers.',
    id: 'CoursePage.goToNextUnit',
  },
  goToPreviousUnit: {
    defaultMessage: 'Go to Previous Unit',
    description:
      'Button to navigate to the previous unit from the course page for screen readers.',
    id: 'CoursePage.goToPreviousUnit',
  },
  launchingSection: {
    defaultMessage: 'Launching Section...',
    description:
      'Description displayed while the Section is launching on any Course Section page.',
    id: 'CoursePage.launchingSection',
  },
  loadingCourse: {
    defaultMessage: 'Loading Course...',
    description:
      'Description displayed while the Course is loading on any Course Section page.',
    id: 'CoursePage.loadingCourse',
  },
  memoryBoosters: {
    defaultMessage: 'Memory Boosters',
    description:
      'Button to open the memory booster panel displayed for mobile and tablet on the course read, practice and watch tabs.',
    id: 'CoursePage.memoryBoosters',
  },
  nextButton: {
    defaultMessage: 'Next',
    description:
      'Button to go to the next tab or screen within the course page.',
    id: 'CoursePage.nextButton',
  },
  nextSection: {
    defaultMessage: 'Next Section',
    description: 'Button to navigate to the next section from the course page.',
    id: 'CoursePage.nextSection',
  },
  quizLabel: {
    defaultMessage: 'Quiz:',
    description:
      'Label displayed when taking a Quiz in a completed section on the course page.',
    id: 'CoursePage.quizLabel',
  },
  reattemptLabel: {
    defaultMessage: 'Reattempt:',
    description:
      'Label displayed when reattempting a completed section and continue practicing on the course page.',
    id: 'CoursePage.reattemptLabel',
  },
  reviewLabel: {
    defaultMessage: 'Review:',
    description:
      'Label displayed when re-entering a completed section that does not require a reattempt and continue practicing on the course page.',
    id: 'CoursePage.reviewLabel',
  },
  tabPractice: {
    defaultMessage: 'Practice',
    description:
      'Label for the selectable tabs to enter the Practice content containing activities.',
    id: 'CoursePage.tabPractice',
  },
  tabQuiz: {
    defaultMessage: 'Quiz',
    description: 'Label for the selectable tabs to enter the Quiz.',
    id: 'CoursePage.tabQuiz',
  },
  tabRead: {
    defaultMessage: 'Read',
    description:
      'Label for the selectable tabs to enter the Read content containing written lecture notes.',
    id: 'CoursePage.tabRead',
  },
  tabWatch: {
    defaultMessage: 'Watch',
    description:
      'Label for the selectable tabs to enter the Watch content containing video lecture.',
    id: 'CoursePage.tabWatch',
  },
})

const initialActivityUiState = {
  activityModalImage: null,
  answerHasChanged: false,
  isActivityModalOpen: false,
}

const initialActivityState = {
  ...initialActivityUiState,
  answer: null,
  isActivityFeedbackOpen: false,
  isActivityHintOpen: false,
  isActivityQuestionOpen: false,
  isConfirmDisabled: false,
  isNewHint: false,
}

const initialUiState = {
  isOpenCourseCompletion: false,
  isScrollable: false,
  isScrolledToBottom: false,
  scrollPosition: null,
}

const initialState = {
  ...initialActivityState,
  ...initialUiState,
  activeSectionId: null,
  footerLeft: null,
  footerWidth: null,
  isLaunchingSection: false,
  section: null,
}

function _getTabText(tabId) {
  if (tabId === 'practice') return CoursePageMessages.tabPractice
  if (tabId === 'read') return CoursePageMessages.tabRead
  if (tabId === 'watch') return CoursePageMessages.tabWatch
  if (tabId === 'quiz') return CoursePageMessages.tabQuiz
}

@injectIntl
@withRouter
@inject('contentStore', 'reportingStore', 'uiStore', 'xapiStore')
@observer
export default class CoursePage extends Component {
  state = { ...initialState }
  get course() {
    const { contentStore } = this.props
    const { course } = contentStore
    if (!course) return null
    return course
    // const { course } = this.props
    // return course
  }
  get unit() {
    const { match } = this.props
    const { params } = match
    const { unitId: paramsUnitId } = params
    const course = this.course
    if (!course || !paramsUnitId) return null
    return course.findUnitById(paramsUnitId) || null
    // const { unit } = this.props
    // return unit
  }
  get section() {
    const { match } = this.props
    const { params } = match
    const { sectionId: paramsSectionId } = params
    const unit = this.unit
    if (!unit || !paramsSectionId) return null
    return unit.findSectionById(paramsSectionId) || null
    // const { section } = this.props
    // return section
  }
  get nextSection() {
    const course = this.course
    const section = this.section
    const unit = this.unit
    const { index: sectionIndex } = section
    const { index: unitIndex } = unit
    const sectionIndexLast = unit.sections.length - 1
    const unitIndexLast = course.units.length - 1
    let sectionIndexNext = sectionIndex + 1
    let unitIndexNext = unitIndex + 1
    if (sectionIndex < sectionIndexLast) return unit.sections[sectionIndexNext]
    if (unitIndex < unitIndexLast)
      return course.units[unitIndexNext].sections[0]
    return course.units[0].sections[0]
  }
  get currentActivity() {
    const { activity } = this.section
    return activity || null
  }
  get isNotMasteredOrIsInReview() {
    const {
      isMastered: isMasteredSection,
      isReview: isReviewSection,
      isReviewDone: isReviewSectionDone,
      pretestMastered: isPretestMasteredSection,
    } = this.section
    return (
      (!isMasteredSection && !isPretestMasteredSection) ||
      (isReviewSection && !isReviewSectionDone)
    )
  }
  get isPractice() {
    const { match } = this.props
    const { params } = match
    const { tab: tabId } = params
    return tabId === PRACTICE_TAB
  }
  get isPracticeDone() {
    const { isActivityFeedbackOpen } = this.state
    const {
      isMastered: isMasteredSection,
      isReview: isReviewSection,
      isReviewDone: isReviewSectionDone,
      pretestMastered: isPretestMasteredSection,
    } = this.section
    return (
      !isActivityFeedbackOpen &&
      ((!isReviewSection && (isPretestMasteredSection || isMasteredSection)) ||
        isReviewSectionDone)
    )
  }
  get isSectionDone() {
    const { contentStore } = this.props
    const { hasPassedQuiz, hasQuiz } = this.section
    const { settings } = contentStore
    const { quiz: isQuizEnabled, quizMastery: isQuizMastery } = settings
    return (
      this.isPracticeDone &&
      (!isQuizEnabled || !hasQuiz || !isQuizMastery || hasPassedQuiz)
    )
  }
  get numSectionsRemaining() {
    const { contentStore } = this.props
    const { sectionsProgress } = contentStore
    const { completedSections, nSections } = sectionsProgress
    const numSectionsRemaining = nSections - completedSections
    return numSectionsRemaining
  }
  get sectionProgress() {
    const { contentStore } = this.props
    const { isUpdatingCourseProgress } = contentStore
    const { settings } = contentStore
    const { isRequireMastery } = settings || {}
    const {
      mastery: sectionMastery,
      progress: sectionProgress,
      rank: sectionRank,
      reattemptRank: sectionRankReattempt,
      type: sectionType,
    } = this.section
    let progress =
      !sectionType || sectionType === 'adaptive'
        ? sectionMastery
        : sectionProgress
    if (
      !isUpdatingCourseProgress &&
      isRequireMastery &&
      progress === 100 &&
      sectionRank === RANK_UNENGAGED &&
      (!sectionRankReattempt || sectionRankReattempt === RANK_UNENGAGED)
    ) {
      progress = 0
    }
    return progress
  }
  componentDidMount() {
    const { match, uiStore } = this.props
    const { params } = match
    const { sectionId: paramsSectionId, tab: paramsTabId } = params
    this.setState({ activeSectionId: paramsSectionId })
    if (
      this.unit &&
      this.section &&
      !this.unit.needsAccessCode &&
      !this.section.locked
    ) {
      // if section has a current activity
      // then reset activity
      if (this.currentActivity) {
        this.resetActivity()
      }
      // launch new xAPI session
      if (!this.isPracticeDone) {
        this.launchSection()
      }
    }
    if (this.course && this.course.progress === 0) {
      uiStore.openFirstSectionMessage()
    }
    this.checkIfScrollable()
    this.onResizeSetFooterWidth()
    window.addEventListener('resize', this.debouncedCheckIfScrollable)
    window.addEventListener('resize', this.onResizeSetFooterWidth)
    this.$coursePage.addEventListener('scroll', this.onScroll)

    uiStore.updateTitle(`Course Page ${capitalizeFirstLetter(paramsTabId)}`)
  }
  componentDidUpdate(prevProps) {
    const { location, match, uiStore } = this.props
    const { activeSectionId } = this.state
    const { params } = match
    const {
      sectionId: paramsSectionId,
      tab: paramsTabId,
      unitId: paramsUnitId,
    } = params
    const { location: prevLocation, match: prevMatch } = prevProps
    const { params: prevParams } = prevMatch
    const {
      sectionId: prevParamsSectionId,
      tab: prevParamsTabId,
      unitId: prevParamsUnitId,
    } = prevParams
    const { isOpenCourseCompletion } = this.state
    const { hash, search } = location
    const { hash: prevHash, search: prevSearch } = prevLocation
    const querystring = qs.parse(search)
    const prevQuerystring = qs.parse(prevSearch)
    const { completed } = querystring
    const { completed: wasCompleted } = prevQuerystring
    if (
      this.unit &&
      this.section &&
      !this.unit.needsAccessCode &&
      !this.section.locked
    ) {
      // if section has a current activity
      // and section or unit has changed
      // then reset activity
      // and launch new xAPI session
      if (
        paramsSectionId !== prevParamsSectionId ||
        paramsUnitId !== prevParamsUnitId
      ) {
        this.resetActivity()
        if (paramsSectionId !== activeSectionId) {
          this.setState({ activeSectionId: paramsSectionId })
        }
        if (!this.isPracticeDone) {
          this.launchSection()
        }
      }
      // Check if scrollable when changing tab to Practice, or changing section
      if (
        paramsTabId === PRACTICE_TAB &&
        (prevParamsTabId !== PRACTICE_TAB ||
          prevParamsSectionId !== paramsSectionId)
      ) {
        this.checkIfScrollable()
        this.onResizeSetFooterWidth()
      }
    }
    if (isOpenCourseCompletion && wasCompleted && !completed) {
      this.onCloseCourseCompletionScreen()
    }
    if (!prevHash && hash) {
      const anchor = this.$coursePage.querySelector(hash)
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth' })
      }
    }

    uiStore.updateTitle(`Course Page ${capitalizeFirstLetter(paramsTabId)}`)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedCheckIfScrollable)
    window.removeEventListener('resize', this.onResizeSetFooterWidth)
    this.$coursePage.removeEventListener('scroll', this.onScroll)
    this.terminateSection()
  }
  checkIfScrollable = () => {
    const { uiStore } = this.props
    const { isScrollable: wasScrollable } = this.state
    const { mediaQuery } = uiStore
    const coursePagePadding =
      mediaQuery === MEDIA_QUERY_MOBILE || mediaQuery === MEDIA_QUERY_TABLET
        ? 80
        : 20
    const totalHeight = this.$course ? this.$course.clientHeight : null
    const visibleHeight = this.$coursePage
      ? this.$coursePage.clientHeight - coursePagePadding
      : null
    checkIfScrollable(
      wasScrollable,
      totalHeight,
      visibleHeight,
      (isScrollable) => {
        if (
          (wasScrollable && !isScrollable) ||
          (!wasScrollable && isScrollable)
        ) {
          this.setState({ isScrollable })
        }
      },
    )
  }
  debouncedCheckIfScrollable = debounce(this.checkIfScrollable, 250)
  launchSection = () => {
    const { match, xapiStore } = this.props
    const { isLaunchingSection } = this.state
    const { params } = match
    const { sectionId: paramsSectionId, unitId: paramsUnitId } = params
    if (isLaunchingSection) return
    this.setState({ isLaunchingSection: true })
    this.section
      .launchSection(this.course.id, paramsUnitId, paramsSectionId)
      .then(() => xapiStore.launchSection(paramsUnitId, paramsSectionId))
      .then(() => this.setState({ isLaunchingSection: false }))
  }
  terminateSection = () => {
    const { xapiStore } = this.props
    xapiStore.terminateSession()
  }
  onChangeActivity = (value) => {
    const { hasNextActivity } = this.section
    if (!hasNextActivity) {
      this.setState({ answer: value, answerHasChanged: true })
    }
  }
  onChangeSection = (section) => {
    const { history } = this.props
    const { id: currentSectionId } = this.section || {}
    const {
      id: sectionId,
      locked: isLockedSection,
      tabs: sectionTabs,
    } = section || { locked: true, tabs: [] }
    const sectionFirstTab = sectionTabs[0]
    const { href: sectionFirstTabHref } = sectionFirstTab || {}
    const { activeSectionId, footerLeft, footerWidth, ...restInitialState } =
      initialState
    if (!sectionFirstTabHref || isLockedSection) return
    if (sectionId === currentSectionId) return
    this.setState({ ...restInitialState })
    history.push(sectionFirstTabHref)
  }
  onChangeTab = (tab) => {
    const { history, match } = this.props
    const { params } = match
    const { tab: currentTabId } = params
    const { href: tabHref, id: tabId } = tab
    if (this.$courseContent) {
      this.$courseContent.focus()
    }
    if (tabId === currentTabId) return
    // set everything but isActivityModalOpen to initial state
    const {
      activeSectionId,
      answer,
      isActivityFeedbackOpen,
      isActivityHintOpen,
      isActivityQuestionOpen,
      isConfirmDisabled,
      isLaunchingSection,
      isNewHint,
      ...restInitialState
    } = initialState
    const newTabHref =
      !!this.currentActivity && tabId === 'practice'
        ? `${tabHref}?a=${this.currentActivity.id}`
        : tabHref
    this.setState({ ...restInitialState })

    history.push(newTabHref)
  }
  onClickBackToDashboard = () => {
    const { history } = this.props
    history.push('/dashboard')
  }
  onClickShowCompletionScreen = () => {
    const { history, location } = this.props
    const { pathname, search } = location
    const querystring = qs.parse(search)
    querystring['completed'] = true
    history.replace(`${pathname}?${qs.stringify(querystring)}`)
    this.setState({ isOpenCourseCompletion: true })
  }
  onCloseActivityFeedback = () => {
    this.setState({ isActivityFeedbackOpen: false }, () => {
      this.resetActivity()
      this.resetScrollPosition()
    })
  }
  onCloseActivityModal = () => {
    // const { uiStore } = this.props
    // const { scrollTop, scrollY } = uiStore
    const { scrollPosition } = this.state
    setTimeout(() => {
      this.$coursePage.scrollTo(0, scrollPosition)
    }, 0)
    this.setState({
      activityModalImage: null,
      isActivityModalOpen: false,
      scrollPosition: null,
    })
    // scrollY(null, scrollTop)
  }
  onCloseCourseCompletionScreen = () => {
    this.setState({ isOpenCourseCompletion: false })
  }
  onCloseFirstSectionMessage = () => {
    const { uiStore } = this.props
    uiStore.closeFirstSectionMessage()
  }
  onConfirmNegativeStreakModal = () => {
    const { uiStore } = this.props
    uiStore.closeNegativeStreakModal()
  }
  onConfirmRushInterventionModal = () => {
    const { uiStore } = this.props
    const { closeRushInterventionModal } = uiStore
    closeRushInterventionModal()
  }
  onDisableConfirm = () => {
    this.setState({ isConfirmDisabled: true })
  }
  onEnableConfirm = () => {
    this.setState({ isConfirmDisabled: false })
  }
  onIdle = () => {
    const { uiStore } = this.props
    uiStore.openIdleModal()
  }
  onCloseIdleModal = () => {
    const { uiStore } = this.props
    uiStore.closeIdleModal()
  }
  onConfirmIncomplete = (event) => {
    this.onSubmit(event, true)
  }
  onLoadCourse = () => {
    const { contentStore } = this.props
    contentStore.loadCourse().then(() => {
      if (
        this.unit &&
        this.section &&
        !this.unit.needsAccessCode &&
        !this.section.locked
      ) {
        this.launchSection()
      }
    })
  }
  onLoadActivity = () => {
    const { history, match } = this.props
    const { params: oldParams } = match
    this.section.loadActivity().then((activity) => {
      const { match: newMatch } = this.props
      const { params: newParams } = newMatch
      this.resetScrollPosition()
      if (
        oldParams.sectionId === newParams.sectionId &&
        newParams.tab === 'practice'
      ) {
        history.replace(`${history.location.pathname}?a=${activity.id}`)
      }
    })
  }
  onMoveToNextActivity = () => {
    const { history } = this.props
    const hasNextActivity = this.section.moveToNextActivity()
    this.$coursePage.scrollTo(0, 0)
    this.setFocusCourseContent()
    if (hasNextActivity) {
      this.resetScrollPosition()
      history.replace(
        `${history.location.pathname}?a=${this.currentActivity.id}`,
      )
    }
    return hasNextActivity
  }
  onNextSection = () => {
    this.onChangeSection(this.nextSection)
  }
  onNextTab(tab) {
    if (tab === PRACTICE_TAB) return
    const tabs = this.section.tabs.map((t) => t.id)
    const currTabIndex = tabs.indexOf(tab)
    let nextTab = this.section.tabs[currTabIndex + 1]
    if (currTabIndex < tabs.length - 1) {
      this.onChangeTab(nextTab)
    }
    return true
  }
  onOpenActivityFeedback = () => {
    this.setState({ isActivityFeedbackOpen: true }, () => {
      this.resetScrollPosition()
    })
  }
  onOpenActivityModal = (e, image) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }
    this.setState({
      activityModalImage: image,
      isActivityModalOpen: true,
      scrollPosition: this.$coursePage.scrollTop,
    })
  }
  onResizeSetFooterWidth = () => {
    const { uiStore } = this.props
    const { isDesktop } = uiStore
    if (isDesktop && this.$course && this.$courseFooter) {
      const { clientWidth: footerWidth } = this.$course
      const { left: courseLeft } = this.$course.getBoundingClientRect()
      const { left: footerLeft } = this.$courseFooter.getBoundingClientRect()
      this.setState({ footerLeft: courseLeft - footerLeft, footerWidth })
    }
  }
  onReviewSection = (isReviewOverride = false) => {
    const { activeSectionId, footerLeft, footerWidth, ...restInitialState } =
      initialState
    this.setState({ ...restInitialState })
    this.resetActivity()
    this.section.startReview(isReviewOverride)
    this.launchSection()
  }
  onSaveActivity = () => {
    const { contentStore, uiStore } = this.props
    const { answer: activityAnswer, isActivityFeedbackOpen } = this.state
    const { settings } = contentStore
    const section = this.section
    let {
      hasNextActivity,
      isMastered: wasMasteredSection,
      isMasteredWithPretestAndReattempts:
        wasMasteredWithPretestAndReattemptsSection,
      isSavingActivity,
      isSkippingActivity,
      reattemptStatus: prevReattemptStatus,
    } = section || {}
    const {
      exam: examSetting,
      isRequireMastery,
      reviewActivitiesType,
    } = settings || {}
    const { activeSectionId, footerLeft, footerWidth, ...restInitialState } =
      initialState
    const wasReattemptStatusFinished =
      prevReattemptStatus === REATTEMPT_STATUS_FINISHED
    if (isSavingActivity)
      return Promise.reject(new Error('Error: already saving activity;'))
    if (isSkippingActivity)
      return Promise.reject(new Error('Error: already skipping activity;'))
    if (hasNextActivity) {
      this.setState({ ...restInitialState })
      return this.onMoveToNextActivity()
    }
    return section
      .saveActivity(activityAnswer)
      .then(({ hasPassed, showCompletionScreen }) => {
        let {
          activityTimestamp,
          isMastered: isMasteredSection,
          pretestMastered: isPretestMasteredSection,
          reattemptStatus,
          streak,
        } = section
        const isReattemptStatusFinished =
          reattemptStatus === REATTEMPT_STATUS_FINISHED
        hasNextActivity = section.hasNextActivity
        if (!hasPassed) {
          // if less than 5 seconds have passed since the activityTimestamp was set
          // open RushInterventionModal
          if (activityTimestamp && Date.now() - activityTimestamp <= 1000 * 5) {
            uiStore.openRushInterventionModal()
          } else if (streak <= -3) {
            uiStore.openNegativeStreakModal()
          }
        }
        if (!isActivityFeedbackOpen && hasPassed) {
          this.resetScrollPosition()
          this.onOpenActivityFeedback()
        }
        if (reviewActivitiesType && hasNextActivity) {
          contentStore.loadMemoryBoosters()
        }
        // if section just got mastered
        // or reattempt is finished
        if (
          !isPretestMasteredSection &&
          ((!wasMasteredSection && isMasteredSection) ||
            (!wasReattemptStatusFinished && isReattemptStatusFinished))
        ) {
          this.resetScrollPosition()
          contentStore.updateCourseProgress().then(() => {
            let {
              isMasteredWithPretestAndReattempts:
                isMasteredWithPretestAndReattemptsSection,
            } = section
            if (showCompletionScreen) {
              this.terminateSection()
              this.course.showCompletionScreen()
              // NOTE - For SCORM (https://headware4.jira.com/browse/FM-2801)
              if (
                !examSetting &&
                (!isRequireMastery ||
                  (!wasMasteredWithPretestAndReattemptsSection &&
                    isMasteredWithPretestAndReattemptsSection &&
                    this.course.isMasteredWithPretestAndReattempts))
              ) {
                contentStore.sendCourseCompletionMessage()
              }
            } else if (
              !examSetting &&
              isRequireMastery &&
              !wasMasteredWithPretestAndReattemptsSection &&
              isMasteredWithPretestAndReattemptsSection &&
              this.course.isMasteredWithPretestAndReattempts
            ) {
              this.terminateSection()
              this.course.showCompletionScreen()
              // NOTE - For SCORM (https://headware4.jira.com/browse/FM-2801)
              contentStore.sendCourseCompletionMessage()
            }
          })
        }
        this.setState({ answerHasChanged: false })
        return hasPassed
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
  onScroll = debounce(() => {
    const { match, uiStore } = this.props
    const { isScrolledToBottom } = this.state
    const { isLoadingActivity } = this.section
    const { params } = match
    const { tab } = params
    const { mediaQuery } = uiStore
    const coursePagePadding =
      mediaQuery === MEDIA_QUERY_MOBILE || mediaQuery === MEDIA_QUERY_TABLET
        ? 80
        : 20
    if (
      this.$course &&
      this.$coursePage &&
      (tab === PRACTICE_TAB || tab === QUIZ_TAB || this.isPracticeDone)
    ) {
      const scrollTop =
        this.$coursePage.clientHeight -
        coursePagePadding +
        this.$coursePage.scrollTop
      if (
        !isScrolledToBottom &&
        !isLoadingActivity &&
        checkIfScrolledToBottom(scrollTop, this.$course.clientHeight)
      ) {
        this.setState({
          isScrolledToBottom: true,
          scrollPosition: this.$coursePage.scrollTop,
        })
      } else {
        this.setState({ scrollPosition: this.$coursePage.scrollTop })
      }
    }
  }, 200)
  onSubmit = (event, confirmedIncomplete = false) => {
    const { uiStore } = this.props
    const { answer: activityAnswer, isActivityFeedbackOpen } = this.state
    const { activity, hasNextActivity, isSavingActivity, isSkippingActivity } =
      this.section || {}
    const { openIncompleteQuestionModal } = uiStore
    if (isActivityFeedbackOpen || isSavingActivity || isSkippingActivity) return
    if (!activityAnswer && !hasNextActivity) return
    if (!confirmedIncomplete && !hasNextActivity) {
      const isValidAnswer = activity.validateAnswer(
        activityAnswer,
        openIncompleteQuestionModal,
      )
      if (!isValidAnswer) return
    }
    if (hasNextActivity) {
      this.$coursePage.scrollTo(0, 0)
      return this.onSaveActivity()
    } else {
      return this.onSaveActivity()
        .then((hasPassed) => {
          if (!hasPassed) {
            this.onDisableConfirm()
          }
          this.$coursePage.scrollTo(0, 0)
          this.setFocusCourseContent()
        })
        .catch((error) => {
          console.error(
            error && error.message
              ? error.message
              : 'Error: could not submit activity;',
          )
        })
    }
  }
  onToggleActivityQuestion = () => {
    const { isActivityQuestionOpen } = this.state
    return this.setState({ isActivityQuestionOpen: !isActivityQuestionOpen })
  }
  onToggleActivityHint = () => {
    const { isActivityHintOpen } = this.state
    return this.setState({
      isActivityHintOpen: !isActivityHintOpen,
      isNewHint: false,
    })
  }
  resetActivity = () => {
    this.setState({
      ...initialActivityState,
      isScrolledToBottom: false,
      scrollPosition: null,
    })
    this.section.resetActivity()
  }
  resetScrollPosition = () => {
    this.onResizeSetFooterWidth()
    this.setState({ isScrolledToBottom: false, scrollPosition: null }, () => {
      this.checkIfScrollable()
    })
  }
  setCourseEl = (el) => {
    this.$course = el
  }
  setCourseContentEl = (el) => {
    this.$courseContent = el
  }
  setCourseFooterEl = (el) => {
    this.$courseFooter = el
  }
  setCoursePageEl = (el) => {
    this.$coursePage = el
  }
  setFocusCourseContent = () => {
    if (!this.$courseContent) return
    this.$courseContent.focus()
  }
  renderAccessCodePage() {
    const { customAccessCodeMessage } = this.course || {}
    return (
      <ScrollToTop>
        <ScrollToTopOnMount />
        <AccessCodePage
          accessCodeMessage={customAccessCodeMessage}
          loadCourse={this.onLoadCourse}
          unit={this.unit}
        />
      </ScrollToTop>
    )
  }
  renderActivityButtons() {
    const { contentStore, intl, uiStore } = this.props
    const { answer: activityAnswer, isConfirmDisabled } = this.state
    const {
      error: sectionError,
      hasNextActivity,
      isSavingActivity,
    } = this.section || {}
    const { settings } = contentStore
    const { openMemoryBoosterPanelModal } = uiStore
    const { formatMessage } = intl
    const { reviewActivitiesType } = settings || {}
    return (
      <Fragment>
        <div className="Course-footerButton">
          <Button
            disabled={
              isSavingActivity ||
              !activityAnswer ||
              _.isEmpty(activityAnswer) ||
              sectionError ||
              (!hasNextActivity && isConfirmDisabled)
            }
            isFullWidth
            isLoading={isSavingActivity}
            isSuccess
            key={`Course-footerButtons-activityButtons-${
              !hasNextActivity ? 'confirmAnswer' : 'next'
            }`}
            onClick={this.onSubmit}
            qa-id="learner-activity-confirm-button"
            text={
              !hasNextActivity
                ? formatMessage(CoursePageMessages.confirmAnswer)
                : formatMessage(CoursePageMessages.nextButton)
            }
          />
        </div>
        {reviewActivitiesType ? (
          <div className="Course-footerButton">
            <Button
              className="Course-memoryBoosterButton"
              isFullWidth
              isInfo
              onClick={openMemoryBoosterPanelModal}
              text={formatMessage(CoursePageMessages.memoryBoosters)}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
  renderActivityFeedbackButtons() {
    const { contentStore, intl, uiStore } = this.props
    const {
      isMastered: isMasteredSection,
      isReview: isReviewSection,
      isReviewDone: isReviewSectionDone,
    } = this.section || {}
    const { settings } = contentStore
    const { openMemoryBoosterPanelModal } = uiStore
    const { formatMessage } = intl
    const { reviewActivitiesType } = settings || {}
    return (
      <Fragment>
        <div className="Course-footerButton">
          <Button
            isFullWidth
            isSuccess
            key="Course-footerButtons-activityFeedbackButtons-next"
            onClick={
              isMasteredSection && (!isReviewSection || isReviewSectionDone)
                ? this.onCloseActivityFeedback
                : this.onSaveActivity
            }
            qa-id="learner-practice-next-question-button"
            text={formatMessage(CoursePageMessages.nextButton)}
          />
        </div>
        {reviewActivitiesType ? (
          <div className="Course-footerButton">
            <Button
              className="Course-memoryBoosterButton"
              isFullWidth
              isInfo
              onClick={openMemoryBoosterPanelModal}
              text={formatMessage(CoursePageMessages.memoryBoosters)}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
  renderNextButton() {
    const { contentStore, intl } = this.props
    const {
      isMasteredWithPretest: isMasteredWithPretestCourse,
      shouldShowCompletionScreen,
    } = this.course
    const { isUpdatingCourseProgress } = contentStore
    const { formatMessage } = intl
    if (this.isPractice && !this.isSectionDone) {
      return null
    } else if (!isMasteredWithPretestCourse) {
      return (
        <Button
          isFullWidth
          isSuccess
          key="Course-footerButtons-sectionMasteredButtons-nextSection"
          onClick={this.onNextSection}
          text={formatMessage(CoursePageMessages.nextSection)}
        />
      )
    } else if (!shouldShowCompletionScreen) {
      return (
        <Button
          className={cx({
            'Course-footerButton-isUpdatingCourseProgress':
              isUpdatingCourseProgress,
          })}
          isDisabled={isUpdatingCourseProgress}
          isFullWidth
          isLink
          isSuccess
          key="Course-footerButtons-sectionMasteredButtons-backToDashboard"
          text={formatMessage(CoursePageMessages.backToDashboard)}
          to="/dashboard"
        />
      )
    }
    return (
      <Button
        isFullWidth
        isSuccess
        key="Course-footerButtons-sectionMasteredButtons-next"
        onClick={this.onClickShowCompletionScreen}
        text={formatMessage(CoursePageMessages.nextButton)}
      />
    )
  }
  renderPractice() {
    const { contentStore, uiStore } = this.props
    const { course, settings } = contentStore
    const { id: courseId } = course || {}
    const {
      activityAttempts: courseActivityAttempts,
      practiceLearningObjectives,
    } = settings || {}
    const {
      closeIncompleteQuestionModal,
      isOpenIdleModal,
      isOpenIdleSessionModal,
      isOpenIncompleteQuestionModal,
      isOpenNegativeStreakModal,
      isOpenRushInterventionModal,
      memoryBoosterModal,
      scrollY,
    } = uiStore
    const { isOpen: isOpenMemoryBoosterModal } = memoryBoosterModal
    const {
      answer,
      answerHasChanged,
      isActivityFeedbackOpen,
      isActivityHintOpen,
      isActivityModalOpen,
      isActivityQuestionOpen,
      isConfirmDisabled,
    } = this.state
    const section = this.section
    const {
      activitiesCounters,
      activity,
      error: sectionError,
      hasNextActivity,
      isLoadingActivity,
      isReview: isReviewSection,
      isReviewOverride,
      isSavingActivity,
      isSkippingActivity,
      progress: sectionProgress,
      streak: sectionStreak,
      tabs: sectionTabs,
    } = section || { tabs: [] }
    return (
      <CoursePagePractice
        activitiesCounters={activitiesCounters}
        activity={activity}
        activityAnswer={answer}
        answerHasChanged={answerHasChanged}
        checkIfScrollable={this.checkIfScrollable}
        closeIncompleteQuestionModal={closeIncompleteQuestionModal}
        courseActivityAttempts={courseActivityAttempts}
        courseId={courseId}
        hasNextActivity={hasNextActivity}
        isActivityFeedbackOpen={isActivityFeedbackOpen}
        isActivityHintOpen={isActivityHintOpen}
        isActivityModalOpen={isActivityModalOpen}
        isActivityQuestionOpen={isActivityQuestionOpen}
        isConfirmDisabled={isConfirmDisabled}
        isLoadingActivity={isLoadingActivity}
        isNotMasteredOrIsInReview={this.isNotMasteredOrIsInReview}
        isOpenIdleModal={isOpenIdleModal}
        isOpenIdleSessionModal={isOpenIdleSessionModal}
        isOpenIncompleteQuestionModal={isOpenIncompleteQuestionModal}
        isOpenMemoryBoosterModal={isOpenMemoryBoosterModal}
        isOpenNegativeStreakModal={isOpenNegativeStreakModal}
        isOpenRushInterventionModal={isOpenRushInterventionModal}
        isReviewSection={isReviewSection}
        isReviewOverride={isReviewOverride}
        isSavingActivity={isSavingActivity}
        isSkippingActivity={isSkippingActivity}
        onChange={this.onChangeActivity}
        onCloseActivityModal={this.onCloseActivityModal}
        onConfirmNegativeStreakModal={this.onConfirmNegativeStreakModal}
        onConfirmRushInterventionModal={this.onConfirmRushInterventionModal}
        onDisableConfirm={this.onDisableConfirm}
        onEnableConfirm={this.onEnableConfirm}
        onIdle={this.onIdle}
        onLoadActivity={this.onLoadActivity}
        onOpenActivityModal={this.onOpenActivityModal}
        onSubmit={this.onSubmit}
        onToggleActivityQuestion={this.onToggleActivityQuestion}
        pageRef={this.$coursePage}
        practiceLearningObjectives={practiceLearningObjectives}
        scrollY={scrollY}
        section={section}
        sectionError={sectionError}
        sectionProgress={sectionProgress}
        sectionStreak={sectionStreak}
        sectionTabs={sectionTabs}
      />
    )
  }
  renderPracticeButtons() {
    const { isActivityFeedbackOpen } = this.state
    const { error: sectionError } = this.section || {}
    if (sectionError) return null
    if (isActivityFeedbackOpen) return this.renderActivityFeedbackButtons()
    return this.renderDoneButtons() || this.renderActivityButtons()
  }
  renderDoneButtons() {
    const {
      isMastered: isMasteredSection,
      isReview: isReviewSection,
      isReviewDone: isReviewSectionDone,
      pretestMastered: sectionPretestMastered,
      rank: sectionRank,
    } = this.section || {}
    if (!isReviewSection && sectionPretestMastered)
      return this.renderSnapshotMasteredButtons()
    if (
      isMasteredSection &&
      (!isReviewSection || (isReviewSectionDone && sectionRank !== 'at-risk'))
    )
      return this.renderSectionMasteredButtons()
    if (isReviewSection && isReviewSectionDone)
      return this.renderSectionReattemptDoneButtons()
    return null
  }
  onClickGoToQuiz = () => {
    const { tabs } = this.section
    const quizTab = tabs.filter((t) => t.id === QUIZ_TAB)[0]
    return this.onChangeTab(quizTab)
  }
  onSubmitQuiz = (event, confirmedIncomplete = false) => {
    const { contentStore, uiStore } = this.props
    const { quiz } = this.section
    const {
      activityAnswer: quizActivityAnswer,
      isSavingActivity: isSavingQuizActivity,
    } = quiz || {}
    const { openIncompleteQuestionModal } = uiStore
    if (!quizActivityAnswer || isSavingQuizActivity) return
    if (
      !confirmedIncomplete &&
      !quiz.validateAnswer(openIncompleteQuestionModal)
    )
      return
    quiz.saveActivity().then(() => {
      this.$coursePage.scrollTo(0, 0)
      const { isDone: isDoneQuiz } = quiz
      if (isDoneQuiz) contentStore.updateCourseProgress()
      // TODO: xAPI
      // if (!wasDoneQuiz && isDoneQuiz) {
      //   this.terminateSession()
      // }
    })
  }
  onNextQuiz = () => {
    const { quiz } = this.section
    const { hasNextActivity: hasNextQuizActivity } = quiz || {}
    this.$coursePage.scrollTo(0, 0)
    if (hasNextQuizActivity) {
      return quiz.moveToNextActivity()
    } else {
      return quiz.resetActivity()
    }
  }
  renderQuiz() {
    const { contentStore, match, uiStore } = this.props
    const { isUpdatingCourseProgress, settings } = contentStore || {}
    const { isEncourageMastery, isRequireMastery, quizPass } = settings || {}
    const { params } = match
    const { tab } = params
    const {
      isMastered: isMasteredSection,
      isMasteredWithPretestAndReattempts:
        isMasteredWithPretestAndReattemptsSection,
      pretestMastered: sectionPretestMastered,
      quiz,
      title: sectionTitle,
    } = this.section || {}
    const {
      closeIncompleteQuestionModal,
      isOpenIdleModal,
      isOpenIdleSessionModal,
      isOpenIncompleteQuestionModal,
      // isOpenNegativeStreakModal,
      // isOpenRushInterventionModal,
      memoryBoosterModal,
      scrollY,
    } = uiStore
    const { isOpen: isOpenMemoryBoosterModal } = memoryBoosterModal || {}
    return (
      <Fragment>
        <CoursePageQuiz
          checkIfScrollable={this.checkIfScrollable}
          closeIncompleteQuestionModal={closeIncompleteQuestionModal}
          isMasteredSection={
            !!(
              sectionPretestMastered ||
              (isEncourageMastery && isMasteredSection) ||
              (isRequireMastery && isMasteredWithPretestAndReattemptsSection)
            )
          }
          isOpenIdleModal={isOpenIdleModal}
          isOpenIdleSessionModal={isOpenIdleSessionModal}
          isOpenIncompleteQuestionModal={isOpenIncompleteQuestionModal}
          isOpenMemoryBoosterModal={isOpenMemoryBoosterModal}
          isUpdatingCourseProgress={isUpdatingCourseProgress}
          onDisableConfirm={() => this.onDisableConfirm(true)}
          onEnableConfirm={() => this.onEnableConfirm(true)}
          onOpenActivityModal={this.onOpenActivityModal}
          onSubmit={this.onSubmitQuiz}
          pageRef={this.$coursePage}
          quiz={quiz}
          requiredQuizScore={quizPass}
          section={this.section}
          scrollY={scrollY}
          sectionTitle={sectionTitle}
        />
        <IdleTimer
          onIdle={this.onIdle}
          startOnLoad
          timeout={getIdleTimeThreshold(tab)}
        />
      </Fragment>
    )
  }
  renderQuizActivityButtons() {
    const { intl } = this.props
    const { quiz } = this.section
    const {
      activityAnswer: quizActivityAnswer,
      activityAnswerHasChanged: quizActivityAnswerHasChanged,
      isSavingActivity: isSavingQuizActivity,
    } = quiz || {}
    const { formatMessage } = intl
    return (
      <div className="Course-footerButton">
        <Button
          disabled={
            isSavingQuizActivity ||
            _.isEmpty(quizActivityAnswer) ||
            !quizActivityAnswerHasChanged
          }
          isFullWidth
          isLoading={isSavingQuizActivity}
          isSuccess
          key="Course-footerButtons-activityButtons-confirmAnswer"
          onClick={this.onSubmitQuiz}
          qa-id="learner-activity-confirm-button"
          text={formatMessage(CoursePageMessages.confirmAnswer)}
        />
      </div>
    )
  }
  renderQuizActivityFeedbackButtons() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="Course-footerButton">
        <Button
          isFullWidth
          isSuccess
          key="Course-footerButtons-activityFeedbackButtons-next"
          onClick={this.onNextQuiz}
          qa-id="learner-practice-next-question-button"
          text={formatMessage(CoursePageMessages.nextButton)}
        />
      </div>
    )
  }
  renderQuizIntroButtons() {
    return <div className="Course-footerButton">{this.renderNextButton()}</div>
  }
  renderQuizDoneButtons() {
    return <div className="Course-footerButton">{this.renderNextButton()}</div>
  }
  renderQuizButtons() {
    const { quiz } = this.section || {}
    const {
      activity: quizActivity,
      hasNextActivity: hasNextQuizActivity,
      isDone: isDoneQuiz,
      isIntro: isIntroQuiz,
    } = quiz || {}
    if (hasNextQuizActivity || (isDoneQuiz && quizActivity))
      return this.renderQuizActivityFeedbackButtons()
    if (quizActivity) return this.renderQuizActivityButtons()
    if (isIntroQuiz) return this.renderQuizIntroButtons()
    if (isDoneQuiz) return this.renderQuizDoneButtons()
    return <div className="Course-footerButton">{this.renderNextButton()}</div>
  }
  renderFooterButtons() {
    const { match } = this.props
    const { params } = match
    const { tab } = params
    if (tab === QUIZ_TAB) return this.renderQuizButtons()
    else if (tab === PRACTICE_TAB) return this.renderPracticeButtons()
    else if ((tab === READ_TAB || tab === WATCH_TAB) && this.isPracticeDone) {
      return this.renderNextButton()
    }
    return null
  }
  renderFooter() {
    const { match, uiStore } = this.props
    const { footerLeft, footerWidth, isScrollable, isScrolledToBottom } =
      this.state
    const { params } = match
    const { tab } = params
    const { isDesktop, mediaQuery } = uiStore
    const coursePagePadding =
      mediaQuery === MEDIA_QUERY_MOBILE || mediaQuery === MEDIA_QUERY_TABLET
        ? 80
        : 20
    return (
      <div
        className={cx('CoursePage__footer-wrapper', {
          'CoursePage__footer-wrapper--is-visible':
            ((tab === WATCH_TAB || tab === READ_TAB) && this.isPracticeDone) ||
            tab === PRACTICE_TAB ||
            tab === QUIZ_TAB ||
            this.isSectionDone,
        })}
      >
        <div className="CoursePage__footer-inner-wrapper">
          <div
            className="Course-footer"
            ref={this.setCourseFooterEl}
            style={isDesktop ? { left: footerLeft, width: footerWidth } : null}
          >
            {tab === PRACTICE_TAB || tab === QUIZ_TAB ? (
              <MoreContentBelowBanner
                isScrollable={isScrollable}
                isScrolledToBottom={isScrolledToBottom}
                scrollableEl={this.$coursePage}
                visibleHeight={
                  this.$coursePage
                    ? this.$coursePage.clientHeight - coursePagePadding
                    : 240
                }
              />
            ) : null}
            <div className="Course-footerButtons">
              <div className="Course-footerButtonWrapper">
                {this.renderFooterButtons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderRead() {
    const { uiStore } = this.props
    const section = this.section
    const { isOpenIdleModal, isOpenIdleSessionModal, memoryBoosterModal } =
      uiStore
    const { isOpen: isOpenMemoryBoosterModal } = memoryBoosterModal
    return (
      <CoursePageRead
        isOpenIdleModal={isOpenIdleModal}
        isOpenIdleSessionModal={isOpenIdleSessionModal}
        isOpenMemoryBoosterModal={isOpenMemoryBoosterModal}
        onIdle={this.onIdle}
        onOpenActivityModal={this.onOpenActivityModal}
        section={section}
        scrollableEl={this.$coursePage}
      />
    )
  }
  renderSectionMasteredButtons() {
    const { contentStore, intl, uiStore } = this.props
    const { settings } = contentStore
    const { openMemoryBoosterPanelModal } = uiStore
    const { formatMessage } = intl
    const { reviewActivitiesType } = settings || {}
    return (
      <Fragment>
        <div className="Course-footerButton">{this.renderNextButton()}</div>
        {reviewActivitiesType ? (
          <div className="Course-footerButton">
            <Button
              className="Course-memoryBoosterButton"
              isFullWidth
              isInfo
              onClick={openMemoryBoosterPanelModal}
              text={formatMessage(CoursePageMessages.memoryBoosters)}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
  renderSectionReattemptDoneButtons() {
    const { contentStore, intl, uiStore } = this.props
    const { settings } = contentStore
    const { openMemoryBoosterPanelModal } = uiStore
    const { formatMessage } = intl
    const { reviewActivitiesType } = settings || {}
    return (
      <Fragment>
        <div className="Course-footerButton">{this.renderNextButton()}</div>
        {reviewActivitiesType ? (
          <div className="Course-footerButton">
            <Button
              className="Course-memoryBoosterButton"
              isFullWidth
              isInfo
              onClick={openMemoryBoosterPanelModal}
              text={formatMessage(CoursePageMessages.memoryBoosters)}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
  renderSnapshotMasteredButtons() {
    const { intl, onToggleMemoryBoosterPanel, reviewActivitiesType } =
      this.props
    const { formatMessage } = intl
    return (
      <Fragment>
        <div className="Course-footerButton">{this.renderNextButton()}</div>
        {reviewActivitiesType ? (
          <div className="Course-footerButton">
            <Button
              className="Course-memoryBoosterButton"
              isFullWidth
              isInfo
              onClick={onToggleMemoryBoosterPanel}
              text={formatMessage(CoursePageMessages.memoryBoosters)}
            />
          </div>
        ) : null}
      </Fragment>
    )
  }
  renderWatch() {
    const { match, uiStore } = this.props
    const { params } = match
    const { tab } = params
    const section = this.section
    const {
      hasVideo: hasVideoSection,
      video: sectionVideo,
      videoDescription: sectionVideoDescription,
    } = section || {}
    const {
      entryId: videoEntryId,
      entrypoint: videoEntrypoint,
      type: videoType,
      url: videoURL,
    } = sectionVideo || {}
    const { isOpenIdleModal, memoryBoosterModal } = uiStore
    const { isOpen: isOpenMemoryBoosterModal } = memoryBoosterModal
    if (!hasVideoSection) return <div>No video</div>
    return (
      <div className="Course-watch" key="course-watch">
        {!isOpenMemoryBoosterModal ? (
          <TimeTracking
            isIdle={isOpenIdleModal}
            section={section}
            what={TYPE_VIDEO}
          />
        ) : null}
        <IdleTimer
          onIdle={this.onIdle}
          startOnLoad
          timeout={getIdleTimeThreshold(tab)}
        />
        {sectionVideoDescription ? (
          <VideoDescription
            innerHtml={sectionVideoDescription}
            onOpenImageModal={this.onOpenActivityModal}
          />
        ) : null}
        <Video
          entryId={videoEntryId}
          entrypoint={videoEntrypoint}
          type={videoType}
          url={videoURL}
        />
      </div>
    )
  }
  renderContent() {
    const { match } = this.props
    const { params } = match
    const {
      sectionId: paramsSectionId,
      tab: paramsTabId,
      unitId: paramsUnitId,
    } = params
    const { tabs: sectionTabs } = this.section || { tabs: [] }
    const sectionTabIds = sectionTabs.map((tab) => tab.id)
    if (sectionTabIds.indexOf(paramsTabId) === -1)
      return (
        <Redirect
          to={`/units/${paramsUnitId}/sections/${paramsSectionId}/practice`}
        />
      )
    if (paramsTabId === PRACTICE_TAB) return this.renderPractice()
    if (paramsTabId === QUIZ_TAB) return this.renderQuiz()
    if (paramsTabId === READ_TAB) return this.renderRead()
    if (paramsTabId === WATCH_TAB) return this.renderWatch()
  }
  renderSection() {
    const { contentStore, intl, match } = this.props
    const { isLaunchingSection } = this.state
    const { formatMessage } = intl

    if (isLaunchingSection)
      return (
        <Loader
          ariaLive="assertive"
          role="status"
          text={formatMessage(CoursePageMessages.launchingSection)}
        />
      )

    const { quiz, tabs } = this.section || {}
    const { params } = match
    const { tab: paramsTabId } = params
    const { settings } = contentStore
    const { quiz: isQuizEnabled, quizLockout: isQuizLockout } = settings || {}
    const {
      activity: quizActivity,
      isDone: isQuizDone,
      isStarted: isQuizStarted,
    } = quiz || {}
    const numSectionTabs = tabs.length
    let sectionTabs = []
    tabs.forEach((tab) => {
      const newTab = {
        id: tab.id,
        href: tab.href,
        isDisabled:
          isQuizEnabled &&
          isQuizLockout &&
          (isQuizStarted || (isQuizDone && quizActivity)) &&
          tab.id !== QUIZ_TAB,
        text: formatMessage(_getTabText(tab.id)),
      }
      sectionTabs.push(newTab)
    })
    return (
      <Fragment>
        <Tabs
          active={paramsTabId}
          className={cx('Course-tabs', {
            'is-quad': numSectionTabs === 4,
            'is-triple': numSectionTabs === 3,
            'is-double': numSectionTabs === 2,
            'is-single': numSectionTabs === 1,
          })}
          isAlt
          items={sectionTabs}
          onChange={this.onChangeTab}
        />
        <div
          className="Course-content"
          ref={this.setCourseContentEl}
          tabIndex="0"
        >
          {this.renderContent()}
        </div>
      </Fragment>
    )
  }
  renderSectionCompleted() {
    const { contentStore } = this.props
    const { isUpdatingCourseProgress } = contentStore
    const { settings } = contentStore
    const {
      isEncourageMastery,
      quizMastery: isQuizMastery,
      isRequireMastery,
    } = settings || {}
    const {
      isMasteredWithPretest: isMasteredWithPretestCourse,
      sectionsAtRisk,
    } = this.course
    const {
      hasPassedQuiz,
      hasQuiz,
      isMastered: isMasteredSection,
      isReview: isReviewSection,
      isReviewDone: isReviewSectionDone,
      pretestMastered: isPretestMasteredSection,
      rank: sectionRank,
      reattemptRank: sectionRankReattempt,
      reattemptStatus: sectionReattemptStatus,
      title: sectionTitle,
    } = this.section
    if (isUpdatingCourseProgress) return <Loader />
    return (
      <SectionCompleted
        hasQuiz={hasQuiz}
        hasPassedQuiz={hasPassedQuiz}
        isEncourageMastery={isEncourageMastery}
        isMastered={isMasteredSection}
        isMasteredWithPretestCourse={isMasteredWithPretestCourse}
        isPretestMastered={isPretestMasteredSection}
        isQuizMastery={isQuizMastery}
        isRequireMastery={isRequireMastery}
        isReview={isReviewSection}
        isReviewDone={isReviewSectionDone}
        numSectionsAtRisk={sectionsAtRisk.length}
        numSectionsRemaining={this.numSectionsRemaining}
        onClickReviewSection={this.onReviewSection}
        onClickGoToQuiz={this.onClickGoToQuiz}
        sectionRank={sectionRank}
        sectionRankReattempt={sectionRankReattempt}
        sectionReattemptStatus={sectionReattemptStatus}
        sectionTitle={sectionTitle}
      />
    )
  }
  renderLabel() {
    const { intl, match } = this.props
    const {
      isReview: isReviewSection,
      isReviewDone: isReviewSectionDone,
      isReviewOverride: isReviewSectionOverride,
    } = this.section
    const { params } = match
    const { tab: paramsTabId } = params
    const { formatMessage } = intl
    if (!this.isPracticeDone && paramsTabId === QUIZ_TAB) {
      return (
        <span className="CoursePage-reattemptLabel">
          {formatMessage(CoursePageMessages.quizLabel)}
        </span>
      )
    }
    return isReviewSection && !isReviewSectionDone ? (
      <span className="CoursePage-reattemptLabel">
        {isReviewSectionOverride
          ? formatMessage(CoursePageMessages.reviewLabel)
          : formatMessage(CoursePageMessages.reattemptLabel)}
        &nbsp;
      </span>
    ) : null
  }
  renderMain() {
    const { title: sectionTitle } = this.section
    let progress = this.sectionProgress
    return (
      <div className="Course" id="main-content" ref={this.setCourseEl}>
        <div
          className="Course-sectionProgressMeter"
          style={{ width: `${progress}%` }}
        />
        <div className="Course-header">
          <div className="Course-sectionTitle">
            {this.renderLabel()}
            <Heading className="Course__section-title">{sectionTitle}</Heading>
          </div>
          <div className="Course-sectionProgress" qa-id="learner-progress">
            <Counter
              className="Course-sectionProgress-num"
              suffix="%"
              value={progress}
            />
          </div>
        </div>
        {this.isPracticeDone
          ? this.renderSectionCompleted()
          : this.renderSection()}
      </div>
    )
  }
  renderBody() {
    const { contentStore, history } = this.props
    const { isOpenCourseCompletion } = this.state
    const course = this.course
    const {
      isMasteredWithPretestAndReattempts,
      sectionsAtRisk: courseSectionsAtRisk,
      title: courseTitle,
    } = course || {}
    const { settings } = contentStore
    const {
      courseCompletionCopy,
      courseMasteryCopy,
      isEncourageMastery,
      isRequireMastery,
    } = settings || {}
    if (isOpenCourseCompletion) {
      if (
        (!isRequireMastery || isMasteredWithPretestAndReattempts) &&
        (courseCompletionCopy || courseMasteryCopy)
      ) {
        course.hideCompletionScreen()
        this.onCloseCourseCompletionScreen()
        return <Redirect to="/" />
      }
      return (
        <CourseCompleted
          courseTitle={courseTitle}
          isEncourageMastery={isEncourageMastery}
          isRequireMastery={isRequireMastery}
          numSectionsAtRisk={
            (isRequireMastery || isEncourageMastery) &&
            !isMasteredWithPretestAndReattempts
              ? courseSectionsAtRisk.length
              : null
          }
          onDashboard={() => history.push('/dashboard')}
          onSearch={() => history.push('/search')}
          onUnmount={() => {
            course.hideCompletionScreen()
            this.onCloseCourseCompletionScreen()
          }}
        />
      )
    }
    return this.renderMain()
  }
  render() {
    const { contentStore, intl, match, uiStore } = this.props
    const {
      activeSectionId,
      activityModalImage,
      isActivityModalOpen,
      isLaunchingSection,
      isOpenCourseCompletion,
    } = this.state
    const { isLoadingCourse, settings } = contentStore
    const { formatMessage } = intl
    const { params } = match
    const { sectionId: paramsSectionId, tab: paramsTabId } = params
    const {
      quiz: isQuizEnabled,
      quizLockout: isQuizLockout,
      reviewActivitiesType,
    } = settings
    const {
      closeMemoryBoosterPanelModal,
      isOpenFirstSectionMessage,
      isOpenIdleModal,
      isOpenIdleSessionModal,
      isOpenMemoryBoosterPanelModal,
    } = uiStore
    const {
      isIntro: isIntroSection,
      isReview: isReviewSection,
      pretestMastered: sectionPretestMastered,
      quiz,
      rank: sectionRank,
      tabs: sectionTabs,
      title: sectionTitle,
    } = this.section || {}
    const {
      activity: quizActivity,
      isDone: isQuizDone,
      isStarted: isQuizStarted,
    } = quiz || {}
    const { needsAccessCode } = this.unit || {}
    const practiceTab = sectionTabs.filter((tab) => tab.id === PRACTICE_TAB)[0]
    const quizTab = sectionTabs.filter((tab) => tab.id === QUIZ_TAB)[0]
    const { href: practiceTabURL } = practiceTab || {}
    const { href: quizTabUrl } = quizTab || {}

    // NOTE / TODO / WIP - if !isLoadingCourse and load course has been attempted, display error

    if (!isLoadingCourse && !this.section) return <Redirect to="/not-found" />
    if (
      paramsTabId !== QUIZ_TAB &&
      isQuizEnabled &&
      isQuizLockout &&
      (isQuizStarted || (isQuizDone && quizActivity))
    ) {
      return <Redirect to={quizTabUrl} />
    }
    return (
      <Fragment>
        <StudentLayout.Page
          className={cx('CoursePage', {
            'is-completion-screen': isOpenCourseCompletion,
            'CoursePage--is-quiz-tab': paramsTabId === QUIZ_TAB,
            'CoursePage--is-practice-tab': paramsTabId === PRACTICE_TAB,
            'CoursePage--is-section-completed-screen': this.isPracticeDone,
            'is-reattempt': isReviewSection && sectionRank === 'at-risk',
          })}
          setRef={this.setCoursePageEl}
        >
          {isLoadingCourse || activeSectionId !== paramsSectionId ? (
            <Loader
              ariaLive="assertive"
              role="status"
              text={formatMessage(CoursePageMessages.loadingCourse)}
            />
          ) : needsAccessCode ? (
            this.renderAccessCodePage()
          ) : (
            this.renderBody()
          )}
        </StudentLayout.Page>
        {!isOpenCourseCompletion && !isLaunchingSection
          ? this.renderFooter()
          : null}
        <IdleModal
          isOpen={isOpenIdleModal && !isOpenIdleSessionModal}
          onClose={this.onCloseIdleModal}
          sectionTitle={sectionTitle}
          tab={paramsTabId}
        />
        <ActivityModal
          image={activityModalImage}
          isOpen={isActivityModalOpen}
          onClose={this.onCloseActivityModal}
        />
        <FirstSectionMessageModal
          isOpen={
            isOpenFirstSectionMessage &&
            !sectionPretestMastered &&
            !isIntroSection
          }
          onClose={this.onCloseFirstSectionMessage}
          practiceTabURL={practiceTabURL}
        />
        {reviewActivitiesType ? (
          <MemoryBoosterPanel
            onClose={closeMemoryBoosterPanelModal}
            isModal
            isOpen={isOpenMemoryBoosterPanelModal}
          />
        ) : null}
      </Fragment>
    )
  }
}
