/* eslint no-eval: 0 */
import React from 'react'
import gtmParts from 'react-google-tag-manager'

export default class GoogleTagManager extends React.Component {
  componentDidMount() {
    // const dataLayerName = this.props.dataLayerName || 'dataLayer'
    const scriptId = 'react-google-tag-manager-gtm'
    const gtmScriptNode = document.getElementById(scriptId)
    eval(gtmScriptNode.textContent)

    // NOTE / TODO / WIP
    // AppStore is instantiated prior to GTM component mounting
    // This means window.dataLayer = window.dataLayer || []
    // Therefore this if will always fail
    // Need to look into why this was done
    // if (!window[dataLayerName]) {
    //   const gtmScriptNode = document.getElementById(scriptId)
    //   eval(gtmScriptNode.textContent)
    // }
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false,
    })

    return (
      <div>
        <div>{gtm.noScriptAsReact()}</div>
        <div id={this.props.scriptId || 'react-google-tag-manager-gtm'}>
          {gtm.scriptAsReact()}
        </div>
      </div>
    )
  }
}
