import { computed, observable } from 'mobx'

import { DEFAULT_LOCALE } from '@/constants'
import { toCamelCase } from '@/utils/helpers'

export default class Session {
  @observable user

  @observable courseId
  @observable internalCourseId
  @observable orgId

  @observable courses = []

  @observable locale = DEFAULT_LOCALE

  @observable token
  @observable tokenExpirationDate

  @observable logoutUrl
  @observable preview = null
  @observable previewUrl

  @observable adminBeta

  @observable showCoursePicker
  @observable snapshot = false

  @observable offsetTime = 0

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get currentRole() {
    if (!this.user) return null
    return this.user.currentRole
  }

  @computed get isLoggedIn() {
    return !!this.token && !this.isTokenExpired()
  }

  @computed get isPreview() {
    return !!this.preview
  }

  isTokenExpired() {
    return this.tokenExpirationDate.getTime() < Date.now() + this.offsetTime
  }

  @computed get hasMoreCourses() {
    return this.courses.length > 1
  }
}
