import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { Heading, Modal } from '@/components'
import './RushInterventionModal.scss'

const RushInterventionModalMessages = defineMessages({
  copy: {
    defaultMessage: `Rushing and answering questions incorrectly will slow your course progress. Head back to Read or Watch for a refresh. When you're ready, Practice to demonstrate your knowledge and mastery. Developing great learning habits will allow you to complete the course more efficiently.`,
    description:
      'Description displayed within the rush intervention modal triggered when a user answers a question too fast.',
    id: 'RushInterventionModal.copy',
  },
  practice: {
    defaultMessage: 'Stay on current tab',
    description: `Button to navigate to the section's practice tab from the rush intervention modal triggered when a user answers a question too fast.`,
    id: 'RushInterventionModal.practice',
  },
  read: {
    defaultMessage: 'Read',
    description: `Button to navigate to the section's read tab from the rush intervention modal triggered when a user answers a question too fast.`,
    id: 'RushInterventionModal.read',
  },
  title: {
    defaultMessage: 'Personalized recommendation',
    description:
      'Title displayed in the rush intervention modal triggered when a user answers a question too fast.',
    id: 'RushInterventionModal.title',
  },
  watch: {
    defaultMessage: 'Watch',
    description: `Button to navigate to the section's watch tab from the rush intervention modal triggered when a user answers a question too fast.`,
    id: 'RushInterventionModal.watch',
  },
})

@injectIntl
@inject('uiStore')
export default class RushInterventionModal extends Component {
  onConfirm = () => {
    const { onConfirm, uiStore } = this.props
    if (onConfirm) {
      onConfirm()
    }
    uiStore.scrollToTop()
  }
  render() {
    const { intl, isOpen, readTabURL, watchTabURL } = this.props
    const { formatMessage } = intl
    return (
      <Modal
        className="RushInterventionModal"
        contentLabel="Rush Intervention Modal"
        isOpen={isOpen}
      >
        <Modal.Header>
          <Heading>
            {formatMessage(RushInterventionModalMessages.title)}
          </Heading>
        </Modal.Header>
        <Modal.Body qa-id="learner-rush-modal">
          <p>{formatMessage(RushInterventionModalMessages.copy)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button
            isSecondary
            key="RushInterventionModal-confirmButton"
            onClick={this.onConfirm}
            qa-id="learner-rush-modal-confirm"
          >
            {formatMessage(RushInterventionModalMessages.practice)}
          </Modal.Button>
          {readTabURL ? (
            <Modal.Button
              isLink
              key="RushInterventionModal-readButton"
              onClick={this.onConfirm}
              to={readTabURL}
            >
              {formatMessage(RushInterventionModalMessages.read)}
            </Modal.Button>
          ) : null}
          {watchTabURL ? (
            <Modal.Button
              isLink
              key="RushInterventionModal-watchButton"
              onClick={this.onConfirm}
              to={watchTabURL}
            >
              {formatMessage(RushInterventionModalMessages.watch)}
            </Modal.Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}
