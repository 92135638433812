import humps from 'lodash-humps'
import { action, observable } from 'mobx'

import { ERROR_SNAPSHOT_LOAD_ACTIVITY } from '@/constants'
import SentryReplay from '@/SentryReplay'
import { hasOwnProperty, toCamelCase } from '@/utils/helpers'
import request from '@/utils/request'

import Activity from './activity'

export default class Snapshot {
  @observable activity = null
  @observable activityCounter = null
  @observable activityError = null
  @observable activitySectionId = null
  @observable activityUnitId = null
  @observable canSkip = true
  @observable canTestOut = true
  @observable courseId = null
  @observable courseTitle = ''
  @observable isDone = false
  @observable isIntro = false
  @observable isOpen = false
  @observable isStandalone = false
  @observable isLoadingActivity = false
  @observable isSavingActivity = false
  @observable isSkippingActivity = false
  @observable nextActivity = null
  @observable numActivities = null
  @observable numSectionsTestedOut = null
  @observable totalActivities = null
  @observable totalSections = null

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  createNewActivity(activityData) {
    const activity = new Activity({ ...activityData })
    return activity
  }

  @action done() {
    this.isOpen = false
  }

  @action start() {
    this.isIntro = false
  }

  @action resetActivity() {
    this.activity = null
    this.activitySectionId = null
    this.activityUnitId = null
  }

  @action loadActivity() {
    this.isLoadingActivity = true
    this.activityError = null
    return request
      .get(`/activities/${this.courseId}/snapshot`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadActivity)
      .catch(this.handleLoadActivityError)
  }
  @action handleLoadActivity = (response) => {
    const { data } = response
    const {
      activity: activityData,
      activityCounter,
      pretestDone,
      sectionCounters,
      sectionId: activitySectionId,
      unitId: activityUnitId,
    } = humps(data)
    if (
      pretestDone &&
      sectionCounters &&
      hasOwnProperty(sectionCounters, 'testedOut') &&
      hasOwnProperty(sectionCounters, 'total')
    ) {
      const { testedOut: numSectionsTestedOut, total: totalSections } =
        sectionCounters || {}
      this.numSectionsTestedOut = numSectionsTestedOut
      this.totalSections = totalSections
      this.isDone = true
    } else if (
      activityData &&
      activityCounter &&
      activitySectionId &&
      activityUnitId
    ) {
      this.resetActivity()
      this.activity = this.createNewActivity(activityData)
      this.activityCounter = activityCounter
      this.activitySectionId = activitySectionId
      this.activityUnitId = activityUnitId
    } else {
      return this.handleLoadActivityError(
        new Error(ERROR_SNAPSHOT_LOAD_ACTIVITY),
      )
    }
    this.isLoadingActivity = false
    return Promise.resolve()
  }
  @action handleLoadActivityError = (error) => {
    const { message } = error
    SentryReplay.captureException(error)
    if (message === ERROR_SNAPSHOT_LOAD_ACTIVITY) {
      this.activityError = ERROR_SNAPSHOT_LOAD_ACTIVITY
    }
    this.isLoadingActivity = false
    return Promise.reject(error)
  }

  @action saveActivity(activityAnswer, skip = false) {
    if (this.isSavingActivity)
      return Promise.reject(new Error('Already saving activity;'))
    if (this.isSkippingActivity)
      return Promise.reject(new Error('Already skipping activity;'))
    if (!activityAnswer && !skip) return Promise.reject(new Error('No answer;'))

    const { id: currentActivityId, isMultipleInput } = this.activity

    if (!skip) {
      this.isSavingActivity = true
    } else {
      this.isSkippingActivity = true
    }

    let POST = { current_activity_id: currentActivityId }
    if (skip) {
      POST = { ...POST, skip: true }
    } else if (isMultipleInput) {
      POST = { ...POST, answers: this.activity.adaptAnswer(activityAnswer) }
    } else {
      POST = { ...POST, answer: this.activity.adaptAnswer(activityAnswer) }
    }

    return request
      .post(`/activities/${this.courseId}/snapshot`, POST)
      .then(this.handleSaveActivity)
      .catch(this.handleSaveActivityError)
  }
  @action handleSaveActivity = (response) => {
    const { data: responseData } = response
    const {
      nextActivity: nextActivityData,
      nextActivityCounter,
      nextActSectionId: nextActivitySectionId,
      nextActUnitId: nextActivityUnitId,
      pretestDone,
      // NOTE / TODO / WIP - this should probably not be returned
      // response: activityResponse,
      sectionCounters,
      // NOTE / TODO / WIP - confirm if we should expect total activities to ever change
      // if not, we can probaby get rid of this
      // totalActivities
    } = humps(responseData)
    this.resetActivity()
    if (nextActivityData) {
      this.activity = this.createNewActivity(nextActivityData)
      this.activityCounter = nextActivityCounter
      this.activitySectionId = nextActivitySectionId
      this.activityUnitId = nextActivityUnitId
    } else if (
      pretestDone &&
      sectionCounters &&
      hasOwnProperty(sectionCounters, 'testedOut') &&
      hasOwnProperty(sectionCounters, 'total')
    ) {
      const { testedOut: numSectionsTestedOut, total: totalSections } =
        sectionCounters || {}
      this.numSectionsTestedOut = numSectionsTestedOut
      this.totalSections = totalSections
      this.isDone = true
    } else {
      // NOTE / TEMP / TODO / WIP - this is only temporary, pretestDone should be set to true
      this.isDone = true
    }
    this.isSavingActivity = false
    this.isSkippingActivity = false
    return Promise.resolve(response)
  }
  @action handleSaveActivityError = (error) => {
    const { response } = error || {}
    const { status } = response || {}
    SentryReplay.captureException(error)
    this.isSavingActivity = false
    if (status === 403 || status === 409) {
      return this.loadActivity()
    }
    return Promise.reject(error)
  }
  validateAnswer(activityAnswer, openIncompleteQuestionModal) {
    return this.activity.validateAnswer(
      activityAnswer,
      openIncompleteQuestionModal,
    )
  }
}
