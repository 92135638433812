import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import cx from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { MESSAGE_UNREAD_STATUS } from '@/constants'
import { Link, UserAvatar, Composer, Loader } from '@/components'
import './Messages.scss'

const MessagesMessages = defineMessages({
  hideRecipients: {
    defaultMessage: 'Hide',
    description: 'Button to hide all message recipients in an open thread.',
    id: 'Messages.hideRecipients',
  },
  labelMessagesCount: {
    defaultMessage: `{messagesCount} {messagesCount, plural,
      one {Message}
      other {Messages}
    }`,
    description:
      'Label displayed to show the number of messages in an open thread.',
    id: 'Messages.labelMessagesCount',
  },
  labelNew: {
    defaultMessage: 'New',
    description:
      'Label displayed to show a message in an open thread has not been read.',
    id: 'Messages.labelNew',
  },
  labelRecipients: {
    defaultMessage: 'Conversation with {recipients}',
    description:
      'Label displayed to show message recipients in an open thread.',
    id: 'Messages.labelRecipients',
  },
  loading: {
    defaultMessage: 'Loading messages...',
    description:
      'Description displayed while initial or additional messages in an open thread being retrieved.',
    id: 'Messages.loading',
  },
  noMessages: {
    defaultMessage: 'No messages.',
    description:
      'Description displayed if there are no messages in an open thread.',
    id: 'Messages.noMessages',
  },
  showRecipients: {
    defaultMessage: '+{remainingRecipientsCount} more',
    description:
      'Button to show all message recipients in an open thread with an indicator for the total number of recipients.',
    id: 'Messages.showRecipients',
  },
})

const BOTTOM_THRESHOLD = 70
const TOP_SCROLL_HINT = 30
const MAX_RECIPIENTS = 3
const DEBOUNCED_VALUE = 50

@injectIntl
@observer
export default class Messages extends Component {
  forceScrollToBottom = false
  shouldScrollToBottom = false
  shouldScrollToPos = false
  isScrolledUp = false
  scrollPos = null

  componentDidMount() {
    this.scrollToBottom(true)
    this.messagesListEl.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount() {
    this.messagesListEl.removeEventListener('scroll', this.onScroll)
  }
  componentDidUpdate(prevProps) {
    const { messages: currentMessages, threadId: currentThreadId } = this.props
    const {
      isLoadingMore: wasLoadingMore,
      messages: prevMessages,
      threadId: prevThreadId,
    } = prevProps

    if (prevThreadId !== currentThreadId) {
      this.forceScrollToBottom = true
      return
    }

    const prevMessagesCount = prevMessages.length
    const currentMessagesCount = currentMessages.length

    if (currentMessagesCount > prevMessagesCount) {
      if (wasLoadingMore) this.shouldScrollToPos = true
      if (!wasLoadingMore) this.shouldScrollToBottom = true
    }

    this.scrollToBottom()
  }
  get isScrolledToBottom() {
    const { scrollTop, offsetHeight, scrollHeight } = this.messagesListEl
    return scrollTop + offsetHeight + BOTTOM_THRESHOLD >= scrollHeight
  }
  get isScrolledToTop() {
    const { scrollTop } = this.messagesListEl
    return scrollTop === 0
  }
  onScroll = _.debounce(() => {
    this.isScrolledUp = !this.isScrolledToBottom
    if (this.isScrolledToTop) {
      this.scrollPos = this.messagesListEl.scrollHeight
      this.props.onLoadMore()
    }
  }, DEBOUNCED_VALUE)
  onSend = (message) => {
    this.forceScrollToBottom = true
    this.props.onSend(message)
  }
  setMessagesListEl = (el) => {
    this.messagesListEl = el
  }
  scrollToBottom(force) {
    if (this.forceScrollToBottom || force) {
      this.messagesListEl.scrollTop = this.messagesListEl.scrollHeight
      this.forceScrollToBottom = false
      return
    }

    if (this.shouldScrollToBottom && !this.isScrolledUp) {
      this.messagesListEl.scrollTop = this.messagesListEl.scrollHeight
      this.shouldScrollToBottom = false
      return
    }

    if (this.shouldScrollToPos) {
      this.messagesListEl.scrollTop =
        this.messagesListEl.scrollHeight - this.scrollPos - TOP_SCROLL_HINT
      this.shouldScrollToPos = false
    }
  }
  openProfile(e, userId) {
    e.preventDefault()
    this.props.openProfileModal(userId)
  }
  renderLoader() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="Messages-loader">
        <Loader text={formatMessage(MessagesMessages.loading)} />
      </div>
    )
  }
  renderAvatarList() {
    const { recipients } = this.props
    const visibleRecipients = this.props.isNamesOpen
      ? recipients
      : recipients.slice(0, MAX_RECIPIENTS)
    let zIndexTotal = visibleRecipients.length

    return (
      <div className="Messages-avatars">
        {visibleRecipients.map((recipient) => (
          <div
            className="Messages-avatar"
            key={recipient.id}
            style={{ zIndex: zIndexTotal-- }}
          >
            <a href="#" onClick={(e) => this.openProfile(e, recipient.id)}>
              <UserAvatar
                size={50}
                className="UserAvatar--large"
                name={recipient.fullName}
              />
            </a>
          </div>
        ))}
        {recipients.length > MAX_RECIPIENTS && !this.props.isNamesOpen ? (
          <div className="Messages-avatar" style={{ zIndex: 0 }}>
            <UserAvatar
              size={50}
              name={`${recipients.length - MAX_RECIPIENTS}`}
              className="UserAvatar--number"
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
  renderRecipientsNames() {
    const { intl, recipients } = this.props
    const { formatMessage } = intl
    const visibleRecipients = this.props.isNamesOpen
      ? recipients
      : recipients.slice(0, MAX_RECIPIENTS)
    return (
      <div className="Messages-names">
        <FormattedMessage
          {...MessagesMessages.labelRecipients}
          values={{
            recipients: (
              <div className="Messages-recipients">
                {visibleRecipients.map((recipient) => (
                  <span key={recipient.id}>
                    <a
                      href="#"
                      onClick={(e) => this.openProfile(e, recipient.id)}
                    >
                      {recipient.firstName}
                    </a>
                  </span>
                ))}
              </div>
            ),
          }}
        />
        {recipients.length > MAX_RECIPIENTS ? (
          <Link
            className={cx('Messages-showMore', {
              'is-open': this.props.isNamesOpen,
            })}
            onClick={this.props.onToggleNames}
            to="#"
          >
            {this.props.isNamesOpen
              ? formatMessage(MessagesMessages.hideRecipients)
              : formatMessage(MessagesMessages.showRecipients, {
                  remainingRecipientsCount: recipients.length - MAX_RECIPIENTS,
                })}
          </Link>
        ) : null}
      </div>
    )
  }
  renderMessages() {
    const { intl, isLoading, messages } = this.props
    const { formatMessage } = intl
    return (
      <div className="Messages-list" ref={this.setMessagesListEl}>
        {isLoading && this.renderLoader()}
        {messages.length ? (
          messages.map(this.renderMessage)
        ) : !isLoading ? (
          <span className="Messages-listEmpty">
            {formatMessage(MessagesMessages.noMessages)}
          </span>
        ) : null}
      </div>
    )
  }
  renderMessageStats() {
    const { intl, messages } = this.props
    const { formatMessage } = intl

    return (
      <div className="Messages-count">
        {formatMessage(MessagesMessages.labelMessagesCount, {
          messagesCount: messages.length,
        })}
      </div>
    )
  }
  renderMessage = (message) => {
    const { intl } = this.props
    const { formatMessage } = intl
    const { readStatus } = message
    const isUnread = readStatus === MESSAGE_UNREAD_STATUS
    return (
      <div key={message.id} className="Message media">
        <div className="media-left">
          <a
            className="Message-UserAvatar"
            href="#"
            onClick={(e) => this.openProfile(e, message.author.id)}
          >
            <UserAvatar name={message.author.fullName} />
          </a>
        </div>
        <div className="media-content">
          <div className="Message-name">
            <a href="#" onClick={(e) => this.openProfile(e, message.author.id)}>
              {message.displayName}
            </a>
            <span className="Message-time">
              <i aria-hidden="true" className="fa fa-clock-o" />
              {message.fmodified}
            </span>
            {isUnread ? (
              <span className="Message-unreadLabel">
                {formatMessage(MessagesMessages.labelNew)}
              </span>
            ) : null}
          </div>
          <div className="Message-content">{message.message}</div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div className="Messages">
        <div
          className={cx('Messages-header media', {
            'is-expanded': this.props.isNamesOpen,
          })}
        >
          <div className="media-left">{this.renderAvatarList()}</div>
          <div className="media-content">
            {this.renderRecipientsNames()}
            {this.renderMessageStats()}
          </div>
        </div>
        <div className="Messages-composer">
          {this.renderMessages()}
          <Composer onSend={this.onSend} />
        </div>
      </div>
    )
  }
}
