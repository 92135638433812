import _ from 'lodash'
import React, { Component } from 'react'
import cx from 'classnames'
import { Button } from '@/components'
import './UpdatesBanner.scss'
class UpdatesBanner extends Component {
  state = {
    height: '0px',
    isHidden: true,
  }
  images = []
  imagesLoaded = []
  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) {
      this.onAddEventListeners()
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { isOpen } = this.props
    const { isHidden } = this.state
    const { isOpen: wasOpen } = prevProps
    const { isHidden: wasHidden } = prevState
    if (wasOpen && !isOpen) {
      this.onRemoveEventListeners()
    } else if (!wasOpen && isOpen) {
      this.onAddEventListeners()
    } else if (wasHidden && !isHidden) {
      this.setHeight()
    }
  }
  componentWillUnmount() {
    this.onRemoveEventListeners()
  }
  onAddEventListeners = () => {
    this.images = this.wrapper.querySelectorAll('img')
    this.imagesLoaded = []
    this.wrapper.addEventListener('transitionend', this.onTransitionEnd, false)
    window.addEventListener('resize', this.onResize, false)
    if (this.images.length) {
      for (let i = 0; i < this.images.length; i++) {
        this.images[i].addEventListener('load', this.onLoadImage)
      }
    } else {
      this.setState({
        isHidden: false,
      })
    }
  }
  onClick = () => {
    const { onClick } = this.props
    if (onClick) {
      onClick()
    }
  }
  onLoadImage = (event) => {
    const { target } = event
    target.removeEventListener('load', this.onLoadImage)
    this.imagesLoaded.push(target)
    if (this.images.length === this.imagesLoaded.length) {
      this.setState({
        isHidden: false,
      })
    }
  }
  onRemoveEventListeners = () => {
    this.wrapper.removeEventListener(
      'transitionend',
      this.onTransitionEnd,
      false,
    )
    window.removeEventListener('resize', this.onResize, false)
    for (let i = 0; i < this.images.length; i++) {
      this.images[i].removeEventListener('load', this.onLoadImage)
    }
    this.images = []
    this.imagesLoaded = []
  }
  onResize = () => {
    this.setHeight()
  }
  onTransitionEnd = (event) => {
    const { isOpen } = this.props
    const { propertyName, target } = event
    const isHeight = propertyName === 'height'
    const isTarget = this.wrapper === target
    if (isHeight && isTarget && !isOpen) {
      this.wrapper.removeEventListener(
        'transitionend',
        this.onTransitionEnd,
        false,
      )
      this.setState({
        isHidden: true,
      })
    }
  }
  setHeight = _.debounce(
    () => {
      this.setState({
        height: `${this.el.getBoundingClientRect().height}px`,
      })
    },
    250,
    { leading: true, trailing: true },
  )
  renderUpdates() {
    const { updates } = this.props
    return updates && updates.length
      ? updates.map((update, index) => {
          let { message } = update
          return (
            <div
              key={`UpdatesBanner-update-${index}`}
              className="UpdatesBanner-updates"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )
        })
      : null
  }
  render() {
    const {
      children,
      className,
      isHiddenButtons,
      isHiddenHeader,
      isHTML,
      isOpen,
      renderHeader,
    } = this.props
    const { height, isHidden } = this.state
    return (
      <div
        className={cx(
          'UpdatesBanner-wrapper',
          {
            'is-open': isOpen,
          },
          className,
        )}
        ref={(el) => {
          this.wrapper = el
        }}
        style={{ height: isOpen ? height : '0px' }}
      >
        <div
          className={cx('UpdatesBanner', {
            'is-hidden': isHidden,
            'is-html': isHTML,
          })}
          ref={(el) => {
            this.el = el
          }}
        >
          {!isHiddenHeader && renderHeader ? (
            <div className="UpdatesBanner-header">{renderHeader()}</div>
          ) : null}
          <div className="UpdatesBanner-content">
            {children || this.renderUpdates()}
          </div>
          {!isHiddenButtons ? (
            <div className="UpdatesBanner-buttons">
              <Button
                className="UpdatesBanner-hideButton"
                isInfo
                onClick={this.onClick}
              >
                X
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
export default UpdatesBanner
