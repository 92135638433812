import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import _ from 'lodash'
import { Button } from '@/components'
import './CheckboxCustom.scss'
const CheckboxCustomMessages = defineMessages({
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to zoom in on checkbox option images.',
    id: 'CheckboxCustom.enlargeImage',
  },
})
@injectIntl
export default class CheckboxCustom extends Component {
  static defaultProps = {
    isRadio: false,
  }
  render() {
    const {
      adminPreventDefault,
      children,
      intl,
      text,
      secondaryText,
      isRadio,
      imageUrl,
      isLabeled,
      isFullLabeled,
      isBlock,
      isColored,
      onOpenActivityModal,
      ...restProps
    } = this.props
    const { formatMessage } = intl

    const className = cx(
      'CheckboxCustom',
      {
        'is-radio': isRadio,
        'is-image': imageUrl,
        'is-labeled': isLabeled,
        'is-full-labeled': isFullLabeled,
        'is-block': isBlock,
        'is-colored': isColored && isBlock,
        'is-danger':
          this.props.value &&
          this.props.value === 'at-risk' &&
          isBlock &&
          isColored,
        'is-success':
          this.props.value &&
          this.props.value === 'all-star' &&
          isBlock &&
          isColored,
      },
      this.props.className,
    )

    const iconClassName = cx('fa', {
      'fa-check': !isRadio,
      'fa-circle': isRadio,
    })

    const labelProps = {
      disabled: this.props.disabled,
    }
    const defaultProps = {}

    // by default id === name
    if (this.props.name) defaultProps.id = this.props.name

    const inputProps = {
      ...defaultProps,
      ...restProps,
    }

    const type = isRadio ? 'radio' : 'checkbox'
    const checkboxLabelFor = _.uniqueId(`input-${this.props.name}-`)
    return (
      <div className={className}>
        <input
          {...inputProps}
          className="CheckboxCustom-input"
          id={checkboxLabelFor}
          type={type}
          qa-id="learner-activity-checkbox"
        />
        <label
          {...labelProps}
          className="CheckboxCustom-label is-clearfix"
          htmlFor={checkboxLabelFor}
          onClick={
            adminPreventDefault
              ? (event) => {
                  event.preventDefault()
                }
              : null
          }
        >
          {imageUrl && (
            <div className="CheckboxCustom-visual">
              <div className="image is-square">
                <img
                  alt={text || ''}
                  src={imageUrl}
                  className="CheckboxCustom-image"
                />
              </div>
              <Button
                aria-label={formatMessage(CheckboxCustomMessages.enlargeImage)}
                className="CheckboxCustom-zoomImage"
                onClick={(e) => onOpenActivityModal(e, imageUrl)}
              >
                <i
                  aria-hidden="true"
                  className="fa fa-search-plus"
                  title={formatMessage(CheckboxCustomMessages.enlargeImage)}
                />
              </Button>
              <span className="CheckboxCustom-icon">
                <i aria-hidden="true" className={iconClassName} />
              </span>
            </div>
          )}
          {!imageUrl && (
            <span className="CheckboxCustom-icon">
              <i aria-hidden="true" className={iconClassName} />
            </span>
          )}
          {text ? (
            <span
              className="CheckboxCustom-text"
              qa-id="learner-activity-checkbox-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            <span
              className="CheckboxCustom-text"
              qa-id="learner-activity-checkbox-text"
            >
              {children}
            </span>
          )}
          {secondaryText && (
            <span className="CheckboxCustom-secondaryText">
              {secondaryText}
            </span>
          )}
        </label>
      </div>
    )
  }
}
