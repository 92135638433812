import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { defineMessages, injectIntl } from 'react-intl'

import './PerformanceMeter.scss'

const computePoints = (percentage) => {
  return Math.round(Math.sqrt(percentage) * 100)
}

const PerformanceMeterMessages = defineMessages({
  title: {
    defaultMessage: 'Performance Meter',
    description: 'Performance Meter title',
    id: 'PerformanceMeter.title',
  },
  emptyPerformanceCopy: {
    defaultMessage: 'Check back here after you complete your first Section.',
    description:
      'Copy under Performance Meter when learner has not completed a section yet',
    id: 'PerformanceMeter.emptyPerformanceCopy',
  },
})

@injectIntl
class PerformanceMeter extends PureComponent {
  state = {
    diff: 0,
    hasLoaded: false,
    isVisibleDiff: false,
  }
  componentDidUpdate(prevProps) {
    const { fasr, isLoadingLearner } = this.props
    const { hasLoaded } = this.state
    const { fasr: prevFasr, isLoadingLearner: wasLoadingLearner } = prevProps
    if (fasr !== prevFasr && prevFasr !== 0) {
      this.setState(
        {
          diff: computePoints(fasr) - computePoints(prevFasr),
          isVisibleDiff: true,
        },
        () => {
          this.removeDiff = setTimeout(() => {
            this.setState({ isVisibleDiff: false })
          }, 2000)
        },
      )
    }
    if (!hasLoaded && wasLoadingLearner && !isLoadingLearner) {
      this.setState({ hasLoaded: true })
    }
  }
  componentWillUnmount() {
    if (this.removeDiff) {
      clearTimeout(this.removeDiff)
    }
  }
  render() {
    const { classFasr, fasr, intl, isNotFoundLearner } = this.props
    const { diff, hasLoaded, isVisibleDiff } = this.state
    const { formatMessage } = intl
    const points = computePoints(fasr)
    let greatCutoff = 75
    let goodCutoff = 55
    let okCutoff = 25
    let okCutoffPoints
    let goodCutoffPoints
    let greatCutoffPoints

    if (hasLoaded && classFasr < 55 && !isNotFoundLearner) {
      goodCutoff = classFasr
      greatCutoff = classFasr + 20
    }
    goodCutoffPoints = computePoints(goodCutoff)
    greatCutoffPoints = computePoints(greatCutoff)
    okCutoffPoints =
      classFasr < 55
        ? Math.round(goodCutoffPoints / 2)
        : computePoints(okCutoff)
    return (
      <div className="PerformanceMeter">
        <span className="PerformanceMeter-title">
          {formatMessage(PerformanceMeterMessages.title)}
        </span>
        <div className="PerformanceMeter-main">
          <div
            className={cx('PerformanceMeter-meterWrapper', {
              'is-dim': !hasLoaded || isNotFoundLearner,
            })}
          >
            <div
              className="PerformanceMeter-bad"
              style={{ width: `${Math.round(okCutoffPoints / 10)}%` }}
            >
              <span className="PerformanceMeter-zero">0</span>
              <div className="PerformanceMeter-badMeter" />
            </div>
            <div
              className="PerformanceMeter-ok"
              style={{
                width: `${Math.round(
                  (goodCutoffPoints - okCutoffPoints) / 10,
                )}%`,
              }}
            >
              <span className="PerformanceMeter-okCutoff">
                {okCutoffPoints}
              </span>
              <div className="PerformanceMeter-okMeter" />
            </div>
            <div
              className="PerformanceMeter-good"
              style={{
                width: `${Math.round(
                  (greatCutoffPoints - goodCutoffPoints) / 10,
                )}%`,
              }}
            >
              <span className="PerformanceMeter-goodCutoff">
                {goodCutoffPoints}
              </span>
              <div className="PerformanceMeter-goodMeter" />
            </div>
            <div
              className="PerformanceMeter-great"
              style={{
                width: `${Math.round((1000 - greatCutoffPoints) / 10)}%`,
              }}
            >
              <span className="PerformanceMeter-greatCutoff">
                {greatCutoffPoints}
              </span>
              <div className="PerformanceMeter-greatMeter" />
              <span className="PerformanceMeter-perfect">1000</span>
            </div>
          </div>
          {!isNotFoundLearner && (
            <div
              className={cx('PerformanceMeter-pointsTooltip', {
                'is-visible': hasLoaded,
              })}
              style={{ left: `${points / 10}%` }}
            >
              <span
                className={cx('PerformanceMeter-points', {
                  'PerformanceMeter-points-is-ok':
                    points >= okCutoffPoints && points < goodCutoffPoints,
                  'PerformanceMeter-points-is-good':
                    points >= goodCutoffPoints && points < greatCutoffPoints,
                  'PerformanceMeter-points-is-great':
                    points >= greatCutoffPoints,
                })}
              >
                {points}
              </span>
              <i className="fa fa-caret-up" />
              <span
                className={cx('PerformanceMeter-pointsDiff', 'is-danger', {
                  'is-visible': isVisibleDiff && diff < 0,
                })}
              >
                {diff}
              </span>
              <span
                className={cx('PerformanceMeter-pointsDiff', 'is-success', {
                  'is-visible': isVisibleDiff && diff > 0,
                })}
              >
                +{diff}
              </span>
            </div>
          )}
          <span
            className={cx('PerformanceMeter-isNotFoundLearner', {
              'is-visible': isNotFoundLearner,
            })}
          >
            *{formatMessage(PerformanceMeterMessages.emptyPerformanceCopy)}
          </span>
        </div>
      </div>
    )
  }
}

PerformanceMeter.propTypes = {
  classFasr: PropTypes.number.isRequired,
  fasr: PropTypes.number.isRequired,
  isNotFoundLearner: PropTypes.bool.isRequired,
}

export default PerformanceMeter
