import React from 'react'
import cx from 'classnames'
import './Progress.scss'

const Progress = (props) => {
  const {
    className,
    hint,
    isAriaHidden,
    isDone,
    isLocked,
    isPretestMastered,
    isReattempt,
    isSimple,
    needsQuiz,
    value,
  } = props

  const classNames = cx(
    'Progress',
    {
      'is-finished': !needsQuiz && (isDone || isPretestMastered), // is mastered or is tested out
      'is-reattempt': isReattempt && !isPretestMastered,
      'is-locked': isLocked,
      'is-unlocked': !isLocked,
      'is-simple': isSimple,
    },
    className,
  )

  const iconClassName = cx(
    'fa Progress-icon',
    {
      // NOTE - locked should show checkmark if pretest mastered
      // https://headware4.jira.com/browse/FM-3884
      'fa-lock': isLocked && !isPretestMastered, // is locked and is not tested out
      // NOTE - this does't seem to be in use
      'fa-unlock-alt': !isDone && !isLocked && !isPretestMastered, // is not done and is not locked and is not tested out
      'fa-check': isDone || isPretestMastered, // is mastered or is tested out
      'fa-repeat': isReattempt && !isPretestMastered,
      'fa-question-circle': !isReattempt && needsQuiz,
      // NOTE - disabled
      // https://headware4.jira.com/browse/FM-3884
      // 'fa-trophy': !isLocked && isPretestMastered // is not locked and is tested out
    },
    className,
  )

  const hintProps = hint ? { 'data-rh': hint, 'data-rh-at': 'left' } : {}

  const additionalAttributes = {}
  if (isAriaHidden) {
    additionalAttributes['aria-hidden'] = 'true'
  }

  return (
    <div className={classNames} qa-id="learner-section-item-progress">
      {!isSimple && (isDone || isLocked || isPretestMastered) && (
        <i aria-hidden="true" className={iconClassName} {...hintProps} />
      )}
      {value && (
        <progress
          className="progress is-success"
          max="1"
          value={value / 100}
          {...additionalAttributes}
        />
      )}
    </div>
  )
}

export default Progress
