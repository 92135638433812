import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { Heading, Modal, Select } from '@/components'
import _ from 'lodash'
import { Input } from '@/components/form'
import './NewMessageModal.scss'

const NewMessageModalMessages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    description: 'Button to close the new message modal.',
    id: 'NewMessageModal.cancel',
  },
  close: {
    defaultMessage: 'Close',
    description: 'Button to close the new message modal for screen readers.',
    id: 'NewMessageModal.close',
  },
  labelMessage: {
    defaultMessage: 'Message',
    description: 'Label for the text input field in the new message modal.',
    id: 'NewMessageModal.labelMessage',
  },
  labelRecipients: {
    defaultMessage: 'Send to',
    description:
      'Label for the input field to search for and select recipients in the new message modal.',
    id: 'NewMessageModal.labelRecipients',
  },
  placeholderRecipients: {
    defaultMessage: 'Start typing a name..',
    description:
      'Placeholder for the input field to search for and select recipients in the new message modal.',
    id: 'NewMessageModal.placeholderRecipients',
  },
  send: {
    defaultMessage: 'Send',
    description: 'Button to send a message from the new message modal.',
    id: 'NewMessageModal.send',
  },
  sendMessage: {
    defaultMessage: 'Send message',
    description: 'Title displayed in the new message modal for mobile.',
    id: 'NewMessageModal.sendMessage',
  },
  sendNewMessage: {
    defaultMessage: 'Send new message',
    description: 'Title displayed in the new message modal.',
    id: 'NewMessageModal.sendNewMessage',
  },
})

const initialState = {
  defaultOptions: null,
  recipients: [],
  message: '',
}

// const stringToNumberArray = string => string.split(',').map(s => Number(s))

@injectIntl
@inject('uiStore')
export default class NewMessageModal extends Component {
  state = {
    ...initialState,
  }
  get readyToSave() {
    const { message, recipients } = this.state
    return recipients.length > 0 && message !== initialState.message
  }
  componentDidUpdate(prevProps) {
    const { recipient: prevRecipient } = prevProps
    const { recipient: currentRecipient } = this.props
    const { id: prevRecipientId } = prevRecipient || {}
    const { id: currentRecipientId } = currentRecipient || {}
    if (
      (!prevRecipient && currentRecipient) ||
      (prevRecipient &&
        currentRecipient &&
        prevRecipientId !== currentRecipientId)
    ) {
      this.setState({ recipients: [currentRecipient] })
    }
  }
  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }
  onSelectChange = (recipients) => {
    this.setState({ recipients })
  }
  onSubmit = () => {
    const { onSave, onSaveCallback } = this.props
    const { recipients, message } = this.state
    if (!this.readyToSave) return
    if (!onSaveCallback) {
      onSave(recipients, message)
      this.setState({ ...initialState })
    } else {
      onSave(recipients, message, (callback) =>
        this.setState({ ...initialState }, () => {
          if (callback) {
            callback()
          }
        }),
      )
    }
  }
  onClose = () => {
    const { onClose, uiStore } = this.props
    if (onClose) {
      onClose()
    }
    this.setState({ ...initialState })
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  loadRecipients = _.debounce((input, callback) => {
    const { loadRecipients } = this.props
    loadRecipients(input)
      .then((result) => {
        callback(
          result.map((user) => {
            let { fullName, isAdminLike, role } = user
            let recipient = { fullName, isAdminLike, ...user }
            if (isAdminLike || role === 'admin' || role === 'instructor') {
              recipient.fullName = `(Staff) ${fullName}`
            } else {
              recipient.fullName = `(Learner) ${fullName}`
            }
            return recipient
          }),
        )
      })
      .catch((error) => callback(error, null))
  }, 500)
  renderRecipients() {
    const { exactRecipient, intl, isLoading } = this.props
    const { recipients } = this.state
    const { formatMessage } = intl

    if (exactRecipient) {
      const recipientName =
        recipients && recipients[0] && recipients[0].fullName
      return (
        <Input
          id="recipients"
          name="recipients"
          value={recipientName || ''}
          disabled
        />
      )
    }

    return (
      <Select
        defaultValue={recipients.length ? recipients : []}
        getOptionLabel={(r) => r.fullName}
        getOptionValue={(r) => r.id}
        hideSelectedOptions
        id="recipients"
        isAsync
        isMulti
        isLoading={isLoading}
        loadOptions={this.loadRecipients}
        maxMenuHeight={200}
        name="recipients"
        onChange={this.onSelectChange}
        placeholder={formatMessage(
          NewMessageModalMessages.placeholderRecipients,
        )}
        value={recipients}
      />
    )
  }
  renderForm() {
    const { intl } = this.props
    const { message } = this.state
    const { formatMessage } = intl
    return (
      <div>
        <div className="field">
          <label className="label" htmlFor="recipients">
            {formatMessage(NewMessageModalMessages.labelRecipients)}
          </label>
          <div className="control">{this.renderRecipients()}</div>
        </div>

        <div className="field">
          <label className="label" htmlFor="new-message">
            {formatMessage(NewMessageModalMessages.labelMessage)}
          </label>
          <div className="control">
            <Input
              name="message"
              value={message}
              onChange={this.onChange}
              isMultiline
              id="new-message"
            />
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { error, intl, isOpen, isSaving } = this.props
    const { formatMessage } = intl
    return (
      <Modal
        className="NewMessageModal"
        contentLabel={formatMessage(NewMessageModalMessages.sendNewMessage)}
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading className="is-hidden-touch">
            {formatMessage(NewMessageModalMessages.sendNewMessage)}
          </Heading>
          <Heading className="is-hidden-desktop">
            {formatMessage(NewMessageModalMessages.sendMessage)}
          </Heading>
        </Modal.Header>
        <Modal.Body>{this.renderForm()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button isSecondary onClick={this.onClose}>
            {formatMessage(NewMessageModalMessages.cancel)}
          </Modal.Button>
          <Modal.Button
            disabled={!this.readyToSave}
            isLoading={isSaving}
            onClick={this.onSubmit}
          >
            {formatMessage(NewMessageModalMessages.send)}
          </Modal.Button>
          {error ? (
            <div className="NewMessageModal-footerError">
              <span>{`* ${error}`}</span>
            </div>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}
