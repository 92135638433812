import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Button } from '@/components'
import LoginLayout from '../LoginLayout'
import ForgotPasswordForm from './ForgotPasswordForm'
import redirectToHome from '../redirectToHome'
import './ForgotPasswordPage.scss'

@inject('sessionStore', 'uiStore')
@observer
@redirectToHome()
export default class ForgotPasswordPage extends Component {
  state = {
    isSubmitted: false,
    isLoading: false,
    error: null,
  }
  componentDidMount() {
    const { uiStore } = this.props
    uiStore.updateTitle('Forgot Password Page')
  }
  // clear api error on form validation
  onFormValidate = () => {
    this.setState({ error: null })
  }
  onSubmit = (email) => {
    const { sessionStore } = this.props
    this.setState({ isLoading: true })
    sessionStore
      .resetPassword(email)
      .then(() => {
        this.setState({
          isSubmitted: true,
          isLoading: false,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }
  renderForm() {
    return (
      <ForgotPasswordForm
        onValidate={this.onFormValidate}
        onSubmit={this.onSubmit}
        isLoading={this.state.isLoading}
        error={this.state.error}
      />
    )
  }
  renderSuccessMessage() {
    return (
      <div className="ForgotPasswordPage__content ForgotPasswordPage__content--is-success">
        <p>
          If an account is found, an email will be sent with instructions on how
          to reset your password. Contact your administrator or instructor if
          there are persistent issues with receiving the email.
        </p>
        <Button isSuccess isFullWidth to="/login">
          Go Back to Login
        </Button>
      </div>
    )
  }
  render() {
    return (
      <LoginLayout className="ForgotPasswordPage" isForgotPassword>
        <div className="title is-spaced">Forgot Password?</div>
        {!this.state.isSubmitted && (
          <p className="ForgotPasswordPage__subtitle">
            Enter your email address in the field below to receive an email with
            instructions for resetting your password.
          </p>
        )}
        {this.state.isSubmitted
          ? this.renderSuccessMessage()
          : this.renderForm()}
      </LoginLayout>
    )
  }
}
