import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import LoginLayout from '../LoginLayout'
import ResetPasswordForm from './ResetPasswordForm'
import './ResetPasswordPage.scss'
import { Button, Link, Loader } from '@/components'

@inject('sessionStore', 'uiStore')
@observer
export default class ResetPasswordPage extends Component {
  state = {
    isLoading: false,
    isResendingError: false,
    isResendingSuccess: false,
    isResendingToken: false,
    isTokenExpired: false,
    isValidatingToken: false,
    error: null,
    errorResend: null,
    tokenEmail: null,
  }
  componentDidMount() {
    const { match, sessionStore, uiStore } = this.props
    const { session } = sessionStore
    const { params } = match
    const { token } = params
    if (session) {
      sessionStore.logout()
    }
    this.setState({ isValidatingToken: true })
    sessionStore
      .validatePasswordToken(token)
      .then(({ email, tokenExpired }) => {
        this.setState({
          isTokenExpired: tokenExpired,
          isValidatingToken: false,
          tokenEmail: email,
        })
      })
      .catch((error) => {
        const { message } = error
        this.setState({
          isValidatingTokenError: message,
          isValidatingToken: false,
        })
      })

    uiStore.updateTitle('Reset Password Page')
  }
  resetPassword = (password) => {
    const { history, match, sessionStore } = this.props
    const { params } = match
    const { token: paramsToken } = params
    this.setState({ isLoading: true })
    sessionStore
      .confirmResetPassword(password, paramsToken)
      .then(({ logoutUrl, token, user }) => {
        sessionStore.loginWithDelay(
          {
            logoutUrl,
            token,
            user,
          },
          () => {
            this.setState({ isLoading: false })
            history.push('/')
          },
        )
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }
  onClickResend = () => {
    const { sessionStore } = this.props
    const { tokenEmail } = this.state
    this.setState({ isResendingToken: true })
    sessionStore
      .resetPassword(tokenEmail)
      .then(() => {
        this.setState({
          isResendingToken: false,
          isResendingError: false,
          isResendingSuccess: true,
        })
      })
      .catch(() => {
        this.setState({
          isResendingToken: false,
          isResendingError: true,
          isResendingSuccess: false,
        })
      })
  }
  // clear api error on form validation
  onFormValidate = () => {
    this.setState({ error: null })
  }
  renderForm() {
    return (
      <ResetPasswordForm
        onValidate={this.onFormValidate}
        onSubmit={this.resetPassword}
        isLoading={this.state.isLoading}
        error={this.state.error}
      />
    )
  }
  renderSuccessMessage() {
    const { sessionStore } = this.props
    const { loginWithDelayTimer } = sessionStore
    return (
      <div className="LoginLayout-success">
        <p>You have successfully changed your password.</p>
        <p>
          You will automatically be logged in to the platform in{' '}
          {loginWithDelayTimer} seconds...
        </p>
      </div>
    )
  }
  renderBody() {
    const { location, sessionStore } = this.props
    const {
      isResendingError,
      isResendingSuccess,
      isTokenExpired,
      isValidatingTokenError,
      tokenEmail,
    } = this.state
    const { pathname } = location
    const isReset = pathname.match('reset-password')
    const { loginWithDelayIntervalId } = sessionStore
    if (isValidatingTokenError) {
      return (
        <Fragment>
          <p className="subtitle">
            The link you clicked is no longer valid or you copy and pasted it
            wrong. Make sure you get the whole thing from the original email you
            received. Go to the{' '}
            <Link to="/forgot-password">Forgot Password Page</Link> and enter
            your email to receive a new link. If this keeps happening, contact
            your course administrator.
          </p>
          <Button to="/forgot-password" isInfo isLink>
            Go to Forgot Password Page
          </Button>
        </Fragment>
      )
    }
    if (isResendingError) {
      return (
        <Fragment>
          <p className="subtitle">
            A new {isReset ? 'reset' : 'set-up'} password link could not be sent
            to your email:
            <br />
            <span className="is-bold">{tokenEmail}</span>
          </p>
          <p className="subtitle">
            Try again or contact your course administrator.
          </p>
          <Button isInfo onClick={this.onClickResend}>
            Send a New Link
          </Button>
        </Fragment>
      )
    }
    if (isResendingSuccess) {
      return (
        <Fragment>
          <p className="subtitle">
            We just sent out a password {isReset ? 'reset' : 'set-up'} link to:
            <br />
            <span className="is-bold">{tokenEmail}</span>
          </p>
          <p className="subtitle">
            This new link will help you{' '}
            {isReset ? 'reset your' : 'get set-up with a brand new'} password
            for your Fulcrum account!
          </p>
          <Button to="/login" isInfo isLink>
            Go to Login Page
          </Button>
        </Fragment>
      )
    }
    if (isTokenExpired) {
      return (
        <Fragment>
          <p className="subtitle">
            The link you clicked is no longer valid. Our links are only valid
            for 4 days. If the link has been sitting in your inbox for longer
            than that, you need to get a new one!
          </p>
          <Button isInfo onClick={this.onClickResend}>
            Send a New Link
          </Button>
        </Fragment>
      )
    }
    if (loginWithDelayIntervalId) return this.renderSuccessMessage()
    return (
      <Fragment>
        <p className="subtitle">
          Please enter your new password. It must be between 5 and 100
          characters and contain both letters and numbers.
        </p>
        {this.renderForm()}
      </Fragment>
    )
  }
  render() {
    const { location } = this.props
    const { isResendingToken, isValidatingToken } = this.state
    const { pathname } = location
    const isReset = pathname.match('reset-password')
    return (
      <LoginLayout className="ResetPasswordPage" isNewPassword>
        <div className="title is-spaced">
          {isReset ? 'Password Reset' : 'Password Set-up'}
        </div>
        {isResendingToken || isValidatingToken ? (
          <div className="ResetPasswordPage-isValidating">
            <Loader />
          </div>
        ) : (
          this.renderBody()
        )}
      </LoginLayout>
    )
  }
}
