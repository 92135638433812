import { extendObservable, action } from 'mobx'

import { handleError } from '@/api'
import SentryReplay from '@/SentryReplay'
import request from '@/utils/request'

import UserProfile from './models/userProfile'

const initialState = {
  session: null,
  userProfile: null,
  isLoadingUserProfile: false,
  isUpdatingUserProfile: false,
}

export default class AccountStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...initialState })
  }

  @action init(session) {
    this.session = session
  }

  @action loadUserProfile() {
    this.isLoadingUserProfile = true
    return request
      .get(`/users/?d=${Date.now()}`)
      .then((response) => this.handleLoadUserProfile(response))
      .catch((error) => this.handleLoadUserProfileError(error))
  }
  @action handleLoadUserProfile(response) {
    this.userProfile = new UserProfile(response.data.user_profile)
    this.isLoadingUserProfile = false
    return this.userProfile
  }
  @action handleLoadUserProfileError(error) {
    SentryReplay.captureException(error)
    this.isLoadingUserProfile = false
    handleError(error)
  }

  @action updateUserProfile(data) {
    this.isUpdatingUserProfile = true
    const userProfile = new UserProfile({ ...data, user: this.session.user })
    return request
      .put(`/users/`, userProfile.toSnakeCase())
      .then((response) => this.handleUpdateUserProfile(response))
      .catch((error) => this.handleUpdateUserProfile(error))
  }
  @action handleUpdateUserProfile() {
    this.isUpdatingUserProfile = false
  }
  @action handleUpdateUserProfileError(error) {
    SentryReplay.captureException(error)
    this.isUpdatingUserProfile = false
    handleError(error)
  }
}
