import React, { Component } from 'react'
import InstanceContentPageSection from './InstanceContentPageSection'

export default class InstanceContentPageSections extends Component {
  render() {
    const { onClickOpenActivityModal, sections } = this.props
    return (
      <div className="InstanceContentPage__sections">
        {sections.map((section, index) => {
          const { activitiesByLo: activities, id: sectionId } = section
          return Object.keys(activities).length ? (
            <InstanceContentPageSection
              key={`InstanceContentPage__section-${sectionId}-${index}`}
              onClickOpenActivityModal={onClickOpenActivityModal}
              section={section}
            />
          ) : null
        })}
      </div>
    )
  }
}
