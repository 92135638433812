import React, { Fragment } from 'react'
import { Heading, Link, Loader } from '@/components'
import {
  RANK_ALL_STAR,
  RANK_PROFICIENT,
  REATTEMPT_STATUS_FINISHED,
  REATTEMPT_STATUS_STARTED,
} from '@/constants'
import { defineMessages, FormattedMessage } from 'react-intl'

const PathwayMessages = defineMessages({
  pathwayCourseMastered: {
    defaultMessage:
      'You completed all {numSections} Sections! Feel free to review or reference this material at any time.',
    description:
      'Description for when all sections in a course have been completed and any at risk sections have been reattempted on the dashboard page.',
    id: 'Pathway.pathwayCourseMastered',
  },
  pathwayEmpty: {
    defaultMessage: 'No knowledge gaps so far. Keep it up!',
    description:
      'Description for when there are no completed sections that are at risk and require a reattempt on the dashboard page.',
    id: 'Pathway.pathwayEmpty',
  },
  pathwayEmptyGenerating: {
    defaultMessage: 'Your progress report is currently being generated.',
    description:
      'Description for when a progress report is being generated on the dashboard page.',
    id: 'Pathway.pathwayEmptyGenerating',
  },
  pathwayHasNoSectionsAtRisk: {
    defaultMessage:
      'As you progress through your course, some Sections may be identified here. {isRequireMastery, select, true {You will be {pathwayRequired} to reattempt those Sections} false {We {pathwayRecommend} reattempting}} to improve your mastery of the specific subject matter.',
    description:
      'Description for when a course has not been mastered and there may be incomplete sections that require reattempts once they are completed on the dashboard page.',
    id: 'Pathway.pathwayHasNoSectionsAtRisk',
  },
  pathwayRequired: {
    defaultMessage: 'REQUIRED',
    description: 'Value for Require Mastery in Pathway Description.',
    id: 'Pathway.pathwayRequired',
  },
  pathwayRecommend: {
    defaultMessage: 'RECOMMEND',
    description: 'Value for Non-Require Mastery in Pathway Description.',
    id: 'Pathway.pathwayRecommend',
  },
  pathwayHasSectionsAtRisk: {
    defaultMessage:
      'You have not achieved 100% mastery of this course just yet! {isRequireMastery, select, true {You are {pathwayRequired} to} false {We {pathwayRecommend} you}} reattempt the following {numSectionsAtRiskRemaining} {numSectionsAtRiskRemaining, plural, one {Section} other {Sections}} to demonstrate higher levels of mastery before you complete this course. Check out the Tips below for more insights on how to improve your learning.',
    description:
      'Description for when there are completed sections that are at risk and need to be reattempted to reach course mastery onn the dashboard page.',
    id: 'Pathway.pathwayHasSectionsAtRisk',
  },
  pathwayHasSectionsAtRiskCourseMastered: {
    defaultMessage: `You've covered all of the material! Nice work.`,
    description:
      'Description for when all at risk sectiosn have been reattempted and course has been mastered on the dashboard page.',
    id: 'Pathway.pathwayHasSectionsAtRiskCourseMastered',
  },
  pathwayTitle: {
    defaultMessage:
      'Pathway to Mastery ({isRequireMastery, select, true {Required} false {Recommended}})',
    description: 'Title for Pathway to Mastery on the Dashboard page.',
    id: 'Pathway.pathwayTitle',
  },
  reattemptLabelReady: {
    defaultMessage: 'Begin Reattempt',
    description:
      'Button to start a reattempt in a section that is at risk from the dashboard page.',
    id: 'Pathway.reattemptLabelReady',
  },
  reattemptLabelStarted: {
    defaultMessage: 'Continue Reattempt',
    description:
      'Button to continue a reattempt in a section that is at risk from the dashboard page.',
    id: 'Pathway.reattemptLabelStarted',
  },
  reattemptLabelFinished: {
    defaultMessage: 'Done',
    description:
      'Label for a completed reattempt in a section that is at risk from the dashboard page.',
    id: 'Pathway.reattemptLabelFinished',
  },
  reattemptLabelFinishedAtRisk: {
    defaultMessage: 'Try Again',
    description:
      'Label for a completed reattempt that remains as at risk in a section that is at risk from the dashboard page.',
    id: 'Pathway.reattemptLabelFinishedAtRisk',
  },
})

const PathwayReattemptLabel = ({ intl, sectionAtRisk }) => {
  const { formatMessage } = intl
  const { reattemptRank, reattemptStatus } = sectionAtRisk
  if (reattemptStatus === REATTEMPT_STATUS_STARTED) {
    return formatMessage(PathwayMessages.reattemptLabelStarted)
  } else if (
    reattemptStatus === REATTEMPT_STATUS_FINISHED &&
    reattemptRank !== RANK_PROFICIENT &&
    reattemptRank !== RANK_ALL_STAR
  ) {
    return formatMessage(PathwayMessages.reattemptLabelFinishedAtRisk)
  } else if (reattemptStatus === REATTEMPT_STATUS_FINISHED) {
    return formatMessage(PathwayMessages.reattemptLabelFinished)
  }
  return formatMessage(PathwayMessages.reattemptLabelReady)
}

const PathwayDescription = ({
  intl,
  sectionsAtRisk,
  isMasteredWithPretestCourse,
  isRequireMastery,
  numSections,
}) => {
  const { formatMessage } = intl
  const numSectionsAtRiskRemaining = sectionsAtRisk.length
  const hasSectionsAtRisk = !!numSectionsAtRiskRemaining
  if (hasSectionsAtRisk) {
    return (
      <p>
        {isMasteredWithPretestCourse ? (
          formatMessage(PathwayMessages.pathwayHasSectionsAtRiskCourseMastered)
        ) : (
          <FormattedMessage
            {...PathwayMessages.pathwayHasSectionsAtRisk}
            values={{
              pathwayRequired: (
                <span className="is-bold">
                  {formatMessage(PathwayMessages.pathwayRequired)}
                </span>
              ),
              pathwayRecommend: (
                <span className="is-bold">
                  {formatMessage(PathwayMessages.pathwayRecommend)}
                </span>
              ),
              isRequireMastery,
              numSectionsAtRiskRemaining,
            }}
          />
        )}
      </p>
    )
  } else if (isMasteredWithPretestCourse) {
    return (
      <p>
        {formatMessage(PathwayMessages.pathwayCourseMastered, { numSections })}
      </p>
    )
  } else {
    return (
      <p>
        <FormattedMessage
          {...PathwayMessages.pathwayHasNoSectionsAtRisk}
          values={{
            pathwayRequired: (
              <span className="is-bold">
                {formatMessage(PathwayMessages.pathwayRequired)}
              </span>
            ),
            pathwayRecommend: (
              <span className="is-bold">
                {formatMessage(PathwayMessages.pathwayRecommend)}
              </span>
            ),
            isRequireMastery,
          }}
        />
      </p>
    )
  }
}

const Pathway = ({
  courseSections,
  intl,
  isLoadingLearner,
  isMasteredWithPretestAndReattemptsCourse,
  isMasteredWithPretestCourse,
  isNotFoundLearner,
  isRequireMastery,
  numSections,
  onClickPathway,
  pretestMasteredCourse,
  sectionsAtRisk,
}) => {
  const { formatMessage } = intl
  if (isMasteredWithPretestAndReattemptsCourse) return null
  return (
    <div className="DashboardPage__pathway">
      <Heading is3>
        {formatMessage(PathwayMessages.pathwayTitle, { isRequireMastery })}
      </Heading>
      {isLoadingLearner ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="DashboardPage__pathway-description">
            <PathwayDescription
              intl={intl}
              sectionsAtRisk={sectionsAtRisk}
              isMasteredWithPretestCourse={isMasteredWithPretestCourse}
              isRequireMastery={isRequireMastery}
              numSections={numSections}
            />
          </div>
          {!isNotFoundLearner &&
          sectionsAtRisk.length &&
          !pretestMasteredCourse ? (
            <div className="DashboardPage__pathway-table">
              {sectionsAtRisk.map((sectionAtRisk) => {
                const {
                  contentfulId: sectionContentfulId,
                  id: sectionId,
                  unitContentfulId: sectionUnitContentfulId,
                } = sectionAtRisk || {}
                const section = courseSections[sectionContentfulId]
                const { title: sectionTitle } = section || {}
                return section ? (
                  <Link
                    className="DashboardPage__pathway-table-row"
                    key={sectionId}
                    onClick={() =>
                      onClickPathway(
                        sectionUnitContentfulId,
                        sectionContentfulId,
                      )
                    }
                    to={`/units/${sectionUnitContentfulId}/sections/${sectionContentfulId}/practice`}
                  >
                    <div className="DashboardPage__pathway-table-column">
                      <Heading
                        is5
                        className="DashboardPage__pathway-section-title"
                      >
                        {sectionTitle}
                      </Heading>
                    </div>
                    <div className="DashboardPage__pathway-table-column">
                      <div className="DashboardPage__pathway-view">
                        <span>
                          <PathwayReattemptLabel
                            intl={intl}
                            sectionAtRisk={sectionAtRisk}
                          />
                        </span>
                        <i aria-hidden="true" className="fa fa-angle-right" />
                      </div>
                    </div>
                  </Link>
                ) : null
              })}
            </div>
          ) : (
            <div className="DashboardPage__pathway-empty">
              {isNotFoundLearner && !pretestMasteredCourse ? (
                <p>{formatMessage(PathwayMessages.pathwayEmptyGenerating)}</p>
              ) : (
                <p>{formatMessage(PathwayMessages.pathwayEmpty)}</p>
              )}
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default Pathway
