import React, { Component } from 'react'
import './BookAndTrophyIcon.scss'
// Note: Converted HTML to JSX
// Removed xmlns:xlink and changed xlink:href to xlinkHref
// https://github.com/reactjs/react-magic/issues/62
class BookAndTrophyIcon extends Component {
  render() {
    return (
      <div className="BookAndTrophyIcon">
        <svg
          aria-hidden="true"
          focusable="false"
          width="240px"
          height="135px"
          viewBox="0 0 240 135"
          version="1.1"
        >
          <defs>
            <polygon
              id="path-1"
              points="0 1.16575239e-15 21 1.16575239e-15 21 21 0 21"
            />
            <polygon
              id="path-3"
              points="0 1.16575239e-15 21 1.16575239e-15 21 21 0 21"
            />
            <polygon
              id="path-5"
              points="0 6.66144223e-16 12 6.66144223e-16 12 12 0 12"
            />
          </defs>
          <g
            id="Artboard"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g id="Trophy-&-Book">
              <g id="Group-5">
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x={0}
                  y={0}
                  width={240}
                  height={135}
                />
                <g
                  id="trophy"
                  transform="translate(112.000000, 16.000000)"
                  fillRule="nonzero"
                >
                  <path
                    d="M30,83 L30,78.5 C30,76.0143581 31.9048001,74 34.2552512,74 L45.7447488,74 C48.0952001,74 50,76.0143581 50,78.5 L50,83 L30,83 Z"
                    id="Path"
                    fill="#FFD664"
                  />
                  <path
                    d="M30,83 L30,79.625 L44.893379,79.625 C45.4811915,79.625 45.957192,79.1207771 45.957192,78.5 C45.957192,76.0143581 44.052392,74 41.7019409,74 L45.7447488,74 C48.0944013,74 50,76.0143581 50,78.5 L50,83 L30,83 Z"
                    id="Path"
                    fill="#F9C646"
                  />
                  <path
                    d="M50,55 L50,59.5 C50,61.9856419 48.0952001,64 45.7447488,64 L34.2552512,64 C31.9048001,64 30,61.9856419 30,59.5 L30,55 L50,55 Z"
                    id="Path"
                    fill="#FFD664"
                  />
                  <path
                    d="M30,55 L30,58.375 L44.893379,58.375 C45.4811915,58.375 45.957192,58.8792229 45.957192,59.5 C45.957192,61.9856419 44.052392,64 41.7019409,64 L45.7447488,64 C48.0944013,64 50,61.9856419 50,59.5 L50,55 L30,55 Z"
                    id="Path"
                    fill="#F9C646"
                  />
                  <polygon
                    id="Path"
                    fill="#A05F33"
                    points="46 64 46 74 33 74 33 64"
                  />
                  <polygon
                    id="Path"
                    fill="#87502A"
                    points="42 64 47 64 47 74 42 74"
                  />
                  <path
                    d="M68.8495451,105 L12.1502511,105 C10.7985963,105 9.78263605,103.774927 10.0400557,102.456851 L13.1670182,86.4579446 C13.5591998,84.4503644 15.3288587,83 17.3874088,83 L63.6123872,83 C65.6709373,83 67.4405963,84.4495627 67.8327778,86.4579446 L70.9597403,102.456851 C71.2179669,103.774927 70.2011997,105 68.8495451,105 L68.8495451,105 Z"
                    id="Path"
                    fill="#A05F33"
                  />
                  <path
                    d="M70.9599696,102.456759 L67.8330354,86.4572688 C67.4416644,84.4568315 65.6841255,83.0120267 63.6360845,83 L66.6871654,98.6122307 C66.9445827,99.9303546 65.9286317,101.155472 64.5769891,101.155472 L10.2950269,101.155472 L10.0400305,102.456759 C9.78261315,103.774882 10.7993711,105 12.1510136,105 L68.8489864,105 C70.2006288,105 71.2173867,103.774882 70.9599696,102.456759 Z"
                    id="Path"
                    fill="#87502A"
                  />
                  <g id="Group" transform="translate(0.000000, 10.000000)">
                    <path
                      d="M18.1455955,86.4558985 L18.962259,81.512888 C19.1333387,80.4755655 20.0307003,79.7141096 21.0830019,79.7141096 L60.331271,79.7141096 C61.3835726,79.7141096 62.2809342,80.4755655 62.4520139,81.512888 L63.2686774,86.4558985 C63.4841411,87.7642474 62.4746093,88.9548289 61.1471276,88.9548289 L20.2671453,88.9548289 C18.9396636,88.9548289 17.9301318,87.765054 18.1455955,86.4558985 Z"
                      id="Path"
                      fill="#FFD664"
                    />
                    <path
                      d="M67.795027,0.204883369 L67.795027,28.5707294 C67.795027,38.0647297 60.094826,45.7615647 50.5966737,45.7615647 L30.8175992,45.7615647 C21.31864,45.7615647 13.6192459,38.0647297 13.6192459,28.5707294 L13.6192459,0.204883369 L67.795027,0.204883369 Z"
                      id="Path"
                      fill="#FFD664"
                    />
                    <path
                      d="M16.76904,0.204883369 L16.76904,6.65144659 L8.59917665,6.65144659 C7.41210932,6.65144659 6.44938249,7.61375259 6.44938249,8.800301 L6.44938249,10.795166 C6.44938249,15.4892578 10.126709,25.1328851 13.126709,25.1328851 L16.76904,25.1328851 L16.76904,31.5794483 L10.9658203,31.5794483 C5.83276367,31.5794483 0,18.1140137 0,10.795166 L0,8.800301 C0,4.05330077 3.85010063,0.204883369 8.59917665,0.204883369"
                      id="Path"
                      fill="#FFD664"
                    />
                    <path
                      d="M81.76904,0.204883369 L81.76904,6.65144659 L73.5991766,6.65144659 C72.4121093,6.65144659 71.4493825,7.61375259 71.4493825,8.800301 L71.4493825,10.795166 C71.4493825,15.4892578 75.126709,25.1328851 78.126709,25.1328851 L81.76904,25.1328851 L81.76904,31.5794483 L75.9658203,31.5794483 C70.8327637,31.5794483 65,18.1140137 65,10.795166 L65,8.800301 C65,4.05330077 68.8501006,0.204883369 73.5991766,0.204883369"
                      id="Path"
                      fill="#F9C646"
                      transform="translate(73.384520, 15.892166) scale(-1, 1) translate(-73.384520, -15.892166) "
                    />
                  </g>
                  <path
                    d="M13,11 L13,15.2451928 L58,15.2451928 C60.3672358,15.2451928 62.2856377,17.1454902 62.2856377,19.4903857 L62.2856377,39.0192287 C62.2856377,48.3972167 54.6104211,56 45.143087,56 L49.8574492,56 C59.3247834,56 67,48.3972167 67,39.0192287 L67,11 L13,11 Z"
                    id="Path"
                    fill="#F9C646"
                  />
                  <path
                    d="M7,4.08150754 L7,5.91849246 C7,8.17297367 8.92250771,10 11.2948098,10 L69.7051902,10 C72.0774923,10 74,8.17297367 74,5.91849246 L74,4.08150754 C74,1.82702613 72.0774923,0 69.7051902,0 L11.2948098,0 C8.92250771,0 7,1.82702613 7,4.08150754 Z"
                    id="Path"
                    fill="#FFD664"
                  />
                  <path
                    d="M69.7447488,0 L64,0 C66.3504513,0 68.2552512,1.82702613 68.2552512,4.08150754 L68.2552512,5.91849246 C68.2552512,8.17297367 66.3504513,10 64,10 L69.7447488,10 C72.0952001,10 74,8.17297367 74,5.91849246 L74,4.08150754 C74,1.82702613 72.0952001,0 69.7447488,0 L69.7447488,0 Z"
                    id="Path"
                    fill="#F9C646"
                  />
                </g>
                <path
                  d="M191.500164,96 C191.077861,96 190.70248,95.7306052 190.567618,95.3306145 C188.678901,89.7346834 187.265317,88.3210988 181.669714,86.4327099 C181.269395,86.2975203 181,85.9221394 181,85.4998359 C181,85.0775325 181.269395,84.7021516 181.669385,84.5672901 C187.264989,82.6789012 188.678573,81.2649885 190.56729,75.6693855 C190.70248,75.2693948 191.077532,75 191.499836,75 C191.922139,75 192.29752,75.2693948 192.432382,75.6693855 C194.321099,81.2649885 195.734683,82.6789012 201.330286,84.5672901 C201.730277,84.7024797 202,85.0775325 202,85.4998359 C202,85.9221394 201.730605,86.2975203 201.330286,86.4327099 C195.734683,88.3210988 194.321099,89.7346834 192.432382,95.3306145 C192.297848,95.7306052 191.922468,96 191.500164,96"
                  id="Fill-1"
                  fill="#FFD00D"
                />
                <path
                  d="M89.5001641,35 C89.0778606,35 88.7024797,34.7306052 88.5676182,34.3306145 C86.6789012,28.7346834 85.2653166,27.3210988 79.6697136,25.4327099 C79.2693948,25.2975203 79,24.9221394 79,24.4998359 C79,24.0775325 79.2693948,23.7021516 79.6693855,23.5672901 C85.2649885,21.6789012 86.6785731,20.2649885 88.5672901,14.6693855 C88.7024797,14.2693948 89.0775325,14 89.4998359,14 C89.9221394,14 90.2975203,14.2693948 90.4323818,14.6693855 C92.3210988,20.2649885 93.7346834,21.6789012 99.3302864,23.5672901 C99.730277,23.7024797 100,24.0775325 100,24.4998359 C100,24.9221394 99.7306052,25.2975203 99.3302864,25.4327099 C93.7346834,27.3210988 92.3210988,28.7346834 90.4323818,34.3306145 C90.2978484,34.7306052 89.9224675,35 89.5001641,35"
                  id="Fill-1"
                  fill="#F9C646"
                />
                <path
                  d="M67.0000938,46 C66.7587775,46 66.5442741,45.8460601 66.4672104,45.617494 C65.3879436,42.4198191 64.5801809,41.6120564 61.3826935,40.5329771 C61.1539399,40.4557259 61,40.2412225 61,39.9999062 C61,39.75859 61.1539399,39.5440866 61.382506,39.4670229 C64.5799934,38.3879436 65.3877561,37.5799934 66.4670229,34.382506 C66.5442741,34.1539399 66.75859,34 66.9999062,34 C67.2412225,34 67.4557259,34.1539399 67.5327896,34.382506 C68.6120564,37.5799934 69.4198191,38.3879436 72.6173065,39.4670229 C72.8458726,39.5442741 73,39.75859 73,39.9999062 C73,40.2412225 72.8460601,40.4557259 72.6173065,40.5329771 C69.4198191,41.6120564 68.6120564,42.4198191 67.5327896,45.617494 C67.4559134,45.8460601 67.24141,46 67.0000938,46"
                  id="Fill-1"
                  fill="#FFD00D"
                />
                <g
                  id="studying"
                  transform="translate(37.000000, 55.000000)"
                  fillRule="nonzero"
                >
                  <path
                    d="M10.9364813,60 C8.2147623,60 6,57.7573442 6,55.0003896 C6,52.2426556 8.2147623,50 10.9364813,50 L64,50 L64,60 L10.9364813,60 Z"
                    id="Path"
                    fill="#DDF5FF"
                  />
                  <path
                    d="M59.7498468,50 L59.7498468,54.5831385 C59.7498468,55.733988 58.8404419,56.6666667 57.7190709,56.6666667 L6,56.6666667 C6.67008761,58.6060465 8.4767414,60 10.5948871,60 L63,60 L63,50.0007791 L59.7498468,50.0007791 L59.7498468,50 Z"
                    id="Path"
                    fill="#C1E9F4"
                  />
                  <path
                    d="M11.0600933,59.6109784 C8.37799961,59.6109784 6.19080233,57.3655411 6.14483671,54.5880175 C6.0988711,51.7292468 8.42166689,49.3890218 11.1719428,49.3890218 L65.9514658,49.3890218 C67.082986,49.3890218 68,48.4355671 68,47.259879 L68,45.1299395 C68,43.9534547 67.082986,43 65.9514658,43 L11.0600933,43 C4.95202903,43 0,48.1488138 0,54.4996019 C0,60.8511862 4.95202903,66 11.0600933,66 L65.9514658,66 C67.082986,66 68,65.045749 68,63.8700607 L68,61.7401212 C68,60.5644329 67.082986,59.6109784 65.9514658,59.6109784 L11.0600933,59.6109784 Z"
                    id="Path"
                    fill="#FF6969"
                  />
                  <path
                    d="M63,44.0007482 L63,50 L65.3681104,50 C66.8218505,50 68,49.1046003 68,48.0004988 L68,46.0002494 C68,44.8953997 66.8218505,44 65.3681104,44 L63,44 L63,44.0007482 Z"
                    id="Path"
                    fill="#EA4747"
                  />
                  <path
                    d="M63,66 L65.3681104,66 C66.8218505,66 68,65.1046005 68,63.9997507 L68,62.0002493 C68,60.8953995 66.8218505,60 65.3681104,60 L63,60 L63,66 Z"
                    id="Path"
                    fill="#EA4747"
                  />
                  <path
                    d="M11,57.1267395 L11,62.870448 C11,64.3885247 12.5446442,65.41808 13.9460448,64.8340818 L16.1058412,63.9342155 L18.0237148,64.7672483 C19.4290945,65.378298 21,64.3487429 21,62.8163446 L21,57.1267395 C21,55.9523783 20.0474296,55 18.8720357,55 L13.1271685,55 C11.9525704,55 11,55.9523783 11,57.1267395 L11,57.1267395 Z"
                    id="Path"
                    fill="#FFD664"
                  />
                  <path
                    d="M10.9364813,16 C8.2147623,16 6,13.7567399 6,11 C6,8.24326012 8.2147623,6 10.9364813,6 L64,6 L64,15.9992209 L10.9364813,15.9992209 L10.9364813,16 Z"
                    id="Path"
                    fill="#DDF5FF"
                  />
                  <path
                    d="M59.7498468,6 L59.7498468,10.5831386 C59.7498468,11.7339878 58.8404419,12.6666668 57.7190709,12.6666668 L6,12.6666668 C6.67008761,14.6060465 8.4767414,16 10.5948871,16 L63,16 L63,6.00077933 L59.7498468,6.00077933 L59.7498468,6 Z"
                    id="Path"
                    fill="#C1E9F4"
                  />
                  <path
                    d="M11.0600933,16.6115536 C8.37799961,16.6115536 6.19080233,14.3660386 6.14483671,11.587622 C6.0988711,8.72875245 8.42166689,6.38924289 11.1719428,6.38924289 L65.9514658,6.38924289 C67.082986,6.38924289 68,5.43495866 68,4.25922962 L68,2.12921655 C68,0.95348752 67.082986,0 65.9514658,0 L11.0600933,0 C4.95202903,0 0,5.14819548 0,11.5 C0,17.8510078 4.95202903,23 11.0600933,23 L65.9514658,23 C67.082986,23 68,22.0465125 68,20.8699869 L68,18.7399737 C68,17.5642446 67.082986,16.6107571 65.9514658,16.6107571 L11.0600933,16.6107571 L11.0600933,16.6115536 Z"
                    id="Path"
                    fill="#FF933B"
                  />
                  <path
                    d="M63,0 L63,6 L65.3681104,6 C66.8218505,6 68,5.10385228 68,3.99975054 L68,1.99950128 C68,0.895399535 66.8218505,0 65.3681104,0 L63,0 Z"
                    id="Path"
                    fill="#F27D27"
                  />
                  <path
                    d="M63,22 L65.3681104,22 C66.8218505,22 68,21.1046005 68,19.9997507 L68,18.0002493 C68,16.8953995 66.8218505,16 65.3681104,16 L63,16 L63,22 Z"
                    id="Path"
                    fill="#F27D27"
                  />
                  <path
                    d="M11,13.1275351 L11,18.8704481 C11,20.3885248 12.5446442,21.4180799 13.9460448,20.8340819 L16.1058412,19.9342156 L18.0237148,20.7680439 C19.4290945,21.3782981 21,20.3487428 21,18.8171403 L21,13.1275351 C21,11.9523783 20.0474296,11 18.8720357,11 L13.1271685,11 C11.9525704,11 11,11.9523783 11,13.1275351 L11,13.1275351 Z"
                    id="Path"
                    fill="#FFD664"
                  />
                  <path
                    d="M58.0635189,38 C60.7852377,38 63,35.7565651 63,32.9996103 C63,30.2426557 60.7852377,28 58.0635189,28 L5,28 L5,38 L58.0635189,38 Z"
                    id="Path"
                    fill="#DDF5FF"
                  />
                  <path
                    d="M58.0635189,28 L57.9858214,28 C58.1689104,28.5213122 58.2689171,29.0823657 58.2689171,29.6667966 C58.2689171,32.4237512 56.0549241,34.6664069 53.3332052,34.6664069 L5,34.6664069 L5,38 L58.0635189,38 C60.7852377,38 63,35.7573443 63,32.9996103 C63,30.2426557 60.7852377,28 58.0635189,28 L58.0635189,28 Z"
                    id="Path"
                    fill="#C1E9F4"
                  />
                  <path
                    d="M56.9399067,37.6115538 C59.6220004,37.6115538 61.8091977,35.3660388 61.8551633,32.5884187 C61.9011289,29.7287527 59.5783331,27.3892429 56.828057,27.3892429 L2.04776814,27.3892429 C0.91701399,27.3892429 0,26.4357554 0,25.2592298 L0,23.1292166 C0,21.9534875 0.91701399,21 2.04776814,21 L56.9399067,21 C63.047971,21 68,26.1489922 68,32.5 C68,38.8510078 63.047971,44 56.9399067,44 L2.04776814,44 C0.91701399,44 0,43.0465125 0,41.8699869 L0,39.7407704 C0,38.5642446 0.91701399,37.6107571 2.04776814,37.6107571 L56.9399067,37.6107571 L56.9399067,37.6115538 Z"
                    id="Path"
                    fill="#8DB0FF"
                  />
                  <path
                    d="M56.5915606,21 L53,21 C59.3004426,21 64.4084396,26.1489922 64.4084396,32.5 C64.4084396,38.8518045 59.3004426,44 53,44 L56.5915606,44 C62.892003,44 68,38.8518045 68,32.5 C68,26.1489922 62.892003,21 56.5915606,21 L56.5915606,21 Z"
                    id="Path"
                    fill="#669AF7"
                  />
                  <path
                    d="M57,35.127534 L57,40.871239 C57,42.3885193 55.4553558,43.4180739 54.053955,42.8340761 L51.8941589,41.9342104 L49.9762852,42.7680382 C48.5709057,43.3790876 47,42.3495331 47,40.8171359 L47,35.127534 C47,33.9523777 47.9525704,33 49.1279644,33 L54.8728313,33 C56.0474296,33 57,33.9523777 57,35.127534 L57,35.127534 Z"
                    id="Path"
                    fill="#FFD664"
                  />
                </g>
              </g>
              <g id="Group-3" transform="translate(180.000000, 75.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-2" />
              </g>
              <g id="Group-3-Copy" transform="translate(79.000000, 14.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3" />
                </mask>
                <g id="Clip-2" />
              </g>
              <g
                id="Group-3-Copy-2"
                transform="translate(61.000000, 34.000000)"
              >
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5" />
                </mask>
                <g id="Clip-2" />
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
export default BookAndTrophyIcon
