import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Input, Label } from '@/components'
import './SearchField.scss'

class SearchField extends Component {
  render() {
    const {
      id,
      label,
      labelPosition,
      name,
      onChange,
      onClear,
      placeholder,
      value,
    } = this.props
    return (
      <div className="SearchField">
        {label && (!labelPosition || labelPosition === 'before') && (
          <Label
            className="SearchField__input-label SearchField__input-label--is-before"
            htmlFor={id}
            text={label}
          />
        )}
        <div className="SearchField__input-wrapper">
          <i aria-hidden="true" className="fa fa-search" />
          <Input
            className="SearchField__input"
            id={id}
            name={name || ''}
            onChange={onChange}
            placeholder={placeholder}
            type="text"
            value={value}
          />
          {value.length ? (
            <i className="fa fa-times-circle" onClick={onClear} />
          ) : null}
        </div>
        {label && labelPosition === 'after' && (
          <Label
            className="SearchField__input-label SearchField__input-label--is-after"
            htmlFor={id}
            text={label}
          />
        )}
      </div>
    )
  }
}

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
}

export default SearchField
