import React, { Component } from 'react'
import cx from 'classnames'

import { Heading, Loader } from '@/components'
import AdminLayout from '../AdminLayout/AdminLayout'

import './AdminPage.scss'

export default class AdminPage extends Component {
  renderDefaultHeader(title) {
    return (
      <AdminLayout.Box>
        <Heading>{title}</Heading>
      </AdminLayout.Box>
    )
  }
  render() {
    const { children, title, renderHeader, isAlt, isLoading } = this.props
    if (isLoading) return <Loader />
    return (
      <div
        className={cx('AdminPage', { 'is-alt': isAlt }, this.props.className)}
      >
        <div className="AdminPage__header">
          {renderHeader ? renderHeader() : this.renderDefaultHeader(title)}
        </div>
        <div className="AdminPage-body">{children}</div>
      </div>
    )
  }
}
