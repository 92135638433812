import React, { Component } from 'react'
import InstanceContentPageActivities from './InstanceContentPageActivities'

export default class InstanceContentPageLearningObjective extends Component {
  render() {
    const { loItems, loTitle, onClickOpenActivityModal } = this.props
    return (
      <div className="InstanceContentPage-learningObjective">
        <h3 className="InstanceContentPage-learningObjectiveTitle">
          <span>Learning Objective:&nbsp;</span>
          <span className="is-bold">{loTitle}</span>
        </h3>
        <div className="InstanceContentPage-learningObjectiveContent">
          <InstanceContentPageActivities
            activities={loItems}
            onClickOpenActivityModal={onClickOpenActivityModal}
          />
        </div>
      </div>
    )
  }
}
