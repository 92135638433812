import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import qs from 'query-string'
import { inject, observer } from 'mobx-react'
import {
  Button,
  Heading,
  NewMessageModal,
  SortablePaginatedSearchTable,
  ProfileModal,
} from '@/components'
import { ROLE_STATUS } from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import EditUserModal, {
  EditUserModalDefaultValues,
} from '@/admin/modals/EditUserModal/EditUserModal'
import NewUserModal, {
  NewUserModalDefaultValues,
} from '@/admin/modals/NewUserModal/NewUserModal'
import NewUsersModal, {
  NewUsersModalDefaultValues,
} from '@/admin/modals/NewUsersModal/NewUsersModal'
import NewGroupMessageModal, {
  NewGroupMessageModalDefaultValues,
} from '@/admin/modals/NewGroupMessageModal/NewGroupMessageModal'
import SaveExistingUserModal, {
  SaveExistingUserModalDefaultValues,
} from '@/admin/modals/SaveExistingUserModal/SaveExistingUserModal'
import WithdrawUserModal, {
  WithdrawUserModalDefaultValues,
} from '@/admin/modals/WithdrawUserModal/WithdrawUserModal'
import './InstanceUsersPage.scss'

const getRoleText = (role) => {
  if (role === 'student') return 'learner'
  return role
}

const initialState = {
  editUserModal: null,
  newGroupMessageModal: null,
  newMessageModal: null,
  newUserModal: null,
  newUsersModal: null,
  pagination: {
    limit: 50,
    offset: 0,
  },
  profileModal: null,
  saveExistingUserModal: null,
  search: '',
  successfulBulkUpload: null,
  withdrawUserModal: null,
}

@inject('adminStore', 'profileStore', 'uiStore')
@observer
export default class InstanceUsersPage extends Component {
  state = {
    ...initialState,
  }
  componentDidMount() {
    const { adminStore, location, uiStore } = this.props
    const { instance } = adminStore
    const { pagination: initialPagination, search: initialSearch } =
      initialState
    const { limit: initialLimit, offset: initialOffset } = initialPagination
    const { search } = location
    const q = qs.parse(search)
    const { limit, offset, term } = q

    uiStore.updateTitle('Course Users Page')

    this.setState({
      pagination: {
        limit: limit || initialLimit,
        offset: offset || initialOffset,
      },
      search: term || initialSearch,
    })
    instance.loadUsers({
      limit: limit || initialLimit,
      offset: offset || initialOffset,
      term: term || initialSearch,
    })
  }
  closeModal = (modal) => {
    const { uiStore } = this.props
    const update = {}
    if (hasOwnProperty(this.state, modal)) {
      update[modal] = null
      this.setState(update)
      uiStore.scrollY(null, uiStore.scrollTop)
    }
  }
  loadInstructors = (input, exclude, isTransfer = false) => {
    const { adminStore } = this.props
    const { instance, session } = adminStore
    const currentUser = session.user
    const excludeIds = exclude || [currentUser.id]
    return instance.loadInstructors(input, excludeIds, isTransfer)
  }
  loadRecipients = (term) => {
    const { adminStore } = this.props
    const { instance, session } = adminStore
    const currentUser = session.user
    const excludeIds = [currentUser.id]
    return instance.loadRecipients(term, excludeIds)
  }
  onChangeEditUserModal = (field, value) => {
    const { editUserModal } = this.state
    const { values } = editUserModal
    if (hasOwnProperty(values, field)) {
      values[field] = value
      this.setState({ editUserModal })
    }
  }
  onChangeLimit = (limit) => {
    const { history, match } = this.props
    const { search } = this.state
    const { url } = match
    const pagination = {
      limit,
      offset: 0,
    }
    history.push(`${url}?${qs.stringify({ ...pagination, term: search })}`)
    this.setState({ pagination }, () => {
      this.searchUsers(pagination, search)
    })
  }
  onChangeNewGroupMessageModal = (field, value) => {
    const { newGroupMessageModal } = this.state
    const { values } = newGroupMessageModal
    if (hasOwnProperty(values, field)) {
      values[field] = value
      newGroupMessageModal['values'] = values
      this.setState({ newGroupMessageModal })
    }
  }
  onChangeNewUserModal = (field, value) => {
    const { newUserModal } = this.state
    const { values } = newUserModal
    if (hasOwnProperty(values, field)) {
      values[field] = value
      newUserModal['values'] = values
      this.setState({ newUserModal })
    }
  }
  onChangeNewUsersModal = (file) => {
    const { adminStore } = this.props
    const { newUsersModal } = this.state
    const { instance } = adminStore
    const { values: currentValues } = newUsersModal
    const { sendEmails } = currentValues
    const values = new NewUsersModalDefaultValues(file, sendEmails)
    newUsersModal['error'] = null
    newUsersModal['values'] = values
    instance
      .uploadUsers(file)
      .then((url) => {
        values['url'] = url
        newUsersModal['error'] = null
        newUsersModal['values'] = values
        this.setState({ newUsersModal })
      })
      .catch((error) => {
        values['url'] = null
        if (error && error.message) {
          const errorJSON = JSON.parse(error)
          if (errorJSON) {
            newUsersModal['error'] = errorJSON
          } else {
            newUsersModal['error'] = {
              upload: ['There was an error while uploading bulk users.'],
            }
          }
        }
        this.setState({ newUsersModal })
      })
  }
  onChangeNewUsersModalSendEmails = (sendEmails) => {
    const { newUsersModal } = this.state
    const { values } = newUsersModal
    values['sendEmails'] = sendEmails
    this.setState({ newUsersModal })
  }
  onChangeSearch = (event) => {
    const { history, match } = this.props
    const { target } = event
    const { url } = match
    const { value } = target
    const pagination = {
      limit: 50,
      offset: 0,
    }
    history.push(`${url}?${qs.stringify({ ...pagination, term: value })}`)
    this.setState(
      {
        pagination,
        search: value,
      },
      () => {
        this.searchUsers(pagination, value)
      },
    )
  }
  onChangeWithdrawUserModal = (field, value) => {
    const { withdrawUserModal } = this.state
    const { values } = withdrawUserModal
    if (hasOwnProperty(values, field)) {
      values[field] = value
      return this.setState({ withdrawUserModal })
    }
  }
  onClearSearch = () => {
    this.onChangeSearch({ target: { value: '' } })
  }
  onEditUser = (isTransfer) => {
    const { adminStore } = this.props
    const { editUserModal, pagination, search } = this.state
    const { instance } = adminStore
    const { values } = editUserModal
    const {
      confirmPassword,
      dstInstructor,
      email,
      field1,
      field2,
      field3,
      firstName,
      instructor,
      lastName,
      password,
      userId,
      removeInstructorId,
      roles,
    } = values
    const courseRoles = [
      {
        course_id: instance.id,
        field1,
        field2,
        field3,
        roles: roles.map((role) => role.type),
      },
    ]
    if (instructor && instructor.instructorId) {
      courseRoles[0]['instructor_id'] = instructor.instructorId
    }
    if (isTransfer) {
      const { instructorId: dstInstructorId } = dstInstructor
      return instance
        .transferStudents(removeInstructorId, dstInstructorId)
        .then(() => {
          instance
            .editUser({
              confirmPassword,
              courseRoles,
              email,
              firstName,
              lastName,
              password,
              userId,
            })
            .then(() => {
              instance.loadUsers({ ...pagination, term: search })
              this.closeModal('editUserModal')
            })
            .catch((error) => {
              console.error('onEditUserError transferStudents', error)
              editUserModal['error'] =
                error && error.message
                  ? JSON.parse(error.message)
                  : { confirm: ['There was an error while editing the user.'] }
              this.setState({ editUserModal })
            })
        })
    }
    instance
      .editUser({
        confirmPassword,
        courseRoles,
        email,
        firstName,
        lastName,
        password,
        userId,
      })
      .then(() => {
        instance.loadUsers({ ...pagination, term: search })
        this.closeModal('editUserModal')
      })
      .catch((error) => {
        console.error('onEditUserError', error)
        if (
          error.message &&
          typeof JSON.parse(error.message) === 'object' &&
          typeof JSON.parse(error.message)['email'] === 'object' &&
          JSON.parse(error.message)['email'][0] ===
            'Cannot withdraw the instructor as long as there are active students associated with it'
        ) {
          editUserModal['error'] = {
            transfer: [
              'Cannot withdraw the instructor as long as there are active students associated with it',
            ],
          }
        } else {
          editUserModal['error'] =
            error && error.message
              ? JSON.parse(error.message)
              : { confirm: ['There was an error while editing the user.'] }
        }
        this.setState({ editUserModal })
      })
  }
  onPageClick = (pageIndex) => {
    const { history, match } = this.props
    const { pagination: prevPagination, search } = this.state
    const { limit } = prevPagination
    const { url } = match
    const pagination = {
      limit,
      offset: pageIndex * limit,
    }
    history.push(`${url}?${qs.stringify({ ...pagination, term: search })}`)
    this.setState({ pagination }, () => {
      this.searchUsers(pagination, search)
    })
  }
  onSaveExistingUser = () => {
    const { adminStore } = this.props
    const { pagination, saveExistingUserModal, search } = this.state
    const { instance } = adminStore
    const { values } = saveExistingUserModal
    const { instructor, roles, user } = values
    instance
      .saveExistingUser(roles, user, instructor)
      .then(() => {
        instance.loadUsers({ ...pagination, term: search })
        this.closeModal('saveExistingUserModal')
      })
      .catch((error) => {
        if (error && error.message) {
          const errorJSON = JSON.parse(error.message)
          saveExistingUserModal['error'] = errorJSON
          this.setState({ saveExistingUserModal })
        } else {
          saveExistingUserModal['error'] = {
            confirm: ['There was an error while saving the user.'],
          }
          this.setState({ saveExistingUserModal })
        }
      })
  }
  onSaveGroupMessage = () => {
    const { adminStore } = this.props
    const { newGroupMessageModal } = this.state
    const { instance } = adminStore
    const { values } = newGroupMessageModal
    instance
      .saveGroupMessage(values)
      .then(() => {
        this.closeModal('newGroupMessageModal')
      })
      .catch((error) => {
        newGroupMessageModal['error'] =
          error && error.message
            ? error.message
            : 'There was an error while uploading bulk users.'
        this.setState({ newGroupMessageModal })
      })
  }
  onSaveThread = (recipients, message, reset) => {
    const { adminStore } = this.props
    const { newMessageModal } = this.state
    const { instance, session } = adminStore
    const currentUser = session.user
    const excludeIds = [currentUser.id]
    instance
      .saveThread(recipients, message, null, excludeIds, currentUser)
      .then(() => {
        reset(() => this.closeModal('newMessageModal'))
      })
      .catch((error) => {
        newMessageModal['error'] =
          error && error.message
            ? error.message
            : 'There was an error while saving the message.'
        this.setState({ newMessageModal })
      })
  }
  onSaveUser = () => {
    const { adminStore } = this.props
    const { newUserModal, pagination, search } = this.state
    const { instance } = adminStore
    const { values } = newUserModal
    instance
      .saveUser(values)
      .then(() => {
        instance.loadUsers({ ...pagination, term: search })
        this.closeModal('newUserModal')
      })
      .catch((error) => {
        if (error && error.message) {
          const errorJSON = JSON.parse(error.message)
          if (errorJSON['existing']) {
            const user = errorJSON['existing']
            const { instructor, roles } = values
            this.openSaveExistingUserModal(roles, user, instructor)
            this.closeModal('newUserModal')
          } else {
            newUserModal['error'] = errorJSON
            this.setState({ newUserModal })
          }
        } else {
          newUserModal['error'] = {
            confirm: 'There was an error while saving the user.',
          }
          this.setState({ newUserModal })
        }
      })
  }
  onSaveUsers = () => {
    const { adminStore } = this.props
    const { newUsersModal } = this.state
    const { instance } = adminStore
    const { values } = newUsersModal
    const { sendEmails, url } = values
    instance
      .saveUsers(url, sendEmails)
      .then((result) => {
        this.setState({ successfulBulkUpload: result })
      })
      .catch((error) => {
        values['url'] = null
        if (error && error.message) {
          const errorJSON = JSON.parse(error.message)
          if (errorJSON) {
            newUsersModal['error'] = errorJSON
          } else {
            newUsersModal['error'] = {
              confirm: ['There was an error while saving bulk users.'],
            }
          }
        }
        this.setState({ newUsersModal })
      })
  }
  onSendMessage = (user) => {
    this.closeModal('profileModal')
    this.openNewMessageModal(user)
  }
  onWithdrawUser = () => {
    const { adminStore } = this.props
    const { pagination, withdrawUserModal, search } = this.state
    const { instance } = adminStore
    const { values } = withdrawUserModal
    const { dstInstructor, id: userId, roles } = values
    if (dstInstructor) {
      const instructorRole = roles.filter(
        (role) => role.type === 'instructor',
      )[0]
      const { id: instructorId } = instructorRole || {}
      const { instructorId: dstInstructorId } = dstInstructor
      return instance
        .transferStudents(instructorId, dstInstructorId)
        .then(() => instance.withdrawUser(userId, roles))
        .then(() => {
          instance.loadUsers({ ...pagination, term: search })
          this.closeModal('withdrawUserModal')
        })
        .catch((error) => {
          console.error('onWithdrawUserError transferStudents', error)
          if (error && error.message) {
            const errorJSON = JSON.parse(error.message)
            withdrawUserModal['error'] = errorJSON
            this.setState({ withdrawUserModal })
          }
        })
    }
    return instance
      .withdrawUser(userId, roles)
      .then(() => {
        instance.loadUsers({ ...pagination, term: search })
        this.closeModal('withdrawUserModal')
      })
      .catch((error) => {
        console.error('onWithdrawUserError', error)
        if (error && error.message) {
          const errorJSON = JSON.parse(error.message)
          withdrawUserModal['error'] = errorJSON
          this.setState({ withdrawUserModal })
        }
      })
  }
  openEditUserModal = (user, roles) => {
    const {
      email,
      field1,
      field2,
      field3,
      firstName,
      id: userId,
      lastName,
    } = user
    const instructorRole = roles.filter((role) => role.type === 'instructor')
    const studentRole = roles.filter((role) => role.type === 'student')
    this.setState({
      editUserModal: {
        error: null,
        values: new EditUserModalDefaultValues({
          email,
          field1,
          field2,
          field3,
          firstName,
          lastName,
          prevInstructorId: studentRole.length
            ? studentRole[0].instructorId
            : null,
          removeInstructorId: instructorRole.length
            ? instructorRole[0].id
            : null,
          roles,
          userId,
        }),
      },
    })
  }
  openNewGroupMessageModal = (role) => {
    this.setState({
      newGroupMessageModal: {
        error: null,
        values: new NewGroupMessageModalDefaultValues(role),
      },
    })
  }
  openNewMessageModal = (user = null) => {
    this.setState({
      newMessageModal: {
        error: null,
        recipient: user,
      },
    })
  }
  openNewUserModal = () => {
    this.setState({
      newUserModal: {
        error: null,
        values: new NewUserModalDefaultValues(),
      },
    })
  }
  openNewUsersModal = () => {
    this.setState({
      newUsersModal: {
        error: null,
        values: new NewUsersModalDefaultValues(),
      },
    })
  }
  openProfileModal = (userId) => {
    const { adminStore, profileStore } = this.props
    const { instance, session } = adminStore
    const { contentfulId } = instance
    const { user: currentUser } = session
    profileStore.loadUserProfile(userId, contentfulId)
    this.setState({
      profileModal: {
        isProfileUserCurrentUser: userId === currentUser.id,
      },
    })
  }
  openSaveExistingUserModal = (roles, user, instructor) => {
    this.setState({
      saveExistingUserModal: {
        error: null,
        values: new SaveExistingUserModalDefaultValues({
          instructor,
          roles,
          user,
        }),
      },
    })
  }
  openWithdrawUserModal = (user, roles) => {
    const { email, firstName, id, lastName } = user
    return this.setState({
      withdrawUserModal: {
        error: null,
        values: new WithdrawUserModalDefaultValues({
          email,
          firstName,
          id,
          lastName,
          roles,
        }),
      },
    })
  }
  onCloseBulkUploadModal = () => {
    this.closeModal('newUsersModal')
    this.setState({ successfulBulkUpload: null })
  }
  searchUsers = _.debounce(
    (pagination, value) => {
      const { adminStore } = this.props
      const { instance } = adminStore
      instance.loadUsers({ ...pagination, term: value })
    },
    250,
    { leading: true, trailing: true },
  )
  renderUsersHeaderButtons() {
    return (
      <div className="InstanceUsersPage-headerButtons">
        <div className="InstanceUsersPage-headerButton">
          <Button
            text="Add Single User"
            isSuccess
            onClick={this.openNewUserModal}
          />
        </div>
        <div className="InstanceUsersPage-headerButton">
          <Button
            text="Add Bulk Users"
            isSuccess
            onClick={this.openNewUsersModal}
          />
        </div>
      </div>
    )
  }
  renderUsersTable() {
    const { adminStore } = this.props
    const { pagination, search } = this.state
    const { limit, offset } = pagination
    const { instance, session } = adminStore
    const { isLoadingUsers, users, usersSearchCount } = instance
    const currentUser = session.user
    const total = usersSearchCount || 0
    return (
      <Fragment>
        <SortablePaginatedSearchTable
          className="InstanceUsersPage__users-table"
          headers={[
            { sortBy: 'first_name', headerText: 'Name' },
            { sortBy: 'email', headerText: 'Email' },
            { sortBy: 'type', headerText: 'Type' },
          ]}
          id="InstanceUsersPageUsersTable"
          isLoading={isLoadingUsers}
          offset={parseInt(offset)}
          onChangeLimit={this.onChangeLimit}
          onChangeSearch={this.onChangeSearch}
          onClearSearch={this.onClearSearch}
          onPageClick={this.onPageClick}
          onSort={null} // TODO
          limit={parseInt(limit)}
          rows={
            users
              ? users.map(({ roles, user }, index) => {
                  const isDisabled = user.roleStatus === ROLE_STATUS.disabled
                  const name = `${user.firstName} ${user.lastName}`
                  return {
                    isDisabled,
                    items: [
                      !isDisabled ? (
                        <Button
                          className="InstanceUsersPage__users-table-name"
                          key={`InstanceUsersPage__users-table-name-${index}`}
                          onClick={() => this.openProfileModal(user.id)}
                        >
                          <span>{name}</span>
                        </Button>
                      ) : (
                        <span
                          key={`InstanceUsersPage__users-table-name-${index}`}
                        >
                          {name}
                        </span>
                      ),
                      <span
                        key={`InstanceUsersPage__users-table-email-${index}`}
                      >
                        {user.email}
                      </span>,
                      <span
                        className="InstanceUsersPage__users-table-roles"
                        key={`InstanceUsersPage__users-table-roles-${index}`}
                      >
                        {roles.map((role) => getRoleText(role.type)).join(', ')}
                      </span>,
                      <div
                        className="InstanceUsersPage__users-table-actions"
                        key={`InstanceUsersPage__users-table-actions-${index}`}
                      >
                        <Button
                          disabled={isDisabled}
                          text="Edit"
                          isInfo
                          onClick={() => this.openEditUserModal(user, roles)}
                        />
                        {user.id !== currentUser.id ? (
                          <Button
                            disabled={isDisabled}
                            isDanger
                            aria-label="Withdraw User"
                            onClick={() =>
                              this.openWithdrawUserModal(user, roles)
                            }
                          >
                            <span>
                              <i
                                className="fa fa-close"
                                aria-hidden="true"
                                title="Withdraw User"
                              />
                            </span>
                          </Button>
                        ) : null}
                      </div>,
                    ],
                  }
                })
              : []
          }
          searchLabel="Search users"
          searchLabelPosition="before"
          searchPlaceholder="Search users..."
          searchTerm={search}
          showingText="Users"
          sortBy={null} // TODO
          sortAscending={null} // TODO
          total={parseInt(total)}
        />
        {!isLoadingUsers && users && !users.length ? (
          <div className="title InstanceUsersPage__empty">No users found.</div>
        ) : null}
      </Fragment>
    )
  }
  renderUsersSidebar() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { course } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    return (
      <div className="InstanceUsersPage-sidebar">
        <Heading is2>Send a Message</Heading>
        <Button
          text="Send to individual users"
          disabled={disableMessaging}
          isPrimary
          isFullWidth
          onClick={() => this.openNewMessageModal()}
        />
        <p>
          You can send a message to each individual learner. Learners may reply
          to your messages. You will see replies in the Messages tab.
        </p>
        <Heading is2>Send a group message</Heading>
        <Button
          text="Send to all learners"
          disabled={disableMessaging}
          isPrimary
          isFullWidth
          onClick={() => this.openNewGroupMessageModal('student')}
        />
        <Button
          text="Send to all admins"
          disabled={disableMessaging}
          isPrimary
          isFullWidth
          onClick={() => this.openNewGroupMessageModal('admin')}
        />
        <Button
          text="Send to all instructors"
          disabled={disableMessaging}
          isPrimary
          isFullWidth
          onClick={() => this.openNewGroupMessageModal('instructor')}
        />
        {disableMessaging ? (
          <span className="InstanceUsersPage-sidebarDisclaimer">
            * Messaging has been disabled for this course.
          </span>
        ) : null}
      </div>
    )
  }
  render() {
    const { adminStore, profileStore } = this.props
    const {
      editUserModal,
      newGroupMessageModal,
      newMessageModal,
      newUserModal,
      newUsersModal,
      profileModal,
      saveExistingUserModal,
      successfulBulkUpload,
      withdrawUserModal,
    } = this.state
    const { instance, session } = adminStore
    const { isLoadingUserProfile, userProfile } = profileStore
    const {
      course,
      isEditingUser,
      isLoadingDstInstructors,
      isLoadingInstructors,
      isLoadingRecipients,
      isSavingExistingUser,
      isSavingGroupMessage,
      isSavingThread,
      isSavingUser,
      isSavingUsers,
      isTransferringStudents,
      isUploadingUsers,
      isWithdrawingUser,
    } = instance || {}
    const { settings, title: courseTitle } = course || {}
    const { disableMessaging } = settings || {}
    return (
      <Fragment>
        <AdminLayout className="InstanceUsersPage">
          <AdminLayout.Sidebar>{this.renderUsersSidebar()}</AdminLayout.Sidebar>
          <AdminLayout.Main id="main-content">
            {this.renderUsersHeaderButtons()}
            {this.renderUsersTable()}
          </AdminLayout.Main>
        </AdminLayout>
        <NewMessageModal
          error={
            newMessageModal && newMessageModal.error
              ? newMessageModal.error
              : null
          }
          isLoading={isLoadingRecipients}
          isOpen={!!newMessageModal}
          isSaving={isSavingThread}
          loadRecipients={this.loadRecipients}
          onClose={() => this.closeModal('newMessageModal')}
          onSave={this.onSaveThread}
          onSaveCallback
          recipient={
            newMessageModal && newMessageModal.recipient
              ? newMessageModal.recipient
              : null
          }
        />
        <NewGroupMessageModal
          error={
            newGroupMessageModal && newGroupMessageModal.error
              ? newGroupMessageModal.error
              : null
          }
          isAdmin={
            newGroupMessageModal &&
            newGroupMessageModal.values &&
            newGroupMessageModal.values.role === 'admin'
          }
          isInstructor={
            newGroupMessageModal &&
            newGroupMessageModal.values &&
            newGroupMessageModal.values.role === 'instructor'
          }
          isLearner={
            newGroupMessageModal &&
            newGroupMessageModal.values &&
            newGroupMessageModal.values.role === 'student'
          }
          isLoading={isSavingGroupMessage}
          isOpen={!!newGroupMessageModal}
          onChange={this.onChangeNewGroupMessageModal}
          onClose={() => this.closeModal('newGroupMessageModal')}
          onConfirm={this.onSaveGroupMessage}
          values={
            newGroupMessageModal && newGroupMessageModal.values
              ? newGroupMessageModal.values
              : null
          }
        />
        <NewUserModal
          error={newUserModal && newUserModal.error ? newUserModal.error : null}
          isLoading={isSavingUser}
          isLoadingInstructors={isLoadingInstructors}
          isOpen={!!newUserModal}
          loadInstructors={this.loadInstructors}
          onChange={this.onChangeNewUserModal}
          onClose={() => this.closeModal('newUserModal')}
          onConfirm={this.onSaveUser}
          values={
            newUserModal && newUserModal.values ? newUserModal.values : null
          }
        />
        <NewUsersModal
          error={
            newUsersModal && newUsersModal.error ? newUsersModal.error : null
          }
          isLoading={isSavingUsers}
          isOpen={!!newUsersModal}
          isUploading={isUploadingUsers}
          onChange={this.onChangeNewUsersModal}
          onChangeSendEmails={this.onChangeNewUsersModalSendEmails}
          onClose={this.onCloseBulkUploadModal}
          onConfirm={this.onSaveUsers}
          successfulBulkUpload={successfulBulkUpload}
          values={
            newUsersModal && newUsersModal.values ? newUsersModal.values : null
          }
        />
        <EditUserModal
          adminUserId={session && session.user && session.user.id}
          error={editUserModal ? editUserModal.error : null}
          isLoading={isTransferringStudents || isEditingUser}
          isLoadingDstInstructors={isLoadingDstInstructors}
          isLoadingInstructors={isLoadingInstructors}
          isOpen={!!editUserModal}
          isTransfer={
            editUserModal &&
            editUserModal.error &&
            editUserModal.error['transfer']
          }
          loadInstructors={this.loadInstructors}
          onChange={this.onChangeEditUserModal}
          onClose={() => this.closeModal('editUserModal')}
          onConfirm={this.onEditUser}
          values={editUserModal ? editUserModal.values : null}
        />
        <ProfileModal
          isCurrentUser={
            profileModal && profileModal.isProfileUserCurrentUser
              ? profileModal.isProfileUserCurrentUser
              : null
          }
          isDisabledMessaging={disableMessaging}
          isLoading={isLoadingUserProfile}
          isOpen={!!profileModal}
          loadingError={
            profileStore && profileStore.error ? profileStore.error : null
          }
          onClose={() => this.closeModal('profileModal')}
          sendMessage={this.onSendMessage}
          userProfile={userProfile}
        />
        <SaveExistingUserModal
          courseTitle={courseTitle}
          email={
            saveExistingUserModal &&
            saveExistingUserModal.values &&
            saveExistingUserModal.values.user
              ? saveExistingUserModal.values.user.email
              : null
          }
          error={
            saveExistingUserModal && saveExistingUserModal.error
              ? saveExistingUserModal.error
              : null
          }
          firstName={
            saveExistingUserModal &&
            saveExistingUserModal.values &&
            saveExistingUserModal.values.user
              ? saveExistingUserModal.values.user.firstName
              : null
          }
          isLoading={isSavingExistingUser}
          isOpen={!!saveExistingUserModal}
          lastName={
            saveExistingUserModal &&
            saveExistingUserModal.values &&
            saveExistingUserModal.values.user
              ? saveExistingUserModal.values.user.lastName
              : null
          }
          onClose={() => this.closeModal('saveExistingUserModal')}
          onConfirm={this.onSaveExistingUser}
          username={
            saveExistingUserModal &&
            saveExistingUserModal.values &&
            saveExistingUserModal.values.user
              ? saveExistingUserModal.values.user.username
              : null
          }
        />
        <WithdrawUserModal
          courseTitle={courseTitle}
          error={withdrawUserModal ? withdrawUserModal.error : null}
          isLoading={isTransferringStudents || isWithdrawingUser}
          isLoadingInstructors={isLoadingInstructors}
          isOpen={!!withdrawUserModal}
          isTransfer={
            withdrawUserModal &&
            withdrawUserModal.error &&
            withdrawUserModal.error['transfer']
          }
          loadInstructors={this.loadInstructors}
          onChange={this.onChangeWithdrawUserModal}
          onClose={() => this.closeModal('withdrawUserModal')}
          onConfirm={this.onWithdrawUser}
          values={withdrawUserModal ? withdrawUserModal.values : null}
        />
      </Fragment>
    )
  }
}
