import { computed, observable, action } from 'mobx'
import _ from 'lodash'
import { upperFirst } from '@/utils/helpers'

const sortUnits = (unitA, unitB) => {
  const { displayOrder: displayOrderA } = unitA
  const { displayOrder: displayOrderB } = unitB
  if (displayOrderA < displayOrderB) {
    return -1
  }
  if (displayOrderA > displayOrderB) {
    return 1
  }
  return 0
}

export default class Learner {
  @observable id
  @observable firstName
  @observable lastName
  @observable rank
  @observable userId
  @observable lastActivity
  @observable masteredUnits
  @observable totalUnits

  @observable units = []
  @observable indexedMetrics
  @observable firstAttempt = 0
  @observable completion = 0
  @observable averages = {
    courseCompletion: 0,
    firstAttempt: 0,
  }
  @observable timeMetrics = {}

  @observable meta
  @observable areUnitsReversed = false

  @action reverseUnits() {
    this.areUnitsReversed = !this.areUnitsReversed
  }

  constructor(data) {
    Object.assign(this, data)
  }

  @computed get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  @computed get shortName() {
    const lastName = `${upperFirst(this.lastName || '')}.`
    return `${this.firstName} ${lastName}`
  }

  @computed get hasSections() {
    return this.sections.length > 0
  }

  @computed get hasMetrics() {
    return !!this.indexedMetrics
  }

  @computed get sections() {
    let index = 0
    const units = this.units
      .reduce((sections, unit) => {
        const {
          contentfulId: unitContentfulId,
          displayOrder: unitDisplayOrder,
          id: unitId,
          title: unitTitle,
        } = unit
        let uniqueSections = {}
        unit.sections.forEach((section) => {
          const {
            contentfulId: sectionContentfulId,
            displayOrder: sectionDisplayOrder,
            hasVideo: sectionHasVideo,
            id: sectionId,
            learningObjectives: sectionLearningObjectives,
            rank: sectionRank,
            reattemptRank: sectionReattemptRank,
            reattemptStatus: sectionReattemptStatus,
            reattemptStatusUpdatedAt: sectionReattemptStatusUpdatedAt,
            testedOut: sectionTestedOut,
            title: sectionTitle,
            watchPercentage: sectionWatchPercentage,
          } = section
          if (!uniqueSections[sectionId]) {
            index = index + 1
            uniqueSections[sectionId] = {
              cid: `${unitId}_${sectionId}`,
              contentfulId: sectionContentfulId,
              displayOrder: sectionDisplayOrder,
              hasVideo: sectionHasVideo,
              id: sectionId,
              learningObjectives: sectionLearningObjectives,
              order: index,
              rank: sectionRank,
              reattemptRank: sectionReattemptRank,
              reattemptStatus: sectionReattemptStatus,
              reattemptStatusUpdatedAt: sectionReattemptStatusUpdatedAt,
              testedOut: sectionTestedOut,
              title: sectionTitle,
              unitContentfulId,
              unitDisplayOrder,
              unitId,
              unitTitle,
              watchPercentage: sectionWatchPercentage,
            }
          } else {
            Object.keys(uniqueSections[sectionId]).forEach((key) => {
              if (!uniqueSections[sectionId][key] && section[key]) {
                uniqueSections[sectionId][key] = section[key]
              }
            })
          }
        })
        return sections.concat(
          Object.keys(uniqueSections).map(
            (sectionId) => uniqueSections[sectionId],
          ),
        )
      }, [])
      .sort((a, b) => a.order - b.order)
    return this.areUnitsReversed ? _.reverse(units) : units
  }

  @action sectionsWithTestedOut(snapshotTestOut) {
    let sectionsWithTestedOut = []
    // If can't test out using Snapshot, do not check for tested out Sections
    let testedOutUnits = snapshotTestOut
      ? [...this.testedOutUnits.sort(sortUnits)]
      : []
    testedOutUnits.forEach((unit) => {
      const {
        displayOrder: unitDisplayOrder,
        sections,
        title: unitTitle,
      } = unit
      sections.forEach((section) => {
        sectionsWithTestedOut.push({
          ...section,
          unitDisplayOrder,
          unitTitle,
        })
      })
    })
    const sectionIdsWithTestedOut = sectionsWithTestedOut.map(
      (section) => section.id,
    )
    let sectionsWithProgress = [...this.sections].filter(
      (section) => sectionIdsWithTestedOut.indexOf(section.id) === -1,
    )
    let allSections = []
    while (sectionsWithTestedOut.length || sectionsWithProgress.length) {
      const sectionA = sectionsWithTestedOut.length
        ? sectionsWithTestedOut[0]
        : null
      const sectionB = sectionsWithProgress.length
        ? sectionsWithProgress[0]
        : null
      if (!sectionA) {
        allSections.push(sectionB)
        sectionsWithProgress.shift()
      } else if (!sectionB) {
        allSections.push(sectionA)
        sectionsWithTestedOut.shift()
      } else if (sectionA.unitDisplayOrder < sectionB.unitDisplayOrder) {
        allSections.push(sectionA)
        sectionsWithTestedOut.shift()
      } else if (sectionB.unitDisplayOrder < sectionA.unitDisplayOrder) {
        allSections.push(sectionB)
        sectionsWithProgress.shift()
      } else if (sectionA.displayOrder < sectionB.displayOrder) {
        allSections.push(sectionA)
        sectionsWithTestedOut.shift()
      } else if (sectionB.displayOrder < sectionA.displayOrder) {
        allSections.push(sectionB)
        sectionsWithProgress.shift()
      } else {
        // Duplicate sections, use tested out
        allSections.push(sectionA)
        sectionsWithProgress.shift()
        sectionsWithTestedOut.shift()
      }
    }
    return allSections
  }

  @computed get orderedMetrics() {
    if (!this.indexedMetrics) return []
    return [
      this.indexedMetrics.rankCountDays,
      this.indexedMetrics.rankFreqNewActivities,
      this.indexedMetrics.rankCountLectureNotes,
      this.indexedMetrics.rankFreqLectureNotes,
      this.indexedMetrics.rankCountVideos,
      this.indexedMetrics.rankAverageActivityAttempts,
      this.indexedMetrics.rankAverageActivityTime,
      this.indexedMetrics.rankDurationLectureNotes,
      this.indexedMetrics.rankFreqVideos,
      this.indexedMetrics.rankDurationVideos,
    ]
  }
}
