import { inject } from 'mobx-react'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import ReactModal from 'react-modal'
import cx from 'classnames'
import { Button } from '@/components'
import './Modal.scss'

const stopPropagation = (event) => {
  event.stopPropagation()
}

const ModalBody = ({ children, className, ...restProps }) => (
  <div {...restProps} className={cx('Modal__body', className)}>
    {children}
  </div>
)
const ModalButton = ({
  children,
  className,
  isPrimary,
  isSecondary,
  isSuccess,
  ...restProps
}) => (
  <Button
    {...restProps}
    className={cx('Modal__button', className, {
      'Modal__button--is-primary': !isSuccess && (!isSecondary || isPrimary),
      'Modal__button--is-secondary': !isSuccess && !isPrimary && isSecondary,
    })}
    isSuccess={isSuccess}
  >
    {children}
  </Button>
)
const ModalFooter = ({ children, className }) => (
  <div className={cx('Modal__footer', className)}>{children}</div>
)
const ModalHeader = ({ children, className }) => (
  <div className={cx('Modal__header', className)}>{children}</div>
)

@inject('uiStore')
class Modal extends Component {
  static defaultProps = {
    shouldCloseOnEsc: true,
    shouldCloseOnOverlayClick: true,
  }
  static Body = ModalBody
  static Button = ModalButton
  static Footer = ModalFooter
  static Header = ModalHeader
  onAfterOpen = () => {
    const { setScrollTop, uiStore } = this.props
    if (setScrollTop) {
      uiStore.setScrollTop()
    }
    const thisNode = ReactDOM.findDOMNode(this)
    thisNode.setAttribute('aria-role', 'dialog')
    if (this.$childrenWrapper) {
      this.$childrenWrapper.focus()
    }
  }
  onRequestClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  setRefChildrenWrapper = (el) => {
    this.$childrenWrapper = el
  }
  renderChildren() {
    const { children, contentLabel, onClickClose, onClose } = this.props
    return (
      <div
        className="Modal__children"
        onClick={!onClickClose ? stopPropagation : null}
        ref={this.setRefChildrenWrapper}
        tabIndex="0"
      >
        {onClose ? (
          <Button
            aria-label={`Close ${contentLabel || 'Modal'}`}
            className="Modal__close"
            onClick={onClose}
          >
            <i className="fa fa-close" />
          </Button>
        ) : null}
        {children}
      </div>
    )
  }
  render() {
    const {
      className,
      contentLabel,
      isDanger,
      isOpen,
      onClose,
      overlayClassName,
      shouldCloseOnEsc,
      shouldCloseOnOverlayClick,
    } = this.props
    const classNames = cx('Modal', className, {
      'Modal--is-close': onClose,
      'Modal--is-danger': isDanger,
    })
    return (
      <ReactModal
        appElement={document.getElementById('root')}
        bodyOpenClassName="Modal--is-open-body"
        className={classNames}
        contentLabel={contentLabel}
        htmlOpenClassName="Modal--is-open-html"
        isOpen={isOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={onClose ? this.onRequestClose : null}
        overlayClassName={cx('Modal__overlay', overlayClassName)}
        portalClassName="Modal__portal"
        shouldCloseOnEsc={!!onClose && !!shouldCloseOnEsc}
        shouldCloseOnOverlayClick={!!onClose && !!shouldCloseOnOverlayClick}
      >
        <div
          className="Modal__wrapper"
          onClick={onClose ? this.onRequestClose : null}
        >
          <div className="Modal__main">
            <div className="Modal__content">{this.renderChildren()}</div>
          </div>
        </div>
      </ReactModal>
    )
  }
}

export default Modal
