import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import './Notification.scss'

export default class Notification extends Component {
  static propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
  }

  static defaultProps = {
    type: 'warning',
  }

  render() {
    const { message, type } = this.props

    const className = cx('notification', {
      'is-primary': type === 'primary',
      'is-link': type === 'link',
      'is-info': type === 'info',
      'is-success': type === 'success',
      'is-warning': type === 'warning',
      'is-danger': type === 'danger',
    })

    if (!message) return null

    return (
      <div className={cx('Notification', this.props.className)}>
        <div className={className}>{message}</div>
      </div>
    )
  }
}
