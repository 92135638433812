import { extendObservable, action, computed } from 'mobx'
import humps from 'lodash-humps'

import { REFRESH_RATE } from '@/constants'
import SentryReplay from '@/SentryReplay'
import { fromUTCToLocale } from '@/utils/helpers'
import request from '@/utils/request'

import Instance from './models/instance'

const initialState = {
  session: null,
  courses: null,
  coursesSearch: '',
  coursesTotal: 0,
  instance: new Instance(),
  isCopyingInstance: false,
  isCourseNotFound: false,
  isLoadingCourses: false,
  isLoadingNotifications: false,
  isLoadingOverview: false,
  isLoadingReportExportURL: false,
  isLoadingUnreadMessagesCount: false,
  isLoadingUsers: false,
  isRefreshingNotifications: false,
  loadNotificationsError: null,
  notifications: [],
  users: null,
  usersSearch: '',
  usersTotal: 0,
  errorLoadingCourses: null,
}

export default class AdminStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  @action reset() {
    this.stopLoadUnreadMessagesCountInterval()
    extendObservable(this, { ...initialState })
  }

  @action init(session) {
    this.session = session
  }

  @action copyInstance(courseId, courseName, integrationType, ssoEndpoint) {
    const POST = {
      course_title: courseName,
    }
    const errors = {}
    if (!courseName) {
      errors['courseName'] = ['Field may not be empty.']
    }
    if (!integrationType) {
      errors['integrationType'] = ['Field may not be empty.']
    } else if (integrationType === 'ssoEndpoint' && !ssoEndpoint) {
      errors['ssoEndpoint'] = ['Field may not be empty.']
    }
    if (Object.keys(errors).length) {
      return Promise.reject(new Error(JSON.stringify(errors)))
    } else {
      if (integrationType === 'ssoEndpoint' && ssoEndpoint) {
        POST['sso_endpoint'] = ssoEndpoint
      }
      this.isCopyingInstance = true
      return request
        .post(`/admin/${courseId}/copy`, POST)
        .then(this.handleCopyInstance)
        .catch(this.handleCopyInstanceError)
    }
  }
  @action handleCopyInstance = () => {
    this.isCopyingInstance = false
    return Promise.resolve()
  }
  @action handleCopyInstanceError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isCopyingInstance = false
    return Promise.reject(
      response && response.data && response.data.errors
        ? new Error(JSON.stringify(humps(response.data.errors)))
        : new Error(
            JSON.stringify({
              confirm: ['There was an error while copying the course.'],
            }),
          ),
    )
  }

  @action loadCourses(options = {}) {
    const { filters } = options
    const { term } = filters || { term: '' }
    this.isLoadingCourses = true
    this.coursesSearch = term
    this.errorLoadingCourses = null
    return request
      .get('/admin/', {
        params: {
          ...filters,
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadCourses(response, term))
      .catch(this.handleLoadCoursesError)
  }
  @action handleLoadCourses = (response, term) => {
    const { data } = response
    const { courses, coursesTotal } = humps(data)
    if (term === this.coursesSearch) {
      this.courses = courses
      this.coursesTotal = coursesTotal
      this.isLoadingCourses = false
    }
  }
  @action handleLoadCoursesError = (error) => {
    SentryReplay.captureException(error)
    this.errorLoadingCourses = 'Failed to load courses.'
    this.isLoadingCourses = false
  }
  @action loadOverview = ({ courseId } = {}) => {
    this.isLoadingOverview = true
    return request
      .get(`/reporting/${courseId}/overview`, {
        params: {
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadOverview(response))
      .catch(this.handleLoadOverviewError)
  }
  @action handleLoadOverview = (response) => {
    const { data } = response
    this.overviewData = humps(data)
    this.isLoadingOverview = false
  }
  @action handleLoadOverviewError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingOverview = false
  }

  @action loadNotifications(isRefresh) {
    if (isRefresh) {
      this.isRefreshingNotifications = true
    } else {
      this.isLoadingNotifications = true
    }
    this.loadNotificationsError = null
    return request
      .get('/admin/pending-tasks-notifications', {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadNotification)
      .catch(this.handleLoadNotificationError)
  }
  @action handleLoadNotification = (response) => {
    const { data } = response
    const { notifications } = data
    // update dates to locale
    this.notifications = humps(notifications).map((notification) => {
      const { addedAt, completed } = notification
      notification.addedAt = fromUTCToLocale(addedAt)
      notification.completed = completed ? fromUTCToLocale(completed) : null
      return notification
    })
    this.isLoadingNotifications = false
    this.isRefreshingNotifications = false
  }
  @action handleLoadNotificationError = (error) => {
    SentryReplay.captureException(error)
    this.loadNotificationsError = error
    this.isLoadingNotifications = false
    this.isRefreshingNotifications = false
  }

  @action loadSettings({ courseId }) {
    this.isLoadingSettings = true
    this.loadSettingsError = null
    return request
      .get(`/admin/${courseId}/get-course-settings`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadSettings)
      .catch(this.handleLoadSettingsError)
  }
  @action handleLoadSettings = (response) => {
    const { data } = response
    const { courseSetting } = humps(data)
    this.settings = courseSetting
    this.isLoadingSettings = false
    return this.settings
  }
  @action handleLoadSettingsError = (error) => {
    SentryReplay.captureException(error)
    this.loadSettingsError = error
    this.isLoadingSettings = false
  }
  @action updateSettings({ courseId, settings }) {
    this.isUpdatingSettings = true
    this.updateSettingsError = null
    const {
      learningMasteryRequirements,
      learningMode,
      memoryBoosterMode,
      snapshot,
    } = settings
    const POST = {
      learner_dashboard: learningMasteryRequirements,
      memory_booster_settings: memoryBoosterMode,
      required_mastery_level: learningMode,
    }
    if (snapshot !== null) {
      POST['snapshot'] = snapshot
    }
    return request
      .post(`/admin/${courseId}/update-course-settings`, POST)
      .then(this.handleUpdateSettings)
      .catch(this.handleUpdateSettingsError)
  }
  @action handleUpdateSettings = () => {
    this.isUpdatingSettings = false
    return Promise.resolve()
  }
  @action handleUpdateSettingsError = (error) => {
    SentryReplay.captureException(error)
    this.updateSettingsError = 'Error updating course settings'
    this.isUpdatingSettings = false
    return Promise.reject(new Error('Error updating course settings'))
  }

  @action loadUsers(options = {}) {
    const { filters } = options
    const { term } = filters || { term: '' }
    this.isLoadingUsers = true
    this.usersSearch = term
    return request
      .get('/admin/users', {
        params: {
          ...filters,
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadUsers(response, term))
      .catch(this.handleLoadUsersError)
  }
  @action handleLoadUsers = (response, term = '') => {
    const { data } = response
    const { data: users, total: usersTotal } = humps(data)
    if (term === this.usersSearch) {
      this.users = users || []
      this.usersTotal = usersTotal
      this.isLoadingUsers = false
    }
  }
  @action handleLoadUsersError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingUsers = false
  }

  @computed get coursesAsTableData() {
    if (!this.courses || this.courses.length === 0) return []
    return this.courses.map((c) => ({
      id: c.id,
      isAdmin: c.isAdmin,
      isDisabledMessaging: c.disableMessaging,
      isInstructor: c.isInstructor,
      items: [
        c.title,
        c.id,
        c.status === 'Suspended' && c.liveStatus !== 'Offline'
          ? c.status
          : c.liveStatus,
        c.learnersCount,
      ],
      liveStatus: c.liveStatus,
    }))
  }
  @computed get usersAsTableData() {
    if (!this.users || this.users.length === 0) return []
    return this.users.map((user) => ({
      courseId: user.courseId,
      courseTitle: user.courseTitle,
      email: user.email,
      firstName: user.firstName,
      id: user.id,
      items: [
        `${user.firstName} ${user.lastName}`,
        user.email,
        user.courseTitle,
        user.role
          ? `${user.role.charAt(0).toUpperCase()}${user.role.slice(1)}`
          : 'None',
      ],
      lastName: user.lastName,
      role: { type: user.role },
      roleId: user.roleId,
      roleStatus: user.roleStatus,
    }))
  }

  @action resetInstance() {
    this.instance = new Instance()
  }
  @action loadInstance(instanceId) {
    this.resetInstance()
    return this.loadCourses({ filters: { course_id: instanceId } })
      .then(() => {
        const course =
          this.courses.filter(
            (course) => course.id === parseInt(instanceId),
          )[0] || null
        if (course) {
          const {
            adminsCount,
            contentfulId,
            endDate,
            instructorsCount,
            isAdmin,
            isInstructor,
            learnersCount,
            liveStatus,
            status,
            title,
          } = course
          this.instance = new Instance({
            id: instanceId,
            adminsCount: adminsCount,
            contentfulId: contentfulId,
            endDate: endDate,
            instructorsCount: instructorsCount,
            isAdmin: isAdmin,
            isInstructor: isInstructor,
            learnersCount: learnersCount,
            liveStatus: liveStatus,
            status: status,
            title: title,
          })
          this.isCourseNotFound = false
        } else {
          this.isCourseNotFound = true
        }
      })
      .catch((error) => {
        SentryReplay.captureException(error)
      })
  }
  @action loadReportExportURL = ({ courseId, expired = false }) => {
    this.isLoadingReportExportURL = true
    let params = { d: Date.now() }
    if (expired) {
      params['expired'] = 1
    }
    return request
      .get(`/reporting/${courseId}/export`, { params: params })
      .then(this.handleLoadReportExport)
      .catch(this.handleLoadReportExportError)
  }
  @action handleLoadReportExport = (response) => {
    this.isLoadingReportExportURL = false
    const { data } = response || {}
    const { course_url_v2: reportExportURL } = data || {}
    return { url: reportExportURL }
  }
  @action handleLoadReportExportError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingReportExportURL = false
    return { error: error.response }
  }

  @action loadUnreadMessagesCount() {
    if (!this.session) {
      if (this.loadUnreadMessagesCountIntervalId) {
        return this.stopLoadUnreadMessagesCountInterval()
      }
      return Promise.resolve()
    }
    const { courseId } = this.session
    this.isLoadingUnreadMessagesCount = true
    return request
      .get(`/messages/${courseId}/counts?d=${Date.now()}`)
      .then(this.handleLoadUnreadMessagesCount)
      .catch(this.handleLoadUnreadMessagesCountError)
  }
  @action handleLoadUnreadMessagesCount = (response) => {
    const { data } = response
    const result = humps(data)
    this.isLoadingUnreadMessagesCount = false
    return result
  }
  @action handleLoadUnreadMessagesCountError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingUnreadMessagesCount = false
    return false
  }

  @action startLoadUnreadMessagesCountInterval = (callback) => {
    if (this.loadUnreadMessagesCountIntervalId) {
      return
    }
    this.loadUnreadMessagesCountIntervalId = setInterval(() => {
      this.loadUnreadMessagesCount().then((unreadMessagesCount) => {
        if (callback) {
          callback(unreadMessagesCount)
        }
      })
    }, REFRESH_RATE * 12) // 5000 * 12 === 60000 === 1min
    return this.loadUnreadMessagesCountIntervalId
  }
  @action stopLoadUnreadMessagesCountInterval = () => {
    clearInterval(this.loadUnreadMessagesCountIntervalId)
    this.loadUnreadMessagesCountIntervalId = null
    return this.loadUnreadMessagesCountIntervalId
  }
}
