import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import _ from 'lodash'
import moment from 'moment'
import cx from 'classnames'

import {
  Button,
  HoverTooltip,
  Link,
  Loader,
  Progress,
  SortablePaginatedSearchTable,
  Toggler,
} from '@/components'
import { CheckboxCustom } from '@/components/form'
import { STATUS_TESTED_OUT } from '@/constants'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import './InstanceAnalyticsPage.scss'

const getTimeSpentText = (timeSpent) => {
  if (!timeSpent) return '0m'
  const minutes = timeSpent / 60
  if (minutes < 60) return `${Math.max(Math.round(minutes), 1)}m`
  const hours = minutes / 60
  const remainderMin = minutes % 60
  return `${Math.floor(hours)}h ${Math.round(remainderMin)}m`
}

const initialPage = { offset: 0, limit: 25 }
const initialFilters = { ...initialPage }

const performanceTooltip =
  'Scores, confidence and behavior are analyzed to surface the risk of not applying the material covered. Unengaged learners are flagged because of poor performance.'

const getRankText = (rank) => {
  if (!rank || rank === null || rank === 'unkn') return 'Not Enough Progress'
  if (rank.toLowerCase() === 'good') return 'Proficient'
  if (rank.replace('-', ' ').toLowerCase() === 'at risk') return 'Unengaged'
  if (rank === STATUS_TESTED_OUT) return 'Tested Out'
  return rank.replace('-', ' ').replace(/\b\w/g, (l) => l.toUpperCase())
}

const getProgressText = (progress) => {
  return progress.includes('-')
    ? 'No Progress'
    : progress[0] === 'p'
    ? 'In Progress'
    : 'Completed'
}

const getFilteredByFilterText = (key, filterText) => {
  if (key === 'rank') {
    return getRankText(filterText)
  } else if (key === 'progress') {
    return getProgressText(filterText)
  }
  return filterText
}

const getFilterLabelText = (label) => {
  if (label === 'Job Code / Role') return 'Job Code'
  if (label === 'Other Label') return 'Label'
  return label
}

@inject('adminStore', 'analyticsStore', 'uiStore')
@observer
export default class InstanceAnalyticsPage extends Component {
  state = {
    activeFilter: null,
    showFilters: false,
  }
  constructor(props) {
    super(props)
    const { adminStore, history, location, match } = this.props
    const { instance } = adminStore
    const { analyticsFilters } = instance
    const { search } = location
    const filters = qs.parse(search)
    let field1 = filters['field1']
    let field2 = filters['field2']
    let field3 = filters['field3']
    let rank = filters['rank']
    let progress = filters['progress']
    if (field1) {
      filters['field1'] = field1.split(',')
    }
    if (field2) {
      filters['field2'] = field2.split(',')
    }
    if (field3) {
      filters['field3'] = field3.split(',')
    }
    if (rank) {
      filters['rank'] = rank.split(',')
    }
    if (progress) {
      filters['progress'] = progress.split(',')
    }
    const updatedFilters = instance.setAnalyticsFilters({
      ...initialFilters,
      ...analyticsFilters,
      ...filters,
    })
    const querystring = { ...updatedFilters }
    Object.keys({ ...querystring }).forEach((key) => {
      const filterValue = instance.getAnalyticsFilter(key)
      querystring[key] = Array.isArray(filterValue)
        ? filterValue.join(',')
        : filterValue
    })
    history.push(`${match.url}?${qs.stringify(querystring)}`)
  }
  get showingDescription() {
    const { adminStore, analyticsStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters: filters } = instance
    const { report } = analyticsStore
    if (!report) return null
    const { learners, learnersMeta } = report
    const { offset } = filters
    const nStart = parseInt(offset) + 1
    const nEnd = parseInt(offset) + learners.length
    return learners.length
      ? `${nStart}-${nEnd} of ${learnersMeta.count} Learners`
      : '0 Learners'
  }
  get hasFilters() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters } = instance
    let filters = _.omit(analyticsFilters, Object.keys(initialPage).concat('q'))
    for (var key in filters) {
      if (Array.isArray(filters[key]) && filters[key].length === 0) {
        delete filters[key]
      }
    }
    return !_.isEmpty(_.filter(filters, (f) => f && f.length !== 0))
  }
  get hasLearners() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { learnersCount } = instance
    return learnersCount > 0
  }
  componentDidMount() {
    const { adminStore, analyticsStore, match, uiStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters: filters, analyticsSort } = instance
    const { params } = match
    const { instanceId } = params
    uiStore.updateTitle('Course Analytics Page')

    this.courseId = instanceId
    analyticsStore
      .loadCourse({ courseId: this.courseId, filters: { ...initialPage } })
      .then(() => {
        analyticsStore.loadLearners({
          courseId: this.courseId,
          filters,
          sorting: analyticsSort,
        })
      })
  }
  filter = (nextFilters, { clear } = {}) => {
    const { adminStore, history, match } = this.props
    const { instance } = adminStore
    const filters = instance.setAnalyticsFilters(nextFilters, { clear })
    const querystring = { ...filters }
    Object.keys({ ...querystring }).forEach((key) => {
      const filterValue = instance.getAnalyticsFilter(key)
      querystring[key] = Array.isArray(filterValue)
        ? filterValue.join(',')
        : filterValue
    })
    history.push(`${match.url}?${qs.stringify(querystring)}`)
    this.searchLearners(filters)
  }
  isFilterChecked = (name, value) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters: filters } = instance
    const filter = filters[name]
    if (!filter) return false
    return filter.indexOf(value) !== -1
  }
  onAccordionChange = (activeFilter) => {
    this.setState({ activeFilter })
  }
  onChangeLimit = (limit) => {
    this.filter({ limit })
  }
  onClearFilters = (event) => {
    event.preventDefault()
    this.filter(initialFilters, { clear: true })
  }
  onClearSearchFilter = () => {
    this.filter({ q: '', ...initialPage })
  }
  onFilterChange = (event) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { target } = event
    let { checked, name, type, value } = target

    if (type === 'checkbox' || name === 'rank') {
      let targetFilter = instance.getAnalyticsFilter(name)
      if (!targetFilter) {
        value = [value]
      } else if (!Array.isArray(targetFilter)) {
        value = [value]
      } else if (targetFilter.indexOf(value) === -1) {
        value = [value, ...targetFilter]
      } else {
        value = targetFilter.filter((v) => v !== value)
      }
    } else if (type === 'radio') {
      value = checked ? value : null
    }

    const filter = { [name]: value }
    this.filter({ ...filter, ...initialPage })
  }
  onLearnerClick = (e, learnerId) => {
    const { match, history } = this.props
    e.preventDefault()
    history.push(`${match.url}/learners/${learnerId}`)
  }
  onPageClick = (pageIndex) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const limit = instance.getAnalyticsFilter('limit')
    this.filter({ offset: pageIndex * limit })
  }
  onToggleFilters = (value) => {
    this.setState({ showFilters: value })
  }
  searchLearners = _.debounce(
    (filters) => {
      const { adminStore, analyticsStore } = this.props
      const { instance } = adminStore
      const { analyticsSortBy, analyticsSortOrientation } = instance
      const sorting = {
        sortBy: analyticsSortBy,
        sortOrientation: analyticsSortOrientation,
      }
      analyticsStore.loadLearners({
        courseId: this.courseId,
        filters,
        sorting,
      })
    },
    250,
    { leading: true, trailing: true },
  )
  onCancelFilter = (event, filterKey, filterValue) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters: filters } = instance
    event.preventDefault()
    filters[filterKey] = filters[filterKey].filter((f) => f !== filterValue)
    this.filter({ ...filters, ...initialPage })
  }
  onSortTable = (nextSortBy) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters: filters } = instance
    instance.setAnalyticsSort(nextSortBy)
    this.filter({ ...filters, offset: 0 })
  }
  stopPropagation = (event) => {
    event.stopPropagation()
  }
  renderSidebar() {
    return this.hasLearners ? (
      <AdminLayout.Sidebar>
        <Toggler
          icon="tasks"
          className="InstanceAnalyticsPage-toggler"
          text="Close filter criteria"
          altText="Show filter criteria"
          onChange={this.onToggleFilters}
          isActive={this.state.showFilters}
        >
          <div className="InstanceAnalyticsPage__filters">
            {this.renderPerformanceFilters()}
            {this.renderProgressFilters()}
            {this.renderLearnersFilters()}
          </div>
        </Toggler>
      </AdminLayout.Sidebar>
    ) : null
  }
  renderPerformanceFilters() {
    const { analyticsStore } = this.props
    const { report } = analyticsStore
    return (
      <div className="InstanceAnalyticsPage-filterPerformance">
        <div className="InstanceAnalyticsPage-filterTitle">
          Status
          <HoverTooltip
            id="InstanceOverviewPage__filter-title-tooltip"
            text={performanceTooltip}
            trigger={['focus', 'hover']}
          >
            <Button
              aria-describedby="InstanceOverviewPage__filter-title-tooltip"
              isText
            >
              <i className="fa fa-info-circle" />
            </Button>
          </HoverTooltip>
        </div>
        <div className="control is-expanded InstanceAnalyticsPage__filter-option">
          {report ? (
            report.ranksAsOptions.map((rank) => {
              if (rank.value === STATUS_TESTED_OUT && rank.count === 0)
                return null
              return (
                <CheckboxCustom
                  checked={this.isFilterChecked('rank', rank.value)}
                  className={`InstanceAnalyticsPage__filter-option--is-${rank.value}`}
                  isBlock
                  key={`InstanceAnalyticsPage__filter-option--is-${rank.value}`}
                  name="rank"
                  onChange={this.onFilterChange}
                  secondaryText={`(${rank.count})`}
                  text={getRankText(rank.text)}
                  value={rank.value}
                />
              )
            })
          ) : (
            <div>
              <p>No available values</p>
            </div>
          )}
        </div>
      </div>
    )
  }
  renderProgressFilters() {
    const { report } = this.props.analyticsStore
    const progressFilters = report
      ? report.filters.filter((filter) => filter.id === 'progress')
      : []
    let sortedProgressFilters = []
    const sortedProgressValues = {
      'no-progress': null,
      progress: null,
      completed: null,
    }
    if (progressFilters[0] && progressFilters[0].values.length) {
      progressFilters[0].values.forEach((progress) => {
        sortedProgressValues[progress.value] = progress
      })
    }
    for (var key in sortedProgressValues) {
      if (sortedProgressValues[key]) {
        sortedProgressFilters.push(sortedProgressValues[key])
      } else {
        sortedProgressFilters.push({ value: key, count: 0 })
      }
    }
    return (
      <div className="InstanceAnalyticsPage-filterProgress">
        <div className="InstanceAnalyticsPage-filterTitle">Progress</div>
        <div className="control is-expanded InstanceAnalyticsPage__filter-option">
          {sortedProgressFilters.length ? (
            sortedProgressFilters.map((progress) => (
              <CheckboxCustom
                checked={this.isFilterChecked('progress', progress.value)}
                isBlock
                key={`InstanceAnalyticsPage__filter-option--is-${progress.value}`}
                name="progress"
                onChange={this.onFilterChange}
                secondaryText={`(${progress.count})`}
                text={getProgressText(progress.value)}
                value={progress.value}
              />
            ))
          ) : (
            <div>
              <p>No available values</p>
            </div>
          )}
        </div>
      </div>
    )
  }
  renderLearnersFilters() {
    const { report } = this.props.analyticsStore
    let isEmptyFilters = true
    if (report && _.isEmpty(report.filters)) return null
    if (report) {
      report.filtersAsOptions.forEach((filter) => {
        if (filter.values.length) {
          isEmptyFilters = false
        }
      })
    }
    if (isEmptyFilters) return null
    return (
      <div className="InstanceAnalyticsPage-filter">
        {report.filtersAsOptions
          .filter((filter) => filter.id !== 'progress')
          .map((filter) => {
            return filter.values.length ? (
              <div
                id={`InstanceAnalyticsPage-filter-${filter.id}`}
                className="InstanceAnalyticsPage__filter-option"
                key={`InstanceAnalyticsPage-filter-${filter.id}`}
              >
                <h1 className="InstanceAnalyticsPage-filterTitle">
                  {getFilterLabelText(filter.label)}
                </h1>
                <div className="InstanceAnalyticsPage__filter-option-items">
                  {filter.values.map((filterValue) => (
                    <CheckboxCustom
                      checked={this.isFilterChecked(
                        filter.id,
                        filterValue.value,
                      )}
                      isBlock
                      key={`InstanceAnalyticsPage__filter-option-items--${filterValue.value}`}
                      name={filter.id}
                      onChange={this.onFilterChange}
                      secondaryText={`(${filterValue.count})`}
                      text={filterValue.text}
                      value={filterValue.value}
                    />
                  ))}
                </div>
              </div>
            ) : null
          })}
      </div>
    )
  }
  renderFilteredBy() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { analyticsFilters } = instance
    const filters = _.omit(
      analyticsFilters,
      Object.keys(initialPage).concat('q'),
    )
    let renderedFilters = []
    let uniqKey = 0
    for (var key in filters) {
      for (let i = 0; i < filters[key].length; i++) {
        let filteredByFilterText = getFilteredByFilterText(key, filters[key][i])
        renderedFilters.unshift(
          <div
            key={`${filters[key][i]}-${uniqKey}`}
            className="InstanceAnalyticsPage__filtered-by-filter"
          >
            {filteredByFilterText}
            <a
              aria-label={`Remove ${key} filter: ${filteredByFilterText}`}
              className="InstanceAnalyticsPage__filtered-by-cancel"
              data-filter={`${key};${filters[key][i]}`}
              href="#"
              onClick={(event) =>
                this.onCancelFilter(event, key, filters[key][i])
              }
              role="button"
            >
              <i className="fa fa-close" />
            </a>
          </div>,
        )
        uniqKey++
      }
    }

    return this.hasFilters ? (
      <div className="InstanceAnalyticsPage__filtered-by">
        <strong className="InstanceAnalyticsPage__filtered-by-label">
          Filtered By:
        </strong>
        <div className="InstanceAnalyticsPage__filtered-by-filters">
          {renderedFilters}
          {renderedFilters.length >= 2 ? (
            <a
              className="InstanceAnalyticsPage__filtered-by-clear"
              href="#"
              onClick={this.onClearFilters}
              role="button"
            >
              Clear All
            </a>
          ) : null}
        </div>
      </div>
    ) : null
  }
  renderLearnersList() {
    const { adminStore, analyticsStore, match } = this.props
    const { report, isLoadingLearners } = analyticsStore
    const hasLearnerData = report && !_.isEmpty(report.learners)
    const { instance } = adminStore
    const { analyticsFilters: filters, analyticsSort } = instance
    const { limit, offset } = filters
    const {
      sortBy: analyticsSortBy,
      sortOrientation: analyticsSortOrientation,
    } = analyticsSort
    const total = report && report.learnersMeta ? report.learnersMeta.count : 0
    return (
      <Fragment>
        {!isLoadingLearners && this.renderFilteredBy()}
        <SortablePaginatedSearchTable
          className="InstanceAnalyticsPage__learners-table"
          headers={[
            { sortBy: 'first_name', headerText: 'Name' },
            { sortBy: 'completion', headerText: 'Progress' },
            { sortBy: 'last_activity', headerText: 'Last Activity' },
            { sortBy: 'time_spent', headerText: 'Time Spent' },
            { sortBy: 'rank', headerText: 'Status' },
          ]}
          id="InstanceAnalyticsPageLearnersTable"
          isLoading={isLoadingLearners}
          offset={parseInt(offset)}
          onChangeLimit={this.onChangeLimit}
          onChangeSearch={this.onFilterChange}
          onClearSearch={this.onClearSearchFilter}
          onPageClick={this.onPageClick}
          onSort={this.onSortTable}
          limit={parseInt(limit)}
          rows={
            hasLearnerData
              ? report.learners.map((learner, index) => {
                  const {
                    completion,
                    fullName,
                    id: learnerId,
                    lastActivity,
                    lastActivitySection,
                    lastActivityUnit,
                    rank,
                    testedOut,
                    timeSpent,
                  } = learner
                  return {
                    items: [
                      <Link
                        className="InstanceAnalyticsPage__learners-table-name"
                        key={`"InstanceAnalyticsPage__learners-table-name-${index}"`}
                        onClick={this.stopPropagation}
                        to={`${match.url}/learners/${learnerId}`}
                      >
                        {fullName.replace(/\b\w/g, (l) => l.toUpperCase())}
                      </Link>,
                      <div
                        key={`"InstanceAnalyticsPage__learners-table-progress-${index}"`}
                      >
                        <Progress
                          className="InstanceAnalyticsPage__learners-table-progress"
                          isAriaHidden
                          value={
                            completion && completion !== 0 ? completion : 0.001
                          }
                        />
                        <span className="InstanceAnalyticsPage__learners-table-progress-text">{`${completion}% Progress`}</span>
                      </div>,
                      <span
                        key={`"InstanceAnalyticsPage__learners-table-last-activity-${index}"`}
                      >
                        {lastActivity
                          ? `${moment
                              .utc(lastActivity)
                              .local()
                              .format('M/D/YYYY')}${
                              lastActivityUnit && lastActivitySection
                                ? ` (Unit ${lastActivityUnit}, Section ${lastActivitySection})`
                                : ''
                            }`
                          : "Hasn't Logged In"}
                      </span>,
                      <span
                        key={`"InstanceAnalyticsPage__learners-table-time-spent-${index}"`}
                      >
                        {getTimeSpentText(timeSpent)}
                      </span>,
                      <span
                        key={`"InstanceAnalyticsPage__learners-table-rank-${index}"`}
                      >
                        {rank && rank !== 'unkn' && !testedOut ? (
                          <i
                            aria-hidden="true"
                            className={cx(
                              'InstanceAnalyticsPage__rank-icon fa fa-circle',
                              {
                                'is-danger': rank === 'at-risk',
                                'is-info': rank === 'good',
                                'is-success': rank === 'all-star',
                              },
                            )}
                          />
                        ) : null}
                        {getRankText(rank)}
                      </span>,
                    ],
                    onRowClick: (e) => this.onLearnerClick(e, learnerId),
                  }
                })
              : []
          }
          showingText="Learners"
          searchLabel="Search learners"
          searchLabelPosition="before"
          searchPlaceholder="Search learners..."
          searchTerm={filters.q || ''}
          sortBy={analyticsSortBy}
          sortAscending={analyticsSortOrientation === 'asc'}
          total={total}
        />
        {!isLoadingLearners && !hasLearnerData ? (
          <div className="title InstanceAnalyticsPage-empty">
            No learners found.
          </div>
        ) : null}
      </Fragment>
    )
  }
  renderMain() {
    return (
      <AdminLayout.Main id="main-content" isFullWidth={!this.hasLearners}>
        {this.hasLearners ? this.renderLearnersList() : null}
        {!this.hasLearners ? this.renderNoLearners() : null}
      </AdminLayout.Main>
    )
  }
  renderNoLearners() {
    return (
      <div className="InstanceAnalyticsPage-noLearners">
        <p>You have 0 learners in this course.</p>
        <br />
        <p>
          Once learners start their adaptive journey through the course, this
          tab will house details about their progress. You’ll be able to use
          Fulcrum’s AI to see who is proficient in the material, who is
          unengaged, and who your all stars are.
        </p>
      </div>
    )
  }
  render() {
    const { analyticsStore } = this.props
    const { isLoadingCourse, loadingError } = analyticsStore

    if (loadingError) {
      return (
        <AdminLayout.Box>
          <div className="has-text-centered">{loadingError}</div>
        </AdminLayout.Box>
      )
    }

    if (isLoadingCourse) {
      return (
        <AdminLayout.Box>
          <Loader />
        </AdminLayout.Box>
      )
    }

    return (
      <AdminLayout className="InstanceAnalyticsPage">
        {this.renderSidebar()}
        {this.renderMain()}
      </AdminLayout>
    )
  }
}
