import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { Heading, Modal } from '@/components'

import './FirstSectionMessageModal.scss'

const FirstSectionMessageModalMessages = defineMessages({
  bodyLine1: {
    defaultMessage: 'Jump directly into whichever tab you want:',
    description:
      'Description displayed in the first section message modal when a user enters a section for the first time when starting a course (line 1).',
    id: 'FirstSectionMessageModal.bodyLine1',
  },
  bodyLine2: {
    defaultMessage:
      '1. Practice is where you answer questions to complete this Section.',
    description:
      'Description displayed in the first section message modal when a user enters a section for the first time when starting a course (line 2).',
    id: 'FirstSectionMessageModal.bodyLine2',
  },
  bodyLine3: {
    defaultMessage:
      '2. You can start learning about this topic on the Read or Watch tabs.',
    description:
      'Description displayed in the first section message modal when a user enters a section for the first time when starting a course (line 3).',
    id: 'FirstSectionMessageModal.bodyLine3',
  },
  bodyLine4: {
    defaultMessage:
      'Remember, you are free to jump to any other tab whenever, wherever.',
    description:
      'Description displayed in the first section message modal when a user enters a section for the first time when starting a course (line 4).',
    id: 'FirstSectionMessageModal.bodyLine4',
  },
  okay: {
    defaultMessage: 'OK',
    description: 'Button to close the first section message modal.',
    id: 'FirstSectionMessageModal.okay',
  },
  practice: {
    defaultMessage: 'Practice',
    description:
      'Button to navigate to the practice tab of a sectionn from the first section message modal.',
    id: 'FirstSectionMessageModal.practice',
  },
  title: {
    defaultMessage: `You're in control`,
    description:
      'Title displayed in the first section message modal when a user enters a section for the first time when starting a course.',
    id: 'FirstSectionMessageModal.title',
  },
})

@injectIntl
@inject('uiStore')
export default class FirstSectionMessageModal extends Component {
  onClose = () => {
    const { uiStore, onClose } = this.props
    if (onClose) {
      onClose()
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  render() {
    const { intl, isOpen, practiceTabURL } = this.props
    const { formatMessage } = intl
    return (
      <Modal
        className="FirstSectionMessageModal"
        contentLabel="First Section Modal"
        isCard
        isOpen={isOpen}
        scrollTop
      >
        <Modal.Header>
          <Heading>
            {formatMessage(FirstSectionMessageModalMessages.title)}
          </Heading>
        </Modal.Header>
        <Modal.Body qa-id="learner-first-section-modal">
          <span>
            {formatMessage(FirstSectionMessageModalMessages.bodyLine1)}
          </span>
          <br />
          <span>
            {formatMessage(FirstSectionMessageModalMessages.bodyLine2)}
          </span>
          <br />
          <span>
            {formatMessage(FirstSectionMessageModalMessages.bodyLine3)}
          </span>
          <br />
          <br />
          <span>
            {formatMessage(FirstSectionMessageModalMessages.bodyLine4)}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button
            isLink
            isSecondary
            onClick={this.onClose}
            qa-id="learner-first-section-modal-confirm"
            to={practiceTabURL}
          >
            {formatMessage(FirstSectionMessageModalMessages.practice)}
          </Modal.Button>
          <Modal.Button onClick={this.onClose}>
            {formatMessage(FirstSectionMessageModalMessages.okay)}
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
