import { observable, computed, action, toJS } from 'mobx'
import { toCamelCase, toSnakeCase } from '@/utils/helpers'
import { ACTIVITY_TYPES } from '@/constants'
import { replaceMathFormulas } from '@/utils/math'
import _ from 'lodash'

const PHRASE = 'PHRASE'
const DELIMITER = '||'

const getTransform = (values) => {
  return values.reduce(
    (total, item) => {
      total.x += item[1]
      total.y += item[2]
      return total
    },
    { x: 0, y: 0 },
  )
}

const TypeMap = {
  multiple_choice: ACTIVITY_TYPES.multipleChoice,
  hot_spot: ACTIVITY_TYPES.hotspot,
  activityDragPhrase: ACTIVITY_TYPES.dragPhrase,
  sort_stack: ACTIVITY_TYPES.sortStack,
  matching: ACTIVITY_TYPES.matching,
  text_input: ACTIVITY_TYPES.textInput,
  textInputInteger: ACTIVITY_TYPES.numericInput,
}

export default class Activity {
  @observable id
  @observable type
  @observable title

  @observable isNewActivity

  @observable question
  @observable questionImageInfo
  @observable imageAlignment
  @observable goodToKnow
  @observable isReview
  // NOTE / TODO / WIP - disabled due to https://headware4.jira.com/browse/FM-2942
  // @observable isReviewIntro = true
  @observable isReviewIntro = false
  @observable passed
  @observable answerResponse
  @observable answerResponseLos
  @observable answersCounter
  @observable correctAnswers
  @observable incorrectAnswers
  @observable hint
  @observable attempt
  @observable skipped
  @observable validationError

  // for inline text/numeric input
  @observable textBeforeInput
  @observable textAfterInput

  // for multiple choice
  @observable choiceType
  @observable choices

  // for matching && sorting
  @observable pairs

  // for dragphrase
  @observable content
  @observable categories
  @observable draggableItems

  // for hotspot
  @observable regions
  @observable expected

  // flag for goodToKnow math replace
  @observable goodToKnowReplaced = false

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
    this.replaceActivityKalturaUrls()
  }

  toSnakeCase() {
    const { id } = this
    return toSnakeCase({ id })
  }

  replaceQuestionMathFormulas() {
    replaceMathFormulas({
      content: this.question,
    }).then((content) => {
      this.question = content
    })
  }

  replaceActivitiesMathFormulas() {
    if (
      this.activityType === ACTIVITY_TYPES.multipleChoice ||
      this.activityType === ACTIVITY_TYPES.singleChoice
    ) {
      _.forEach(this.choices, (choice) => {
        replaceMathFormulas({
          content: choice.content,
        }).then((content) => {
          choice.content = content
        })
      })
    }

    if (
      this.activityType === ACTIVITY_TYPES.numericInput ||
      this.activityType === ACTIVITY_TYPES.textInput
    ) {
      replaceMathFormulas({
        content: this.textBeforeInput,
      }).then((content) => {
        this.textBeforeInput = content
      })

      replaceMathFormulas({
        content: this.textAfterInput,
      }).then((content) => {
        this.textAfterInput = content
      })
    }

    if (
      this.activityType === ACTIVITY_TYPES.matching ||
      this.activityType === ACTIVITY_TYPES.sortStack
    ) {
      const pairs = toJS(this.pairs)
      pairs.labels.forEach((label) => {
        replaceMathFormulas({
          content: label.content,
        }).then((content) => {
          label.content = content
          this.pairs = pairs
        })
      })

      pairs.definitions.forEach((def) => {
        replaceMathFormulas({
          content: def.content,
        }).then((content) => {
          def.content = content
          this.pairs = pairs
        })
      })
    }
  }

  @action replaceActivityMathFormulas = () => {
    this.replaceQuestionMathFormulas()
    this.replaceActivitiesMathFormulas()
  }

  @action replaceHintMathFormulas() {
    replaceMathFormulas({
      content: this.hint,
    }).then((content) => {
      this.hint = content
    })
  }

  @action replaceGoodToKnowMathFormulas() {
    if (this.goodToKnowReplaced) return

    replaceMathFormulas({
      content: this.goodToKnow,
    }).then((content) => {
      this.goodToKnowReplaced = true
      this.goodToKnow = content
    })
  }

  @action replaceActivityKalturaUrls() {
    // hotspot activity image
    if (!this.questionImageInfo || !this.questionImageInfo.src) return
    this.questionImageInfo.src = this.questionImageInfo.src.replace(
      new RegExp('http://cdnbakmi', 'g'),
      'https://cdnsecakmi',
    )
  }

  @computed get currentHint() {
    const {
      answerResponse,
      hint: currentActivityHint,
      isDragPhrase,
      isMatching,
      isMultipleChoice,
      isMultipleInput,
      isNumericInput,
      isSingleChoice,
      isSortStack,
      isTextInput,
    } = this
    let answerHints = []
    let hasGenericHint = false
    if (answerResponse) {
      if (
        ((isMatching || isSortStack) && !currentActivityHint) ||
        isNumericInput ||
        isSingleChoice ||
        isTextInput
      ) {
        for (let i = 0; i < answerResponse.length; i++) {
          let answerRes = answerResponse[i] || {}
          let answerHint = answerRes.hint
          if (answerHint) {
            answerHints.push(answerHint)
            if (!isMultipleInput) {
              break
            }
          }
        }
      } else if (isDragPhrase || isMultipleChoice) {
        const answerResponseHints = {}
        for (let i = 0; i < answerResponse.length; i++) {
          let answerRes = answerResponse[i] || {}
          let answerHint = answerRes.hint
          let answerValue = isDragPhrase
            ? answerRes.value.trim()
            : answerRes.value
          if (answerHint) {
            if (!answerResponseHints[answerValue]) {
              answerResponseHints[answerValue] = isDragPhrase
                ? `${answerValue} - ${answerHint}`
                : answerHint
              if (isDragPhrase) {
                answerHints.push(answerResponseHints[answerValue])
              }
            }
          } else if (!hasGenericHint && currentActivityHint) {
            // If an answer has no specific hint
            // make the generic hint appear at the top once
            answerHints.unshift(currentActivityHint)
            hasGenericHint = true
          }
        }
        if (isMultipleChoice) {
          // Hints come back in the order the answers were selected
          // and need to be reordered to match the order of choices
          this.choices.forEach((c, i) => {
            if (answerResponseHints[c.id]) {
              answerHints.push(`${i + 1}. ${answerResponseHints[c.id]}`)
            }
          })
        }
      }
    }
    if (answerHints.length) {
      if (isMultipleInput) {
        // Remove duplicates
        answerHints = answerHints.filter((v, i, a) => a.indexOf(v) === i)
        return answerHints
      }
      return answerHints
    }
    return currentActivityHint ? [currentActivityHint] : []
  }
  @computed get hasHint() {
    return !!(!this.passed && this.currentHint.length)
  }

  @computed get hasPassed() {
    return this.passed === true
  }

  @computed get hasNotPassed() {
    return this.passed === false
  }

  @computed get hotSpotData() {
    if (!this.questionImageInfo) return

    const transform = getTransform(this.questionImageInfo.transform)
    const imageTransform = transform
      ? `matrix(1,0,0,1,${transform.x},${transform.y})`
      : 'matrix(1,0,0,1,0,0)'

    const regions = this.regions.map((region) => {
      const paths = region.path.map((points) => points.join(' '))
      const t = getTransform(region.transform)
      return {
        ...region,
        id: region.id,
        path: paths.join(' '),
        transform: `matrix(1,0,0,1,${t.x},${t.y})`,
      }
    })

    return {
      numberOfPins: this.expected,
      imageSrc: this.questionImageInfo.src,
      imageWidth: this.questionImageInfo.width,
      imageHeight: this.questionImageInfo.height,
      imageX: this.questionImageInfo.x,
      imageY: this.questionImageInfo.y,
      imageTransform,
      regions,
    }
  }

  @computed get activityType() {
    const type = TypeMap[this.type]
    if (type === ACTIVITY_TYPES.multipleChoice && this.choiceType === 'radio') {
      return ACTIVITY_TYPES.singleChoice
    }
    return type
  }

  @computed get cleanedChoices() {
    if (!this.choices) return []
    return this.choices.map((choice) => ({
      ...choice,
      content: choice.content, // .replace(/(\s+)?<br\s?\/>/g, '')
    }))
  }

  @computed get contentBasedOnDraggableItems() {
    let content = this.content.replace(/({{|}})/gim, '')
    this.draggableItems.forEach((item, index) => {
      content = content.replace(item.title, `${DELIMITER}${index}${DELIMITER}`)
    })
    return content
      .split(DELIMITER)
      .filter((c) => c.trim() !== '')
      .map((text) => {
        const index = Number(text)
        return isNaN(index)
          ? { isPhrase: false, text }
          : { isPhrase: true, text: this.draggableItems[index].title }
      })
  }

  @computed get cleanedContent() {
    if (!this.content) return []

    // if we have draggable items, we just need to show the content
    if (this.draggableItems) {
      return this.contentBasedOnDraggableItems
    }

    let phrases = []
    const result = this.content
      .replace(/{{(.*?)}}/gi, (m, g1) => {
        phrases.push(g1)
        return `${DELIMITER}${PHRASE}${DELIMITER}`
      })
      .split(DELIMITER)
    let index = 0
    return result.map((text) => ({
      isPhrase: text === PHRASE,
      text: text === PHRASE ? phrases[index++] : text,
    }))
  }

  @computed get isMultipleChoice() {
    return this.activityType === ACTIVITY_TYPES.multipleChoice
  }
  @computed get isSingleChoice() {
    return this.activityType === ACTIVITY_TYPES.singleChoice
  }
  @computed get isHotspot() {
    return this.activityType === ACTIVITY_TYPES.hotspot
  }
  @computed get isDragPhrase() {
    return this.activityType === ACTIVITY_TYPES.dragPhrase
  }
  @computed get isSortStack() {
    return this.activityType === ACTIVITY_TYPES.sortStack
  }
  @computed get isMatching() {
    return this.activityType === ACTIVITY_TYPES.matching
  }
  @computed get isMultipleInput() {
    let questionStem = null
    if (this.activityType === ACTIVITY_TYPES.textInput) {
      questionStem = this.question
    } else if (this.activityType === ACTIVITY_TYPES.numericInput) {
      questionStem = this.textBeforeInput
    }
    if (
      questionStem &&
      questionStem
        .split(/(__+)/)
        .filter((segment) => !!segment && segment.match(/__+/)).length > 1
    )
      return true
    return false
  }
  @computed get isTextInput() {
    return this.activityType === ACTIVITY_TYPES.textInput
  }
  @computed get isNumericInput() {
    return this.activityType === ACTIVITY_TYPES.numericInput
  }

  adaptAnswer(answer) {
    if (this.isHotspot) {
      return _.compact(answer.map((item) => item.regionId))
    }

    if (this.isSortStack) {
      return answer.map((item, index) => ({
        label: item.id,
        definition: this.pairs.definitions[index].id,
      }))
    }

    if (this.isMatching) {
      return answer.map((pair) => ({
        definition: pair.definitionId,
        label: pair.labelId,
      }))
    }

    if (this.isMultipleInput) {
      return Object.keys(answer).map((index) => ({
        index: parseInt(index),
        value: answer[index],
      }))
    }

    return answer
  }

  getAnswersCountTotals(answer, isOpenIncompleteQuestionModal) {
    let answerLength = answer && answer.length
    let numAnswersIncomplete
    let numAnswersTotal
    if (isOpenIncompleteQuestionModal && answer) {
      if (
        this.activityType === ACTIVITY_TYPES.sortStack ||
        this.activityType === ACTIVITY_TYPES.matching
      ) {
        const items = this.pairs && this.pairs.labels
        numAnswersIncomplete = items.length - answerLength
        numAnswersTotal = items.length
      }
      if (this.activityType === ACTIVITY_TYPES.dragPhrase) {
        answerLength =
          answer &&
          answer.reduce(
            (total, categories) => total + categories.phrases.length,
            0,
          )
        if (typeof this.draggableItems === 'object') {
          numAnswersIncomplete = this.draggableItems.length - answerLength
          numAnswersTotal = this.draggableItems.length
        } else {
          // Legacy dragphrase
          const phrases = this.cleanedContent.filter(
            (content) => content.isPhrase,
          )
          numAnswersIncomplete = phrases.length - answerLength
          numAnswersTotal = phrases.length
        }
      }
    }
    return { numAnswersIncomplete, numAnswersTotal }
  }

  validateAnswer(answer, openIncompleteQuestionModal) {
    if (
      this.activityType === ACTIVITY_TYPES.sortStack ||
      this.activityType === ACTIVITY_TYPES.matching
    ) {
      const items = this.pairs && this.pairs.labels
      if (answer.length < items.length) {
        const isOpenIncompleteQuestionModal = openIncompleteQuestionModal()
        if (isOpenIncompleteQuestionModal) return false
      }
    }
    if (this.activityType === ACTIVITY_TYPES.dragPhrase) {
      const categorizationAnswerLength =
        answer &&
        answer.reduce(
          (total, categories) => total + categories.phrases.length,
          0,
        )
      if (
        typeof this.draggableItems === 'object' &&
        categorizationAnswerLength < this.draggableItems.length
      ) {
        const isOpenIncompleteQuestionModal = openIncompleteQuestionModal()
        if (isOpenIncompleteQuestionModal) return false
      } else if (
        categorizationAnswerLength <
        this.cleanedContent.filter((content) => content.isPhrase).length
      ) {
        // Legacy dragphrase
        const isOpenIncompleteQuestionModal = openIncompleteQuestionModal()
        if (isOpenIncompleteQuestionModal) return false
      }
    }
    return true
  }
}
