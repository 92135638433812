import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { Loader, Button, GateCard, GateCardBody } from '@/components'
import './CoursePicker.scss'

const CoursePickerMessages = defineMessages({
  description: {
    defaultMessage: 'Please select your course.',
    description: 'Description displayed on the course picker page.',
    id: 'CoursePicker.description',
  },
  enter: {
    defaultMessage: 'Enter',
    description: 'Button to enter a course from the course picker page.',
    id: 'CoursePicker.enter',
  },
  entering: {
    defaultMessage: 'Entering course...',
    description:
      'Text displayed on the course picker page while the selected course is loading.',
    id: 'CoursePicker.entering',
  },
  loading: {
    defaultMessage: 'Loading courses...',
    description:
      'Text displayed on the course picker page while the list of courses for a given user is loading.',
    id: 'CoursePicker.loading',
  },
  title: {
    defaultMessage: 'Hi Learner!',
    description: 'Title displayed on the course picker page.',
    id: 'CoursePicker.title',
  },
})

@injectIntl
export default class CoursePicker extends Component {
  renderCourse = (course, index) => {
    const { intl } = this.props
    const { formatMessage } = intl
    // const progress = Math.round(course.progress.score / course.progress.totalScore * 100) || 0

    return (
      <div
        key={`course-${index}`}
        className="CoursePicker-course level is-mobile"
      >
        <div className="level-left">
          {/* <RadialProgress isSmall isAlt value={progress} size='60' /> */}
          <div className="CoursePicker-title">{course.title}</div>
        </div>
        <div className="level-right">
          <Button
            isSuccess
            onClick={() => this.props.switchCourse(course)}
            qa-id="learner-enter-course-button"
          >
            {formatMessage(CoursePickerMessages.enter)}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { courses, intl, isLoadingCourses, isSwitchingCourse } = this.props
    const { formatMessage } = intl
    return (
      <GateCard
        isMedium
        isTitled
        title={
          <div>
            <div className="title">
              {formatMessage(CoursePickerMessages.title)}
            </div>
            <div className="subtitle">
              {formatMessage(CoursePickerMessages.description)}
            </div>
          </div>
        }
      >
        <GateCardBody>
          <div className="CoursePicker">
            {isSwitchingCourse && (
              <Loader
                isOverlay
                text={formatMessage(CoursePickerMessages.entering)}
              />
            )}
            {isLoadingCourses ? (
              <Loader text={formatMessage(CoursePickerMessages.loading)} />
            ) : (
              courses.map((course, index) => this.renderCourse(course, index))
            )}
          </div>
        </GateCardBody>
      </GateCard>
    )
  }
}
