import cx from 'classnames'
import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import AdminPage from '@/admin/components/AdminPage/AdminPage'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import AdminNotificationDetailsPage from './AdminNotificationDetailsPage/AdminNotificationDetailsPage'
import { Button, Heading, Loader } from '@/components'
import {
  BULK_UPLOAD_NOTIFICATION_ACTION,
  BULK_UPLOAD_MULTIPLE_COURSES_NOTIFICATION_ACTION,
  REPORTS_REFRESH_NOTIFICATION_ACTION,
  NOTIFICATION_ACTION_TITLE_MAPPING,
} from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'

import './AdminNotificationsPage.scss'

const isDownloadURLExpired = (url) => {
  const urlQuery = qs.parse(url)
  const { Expires: expiresSec } = urlQuery
  const expiresMs = parseInt(expiresSec) * 1000
  return expiresMs < Date.now()
}

@inject('adminStore', 'uiStore')
@observer
export default class AdminNotificationsPage extends Component {
  state = {
    clickedDownloadIndices: [],
    firstReportIndices: {}, // Current implementation will disable old report download buttons
    notification: null,
  }
  componentDidMount() {
    const { adminStore, uiStore } = this.props
    adminStore.loadNotifications().then(() => {
      this.checkForMostRecentReports()
    })

    uiStore.updateTitle('Notifications Page')
  }
  componentDidUpdate() {
    const { uiStore } = this.props
    uiStore.updateTitle('Notifications Page')
  }
  checkForMostRecentReports = () => {
    const { adminStore } = this.props
    const { notifications } = adminStore
    const firstReportIndices = {}
    if (!notifications.length) return
    notifications.forEach((notification, i) => {
      if (notification.action === 'reports_refresh') {
        const { context } = notification
        const { courses } = context || {}
        const courseId = courses[0]
        if (!hasOwnProperty(firstReportIndices, courseId)) {
          firstReportIndices[courseId] = i
        }
      }
    })
    if (Object.keys(firstReportIndices).length) {
      this.setState({ firstReportIndices })
    }
  }
  onClickBackNotification = () => {
    const { uiStore } = this.props
    uiStore.scrollToTop()
    this.setState({ notification: null })
  }
  onClickCourse = (courseId) => {
    const { history } = this.props
    history.push(`/admin/courses/${courseId}/users`)
  }
  onClickDownloadReport = (response, courseId, index) => {
    const { adminStore } = this.props
    const { clickedDownloadIndices } = this.state
    const { loadReportExportURL } = adminStore
    const { courseUrlV2: url } = response
    if (!isDownloadURLExpired(url)) {
      window.location.assign(url)
    } else {
      clickedDownloadIndices.push(index)
      this.setState({ clickedDownloadIndices }, () => {
        loadReportExportURL({ courseId }).then(({ url: newURL }) => {
          if (newURL) {
            window.location.assign(newURL)
          }
          this.setState({
            clickedDownloadIndices: clickedDownloadIndices.filter(
              (i) => i !== index,
            ),
          })
        })
      })
    }
  }
  onClickNotification = (notification) => {
    const { uiStore } = this.props
    uiStore.scrollToTop()
    this.setState({ notification })
  }
  onClickRefreshNotifications = () => {
    const { adminStore } = this.props
    adminStore.loadNotifications(true).then(() => {
      this.checkForMostRecentReports()
    })
  }
  renderHeader = () => {
    return (
      <div className="AdminNotificationsPage-header">
        <div className="AdminNotificationsPage-headerItem">
          <Heading>
            <span>Notifications</span>
          </Heading>
        </div>
      </div>
    )
  }
  renderButtons = (notification, index) => {
    const { adminStore } = this.props
    const { isLoadingReportExportURL } = adminStore
    const buttons = []
    const { action, completed, context, response } = notification
    const { courses } = context || {}
    const { clickedDownloadIndices, firstReportIndices } = this.state || {}

    if (action === BULK_UPLOAD_NOTIFICATION_ACTION) {
      buttons.push(
        <Button onClick={() => this.onClickCourse(courses[0])}>
          View Course
        </Button>,
      )
      buttons.push(
        completed ? (
          <Button onClick={() => this.onClickNotification(notification)}>
            View Details
          </Button>
        ) : (
          <Button onClick={() => this.onClickRefreshNotifications()}>
            Refresh To Update
          </Button>
        ),
      )
    }

    if (action === BULK_UPLOAD_MULTIPLE_COURSES_NOTIFICATION_ACTION) {
      buttons.push(
        completed ? (
          <Button onClick={() => this.onClickNotification(notification)}>
            View Details
          </Button>
        ) : (
          <Button onClick={() => this.onClickRefreshNotifications()}>
            Refresh To Update
          </Button>
        ),
      )
    }

    if (action === REPORTS_REFRESH_NOTIFICATION_ACTION) {
      const courseId = courses[0]
      buttons.push(
        <Button onClick={() => this.onClickCourse(courseId)}>
          View Course
        </Button>,
      )
      if (completed) {
        const isExpired = firstReportIndices[courseId] !== index
        const downloadReportButtonText = isExpired
          ? 'Expired'
          : 'Download Report'
        const hasCurrentIndex = clickedDownloadIndices.indexOf(index) !== -1
        const isLoadingReport = isLoadingReportExportURL && hasCurrentIndex
        buttons.push(
          <Button
            disabled={
              isExpired || (!hasCurrentIndex && clickedDownloadIndices.length)
            }
            onClick={() =>
              this.onClickDownloadReport(response, courseId, index)
            }
            isLoading={isLoadingReport}
          >
            {downloadReportButtonText}
          </Button>,
        )
      } else {
        buttons.push(
          <Button onClick={() => this.onClickRefreshNotifications()}>
            Refresh To Update
          </Button>,
        )
      }
    }

    return (
      <div className="AdminNotificationsPage-notificationButtons">
        {buttons.map((button, i) => (
          <div
            className="AdminNotificationsPage-notificationButton"
            key={`AdminNotificationsPage-notificationButton-${index}-${i}`}
          >
            {button}
          </div>
        ))}
      </div>
    )
  }
  renderNotification = (notification, index) => {
    const { action, addedAt, completed, response } = notification
    const { errors } = response || {}
    return (
      <div
        className={cx('AdminNotificationsPage-notification', {
          'is-error': !!errors,
          'is-success': completed && !errors,
        })}
        key={`AdminNotificationsPage-notification-${index}`}
      >
        <div className="AdminNotificationsPage-notificationAction">
          <Heading is3>{NOTIFICATION_ACTION_TITLE_MAPPING[action]}</Heading>
          {this.renderButtons(notification, index)}
        </div>
        <div className="AdminNotificationsPage-notificationDetails">
          <span className="is-column">
            <span className="is-bold">Started:&nbsp;</span>
            <span className="is-block">{addedAt}&nbsp;</span>
          </span>
          <span className="is-column">
            <span className="is-bold">Completed:&nbsp;</span>
            <span className="is-block">{completed || 'N/A'}&nbsp;</span>
          </span>
          <span className="is-column">
            <span className="is-bold">Status:&nbsp;</span>
            <span className="is-block">
              {!completed ? 'Processing' : errors ? 'Error' : 'Success'}
            </span>
          </span>
        </div>
      </div>
    )
  }
  renderMain() {
    const { adminStore } = this.props
    const { notification } = this.state
    const { isLoadingNotifications, loadNotificationsError, notifications } =
      adminStore
    if (isLoadingNotifications) {
      return <Loader />
    }
    if (loadNotificationsError) {
      return (
        <div className="AdminNotificationsPage-error">
          <p>There was an issue loading your notifications.</p>
        </div>
      )
    }
    if (!notifications.length) {
      return (
        <div className="AdminNotificationsPage-empty">
          <p>You currently have no notifications available.</p>
        </div>
      )
    }
    return notification ? (
      <AdminNotificationDetailsPage
        notification={notification}
        onClickBack={this.onClickBackNotification}
      />
    ) : (
      <div className="AdminNotificationsPage-notifications">
        {notifications.map(this.renderNotification)}
      </div>
    )
  }
  render() {
    const { adminStore } = this.props
    const { isRefreshingNotifications } = adminStore
    return (
      <AdminPage
        isAlt
        title="AdminNotificationsPage"
        renderHeader={this.renderHeader}
      >
        <AdminLayout className="AdminNotificationsPage">
          {/* <AdminLayout.Sidebar>
          <Heading><span>AdminNotificationsPage Sidebar</span></Heading>
        </AdminLayout.Sidebar> */}
          <AdminLayout.Main id="main-content" isFullWidth>
            {this.renderMain()}
            {isRefreshingNotifications ? (
              <div className="AdminNotificationsPage-refreshing">
                <Loader text="Refreshing..." />
              </div>
            ) : null}
          </AdminLayout.Main>
        </AdminLayout>
      </AdminPage>
    )
  }
}
