import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import cx from 'classnames'

import './Button.scss'

class Button extends Component {
  render() {
    const {
      to,
      nav,
      href,
      submit,
      isAlt,
      isWhite,
      isLight,
      isDark,
      isDisabled,
      isBlack,
      isLink,
      isPrimary,
      isSecondary,
      isInfo,
      isSuccess,
      isWarning,
      isDanger,
      isSmall,
      isMedium,
      isLarge,
      isOutlined,
      isInverted,
      isLoading,
      isFullWidth,
      isIcon,
      isWide,
      isWider,
      isTight,
      text,
      isCircle,
      isHiddenSubmit,
      isText,
      setRef,
      ...rest
    } = this.props

    const className = cx(
      'button Button',
      {
        'is-alt': isAlt,
        'is-white': isWhite,
        'is-light': isLight,
        'is-dark': isDark,
        'is-black': isBlack,
        'is-link': isLink,
        'is-text': isText,
        'is-primary': isPrimary,
        'is-secondary': isSecondary,
        'is-info': isInfo,
        'is-success': isSuccess,
        'is-warning': isWarning,
        'is-danger': isDanger,
        'is-outlined': isOutlined,
        'is-inverted': isInverted,
        'is-loading': isLoading,
        'is-fullwidth': isFullWidth,
        'is-small': isSmall,
        'is-medium': isMedium,
        'is-large': isLarge,
        'is-icon': isIcon,
        'is-wide': isWide,
        'is-wider': isWider,
        'is-circle': isCircle,
        'is-hidden-submit': isHiddenSubmit,
        'is-tight': isTight,
      },
      this.props.className,
    )

    const linkProps = { to, ...rest }
    if (to) {
      return (
        <Link {...linkProps} className={className}>
          {text || this.props.children}
        </Link>
      )
    }
    if (to && nav) {
      return (
        <NavLink {...linkProps} className={className}>
          {text || this.props.children}
        </NavLink>
      )
    }

    const aProps = { href, ...rest }
    if (href) {
      return <a {...aProps}>{text || this.props.children}</a>
    }

    const defaultProps = { type: 'button' }
    if (this.props.submit) defaultProps.type = 'submit'
    const buttonProps = { ...defaultProps, ...rest }
    if (isHiddenSubmit) {
      buttonProps['aria-hidden'] = true
      buttonProps['tabIndex'] = -1
    }
    if (isDisabled) {
      buttonProps['disabled'] = true
    }
    return (
      <button {...buttonProps} className={className} ref={setRef}>
        {text || this.props.children}
      </button>
    )
  }
}

export default Button
