import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import cx from 'classnames'
import { Button, GateCard } from '@/components'
import './CourseCompleted.scss'

const CourseCompletedMessages = defineMessages({
  descriptionEncourageMasteryParagraph1: {
    defaultMessage: `Well done - you've completed this course.`,
    description:
      'Description displayed on the course completion screen if Encourage Mastery is enabled (paragraph 1).',
    id: 'CourseCompleted.descriptionEncourageMasteryParagraph1',
  },
  descriptionEncourageMasteryParagraph2: {
    defaultMessage:
      'As long as you can still log in, you can browse through the materials in this course and use them as a reference.',
    description:
      'Description displayed on the course completion screen if Encourage Mastery is enabled (paragraph 2).',
    id: 'CourseCompleted.descriptionEncourageMasteryParagraph2',
  },
  descriptionEncourageMasteryParagraph3: {
    defaultMessage:
      'Note: you currently have {numSectionsAtRisk} {numSectionsAtRisk, plural, one {Section} other {Sections}} in your Pathway to Mastery on your dashboard that you could use extra practice in.',
    description:
      'Description displayed on the course completion screen if Encourage Mastery is enabled and there are Sections in Pathway to Mastery (paragraph 3).',
    id: 'CourseCompleted.descriptionEncourageMasteryParagraph3',
  },
  descriptionParagraph1: {
    defaultMessage:
      'Well done! You have completed every Section in this course.',
    description:
      'Description displayed on the course completion screen (paragraph 1).',
    id: 'CourseCompleted.descriptionParagraph1',
  },
  descriptionParagraph2: {
    defaultMessage:
      'As long as you can still log in, you can browse through the materials in this course and use them as a reference. Remember, learning is a process, not a one-and-done deal!',
    description:
      'Description displayed on the course completion screen (paragraph 2).',
    id: 'CourseCompleted.descriptionParagraph2',
  },
  descriptionPathwayParagraph1: {
    defaultMessage: `We've identified {numSectionsAtRisk} {numSectionsAtRisk, plural, one {Section} other {Sections}} in your Pathway to Mastery that will need further review. Our platform is designed to help you achieve application-level mastery.`,
    description:
      'Description displayed on the course completion screen if there are Sections in Pathway to Mastery (paragraph 1).',
    id: 'CourseCompleted.descriptionPathwayParagraph1',
  },
  descriptionPathwayParagraph2: {
    defaultMessage: `Demonstrate mastery by answering Practice questions correctly on your first attempt. You can always jump back to Read or Watch to better inform your answers in Practice. Once you clear out the remaining Sections, you'll be 100% complete with this course.`,
    description:
      'Description displayed on the course completion screen if there are Sections in Pathway to Mastery (paragraph 2).',
    id: 'CourseCompleted.descriptionPathwayParagraph2',
  },
  descriptionRequireMasteryParagraph1: {
    defaultMessage:
      'Well done! You have mastered 100% of this course. Your patience and perseverance paid off so celebrate this win.',
    description:
      'Description displayed on the course completion screen if Require Mastery is enabled and all reattempts have been completed (paragraph 1).',
    id: 'CourseCompleted.descriptionRequireMasteryParagraph1',
  },
  descriptionRequireMasteryParagraph2: {
    defaultMessage:
      'Keep this course handy for future reference. Remember, learning is a process, and refreshing your knowledge from time to time will greatly enhance your retention.',
    description:
      'Description displayed on the course completion screen if Require Mastery is enabled and all reattempts have been completed (paragraph 2).',
    id: 'CourseCompleted.descriptionRequireMasteryParagraph2',
  },
  goToDashboard: {
    defaultMessage: 'Go to Learner Dashboard',
    description:
      'Button to return to the dashboard from the course completion screen.',
    id: 'CourseCompleted.goToDashboard',
  },
  search: {
    defaultMessage: 'Search through course',
    description:
      'Button to search through the course from the course completion screen.',
    id: 'CourseCompleted.search',
  },
  title: {
    defaultMessage: 'Congratulations!',
    description: 'Title displayed on the course completion screen.',
    id: 'CourseCompleted.title',
  },
  titlePathway: {
    defaultMessage: `You're almost finished!`,
    description:
      'Title displayed on the course completion screen if there are Sectionns in Pathway to Mastery.',
    id: 'CourseCompleted.titlePathway',
  },
})

@injectIntl
export default class CourseCompleted extends Component {
  componentWillUnmount() {
    const { onUnmount } = this.props
    onUnmount && onUnmount()
  }
  renderBody() {
    const { intl, isEncourageMastery, isRequireMastery, numSectionsAtRisk } =
      this.props
    const { formatMessage } = intl
    if (isRequireMastery) {
      if (numSectionsAtRisk) {
        return (
          <Fragment>
            <span>
              {formatMessage(
                CourseCompletedMessages.descriptionPathwayParagraph1,
                { numSectionsAtRisk },
              )}
            </span>
            <br />
            <br />
            <span>
              {formatMessage(
                CourseCompletedMessages.descriptionPathwayParagraph2,
              )}
            </span>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <span>
              {formatMessage(
                CourseCompletedMessages.descriptionRequireMasteryParagraph1,
              )}
            </span>
            <br />
            <br />
            <span>
              {formatMessage(
                CourseCompletedMessages.descriptionRequireMasteryParagraph2,
              )}
            </span>
          </Fragment>
        )
      }
    }
    if (isEncourageMastery) {
      return (
        <Fragment>
          <span>
            {formatMessage(
              CourseCompletedMessages.descriptionEncourageMasteryParagraph1,
            )}
          </span>
          <br />
          <br />
          <span>
            {formatMessage(
              CourseCompletedMessages.descriptionEncourageMasteryParagraph2,
            )}
          </span>
          <br />
          <br />
          {numSectionsAtRisk ? (
            <span>
              {formatMessage(
                CourseCompletedMessages.descriptionEncourageMasteryParagraph3,
                { numSectionsAtRisk },
              )}
            </span>
          ) : null}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <span>
          {formatMessage(CourseCompletedMessages.descriptionParagraph1)}
        </span>
        <br />
        <br />
        <span>
          {formatMessage(CourseCompletedMessages.descriptionParagraph2)}
        </span>
      </Fragment>
    )
  }
  renderTitle() {
    const { courseTitle, intl, isRequireMastery, numSectionsAtRisk } =
      this.props
    const { formatMessage } = intl
    if (isRequireMastery && numSectionsAtRisk) {
      return <span>{formatMessage(CourseCompletedMessages.titlePathway)}</span>
    }
    return (
      <FormattedMessage
        {...CourseCompletedMessages.title}
        values={{
          courseTitle: (
            <span className="CourseCompleted-course">{courseTitle}</span>
          ),
        }}
      />
    )
  }
  render() {
    const { className, onDashboard, onSearch, intl } = this.props
    const { formatMessage } = intl
    const classNames = cx('CourseCompleted', className)
    return (
      <GateCard className={classNames}>
        <h1 className="CourseCompleted-title">{this.renderTitle()}</h1>
        <div className="CourseCompleted-body">{this.renderBody()}</div>
        <div className="columns">
          {onDashboard && (
            <div className="column">
              <Button isFullWidth isSuccess onClick={onDashboard}>
                {formatMessage(CourseCompletedMessages.goToDashboard)}
              </Button>
            </div>
          )}
          {onSearch && (
            <div className="column">
              <Button isFullWidth isSuccess onClick={onSearch}>
                {formatMessage(CourseCompletedMessages.search)}
              </Button>
            </div>
          )}
        </div>
      </GateCard>
    )
  }
}
