import React, { Component } from 'react'
import { PASSWORD_TRIM_REGEX } from '@/constants'

const isEmpty = (value) => value == null || value === ''
const isError = (value) => !!value

export default function form({ reset, values, validate }) {
  return (Wrapped) =>
    class FormWrapper extends Component {
      constructor(props) {
        super(props)
        this.validate = validate
        this.defaultErrors = {}
        Object.keys(values).forEach((key) => {
          this.defaultErrors[key] = false
        })
        this.state = {
          values: { ...values },
          errors: { ...this.defaultErrors },
        }
      }

      handleChange = (e) => {
        let { name, value } = e.target
        if (name === 'password' || name === 'confirmPassword') {
          value = value.replace(PASSWORD_TRIM_REGEX, '')
        }
        this.setState({
          values: { ...this.state.values, [name]: value },
        })
      }

      getInputProps = (name) => {
        return {
          name,
          isDanger: isError(this.state.errors[name]),
          disabled: this.props.isLoading,
          value: this.state.values[name],
          onChange: this.handleChange,
        }
      }

      isValid = () => {
        const { values } = this.state
        this.props.onValidate(values)
        const errors = this.validate(values)
        if (errors) {
          this.setState({ errors: { ...errors } })
        } else {
          this.setState({ errors: { ...this.defaultErrors } })
        }
        return !errors
      }

      isEmpty = () => {
        const { values } = this.state
        return Object.keys(values).some((key) => isEmpty(values[key]))
      }

      renderErrors = () => {
        const formErrors = { ...this.state.errors, api: this.props.error }
        return Object.keys(formErrors).map((key) => {
          const error = formErrors[key]
          if (!isError(error) || typeof error !== 'string') return null
          return (
            <div key={key} className="help is-danger" role="alert">
              <i aria-hidden="true" className="icon-alert" /> {error}
            </div>
          )
        })
      }

      reset = () => {
        const newValues = reset()
        this.setState({ values: { ...newValues } })
      }

      render() {
        const newProps = {
          form: {
            values: () => this.state.values,
            isValid: this.isValid,
            isEmpty: this.isEmpty,
            renderErrors: this.renderErrors,
            inputProps: this.getInputProps,
            handleChange: this.handleChange,
            reset: this.reset,
          },
        }
        return <Wrapped {...this.props} {...newProps} />
      }
    }
}
