import React, { Component } from 'react'
import logo from '@/assets/fulcrum-logotype-light.png'
import cx from 'classnames'

import { Footer } from '@/components'

import './LoginLayout.scss'

class LoginLayout extends Component {
  componentDidMount() {
    const documentTrimClassName = document.documentElement.className.trim()
    const documentClassNames = documentTrimClassName
      ? documentTrimClassName.split(' ')
      : []
    documentClassNames.push('is-login')
    document.documentElement.className = documentClassNames.join(' ')
  }
  componentWillUnmount() {
    const documentTrimClassName = document.documentElement.className.trim()
    const documentClassNames = documentTrimClassName
      ? documentTrimClassName.split(' ')
      : []
    const classNameIndex = documentClassNames.indexOf('is-login')
    if (classNameIndex !== -1) {
      documentClassNames.splice(classNameIndex, 1)
    }
    document.documentElement.className = documentClassNames
  }
  render() {
    const { isSignIn, isForgotPassword, isNewPassword, isUpdatePassword } =
      this.props
    const className = cx(
      'LoginLayout',
      {
        'is-sign-in': isSignIn,
        'is-forgot-password': isForgotPassword,
        'is-new-password': isNewPassword,
        'is-update-password': isUpdatePassword,
      },
      this.props.className,
    )
    return (
      <div>
        <div className={className}>
          <div className="LoginLayout__header">
            <img alt="" className="LoginLayout__logo" src={logo} />
          </div>
          <div className="LoginLayout-box">
            <div className="LoginLayout-content">{this.props.children}</div>
            <Footer className="LoginLayout__footer" />
          </div>
        </div>
      </div>
    )
  }
}

export default LoginLayout
