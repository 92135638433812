import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { GateCard, GateCardBody, Button } from '@/components'
import './AdminNotFoundPage.scss'

@inject('uiStore')
@observer
export default class AdminNotFoundPage extends Component {
  componentDidMount() {
    const { uiStore } = this.props
    uiStore.updateTitle('Page Not Found')
  }
  componentDidUpdate() {
    const { uiStore } = this.props
    uiStore.updateTitle('Page Not Found')
  }
  render() {
    return (
      <div className="AdminNotFoundPage PageWrapper">
        <div className="container Container">
          <GateCard
            title={`Page Not Found.`}
            isTitled
            className="AdminNotFoundPage-gate"
          >
            <GateCardBody>
              <Button
                to="/"
                isLink
                isWider
                isPrimary
                className="AdminNotFoundPage-button"
              >
                Back to Home
              </Button>
            </GateCardBody>
          </GateCard>
        </div>
      </div>
    )
  }
}
