import React, { Fragment } from 'react'
import cx from 'classnames'

import { LightBulbIcon, RichTextWrapper } from '@/components'

import './ActivityHint.scss'

const ActivityHint = ({
  attempt,
  currentActivityHint,
  currentActivityId,
  hideHintText,
  id,
  isActivityFeedbackOpen,
  isActivityHintOpen,
  isNewHint,
  loadingText,
  onOpenImageModal,
  onToggleActivityHint,
  showHintText,
}) => {
  const shouldRenderActivityHint =
    !isActivityFeedbackOpen &&
    id === currentActivityId &&
    currentActivityHint &&
    currentActivityHint.length
  if (!shouldRenderActivityHint) return null
  return (
    <Fragment>
      <div className="ActivityHint__show-hint-wrapper">
        <button
          aria-controls="ActivityHint"
          aria-expanded={isActivityHintOpen}
          className={cx('ActivityHint__show-hint-button', {
            'ActivityHint__show-hint-button--is-new': isNewHint,
          })}
          onClick={onToggleActivityHint}
        >
          <LightBulbIcon isOutline />
          <span className="ActivityHint__show-hint-text">
            {!isActivityHintOpen ? showHintText : hideHintText}
          </span>
        </button>
      </div>
      <div
        aria-hidden={!isActivityHintOpen}
        aria-live="assertive"
        className={cx('ActivityHint', {
          'ActivityHint--is-open': isActivityHintOpen,
        })}
        id="ActivityHint"
        key={`ActivityHint-${id}-${attempt}`}
        role="alert"
      >
        <RichTextWrapper
          className="ActivityHint__rich-text-wrapper"
          content={currentActivityHint.join()}
          id={`ActivityHint__${id}`}
          key={`ActivityHint__${id}`}
          loadingText={loadingText}
          onOpenImageModal={onOpenImageModal}
        />
      </div>
    </Fragment>
  )
}

export default ActivityHint
