import React, { Component } from 'react'
import ConfirmationModal from '@/admin/modals/ConfirmationModal/ConfirmationModal'
import { Heading, Link } from '@/components'
import {
  REPORT_EXPORT_MODAL_VIEW_HELP,
  REPORT_EXPORT_MODAL_VIEW_INPROGRESS,
} from '@/constants'
import './ReportExportHelpModal.scss'
const REPORT_EXPORT_MODAL_TITLES = {
  [REPORT_EXPORT_MODAL_VIEW_HELP]: 'Export Report Help',
  [REPORT_EXPORT_MODAL_VIEW_INPROGRESS]: 'Export Report in Progress',
}
export default class ReportExportHelpModal extends Component {
  renderHelpBody() {
    return (
      <div className="ReportExportHelpModal-reportTooltip">
        <Heading is4>
          <span className="bold">Course Report Export</span>
          <span>&nbsp;includes:</span>
        </Heading>
        <ul>
          <li>First Name</li>
          <li>Last Name</li>
          <li>Email</li>
          <li>Fulcrum Learner ID</li>
          <li>User Creation Date (UTC)</li>
          <li>Location</li>
          <li>Job Code/Role</li>
          <li>Other Label</li>
          <li>Total Number of Units Completed</li>
          <li>Total Number of Tested Out Units</li>
          <li>Total Number of Sections Completed</li>
          <li>Total Number of Tested Out Sections</li>
          <li>Total Number of Questions Completed</li>
          <li>First Activity (UTC)</li>
          <li>Last Activity (UTC)</li>
          <li>Progress (%)</li>
          <li>Adjusted Performance Score (Confident Mastery)</li>
          <li>Avg Activity Difficulty</li>
          <li>Last Mastered Unit</li>
          <li>Last Mastered Section</li>
          <li>Total Time Spent</li>
          <li>Course Completion (UTC)</li>
        </ul>
      </div>
    )
  }
  renderInProgressBody() {
    const { onClose } = this.props
    return (
      <div className="ReportExportHelpModal-inProgress">
        <p>
          The report export process has started, please check the{' '}
          <Link
            className="ReportExportHelpModal__notification-page-link"
            to="/admin/notifications"
            onClick={onClose}
          >
            Notifications Page
          </Link>{' '}
          for updates. It is located on the top right hand corner (
          <i aria-hidden="true" className="fa fa-bell" />
          ).
        </p>
      </div>
    )
  }
  render() {
    const { isOpen, onClose, onConfirm, view } = this.props
    return (
      <ConfirmationModal
        className="ReportExportHelpModal"
        confirmText="OK"
        isCancelHidden
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        title={REPORT_EXPORT_MODAL_TITLES[view]}
      >
        {view === REPORT_EXPORT_MODAL_VIEW_HELP ? this.renderHelpBody() : null}
        {view === REPORT_EXPORT_MODAL_VIEW_INPROGRESS
          ? this.renderInProgressBody()
          : null}
      </ConfirmationModal>
    )
  }
}
