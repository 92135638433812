import React from 'react'
import cx from 'classnames'

import './Loader.scss'

const Loader = (props) => {
  const { ariaLive, className, isOverlay, role, text, ...rest } = props
  const additionalAttributes = {}
  const classNames = cx(
    'Loader',
    {
      'is-overlay': isOverlay,
    },
    className,
  )
  if (ariaLive) {
    additionalAttributes['aria-live'] = ariaLive
  }
  if (role) {
    additionalAttributes['role'] = role
  }
  return (
    <div {...rest} className={classNames}>
      <div className="loader" />
      {text && (
        <div className="Loader-text" {...additionalAttributes}>
          {text}
        </div>
      )}
    </div>
  )
}

export default Loader
