import cx from 'classnames'
import React, { Component } from 'react'
import { Activity, Button, Heading, Loader, Modal, Tabs } from '@/components'
import './ActivityDetailsModal.scss'
export default class ActivityDetailsModal extends Component {
  state = {
    answer: null,
    // view options
    //  question
    //  correctAnswers
    //  incorrectAnswers
    view: 'question',
  }
  get correctAnswers() {
    const { correctAnswers } = this.props
    return correctAnswers
  }
  get incorrectAnswers() {
    const { activity, correctAnswers, incorrectAnswers } = this.props
    const {
      isDragPhrase,
      isMatching,
      isMultipleChoice,
      isNumericInput,
      isSingleChoice,
      isSortStack,
      isTextInput,
    } = activity
    let formattedAnswers = null
    let formattedCorrectAnswers = null
    if (
      !incorrectAnswers ||
      (Array.isArray(incorrectAnswers) && !incorrectAnswers.length)
    )
      return formattedAnswers
    if (isDragPhrase) {
      formattedAnswers = {}
      formattedCorrectAnswers = {}
      correctAnswers.forEach((correctAnswer) => {
        const correctAnswerName = Object.keys(correctAnswer)[0]
        const correctAnswerPhrases = correctAnswer[correctAnswerName]
        formattedCorrectAnswers[correctAnswerName] = correctAnswerPhrases
      })
      incorrectAnswers.forEach((answer) => {
        const { name, phrases } = answer
        formattedAnswers[name] = []
        phrases.forEach((phrase) => {
          let isCorrect = false
          if (
            formattedCorrectAnswers[name] &&
            formattedCorrectAnswers[name].indexOf(phrase) !== -1
          ) {
            isCorrect = true
          }
          formattedAnswers[name].push({ isCorrect, phrase: phrase.trim() })
        })
      })
    } else if (isMatching) {
      incorrectAnswers.forEach((answer) => {
        const { definition, label } = answer
        for (let i = 0; i < correctAnswers.length; i++) {
          const {
            definition: correctAnswerDefinition,
            label: correctAnswerLabel,
          } = correctAnswers[i]
          if (
            label === correctAnswerLabel &&
            definition === correctAnswerDefinition
          ) {
            answer.isCorrect = true
            break
          } else {
            answer.isCorrect = false
          }
        }
      })
      formattedAnswers = incorrectAnswers
    } else if (isMultipleChoice || isSingleChoice) {
      formattedAnswers = {}
      incorrectAnswers.forEach((answer) => {
        if (correctAnswers.indexOf(answer) !== -1) {
          formattedAnswers[answer] = true
        } else {
          formattedAnswers[answer] = false
        }
      })
    } else if (isSortStack) {
      incorrectAnswers.forEach((answer, index) => {
        const { label } = answer
        for (let i = 0; i < correctAnswers.length; i++) {
          const { label: correctAnswerLabel } = correctAnswers[i]
          if (label === correctAnswerLabel && index === i) {
            answer.isCorrect = true
            break
          } else {
            answer.isCorrect = false
          }
        }
      })
      formattedAnswers = incorrectAnswers
    } else if (isNumericInput || isTextInput) {
      formattedAnswers = incorrectAnswers
    }
    return formattedAnswers
  }
  onChangeActivity = (value) => {
    this.setState({ answer: value })
  }
  onClickChangeView = (nextView) => {
    const { view } = this.state
    if (nextView === view) return
    this.setState({ view: nextView })
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  renderActivity = () => {
    const { activity, onOpenActivityImageModal } = this.props
    const { answer, view } = this.state
    const {
      activityEmbed,
      activityType,
      answersCounter,
      id: activityId,
      question,
      questionImage,
      questionImageDescription,
      questionImageTitle,
      questionVideo,
      replaceActivityMathFormulas,
      title: activityTitle,
    } = activity
    return (
      <Activity
        activityEmbed={activityEmbed}
        adminCorrectAnswers={
          view === 'correctAnswers' ? this.correctAnswers : null
        }
        adminIncorrectAnswers={
          view === 'incorrectAnswers' ? this.incorrectAnswers : null
        }
        adminHideLabel={view === 'incorrectAnswers'}
        answersCounter={answersCounter}
        id={activityId}
        image={questionImage}
        imageAlt={questionImageDescription || activityTitle}
        imageTitle={questionImageTitle || activityTitle}
        onChange={view === 'question' ? this.onChangeActivity : () => {}}
        onOpenActivityModal={onOpenActivityImageModal}
        replaceActivityMathFormulas={replaceActivityMathFormulas}
        title={question}
        type={activityType}
        value={view === 'question' ? answer : null}
        video={questionVideo}
        // choices activities
        choice={{
          choices: activity.cleanedChoices,
        }}
        // input activities
        input={{
          isMultipleInput: activity.isMultipleInput,
          textBefore: activity.textBeforeInput,
          textAfter: activity.textAfterInput,
        }}
        // matching activity
        matching={{
          definitions: activity.pairs && activity.pairs.definitions,
          labels: activity.pairs && activity.pairs.labels,
        }}
        // sorting activity
        sortStack={{
          items: activity.pairs && activity.pairs.labels,
        }}
        // hotspot activity
        hotspot={activity.hotSpotData}
        // dragPhrase activity
        dragPhrase={{
          content: activity.cleanedContent,
          categories: activity.categories,
          items: activity.draggableItems,
        }}
      />
    )
  }
  renderIncorrectAnswerHeader() {
    const { activity, incorrectAnswerMetrics } = this.props
    const { isNumericInput, isSingleChoice, isTextInput } = activity
    const { mostCommonAttempts, totalAttempts } = incorrectAnswerMetrics
    const text = `The exact answer below is the most common incorrect ${
      isSingleChoice || isNumericInput || isTextInput
        ? 'answer'
        : 'combination of answers'
    } submitted. This question was answered like this ${mostCommonAttempts} times out of ${totalAttempts} total attempts.`
    return (
      <div className="ActivityDetailsModal-incorrectAnswerHeader">
        <div className="ActivityDetailsModal-incorrectAnswerHeader-icon">
          <i className="fa fa-close" />
        </div>
        <span>{text}</span>
      </div>
    )
  }
  renderView = () => {
    return (
      <div className="ActivityDetailsModal-view">{this.renderActivity()}</div>
    )
  }
  renderBody = () => {
    const { activity, error, isLoading } = this.props
    const { view } = this.state
    const { isHotspot } = activity || {}
    if (isLoading) {
      return (
        <div className="ActivityDetailsModal-body">
          <Loader />
        </div>
      )
    }
    if (error) {
      return (
        <div className="ActivityDetailsModal-body">
          <p>{error}</p>
        </div>
      )
    }
    return (
      <div className="ActivityDetailsModal-body">
        <Tabs isAlt>
          <Button
            className={cx({ 'is-active': view === 'question' })}
            onClick={() => this.onClickChangeView('question')}
          >
            Question
          </Button>
          {!isHotspot ? (
            <Button
              className={cx({
                'is-active': view === 'correctAnswers',
              })}
              onClick={() => this.onClickChangeView('correctAnswers')}
            >
              Correct Answer
            </Button>
          ) : null}
          {!isHotspot ? (
            <Button
              className={cx({ 'is-active': view === 'incorrectAnswers' })}
              disabled={!this.incorrectAnswers}
              onClick={() => this.onClickChangeView('incorrectAnswers')}
            >
              Most Common Incorrect Answer
            </Button>
          ) : null}
        </Tabs>
        {view === 'incorrectAnswers'
          ? this.renderIncorrectAnswerHeader()
          : null}
        {this.renderView()}
      </div>
    )
  }
  render() {
    const { error, isOpen } = this.props
    const { view } = this.state
    return (
      <Modal
        className={cx('ActivityDetailsModal', {
          'is-error': !!error,
          'is-correct': view === 'correctAnswers',
          'is-incorrect': view === 'incorrectAnswers',
        })}
        contentLabel="Activity Details Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>Question and Answers</Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        {error ? (
          <Modal.Footer>
            <Modal.Button isSecondary onClick={this.onClose}>
              Close
            </Modal.Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    )
  }
}
