import React from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'

import AdminNotFoundPage from './pages/AdminNotFoundPage/AdminNotFoundPage'
import AdminNotificationsPage from './pages/AdminNotificationsPage/AdminNotificationsPage'
import InstancesPage from './pages/InstancesPage'
import InstancePage from './pages/InstancePage/InstancePage'

const AdminRoutes = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/courses`} component={InstancesPage} />
      <Route
        path={`${match.path}/courses/:instanceId`}
        component={InstancePage}
      />
      <Route
        path={`${match.path}/notifications`}
        component={AdminNotificationsPage}
      />
      <Route exact path={`${match.path}/users`} component={InstancesPage} />
      <Redirect exact from={match.url} to={`${match.url}/courses`} />
      <Route exact component={AdminNotFoundPage} />
    </Switch>
  )
}

export default AdminRoutes
