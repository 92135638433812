import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { CheckboxCustom, Heading, Modal } from '@/components'

import './IncompleteQuestionModal.scss'

const IncompleteQuestionModalMessages = defineMessages({
  back: {
    defaultMessage: 'Go Back',
    description: 'Button to close the incomplete question modal.',
    id: 'IncompleteQuestionModal.back',
  },
  body: {
    defaultMessage:
      'You are currently missing {numAnswersIncomplete} out of {numAnswersTotal} possible answers and will get this question incorrect if you press confirm.',
    description:
      'Description displayed in the incomplete question modal when a user tries to confirm an answer with empty fields.',
    id: 'IncompleteQuestionModal.body',
  },
  confirm: {
    defaultMessage: 'Confirm',
    description:
      'Button to confirm an incomplete answer in the incomplete question modal.',
    id: 'IncompleteQuestionModal.confirm',
  },
  dontShowAgain: {
    defaultMessage: `Don't show again`,
    description:
      'Checkbox text in the incomplete question modal for users to prevent it from opening again.',
    id: 'IncompleteQuestionModal.dontShowAgain',
  },
  title: {
    defaultMessage: 'Are you sure?',
    description:
      'Title displayed in the incomplete question modal when a user tries to confirm an incomplete answer.',
    id: 'IncompleteQuestionModal.title',
  },
})

@injectIntl
@inject('uiStore')
export default class IncompleteQuestionModal extends Component {
  state = {
    dontShowAgain: false,
  }
  onToggleCheckbox = () => {
    const { dontShowAgain } = this.state
    this.setState({ dontShowAgain: !dontShowAgain })
  }
  onClose = (e, dontShowAgain = false) => {
    const { uiStore, onClose } = this.props
    if (onClose) {
      onClose(dontShowAgain)
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  onConfirm = (e) => {
    const { onConfirm } = this.props
    const { dontShowAgain } = this.state
    if (onConfirm) {
      onConfirm()
    }
    this.onClose(e, dontShowAgain)
  }
  render() {
    const { intl, isOpen, numAnswersIncomplete, numAnswersTotal } = this.props
    const { dontShowAgain } = this.state
    const { formatMessage } = intl
    return (
      <Modal
        className="IncompleteQuestionModal"
        contentLabel="Incomplete Question Modal"
        isCard
        isOpen={isOpen}
        scrollTop
      >
        <Modal.Header>
          <Heading>
            {formatMessage(IncompleteQuestionModalMessages.title)}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <p>
            {formatMessage(IncompleteQuestionModalMessages.body, {
              numAnswersIncomplete,
              numAnswersTotal,
            })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <CheckboxCustom
            onChange={this.onToggleCheckbox}
            text={formatMessage(IncompleteQuestionModalMessages.dontShowAgain)}
            value={dontShowAgain}
          />
          <Modal.Button isSecondary onClick={this.onConfirm}>
            {formatMessage(IncompleteQuestionModalMessages.confirm)}
          </Modal.Button>
          <Modal.Button isInfo onClick={this.onClose}>
            {formatMessage(IncompleteQuestionModalMessages.back)}
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
