import { observable, computed } from 'mobx'
import {
  DISABLE_DASHBOARD,
  ENCOURAGE_MASTERY,
  REQUIRE_MASTERY,
} from '@/constants'
import { toCamelCase } from '@/utils/helpers'

export default class Settings {
  @observable activityAttempts = null
  @observable courseCompletionCopy = null
  @observable courseIntro = null
  @observable courseMasteryCopy = null
  @observable exam = false
  @observable examCertificate = true
  @observable isDisabledMessaging = false
  @observable isSnapshot = null
  @observable isSnapshotStandalone = null
  @observable learnerDashboard = null
  @observable practiceLearningObjectives = false
  @observable quiz = false
  @observable quizLockout = null
  @observable quizMastery = null
  @observable quizPass = null
  @observable requiredMasteryLevel = null
  @observable reviewActivitiesType = null
  @observable snapshotSkip = null
  @observable snapshotTestOut = null

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get isDisabledDashboard() {
    return (
      !this.learnerDashboard ||
      this.learnerDashboard === DISABLE_DASHBOARD ||
      (!this.isEncourageMastery && !this.isRequireMastery)
    )
  }
  @computed get isEncourageMastery() {
    return this.learnerDashboard === ENCOURAGE_MASTERY
  }
  @computed get isRequireMastery() {
    return this.learnerDashboard === REQUIRE_MASTERY
  }
}
