import React from 'react'
import { Link as RouterLink, NavLink } from 'react-router-dom'
import cx from 'classnames'
import './Link.scss'

const Link = (props) => {
  const { to, nav, href, isButton, isInfo, ...rest } = props

  // TODO: add a class link
  const className = cx(
    'Link link',
    { 'Button button': isButton, 'is-info': isInfo },
    props.className,
  )

  const linkProps = { to, ...rest }
  if (to && nav) {
    return (
      <NavLink {...linkProps} className={className}>
        {props.children}
      </NavLink>
    )
  }

  if (to) {
    return (
      <RouterLink {...linkProps} className={className}>
        {props.children}
      </RouterLink>
    )
  }

  const aProps = { href, ...rest }
  if (isButton) {
    return (
      <a {...aProps} className={className}>
        {props.children}
      </a>
    )
  }
  return <a {...aProps}>{props.children}</a>
}

export default Link
