import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { Heading, Loader, Modal, UserAvatar } from '@/components'
import './ProfileModal.scss'

const ProfileModalMessages = defineMessages({
  close: {
    defaultMessage: 'Close',
    description: 'Button to close the user profile modal for screen readers.',
    id: 'ProfileModal.close',
  },
  labelAbout: {
    defaultMessage: 'About me',
    description:
      'Label displayed for the field that contain details about the user in the user profile modal.',
    id: 'ProfileModal.labelAbout',
  },
  labelBirthday: {
    defaultMessage: 'Birthday',
    description: `Label displayed for the user's birthday in the user profile modal.`,
    id: 'ProfileModal.labelBirthday',
  },
  labelLocation: {
    defaultMessage: 'Location',
    description: `Label displayed for the user's location in the user profile modal.`,
    id: 'ProfileModal.labelLocation',
  },
  labelProfile: {
    defaultMessage: 'Learner profile',
    description: 'Title displayed in the user profile modal.',
    id: 'ProfileModal.labelProfile',
  },
  labelProfileSelf: {
    defaultMessage: 'My learner profile',
    description:
      'Title displayed in the user profile modal if the user is viewing their own profile.',
    id: 'ProfileModal.labelProfileSelf',
  },
  sendMessage: {
    defaultMessage: 'Send Message',
    description:
      'Button to open the new message modal from the user profile modal.',
    id: 'ProfileModal.sendMessage',
  },
})

@injectIntl
@inject('uiStore')
export default class ProfileModal extends Component {
  onClose = () => {
    const { onClose, uiStore } = this.props
    if (onClose) {
      onClose()
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  onSubmit = () => {
    const { sendMessage, userProfile } = this.props
    const { user } = userProfile || {}
    sendMessage(user)
  }
  renderBody() {
    const { intl, isLoading, loadingError, userProfile } = this.props
    const { formatMessage } = intl
    if (isLoading)
      return (
        <div className="ProfileModal-loaderWrap">
          <Loader />
        </div>
      )
    if (loadingError)
      return <div className="ProfileModal-loaderWrap">{loadingError}</div>
    if (!userProfile) return null
    return (
      <div className="ProfileModal-body">
        <div className="columns is-mobile is-multiline">
          <div className="column is-12-mobile is-6-tablet">
            <div className="columns is-smallgap is-mobile ProfileModal-alignCenter">
              <div className="column is-narrow">
                <UserAvatar
                  size={50}
                  className="UserAvatar--large"
                  name={userProfile.user.fullName}
                />
              </div>
              <div className="column">
                <h1 className="ProfileModal-title">
                  {userProfile.user.fullName}
                </h1>
                <a
                  className="ProfileModal-email"
                  href={`mailto:${userProfile.user.email}`}
                  rel="noopener noreferrer"
                >
                  {userProfile.user.email}
                </a>
              </div>
            </div>
          </div>
          <div className="column is-6-mobile is-3-tablet ProfileModal-location">
            <div className="ProfileModal-heading">
              {formatMessage(ProfileModalMessages.labelLocation)}
            </div>
            <div>{userProfile.location}</div>
          </div>
          <div className="column is-6-mobile is-3-tablet ProfileModal-birthday">
            <div className="ProfileModal-heading">
              {formatMessage(ProfileModalMessages.labelBirthday)}
            </div>
            <div>{userProfile.fbirthday}</div>
          </div>
        </div>
        {userProfile.longDescription && (
          <div className="ProfileModal-about">
            <div className="ProfileModal-heading">
              {formatMessage(ProfileModalMessages.labelAbout)}
            </div>
            <div>{userProfile.longDescription}</div>
          </div>
        )}
      </div>
    )
  }
  render() {
    const { intl, isCurrentUser, isDisabledMessaging, isOpen, userProfile } =
      this.props
    const { formatMessage } = intl
    const title = isCurrentUser
      ? formatMessage(ProfileModalMessages.labelProfileSelf)
      : formatMessage(ProfileModalMessages.labelProfile)
    return (
      <Modal
        className="ProfileModal"
        contentLabel="Profile Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>{title}</Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button isSecondary onClick={this.onClose}>
            {formatMessage(ProfileModalMessages.close)}
          </Modal.Button>
          {userProfile && !isCurrentUser && !isDisabledMessaging ? (
            <Modal.Button onClick={this.onSubmit}>
              {formatMessage(ProfileModalMessages.sendMessage)}
            </Modal.Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}
