import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import IdleTimer from 'react-idle-timer'
import { IdleModal, Loader } from '@/components'
import { AdminLayout, StudentLayout } from '@/layouts'
import './AppLayout.scss'

// This is set to 15min as the IdleModal has a 15min timer
// before logging the User out.
const JWT_TIMEOUT = 15 * 60 * 1000 // 15min * 60s * 1000ms
const JWT_REFRESH_INTERVAL = 29 * 60 * 1000 // 29min * 60s * 1000ms
const JWT_REFRESH_INTERVAL_MINIMUM = 1000 // 1000ms

class SessionTimer extends Component {
  componentDidMount() {
    this.onExtend(JWT_REFRESH_INTERVAL_MINIMUM)
  }
  componentWillUnmount() {
    if (this.extendTimeout) {
      clearTimeout(this.extendTimeout)
    }
  }
  onExtend = (interval) => {
    const { onExtend } = this.props
    this.extendTimeout = setTimeout(() => {
      onExtend()
        .then(() => {
          this.onExtend()
        })
        .catch(() => {
          this.onLogout()
        })
    }, interval || JWT_REFRESH_INTERVAL)
  }
  onCloseIdleModal = () => {
    const { onCloseIdleModal } = this.props
    this.onExtend(JWT_REFRESH_INTERVAL_MINIMUM)
    onCloseIdleModal()
  }
  onIdle = () => {
    const { onIdle } = this.props
    clearTimeout(this.extendTimeout)
    onIdle()
  }
  onLogout = () => {
    const { onLogout } = this.props
    onLogout()
  }
  render() {
    const { isOpenIdleSessionModal } = this.props
    return (
      <Fragment>
        <IdleTimer onIdle={this.onIdle} timeout={JWT_TIMEOUT} startOnLoad />
        <IdleModal
          isOpen={isOpenIdleSessionModal}
          onClose={this.onCloseIdleModal}
          tab="session"
        />
      </Fragment>
    )
  }
}

@withRouter
@inject('sessionStore', 'uiStore')
@observer
export default class AppLayout extends Component {
  componentDidMount() {
    const { sessionStore, history } = this.props
    const { appStore } = sessionStore || {}
    if (appStore && !appStore.historyApi) {
      appStore.historyApi = history
    }
    sessionStore.loadLocaleMessages()
    window.addEventListener('keydown', this.handleFirstTab)
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleFirstTab)
    window.removeEventListener('mousedown', this.handleMouseDownOnce)
  }
  handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
      window.removeEventListener('keydown', this.handleFirstTab)
      window.addEventListener('mousedown', this.handleMouseDownOnce)
    }
  }
  handleMouseDownOnce = () => {
    document.body.classList.remove('user-is-tabbing')
    window.removeEventListener('mousedown', this.handleMouseDownOnce)
    window.addEventListener('keydown', this.handleFirstTab)
  }
  onCloseIdleModal = () => {
    const { uiStore } = this.props
    uiStore.closeIdleSessionModal()
  }
  onExtend = () => {
    const { sessionStore } = this.props
    const { session } = sessionStore || {}
    if (!session) return
    return sessionStore.refreshJwt()
  }
  onIdle = () => {
    const { uiStore } = this.props
    uiStore.openIdleSessionModal()
  }
  onLogout = () => {
    const { sessionStore } = this.props
    sessionStore.logout()
  }
  renderAdminLayout() {
    const { children } = this.props
    return <AdminLayout>{children}</AdminLayout>
  }
  renderStudentLayout() {
    const { children } = this.props
    return <StudentLayout>{children}</StudentLayout>
  }
  renderMain() {
    const { sessionStore } = this.props
    const { session } = sessionStore
    const { user: sessionUser } = session || {}
    const { isCurrentRoleAdmin } = sessionUser || {}
    return isCurrentRoleAdmin
      ? this.renderAdminLayout()
      : this.renderStudentLayout()
  }
  render() {
    const { children, sessionStore, uiStore } = this.props
    const { isLoadingLocaleMessages, isUpdatingLocale, session } = sessionStore
    const { isOpenIdleSessionModal } = uiStore
    const { user: sessionUser } = session || {}
    const { isCurrentRoleAdmin } = sessionUser || {}
    const className = !isCurrentRoleAdmin
      ? cx('AppLayout', uiStore.getTheme())
      : 'AppLayout'
    if (!session) return children
    return (
      <div
        className={className}
        ref={(el) => (this.el = el)}
        qa-id="app-layout"
      >
        {isLoadingLocaleMessages || isUpdatingLocale ? (
          <Loader />
        ) : (
          this.renderMain()
        )}
        <SessionTimer
          isOpenIdleSessionModal={isOpenIdleSessionModal}
          onCloseIdleModal={this.onCloseIdleModal}
          onExtend={this.onExtend}
          onIdle={this.onIdle}
          onLogout={this.onLogout}
        />
      </div>
    )
  }
}
