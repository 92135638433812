import { extendObservable, action } from 'mobx'
import humps from 'lodash-humps'
import { handleError } from '@/api'
import SentryReplay from '@/SentryReplay'
import request from '@/utils/request'
import qs from 'query-string'
import _ from 'lodash'

const initialState = {
  context: null,
  result: null,
  isLoading: false,
}

export default class SearchStore {
  constructor(appStore) {
    extendObservable(this, { ...initialState })
    this.appStore = appStore
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...initialState })
  }

  @action init(context) {
    this.context = context
  }

  @action search(queries) {
    if (_.isEmpty(queries)) return

    // term, category, limit, offset
    const querystring = qs.stringify({ d: Date.now(), ...queries, limit: 50 })

    this.isLoading = true
    return request
      .get(`/search/${this.context.courseId}?${querystring}`)
      .then((response) => this.handleSearch(response))
      .catch((error) => this.handleSearchError(error))
  }

  @action handleSearch(response) {
    this.isLoading = false
    const result = humps(response.data)
    if (result.results.length > 0) {
      result.categories = this.adaptCategories(result)
      result.results = this.adaptResults(result)
    }

    this.result = result
  }

  @action handleSearchError(error) {
    SentryReplay.captureException(error)
    this.isLoading = false
    handleError(error)
  }

  @action clean() {
    this.result = null
  }

  adaptCategories(result) {
    return [
      {
        value: '__NONE__',
        title: 'Everything',
        count: result.count,
      },
      ...result.categories.map((c) => ({
        ...c,
        title: _.capitalize(c.value),
      })),
    ]
  }

  adaptResults(result) {
    return result.results.map((r) => {
      if (_.isEmpty(r.videoThumbnailUrl)) {
        r.videoThumbnailUrl = null
      }
      r.category = r.category.map((c) => ({
        value: c,
        title: _.capitalize(c),
      }))
      return r
    })
  }
}
