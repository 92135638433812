import _ from 'lodash'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import IdleTimer from 'react-idle-timer'
import debounce from 'lodash/debounce'
import {
  Activity,
  ActivityModal,
  AnimatedActivityIcon,
  Button,
  Heading,
  IdleModal,
  IncompleteQuestionModal,
  Loader,
  Modal,
  MoreContentBelowBanner,
  // NOTE - disabled https://headware4.jira.com/browse/FM-3884
  // TrophyIcon
} from '@/components'
import {
  checkIfScrollable,
  checkIfScrolledToBottom,
} from '@/components/MoreContentBelowBanner/MoreContentBelowBanner'
import TimeTracking, {
  TYPE_ACTIVITY,
} from '@/components/TimeTracking/TimeTracking'
import './Snapshot.scss'

const SnapshotMessages = defineMessages({
  activityErrorBody: {
    defaultMessage:
      'There was an error while loading the current question. Try refreshing the page. If the issue persists, please contact your learning administrator.',
    description:
      'Body copy displayed when there is an issue loading an Activity.',
    id: 'SnapshotMessages.activityErrorBody',
  },
  activityErrorHeader: {
    defaultMessage: 'Oops, something went wrong.',
    description:
      'Header copy displayed when there is an issue loading an Activity.',
    id: 'SnapshotMessages.activityErrorHeader',
  },
  beginPreTestButton: {
    defaultMessage: 'Begin Pre-Test',
    description: 'Button to begin the Pre-Test in Snapshot.',
    id: 'SnapshotMessages.beginPreTestButton',
  },
  beginPreTestButtonStandalone: {
    defaultMessage: 'Start Here',
    description: 'Button to begin the Pre-Test in Snapshot Standalone.',
    id: 'SnapshotMessages.beginPreTestButtonStandalone',
  },
  completeBodyNoTestOut: {
    defaultMessage:
      'Thank you for completing the pre-course questions. You are now ready to begin your course.',
    description:
      'Body copy for the completion screen when Sections cannot be tested out of in Snapshot.',
    id: 'SnapshotMessages.completeBodyNoTestOut',
  },
  completeBodyStandalone: {
    defaultMessage: `Your organization will be in touch soon with next steps. You can now logout or close the browser window. That's all there is to it.`,
    description: 'Body copy for the completion screen in Snapshot Standalone.',
    id: 'SnapshotMessages.completeBodyStandalone',
  },
  completeBodyTestedOut: {
    defaultMessage: `You've tested out of {numSectionsTestedOut} {numSectionsTestedOut, plural, one {Section} other {Sections}}! That leaves {numSectionsRemaining} {numSectionsRemaining, plural, one {Section} other {Sections}} left for you to learn.`,
    description:
      'Body copy for the completion screen when Sections have been tested out of in Snapshot.',
    id: 'SnapshotMessages.completeBodyTestedOut',
  },
  completeBodyTestedOutCourse: {
    defaultMessage: `You did so well that you've tested out of every section in this Course.`,
    description:
      'Body copy for the completion screen when all Sections have been tested out of in Snapshot.',
    id: 'SnapshotMessages.completeBodyTestedOutCourse',
  },
  completeBodyTestedOutCourseSubtext: {
    defaultMessage: `You can still review the material and test yourself on other questions if you'd like.`,
    description:
      'Body subtext copy for the completion screen when all Sections have been tested out of in Snapshot.',
    id: 'SnapshotMessages.completeBodyTestedOutCourseSubtext',
  },
  completeBodyZeroTestedOut1: {
    defaultMessage:
      'At this time, you have not tested out of any Sections. Your course will continue adjusting based on your progress and performance.',
    description:
      'Body copy (1) for the completion screen in Snapshot when testing out of 0 sections.',
    id: 'SnapshotMessages.completeBodyZeroTestedOut1',
  },
  completeBodyZeroTestedOut2: {
    defaultMessage: 'Good luck!',
    description:
      'Body copy (2) for the completion screen in Snapshot when testing out of 0 sections.',
    id: 'SnapshotMessages.completeBodyZeroTestedOut2',
  },
  completeHeaderStandalone: {
    defaultMessage:
      'Congrats! You Have Completed Your Personalized Assessment Diagnostic for {courseTitle}',
    description: 'Header for the completion screen in Snapshot Standalone.',
    id: 'SnapshotMessages.completeHeaderStandalone',
  },
  completeHeaderTestedOut: {
    defaultMessage: 'You completed the Pre-Test!',
    description:
      'Header for the completion screen when Sections have been tested out of in Snapshot.',
    id: 'SnapshotMessages.completeHeaderTestedOut',
  },
  completeHeaderTestedOutCourse: {
    defaultMessage: `Congrats! You've tested out of this Course!`,
    description:
      'Header for the completion screen when all Sections have been tested out of in Snapshot.',
    id: 'SnapshotMessages.completeHeaderTestedOutCourse',
  },
  completeHeaderZeroTestedOut: {
    defaultMessage: `You completed the Pre-Test!`,
    description:
      'Header for the completion screen in Snapshot when testing out of 0 sections.',
    id: 'SnapshotMessages.completeHeaderZeroTestedOut',
  },
  confirmAnswerButton: {
    defaultMessage: 'Confirm Answer',
    description: 'Button for submitting an answer to an activity in Snapshot.',
    id: 'SnapshotMessages.confirmAnswerButton',
  },
  launchCourseButton: {
    defaultMessage: 'Launch Course',
    description: 'Button to launch course after completion in Snapshot.',
    id: 'SnapshotMessages.launchCourseButton',
  },
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to zoom in on activity images in Snapshot.',
    id: 'SnapshotMessages.enlargeImage',
  },
  introBody1: {
    defaultMessage: 'Hi {firstName},',
    description:
      'First paragraph in the body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.introBody1',
  },
  introBody1Standalone: {
    defaultMessage: 'Hi {firstName},',
    description:
      'First paragraph in the body copy for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introBody1Standalone',
  },
  introBody2: {
    defaultMessage: `You are about to customize your course, {courseTitle}. You'll be served a series of challenging questions, designed to measure your mastery and pinpoint any knowledge gaps.`,
    description:
      'Second paragraph in the body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.introBody2',
  },
  introBody2Standalone: {
    defaultMessage:
      'The following is a personalized, adaptive assessment diagnostic for {courseTitle}. You will be served a series of assessments so we can determine a baseline measurement of your current knowledge of this subject matter in order to better personalize your future learning materials.',
    description:
      'Second paragraph in the body copy for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introBody2Standalone',
  },
  introBody2NoTestOut: {
    defaultMessage:
      'Before you begin, you will receive a series of questions to gauge your level of understanding which will be used to measure your knowledge gained from participating in the course. You will not receive feedback for these pre-course questions and your answers will not impact your completion of the course. You will be required to complete each section and pass the final exam. We appreciate your participation in this pilot testing and look forward to your candid feedback.',
    description:
      'Second paragraph in the body copy for the introduction screen in Snapshot when Learners cannot test out of Sections.',
    id: 'SnapshotMessages.introBody2NoTestOut',
  },
  introBody3: {
    defaultMessage: `You will "test out" of sections where you demonstrate mastery. These sections will be instantly marked done as soon as you enter the course. There {totalSections, plural, one {is} other {are}} {totalSectionsSpan} {totalSections, plural, one {Section} other {total Sections}} in this course.`,
    description:
      'Third paragraph in the body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.introBody3',
  },
  introBody3Standalone: {
    defaultMessage:
      'Depending on how you perform, you may receive less or more assessments. Please keep in mind, we will not validate whether you got the answer correct or incorrect. This is with intention.',
    description:
      'Third paragraph in the body copy for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introBody3Standalone',
  },
  introBody4: {
    defaultMessage: 'Good luck!',
    description:
      'Fourth paragraph in the body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.introBody4',
  },
  introBody4Standalone: {
    defaultMessage:
      'This is all designed to save you time so you can focus more on what matters most.',
    description:
      'Fourth paragraph in the body copy for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introBody4Standalone',
  },
  introBody5Standalone: {
    defaultMessage: `When you complete, you can logout or close the browser window. That's all there is to it.`,
    description:
      'Fifth paragraph in the body copy for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introBody5Standalone',
  },
  introBody6Standalone: {
    defaultMessage: 'Good luck!',
    description:
      'Sixth paragraph in the body copy for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introBody6Standalone',
  },
  introHeader: {
    defaultMessage: 'Pre-test: Personalize your course',
    description: 'Header for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.introHeader',
  },
  introHeaderStandalone: {
    defaultMessage:
      'Welcome to Your Personalized Assessment Diagnostic for {courseTitle}',
    description: 'Header for the introduction screen in Snapshot Standalone.',
    id: 'SnapshotMessages.introHeaderStandalone',
  },
  // NOTE / TODO / WIP: This should be handled by Localization
  kpmgIntroBody1: {
    defaultMessage: 'Hi Learner,',
    description:
      'First paragraph in the KPMG specific body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.kpmgIntroBody1',
  },
  // NOTE / TODO / WIP: This should be handled by Localization
  kpmgIntroBody2: {
    defaultMessage: `You are about to customize your {courseTitle} {trainingExperience}. As part of this customization, you will receive a series of questions related to topics covered in this course. If your answers demonstrate that you already mastered that topic the section on that topic will automatically be marked complete (but the content for that section will be accessible if you want to review it). Feedback is not made available for questions answered.`,
    description:
      'Second paragraph in the KPMG specific body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.kpmgIntroBody2',
  },
  // NOTE / TODO / WIP: This should be handled by Localization
  kpmgIntroBody2CTA: {
    defaultMessage: 'training experience',
    description:
      'Second paragraph CTA in the KPMG specific body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.kpmgIntroBody2CTA',
  },
  // NOTE / TODO / WIP: This should be handled by Localization
  kpmgIntroBody3: {
    defaultMessage: `If you correctly answer the questions for each topic you will still be required to complete one section and the post course exam.`,
    description:
      'Third paragraph in the KPMG specific body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.kpmgIntroBody3',
  },
  // NOTE / TODO / WIP: This should be handled by Localization
  kpmgIntroBody4: {
    defaultMessage: 'Good luck!',
    description:
      'Fourth paragraph in the KPMG specific body copy for the introduction screen in Snapshot.',
    id: 'SnapshotMessages.kpmgIntroBody4',
  },
  // NOTE / TODO / WIP: This should be handled by Localization
  kpmgIntroHeader: {
    defaultMessage: 'Personalize your learning',
    description:
      'Header for the KPMG specific introduction screen in Snapshot.',
    id: 'SnapshotMessages.kpmgIntroHeader',
  },
  moreContentBelow: {
    defaultMessage: 'Scroll For More Content',
    description:
      'Banner to show that there is hidden content below the footer on Snapshot.',
    id: 'SnapshotMessages.moreContentBelow',
  },
  pretestHeader: {
    defaultMessage: 'Pre-Test: {courseTitle}',
    description: 'Header for the activity screen in Snapshot.',
    id: 'SnapshotMessages.pretestHeader',
  },
  refreshButton: {
    defaultMessage: 'Refresh Page',
    description: 'Button displayed when there is an issue loading an Activity.',
    id: 'SnapshotMessages.refreshButton',
  },
  skipQuestionButton: {
    defaultMessage: 'Skip',
    description: 'Button for skipping a question in Snapshot.',
    id: 'SnapshotMessages.skipQuestionButton',
  },
})

const initialStateActivity = {
  activityAnswer: null,
  activityModalImage: null,
  isActivityModalOpen: false,
  isScrollable: false,
  isScrolledToBottom: false,
}

const initialState = {
  ...initialStateActivity,
  skipButtonKey: Date.now(),
}

const getIdleTimeThreshold = (tab) => {
  if (tab === 'watch') return 15 * 60 * 1000 // 15 minutes
  return 5 * 60 * 1000 // 5 minutes
}

@injectIntl
// NOTE / TODO / WIP: We are using sessionStore to detect KPMG
// and display KPMG specific copy. This should be handled by Localization.
@inject('contentStore', 'sessionStore', 'uiStore', 'xapiStore')
@observer
class Snapshot extends Component {
  state = { ...initialState }
  componentDidMount() {
    const { contentStore } = this.props
    window.addEventListener('resize', this.debouncedCheckIfScrollable)
    setTimeout(this.checkIfScrollable, 0)
    contentStore.loadSnapshot().then(() => {
      const { snapshot } = contentStore
      const { isDone } = snapshot
      if (!isDone) {
        this.launchSession()
      }
    })
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedCheckIfScrollable)
    if (this.hasAddedScrollListener) {
      this.$content.removeEventListener('scroll', this.onScroll)
    }
    this.terminateSession()
  }
  checkIfScrollable = () => {
    const { isScrollable: wasScrollable } = this.state
    const totalHeight = this.$innerContent
      ? this.$innerContent.clientHeight
      : null
    const visibleHeight = this.$content ? this.$content.clientHeight : null
    checkIfScrollable(
      wasScrollable,
      totalHeight,
      visibleHeight,
      (isScrollable) => {
        this.setState({ isScrollable })
      },
    )
  }
  debouncedCheckIfScrollable = debounce(this.checkIfScrollable, 250)
  launchSession = () => {
    const { xapiStore } = this.props
    xapiStore.launchSession('snapshot')
  }
  terminateSession = () => {
    const { xapiStore } = this.props
    xapiStore.terminateSession()
  }
  onChangeActivity = (activityAnswer) => {
    this.setState({ activityAnswer })
  }
  onCloseActivityModal = () => {
    this.setState({
      activityModalImage: null,
      isActivityModalOpen: false,
    })
  }
  onCloseIdleModal = () => {
    const { uiStore } = this.props
    uiStore.closeIdleModal()
  }
  onDone = () => {
    const { contentStore } = this.props
    const { snapshot } = contentStore
    contentStore.updateCourseProgress().then(() => {
      snapshot.done()
    })
  }
  onIdle = () => {
    const { uiStore } = this.props
    uiStore.openIdleModal()
  }
  onConfirmIncomplete = (event) => {
    this.onSubmitActivity(event, true)
  }
  onNext = () => {
    const { contentStore } = this.props
    const { snapshot } = contentStore
    if (!snapshot) return
    snapshot.start()
    this.checkIfScrollable()
  }
  onOpenActivityModal = (e, image) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      activityModalImage: image,
      isActivityModalOpen: true,
    })
  }
  onScroll = debounce(() => {
    const { contentStore } = this.props
    const { isScrolledToBottom } = this.state
    const { snapshot } = contentStore
    const { isLoadingActivity } = snapshot || {}
    if (this.$content && this.$innerContent) {
      const scrollTop = this.$content.scrollTop + this.$content.clientHeight
      if (
        !isScrolledToBottom &&
        !isLoadingActivity &&
        checkIfScrolledToBottom(scrollTop, this.$innerContent.clientHeight)
      ) {
        this.setState({ isScrolledToBottom: true })
      }
    }
  }, 200)
  onSkipActivity = () => {
    const { contentStore } = this.props
    const { snapshot } = contentStore
    if (!snapshot) return
    this.setState({ activityAnswer: null, skipButtonKey: Date.now() })
    snapshot.saveActivity(null, true).then(() => {
      this.resetScrollPosition()
    })
  }
  onSubmitActivity = (event, confirmedIncomplete = false) => {
    const { contentStore, uiStore } = this.props
    const { activityAnswer } = this.state
    const { snapshot } = contentStore
    const { openIncompleteQuestionModal } = uiStore
    const { isSavingActivity } = snapshot || {}
    if (!activityAnswer || isSavingActivity) return
    if (!confirmedIncomplete) {
      const isValidAnswer = snapshot.validateAnswer(
        activityAnswer,
        openIncompleteQuestionModal,
      )
      if (!isValidAnswer) return
    }
    const answer = activityAnswer
    this.setState({ activityAnswer: null })
    snapshot.saveActivity(answer).then(() => {
      this.resetScrollPosition()
    })
  }
  resetScrollPosition = () => {
    this.setState({ isScrolledToBottom: false }, () => {
      this.checkIfScrollable()
    })
  }
  setContentRef = (el) => {
    this.$content = el
    if (!this.hasAddedScrollListener) {
      this.$content.addEventListener('scroll', this.onScroll)
      this.hasAddedScrollListener = true
    }
  }
  setInnerContentRef = (el) => {
    this.$innerContent = el
  }
  renderActivity() {
    const { contentStore, uiStore } = this.props
    const { activityAnswer, activityModalImage, isActivityModalOpen } =
      this.state
    const { snapshot } = contentStore
    const { scrollY } = uiStore
    const { isSavingActivity, activity } = snapshot || {}
    const {
      activityEmbed,
      activityType,
      answersCounter,
      hasHint,
      hasNotPassed,
      id: activityId,
      imageAlignment,
      isNewActivity,
      question,
      questionImage,
      questionImageDescription,
      questionImageTitle,
      questionVideo,
      replaceActivityMathFormulas,
      title: activityTitle,
      validationError: activityValidationError,
    } = activity || {}
    return !isActivityModalOpen ? (
      <Activity
        id={activityId}
        activityEmbed={activityEmbed}
        answersCounter={answersCounter}
        checkIfScrollable={this.checkIfScrollable}
        currentActivityId={activityId}
        dangerLabel={activityValidationError}
        hasHint={hasHint}
        image={questionImage}
        imageAlignment={imageAlignment}
        imageAlt={questionImageDescription || activityTitle}
        imageTitle={questionImageTitle || activityTitle}
        isDanger={hasNotPassed}
        isNewActivity={isNewActivity}
        isSavingActivity={isSavingActivity}
        onChange={this.onChangeActivity}
        onOpenActivityModal={this.onOpenActivityModal}
        onSubmit={this.onSubmitActivity}
        pageRef={this.$content}
        renderImageAfterQuestion={() =>
          this.renderImageAfterQuestion(
            questionImage,
            questionImageDescription || activityTitle,
            questionImageTitle || activityTitle,
          )
        }
        replaceActivityMathFormulas={replaceActivityMathFormulas}
        scrollY={scrollY}
        title={question}
        type={activityType}
        value={activityAnswer}
        video={questionVideo}
        // choices activities
        choice={{
          choices: activity.cleanedChoices,
        }}
        // input activities
        input={{
          isMultipleInput: activity.isMultipleInput,
          textBefore: activity.textBeforeInput,
          textAfter: activity.textAfterInput,
        }}
        // matching activity
        matching={{
          definitions: activity.pairs && activity.pairs.definitions,
          labels: activity.pairs && activity.pairs.labels,
        }}
        // sorting activity
        sortStack={{
          items: activity.pairs && activity.pairs.labels,
        }}
        // hotspot activity
        hotspot={activity.hotSpotData}
        // dragPhrase activity
        dragPhrase={{
          content: activity.cleanedContent,
          categories: activity.categories,
          items: activity.draggableItems,
        }}
      />
    ) : (
      <ActivityModal
        image={activityModalImage}
        isOpen={isActivityModalOpen}
        onClose={this.onCloseActivityModal}
      />
    )
  }
  renderActivityBody() {
    const { contentStore } = this.props
    const { snapshot } = contentStore
    const {
      activityError,
      isLoadingActivity,
      isSavingActivity,
      isSkippingActivity,
    } = snapshot || {}
    if (activityError) {
      return this.renderActivityError()
    }
    return isLoadingActivity || isSavingActivity || isSkippingActivity ? (
      <div className="Snapshot__loader-wrapper">
        <Loader className="Snapshot__loader" />
      </div>
    ) : (
      <Fragment>
        {this.renderActivity()}
        {this.renderActivityTimeTracking()}
      </Fragment>
    )
  }
  renderActivityError() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="Snapshot__activity-error">
        <Heading className="Snapshot__activity-error-header">
          {formatMessage(SnapshotMessages.activityErrorHeader)}
        </Heading>
        <p className="Snapshot__activity-error-body">
          {formatMessage(SnapshotMessages.activityErrorBody)}
        </p>
      </div>
    )
  }
  renderActivityButtons() {
    const { contentStore, intl } = this.props
    const { activityAnswer, skipButtonKey } = this.state
    const { snapshot } = contentStore
    const { formatMessage } = intl
    const { canSkip, isLoadingActivity, isSavingActivity, isSkippingActivity } =
      snapshot || {}
    return (
      <div className="Snapshot__footer__activityButtons">
        <div className="Snapshot__footer__buttonWrapper">
          <Modal.Button
            className="Snapshot__button Snapshot__button--is-confirm"
            disabled={
              !activityAnswer || _.isEmpty(activityAnswer) || isSkippingActivity
            }
            isLoading={isLoadingActivity || isSavingActivity}
            isSuccess
            key="Snapshot__button--is-confirm"
            onClick={this.onSubmitActivity}
            qa-id="learner-activity-confirm-button"
          >
            {formatMessage(SnapshotMessages.confirmAnswerButton)}
          </Modal.Button>
        </div>
        {canSkip && (
          <div className="Snapshot__footer__buttonWrapper">
            <Modal.Button
              className="Snapshot__button Snapshot__button--is-skip"
              disabled={isSavingActivity}
              isLoading={isLoadingActivity || isSkippingActivity}
              isSecondary
              key={`Snapshot__button--is-skip--${skipButtonKey}`}
              onClick={this.onSkipActivity}
            >
              {formatMessage(SnapshotMessages.skipQuestionButton)}
            </Modal.Button>
          </div>
        )}
      </div>
    )
  }
  renderActivityScreen() {
    const { contentStore } = this.props
    const { snapshot } = contentStore
    const {
      activityCounter,
      courseTitle,
      isLoadingActivity,
      isSavingActivity,
      isSkippingActivity,
      totalActivities,
    } = snapshot || {}
    const progress = Math.round(((activityCounter - 1) / totalActivities) * 100)
    return (
      <Fragment>
        <div
          className="Snapshot__header-progressMeter"
          style={{ width: `${progress > 100 ? 100 : progress}%` }}
        />
        <Modal.Header className="Snapshot__header Snapshot__header--is-activity">
          <Heading is2>
            <FormattedMessage
              {...SnapshotMessages.pretestHeader}
              values={{
                courseTitle: (
                  <span className="Snapshot__header-course-title">
                    {courseTitle}
                  </span>
                ),
              }}
            />
          </Heading>
        </Modal.Header>
        <Modal.Body
          className={cx('Snapshot__body Snapshot__body--is-activity', {
            'Snapshot__body--is-loading':
              isLoadingActivity || isSavingActivity || isSkippingActivity,
          })}
        >
          {this.renderActivityBody()}
        </Modal.Body>
      </Fragment>
    )
  }
  renderActivityTimeTracking() {
    const { contentStore, uiStore } = this.props
    const { snapshot } = contentStore
    const { isOpenIdleModal, isOpenIdleSessionModal } = uiStore
    const { activity, activitySectionId, activityUnitId, courseId } =
      snapshot || {}
    const { id: activityId } = activity
    return (
      <Fragment>
        <TimeTracking
          entityId={activityId}
          isIdle={isOpenIdleModal}
          // not actually passing section
          // used to pass courseId
          section={{
            courseId,
            id: activitySectionId,
            unitId: activityUnitId,
          }}
          what={TYPE_ACTIVITY}
        />
        <IdleTimer
          onIdle={this.onIdle}
          timeout={getIdleTimeThreshold('snapshot')}
          startOnLoad
        />
        <IdleModal
          isOpen={isOpenIdleModal && !isOpenIdleSessionModal}
          onClose={this.onCloseIdleModal}
          tab="snapshot"
        />
      </Fragment>
    )
  }
  renderButtons() {
    const { contentStore, intl } = this.props
    const { snapshot } = contentStore
    const { formatMessage } = intl
    const { activity, activityError, isDone, isIntro, isStandalone } =
      snapshot || {}
    if (isDone) return this.renderDoneButtons()
    if (isStandalone && isIntro) return this.renderIntroButtonsStandalone()
    if (isIntro) return this.renderIntroButtons()
    if (activity && activityError) {
      return (
        <Modal.Button
          className="Snapshot__button Snapshot__button--is-refresh"
          onClick={(event) => {
            event.preventDefault()
            window.location.reload()
          }}
        >
          {formatMessage(SnapshotMessages.refreshButton)}
        </Modal.Button>
      )
    }
    if (activity) return this.renderActivityButtons()
  }
  renderContent() {
    const { contentStore } = this.props
    const { snapshot } = contentStore
    const { activity, isDone, isIntro, isStandalone } = snapshot || {}
    if (isStandalone && isDone) return this.renderDoneScreenStandalone()
    if (isDone) return this.renderDoneScreen()
    if (isStandalone && isIntro) return this.renderIntroScreenStandalone()
    if (isIntro) return this.renderIntroScreen()
    if (activity) return this.renderActivityScreen()
    return <div>hello world</div>
  }
  renderDoneScreen() {
    return (
      <Fragment>
        <Modal.Header className="Snapshot__header Snapshot__header--is-done">
          {this.renderDoneScreenHeading()}
        </Modal.Header>
        <Modal.Body className="Snapshot__body Snapshot__body--is-done">
          {/* NOTE - disabled https://headware4.jira.com/browse/FM-3884 */}
          {/* <TrophyIcon className='Snapshot__trophy-icon' /> */}
          <AnimatedActivityIcon className="Snapshot__success-icon" />
          {this.renderDoneScreenBody()}
        </Modal.Body>
      </Fragment>
    )
  }
  renderDoneScreenHeading() {
    const { contentStore, intl } = this.props
    const { formatMessage } = intl
    const { snapshot } = contentStore
    const { numSectionsTestedOut, totalSections } = snapshot || {}
    if (numSectionsTestedOut === totalSections) {
      return (
        <Heading>
          {formatMessage(SnapshotMessages.completeHeaderTestedOutCourse)}
        </Heading>
      )
    }
    return (
      <Heading>
        {numSectionsTestedOut
          ? formatMessage(SnapshotMessages.completeHeaderTestedOut, {
              numSectionsTestedOut,
            })
          : formatMessage(SnapshotMessages.completeHeaderZeroTestedOut)}
      </Heading>
    )
  }
  renderDoneScreenBody() {
    const { contentStore, intl } = this.props
    const { formatMessage } = intl
    const { snapshot } = contentStore
    const { canTestOut, numSectionsTestedOut, totalSections } = snapshot || {}
    if (!canTestOut)
      return <p>{formatMessage(SnapshotMessages.completeBodyNoTestOut)}</p>
    if (numSectionsTestedOut === totalSections) {
      return (
        <Fragment>
          <p className="is-tested-out">
            {formatMessage(SnapshotMessages.completeBodyTestedOutCourse)}
          </p>
          <p>
            {formatMessage(SnapshotMessages.completeBodyTestedOutCourseSubtext)}
          </p>
        </Fragment>
      )
    }
    return (
      <Fragment>
        {numSectionsTestedOut ? (
          <p>
            {formatMessage(SnapshotMessages.completeBodyTestedOut, {
              numSectionsRemaining: totalSections - numSectionsTestedOut,
              numSectionsTestedOut,
            })}
          </p>
        ) : (
          <Fragment>
            <p>{formatMessage(SnapshotMessages.completeBodyZeroTestedOut1)}</p>
            <p>{formatMessage(SnapshotMessages.completeBodyZeroTestedOut2)}</p>
          </Fragment>
        )}
      </Fragment>
    )
  }
  renderDoneButtons() {
    const { contentStore, intl } = this.props
    const { isUpdatingCourseProgress } = contentStore
    const { formatMessage } = intl
    return (
      <Modal.Button
        className="Snapshot__button Snapshot__button--is-done"
        isLoading={isUpdatingCourseProgress}
        key="Snapshot__button--is-done"
        onClick={this.onDone}
      >
        {formatMessage(SnapshotMessages.launchCourseButton)}
      </Modal.Button>
    )
  }
  renderDoneScreenStandalone() {
    const { contentStore, intl } = this.props
    const { snapshot } = contentStore
    const { formatMessage } = intl
    const { courseTitle } = snapshot || {}
    return (
      <Fragment>
        <Modal.Header className="Snapshot__header Snapshot__header--is-done">
          <Heading>
            <FormattedMessage
              {...SnapshotMessages.completeHeaderStandalone}
              values={{ courseTitle }}
            />
          </Heading>
        </Modal.Header>
        <Modal.Body className="Snapshot__body Snapshot__body--is-done">
          <AnimatedActivityIcon className="Snapshot__success-icon" />
          <p>{formatMessage(SnapshotMessages.completeBodyStandalone)}</p>
        </Modal.Body>
      </Fragment>
    )
  }
  renderFooter() {
    const { contentStore } = this.props
    const { isScrollable, isScrolledToBottom } = this.state
    const { snapshot } = contentStore
    const { isDone, isStandalone } = snapshot || {}
    if (isDone && isStandalone) return
    return (
      <Modal.Footer className="Snapshot__footer">
        <MoreContentBelowBanner
          isScrollable={isScrollable}
          isScrolledToBottom={isScrolledToBottom}
          scrollableEl={this.$content}
          visibleHeight={this.$content ? this.$content.clientHeight : 240}
        />
        {this.renderButtons()}
      </Modal.Footer>
    )
  }
  renderImageAfterQuestion = (image, imageAlt, imageTitle) => {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div
        className="Activity-colImage"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <img
          className="Activity-image"
          src={image}
          alt={imageAlt}
          title={imageTitle}
        />
        <Button
          aria-label={formatMessage(SnapshotMessages.enlargeImage)}
          className="Activity-zoomImage"
          onClick={(e) => this.onOpenActivityModal(e, image)}
        >
          <span>{formatMessage(SnapshotMessages.enlargeImage)}</span>
          <i
            aria-hidden="true"
            className="fa fa-search-plus"
            title={formatMessage(SnapshotMessages.enlargeImage)}
          />
        </Button>
      </div>
    )
  }
  renderIntroScreen() {
    const {
      intl,
      // NOTE / TODO / WIP: We are using sessionStore to detect KPMG
      // and display KPMG specific copy. This should be handled by Localization.
      sessionStore,
    } = this.props
    const { formatMessage } = intl
    // NOTE / TODO / WIP: This should be handled by Localization
    const { session } = sessionStore
    const { orgId } = session || {}
    const isKPMG = orgId === 158
    return (
      <Fragment>
        <Modal.Header className="Snapshot__header Snapshot__header--is-intro">
          <Heading>
            {
              // NOTE / TODO / WIP: This should be handled by Localization
              isKPMG
                ? formatMessage(SnapshotMessages.kpmgIntroHeader)
                : formatMessage(SnapshotMessages.introHeader)
            }
          </Heading>
        </Modal.Header>
        <Modal.Body className="Snapshot__body Snapshot__body--is-intro">
          {this.renderIntroBody()}
        </Modal.Body>
      </Fragment>
    )
  }
  renderIntroBody() {
    const {
      contentStore,
      intl,
      // NOTE / TODO / WIP: We are using sessionStore to detect KPMG
      // and display KPMG specific copy. This should be handled by Localization.
      sessionStore,
    } = this.props
    const { course, snapshot } = contentStore
    const { numSections } = course
    const { canTestOut, courseTitle } = snapshot || {}
    const { formatMessage } = intl
    const { session, user } = sessionStore
    const { firstName } = user || {}
    const { orgId } = session || {}
    const isKPMG = orgId === 158
    if (!canTestOut) {
      return (
        <Fragment>
          <p>
            {
              // NOTE / TODO / WIP: This should be handled by Localization
              isKPMG
                ? formatMessage(SnapshotMessages.kpmgIntroBody1)
                : formatMessage(SnapshotMessages.introBody1, { firstName })
            }
          </p>
          <p>{formatMessage(SnapshotMessages.introBody2NoTestOut)}</p>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <p>
          {
            // NOTE / TODO / WIP: This should be handled by Localization
            isKPMG
              ? formatMessage(SnapshotMessages.kpmgIntroBody1)
              : formatMessage(SnapshotMessages.introBody1, { firstName })
          }
        </p>
        <p>
          {
            // NOTE / TODO / WIP: This should be handled by Localization
            isKPMG ? (
              <FormattedMessage
                {...SnapshotMessages.kpmgIntroBody2}
                values={{
                  courseTitle: (
                    <span className="Snapshot__body--is-medium">
                      {courseTitle}
                    </span>
                  ),
                  trainingExperience: (
                    <span className="Snapshot__body--is-medium">
                      {formatMessage(SnapshotMessages.kpmgIntroBody2CTA)}
                    </span>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                {...SnapshotMessages.introBody2}
                values={{
                  courseTitle: (
                    <span className="Snapshot__body--is-medium">
                      {courseTitle}
                    </span>
                  ),
                }}
              />
            )
          }
        </p>
        <p>
          {
            // NOTE / TODO / WIP: This should be handled by Localization
            isKPMG ? (
              <FormattedMessage
                {...SnapshotMessages.kpmgIntroBody3}
                values={{
                  totalSections: numSections,
                  totalSectionsSpan: (
                    <span className="Snapshot__body--is-medium">
                      {numSections}
                    </span>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                {...SnapshotMessages.introBody3}
                values={{
                  totalSections: numSections,
                  totalSectionsSpan: (
                    <span className="Snapshot__body--is-medium">
                      {numSections}
                    </span>
                  ),
                }}
              />
            )
          }
        </p>
        <p>
          {
            // NOTE / TODO / WIP: This should be handled by Localization
            formatMessage(
              isKPMG
                ? SnapshotMessages.kpmgIntroBody4
                : SnapshotMessages.introBody4,
            )
          }
        </p>
      </Fragment>
    )
  }
  renderIntroButtons() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <Modal.Button
        className="Snapshot__button Snapshot__button--is-next"
        key="Snapshot__button--is-next"
        onClick={this.onNext}
      >
        {formatMessage(SnapshotMessages.beginPreTestButton)}
      </Modal.Button>
    )
  }
  renderIntroScreenStandalone() {
    const { contentStore, intl, sessionStore } = this.props
    const { formatMessage } = intl
    const { snapshot } = contentStore
    const { courseTitle } = snapshot || {}
    const { user } = sessionStore
    const { firstName } = user || {}
    return (
      <Fragment>
        <Modal.Header className="Snapshot__header Snapshot__header--is-intro">
          <Heading>
            <FormattedMessage
              {...SnapshotMessages.introHeaderStandalone}
              values={{ courseTitle }}
            />
          </Heading>
        </Modal.Header>
        <Modal.Body className="Snapshot__body Snapshot__body--is-intro">
          <p>
            {formatMessage(SnapshotMessages.introBody1Standalone, {
              firstName,
            })}
          </p>
          <p>
            <FormattedMessage
              {...SnapshotMessages.introBody2Standalone}
              values={{
                courseTitle: (
                  <span className="Snapshot__body--is-medium">
                    {courseTitle}
                  </span>
                ),
              }}
            />
          </p>
          <p>{formatMessage(SnapshotMessages.introBody3Standalone)}</p>
          <p className="Snapshot__body--is-bold">
            {formatMessage(SnapshotMessages.introBody4Standalone)}
          </p>
          <p>{formatMessage(SnapshotMessages.introBody5Standalone)}</p>
          <p>{formatMessage(SnapshotMessages.introBody6Standalone)}</p>
        </Modal.Body>
      </Fragment>
    )
  }
  renderIntroButtonsStandalone() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <Modal.Button
        className="Snapshot__button Snapshot__button--is-next"
        key="Snapshot__button--is-next"
        onClick={this.onNext}
      >
        {formatMessage(SnapshotMessages.beginPreTestButtonStandalone)}
      </Modal.Button>
    )
  }
  renderMain() {
    const { contentStore, xapiStore } = this.props
    const { isLoadingSnapshot, snapshot } = contentStore
    const { isLaunchingSession } = xapiStore
    const { isLoadingActivity, isSavingActivity, isSkippingActivity } =
      snapshot || {}
    return (
      <Fragment>
        <div className="Snapshot__content" ref={this.setContentRef}>
          <div ref={this.setInnerContentRef}>
            {isLoadingSnapshot || isLaunchingSession ? (
              <Loader />
            ) : (
              this.renderContent()
            )}
          </div>
        </div>
        {!isLoadingSnapshot &&
        !isLaunchingSession &&
        !isLoadingActivity &&
        !isSavingActivity &&
        !isSkippingActivity
          ? this.renderFooter()
          : null}
      </Fragment>
    )
  }
  render() {
    const { contentStore, uiStore, xapiStore } = this.props
    const { activityAnswer } = this.state
    const { isLoadingSnapshot, snapshot } = contentStore
    const { isLaunchingSession } = xapiStore
    const { activity, activityError, isDone, isIntro, isOpen, isStandalone } =
      snapshot || {}
    const { closeIncompleteQuestionModal, isOpenIncompleteQuestionModal } =
      uiStore
    const answersCountTotals =
      activity &&
      activity.getAnswersCountTotals(
        activityAnswer,
        isOpenIncompleteQuestionModal,
      )
    const { numAnswersIncomplete, numAnswersTotal } = answersCountTotals || {}
    if (isLaunchingSession || isLoadingSnapshot) {
      return <Loader className="Snapshot__loader" />
    }
    return (
      <Fragment>
        <Modal
          className="Snapshot"
          isOpen={isOpen}
          overlayClassName="Snapshot__overlay"
        >
          <div
            className={cx('Snapshot__main', {
              'Snapshot__main--has-no-footer': isDone && isStandalone,
              'Snapshot__main--is-activity-error': activityError,
              'Snapshot__main--is-done': isDone,
              'Snapshot__main--is-intro': isIntro,
              'Snapshot__main--is-loading': isLoadingSnapshot,
            })}
            qa-id="learner-snapshot-modal"
          >
            {this.renderMain()}
          </div>
        </Modal>
        <IncompleteQuestionModal
          isOpen={isOpenIncompleteQuestionModal}
          numAnswersIncomplete={numAnswersIncomplete}
          numAnswersTotal={numAnswersTotal}
          onClose={closeIncompleteQuestionModal}
          onConfirm={this.onConfirmIncomplete}
        />
      </Fragment>
    )
  }
}

export default Snapshot
