import React from 'react'
import cx from 'classnames'

import { Button, CheckboxCustom, Heading } from '@/components'

import {
  SETTINGS_CATEGORY_SNAPSHOT,
  SNAPSHOT_STANDALONE_CHOICES,
  SNAPSHOT_STANDALONE_NAME,
} from './InstanceSettingsPageConstants'

const InstanceSettingsPageSnapshot = ({
  hasSnapshotSettings,
  isChecked,
  isEnabledSnapshot,
  isLoading,
  onChange,
  onSave,
}) => {
  return (
    <div className="InstanceSettingsPage__setting">
      <div className="InstanceSettingsPage__setting-item">
        <Heading is4 className="InstanceSettingsPage__setting-title">
          Snapshot
        </Heading>
        <span className="InstanceSettingsPage__setting-subtitle">
          Snapshot is an AI-powered standalone needs assessment tool to obtain a
          baseline measurement of an audiences’ proficiency on a given subject
          matter. This will also better inform you on which courses to initially
          migrate into Fulcrum’s transformative Adaptive 3.0 MicroLearning and
          Predictive Analytics platform.
          {!hasSnapshotSettings ? (
            <span className="InstanceSettingsPage__setting-subtitle-contact">
              Please contact Fulcrum Client Success at clients@fulcrumlabs.ai to
              learn more about this product add-on.
            </span>
          ) : null}
        </span>
        {hasSnapshotSettings &&
          SNAPSHOT_STANDALONE_CHOICES.map((choice, i) => (
            <div
              className={cx('InstanceSettingsPage-snapshot-standalone', {
                'InstanceSettingsPage__setting-disabled':
                  !hasSnapshotSettings || isEnabledSnapshot,
              })}
              key={`InstanceSettingsPage-snapshot-standalone-${i}`}
            >
              <CheckboxCustom
                checked={isChecked(SNAPSHOT_STANDALONE_NAME, choice.value)}
                className="InstanceSettingsPage-value--is-bold"
                disabled={!hasSnapshotSettings || isEnabledSnapshot}
                isRadio
                name={SNAPSHOT_STANDALONE_NAME}
                onChange={onChange}
                value={choice.value}
              >
                <span className="InstanceSettingsPage__value-label">
                  <span className="InstanceSettingsPage__value-text">
                    {choice.text}
                  </span>
                </span>
              </CheckboxCustom>
            </div>
          ))}
      </div>
      {hasSnapshotSettings && (
        <Button
          className="InstanceSettingsPage__setting-save"
          disabled={!hasSnapshotSettings || isEnabledSnapshot}
          isLoading={isLoading}
          isPrimary
          name={SETTINGS_CATEGORY_SNAPSHOT}
          onClick={onSave}
        >
          Save
        </Button>
      )}
    </div>
  )
}

export default InstanceSettingsPageSnapshot
