import cx from 'classnames'
import React, { Component, Fragment } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import AdminPage from '@/admin/components/AdminPage/AdminPage'

import ConfirmationModal from '@/admin/modals/ConfirmationModal/ConfirmationModal'
import ReportExportHelpModal from '@/admin/modals/ReportExportHelpModal/ReportExportHelpModal'

import AdminNotFoundPage from '@/admin/pages/AdminNotFoundPage/AdminNotFoundPage'
import InstanceUsersPage from '@/admin/pages/InstanceUsersPage/InstanceUsersPage'
import InstanceAnalyticsPage from '@/admin/pages/InstanceAnalyticsPage/InstanceAnalyticsPage'
import InstanceAnalyticsLearnerPage from '@/admin/pages/InstanceAnalyticsLearnerPage/InstanceAnalyticsLearnerPage'
import InstanceContentPage from '@/admin/pages/InstanceContentPage/InstanceContentPage'
import InstanceOverviewPage from '@/admin/pages/InstanceOverviewPage/InstanceOverviewPage'
import InstanceMessagesPage from '@/admin/pages/InstanceMessagesPage/InstanceMessagesPage'
import InstanceSettingsPage from '@/admin/pages/InstanceSettingsPage/InstanceSettingsPage'

import { Button, Dropdown, Heading, Link, Tabs } from '@/components'
import {
  REPORT_EXPORT_MODAL_VIEW_HELP,
  REPORT_EXPORT_MODAL_VIEW_INPROGRESS,
} from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'

import './InstancePage.scss'

const isDownloadURLExpired = (url) => {
  const urlQuery = qs.parse(url)
  const { Expires: expiresSec } = urlQuery
  const expiresMs = parseInt(expiresSec) * 1000
  return expiresMs < Date.now()
}

const initialState = {
  isLoadingReportExports: false,
  reportExportErrorModal: null,
  reportExportHelpModal: null,
  unreadMessagesCount: [],
}

@inject('adminStore', 'uiStore')
@observer
export default class InstancePage extends Component {
  state = {
    ...initialState,
  }
  componentDidMount() {
    const { adminStore, match } = this.props
    const { params } = match
    const { instanceId } = params
    adminStore.loadInstance(instanceId)
  }
  componentDidUpdate() {
    const { adminStore, location } = this.props
    const { instance, loadUnreadMessagesCountIntervalId } = adminStore
    const { course } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    const { pathname } = location
    if (settings && !disableMessaging && !loadUnreadMessagesCountIntervalId) {
      if (!pathname.match(/messages/)) {
        adminStore.startLoadUnreadMessagesCountInterval()
      }
      adminStore.loadUnreadMessagesCount()
    }
  }
  componentWillUnmount() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { course } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    if (!disableMessaging) {
      adminStore.stopLoadUnreadMessagesCountInterval()
    }
  }
  closeModal = (modal) => {
    const { uiStore } = this.props
    const update = {}
    if (hasOwnProperty(this.state, modal)) {
      update[modal] = null
      this.setState(update)
      uiStore.scrollY(null, uiStore.scrollTop)
    }
  }
  onClickLoadReportExports = () => {
    const { adminStore } = this.props
    const { isLoadingReportExports } = this.state
    const { instance, loadReportExportURL } = adminStore
    const courseId = instance.id
    if (isLoadingReportExports) return
    this.setState({ isLoadingReportExports: true })
    loadReportExportURL({ courseId, expired: true }).then(({ error, url }) => {
      if (error) {
        if (error.status === 404 || error.status === 410) {
          this.refreshReportExports()
        }
      } else if (url && !isDownloadURLExpired(url)) {
        this.setState({ isLoadingReportExports: false })
        window.location.assign(url)
      }
    })
  }
  onClickReportHelp = () => {
    this.setState({ reportExportHelpModal: REPORT_EXPORT_MODAL_VIEW_HELP })
  }
  refreshReportExports = () => {
    const { adminStore } = this.props
    const { instance } = adminStore
    instance
      .refreshReportExports()
      .then((isRefreshingReport) => {
        this.setState({ isLoadingReportExports: false })
        if (isRefreshingReport) {
          this.setState({
            reportExportHelpModal: REPORT_EXPORT_MODAL_VIEW_INPROGRESS,
          })
        } else {
          this.setState({
            reportExportErrorModal: true,
          })
        }
      })
      .catch((error) => {
        console.error('refreshReportExportsError', error)
        this.setState({ isLoadingReportExports: false })
      })
  }
  setUnreadMessagesCount = (unreadMessagesCount) => {
    this.setState({ unreadMessagesCount })
  }
  renderHeader = () => {
    const { adminStore, location, match } = this.props
    const { isLoadingReportExports, unreadMessagesCount } = this.state
    const { instance } = adminStore
    const { course, id: instanceId, isAdmin, title } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    const { pathname } = location
    const { url } = match
    const isMessagesActive = pathname.match(/\/admin\/courses\/\d+\/messages/)
    const hasUnread =
      unreadMessagesCount && Array.isArray(unreadMessagesCount)
        ? unreadMessagesCount.filter(
            (unread) => unread.courseId === parseInt(instanceId),
          )[0]
        : false
    const unreadCount =
      hasUnread && hasUnread.unreadCount ? hasUnread.unreadCount : 0
    const isUnread = !isMessagesActive && unreadCount
    return (
      <div className="InstancePage__header">
        <div className="InstancePage__header-item">
          <Heading is1>
            <span>{title}</span>
          </Heading>
        </div>
        <div className="InstancePage__header-item">
          <Tabs isAlt>
            <Link nav to={`${url}/overview`}>
              Overview
            </Link>
            {isAdmin ? (
              <Link nav to={`${url}/users`}>
                Admin
              </Link>
            ) : null}
            <Link
              className={isAdmin ? 'is-tablet' : ''}
              nav
              to={`${url}/analytics`}
            >
              Learner Data
            </Link>
            <Link className="is-tablet" nav to={`${url}/content`}>
              Content Data
            </Link>
            {!disableMessaging ? (
              <Link
                className={cx('InstancePage-messagesLink', {
                  'is-unread': isUnread,
                })}
                nav
                to={`${url}/messages`}
              >
                <span>Messages</span>
                {isUnread ? (
                  <span className="InstancePage-messagesUnreadCount">
                    {unreadCount > 9 ? '9+' : unreadCount}
                  </span>
                ) : null}
              </Link>
            ) : null}
            {isAdmin ? (
              <Link className="is-desktop" nav to={`${url}/settings`}>
                Settings
              </Link>
            ) : null}
          </Tabs>
        </div>
        <div className="InstancePage__header-item">
          <Dropdown
            ariaLabel="reports"
            icon="fa-file-excel-o"
            label="Reports"
            isSimple
            preventCloseOnClickItem
          >
            <Button
              disabled={isLoadingReportExports}
              isLoading={isLoadingReportExports}
              onClick={() => this.onClickLoadReportExports()}
            >
              Course Report Export
            </Button>
            <Button onClick={this.onClickReportHelp}>
              What&apos;s In Reports?
            </Button>
          </Dropdown>
        </div>
      </div>
    )
  }
  renderNotFound() {
    return (
      <div className="InstancePage-notFound">
        <div className="InstancePage-notFoundHeader">
          <h1>There was an issue loading the Course</h1>
        </div>
        <div className="InstancePage-notFoundMain">
          <p>
            Please confirm that the URL is correct and the Course is not set to
            &apos;Draft&apos; in Contentful.
          </p>
          <Button to="/admin/courses" isInfo>
            Back to Courses
          </Button>
        </div>
      </div>
    )
  }
  render() {
    const { adminStore, location, match } = this.props
    const { reportExportErrorModal, reportExportHelpModal } = this.state
    const { instance, isCourseNotFound, isLoadingCourses } = adminStore
    const {
      isAdmin,
      isInitialized,
      isLoadingCourse,
      isLoadingSettings,
      liveStatus,
    } = instance
    const { path, url } = match
    const { state } = location
    const query = qs.stringify(state)
    if (liveStatus === 'Offline') return <Redirect to="/" />
    if (
      (!isCourseNotFound && !isInitialized) ||
      isLoadingCourses ||
      isLoadingCourse ||
      isLoadingSettings
    )
      return <AdminPage isLoading />
    if (isCourseNotFound) return this.renderNotFound()
    return (
      <Fragment>
        <div className="InstancePage-backToCourseList">
          <Link aria-label="Go to courses" to={`/admin/courses?${query}`}>
            <i
              className="fa fa-angle-left"
              aria-hidden="true"
              title="Go to courses"
            />
            &nbsp;<span>Back to Course List</span>
          </Link>
        </div>
        <AdminPage
          className="InstancePage"
          isAlt
          title="Instance"
          renderHeader={this.renderHeader}
        >
          <Switch>
            <Redirect exact from={url} to={`${url}/overview`} />
            <Redirect
              exact
              from={`${url}/analytics/learners`}
              to={`${url}/analytics`}
            />
            <Route
              exact
              path={`${path}/overview`}
              component={InstanceOverviewPage}
            />
            {isAdmin ? (
              <Route
                exact
                path={`${path}/users`}
                component={InstanceUsersPage}
              />
            ) : null}
            <Route
              exact
              path={`${path}/analytics/learners/:learnerId`}
              component={InstanceAnalyticsLearnerPage}
            />
            <Route
              exact
              path={`${path}/analytics`}
              component={InstanceAnalyticsPage}
            />
            <Route
              exact
              path={`${path}/content`}
              component={InstanceContentPage}
            />
            <Route
              exact
              path={`${path}/messages/:threadId`}
              component={InstanceMessagesPage}
            />
            <Route
              exact
              path={`${path}/messages`}
              component={InstanceMessagesPage}
            />
            <Route
              exact
              path={`${path}/settings/:settingsCategory`}
              component={InstanceSettingsPage}
            />
            <Redirect
              exact
              from={`${path}/settings`}
              to={`${path}/settings/general`}
            />
            <Redirect to="/admin/not-found" component={AdminNotFoundPage} />
          </Switch>
          <ConfirmationModal
            className="ReportExportErrorModal"
            title="Course Report Export"
            description="There was an issue loading the course report export, please try again later."
            confirmText="OK"
            isCancelHidden
            isOpen={!!reportExportErrorModal}
            onClose={() => this.closeModal('reportExportErrorModal')}
            onConfirm={() => this.closeModal('reportExportErrorModal')}
          />
          <ReportExportHelpModal
            isOpen={!!reportExportHelpModal}
            onClose={() => this.closeModal('reportExportHelpModal')}
            onConfirm={() => this.closeModal('reportExportHelpModal')}
            view={reportExportHelpModal}
          />
        </AdminPage>
      </Fragment>
    )
  }
}
