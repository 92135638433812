import React, { Component, Children } from 'react'
import cx from 'classnames'
import ClickOutside from 'react-click-outside'

import './Dropdown.scss'

export default class Dropdown extends Component {
  state = {
    isActive: false,
  }

  toggle = () => {
    this.setState((state) => ({ isActive: !state.isActive }))
  }

  close = () => {
    this.setState({ isActive: false })
  }

  render() {
    const { ariaLabel, icon, preventCloseOnClickItem } = this.props
    const className = cx(
      'Dropdown',
      {
        'is-active': this.state.isActive,
        'is-simple': this.props.isSimple,
      },
      this.props.className,
    )
    const expandAriaLabel = ariaLabel
      ? `Expand ${ariaLabel} dropdown`
      : 'Expand dropdown'
    const collapseAriaLabel = ariaLabel
      ? `Collapse ${ariaLabel} dropdown`
      : 'Collapse dropdown'
    return (
      <ClickOutside className={className} onClickOutside={this.close}>
        <button
          className="Dropdown-label"
          onClick={this.toggle}
          aria-label={cx({
            [expandAriaLabel]: !this.state.isActive,
            [collapseAriaLabel]: this.state.isActive,
          })}
        >
          {this.props.label}
          <div className="icon is-small Dropdown-icon">
            <i
              className={`fa ${icon || 'fa-angle-down'}`}
              aria-hidden="true"
              title={cx({
                [expandAriaLabel]: !this.state.isActive,
                [collapseAriaLabel]: this.state.isActive,
              })}
            />
          </div>
        </button>
        <ul className="Dropdown-list">
          {Children.map(this.props.children, (child, index) => (
            <li
              className="Dropdown-item"
              key={`Dropdown-item-${index}`}
              onClick={preventCloseOnClickItem ? null : this.close}
            >
              {child}
            </li>
          ))}
        </ul>
      </ClickOutside>
    )
  }
}
