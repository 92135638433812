import React, { Component } from 'react'
import { UpdatesBanner } from '@/components'
import './CourseIntroBanner.scss'
class CourseIntroBanner extends Component {
  render() {
    const { courseIntro, isOpen } = this.props
    return (
      <UpdatesBanner
        className="CourseIntroBanner"
        isHiddenButtons
        isHiddenHeader
        isHTML
        isOpen={isOpen}
      >
        <div
          className="CourseIntroBanner-content"
          dangerouslySetInnerHTML={{ __html: courseIntro }}
        />
      </UpdatesBanner>
    )
  }
}
export default CourseIntroBanner
