import axios from 'axios'

import SentryReplay from '@/SentryReplay'
import { sanitizeBody, sanitizeUrl } from '@/utils/helpers'

const api = axios.create({
  baseURL: '/locales/',
  timeout: 50000,
  headers: {},
})

let options = {
  handleResponse: (res) => Promise.resolve(res),
  handleErrors: (err) => Promise.reject(err),
}

api.interceptors.request.use(
  (request) => {
    const { method, url } = request || {}
    SentryReplay.addBreadcrumb({
      category: 'httpRequest',
      message: `${method}: ${sanitizeUrl(url)}`,
      level: 'info',
      type: 'default',
    })
    return request
  },
  (error) => {
    const { config } = error || {}
    const { method, url } = config || {}
    SentryReplay.captureMessage(
      `${method}: ${sanitizeUrl(url)} - Request Error`,
      'error',
      {
        type: 'axios.interceptors.request.error',
      },
    )
    return Promise.reject(error)
  },
)

function _setResponseExtraData(response = {}) {
  const { config, status } = response
  const { data, method, url } = config || {}
  return {
    body: sanitizeBody(data),
    canceledRequest: status === 0,
    method,
    serverError: status >= 500,
    status: status,
    url,
  }
}

api.interceptors.response.use(
  (response) => {
    const { body, method, status, url } = _setResponseExtraData(response)
    SentryReplay.addBreadcrumb({
      category: 'httpResponse',
      data: body,
      message: `(${status}) ${method}: ${url}`,
      level: 'info',
      type: 'default',
    })
    return response
  },
  (error) => {
    const { response } = error || {}
    const { method, status, url } = _setResponseExtraData(response)
    if (status === 0 || status >= 500) {
      SentryReplay.captureMessage(
        `(${status}) ${method}: ${sanitizeUrl(url)} - Response Error`,
        'error',
        {
          type: 'axios.interceptors.response.error',
        },
      )
    }
    return Promise.reject(error)
  },
)

class LocaleRequest {
  options(params) {
    options = { ...options, ...params }
  }

  handleResponse = (res) => res

  get = (url, config) =>
    api
      .get(`${url}.json`, config)
      .then(options.handleResponse)
      .catch(options.handleErrors)
}

export default new LocaleRequest()
