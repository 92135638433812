import React, { Component } from 'react'
import cx from 'classnames'
import { Button, ProgressChart } from '@/components'
import { TransitionHeight } from '@/components/animations'
import {
  __getActivityType,
  __getActivityTypeLabel,
} from './InstanceContentPage'

function __normalizeNumber(num) {
  return num.toString().indexOf('.') !== -1 ? num.toFixed(2) : num
}

export default class InstanceContentPageActivity extends Component {
  state = { isActive: false }
  get activityTypeLabel() {
    const { activity } = this.props
    const { activityType: type } = activity
    const activityType = __getActivityType(type)
    return __getActivityTypeLabel(activityType)
  }
  componentDidUpdate(prevProps) {
    const { activity } = this.props
    const { isActive } = this.state
    const { activity: prevActivity } = prevProps
    const { id: activityId } = activity
    const { id: prevActivityId } = prevActivity
    if (isActive && activityId !== prevActivityId) {
      this.setState({ isActive: false })
    }
  }
  onAccordionChange = (event) => {
    const { isActive } = this.state
    event.preventDefault()
    this.setState({ isActive: !isActive })
  }
  render() {
    const { activity, onClickOpenActivityModal } = this.props
    const { isActive } = this.state
    const {
      avgAttempt: activityAverageAttempts,
      avgFirst: activityAverageFAPR,
      avgPost: activityAveragePFAPR,
      id: activityId,
      rank: activityRank,
      title: activityTitle,
      totalAttempt: activityTotalAttempts,
    } = activity
    return (
      <div
        className={cx('InstanceContentPage__activity', {
          'InstanceContentPage__activity--is-at-risk':
            activityRank === 'at-risk',
          'InstanceContentPage__activity--is-good': activityRank === 'good',
          'InstanceContentPage__activity--is-all-star':
            activityRank === 'all-star',
        })}
        key={`InstanceContentPage__activity-${activityId}`}
      >
        <div
          className="InstanceContentPage__activity-header"
          onClick={this.onAccordionChange}
        >
          <div className="InstanceContentPage__activity-header-column">
            <h3 className="InstanceContentPage__activity-title">
              <Button
                aria-expanded={isActive}
                aria-label={`${
                  !isActive ? 'Expand' : 'Collapse'
                } ${activityTitle}`}
                className="InstanceContentPage__activity-toggle"
                isText
              >
                <i
                  className={cx('fa', {
                    'fa-minus-square-o': isActive,
                    'fa-plus-square-o': !isActive,
                  })}
                />
              </Button>
              <span>{activityTitle}</span>
            </h3>
          </div>
          <div className="InstanceContentPage__activity-header-column">
            <span>{this.activityTypeLabel}</span>
          </div>
        </div>
        <TransitionHeight in={isActive}>
          <div className="InstanceContentPage__activity-content">
            <div className="InstanceContentPage__activity-content-header">
              <div className="InstanceContentPage__activity-attempts-count">
                <h3>Average Attempts</h3>
                <span>
                  {activityAverageAttempts
                    ? __normalizeNumber(activityAverageAttempts)
                    : 0}
                </span>
              </div>
              <div className="InstanceContentPage__activity-learner-count">
                <h3>Total Attempts</h3>
                <span>{activityTotalAttempts || 0}</span>
              </div>
              <div className="InstanceContentPage__activity-modal-button">
                <Button
                  isSuccess
                  onClick={() => onClickOpenActivityModal(activityId)}
                >
                  Question & Answers
                </Button>
              </div>
            </div>
            <div className="InstanceContentPage__activity-content-performance">
              <div className="InstanceContentPage__activity-question-performance">
                <h3>Question Performance</h3>
                <span className="is-description">
                  Pass rate on first attempt of a question.
                </span>
                <ProgressChart
                  isHiddenAverage
                  theme={activityRank}
                  value={
                    activityAverageFAPR
                      ? __normalizeNumber(activityAverageFAPR * 100)
                      : 0
                  }
                  valueLabel="Average"
                />
              </div>
              <div className="InstanceContentPage__activity-subsequent-performance">
                <h3>Subsequent Performance</h3>
                <span className="is-description">
                  Average pass rate after the first attempt.
                </span>
                <ProgressChart
                  isHiddenAverage
                  theme={activityRank}
                  value={
                    activityAveragePFAPR
                      ? __normalizeNumber(activityAveragePFAPR * 100)
                      : 0
                  }
                  valueLabel="Average"
                />
              </div>
            </div>
          </div>
        </TransitionHeight>
      </div>
    )
  }
}
