import React, { Component } from 'react'
import { inject } from 'mobx-react'
import { defineMessages, injectIntl } from 'react-intl'
import { Heading, Modal } from '@/components'

import './IdleModal.scss'

const SessionExpiredMessages = defineMessages({
  body: {
    defaultMessage: 'You have been logged out due to inactivity.',
    description: 'Body of the Session Expired Idle Modal.',
    id: 'SessionExpired.body',
  },
  okay: {
    defaultMessage: 'OK',
    description: 'Okay button in the Session Expired Idle Modal.',
    id: 'SessionExpired.okay',
  },
  title: {
    defaultMessage: 'Session Expired',
    description: 'Title of the Session Expired Idle Modal.',
    id: 'SessionExpired.title',
  },
})

@injectIntl
@inject('uiStore')
export default class SessionExpiredModal extends Component {
  onClose = () => {
    const { uiStore, onClose } = this.props
    if (onClose) {
      onClose()
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  render() {
    const { intl, isOpen } = this.props
    const { formatMessage } = intl
    return (
      <Modal
        className="SessionExpiredModal"
        contentLabel="Session Expired Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading className="SessionExpiredModal__title">
            {formatMessage(SessionExpiredMessages.title)}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <p>{formatMessage(SessionExpiredMessages.body)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button
            className="SessionExpiredModal__confirm"
            onClick={this.onClose}
          >
            {formatMessage(SessionExpiredMessages.okay)}
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
