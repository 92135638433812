import React, { Component } from 'react'
import { Heading } from '@/components'
import FulcrumAnimated from '@/assets/fulcrum_animated.gif'
import { defineMessages, injectIntl } from 'react-intl'

import './MaintenanceBanner.scss'

const MaintenanceBannerMessages = defineMessages({
  body1: {
    defaultMessage: `Until we return, take a break and treat yourself to some Netflix! If you need a recommendation, we suggest The Queen's Gambit. Talk about building mastery and confidence!`,
    description: 'Body text (1) on the Maintenance Banner page',
    id: 'MaintenanceBanner.body1',
  },
  body2: {
    defaultMessage: `We'll be back up in a few hours. Thank you for your patience.`,
    description: 'Body text (2) on the Maintenance Banner page',
    id: 'MaintenanceBanner.body2',
  },
  header: {
    defaultMessage: 'Fulcrum Labs is down for planned maintenance',
    description: 'Header on the Maintenance Banner page',
    id: 'MaintenanceBanner.header',
  },
  mission: {
    defaultMessage: 'Our Mission',
    description:
      'Button text to open Fulcrum mission video on the Maintenance Banner page',
    id: 'MaintenanceBanner.mission',
  },
  status: {
    defaultMessage: 'Current Status:',
    description:
      'Body text (3) on the Maintenance Banner page that directs users to the status page',
    id: 'MaintenanceBanner.status',
  },
})

@injectIntl
export default class MaintenanceBanner extends Component {
  state = {
    isPlayingVideo: false,
  }
  handleVideoClose = () => {
    this.setState({ isPlayingVideo: false })
  }
  handleVideoOpen = () => {
    this.setState({ isPlayingVideo: true })
  }
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="MaintenanceBanner">
        <div className="MaintenanceBanner__gif">
          <img src={FulcrumAnimated} />
        </div>
        <div className="MaintenanceBanner__text">
          <Heading is1>
            {formatMessage(MaintenanceBannerMessages.header)}
          </Heading>
          <div className="MaintenanceBanner__text-body">
            <p>{formatMessage(MaintenanceBannerMessages.body1)}</p>
            <p>{formatMessage(MaintenanceBannerMessages.body2)}</p>
            <p>
              {formatMessage(MaintenanceBannerMessages.status)}{' '}
              <a
                href="https://status.fulcrumlabs.ai/"
                target="_blank"
                rel="noreferrer"
              >
                https://status.fulcrumlabs.ai/
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
