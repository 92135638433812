import { action, computed, observable } from 'mobx'
import _ from 'lodash'
import humps from 'lodash-humps'
import moment from 'moment'
import qs from 'query-string'

import {
  MESSAGE_READ_STATUS,
  MESSAGE_UNREAD_STATUS,
  PAGE_LIMIT,
  REFRESH_RATE,
  SUMMARY_LENGTH,
} from '@/constants'
import {
  SETTINGS_CATEGORY_EXAM,
  SETTINGS_CATEGORY_PRETEST,
  SETTINGS_CATEGORY_SNAPSHOT,
} from '@/admin/pages/InstanceSettingsPage/InstanceSettingsPageConstants'
import SentryReplay from '@/SentryReplay'
import Activity from '@/stores/models/activity'
import Message from '@/stores/models/message'
import Report from '@/stores/models/report'
import Thread from '@/stores/models/thread'
import User from '@/stores/models/user'
import { hasOwnProperty, toCamelCase } from '@/utils/helpers'
import request from '@/utils/request'

const isPassword = (value) => /^(?=.*[A-Za-z])(?=.*\d).{5,100}$/.test(value)

function getSettingsField(settings, field) {
  if (settings) {
    const { fields } = settings
    if (!field) {
      return fields
    }
    if (hasOwnProperty(fields, field)) {
      return fields[field]
    }
  }
  return null
}

const POST_KEYS_SETTINGS = {
  exam: 'exam',
  examPass: 'exam_pass',
  learningMasteryRequirements: 'learner_dashboard',
  learningMode: 'required_mastery_level',
  memoryBoosterMode: 'memory_booster_settings',
  snapshot: 'snapshot',
  snapshotStandalone: 'snapshot_standalone',
  snapshotTestOut: 'snapshot_test_out',
}

export default class Instance {
  @observable id
  @observable adminsCount
  @observable analyticsFilters
  @observable analyticsSort = {
    // sortBy options:
    // 'first_name'
    // 'completion'
    // 'last_activity'
    // 'time_spent'
    // 'rank'
    sortBy: 'first_name',
    // sortOrientation options:
    // 'asc'
    // 'desc'
    sortOrientation: 'asc',
  }
  @observable contentFilters = {
    filters: {},
    filteredUnits: [],
    filterOptions: {},
  }
  @observable contentfulId
  @observable endDate
  @observable instructorsCount
  @observable isAdmin = false
  @observable isInstructor = false
  @observable learnersCount
  @observable liveStatus
  @observable integrationSettings = null
  @observable settings = null
  @observable status
  @observable title

  @observable course

  @observable report
  @observable reportUnit
  @observable reportUnitIds = []
  @observable reportUnits = []
  @observable reportUrl

  @observable refreshRate = REFRESH_RATE
  @observable threads = []
  @observable threadsCount = 0

  @observable users
  @observable usersSearch = ''
  @observable usersSearchCount

  @observable isInitialized = false

  @observable isLoadingActivityDetails = false
  @observable isLoadingCourse = false
  @observable isLoadingCourseEntries = false
  @observable isLoadingDstInstructors = false
  @observable isLoadingInstructors = false
  @observable isLoadingMessages = false
  @observable isLoadingMoreMessages = false
  @observable isLoadingRecipients = false
  @observable isLoadingReport = false
  @observable isLoadingReportExports = false
  @observable isLoadingReportUnit = false
  @observable isLoadingReportUnits = false
  @observable isLoadingSettings = false
  @observable isLoadingThread = false
  @observable isLoadingThreads = false
  @observable isLoadingMoreThreads = false
  @observable isLoadingUsers = false

  @observable isDeactivatingCourse = false
  @observable isEditingCourse = false
  @observable isEditingUser = false
  @observable isLoadSettingsError = false
  @observable isMarkingThreadAsRead = false
  @observable isSavingGroupMessage = false
  @observable isSavingMessage = false
  @observable isSavingThread = false
  @observable isSavingExistingUser = false
  @observable isSavingUser = false
  @observable isSavingUsers = false
  @observable isThreadsLoaded = false
  @observable isTransferringStudents = false
  @observable isUpdatingExam = false
  @observable isUpdatingPretest = false
  @observable isUpdatingSnapshot = false
  @observable isUpdatingSettings = false
  @observable isUpdatingSettingsError = false
  @observable updateSettingsErrors = {}
  @observable isUploadingUsers = false
  @observable isWithdrawingUser = false

  @computed get admin() {
    return this.users
      ? this.users.filter(
          (userObj) =>
            !!userObj.roles.filter((role) => role.type === 'admin').length,
        )
      : []
  }
  @computed get instructors() {
    return this.users
      ? this.users.filter(
          (userObj) =>
            !!userObj.roles.filter((role) => role.type === 'instructor').length,
        )
      : []
  }
  @computed get students() {
    return this.users
      ? this.users.filter(
          (userObj) =>
            !!userObj.roles.filter((role) => role.type === 'student').length,
        )
      : []
  }

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
    if (this.id) {
      this.loadCourse()
      this.loadReport()
      this.loadSettings()
      this.isInitialized = true
    }
  }

  @action deactivateCourse() {
    this.isDeactivatingCourse = true
    return request
      .post(`/admin/${this.id}/suspend`)
      .then(this.handleDeactivateCourse)
      .catch(this.handleDeactivateCourseError)
  }
  @action handleDeactivateCourse = () => {
    this.isDeactivatingCourse = false
    return Promise.resolve(true)
  }
  @action handleDeactivateCourseError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isDeactivatingCourse = false
    if (response) {
      const { data, status } = response
      if (status === 404) {
        const { message } = data
        return Promise.reject(new Error(message))
      }
    }
    return Promise.reject(
      new Error('Could not deactivate course, please try again later.'),
    )
  }

  @action editCourse({ lms, xapi }) {
    const { lmsSettings, xapiEndpoint } = this.course
    const PUT = {}
    let isEmptyLMS = true
    let isEmptyXAPI = true
    Object.keys(lms).forEach((key) => {
      if (lms[key]) {
        isEmptyLMS = false
      }
    })
    Object.keys(xapi).forEach((key) => {
      if (xapi[key]) {
        isEmptyXAPI = false
      }
    })
    if (!isEmptyLMS) {
      PUT['lms'] = {
        key: lms.key || lmsSettings.key,
        secret: lms.secret || lmsSettings.secret,
        url: lms.url || lmsSettings.url,
      }
    }
    if (!isEmptyXAPI) {
      PUT['xapi'] = {
        password: xapi.password || xapiEndpoint.password,
        url: xapi.url || xapiEndpoint.url,
        username: xapi.username || xapiEndpoint.username,
      }
    }
    if (isEmptyLMS && isEmptyXAPI) {
      return Promise.resolve()
    }
    this.isEditingCourse = true
    return request
      .put(`/admin/${this.id}/edit`, PUT)
      .then(this.handleEditCourse)
      .catch(this.handleEditCourseError)
  }
  @action handleEditCourse = () => {
    this.isEditingCourse = false
    return Promise.resolve()
  }
  @action handleEditCourseError = (error) => {
    SentryReplay.captureException(error)
    this.isEditingCourse = false
    return Promise.reject(error)
  }

  @action editUser({
    confirmPassword,
    courseRoles,
    userId,
    firstName,
    lastName,
    email,
    password,
  }) {
    const PUT = {
      course_roles: courseRoles,
      email,
      first_name: firstName,
      last_name: lastName,
    }
    const errors = {}
    if (!email) {
      errors['email'] = ['Field may not be empty.']
    }
    if (!firstName) {
      errors['firstName'] = ['Field may not be empty.']
    }
    if (!lastName) {
      errors['lastName'] = ['Field may not be empty.']
    }
    if (!userId) {
      errors['confirm'] = ['User ID is not defined.']
    }
    // optional fields
    if (password) {
      if (!confirmPassword) {
        errors['confirmPassword'] = [
          'The new password and password confirmation do not match.',
        ]
      } else if (password !== confirmPassword) {
        errors['confirmPassword'] = [
          'The new password and password confirmation do not match.',
        ]
      } else if (password === confirmPassword) {
        if (isPassword(password)) {
          PUT['password'] = password
          PUT['password_confirmation'] = confirmPassword
        } else {
          errors['password'] = [
            'The password you entered must be between 5 and 100 characters and contain both letters and numbers.',
          ]
          errors['confirmPassword'] = [
            'The password you entered must be between 5 and 100 characters and contain both letters and numbers.',
          ]
        }
      }
    } else if (confirmPassword) {
      errors['password'] = [
        'The new password and password confirmation do not match.',
      ]
    }
    if (
      courseRoles[0].roles.indexOf('student') !== -1 &&
      !courseRoles[0]['instructor_id']
    ) {
      errors['instructorId'] = ['Field may not be empty.']
    }
    if (Object.keys(errors).length) {
      return Promise.reject(new Error(JSON.stringify(errors)))
    }
    this.isEditingUser = true
    return request
      .put(`/admin/users/${userId}/edit`, PUT)
      .then(this.handleEditUser)
      .catch(this.handleEditUserError)
  }
  @action handleEditUser = () => {
    this.isEditingUser = false
    return Promise.resolve()
  }
  @action handleEditUserError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isEditingUser = false
    if (response) {
      const { data, status } = response
      if (data) {
        const { errors, message } = data
        if (status === 409) {
          return Promise.reject(new Error(JSON.stringify({ email: [message] })))
        } else if (status === 404) {
          return Promise.reject(
            new Error(JSON.stringify({ confirm: [message] })),
          )
        }
        if (errors) {
          return Promise.reject(new Error(JSON.stringify(humps(errors))))
        }
      }
    }
    return Promise.reject(
      new Error(
        JSON.stringify({
          confirm: ['There was an error while editing the user.'],
        }),
      ),
    )
  }

  @action getThreadById(threadId) {
    return (
      this.threads.filter((thread) => thread.id === parseInt(threadId))[0] ||
      null
    )
  }

  @action loadActivityDetails(activityId) {
    this.isLoadingActivityDetails = true
    return request
      .get(`/admin/${this.id}/activity/${activityId}`)
      .then(this.handleActivityDetails)
      .catch(this.handleActivityDetailsError)
  }
  @action handleActivityDetails = (response) => {
    const { data } = response
    const {
      activity: activityData,
      correct_answers: activityDataCorrectAnswers,
    } = data
    const { type: activityDataType } = activityData
    const {
      activity,
      correctAnswers,
      commonIncorrectAnswers,
      commonIncorrectAnswerMetrics,
    } = humps(data)
    this.isLoadingActivityDetails = false
    return {
      activity: new Activity(activity),
      correctAnswers:
        activityDataType === 'activityDragPhrase'
          ? activityDataCorrectAnswers
          : correctAnswers,
      incorrectAnswers: commonIncorrectAnswers,
      incorrectAnswerMetrics: commonIncorrectAnswerMetrics,
    }
  }
  @action handleActivityDetailsError = (error) => {
    const { response } = error || {}
    SentryReplay.captureException(error)
    this.isLoadingActivityDetails = false
    if (response) {
      const { status } = response
      if (status === 404) {
        return Promise.reject(
          new Error(
            'The activity could not be found. It may be set to Draft or Archived in Contentful.',
          ),
        )
      }
    }
    return Promise.reject(error)
  }

  @action loadCourse() {
    this.isLoadingCourse = true
    return this.loadCourseEntries()
  }
  @action loadCourseEntries() {
    const contentfulId = this.contentfulId
    this.isLoadingCourseEntries = true
    return request
      .get(`/content/${contentfulId}/entries`, {
        params: {
          content_type: 'course',
          d: Date.now(),
          select: 'fields,sys',
          'sys.id': contentfulId,
          include: 2,
        },
      })
      .then((response) => this.handleLoadCourse(response))
      .catch((error) => this.handleLoadCourseError(error))
  }
  @action handleLoadCourse = (response) => {
    const course = response.data.items[0]
    const includesById = {}
    const assetsById = {}
    const dataIncludes = response.data.includes || {}
    const assets = dataIncludes.Asset || []
    const includes = dataIncludes.Entry || []
    for (let i = 0; i < includes.length; i++) {
      includesById[includes[i].sys.id.toUpperCase()] = includes[i]
    }
    for (let i = 0; i < assets.length; i++) {
      assetsById[assets[i].sys.id] = assets[i]['fields']['file']['url']
    }
    const settings =
      course &&
      course.fields.settings &&
      course.fields.settings.sys &&
      course.fields.settings.sys.id
        ? includesById[course.fields.settings.sys.id.toUpperCase()]
        : null
    const settingsLDAP =
      course &&
      course.fields.ldap_settings &&
      course.fields.ldap_settings[0] &&
      course.fields.ldap_settings[0].sys &&
      course.fields.ldap_settings[0].sys.id
        ? includesById[course.fields.ldap_settings[0].sys.id.toUpperCase()]
        : null
    const settingsLMS =
      course &&
      course.fields.lms_settings &&
      course.fields.lms_settings[0] &&
      course.fields.lms_settings[0].sys &&
      course.fields.lms_settings[0].sys.id
        ? includesById[course.fields.lms_settings[0].sys.id.toUpperCase()]
        : null
    const settingsLMSPassport =
      settingsLMS &&
      settingsLMS.fields &&
      settingsLMS.fields.passport &&
      settingsLMS.fields.passport.sys &&
      settingsLMS.fields.passport.sys.id
        ? includesById[settingsLMS.fields.passport.sys.id.toUpperCase()]
        : null
    const settingsSAML =
      course &&
      course.fields.saml_settings &&
      course.fields.saml_settings[0] &&
      course.fields.saml_settings[0].sys &&
      course.fields.saml_settings[0].sys.id
        ? includesById[course.fields.saml_settings[0].sys.id.toUpperCase()]
        : null
    const settingsXAPI =
      course &&
      course.fields.xapi_endpoint &&
      course.fields.xapi_endpoint.sys &&
      course.fields.xapi_endpoint.sys.id
        ? includesById[course.fields.xapi_endpoint.sys.id.toUpperCase()]
        : null
    this.course = humps({
      customAccessCodeMessage: getSettingsField(
        course,
        'custom_access_code_message',
      ),
      displayTitle: getSettingsField(course, 'display_title'),
      ldapSettings:
        settingsLDAP && settingsLDAP.fields ? settingsLDAP.fields : null,
      lmsSettings:
        (settingsLMS && settingsLMS.fields) ||
        (settingsLMSPassport && settingsLMSPassport.fields)
          ? _.extend(
              {},
              settingsLMS && settingsLMS.fields ? settingsLMS.fields : {},
              settingsLMSPassport && settingsLMSPassport.fields
                ? settingsLMSPassport.fields
                : {},
            )
          : null,
      lockedElements: getSettingsField(course, 'lockedElements'),
      logo: getSettingsField(course, 'lockedElements'),
      samlSettings:
        settingsSAML && settingsSAML.fields ? settingsSAML.fields : null,
      settings: getSettingsField(settings),
      title: getSettingsField(course, 'title'),
      xapiEndpoint:
        settingsXAPI && settingsXAPI.fields ? settingsXAPI.fields : null,
    })
    this.isLoadingCourse = false
    this.isLoadingCourseEntries = false
    return Promise.resolve()
  }
  @action handleLoadCourseError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingCourse = false
    this.isLoadingCourseEntries = false
    return Promise.reject(error)
  }

  @action loadInstructors(
    term,
    excludeIds,
    isTransfer = false,
    courseId = this.id,
  ) {
    if (!courseId) {
      return Promise.reject(new Error('Missing courseId.'))
    }
    if (!isTransfer) {
      this.isLoadingInstructors = true
    } else {
      this.isLoadingDstInstructors = true
    }
    const excludeIdsArg =
      excludeIds && Array.isArray(excludeIds) && excludeIds.length
        ? excludeIds
        : []
    return request
      .get(`/admin/${courseId}/users`, {
        params: {
          role: 'instructor',
          term: term || '',
          d: Date.now(),
        },
      })
      .then((response) =>
        this.handleLoadInstructors(response, excludeIdsArg, isTransfer),
      )
      .catch(this.handleLoadInstructorsError)
  }
  @action handleLoadInstructors(response, excludeIds, isTransfer) {
    const { data: result } = response
    const { data } = result
    if (!isTransfer) {
      this.isLoadingInstructors = false
    } else {
      this.isLoadingDstInstructors = false
    }
    if (!data || !data.length) {
      return []
    }
    return data
      .map(
        (userObj) =>
          new User({
            ...userObj.user,
            instructorId: userObj.roles.filter(
              (role) => role.type === 'instructor',
            )[0].id,
          }),
      )
      .filter((user) => excludeIds.indexOf(user.id) === -1)
  }
  @action handleLoadInstructorsError(error) {
    SentryReplay.captureException(error)
    this.isLoadingInstructors = false
  }

  @action loadMessages(thread, currentUser, offset, limit) {
    const queries = qs.stringify({ d: Date.now(), offset, limit })
    const { id: threadId } = thread
    this.isLoadingMessages = true
    this.isLoadingMoreMessages = !!(offset && limit)
    return request
      .get(
        `/messages/${this.contentfulId}/threads/${threadId}/messages?${queries}`,
      )
      .then((response) =>
        this.handleLoadMessages(response, thread, currentUser),
      )
      .catch(this.handleLoadMessagesError)
  }
  @action loadMoreMessages(thread, currentUser) {
    const { messages, messagesCount } = thread
    const offset = messages.length
    const limit = PAGE_LIMIT
    if (offset >= messagesCount || this.isLoadingMoreMessages)
      return Promise.resolve()
    return this.loadMessages(thread, currentUser, offset, limit)
  }
  @action handleLoadMessages = (response, thread, currentUser) => {
    let { messages, messages_count: messagesCount } =
      response && response.data
        ? response.data
        : { messages: [], messages_count: 0 }
    this.isLoadingMessages = false
    this.isLoadingMoreMessages = false
    return {
      messages: this.sortMessages(thread, messages, currentUser),
      messagesCount,
    }
  }
  @action handleLoadMessagesError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingMessages = false
    this.isLoadingMoreMessages = false
  }

  @action loadRecipients(term, excludeIds) {
    if (_.isEmpty(term)) return Promise.resolve([])
    this.isLoadingRecipients = true
    return request
      .get(`/admin/${this.id}/users?term=${term || ''}&d=${Date.now()}`)
      .then((response) =>
        this.handleLoadRecipients(
          response,
          excludeIds && Array.isArray(excludeIds) && excludeIds.length
            ? excludeIds
            : [],
        ),
      )
      .catch(this.handleLoadRecipientsError)
  }
  @action handleLoadRecipients = (response, excludeIds) => {
    const { data: result } = response
    const { data } = result
    this.isLoadingRecipients = false
    if (!data) {
      return []
    }
    return data
      .map((userObj) => {
        let { roles, user } = userObj
        let roleStrings = roles.map((roleObj) => roleObj.type)
        return new User({
          isAdmin: roleStrings.indexOf('admin') !== -1,
          isInstructor: roleStrings.indexOf('instructor') !== -1,
          isStudent: roleStrings.indexOf('student') !== -1,
          ...user,
        })
      })
      .filter((user) => excludeIds.indexOf(user.id) === -1)
  }
  @action handleLoadRecipientsError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingRecipients = false
  }

  @action loadReport() {
    this.isLoadingReport = true
    return request
      .get(`/reporting/${this.id}`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadReport)
      .catch(this.handleLoadReportError)
  }
  @action handleLoadReport = (response) => {
    const result = response.data
    const report = humps(result)
    this.isLoadingReport = false
    this.report = new Report({
      courseId: this.id,
      ...report,
    })
    return Promise.resolve()
  }
  @action handleLoadReportError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingReport = false
    return Promise.reject(error)
  }

  @action refreshReportExports() {
    this.isLoadingReportExports = true
    return request
      .put(`/reporting/${this.id}/refresh-reports`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleRefreshReportExports)
      .catch(this.handleRefreshReportExportsError)
  }
  @action handleRefreshReportExports = (response) => {
    const { status } = response
    const isRefreshingReport = status === 200
    this.isLoadingReportExports = false
    return isRefreshingReport
  }
  @action handleRefreshReportExportsError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingReportExports = false
    return false
  }

  @action loadReportUnits(unitId = null) {
    this.isLoadingReportUnits = true
    let params = { d: Date.now() }
    if (unitId) {
      params['unit_id'] = unitId
      if (this.reportUnitIds.indexOf(unitId) !== -1) return Promise.resolve()
    }
    return request
      .get(`/reporting/${this.id}/units`, { params })
      .then((response) => this.handleLoadReportUnits(response, unitId))
      .catch(this.handleLoadReportUnitsError)
  }
  @action handleLoadReportUnits = (response, unitId = null) => {
    const result = response.data
    const data = humps(result)
    const { units: reportUnits } = data
    const unitIds = reportUnits.map((u) => u['id'])
    const reportUnit =
      (unitId && reportUnits.filter((u) => u['id'] === unitId)[0]) ||
      reportUnits[0]
    const reportUnitId = reportUnit && reportUnit['id']
    if (this.reportUnitIds.indexOf(reportUnitId) === -1) {
      this.reportUnitIds.push(reportUnitId)
    }
    if (!this.reportUnits.length) {
      this.reportUnits = reportUnits
    } else if (unitId && unitIds.indexOf(unitId) !== -1) {
      this.reportUnits = this.reportUnits.map((u) => {
        if (u['id'] !== unitId) return u
        return { ...reportUnit }
      })
    }
    this.isLoadingReportUnits = false
    return this.reportUnits
  }
  @action handleLoadReportUnitsError = (error) => {
    SentryReplay.captureException(error)
    this.reportUnits = []
    this.isLoadingReportUnits = false
    return this.reportUnits
  }

  @action loadSettings() {
    this.isLoadingSettings = true
    this.isLoadSettingsError = null
    return request
      .get(`/admin/${this.id}/get-course-settings`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadSettings)
      .catch(this.handleLoadSettingsError)
  }
  @action handleLoadSettings = (response) => {
    const { data } = response
    const { courseSetting, integrationSettings } = humps(data)
    this.settings = courseSetting
    this.integrationSettings = integrationSettings
    this.isLoadingSettings = false
    return this.settings
  }
  @action handleLoadSettingsError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadSettingsError = error
    this.isLoadingSettings = false
  }

  @action loadThread(threadId, currentUser) {
    const thread = this.getThreadById(threadId)
    this.isLoadingThread = true
    return this.loadMessages(thread, currentUser).then((response) => {
      const { messages, messagesCount } = response
      thread.messages = messages
      thread.messagesCount = messagesCount
      this.isLoadingThread = false
      return thread
    })
  }

  @action loadThreads(offset, limit, currentUser) {
    this.isLoadingThreads = true
    this.isLoadingMoreThreads = !!(offset && limit)
    return request
      .get(`/messages/${this.contentfulId}/threads`, {
        params: {
          limit,
          offset,
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadThreads(response, currentUser))
      .catch(this.handleLoadThreadsError)
  }
  @action loadMoreThreads(currentUser) {
    const offset = this.threads.length
    const limit = PAGE_LIMIT
    if (offset >= this.threadsCount || this.isLoadingMoreThreads) return
    return this.loadThreads(offset, limit, currentUser)
  }
  @action handleLoadThreads = (response, currentUser) => {
    let { threads, threads_count: threadsCount } = response.data
    this.threads = this.sortThreads(threads, currentUser)
    this.threadsCount = threadsCount
    this.isLoadingThreads = false
    this.isLoadingMoreThreads = false
    if (!this.isThreadsLoaded) {
      this.isThreadsLoaded = true
    }
    return this.threads
  }
  @action handleLoadThreadsError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingThreads = false
    this.isLoadingMoreThreads = false
  }

  @action loadUsers(
    { limit, offset, term } = { limit: 50, offset: 0, term: '' },
  ) {
    this.usersSearch = term
    this.isLoadingUsers = true
    return request
      .get(`/admin/${this.id}/users`, {
        params: {
          limit,
          offset,
          term,
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadUsers(response, term))
      .catch(this.handleLoadUsersError)
  }
  @action handleLoadUsers = (response, term) => {
    const { data: result } = response
    const { data, total } = result
    const users = humps(data)
    if (term === this.usersSearch) {
      this.users = users
      this.usersSearchCount = total || null
      this.isLoadingUsers = false
    }
    return users
  }
  @action handleLoadUsersError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingUsers = false
    return Promise.reject(new Error('There was an error while loading users.'))
  }

  @action markThreadAsRead(threadId) {
    const thread = this.threads.filter(
      (thrd) => thrd.id === parseInt(threadId),
    )[0]
    if (!thread || !thread.shouldMarkAsRead) {
      return Promise.resolve(thread)
    }
    this.isMarkingThreadAsRead = true
    return request
      .post(`/messages/${this.contentfulId}/threads/${threadId}/read`)
      .then((response) => this.handleMarkThreadAsRead(response, threadId))
      .catch(this.handleMarkThreadAsReadError)
  }
  @action handleMarkThreadAsRead = (response, threadId) => {
    const thread = this.threads.filter(
      (thrd) => thrd.id === parseInt(threadId),
    )[0]
    this.isMarkingThreadAsRead = false
    if (thread) {
      thread.shouldMarkAsRead = false
    }
    return Promise.resolve(thread)
  }
  @action handleMarkThreadAsReadError = (error) => {
    SentryReplay.captureException(error)
    this.isMarkingThreadAsRead = false
    return Promise.reject(error)
  }

  @action saveExistingUser(roles, user, instructor) {
    const { id: userId } = user || {}
    const { instructorId } = instructor || {}
    this.isSavingExistingUser = true
    return request
      .post(`/admin/${this.id}/users/${userId}/enroll?`, {
        roles: roles,
        instructor_id: instructorId,
      })
      .then(this.handleSaveExistingUser)
      .catch(this.handleSaveExistingUserError)
  }
  @action handleSaveExistingUser = () => {
    this.isSavingExistingUser = false
    return Promise.resolve()
  }
  @action handleSaveExistingUserError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isSavingExistingUser = false
    if (response) {
      const { data, status } = response
      if (status === 404) {
        const { message } = data
        return Promise.reject(new Error(JSON.stringify({ confirm: [message] })))
      }
    }
    return Promise.reject(
      new Error(
        JSON.stringify({
          confirm: ['There was an error while saving existing user.'],
        }),
      ),
    )
  }

  // NOTE / TODO / WIP - disable group message type (group / individual), needs BE support
  // @action saveGroupMessage ({ message, role, type }) {
  @action saveGroupMessage({ message, role }) {
    const POST = {
      message,
      role,
      // NOTE / TODO / WIP - disable group message type (group / individual), needs BE support
      // type
    }
    this.isSavingGroupMessage = true
    return request
      .post(`/messages/${this.id}/bulk-message`, POST)
      .then(this.handleSaveGroupMessage)
      .catch(this.handleSaveGroupMessageError)
  }
  @action handleSaveGroupMessage = () => {
    this.isSavingGroupMessage = false
    return true
  }
  @action handleSaveGroupMessageError = (error) => {
    let message =
      error && error.data && error.data.message
        ? error.data.message
        : 'There was an error while saving the group message.'
    SentryReplay.captureException(error)
    this.isSavingGroupMessage = false
    return Promise.reject(new Error(message))
  }

  @action saveMessage(thread, message, attachments, currentUser) {
    const POST = {
      attachments,
      message,
    }
    const { id: threadId } = thread
    this.isSavingMessage = true
    return request
      .post(`/messages/${this.contentfulId}/threads/${threadId}/messages`, POST)
      .then((response) => this.handleSaveMessage(response, thread, currentUser))
      .catch(this.handleSaveMessageError)
  }
  @action handleSaveMessage = (response, thread, currentUser) => {
    const { data } = response
    const { messages } = thread
    const { message } = data
    messages.push(new Message({ ...message, user: currentUser }))
    thread.messages = this.sortMessages(thread, messages, currentUser)
    this.isSavingMessage = false
    return thread
  }
  @action handleSaveMessageError = (error) => {
    SentryReplay.captureException(error)
    this.isSavingMessage = false
  }

  @action saveThread(
    recipients,
    message,
    attachments,
    excludeIds,
    currentUser,
  ) {
    const recipientIds = Object.keys(recipients)
      .map((key) => recipients[key].id)
      .filter((recipientId) => excludeIds.indexOf(recipientId) === -1)
    if (!recipientIds.length) {
      return Promise.reject(new Error('No recipients selected'))
    }
    const POST = {
      message,
      recipients: recipientIds,
    }
    if (attachments) {
      POST['attachments'] = attachments
    }
    this.isSavingThread = true
    return request
      .post(`/messages/${this.contentfulId}/threads`, POST)
      .then((response) => this.handleSaveThread(response, currentUser))
      .catch((error) => this.handleSaveThreadError(error, recipients))
  }
  @action handleSaveThread = (response, currentUser) => {
    const { data } = response
    const newThread = new Thread({ ...data, user: currentUser })
    this.threads = this.threads.filter((thread) => thread.id !== newThread.id)
    this.threads.unshift(newThread)
    this.isSavingThread = false
    return newThread
  }
  @action handleSaveThreadError = (error, recipients) => {
    const { response } = error
    const { data } = response
    const { errors, message } = data
    SentryReplay.captureException(error)
    this.isSavingThread = false
    if (errors) {
      const { recipients: errorRecipients } = errors
      if (errorRecipients) {
        const errorRecipientIds = Object.keys(errorRecipients)
        const errorRecipientNames = recipients
          .filter(
            (recipient) => errorRecipientIds.indexOf(`${recipient.id}`) !== -1,
          )
          .map((recipient) => recipient.fullName)
        return Promise.reject(
          new Error(
            `Invalid recipients${
              errorRecipientNames.length
                ? `: ${errorRecipientNames.join(', ')}`
                : ''
            }.`,
          ),
        )
      }
    }
    return Promise.reject(new Error(message))
  }

  @action saveUser({
    email,
    field1,
    field2,
    field3,
    firstName,
    instructor,
    lastName,
    roles,
  }) {
    const POST = {
      roles,
      user: {
        email: email && typeof email === 'string' ? email.trim() : '',
        first_name: firstName,
        last_name: lastName,
        username: email,
      },
    }
    if (instructor && instructor.instructorId) {
      POST['instructor_id'] = instructor.instructorId
    }
    const errors = {}
    if (!email || !firstName || !lastName) {
      errors['user'] = {}
      if (!email) {
        errors['user']['email'] = ['Field may not be empty.']
      } else if (typeof email !== 'string') {
        errors['user']['email'] = ['Field is not a string.']
      }
      if (!firstName) {
        errors['user']['firstName'] = ['Field may not be empty.']
      }
      if (!lastName) {
        errors['user']['lastName'] = ['Field may not be empty.']
      }
    }
    if (!roles || !Array.isArray(roles) || !roles.length) {
      errors['roles'] = ['Field may not be empty.']
    } else if (
      Array.isArray(roles) &&
      roles.indexOf('student') !== -1 &&
      (!instructor || !instructor.instructorId)
    ) {
      errors['instructorId'] = ['Field may not be empty.']
    }
    if (field1) {
      POST['user']['field1'] = field1
    }
    if (field2) {
      POST['user']['field2'] = field2
    }
    if (field3) {
      POST['user']['field3'] = field3
    }
    if (Object.keys(errors).length) {
      return Promise.reject(new Error(JSON.stringify(errors)))
    }
    this.isSavingUser = true
    return request
      .post(`/admin/${this.id}/users`, POST)
      .then(this.handleSaveUser)
      .catch(this.handleSaveUserError)
  }
  @action handleSaveUser = () => {
    this.isSavingUser = false
  }
  @action handleSaveUserError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isSavingUser = false
    if (response) {
      const { data, status } = response
      if (status === 409) {
        // Conflict, username already exists, prompt existing user
        if (!data.id) {
          return Promise.reject(
            new Error(
              JSON.stringify({
                confirm: [
                  'Existing user does not belong to your organization.',
                ],
              }),
            ),
          )
        }
        return Promise.reject(
          new Error(JSON.stringify({ existing: humps(data) })),
        )
      } else if (status === 404) {
        const { message } = data
        return Promise.reject(new Error(JSON.stringify({ confirm: [message] })))
      }
    }
    return Promise.reject(
      response && response.data && response.data.errors
        ? new Error(JSON.stringify(humps(response.data.errors)))
        : new Error(
            JSON.stringify({
              confirm: ['There was an error while saving the user.'],
            }),
          ),
    )
  }

  @action saveUsers(url, sendEmails = true, isMultiCourse = false) {
    const POST = { send_emails: sendEmails, url }
    if (!url) {
      return Promise.reject(
        new Error({ upload: ['Missing uploaded file URL to add bulk users.'] }),
      )
    }
    const apiEndpoint = isMultiCourse
      ? '/admin/users/import'
      : `/admin/${this.id}/users/import`
    this.isSavingUsers = true
    return request
      .post(apiEndpoint, POST)
      .then(this.handleSaveUsers)
      .catch(this.handleSaveUsersError)
  }
  @action handleSaveUsers = (response) => {
    const { data } = response
    this.isSavingUsers = false
    return Promise.resolve(data)
  }
  @action handleSaveUsersError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isSavingUsers = false
    if (response) {
      const { data, status } = response
      if (status === 404) {
        const { message } = data
        return Promise.reject(new Error(JSON.stringify({ confirm: [message] })))
      } else if (data) {
        const { errors, message } = data
        if (typeof errors === 'string') {
          return Promise.reject(
            new Error(JSON.stringify({ confirm: [errors] })),
          )
        }
        if (!errors && message) {
          return Promise.reject(new Error(JSON.stringify({ isMulti: message })))
        }
      }
    }
    return Promise.reject(
      new Error(
        JSON.stringify({
          confirm: ['There was an error while saving bulk users.'],
        }),
      ),
    )
  }

  @action sortMessages(thread, messages = [], currentUser) {
    const newMessages = messages
      // filter new messages
      .filter((message) => !_.find(thread.messages, (m) => m.id === message.id))
      // map them to objects
      .map((message) => new Message({ ...message, user: currentUser }))
    // remove optimistic messages
    const nonOptimistic = thread.messages.filter(
      (message) => !message.isOptimistic,
    )
    newMessages.forEach((message) => {
      const { id: messageId } = message
      if (
        message.readStatus === MESSAGE_UNREAD_STATUS &&
        thread.unreadMessageIds.indexOf(messageId) === -1
      ) {
        thread.unreadMessageIds.push(messageId)
      }
    })
    if (
      thread.unreadCount &&
      thread.unreadMessageIds.length === thread.unreadCount
    ) {
      thread.shouldMarkAsRead = true
    }
    return _.sortBy([...nonOptimistic, ...newMessages], (m) =>
      Number(m.modified.format('x')),
    )
  }

  @action sortThreads(threads = [], currentUser) {
    const newThreads = []
    threads.forEach((thread) => {
      const existing = _.find(this.threads, (t) => t.id === thread.id)
      const isChanged = existing && !existing.modified.isSame(thread.modified)
      thread.summary = _.truncate(thread.summary, { length: SUMMARY_LENGTH })
      if (!existing) {
        newThreads.push(new Thread({ ...thread, user: currentUser }))
      } else {
        existing.unreadCount = thread.unread_count
        if (!existing.unreadCount) {
          existing.unreadMessageIds = []
          existing.messages.forEach((message) => {
            message.readStatus = MESSAGE_READ_STATUS
          })
        }
      }
      if (isChanged) {
        existing.summary = thread.summary
        existing.summaryAuthor = thread.summary_author
        existing.modified = moment(thread.modified)
      }
    })
    return _.sortBy(
      [...newThreads, ...this.threads],
      (t) => -Number(t.modified.format('x')),
    )
  }

  @action transferStudents(instructorId, dstInstructorId, courseId = null) {
    const POST = { dstInstructorId, instructorId }
    const errors = {}
    const cId = courseId || this.id
    if (!dstInstructorId) {
      errors['dstInstructorId'] = ['Field may not be empty.']
    }
    if (!instructorId) {
      errors['instructorId'] = ['Field may not be empty.']
    }
    if (Object.keys(errors).length) {
      return Promise.reject(new Error(JSON.stringify(errors)))
    }
    this.isTransferringStudents = true
    return request
      .post(
        `/admin/${cId}/users/students/${instructorId}/transfer/${dstInstructorId}`,
        POST,
      )
      .then(this.handleTransferStudents)
      .catch(this.handleTransferStudentsError)
  }
  @action handleTransferStudents = () => {
    this.isTransferringStudents = false
    return Promise.resolve()
  }
  @action handleTransferStudentsError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isTransferringStudents = false
    if (response) {
      const { data, status } = response
      if (status === 409) {
        const { message } = data
        return Promise.reject(new Error(JSON.stringify({ confirm: [message] })))
      }
    }
    return Promise.reject(
      new Error(
        JSON.stringify({
          confirm: [
            'There was an error while transferring students from the user.',
          ],
        }),
      ),
    )
  }

  @action getLoadingCategory(category) {
    if (category === SETTINGS_CATEGORY_EXAM) {
      return this.isUpdatingExam
    } else if (category === SETTINGS_CATEGORY_PRETEST) {
      return this.isUpdatingPretest
    } else if (category === SETTINGS_CATEGORY_SNAPSHOT) {
      return this.isUpdatingSnapshot
    }
    return false
  }
  @action setLoadingCategory(category, value) {
    if (category === SETTINGS_CATEGORY_EXAM) {
      this.isUpdatingExam = value
    } else if (category === SETTINGS_CATEGORY_PRETEST) {
      this.isUpdatingPretest = value
    } else if (category === SETTINGS_CATEGORY_SNAPSHOT) {
      this.isUpdatingSnapshot = value
    }
  }
  @action updateCategory(category, data) {
    if (this.getLoadingCategory(category)) return Promise.resolve()
    this.setLoadingCategory(category, true)
    this.isUpdatingSettingsError = null
    const POST = {}
    Object.keys(data).forEach((key) => {
      this.updateSettingsErrors[key] = false
      if (!POST_KEYS_SETTINGS[key]) return
      POST[POST_KEYS_SETTINGS[key]] = data[key]
    })
    if (!Object.keys(POST).length) return Promise.resolve()
    return request
      .post(`/admin/${this.id}/update-course-settings`, POST)
      .then((response) => this.handleUpdateCategory(category, response, POST))
      .catch((error) => this.handleUpdateCategoryError(category, error, data))
  }
  @action handleUpdateCategory = (category, response, POST) => {
    this.setLoadingCategory(category, false)
    this.settings = { ...this.settings, ...humps(POST) }
    return Promise.resolve()
  }
  @action handleUpdateCategoryError = (category, error, data) => {
    const errorMessage = 'Error updating course settings'
    SentryReplay.captureException(error)
    Object.keys(data).forEach((key) => {
      this.updateSettingsErrors[key] = true
    })
    this.isUpdatingSettingsError = errorMessage
    this.setLoadingCategory(category, false)
    return Promise.reject(new Error(errorMessage))
  }

  @action updateSettings({ name, settings }) {
    this.isUpdatingSettings = true
    this.isUpdatingSettingsError = null
    this.updateSettingsErrors[name] = false
    const POST = {}
    if (!POST_KEYS_SETTINGS[name]) return Promise.resolve()
    if (hasOwnProperty(settings, name)) {
      POST[POST_KEYS_SETTINGS[name]] = settings[name]
    }
    return request
      .post(`/admin/${this.id}/update-course-settings`, POST)
      .then((response) => this.handleUpdateSettings(response, POST))
      .catch((error) => this.handleUpdateSettingsError(error, name))
  }
  @action handleUpdateSettings = (response, POST) => {
    this.isUpdatingSettings = false
    this.settings = { ...this.settings, ...humps(POST) }
    return Promise.resolve()
  }
  @action handleUpdateSettingsError = (error, name) => {
    const errorMessage = 'Error updating course settings'
    SentryReplay.captureException(error)
    this.updateSettingsErrors[name] = true
    this.isUpdatingSettingsError = errorMessage
    this.isUpdatingSettings = false
    return Promise.reject(new Error(errorMessage))
  }

  @action uploadUsers(file) {
    const { name, type } = file
    if (name && name.match(/.csv$/)) {
      const POST = [
        {
          name,
          type: type || 'text/csv',
        },
      ]
      this.isUploadingUsers = true
      return request
        .post(`/content/${this.id}/sign-upload`, POST)
        .then((response) => {
          let signed = response.data[name]
          return fetch(signed.url, {
            body: file,
            headers: {
              'Content-Type': type || 'text/csv',
            },
            method: 'PUT',
          }).then((s3Result) =>
            this.handleUploadUsers({
              name,
              s3: {
                result: s3Result,
                url: signed.s3_url,
              },
            }),
          )
        })
        .catch((response) => this.handleUploadUsersError(response))
    } else {
      return Promise.reject(
        file
          ? new Error(
              JSON.stringify({
                upload: 'Selected file must be in .CSV format.',
              }),
            )
          : new Error(JSON.stringify({ upload: 'No file selected.' })),
      )
    }
  }
  @action handleUploadUsers = (response) => {
    const { s3 } = response
    const { url } = s3
    // const { name, s3 } = response
    // const { result, url } = s3
    // console.log('S3 result', name, result)
    // console.log('Trigger process for', url)
    this.isUploadingUsers = false
    return url
  }
  @action handleUploadUsersError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isUploadingUsers = false
    return Promise.reject(
      response && response.data && response.data.message
        ? new Error(JSON.stringify({ upload: [response.data.message] }))
        : new Error({
            upload: ['There was an error while uploading bulk users.'],
          }),
    )
  }

  @action withdrawUser(userId, roles, courseId = this.id) {
    const POST = { roles: roles.map((role) => role.type) }
    this.isWithdrawingUser = true
    return request
      .post(`/admin/${courseId}/users/${userId}/withdraw`, POST)
      .then(this.handleWithdrawUser)
      .catch(this.handleWithdrawUserError)
  }
  @action handleWithdrawUser = () => {
    this.isWithdrawingUser = false
    return Promise.resolve()
  }
  @action handleWithdrawUserError = (error) => {
    const { response } = error
    SentryReplay.captureException(error)
    this.isWithdrawingUser = false
    if (response) {
      const { data, status } = response
      if (status === 409) {
        // Conflict, user is an instructor that has existing students, prompt transfer students
        const { message } = data
        return Promise.reject(
          new Error(JSON.stringify({ transfer: [message] })),
        )
      } else if (status === 404) {
        const { message } = data
        return Promise.reject(new Error(JSON.stringify({ confirm: [message] })))
      }
    }
    return Promise.reject(
      new Error(
        JSON.stringify({
          confirm: ['There was an error while withdrawing the user.'],
        }),
      ),
    )
  }

  @computed get analyticsSortBy() {
    const { sortBy: analyticsSortBy } = this.analyticsSort
    return analyticsSortBy
  }
  @computed get analyticsSortOrientation() {
    const { sortOrientation: analyticsSortOrientation } = this.analyticsSort
    return analyticsSortOrientation
  }
  @action setAnalyticsSort = (sortBy) => {
    let sortOrientation = 'asc'
    if (this.analyticsSortBy === sortBy) {
      sortOrientation = this.analyticsSortOrientation === 'asc' ? 'desc' : 'asc'
    }
    this.analyticsSort.sortOrientation = sortOrientation
    this.analyticsSort.sortBy = sortBy
    return this.analyticsSort
  }
  @action getAnalyticsFilter = (key) => {
    const filterValue = this.analyticsFilters[key]
    return typeof filterValue === 'object' && filterValue['$mobx']
      ? filterValue['$mobx']['values']
      : filterValue
  }
  @action setAnalyticsFilters = (nextFilters, { clear } = {}) => {
    this.analyticsFilters = clear
      ? nextFilters
      : { ...this.analyticsFilters, ...nextFilters }
    return this.analyticsFilters
  }
  @action getContentFilter = (key) => {
    const filterValue = this.contentFilters.filters[key]
    return typeof filterValue === 'object' && filterValue['$mobx']
      ? filterValue['$mobx']['values']
      : filterValue
  }
  @action setContentFilters = (nextFilters, { clear } = {}) => {
    this.contentFilters = clear
      ? nextFilters
      : { ...this.contentFilters, ...nextFilters }
    return this.contentFilters
  }
}
