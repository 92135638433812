import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import './ActivityError.scss'
import { Button } from '@/components'

const ActivityErrorMessages = defineMessages({
  400: {
    defaultMessage:
      'Looks like you may have multiple tabs opened within this course.  Please close the other tabs and then refresh this screen.',
    description:
      'Description displayed on the activity error screen when there is a validation error while loading or answering an activity.',
    id: 'ActivityError.400',
  },
  401: {
    defaultMessage: `This can happen when there's an authentication issue. Try logging out and logging back in to this page.`,
    description: `Description displayed on the activity error screen when the user's session may have expired while loading or answering an activity.`,
    id: 'ActivityError.401',
  },
  403: {
    defaultMessage:
      'You do not have access to this page. Try navigating back here from your dashboard or refreshing.',
    description: `Description displayed on the activity error screen when the user does not have proper access to the course while loading or answering an activity.`,
    id: 'ActivityError.403',
  },
  404: {
    defaultMessage:
      'Unfortunately, this page does not exist. Try going back to the Learner Dashboard.',
    description:
      'Description displayed on the activity error screen when an activity to be served could not be found.',
    id: 'ActivityError.404',
  },
  409: {
    defaultMessage:
      'The question you answered is not the question you should be on. Select "Refresh Question" to load the current question.',
    description:
      'Description displayed on the activity error screen when the activity being answered is not the activity the user should be on.',
    id: 'ActivityError.409',
  },
  500: {
    defaultMessage:
      'Try refreshing the page. This can occur when a question was set up incorrectly. Reach out to your course administrator if this problem persists.',
    description:
      'Description displayed on the activity error screen when there is an unknown error while loading or answering an activity.',
    id: 'ActivityError.500',
  },
  defaultError: {
    defaultMessage: 'There was an issue loading the current question.',
    description:
      'Default description displayed on the activity error screen when there is an unknown error while displaying, loading or answering an activity.',
    id: 'ActivityError.defaultError',
  },
  goToDashboard: {
    defaultMessage: 'Go To Learner Dashboard',
    description:
      'Button to return to the dashboard from the activity error screen.',
    id: 'ActivityError.goToDashboard',
  },
  networkError: {
    defaultMessage:
      'This can happen when your internet connection is not configured properly. Make sure you have reliable internet.',
    description:
      'Description displayed on the activity error screen when internet connectivity is lost while loading or answering an activity.',
    id: 'ActivityError.networkError',
  },
  refreshActivity: {
    defaultMessage: 'Refresh Question',
    description:
      'Button to attempt reloading of an activity from the activity error screen.',
    id: 'ActivityError.refreshActivity',
  },
  refreshPage: {
    defaultMessage: 'Refresh Page',
    description:
      'Button to attempt reloading of an activity by refreshing the page from the activity error screen.',
    id: 'ActivityError.refreshPage',
  },
  timeout: {
    defaultMessage:
      'This can happen when the server is taking too long to respond or if you have a poor internet connection. Please make sure you have a reliable internet connection and check back later.',
    description:
      'Description displayed on the activity error screen when loading or answering an activity takes too long.',
    id: 'ActivityError.timeout',
  },
})

const errorStatusMessages = {
  400: ActivityErrorMessages['400'],
  401: ActivityErrorMessages['401'],
  403: ActivityErrorMessages['403'],
  404: ActivityErrorMessages['404'],
  409: ActivityErrorMessages['409'],
  500: ActivityErrorMessages['500'],
}

@injectIntl
export default class ActivityError extends Component {
  renderErrorMessage(status, message) {
    const { intl } = this.props
    const { formatMessage } = intl
    if (status && errorStatusMessages[status]) {
      return <p>{formatMessage(errorStatusMessages[status])}</p>
    } else if (
      typeof message === 'string' &&
      message.includes('NETWORK ERROR')
    ) {
      return <p>{formatMessage(ActivityErrorMessages.networkError)}</p>
    } else if (typeof message === 'string' && message.includes('TIMEOUT')) {
      return <p>{formatMessage(ActivityErrorMessages.timeout)}</p>
    } else {
      return <p>{formatMessage(ActivityErrorMessages.defaultError)}</p>
    }
  }
  renderButtons(errorStatus) {
    const { intl, section } = this.props
    const { formatMessage } = intl
    if (errorStatus === 409) {
      return (
        <Button
          className="ActivityError-btn"
          isInfo
          onClick={() => section.loadActivity()}
        >
          {formatMessage(ActivityErrorMessages.refreshActivity)}
        </Button>
      )
    } else {
      return [
        <Button
          className="ActivityError-btn"
          isInfo
          isOutlined
          isLink
          key="ActivityError-dashboard-btn"
          to="/dashboard"
        >
          {formatMessage(ActivityErrorMessages.goToDashboard)}
        </Button>,
        <Button
          className="ActivityError-btn"
          isInfo
          key="ActivityError-refresh-btn"
          onClick={() => window.location.reload()}
        >
          {formatMessage(ActivityErrorMessages.refreshPage)}
        </Button>,
      ]
    }
  }
  render() {
    const { className, error } = this.props
    const {
      message: errorMessage,
      status: errorStatus,
      title: errorTitle,
    } = error || {}
    const title = errorTitle || 'Something went wrong'
    return (
      <div className={cx('ActivityError', className)}>
        {errorStatus && (
          <div className="ActivityError-errorCode">
            <span>{errorStatus.toString()}</span>
          </div>
        )}
        <h1 className="title">{title}</h1>
        {this.renderErrorMessage(errorStatus, errorMessage)}
        <div className="ActivityError-buttons">
          {this.renderButtons(errorStatus)}
        </div>
      </div>
    )
  }
}
