export const DEFAULT_LOCALE = 'en-US'

export const LOCALSTORAGE_LOCALE_KEY = 'fulcrumlabs-locale'

export const GENDERS = ['Male', 'Female']

export const RANK_ALL_STAR = 'all-star'
export const RANK_PROFICIENT = 'good'
export const RANK_UNENGAGED = 'at-risk'

export const US_STATES = [
  {
    code: 'AL',
    value: 'Alabama',
    text: 'Alabama',
  },
  {
    code: 'AK',
    value: 'Alaska',
    text: 'Alaska',
  },
  {
    code: 'AS',
    value: 'American Samoa',
    text: 'American Samoa',
  },
  {
    code: 'AZ',
    value: 'Arizona',
    text: 'Arizona',
  },
  {
    code: 'AR',
    value: 'Arkansas',
    text: 'Arkansas',
  },
  {
    code: 'AA',
    value: 'Armed Forces Americas',
    text: 'Armed Forces Americas',
  },
  {
    code: 'AE',
    value: 'Armed Forces Europe',
    text: 'Armed Forces Europe',
  },
  {
    code: 'AP',
    value: 'Armed Forces Pacific',
    text: 'Armed Forces Pacific',
  },
  {
    code: 'CA',
    value: 'California',
    text: 'California',
  },
  {
    code: 'CO',
    value: 'Colorado',
    text: 'Colorado',
  },
  {
    code: 'CT',
    value: 'Connecticut',
    text: 'Connecticut',
  },
  {
    code: 'DE',
    value: 'Delaware',
    text: 'Delaware',
  },
  {
    code: 'DC',
    value: 'District of Columbia',
    text: 'District of Columbia',
  },
  {
    code: 'FM',
    value: 'Federated States of Micronesia',
    text: 'Federated States of Micronesia',
  },
  {
    code: 'FL',
    value: 'Florida',
    text: 'Florida',
  },
  {
    code: 'GA',
    value: 'Georgia',
    text: 'Georgia',
  },
  {
    code: 'GU',
    value: 'Guam',
    text: 'Guam',
  },
  {
    code: 'HI',
    value: 'Hawaii',
    text: 'Hawaii',
  },
  {
    code: 'ID',
    value: 'Idaho',
    text: 'Idaho',
  },
  {
    code: 'IL',
    value: 'Illinois',
    text: 'Illinois',
  },
  {
    code: 'IN',
    value: 'Indiana',
    text: 'Indiana',
  },
  {
    code: 'IA',
    value: 'Iowa',
    text: 'Iowa',
  },
  {
    code: 'KS',
    value: 'Kansas',
    text: 'Kansas',
  },
  {
    code: 'KY',
    value: 'Kentucky',
    text: 'Kentucky',
  },
  {
    code: 'LA',
    value: 'Louisiana',
    text: 'Louisiana',
  },
  {
    code: 'ME',
    value: 'Maine',
    text: 'Maine',
  },
  {
    code: 'MH',
    value: 'Marshall Islands',
    text: 'Marshall Islands',
  },
  {
    code: 'MD',
    value: 'Maryland',
    text: 'Maryland',
  },
  {
    code: 'MA',
    value: 'Massachusetts',
    text: 'Massachusetts',
  },
  {
    code: 'MI',
    value: 'Michigan',
    text: 'Michigan',
  },
  {
    code: 'MN',
    value: 'Minnesota',
    text: 'Minnesota',
  },
  {
    code: 'MS',
    value: 'Mississippi',
    text: 'Mississippi',
  },
  {
    code: 'MO',
    value: 'Missouri',
    text: 'Missouri',
  },
  {
    code: 'MT',
    value: 'Montana',
    text: 'Montana',
  },
  {
    code: 'NE',
    value: 'Nebraska',
    text: 'Nebraska',
  },
  {
    code: 'NV',
    value: 'Nevada',
    text: 'Nevada',
  },
  {
    code: 'NH',
    value: 'New Hampshire',
    text: 'New Hampshire',
  },
  {
    code: 'NJ',
    value: 'New Jersey',
    text: 'New Jersey',
  },
  {
    code: 'NM',
    value: 'New Mexico',
    text: 'New Mexico',
  },
  {
    code: 'NY',
    value: 'New York',
    text: 'New York',
  },
  {
    code: 'NC',
    value: 'North Carolina',
    text: 'North Carolina',
  },
  {
    code: 'ND',
    value: 'North Dakota',
    text: 'North Dakota',
  },
  {
    code: 'MP',
    value: 'Northern Mariana Islands',
    text: 'Northern Mariana Islands',
  },
  {
    code: 'OH',
    value: 'Ohio',
    text: 'Ohio',
  },
  {
    code: 'OK',
    value: 'Oklahoma',
    text: 'Oklahoma',
  },
  {
    code: 'OR',
    value: 'Oregon',
    text: 'Oregon',
  },
  {
    code: 'PW',
    value: 'Palau',
    text: 'Palau',
  },
  {
    code: 'PA',
    value: 'Pennsylvania',
    text: 'Pennsylvania',
  },
  {
    code: 'PR',
    value: 'Puerto Rico',
    text: 'Puerto Rico',
  },
  {
    code: 'RI',
    value: 'Rhode Island',
    text: 'Rhode Island',
  },
  {
    code: 'SC',
    value: 'South Carolina',
    text: 'South Carolina',
  },
  {
    code: 'SD',
    value: 'South Dakota',
    text: 'South Dakota',
  },
  {
    code: 'TN',
    value: 'Tennessee',
    text: 'Tennessee',
  },
  {
    code: 'TX',
    value: 'Texas',
    text: 'Texas',
  },
  {
    code: 'UT',
    value: 'Utah',
    text: 'Utah',
  },
  {
    code: 'VT',
    value: 'Vermont',
    text: 'Vermont',
  },
  {
    code: 'VI',
    value: 'Virgin Islands',
    text: 'Virgin Islands',
  },
  {
    code: 'VA',
    value: 'Virginia',
    text: 'Virginia',
  },
  {
    code: 'WA',
    value: 'Washington',
    text: 'Washington',
  },
  {
    code: 'WV',
    value: 'West Virginia',
    text: 'West Virginia',
  },
  {
    code: 'WI',
    value: 'Wisconsin',
    text: 'Wisconsin',
  },
  {
    code: 'WY',
    value: 'Wyoming',
    text: 'Wyoming',
  },
]

export const EMPTY_VALUE = '__EMPTY__'

export const ACTIVITY_TYPES = {
  multipleChoice: 'multipleChoice',
  singleChoice: 'singleChoice',
  hotspot: 'hotspot',
  dragPhrase: 'dragPhrase',
  sortStack: 'sortStack',
  matching: 'matching',
  textInput: 'textInput',
  numericInput: 'numericInput',
}

export const VIDEO_TYPES = {
  YOUTUBE: 'youtube_object',
  VIMEO: 'vimeo_object',
  KALTURA: 'kaltura_object', // this will be removed soon
  KALTURA_VIDEO: 'kaltura_video_object', // this will become main video type
  VIDEO: 'video_entry',
}

export const SELECT_ROLES = [
  { label: 'Admin', value: 'admin' },
  { label: 'Instructor', value: 'instructor' },
  { label: 'Learner', value: 'student' },
]

export const THEMES = {
  149: 'ONEGM', // 1GiantMind
}

export const THEME_COLORS = {
  default: {
    blue: '#479DD9',
    green: '#00C479',
    yellow: '#FACA25',
  },
  ONEGM: {
    // 1GiantMind
    blue: '#5E6AA2',
    green: '#9B7281',
    yellow: '#9B7281',
  },
}

export const BULK_UPLOAD_NOTIFICATION_ACTION = 'bulk_upload'
export const BULK_UPLOAD_MULTIPLE_COURSES_NOTIFICATION_ACTION =
  'bulk_upload_mutiple_courses' // aware of typo
export const REPORTS_REFRESH_NOTIFICATION_ACTION = 'reports_refresh'

export const NOTIFICATION_ACTION_TITLE_MAPPING = {
  [BULK_UPLOAD_NOTIFICATION_ACTION]: 'Bulk Upload',
  [BULK_UPLOAD_MULTIPLE_COURSES_NOTIFICATION_ACTION]: 'Global Bulk Upload',
  [REPORTS_REFRESH_NOTIFICATION_ACTION]: 'Report Export',
}

// MESSAGES
export const PAGE_LIMIT = 20
export const REFRESH_RATE = 5000
export const SUMMARY_LENGTH = 90

export const MESSAGE_READ_STATUS = 'Read'
export const MESSAGE_UNREAD_STATUS = 'Unread'

export const ADMIN_METRIC_LABELS = {
  '# Notes Read': {
    'all-star': { label: 'Usually accesses Read.' },
    'at-risk': {
      label: 'Rarely accesses the Read Tab.',
      description:
        'Learners who skip out on an entire modality miss opportunities to put the information into long-term memory.',
    },
    good: { label: 'Often accesses Read.' },
    isHidden: true,
  },
  '# Videos Watched': {
    'all-star': { label: 'Usually accesses Watch' },
    'at-risk': {
      label: 'Rarely accesses Watch.',
      description:
        'Encourage this learner to check back on the video material as a refresher.',
    },
    good: { label: 'Often accesses Watch' },
  },
  'Attempts per Activity': {
    'all-star': { label: 'Usually gets assessment correct on first try.' },
    'at-risk': {
      label: 'Rarely gets assessments correct on the first try.',
      description:
        'This learner does not understand the material on their first attempt and may be frustrated or losing interest.',
    },
    good: { label: 'Often gets assessment correct on first try.' },
  },
  'Login Frequency': {
    'all-star': { label: 'Usually spends quality time learning.' },
    'at-risk': {
      label: 'Rarely spends time learning in the platform.',
      description:
        'Learners that log activity at least three times a week are much more successful in their learning.',
    },
    good: { label: 'Often spends time learning.' },
    isHidden: true,
  },
  'Practicing Frequency': {
    'all-star': { label: 'Minimal quantity of Practice assessments needed.' },
    'at-risk': {
      label: 'A large volume of assessments are being taken.',
      description:
        'This learner is consuming a much larger quantity of assessments compared to their peers.',
    },
    good: { label: 'Some additional Practice assessments needed.' },
  },
  'Reading Frequency': {
    'all-star': { label: 'Usually uses Read as a resource.' },
    'at-risk': {
      label: 'Rarely uses Read as a resource.',
      description:
        'This learner is not using Read as a resource to better inform their assessment decisions.',
    },
    good: { label: 'Often uses Read as a resource.' },
  },
  'Reading Time': {
    'all-star': { label: 'Usually spends time Reading.' },
    'at-risk': {
      label: 'Rarely spends time reading.',
      description:
        'The learner may be skimming or skipping reading material and missing important details.',
    },
    good: { label: 'Often spends time Reading.' },
  },
  'Time per Activity': {
    'all-star': { label: 'Answers assessments very quickly.' },
    'at-risk': {
      label: 'Answers assessments too slowly.',
      description: 'This learner may be facing a confidence barrier.',
    },
    good: { label: 'Answers assessments quickly.' },
    isHidden: true,
  },
  'Video Frequency': {
    'all-star': { label: 'Usually uses Watch as a resource.' },
    'at-risk': {
      label: 'Rarely uses Watch as a resource.',
      description:
        'Encourage this learner to check back on the video material (if available) to add it to their long-term memory.',
    },
    good: { label: 'Often uses Watch as a resource.' },
    isHidden: true,
  },
  'Video Time': {
    'all-star': { label: 'Usually spends time Watching.' },
    'at-risk': {
      label: 'Rarely uses Watch as a resource.',
      description:
        'This learner is not using Watch as a resource to better inform their assessment decisions.',
    },
    good: { label: 'Often spends time Watching.' },
    validation: (sectionsWithTestedOut) => {
      // if true, display metric
      // has unengaged sections with videos
      // and unengaged sections with videos have no watch percentage
      // or watch percentage is not a number
      // or watch percentage is less than 100
      const sectionUnengagedWithVideo = sectionsWithTestedOut.filter(
        (section) => {
          const {
            hasVideo: sectionHasVideo,
            rank: sectionRank,
            reattemptRank: sectionReattemptRank,
            testedOut: sectionTestedOut,
            // watchPercentage: sectionWatchPercentage,
          } = section
          return (
            sectionHasVideo &&
            // (sectionWatchPercentage === 0 || sectionWatchPercentage) &&
            !sectionTestedOut &&
            (!sectionRank || sectionRank === RANK_UNENGAGED) &&
            (!sectionReattemptRank || sectionReattemptRank === RANK_UNENGAGED)
          )
        },
      )
      return (
        sectionUnengagedWithVideo.length &&
        !!sectionUnengagedWithVideo.filter((section) => {
          const { watchPercentage: sectionWatchPercentage } = section
          return (
            !sectionWatchPercentage ||
            isNaN(sectionWatchPercentage) ||
            sectionWatchPercentage < 100
          )
        }).length
      )
    },
  },
}

export const STUDENT_METRIC_LABELS = {
  '# Notes Read': {
    'all-star': { label: 'Usually accesses Read.' },
    'at-risk': {
      label: 'You rarely access the Read Tab.',
      description:
        'Skipping out on an entire modality is a missed opportunity to put the information into long-term memory.',
    },
    good: { label: 'Often accesses Read.' },
  },
  '# Videos Watched': {
    'all-star': { label: 'Usually accesses Watch' },
    'at-risk': {
      label: 'You rarely access Watch.',
      description:
        'You should check back on the video material as a refresher.',
    },
    good: { label: 'Often accesses Watch' },
  },
  'Attempts per Activity': {
    'all-star': {
      label:
        'Your activity performance shows that you are actively learning and are mastering the topic. Awesome!',
    },
    'at-risk': {
      label: 'You miss questions often.',
      description:
        'Too many tries can indicate frustration or lack of interest. Try taking a break or slowing down.',
    },
    good: {
      label: 'Your activity performance shows that you are actively learning.',
    },
  },
  'Login Frequency': {
    'all-star': {
      label:
        "You're regularly logging in to learn and its paying off. Good job!",
    },
    'at-risk': {
      label: "You're not logging in enough!",
      description:
        'Learners that log activity at least three times a week are much more successful in their learning.',
    },
    good: { label: "You're regularly logging in to learn. Good job!" },
  },
  'Practicing Frequency': {
    'all-star': {
      label:
        "You're learning with a very high level of understanding and adaptability.",
    },
    'at-risk': {
      label: 'Large quantity of practice activities needed.',
      description:
        'This means you may be missing out on higher level understanding. In the next section you practice on, try getting a 3 right in a row.',
    },
    good: { label: "You're learning with a high level of understanding." },
  },
  'Reading Frequency': {
    'all-star': {
      label:
        "You're reviewing material consistently, which is helping you with long-term memory and application.",
    },
    'at-risk': {
      label: 'Review the reading material more.',
      description:
        'Check back on the reading material to add it to your long-term memory.',
    },
    good: {
      label:
        "You're reviewing material, which is helping you with long-term memory and application.",
    },
  },
  'Reading Time': {
    'all-star': { label: "You're reading the material very carefully." },
    'at-risk': {
      label: "You don't seem to be reading much.",
      description:
        'Skimming can cause you to miss important details. You may prefer to initially watch videos, but make sure you still review the text.',
    },
    good: { label: "You're reading the material carefully." },
  },
  'Time per Activity': {
    'all-star': { label: 'Answers assessments very quickly.' },
    'at-risk': {
      label: "You're taking too long on some activities.",
      description: 'This learner may be facing a confidence barrier.',
    },
    good: { label: 'Answers assessments quickly.' },
  },
  'Video Frequency': {
    'all-star': {
      label:
        'Your long-term memory of this subject is greatly improving because you are re-watching and reviewing videos.',
    },
    'at-risk': {
      label: 'Try going back to re-watch videos.',
      description:
        "After you've completed a section, it will help your long-term memory if you watch the videos again.",
    },
    good: {
      label:
        'Your long-term memory of this subject is improving because you are re-watching and reviewing videos.',
    },
  },
  'Video Time': {
    'all-star': { label: "You're watching the videos in full." },
    'at-risk': {
      label: "You don't seem to be watching much.",
      description:
        "Some learners prefer to read, but it's good to add variety to learning and still use Watch occasionally.",
    },
    good: { label: "You're watching the videos (mostly) in full." },
  },
}

export const METRIC_LABELS = {
  admin: ADMIN_METRIC_LABELS,
  student: STUDENT_METRIC_LABELS,
}

export const ACTIVITY_PERFORMANCE_MINIMUM = 55

export const ACTIVITY_COMPLETED_CORRECT = 'ACTIVITY_COMPLETED_CORRECT'
export const ACTIVITY_COMPLETED_REATTEMPT = 'ACTIVITY_COMPLETED_REATTEMPT'
export const ACTIVITY_COMPLETED_SECTION = 'ACTIVITY_COMPLETED_SECTION'
export const ACTIVITY_COMPLETED_SNAPSHOT = 'ACTIVITY_COMPLETED_SNAPSHOT'

export const REATTEMPT_STATUS_READY = 1
export const REATTEMPT_STATUS_STARTED = 2
export const REATTEMPT_STATUS_FINISHED = 3

export const CONTRAST_MODE = 'data-contrast'

export const STATUS_TESTED_OUT = 'tested_out'

export const MEDIA_QUERY_MOBILE = 'mobile'
export const MEDIA_QUERY_TABLET = 'tablet'
export const MEDIA_QUERY_DESKTOP = 'desktop'

export const DEVICE_WIDTH_PX_TABLET = 769
export const DEVICE_WIDTH_PX_DESKTOP = 1000

export const REPORT_EXPORT_MODAL_VIEW_HELP = 'help'
export const REPORT_EXPORT_MODAL_VIEW_INPROGRESS = 'in-progress'

// Values for learner dashboard setting
export const DISABLE_DASHBOARD = 'disabled'
export const ENCOURAGE_MASTERY = 'encourage_mastery'
export const REQUIRE_MASTERY = 'require_mastery'

export const EXAM_READY = 'ready'
export const EXAM_STARTED = 'started'
export const EXAM_FINISHED = 'finished'

export const QUIZ_READY = 'ready'
export const QUIZ_STARTED = 'started'
export const QUIZ_FINISHED = 'finished'

export const ROLE_STATUS = {
  disabled: 'Disabled',
}

// ERRORS
export const ERROR_QUIZ_LOAD_ACTIVITY = 'ERROR_QUIZ_LOAD_ACTIVITY'
export const ERROR_EXAM_LOAD_ACTIVITY = 'ERROR_EXAM_LOAD_ACTIVITY'
export const ERROR_SNAPSHOT_LOAD_ACTIVITY = 'ERROR_SNAPSHOT_LOAD_ACTIVITY'
export const ERROR_UNDER_MAINTENANCE = 'UNDER_MAINTENANCE'

export const PASSWORD_TRIM_REGEX = /^[^ -~]+|[^ -~]+$/g

// ENV VARS
export const ENABLE_XAPI = process.env.ENABLE_XAPI === 'true' || false
export const ENV = process.env.ENV
export const FEEDBACKIFY_ID = process.env.FEEDBACKIFY_ID
export const NODE_ENV = process.env.NODE_ENV
export const RELEASE_NAME = process.env.RELEASE_NAME
export const SENTRY_KEY = process.env.SENTRY_KEY

// ENV VAR TANGENT
export const IS_DEV = NODE_ENV === 'development'
