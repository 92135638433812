import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import './SkipNavigation.scss'

const SkipNavigationMessages = defineMessages({
  copy: {
    defaultMessage: 'Skip to Main Content',
    description:
      'Button to skip to the main content for users using keyboard navigation.',
    id: 'SkipNavigation.copy',
  },
})

function setFocus(event) {
  const { target } = event
  const href = target.getAttribute('href')
  const el = document.querySelectorAll(href)[0]
  if (el) {
    el.focus()
  }
}

@injectIntl
export default class SkipNavigation extends Component {
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <a className="SkipNavigation" href="#main-content" onClick={setFocus}>
        {formatMessage(SkipNavigationMessages.copy)}
      </a>
    )
  }
}
