import { computed, observable } from 'mobx'
import _ from 'lodash'
import moment from 'moment'

const RANKS = [
  { value: 'at-risk', isDanger: true, text: 'At risk', count: 0 },
  { value: 'good', isInfo: true, text: 'Good', count: 0 },
  { value: 'all-star', isSuccess: true, text: 'All star', count: 0 },
  { value: 'tested_out', text: 'Tested Out', count: 0 },
]

export default class Report {
  @observable title

  @observable downloadUrls
  @observable units = []

  @observable learners = []
  @observable learnersMeta

  @observable loadedUnits = {}
  @observable loadedLearners = {}

  @observable currentUnitId
  @observable currentLearnerId

  @observable averages
  @observable filters

  @observable indexingDate

  constructor(data) {
    Object.assign(this, data)
  }

  @computed get indexedAt() {
    if (!this.indexingDate) return

    const matched = this.indexingDate.match(
      /(\d{4})(\d{2})(\d{2})-(\d{2})(\d{2})/,
    )
    if (!matched) return
    const [, yy, mm, dd, hs, ms] = matched
    return moment(`${yy}-${mm}-${dd}T${hs}:${ms}Z`).fromNow()
  }

  @computed get firstDownloadUrl() {
    return this.downloadUrls && this.downloadUrls[0]
  }

  @computed get firstLearnerId() {
    return this.learners[0] && this.learners[0].id
  }

  @computed get firstUnitId() {
    return this.units[0] && this.units[0].id
  }

  @computed get currentUnit() {
    return this.loadedUnits[this.currentUnitId]
  }

  @computed get currentLearner() {
    return this.loadedLearners[this.currentLearnerId]
  }

  @computed get unitsAsOptions() {
    return this.units.map((unit) => ({ value: unit.id, text: unit.title }))
  }

  @computed get ranksAsOptions() {
    const ranks = this.learnersMeta.ranks && this.learnersMeta.ranks.slice()
    return RANKS.map((r) => {
      const rank = _.find(ranks, { value: r.value })
      // const isDisabled = rank ? !rank.count : true
      // return { ...r, ...rank, isDisabled }
      return { ...r, ...rank }
    })
  }

  @computed get filtersAsOptions() {
    const labels = {
      field1: 'Location',
      field2: 'Job Code / Role',
      field3: 'Other Label',
    }
    return _.map(this.filters, (f) => {
      const filter = _.find(this.learnersMeta.filters.slice(), { id: f.id })
      const values = filter
        ? filter.values.map((v) => ({ ...v, text: v.value }))
        : []
      return {
        ...f,
        ...filter,
        label: labels[f.id],
        values: [...values],
      }
    }).sort((a, b) => {
      const { id: idA } = a
      const { id: idB } = b
      const idANum = parseInt(idA.split('field')[1])
      const idBNum = parseInt(idB.split('field')[1])
      return idANum - idBNum
    })
  }
}
