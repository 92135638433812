import React from 'react'
import cx from 'classnames'

import './GateCard.scss'

export const GateCardBody = (props) => {
  return (
    <div className={cx('GateCard-body', props.className)}>{props.children}</div>
  )
}

const GateCard = (props) => {
  const className = cx(
    'GateCard',
    {
      'has-logo': props.logo,
      'is-medium': props.isMedium,
      'is-titled': props.isTitled,
      'is-error': props.isError,
    },
    props.className,
  )

  return (
    <div className={className}>
      {props.isTitled && (
        <div className="GateCard-header">
          {props.isTitled && (
            <div className="GateCard-title title">{props.title}</div>
          )}
          {props.logo && (
            <div className="GateCard-logo">
              <img src={props.logo} />
            </div>
          )}
        </div>
      )}
      <div className="GateCard-content">
        {props.title && !props.isTitled && (
          <h1 className="title">{props.title}</h1>
        )}
        {props.children}
      </div>
    </div>
  )
}

export default GateCard
