import cx from 'classnames'
import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import ConfirmationModal from '@/admin/modals/ConfirmationModal/ConfirmationModal'
import { CheckboxCustom, Heading, Loader } from '@/components'
import { DISABLE_DASHBOARD } from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'

import {
  DASHBOARD_NAME,
  EXAM_NAME,
  EXAM_PASS_NAME,
  LTI_NAME,
  SETTINGS_CATEGORY_EXAM,
  SETTINGS_CATEGORY_GENERAL,
  SETTINGS_CATEGORY_INTEGRATIONS,
  SETTINGS_CATEGORY_SNAPSHOT,
  SETTINGS_CATEGORY_PRETEST,
  SETTINGS_CATEGORIES,
  SNAPSHOT_NAME,
  SNAPSHOT_STANDALONE_NAME,
  SNAPSHOT_TEST_OUT_NAME,
} from './InstanceSettingsPageConstants'
import InstanceSettingsPageDashboard from './InstanceSettingsPageDashboard'
import InstanceSettingsPageExam from './InstanceSettingsPageExam'
import InstanceSettingsPageLti from './InstanceSettingsPageLti'
import InstanceSettingsPagePretest from './InstanceSettingsPagePretest'
import InstanceSettingsPageSavedLabel from './InstanceSettingsPageSavedLabel'
import InstanceSettingsPageSideBar from './InstanceSettingsPageSideBar'
import InstanceSettingsPageSnapshot from './InstanceSettingsPageSnapshot'
import './InstanceSettingsPage.scss'

const LEARNING_MODE_CHOICES = [
  {
    id: 'high stakes',
    content: `Mastery mode verifies that each learner is achieving the maximum level of proficiency in a course.  We recommend this for high stakes learning where there's no room for on-the-job performance errors or mistakes.`,
    recommended: true,
    text: 'Mastery',
  },
  {
    id: 'efficiency',
    content: `Efficient mode is the middle ground between Mastery and Primer modes.  It's recommended for less "high stakes" learning than above but still has direct, substantial business ramifications.`,
    text: 'Efficient',
  },
  {
    id: 'primer',
    content:
      'Primer mode creates a brief, personalized experience simply to prime or refresh learners for lower stakes training or in preparation for blended learning scenarios.',
    text: 'Primer',
  },
]

const MEMORY_BOOSTER_MODE_CHOICES = [
  {
    id: 'none',
    content: 'Memory boosters will not be available.',
    text: 'None',
  },
  {
    id: 'low',
    content:
      'Learners will be given a new memory booster option every 10-15 questions, as long as there are knowledge gaps in their learning path.',
    text: 'Low',
  },
  {
    id: 'medium',
    content:
      'Learners will be given a new memory booster option every 5-15 questions, as long as there are knowledge gaps in their learning path.',
    recommended: true,
    text: 'Medium',
  },
  {
    id: 'high',
    content:
      'Learners will be given a new memory booster option every 2-8 questions, as long as there are knowledge gaps in their learning path.',
    text: 'High',
  },
]

const validateExamPass = (value) => {
  const r = /^\d+$/
  const isMatch = value.match(r)
  if (!isMatch) return false

  const parsedValue = parseInt(value)
  if (parsedValue > 100) return false

  return true
}

const DEFAULT_CONFIRMATION_MODAL_STATE = {
  description:
    'If required, the setting can be disabled via contacting the Fulcrum Labs customer support team at clients@fulcrumlabs.ai.',
  isOpen: false,
  name: null,
  prompt:
    'Once this setting is enabled, it cannot be disabled through the interface. Please confirm the correct settings have been selected.',
  title: null,
  value: null,
}

@inject('adminStore', 'uiStore')
@observer
export default class InstanceSettingsPage extends Component {
  state = {
    // Settings Categories
    // - general
    // - integrations
    // - pretest
    // - snapshot
    // - exam
    category: 'general',
    confirmationModal: { ...DEFAULT_CONFIRMATION_MODAL_STATE },
    errorExamPass: false,
    exam: null,
    examPass: null,
    isDoneUpdatingSettings: false,
    learningMasteryRequirements: null,
    learningMode: null,
    memoryBoosterMode: null,
    savedSettings: [],
    snapshot: null,
    snapshotStandalone: null,
    snapshotTestOut: null,
  }
  get basePath() {
    const { match } = this.props
    const { params } = match
    const { instanceId } = params
    return `/admin/courses/${instanceId}/settings`
  }
  get hasExamSettings() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    return hasOwnProperty(instanceSettings, EXAM_NAME)
  }
  get hasIntegrationSettings() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { integrationSettings } = instance
    return !!(integrationSettings && Object.keys(integrationSettings).length)
  }
  get hasLtiSettings() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { integrationSettings } = instance
    return !!(
      integrationSettings && hasOwnProperty(integrationSettings, LTI_NAME)
    )
  }
  get hasPretestSettings() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    return hasOwnProperty(instanceSettings, SNAPSHOT_NAME)
  }
  get hasSnapshotSettings() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    return hasOwnProperty(instanceSettings, SNAPSHOT_STANDALONE_NAME)
  }
  get isDisabledDashboard() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    const { learnerDashboard: learnerDashboardSetting } = instanceSettings || {}
    return learnerDashboardSetting === DISABLE_DASHBOARD
  }
  get isEnabledExam() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    const { exam: examSetting } = instanceSettings || {}
    return !!examSetting
  }
  get isEnabledPretest() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    const { snapshot: snapshotSetting } = instanceSettings || {}
    return !!snapshotSetting
  }
  get isEnabledSnapshot() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    const { snapshotStandalone: snapshotStandaloneSetting } =
      instanceSettings || {}
    return !!snapshotStandaloneSetting
  }
  get isUpdatingCategory() {
    const { adminStore } = this.props
    const { category } = this.state
    const { instance } = adminStore
    return instance.getLoadingCategory(category)
  }
  get isUpdatingSettings() {
    const { savedSettings } = this.state
    const filteredSavedSettings = savedSettings.filter((s) => s.isLoading)
    return !!filteredSavedSettings.length
  }
  componentDidMount() {
    const { adminStore, match, uiStore } = this.props
    const { instance } = adminStore
    const { settings: instanceSettings } = instance
    const { params } = match
    const { settingsCategory } = params

    const {
      learnerDashboard,
      memoryBoosterSettings,
      requiredMasteryLevel,
      snapshot,
      snapshotStandalone,
      snapshotTestOut,
      exam,
      examPass,
    } = instanceSettings || {}
    const update = {
      learningMasteryRequirements: learnerDashboard,
      learningMode: requiredMasteryLevel,
      memoryBoosterMode: memoryBoosterSettings,
    }
    if (this.hasPretestSettings) {
      update[SNAPSHOT_NAME] = snapshot
      update[SNAPSHOT_TEST_OUT_NAME] = snapshotTestOut
    }
    if (this.hasSnapshotSettings) {
      update[SNAPSHOT_STANDALONE_NAME] = snapshotStandalone
    }
    if (this.hasExamSettings) {
      update[EXAM_NAME] = exam
      update[EXAM_PASS_NAME] = `${examPass}`
    }
    this.setState({
      ...update,
      category: settingsCategory,
    })

    uiStore.updateTitle('Course Settings Page')
  }
  componentDidUpdate() {
    const { match } = this.props
    const { category } = this.state
    const { params } = match
    const { settingsCategory } = params
    if (
      SETTINGS_CATEGORIES.indexOf(settingsCategory) !== -1 &&
      category !== settingsCategory
    ) {
      this.setState({
        category: settingsCategory,
        confirmationModal: { ...DEFAULT_CONFIRMATION_MODAL_STATE },
      })
    }
  }
  isChecked = (name, value) => {
    return this.state[name] === value
  }
  onChange = ({ target }) => {
    const { savedSettings } = this.state
    const { name, value } = target
    const originalValue = this.state[name]
    const filteredSavedSettings = savedSettings.filter((s) => s.name !== name)
    if (!name) return
    if (
      (name === EXAM_NAME && !this.isEnabledExam) ||
      (name === SNAPSHOT_NAME &&
        !this.isEnabledPretest &&
        !this.isEnabledSnapshot) ||
      (name === SNAPSHOT_STANDALONE_NAME &&
        !this.isEnabledSnapshot &&
        !this.isEnabledPretest) ||
      name === SNAPSHOT_TEST_OUT_NAME
    ) {
      this.setState({
        [name]: value === 'true',
        savedSettings: [
          ...filteredSavedSettings,
          {
            isLoading: false,
            name,
            value: value === 'true',
          },
        ],
      })
    } else if (name === EXAM_PASS_NAME) {
      const trimmedValue = value.trim()
      const isValid = validateExamPass(trimmedValue)
      this.setState({
        errorExamPass: !isValid,
        [name]: trimmedValue,
      })
    } else if (
      [
        EXAM_NAME,
        EXAM_PASS_NAME,
        SNAPSHOT_NAME,
        SNAPSHOT_STANDALONE_NAME,
        SNAPSHOT_TEST_OUT_NAME,
      ].indexOf(name) === -1
    ) {
      this.setState(
        {
          [name]: value,
          savedSettings: [
            ...filteredSavedSettings,
            {
              isLoading: true,
              name,
              value,
            },
          ],
        },
        () => this.onSaveSettings(name, originalValue),
      )
    }
  }
  onClickCloseDoneUpdating = () => {
    this.setState({ isDoneUpdatingSettings: false })
  }
  onClickOpenConfirmationModal = (originalEvent) => {
    const { category, confirmationModal } = this.state
    const { target } = originalEvent || {}
    const { name, value } = target
    let title = 'Confirm'
    let description = null
    let prompt = null
    if (category === SETTINGS_CATEGORY_EXAM) {
      title = 'Enable Exam'
    } else if (category === SETTINGS_CATEGORY_PRETEST) {
      title = 'Enable Pre-Test'
    } else if (category === SETTINGS_CATEGORY_SNAPSHOT) {
      title = 'Enable Snapshot'
    } else if (category === SETTINGS_CATEGORY_GENERAL) {
      if (name === DASHBOARD_NAME) {
        title = 'Enable Dashboard'
        prompt =
          'Once this setting is enabled, it cannot be disabled through the interface. You will still be able to select between Encourage Mastery and Require Mastery.'
      }
    }
    this.setState({
      confirmationModal: {
        ...confirmationModal,
        description: description || confirmationModal.description,
        isOpen: true,
        name,
        prompt: prompt || confirmationModal.prompt,
        title,
        value,
      },
    })
  }
  onCloseConfirmationModal = () => {
    this.setState({
      confirmationModal: { ...DEFAULT_CONFIRMATION_MODAL_STATE },
    })
  }
  onConfirm = () => {
    const { adminStore } = this.props
    const {
      category,
      confirmationModal,
      exam,
      examPass,
      snapshot,
      snapshotStandalone,
      snapshotTestOut,
    } = this.state
    const { instance } = adminStore
    const data = {}
    if (instance.getLoadingCategory(category)) return
    if (category === SETTINGS_CATEGORY_EXAM) {
      const isValid = validateExamPass(examPass)
      if (!isValid || examPass === '') {
        this.setState({ errorExamPass: isValid })
        return
      }
      data[EXAM_NAME] = exam
      data[EXAM_PASS_NAME] = examPass
    } else if (category === SETTINGS_CATEGORY_PRETEST) {
      data[SNAPSHOT_NAME] = snapshot
      data[SNAPSHOT_TEST_OUT_NAME] = snapshotTestOut
    } else if (category === SETTINGS_CATEGORY_SNAPSHOT) {
      data[SNAPSHOT_STANDALONE_NAME] = snapshotStandalone
    } else if (category === SETTINGS_CATEGORY_GENERAL) {
      const { name, value } = confirmationModal
      return this.onChange({ target: { name, value } })
    }
    this.onSaveCategory(category, data)
  }
  onSaveCategory = (category, data) => {
    const { adminStore } = this.props
    const { confirmationModal } = this.state
    const { instance } = adminStore
    const { isOpen: isOpenConfirmationModal } = confirmationModal
    if (instance.getLoadingCategory(category)) return
    this.setState({ isDoneUpdatingSettings: false }, () => {
      instance.updateCategory(category, data).then(() => {
        const update = {}
        if (isOpenConfirmationModal) {
          update['confirmationModal'] = { ...DEFAULT_CONFIRMATION_MODAL_STATE }
        }
        this.setState({ ...update, isDoneUpdatingSettings: true })
      })
    })
  }
  onSaveSettings = (name, originalValue) => {
    const { adminStore } = this.props
    const { confirmationModal, savedSettings } = this.state
    const { instance } = adminStore
    const { isOpen: isOpenConfirmationModal } = confirmationModal
    const filteredSavedSettings = savedSettings.filter((s) => s.name !== name)
    this.setState({ isDoneUpdatingSettings: false }, () => {
      const currentValue = this.state[name]
      instance
        .updateSettings({ name, settings: { [name]: currentValue } })
        .then(() => {
          const update = {}
          if (isOpenConfirmationModal) {
            update['confirmationModal'] = {
              ...DEFAULT_CONFIRMATION_MODAL_STATE,
            }
          }
          this.setState({
            ...update,
            savedSettings: [
              ...filteredSavedSettings,
              {
                isLoading: false,
                name,
                value: currentValue,
              },
            ],
            isDoneUpdatingSettings: true,
          })
        })
        .catch((error) => {
          const newState = {
            savedSettings: [
              ...filteredSavedSettings,
              {
                error,
                isLoading: false,
                name,
                value: currentValue,
              },
            ],
            isDoneUpdatingSettings: true,
          }
          if (error) {
            newState[name] = originalValue
          }
          this.setState({ ...newState })
        })
    })
  }
  renderDoneUpdatingSettings() {
    const { adminStore } = this.props
    const { isDoneUpdatingSettings } = this.state
    const { instance } = adminStore
    const { isUpdatingSettingsError } = instance
    return (
      <div
        className={cx('InstanceSettingsPage__saved-banner', {
          'InstanceSettingsPage__saved-banner--is-active':
            isDoneUpdatingSettings,
        })}
      >
        <span className="InstanceSettingsPage__saved-banner-text">
          {isUpdatingSettingsError
            ? 'Settings Could Not Be Saved'
            : 'Settings Saved'}
          <i className="fa fa-close" onClick={this.onClickCloseDoneUpdating} />
        </span>
      </div>
    )
  }
  renderLearningModeSetting() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { updateSettingsErrors } = instance
    const { savedSettings } = this.state
    const name = 'learningMode'
    const namedSetting = savedSettings.filter((s) => s.name === name)[0]
    return (
      <div className="InstanceSettingsPage__setting">
        <Heading is4 className="InstanceSettingsPage__setting-title">
          Learning Mode
        </Heading>
        {LEARNING_MODE_CHOICES.map((choice, i) => (
          <div key={`InstanceSettingsPage-learningMode-${i}`}>
            <CheckboxCustom
              className="InstanceSettingsPage-value--is-bold"
              isRadio
              name={name}
              onChange={this.onChange}
              value={choice.id}
              checked={this.isChecked('learningMode', choice.id)}
            >
              <span className="InstanceSettingsPage__value-label">
                <span className="InstanceSettingsPage__value-text">
                  {choice.text}
                </span>
                {choice.recommended && (
                  <span className="InstanceSettingsPage__setting-recommended">
                    &nbsp;(recommended)
                  </span>
                )}
                {namedSetting &&
                namedSetting.value === choice.id &&
                namedSetting.isLoading === false &&
                !updateSettingsErrors[name] ? (
                  <InstanceSettingsPageSavedLabel />
                ) : null}
              </span>
            </CheckboxCustom>
            <span className="InstanceSettingsPage__setting-content">
              {choice.content}
            </span>
          </div>
        ))}
      </div>
    )
  }
  renderDashboardSetting() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { updateSettingsErrors } = instance
    const { savedSettings } = this.state
    const namedSetting = savedSettings.filter(
      (s) => s.name === DASHBOARD_NAME,
    )[0]
    return (
      <InstanceSettingsPageDashboard
        errors={updateSettingsErrors}
        isChecked={this.isChecked}
        isDisabledDashboard={this.isDisabledDashboard}
        isLoading={namedSetting && namedSetting.isLoading}
        onChange={this.onChange}
        onChangeDisabled={this.onClickOpenConfirmationModal}
        value={namedSetting && namedSetting.value}
      />
    )
  }
  renderMemoryBoosterSetting() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { updateSettingsErrors } = instance
    const { savedSettings } = this.state
    const name = 'memoryBoosterMode'
    const namedSetting = savedSettings.filter((s) => s.name === name)[0]
    return (
      <div className="InstanceSettingsPage__setting">
        <Heading is4 className="InstanceSettingsPage__setting-title">
          Memory Boosters
        </Heading>
        <span className="InstanceSettingsPage__setting-subtitle">
          Memory Boosters are an engaging way for learners to reinforce what
          they are learning. Learners choose when and if they want to boost
          their memory. You can choose how often they become available to
          learners. Learners who perform very well with a high confident mastery
          will have less knowledge gaps to fill, and therefore less memory
          boosters needed.
        </span>
        {MEMORY_BOOSTER_MODE_CHOICES.map((choice, i) => (
          <div key={`InstanceSettingsPage-memoryBoosterMode-${i}`}>
            <CheckboxCustom
              className="InstanceSettingsPage-value--is-bold"
              isRadio
              name={name}
              onChange={this.onChange}
              value={choice.id}
              checked={this.isChecked('memoryBoosterMode', choice.id)}
            >
              <span className="InstanceSettingsPage__value-label">
                <span className="InstanceSettingsPage__value-text">
                  {choice.text}
                </span>
                {choice.recommended && (
                  <span className="InstanceSettingsPage__setting-recommended">
                    &nbsp;(recommended)
                  </span>
                )}
                {namedSetting &&
                namedSetting.value === choice.id &&
                namedSetting.isLoading === false &&
                !updateSettingsErrors[name] ? (
                  <InstanceSettingsPageSavedLabel />
                ) : null}
              </span>
            </CheckboxCustom>
            <span className="InstanceSettingsPage__setting-content">
              {choice.content}
            </span>
          </div>
        ))}
      </div>
    )
  }
  renderExam() {
    const { errorExamPass, examPass, savedSettings } = this.state
    const namedSavedSettings = savedSettings.filter(
      (s) => s.name === EXAM_NAME,
    )[0]
    const { value: examSavedValue } = namedSavedSettings || {}
    return (
      <InstanceSettingsPageExam
        errorExamPass={errorExamPass}
        examPass={examPass}
        hasExamSettings={this.hasExamSettings}
        isChecked={this.isChecked}
        isEnabledExam={this.isEnabledExam}
        isLoading={this.isUpdatingCategory}
        onChange={this.onChange}
        onSave={
          !this.isEnabledExam && !!examSavedValue
            ? this.onClickOpenConfirmationModal
            : this.onConfirm
        }
      />
    )
  }
  renderPretestSetting() {
    const { savedSettings } = this.state
    const namedSavedSettings = savedSettings.filter(
      (s) => s.name === SNAPSHOT_NAME,
    )[0]
    const { value: snapshotSavedValue } = namedSavedSettings || {}
    return (
      <InstanceSettingsPagePretest
        hasPretestSettings={this.hasPretestSettings}
        isChecked={this.isChecked}
        isEnabledPretest={this.isEnabledPretest || this.isEnabledSnapshot}
        isLoading={this.isUpdatingCategory}
        onChange={this.onChange}
        onSave={
          !this.isEnabledPretest && !!snapshotSavedValue
            ? this.onClickOpenConfirmationModal
            : this.onConfirm
        }
      />
    )
  }
  renderSnapshotSetting() {
    const { savedSettings } = this.state
    const namedSavedSettings = savedSettings.filter(
      (s) => s.name === SNAPSHOT_STANDALONE_NAME,
    )[0]
    const { value: snapshotStandaloneSavedValue } = namedSavedSettings || {}
    return (
      <InstanceSettingsPageSnapshot
        hasSnapshotSettings={this.hasSnapshotSettings}
        isChecked={this.isChecked}
        isEnabledSnapshot={this.isEnabledSnapshot || this.isEnabledPretest}
        isLoading={this.isUpdatingCategory}
        onChange={this.onChange}
        onSave={
          !this.isEnabledSnapshot && !!snapshotStandaloneSavedValue
            ? this.onClickOpenConfirmationModal
            : this.onConfirm
        }
      />
    )
  }
  renderLtiSettings() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { integrationSettings } = instance || {}
    const { lti: ltiSettings } = integrationSettings || {}
    const { key: ltiKey, secret: ltiSecret, url: ltiUrl } = ltiSettings || {}
    return (
      <InstanceSettingsPageLti
        hasLtiSettings={this.hasLtiSettings}
        ltiKey={ltiKey}
        ltiSecret={ltiSecret}
        ltiUrl={ltiUrl}
      />
    )
  }
  renderSettingsCategory() {
    const { category } = this.state
    if (category === SETTINGS_CATEGORY_GENERAL) {
      return (
        <Fragment>
          {this.renderLearningModeSetting()}
          {this.renderDashboardSetting()}
          {this.renderMemoryBoosterSetting()}
        </Fragment>
      )
    } else if (category === SETTINGS_CATEGORY_INTEGRATIONS) {
      return this.renderLtiSettings()
    } else if (category === SETTINGS_CATEGORY_SNAPSHOT) {
      return this.renderSnapshotSetting()
    } else if (category === SETTINGS_CATEGORY_PRETEST) {
      return this.renderPretestSetting()
    } else if (category === SETTINGS_CATEGORY_EXAM) {
      return this.renderExam()
    }
    return null
  }
  renderSettingsMain() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { isLoadingSettings, isLoadingSettingsError } = instance
    if (isLoadingSettings) {
      return <Loader />
    }
    if (isLoadingSettingsError) {
      return (
        <div className="InstanceSettingsPage-error">
          <p>There was an issue loading your settings.</p>
        </div>
      )
    }
    return (
      <div className="InstanceSettingsPage-main">
        <div className="InstanceSettingsPage__settings">
          {this.renderSettingsCategory()}
        </div>
      </div>
    )
  }
  renderSidebar() {
    const { category } = this.state
    return (
      <InstanceSettingsPageSideBar
        basePath={this.basePath}
        category={category}
      />
    )
  }
  render() {
    const { category, confirmationModal } = this.state
    if (SETTINGS_CATEGORIES.indexOf(category) === -1) {
      return <Redirect to={`${this.basePath}/${SETTINGS_CATEGORY_GENERAL}`} />
    }
    return (
      <AdminLayout className="InstanceSettingsPage">
        <AdminLayout.Sidebar>{this.renderSidebar()}</AdminLayout.Sidebar>
        <AdminLayout.Main id="main-content">
          {this.renderSettingsMain()}
          {this.renderDoneUpdatingSettings()}
          <ConfirmationModal
            {...confirmationModal}
            isLoading={this.isUpdatingCategory || this.isUpdatingSettings}
            onClose={this.onCloseConfirmationModal}
            onConfirm={this.onConfirm}
          />
        </AdminLayout.Main>
      </AdminLayout>
    )
  }
}
