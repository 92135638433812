import crypto from 'crypto'
import _ from 'lodash'
import moment from 'moment'
import { METRIC_LABELS, STATUS_TESTED_OUT } from '@/constants'

// assign values to object in camelCase style
export const toCamelCase = (self, object) => {
  return Object.keys(object).forEach((key) => {
    self[_.camelCase(key)] = object[key]
  })
}

export const toSnakeCase = (obj) => {
  const result = {}
  Object.keys(obj).forEach((key) => {
    const isObject =
      obj[key] && !Array.isArray(obj[key]) && typeof obj[key] === 'object'
    result[_.snakeCase(key)] = isObject ? toSnakeCase(obj[key]) : obj[key]
  })
  return result
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// make first letter upper case
export const upperFirst = (value) => value.substr(0, 1).toUpperCase()

// If the message was sent/received during the current calendar day,
// the timestamp will be presented as "Today at h:mm am/pm", e.g., Today at 8:30am

// If the message was sent/received during the previous calendar day,
// the timestamp will be presented as "Yesterday at h:mm am/pm", e.g., Yesterday at 3:45pm

// If the sent/received more than 2 days prior but within the same calendar year as the current date,
// the timestamp will be presented as "Month D at h:mm am/pm", e.g., March 2 at 10:00am

// If the post, comment, or message was sent/received more than 2 days prior but within a previous calendar year,
// the timestamp will be presented as "Month D, YYYY at h:mm am/pm", e.g., December 30, 2016 at 2:00pm
export const timestamp = (date) => {
  const theDate = moment(date)
  const today = moment()
  const yesterday = moment().subtract(1, 'days')
  const twoDaysAgo = moment().subtract(2, 'days')

  // today format
  if (theDate.isSame(today, 'day')) {
    return theDate.format('[Today at] h:mm A')
  }
  // yesterday format
  if (theDate.isSame(yesterday, 'day')) {
    return theDate.format('[Yesterday at] h:mm A')
  }
  // same year format
  if (theDate.isBefore(twoDaysAgo, 'day') && theDate.isSame(today, 'year')) {
    return theDate.format('MMM D [at] h:mm A')
  }
  // prev years format
  return theDate.format('MMM D, YYYY [at] h:mm A')
}

export const timestampFromNow = (date) => {
  const theDate = moment(date)
  return theDate.fromNow()
}

export const fromUTCToLocale = (date, format = 'M/D/YYYY, h:mm:ss A') => {
  const theDateUTC = moment.utc(date)
  return theDateUTC.local().format(format)
}

export const parseQueryString = (search) => {
  if (!search) return null
  let qsPairs = search.substring(1).split('&') // remove ? and split by &
  return qsPairs.map((pair) => {
    const [key, value] = pair.split('=')
    return { key, value }
  })
}

export const hasOwnProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export const getMetricLabel = (metric, env = 'admin') => {
  const { label, rank } = metric || {}
  const ENV_METRIC_LABELS = METRIC_LABELS[env]
  const METRIC_LABEL = label ? ENV_METRIC_LABELS[label] : null
  const { isHidden, validation } = METRIC_LABEL || {}
  return METRIC_LABEL && METRIC_LABEL[rank]
    ? { isHidden, validation, ...METRIC_LABEL[rank] }
    : { isHidden, validation, label: label || '' }
}

export const filterMetricLabels = (metric) => {
  const { label } = metric || {}
  return (
    label &&
    !(
      label === 'Time per Activity' ||
      // label === '# Videos Watched' ||
      label === '# Notes Read'
    )
  )
}

// https://www.w3schools.com/js/js_cookies.asp
export function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

// https://www.w3schools.com/js/js_cookies.asp
// modified to accept expiry in ms
export function setCookie(cname, cvalue, expiryMs) {
  var d = new Date()
  d.setTime(d.getTime() + expiryMs)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export const sortMetricLabelsFromAtRisk = (a, b) => {
  const { rank: rankA } = a
  const { rank: rankB } = b
  if (rankA === rankB) {
    return 0
  } else if (rankA === 'all-star') {
    return 1
  } else if (rankA === 'good') {
    return rankB === 'all-star' ? 0 : 1
  } else {
    // rankA === 'at-risk'
    return -1
  }
}

export const rankLabel = (rank) => {
  if (!rank) {
    return null
  }
  if (rank === 'at-risk') {
    rank = 'unengaged'
  } else if (rank === 'good') {
    rank = 'proficient'
  } else if (rank === STATUS_TESTED_OUT) return 'Tested Out'
  return _.upperFirst(_.lowerCase(rank))
}

export const isPassword = (value) =>
  /(?=^[ -~].*)(?=.*[A-Za-z])(?=.*\d)(?=.*[ -~]$)^\S.{3,98}\S$/.test(value)

export const getIdleTimeThreshold = (tab) => {
  if (tab === 'watch') return 15 * 60 * 1000 // 15 minutes
  return 5 * 60 * 1000 // 5 minutes
}

export const getMobxArrayValues = (prop) => {
  return prop && prop['$mobx'] && prop['$mobx']['values']
}

function _sanitizeKeys(obj, value = '*****', optionalKeys = []) {
  const keys = [
    'auth_token',
    'current_password',
    'password',
    'password_confirmation',
    'token',
    ...optionalKeys,
  ]
  Object.keys(obj).forEach((key) => {
    if (keys.indexOf(key) !== -1) {
      obj[key] = obj[key] ? value : null
    }
  })
  return obj
}

export const sanitizeBody = (data) => {
  if (data && data !== '') {
    try {
      let jsonData = JSON.parse(data)
      return _sanitizeKeys(jsonData)
    } catch (err) {
      return data
    }
  }
  return null
}

export const sanitizeUrl = (url, optionalMatches = []) => {
  const matches = [
    '/login/token/',
    '/reset-password/',
    '/set-password/',
    ...optionalMatches,
  ]
  matches.forEach((match) => {
    if (url.indexOf(match) !== -1) {
      url = url.replace(new RegExp(`^.+${match}(.+)$`), `${match}*****`)
    }
  })
  return url
}

function _getRandomIntInclusive(min, max) {
  // The maximum is inclusive and the minimum is inclusive
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min)
}
const _encryptBaseRequestHeader = (value, secret) => {
  const hmac = crypto.createHmac('sha256', secret)
  hmac.update(value)
  return hmac.digest('base64')
}
const _DEFAULT_CHARSETS = [
  'abcdefghijklmnopqrstuvwxyz',
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  '1234567890',
  '~!@#$%^&*?;:+=_',
]
const _getRandomBaseRequestHeaderSegmentCharSets = (excludeChars) => {
  return _DEFAULT_CHARSETS.map((charSet) =>
    charSet
      .split('')
      .filter((v) => excludeChars.indexOf(v) === -1)
      .join(''),
  )
}
const _generateRandomBaseRequestHeaderSegmentPiece = (
  excludeFirstChars,
  excludeLastChars,
) => {
  // each segment piece consists of:
  // 2 lowercase letters, 2 uppercase letters, 1 number, 1 special character

  // map an array of charset indices defined as:
  // - 0: lowercase
  // - 1: uppercase
  // - 2: numbers
  // - 3: special
  // assign each value a random sort value
  // sort array based on the random sort value
  // map the results as an array of the values
  // join the results as a string
  return [0, 0, 1, 1, 2, 3]
    .map((charSetIndex) => ({
      index: charSetIndex,
      sort: Math.random(),
    }))
    .sort((a, b) => a.sort - b.sort)
    .map((value, index, array) => {
      const { index: charSetIndex } = value
      // if it is the first or last character
      // then get charSet with exclusions
      const isIndexFirst = index === 0
      const isIndexLast = index === array.length - 1
      const charSets =
        isIndexFirst || isIndexLast
          ? _getRandomBaseRequestHeaderSegmentCharSets(
              isIndexFirst ? excludeFirstChars : excludeLastChars,
            )
          : _DEFAULT_CHARSETS
      const charSet = charSets[charSetIndex]
      const charSetMaxIndex = charSet.length - 1
      return charSet[_getRandomIntInclusive(0, charSetMaxIndex)]
    })
    .join('')
}
const _generateRandomBaseRequestHeaderSegment = () => {
  // the random key consists of three, hyphen delimited, six letter segments
  // where each must abide by the following rules:
  // - must contain one of ~!@#$%^&*?;:+=_
  // - must contain one number
  // - must contain two lowercase letter
  // - must contain two uppercase letter
  // - each segment must have different first character
  // - each segment must have different last character
  const segments = []
  const excludeFirstChars = []
  const excludeLastChars = []
  while (segments.length < 3) {
    segments.push(
      _generateRandomBaseRequestHeaderSegmentPiece(
        excludeFirstChars,
        excludeLastChars,
      ),
    )
    excludeFirstChars.push(segments[segments.length - 1][0])
    excludeLastChars.push(
      segments[segments.length - 1][segments[segments.length - 1].length - 1],
    )
  }
  return segments.join('-')
}
const _toBase64 = (value) => btoa(value)

const baseHeaderPrefix = 'X'
const baseHeader = 'Cloudfront'
const baseHeaderSuffix = 'Key'
export const getBaseRequestHeaders = (secret, offsetTime) => {
  // x-cloudfront-key is a base64 encoded string
  // that consists of four underscore delimited segments:
  // - user-agent and then encrypt with a secret that is deployed to the frontend
  // - encrypted IP
  // - ISO time offset by 5 hours
  // - encrypted random key requirements
  const timestamp = Date.now()
  const userAgent = navigator.userAgent
  const random = _generateRandomBaseRequestHeaderSegment()
  const e_random = _encryptBaseRequestHeader(random, secret)
  const e_userAgent = _encryptBaseRequestHeader(userAgent, secret)

  const b64StringBase = _toBase64(
    `${e_userAgent}-${e_random}-${_toBase64(
      timestamp + offsetTime,
    )}-${_toBase64(random)}`,
  )
  const b64StringSignature = _encryptBaseRequestHeader(b64StringBase, secret)
  return {
    [`${baseHeaderPrefix}-${baseHeader}-${baseHeaderSuffix}`]: `${b64StringBase}.${b64StringSignature}`,
  }
}
