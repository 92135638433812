import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { observer } from 'mobx-react'
import {
  Activity,
  ActivityCompleted,
  ActivityError,
  Button,
  IncompleteQuestionModal,
  Loader,
  NegativeStreakModal,
  RushInterventionModal,
} from '@/components'
import { ACTIVITY_COMPLETED_CORRECT } from '@/constants'
import IdleTimer from 'react-idle-timer'
import TimeTracking, {
  TYPE_ACTIVITY,
} from '@/components/TimeTracking/TimeTracking'
import { READ_TAB, WATCH_TAB } from '@/stores/models/section'
import { getIdleTimeThreshold } from '@/utils/helpers'

const CoursePagePracticeMessages = defineMessages({
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description:
      'Button to zoom in on activity images on the course page practice tab.',
    id: 'CoursePagePractice.enlargeImage',
  },
  loadingActivity: {
    defaultMessage: 'Loading question...',
    description:
      'Description displayed when an activity is loading upon entering the course page practice tab',
    id: 'CoursePagePractice.loadingActivity',
  },
})

@injectIntl
@observer
export default class CoursePagePractice extends Component {
  get currentActivity() {
    const { activity } = this.props
    return activity || null
  }
  componentDidMount() {
    const { isNotMasteredOrIsInReview } = this.props
    // if section is not mastered or is in review
    // then load activity
    if (isNotMasteredOrIsInReview && !this.currentActivity) {
      this.onLoadActivity()
    }
  }
  componentDidUpdate() {
    const {
      isActivityFeedbackOpen,
      isLoadingActivity,
      isNotMasteredOrIsInReview,
      sectionError,
    } = this.props
    // if activity feedback is not open
    // and section is not mastered or is in review
    // and section is not loading an activity
    // and there is no current activity
    // and there is no section error
    // then load activity
    if (
      !isActivityFeedbackOpen &&
      isNotMasteredOrIsInReview &&
      !isLoadingActivity &&
      !this.currentActivity &&
      !sectionError
    ) {
      this.onLoadActivity()
    }
  }
  onChange = (value) => {
    const {
      isActivityFeedbackOpen,
      isConfirmDisabled,
      onChange,
      onEnableConfirm,
    } = this.props
    if (isActivityFeedbackOpen) return
    if (isConfirmDisabled) {
      onEnableConfirm()
    }
    if (onChange) {
      onChange(value)
    }
  }
  onConfirmIncomplete = (event) => {
    const { onSubmit } = this.props
    onSubmit(event, true)
  }
  onLoadActivity = () => {
    const { onLoadActivity } = this.props
    return onLoadActivity()
  }
  renderImageAfterQuestion = (image, imageAlt, imageTitle) => {
    const { intl, onOpenActivityModal } = this.props
    const { formatMessage } = intl
    return (
      <div
        className="Activity-colImage"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <img
          className="Activity-image"
          src={image}
          alt={imageAlt}
          title={imageTitle}
        />
        <Button
          aria-label={formatMessage(CoursePagePracticeMessages.enlargeImage)}
          className="Activity-zoomImage"
          onClick={(e) => onOpenActivityModal(e, image)}
        >
          <span>{formatMessage(CoursePagePracticeMessages.enlargeImage)}</span>
          <i
            aria-hidden="true"
            className="fa fa-search-plus"
            title={formatMessage(CoursePagePracticeMessages.enlargeImage)}
          />
        </Button>
      </div>
    )
  }
  renderActivity(activity) {
    const {
      activitiesCounters,
      activityAnswer,
      answerHasChanged,
      checkIfScrollable,
      courseActivityAttempts,
      courseId,
      hasNextActivity,
      isActivityFeedbackOpen,
      isActivityQuestionOpen,
      isConfirmDisabled,
      isReviewOverride,
      isReviewSection,
      isSavingActivity,
      isSkippingActivity,
      onDisableConfirm,
      onOpenActivityModal,
      onSubmit,
      pageRef,
      practiceLearningObjectives,
      scrollY,
    } = this.props
    const {
      activityEmbed,
      activityType,
      answerResponse,
      answerResponseLos,
      answersCounter,
      attempt: activityAttempt,
      correctAnswers,
      currentHint: currentActivityHint,
      hasHint,
      hasNotPassed,
      id: activityId,
      imageAlignment,
      incorrectAnswers,
      isNewActivity,
      question,
      questionImage,
      questionImageDescription,
      questionImageTitle,
      questionVideo,
      replaceActivityMathFormulas,
      skipped: isSkippedActivity,
      title: activityTitle,
      validationError: activityValidationError,
    } = activity || {}
    return (
      <Activity
        id={activityId}
        activitiesCounters={activitiesCounters}
        activityEmbed={activityEmbed}
        answerHasChanged={answerHasChanged}
        answerResponse={answerResponse || null}
        answerResponseLos={
          (practiceLearningObjectives && answerResponseLos) || null
        }
        answersCounter={answersCounter}
        attempt={activityAttempt}
        checkIfScrollable={checkIfScrollable}
        courseActivityAttempts={courseActivityAttempts}
        correctAnswers={correctAnswers || null}
        courseId={courseId}
        currentActivityHint={currentActivityHint}
        currentActivityId={this.currentActivity.id}
        dangerLabel={activityValidationError}
        hasHint={hasHint}
        hasNextActivity={hasNextActivity}
        image={questionImage}
        imageAlignment={imageAlignment}
        imageAlt={questionImageDescription || activityTitle}
        imageTitle={questionImageTitle || activityTitle}
        incorrectAnswers={incorrectAnswers || null}
        isActivityFeedbackOpen={isActivityFeedbackOpen}
        isActivityQuestionOpen={isActivityQuestionOpen}
        isConfirmDisabled={isConfirmDisabled}
        isDanger={hasNotPassed}
        isNewActivity={isNewActivity}
        isReviewOverride={isReviewOverride}
        isReviewSection={isReviewSection}
        isSavingActivity={isSavingActivity}
        isSkippingActivity={isSkippingActivity}
        onChange={this.onChange}
        onDisableConfirm={onDisableConfirm}
        onOpenActivityModal={onOpenActivityModal}
        onSubmit={onSubmit}
        pageRef={pageRef}
        renderImageAfterQuestion={() =>
          this.renderImageAfterQuestion(
            questionImage,
            questionImageDescription || activityTitle,
            questionImageTitle || activityTitle,
          )
        }
        replaceActivityMathFormulas={replaceActivityMathFormulas}
        skipped={isSkippedActivity}
        title={question}
        type={activityType}
        scrollY={scrollY}
        value={activityAnswer}
        video={questionVideo}
        // choices activities
        choice={{
          choices: activity.cleanedChoices,
        }}
        // input activities
        input={{
          isMultipleInput: activity.isMultipleInput,
          textBefore: activity.textBeforeInput,
          textAfter: activity.textAfterInput,
        }}
        // matching activity
        matching={{
          definitions: activity.pairs && activity.pairs.definitions,
          labels: activity.pairs && activity.pairs.labels,
        }}
        // sorting activity
        sortStack={{
          items: activity.pairs && activity.pairs.labels,
        }}
        // hotspot activity
        hotspot={activity.hotSpotData}
        // dragPhrase activity
        dragPhrase={{
          content: activity.cleanedContent,
          categories: activity.categories,
          items: activity.draggableItems,
        }}
      />
    )
  }
  renderActivityTimeTracking() {
    const {
      hasNextActivity,
      isOpenIdleModal,
      isOpenIdleSessionModal,
      isOpenMemoryBoosterModal,
      onIdle,
      section,
    } = this.props
    const { id: currentActivityId } = this.currentActivity || {}
    return (
      <Fragment>
        {section &&
        currentActivityId &&
        !isOpenMemoryBoosterModal &&
        !hasNextActivity ? (
          <TimeTracking
            entityId={currentActivityId}
            isIdle={isOpenIdleModal && !isOpenIdleSessionModal}
            section={section}
            what={TYPE_ACTIVITY}
          />
        ) : null}
        <IdleTimer
          onIdle={onIdle}
          timeout={getIdleTimeThreshold('practice')}
          startOnLoad
        />
      </Fragment>
    )
  }
  renderActivityScreen() {
    const { activity } = this.props
    if (!activity) return
    return (
      <Fragment>
        {this.renderActivityTimeTracking()}
        {this.renderActivity(activity)}
      </Fragment>
    )
  }
  renderActivityFeedback() {
    const {
      isActivityQuestionOpen,
      onToggleActivityQuestion,
      practiceLearningObjectives,
      sectionStreak,
    } = this.props
    const { answerResponseLos, goodToKnow: currentActivityGoodToKnow } =
      this.currentActivity || {}
    return (
      <ActivityCompleted
        goodToKnow={currentActivityGoodToKnow}
        learningObjectives={practiceLearningObjectives && answerResponseLos}
        onClickShowQuestion={onToggleActivityQuestion}
        renderActivity={
          isActivityQuestionOpen
            ? this.renderActivity(this.currentActivity)
            : null
        }
        streak={sectionStreak}
        type={ACTIVITY_COMPLETED_CORRECT}
      />
    )
  }
  renderBody() {
    const {
      activity,
      intl,
      isActivityFeedbackOpen,
      isLoadingActivity,
      section,
      sectionError,
    } = this.props
    const { formatMessage } = intl
    if (sectionError) {
      return <ActivityError error={sectionError} section={section} />
    }
    if (!activity || isLoadingActivity) {
      return (
        <Loader
          ariaLive="assertive"
          role="status"
          text={formatMessage(CoursePagePracticeMessages.loadingActivity)}
        />
      )
    }
    if (isActivityFeedbackOpen) {
      return this.renderActivityFeedback()
    }
    return this.renderActivityScreen()
  }
  render() {
    const {
      activity,
      activityAnswer,
      closeIncompleteQuestionModal,
      isOpenIncompleteQuestionModal,
      isOpenNegativeStreakModal,
      isOpenRushInterventionModal,
      onConfirmNegativeStreakModal,
      onConfirmRushInterventionModal,
      sectionTabs,
    } = this.props
    const readTab = sectionTabs.filter((tab) => tab.id === READ_TAB)[0]
    const watchTab = sectionTabs.filter((tab) => tab.id === WATCH_TAB)[0]
    const { href: readTabURL } = readTab || {}
    const { href: watchTabURL } = watchTab || {}
    const answersCountTotals =
      activity &&
      activity.getAnswersCountTotals(
        activityAnswer,
        isOpenIncompleteQuestionModal,
      )
    const { numAnswersIncomplete, numAnswersTotal } = answersCountTotals || {}
    return (
      <div className="Course-practice" qa-id="learner-practice-layout">
        {this.renderBody()}
        {
          <NegativeStreakModal
            isOpen={isOpenNegativeStreakModal}
            onConfirm={onConfirmNegativeStreakModal}
            readTabURL={readTabURL}
            watchTabURL={watchTabURL}
          />
        }
        {
          <RushInterventionModal
            isOpen={isOpenRushInterventionModal}
            onConfirm={onConfirmRushInterventionModal}
            readTabURL={readTabURL}
            watchTabURL={watchTabURL}
          />
        }
        <IncompleteQuestionModal
          isOpen={isOpenIncompleteQuestionModal}
          numAnswersIncomplete={numAnswersIncomplete}
          numAnswersTotal={numAnswersTotal}
          onClose={closeIncompleteQuestionModal}
          onConfirm={this.onConfirmIncomplete}
        />
      </div>
    )
  }
}
