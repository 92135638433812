import React from 'react'
import cx from 'classnames'

import './Heading.scss'

const Heading = ({
  is1,
  is2,
  is3,
  is4,
  is5,
  is6,
  text,
  children,
  className,
  ...rest
}) => {
  let tag = 'h1'
  if (is2) tag = 'h2'
  if (is3) tag = 'h3'
  if (is4) tag = 'h4'
  if (is5) tag = 'h5'
  if (is6) tag = 'h6'

  const elClassName = cx('Heading title', className, {
    'is-2': is2,
    'is-3': is3,
    'is-4': is4,
    'is-5': is5,
    'is-6': is6,
  })

  return React.createElement(
    tag,
    { ...rest, className: elClassName },
    text || children,
  )
}

export default Heading
