import React, { Component } from 'react'
import { Notification } from './components'
import { inject, observer } from 'mobx-react'

@inject('uiStore')
@observer
export default class NotificationContainer extends Component {
  render() {
    const { uiStore } = this.props
    return <Notification message={uiStore.message} type={uiStore.messageType} />
  }
}
