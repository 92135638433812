import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { UpdatesBanner } from '@/components'
import {
  RANK_ALL_STAR,
  RANK_PROFICIENT,
  RANK_UNENGAGED,
  REATTEMPT_STATUS_FINISHED,
} from '@/constants'
import './CourseCompletionMessageBanner.scss'
const CourseCompletionMessageBannerMessages = defineMessages({
  defaultHeadline: {
    defaultMessage: 'Congratulations!',
    description:
      'Default headline displayed on the learner homepage in the course completion message banner upon completing a course.',
    id: 'CourseCompletionMessageBanner.defaultHeadline',
  },
  defaultSubheadline: {
    defaultMessage:
      'Well done! You have completed every Section in this course.',
    description:
      'Default subheadline displayed on the learner homepage in the course completion message banner upon completing a course.',
    id: 'CourseCompletionMessageBanner.defaultSubheadline',
  },
  defaultSubheadlineGenerating: {
    defaultMessage: `Well done! You've completed every Section in this course. Our system is currently analyzing your performance.`,
    description:
      'Default subheadline displayed on the learner homepage in the course completion message banner while a learner report is still being gennerated upon completing a course.',
    id: 'CourseCompletionMessageBanner.defaultSubheadlineGenerating',
  },
  defaultSubheadlineMastered: {
    defaultMessage: `You've mastered every Section in this course.`,
    description:
      'Default subheadlinen displayed on the learner homepage in the course completion message banner if no sections are at risk upon completingn a course.',
    id: 'CourseCompletionMessageBanner.defaultSubheadlineMastered',
  },
})
@injectIntl
class CourseCompletionMessageBanner extends Component {
  renderDefaultSubheadline(isAtRisk, isNotFoundLearner) {
    const { intl, pretestMasteredCourse } = this.props
    const { formatMessage } = intl
    if (isNotFoundLearner && !pretestMasteredCourse)
      return formatMessage(
        CourseCompletionMessageBannerMessages.defaultSubheadlineGenerating,
      )
    if (isAtRisk)
      return formatMessage(
        CourseCompletionMessageBannerMessages.defaultSubheadline,
      )
    return formatMessage(
      CourseCompletionMessageBannerMessages.defaultSubheadlineMastered,
    )
  }
  renderDefault(isAtRisk, isNotFoundLearner) {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="CourseCompletionMessageBanner-content">
        <h1 className="CourseCompletionMessageBanner-headline">
          {formatMessage(CourseCompletionMessageBannerMessages.defaultHeadline)}
        </h1>
        <h2 className="CourseCompletionMessageBanner-subheadline">
          {this.renderDefaultSubheadline(isAtRisk, isNotFoundLearner)}
        </h2>
      </div>
    )
  }
  render() {
    const {
      courseCompletionCopy,
      courseMasteryCopy,
      isNotFoundLearner,
      isRequireMastery,
      numSections,
      pretestMasteredCourse,
      sectionsCompleted,
    } = this.props
    const sectionsAtRisk = sectionsCompleted
      ? sectionsCompleted.filter((section) => {
          const {
            rank: sectionRank,
            reattemptRank: sectionReattemptRank,
            reattemptStatus: sectionReattemptStatus,
            testedOut: sectionTestedOut,
          } = section
          return (
            !sectionTestedOut &&
            isRequireMastery &&
            (!sectionRank || sectionRank === RANK_UNENGAGED) &&
            isRequireMastery &&
            (sectionReattemptStatus !== REATTEMPT_STATUS_FINISHED ||
              (sectionReattemptRank !== RANK_PROFICIENT &&
                sectionReattemptRank !== RANK_ALL_STAR))
          )
        })
      : []
    const isAtRisk =
      !pretestMasteredCourse &&
      (!sectionsCompleted ||
        sectionsCompleted.length !== numSections ||
        sectionsAtRisk.length)
    const innerHTML = isAtRisk ? courseCompletionCopy : courseMasteryCopy
    return (
      <UpdatesBanner
        className="CourseCompletionMessageBanner"
        isHiddenButtons
        isHiddenHeader
        isHTML={(!isNotFoundLearner || pretestMasteredCourse) && !!innerHTML}
        isOpen
      >
        {(!isNotFoundLearner || pretestMasteredCourse) && !!innerHTML ? (
          <div
            className="CourseCompletionMessageBanner-content"
            dangerouslySetInnerHTML={{ __html: innerHTML }}
          />
        ) : (
          this.renderDefault(isAtRisk, isNotFoundLearner)
        )}
      </UpdatesBanner>
    )
  }
}
export default CourseCompletionMessageBanner
