import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { ReadTabLectureNotes } from '@/components'
import IdleTimer from 'react-idle-timer'
import TimeTracking, { TYPE_READ } from '@/components/TimeTracking/TimeTracking'
import { getIdleTimeThreshold } from '@/utils/helpers'

const CoursePageMessages = defineMessages({
  noLectureNotes: {
    defaultMessage: 'No Read available.',
    description:
      'Description displayed when the section has no lecture notes available.',
    id: 'CoursePage.noLectureNotes',
  },
})

@injectIntl
@observer
export default class CoursePageRead extends Component {
  componentDidMount() {
    const { section } = this.props
    // FIXME / NOTE / TODO: Temporary fix for incorrect Kaltura partner ID being used for images
    // in the Read for Sections - https://headware4.jira.com/browse/SD-259
    section.replaceLectureNotesKalturaUrls()
    section.replaceLectureNotesMathFormulas()
  }
  render() {
    const {
      intl,
      isOpenIdleModal,
      isOpenIdleSessionModal,
      isOpenMemoryBoosterModal,
      onIdle,
      onOpenActivityModal,
      oScrollToAnchor,
      scrollableEl,
      section,
    } = this.props
    const { formatMessage } = intl
    const { lectureNotes } = section || {}
    return (
      <div key="course-read">
        {!isOpenMemoryBoosterModal ? (
          <TimeTracking
            isIdle={isOpenIdleModal && !isOpenIdleSessionModal}
            section={section}
            what={TYPE_READ}
          />
        ) : null}
        <IdleTimer
          onIdle={onIdle}
          startOnLoad
          timeout={getIdleTimeThreshold('read')}
        />
        <div className="Course-read content">
          {lectureNotes ? (
            <ReadTabLectureNotes
              lectureNotes={lectureNotes}
              onOpenActivityModal={onOpenActivityModal}
              oScrollToAnchor={oScrollToAnchor}
              scrollableEl={scrollableEl}
            />
          ) : (
            <div className="Course-noNotes title">
              <p>{formatMessage(CoursePageMessages.noLectureNotes)}</p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
