import React, { Component } from 'react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'

import './Footer.scss'

@withRouter
export default class Footer extends Component {
  render() {
    const className = cx(
      'Footer',
      {
        'is-messages': this.props.match.path.match(/messages/),
        'is-units': this.props.match.path.match(/units/),
      },
      this.props.className,
    )

    return (
      <div className={className}>
        <p className="Footer-copy">
          &copy; Powered by Fulcrum Labs&trade;. All Rights Reserved.
        </p>
      </div>
    )
  }
}
