import React, { Component } from 'react'
import { inject } from 'mobx-react'
import { Button, Modal } from '@/components'
import cx from 'classnames'

import './ActivityModal.scss'

const zoomOptions = [
  0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4,
  5,
]
const defaultZoomIndex = zoomOptions.indexOf(1)

@inject('uiStore')
export default class ActivityModal extends Component {
  img = null
  state = {
    imgWidthCoefficientIndex: defaultZoomIndex,
    isZoomTextVisible: false,
  }
  close = () => {
    const { isReview, onClose, uiStore } = this.props
    this.setState({ imgWidthCoefficientIndex: defaultZoomIndex })
    if (onClose) {
      onClose()
    }
    if (!isReview) {
      uiStore.scrollY(null, uiStore.scrollTop)
    }
  }
  removeZoomText = (prevZoom) => {
    const { imgWidthCoefficientIndex: currZoom } = this.state
    if (prevZoom === currZoom) {
      this.setState({ isZoomTextVisible: false })
    }
  }
  setImgRef = (el) => {
    this.img = el
  }
  zoom = (e, zoomOut = false) => {
    const { imgWidthCoefficientIndex } = this.state
    e.stopPropagation()
    if (zoomOut) {
      if (imgWidthCoefficientIndex === 0) return
      setTimeout(() => this.removeZoomText(imgWidthCoefficientIndex - 1), 750)
      this.setState({
        imgWidthCoefficientIndex: imgWidthCoefficientIndex - 1,
        isZoomTextVisible: true,
      })
    } else {
      if (imgWidthCoefficientIndex === zoomOptions.length - 1) return
      setTimeout(() => this.removeZoomText(imgWidthCoefficientIndex + 1), 750)
      this.setState({
        imgWidthCoefficientIndex: imgWidthCoefficientIndex + 1,
        isZoomTextVisible: true,
      })
    }
  }

  zoomOut = (e) => {
    this.zoom(e, true)
  }

  renderZoom() {
    return (
      <div className="ActivityImageModal-zoom">
        <Button
          aria-label="Zoom out image"
          className="ActivityImageModal__zoom-button"
          isText
          onClick={this.zoomOut}
        >
          <i className="fa fa-search-minus" />
        </Button>
        <Button
          aria-label="Zoom in image"
          className="ActivityImageModal__zoom-button"
          isText
          onClick={this.zoom}
        >
          <i className="fa fa-search-plus" />
        </Button>
      </div>
    )
  }

  renderZoomText() {
    const { imgWidthCoefficientIndex, isZoomTextVisible } = this.state
    return (
      <div
        className={cx('ActivityImageModal-zoomText', {
          'is-visible': isZoomTextVisible,
        })}
      >{`${Math.round(zoomOptions[imgWidthCoefficientIndex] * 100)}%`}</div>
    )
  }

  render() {
    const { image, isOpen, isReview } = this.props
    const { imgWidthCoefficientIndex } = this.state
    return (
      <Modal
        className="ActivityImageModal"
        contentLabel="Activity Image Modal"
        isOpen={isOpen}
        onClickClose
        onClose={this.close}
        setScrollTop={!isReview}
      >
        <img
          className="ActivityImageModal-image"
          ref={this.setImgRef}
          src={image}
          style={
            this.img
              ? {
                  width:
                    zoomOptions[imgWidthCoefficientIndex] *
                    this.img.naturalWidth,
                }
              : null
          }
        />
        {this.renderZoom()}
        {this.renderZoomText()}
      </Modal>
    )
  }
}
