import { observable, action, computed } from 'mobx'
import _ from 'lodash'

import { toCamelCase } from '@/utils/helpers'

import Section from './section'

export default class Unit {
  @observable index

  @observable id
  @observable title
  @observable sections
  @observable courseId
  @observable completed
  @observable mastered
  @observable pretestMastered
  @observable course

  @observable needsAccessCode = false

  constructor(data) {
    const { sections, ...restData } = data
    this.init(restData)
    this.sections = sections.map(
      (section, index) =>
        new Section({
          ...section,
          course: this.course,
          courseId: this.courseId,
          index,
          unit: this,
          unitId: this.id,
        }),
    )
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  findSectionById(id) {
    return _.find(this.sections, (section) => section.id === id)
  }

  @action findSectionAndUpdate(sectionObject) {
    const section = this.findSectionById(sectionObject.id)
    if (!section) return false
    section.activitiesCounters = sectionObject.activityCounters
    section.completed = sectionObject.completed
    section.mastered = sectionObject.mastered
    section.masteredQuiz = sectionObject.masteredQuiz
    section.pretestMastered = sectionObject.pretestMastered
    section.locked = sectionObject.locked
    section.rank = sectionObject.rank
    section.reattemptRank = sectionObject.reattemptRank
    section.reattemptStatus = sectionObject.reattemptStatus
  }

  @computed get cid() {
    return _.kebabCase(`${this.title}-${this.id}`)
  }

  @computed get sectionsLeft() {
    return this.sections.reduce(
      (incompleteSections, section) =>
        incompleteSections + (section.isMasteredWithPretest ? 0 : 1),
      0,
    )
  }

  @computed get score() {
    return this.sections.reduce((score, section) => score + section.score, 0)
  }

  @computed get isCompleted() {
    return !!this.completed
  }

  @computed get isMastered() {
    return !!this.mastered
  }

  @computed get isMasteredWithPretest() {
    return (
      !!this.mastered ||
      (this.course & this.course.snapshotTestOut && !!this.pretestMastered) ||
      _.every(
        this.sections,
        (section) => section.isMastered || section.isMasteredWithPretest,
      )
    )
  }

  @computed get isMasteredWithPretestAndReattempts() {
    return (
      (this.course & this.course.snapshotTestOut && !!this.pretestMastered) ||
      _.every(
        this.sections,
        (section) => section.isMasteredWithPretestAndReattempts,
      )
    )
  }

  @computed get totalScore() {
    return this.sections.reduce(
      (score, section) => score + section.totalScore,
      0,
    )
  }

  @computed get progress() {
    return Math.round((this.score / this.totalScore) * 100)
  }
}
