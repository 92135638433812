import React, { Component } from 'react'
import cx from 'classnames'

import './Tabs.scss'

export default class Tabs extends Component {
  changeTab = (e, active) => {
    e.preventDefault()
    this.props.onChange(active)
  }

  renderItem = (item) => {
    const { active } = this.props
    const className = cx('Tabs__item', {
      active: active === item.id,
      disabled: !!item.isDisabled,
      'Tabs__item--is-disabled': !!item.isDisabled,
    })

    return (
      <a
        aria-label={item['aria-label'] || item.text}
        qa-id={`learner-${item.text}-tab`.toLowerCase()}
        className={className}
        href={item.isDisabled ? '#' : item.href}
        key={item.id}
        onClick={
          item.isDisabled
            ? (e) => {
                e.preventDefault()
              }
            : (e) => this.changeTab(e, item)
        }
      >
        {item.text}
      </a>
    )
  }

  render() {
    const className = cx(
      'Tabs',
      {
        alt: this.props.isAlt,
      },
      this.props.className,
    )

    return (
      <div className={className} qa-id="learner-tabs">
        {this.props.children
          ? this.props.children
          : this.props.items.map(this.renderItem)}
      </div>
    )
  }
}
