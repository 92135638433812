import axios from 'axios'

import SentryReplay from '@/SentryReplay'
import {
  getBaseRequestHeaders,
  sanitizeBody,
  sanitizeUrl,
} from '@/utils/helpers'

const api = axios.create({
  baseURL: process.env.API_URL,
  timeout: 50000,
  headers: {},
})

let options = {
  handleResponse: (res) => Promise.resolve(res),
  handleErrors: (err) => Promise.reject(err),
}

api.interceptors.request.use(
  (request) => {
    const { data, method, url } = request || {}
    SentryReplay.addBreadcrumb({
      category: 'httpRequest',
      data: sanitizeBody(data && JSON.stringify(data)),
      message: `${method}: ${sanitizeUrl(url)}`,
      level: 'info',
      type: 'default',
    })
    return request
  },
  (error) => {
    const { config } = error || {}
    const { method, url } = config || {}
    SentryReplay.captureMessage(
      `${method}: ${sanitizeUrl(url)} - Request Error`,
      'error',
      {
        type: 'axios.interceptors.request.error',
      },
    )
    return Promise.reject(error)
  },
)

function _setResponseExtraData(response = {}) {
  const { config, headers, status } = response
  const { data, method, url } = config || {}
  return {
    body: sanitizeBody(data),
    canceledRequest: status === 0,
    method,
    requestId: headers && headers['x-amz-cf-id'],
    serverError: status >= 500,
    status: status,
    url,
  }
}

api.interceptors.response.use(
  (response) => {
    const { body, method, status, url } = _setResponseExtraData(response)
    SentryReplay.addBreadcrumb({
      category: 'httpResponse',
      data: body,
      message: `(${status}) ${method}: ${url}`,
      level: 'info',
      type: 'default',
    })
    return response
  },
  (error) => {
    const { response } = error || {}
    const { method, status, url } = _setResponseExtraData(response)
    if (status === 0 || status >= 500) {
      SentryReplay.captureMessage(
        `(${status}) ${method}: ${sanitizeUrl(url)} - Response Error`,
        'error',
        {
          type: 'axios.interceptors.response.error',
        },
      )
    }
    return Promise.reject(error)
  },
)

class Request {
  tokenOffsetTime = 0

  getConfig(config) {
    if (!this.authorizationHeader) return config
    return {
      ...(config || {}),
      headers: {
        ...((config && config.headers) || {}),
        ...getBaseRequestHeaders(
          this.authorizationHeaderSignature,
          this.tokenOffsetTime,
        ),
      },
    }
  }

  options(params) {
    options = { ...options, ...params }
  }

  get authorizationHeader() {
    if (
      !api.defaults.headers.common['Authorization'] ||
      api.defaults.headers.common['Authorization'] === ''
    ) {
      return null
    }
    return api.defaults.headers.common['Authorization']
  }
  get authorizationHeaderSignature() {
    try {
      const [_bearer, token] =
        api.defaults.headers.common['Authorization'].split('JWT ')
      const [_hash, _data, signature] = token.split('.')
      return signature
    } catch (e) {
      return null
    }
  }

  setToken(token, tokenOffsetTime) {
    api.defaults.headers.common['Authorization'] = `JWT ${token}`
    this.tokenOffsetTime = tokenOffsetTime
  }
  resetToken() {
    delete api.defaults.headers.common['Authorization']
    this.tokenOffsetTime = 0
  }

  // NOTE / TODO: implement cookie header - https://headware4.jira.com/browse/FD-472
  // setCookieHeader (value) {
  //   api.defaults.headers.common['x-fulcrum-cookie'] = value
  // },
  // resetCookieHeader () {
  //   delete api.defaults.headers.common['x-fulcrum-cookie']
  // },

  handleResponse = (res) => res

  get = (url, config) =>
    api
      .get(url, this.getConfig(config))
      .then(options.handleResponse)
      .catch(options.handleErrors)

  delete = (url, config) =>
    api
      .delete(url, this.getConfig(config))
      .then(options.handleResponse)
      .catch(options.handleErrors)

  put = (url, data, config) =>
    api
      .put(url, data, this.getConfig(config))
      .then(options.handleResponse)
      .catch(options.handleErrors)

  post = (url, data, config) =>
    api
      .post(url, data, this.getConfig(config))
      .then(options.handleResponse)
      .catch(options.handleErrors)

  patch = (url, data, config) =>
    api
      .patch(url, data, this.getConfig(config))
      .then(options.handleResponse)
      .catch(options.handleErrors)
}

export default new Request()
