import humps from 'lodash-humps'
import { action, computed, observable } from 'mobx'
import Activity from './activity'

import {
  ERROR_EXAM_LOAD_ACTIVITY,
  EXAM_FINISHED,
  EXAM_READY,
  EXAM_STARTED,
} from '@/constants'
import SentryReplay from '@/SentryReplay'
import { toCamelCase } from '@/utils/helpers'
import request from '@/utils/request'

export default class Exam {
  @observable activity = null
  @observable activityError = null
  @observable attempt = 0
  @observable courseId = null
  @observable courseTitle = ''
  @observable isDownloadingCertificate = false
  @observable isLoadingActivity = false
  @observable isSavingActivity = false
  @observable nasbaCpeCredits = null
  @observable nextActivity = null
  @observable numActivities = null
  @observable numSectionsTestedOut = null

  @observable introCopy = null
  @observable outroCopy = null
  @observable passed = null
  @observable results = null
  @observable score = null
  @observable status = null
  @observable surveyUrl = null

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  createNewActivity(activityData) {
    const activity = new Activity({ ...activityData })
    return activity
  }

  @computed get isDone() {
    return this.status === EXAM_FINISHED
  }

  @computed get isIntro() {
    return !this.status || this.isReady
  }

  @computed get isPassed() {
    return !!this.passed
  }

  @computed get isReady() {
    return this.status === EXAM_READY
  }

  @computed get isStarted() {
    return this.status === EXAM_STARTED
  }

  @action incrementAttempt() {
    this.attempt = this.attempt + 1
    return this.attempt
  }

  @action resetActivity() {
    this.activity = null
  }

  @action loadActivity() {
    this.isLoadingActivity = true
    this.activityError = null
    return request
      .get(`/activities/${this.courseId}/exam/activity`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleLoadActivity)
      .catch(this.handleLoadActivityError)
  }
  @action handleLoadActivity = (response) => {
    const { data } = response
    const {
      activity: activityData,
      numExamActivitiesRemaining,
      numExamActivitiesTotal,
      outro: examOutro,
      passed: examPassed,
      score: examScore,
      status: examStatus,
    } = humps(data)

    this.numExamActivitiesRemaining = numExamActivitiesRemaining
    this.numExamActivitiesTotal = numExamActivitiesTotal
    this.outroCopy = examOutro
    this.passed = examPassed
    this.score = examScore
    this.status = examStatus

    if (activityData) {
      this.resetActivity()
      this.activity = this.createNewActivity(activityData)
    } else if (this.isStarted) {
      return this.handleLoadActivityError(new Error(ERROR_EXAM_LOAD_ACTIVITY))
    }
    this.isLoadingActivity = false
    return Promise.resolve()
  }
  @action handleLoadActivityError = (error) => {
    const { message } = error
    if (message === ERROR_EXAM_LOAD_ACTIVITY) {
      this.activityError = ERROR_EXAM_LOAD_ACTIVITY
    }
    SentryReplay.captureException(error)
    this.isLoadingActivity = false
    return Promise.reject(error)
  }

  @action saveActivity(activityAnswer) {
    if (this.isSavingActivity)
      return Promise.reject(new Error('Already saving activity;'))
    if (!activityAnswer) return Promise.reject(new Error('No answer;'))

    const { id: currentActivityId, isMultipleInput } = this.activity

    this.isSavingActivity = true

    let POST = { current_activity_id: currentActivityId }
    if (isMultipleInput) {
      POST = { ...POST, answers: this.activity.adaptAnswer(activityAnswer) }
    } else {
      POST = { ...POST, answer: this.activity.adaptAnswer(activityAnswer) }
    }

    return request
      .post(`/activities/${this.courseId}/exam/answer`, POST)
      .then(this.handleSaveActivity)
      .catch(this.handleSaveActivityError)
  }
  @action handleSaveActivity = (response) => {
    const { data: responseData } = response
    const {
      activity: activityData,
      numExamActivitiesRemaining,
      numExamActivitiesTotal,
      outro: examOutro,
      passed: examPassed,
      results: examResults,
      score: examScore,
      status: examStatus,
    } = humps(responseData)

    this.numExamActivitiesRemaining = numExamActivitiesRemaining
    this.numExamActivitiesTotal = numExamActivitiesTotal
    this.outroCopy = examOutro
    this.passed = examPassed
    this.results = examResults
    this.score = examScore
    this.status = examStatus

    this.resetActivity()
    if (activityData) {
      this.activity = this.createNewActivity(activityData)
    }
    this.isSavingActivity = false
    return Promise.resolve(response)
  }
  @action handleSaveActivityError = (error) => {
    const { response } = error || {}
    const { status } = response || {}
    SentryReplay.captureException(error)
    this.isSavingActivity = false
    if (status === 403 || status === 409) {
      return this.loadActivity()
    }
    return Promise.reject(error)
  }

  validateAnswer(activityAnswer, openIncompleteQuestionModal) {
    return this.activity.validateAnswer(
      activityAnswer,
      openIncompleteQuestionModal,
    )
  }

  @action downloadCertificate() {
    this.isDownloadingCertificate = true
    return request
      .get(`/activities/${this.courseId}/exam-certificate`, {
        params: {
          d: Date.now(),
        },
      })
      .then(this.handleDownloadCertificate)
      .catch(this.handleDownloadCertificateError)
  }
  @action handleDownloadCertificate = (response) => {
    const { data: responseData } = response
    const { downloadUrl } = humps(responseData)
    this.isDownloadingCertificate = false
    window.location.assign(downloadUrl)
  }
  @action handleDownloadCertificateError = (error) => {
    SentryReplay.captureException(error)
    this.isDownloadingCertificate = false
    return Promise.reject(error)
  }
}
