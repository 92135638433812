import React, { Component } from 'react'
import validator from 'validator'

import { Button } from '@/components'
import { Input } from '@/components/form'

import form from '../form'

import './ForgotPasswordForm.scss'

const isEmail = (value) => validator.isEmail(value)

const errors = {
  email: {
    email:
      'Error: invalid email address. An email should follow the format username@domain.com.',
  },
}

const formOptions = {
  values: {
    email: '',
  },
  validate: (values) => {
    if (!isEmail(values.email)) {
      return { ...errors.email }
    }
    return null
  },
}

@form(formOptions)
export default class ForgotPasswordForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    if (!form.isValid()) return
    const { email } = form.values()
    this.props.onSubmit(email)
  }

  render() {
    const { form } = this.props
    return (
      <form className="ForgotPasswordForm" onSubmit={this.handleSubmit}>
        <div className="field">
          <div className="control">
            <Input
              {...form.inputProps('email')}
              id="ForgotPasswordForm__email"
              label="Email"
            />
          </div>
        </div>
        <div className="help-group">{form.renderErrors()}</div>
        <div className="field field-action is-grouped">
          <Button
            className="ForgotPasswordForm__cancel"
            isDark
            isFullWidth
            to="/login"
            qa-id="forgot-password-cancel-button"
          >
            Cancel
          </Button>
          <Button
            className="ForgotPasswordForm__submit"
            disabled={form.isEmpty() || this.props.isLoading}
            isFullWidth
            isLoading={this.props.isLoading}
            isSuccess
            submit
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}
