import React, { Component, Fragment } from 'react'
import { IntlProvider } from 'react-intl'
import { inject, observer } from 'mobx-react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import AccountPage from './pages/AccountPage/AccountPage'
import CoursePage from './pages/CoursePage/CoursePage'
import CoursePickerPage from './pages/CoursePickerPage'
import DashboardPage from './pages/DashboardPage/DashboardPage'
import ExamPage from './pages/ExamPage/ExamPage'
import SummaryPage from './pages/SummaryPage/SummaryPage'
import FAQPage from './pages/FAQPage/FAQPage'
import MessagesPage from './pages/MessagesPage/MessagesPage'
import SearchPage from './pages/SearchPage/SearchPage'

import LoginPage from './pages/login/LoginPage/LoginPage'
import TokenLoginPage from './pages/login/TokenLoginPage/TokenLoginPage'
import ForgotPasswordPage from './pages/login/ForgotPasswordPage/ForgotPasswordPage'
import ResetPasswordPage from './pages/login/ResetPasswordPage/ResetPasswordPage'
import UpdatePasswordPage from './pages/login/UpdatePasswordPage/UpdatePasswordPage'
import AdminNotFoundPage from './admin/pages/AdminNotFoundPage/AdminNotFoundPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'

import AdminRoutes from './admin/Routes'

import { DEFAULT_LOCALE } from './constants'
import { AppLayout } from './layouts'

import { LocaleSelectorModal } from '@/components'

import da from 'react-intl/locale-data/da'
import de from 'react-intl/locale-data/de'
import en from 'react-intl/locale-data/en'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import ja from 'react-intl/locale-data/ja'
import ko from 'react-intl/locale-data/ko'
import nb from 'react-intl/locale-data/nb'
import pl from 'react-intl/locale-data/pl'
import pt from 'react-intl/locale-data/pt'
import ro from 'react-intl/locale-data/ro'
import ru from 'react-intl/locale-data/ru'
import si from 'react-intl/locale-data/si'
import tr from 'react-intl/locale-data/tr'
import uk from 'react-intl/locale-data/uk'
import zh from 'react-intl/locale-data/zh'
import { addLocaleData } from 'react-intl'
addLocaleData([
  ...da,
  ...de,
  ...en,
  ...es,
  ...fr,
  ...ja,
  ...ko,
  ...nb,
  ...pl,
  ...pt,
  ...ro,
  ...ru,
  ...si,
  ...tr,
  ...uk,
  ...zh,
])

@inject('sessionStore', 'uiStore')
@observer
export default class App extends Component {
  componentDidMount() {
    this.updateTitle()
  }
  updateTitle(title) {
    const { uiStore } = this.props
    uiStore.updateTitle(title)
  }
  renderAdminRoutes() {
    return (
      <Switch>
        <Redirect exact from="/" to="/admin" />
        <Redirect exact from="/index.html" to="/admin" />
        <PrivateRoute path="/admin" component={AdminRoutes} />
        <PrivateRoute exact path="/account" component={AccountPage} />
        <PrivateRoute exact component={AdminNotFoundPage} />
      </Switch>
    )
  }
  renderLearnerRoutes() {
    return (
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/index.html" to="/" />
        <PrivateRoute exact path="/courses" component={CoursePickerPage} />
        <PrivateRoute exact path="/dashboard" component={DashboardPage} />
        <PrivateRoute exact path="/dashboard/course" component={SummaryPage} />
        <PrivateRoute exact path="/exam" component={ExamPage} />
        <PrivateRoute
          exact
          path="/units/:unitId/sections/:sectionId/:tab(watch|read|practice|quiz)"
          component={CoursePage}
        />
        <PrivateRoute
          exact
          path="/messages/:threadId?"
          component={MessagesPage}
        />
        <PrivateRoute exact path="/account" component={AccountPage} />
        <PrivateRoute exact path="/faq" component={FAQPage} />
        <PrivateRoute exact path="/search" component={SearchPage} />
        <PrivateRoute exact component={NotFoundPage} />
      </Switch>
    )
  }
  renderRoutes() {
    const { sessionStore } = this.props
    const { session } = sessionStore
    const { user } = session || {}
    const { isCurrentRoleAdmin } = user || {}
    if (isCurrentRoleAdmin) return this.renderAdminRoutes()
    return this.renderLearnerRoutes()
  }
  render() {
    const { sessionStore } = this.props
    const { localeMessages, session } = sessionStore || {}
    const { locale } = session || {}
    return (
      <IntlProvider
        defaultLocale={DEFAULT_LOCALE}
        locale={locale}
        messages={localeMessages}
      >
        <Fragment>
          <Router>
            <Switch>
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/login/ldap/:orgAlias/:ldapKey"
                component={LoginPage}
              />
              <Route
                exact
                path="/login/token/:token"
                component={TokenLoginPage}
              />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/reset-password/:token"
                component={ResetPasswordPage}
              />
              <Route
                exact
                path="/set-password/:token"
                component={ResetPasswordPage}
              />
              <Route
                exact
                path="/update-password"
                component={UpdatePasswordPage}
              />
              <AppLayout>{this.renderRoutes()}</AppLayout>
            </Switch>
          </Router>
          <LocaleSelectorModal />
        </Fragment>
      </IntlProvider>
    )
  }
}
