import React from 'react'
import cx from 'classnames'

import { Link } from '@/components'

import {
  SETTINGS_CATEGORY_GENERAL,
  SETTINGS_CATEGORY_INTEGRATIONS,
  SETTINGS_CATEGORY_EXAM,
  SETTINGS_CATEGORY_PRETEST,
  SETTINGS_CATEGORY_SNAPSHOT,
} from './InstanceSettingsPageConstants'

const InstanceSettingsPageSideBar = ({ basePath, category }) => {
  const examLabel = 'Go to Exam Settings'
  const integrationsLabel = 'Go to Integration Settings'
  const pretestLabel = 'Go to Pre-Test Settings'
  const snapshotLabel = 'Go to Snapshot Settings'
  return (
    <div className="InstanceSettingsPage-sidebar">
      <ul className="InstanceSettingsPage-sidebar__list">
        <li className="InstanceSettingsPage-sidebar__list-item">
          <Link
            aria-label="Go to General Settings"
            className={cx('InstanceSettingsPage-sidebar__link', {
              'InstanceSettingsPage-sidebar__link--is-active':
                category === SETTINGS_CATEGORY_GENERAL,
            })}
            onClick={(event) => {
              if (category === SETTINGS_CATEGORY_GENERAL) {
                event.preventDefault()
              }
            }}
            tabIndex={category === SETTINGS_CATEGORY_GENERAL ? -1 : null}
            title="Go to General Settings"
            to={`${basePath}/${SETTINGS_CATEGORY_GENERAL}`}
          >
            General
          </Link>
        </li>
        <li className="InstanceSettingsPage-sidebar__list-item">
          <Link
            aria-label={integrationsLabel}
            className={cx('InstanceSettingsPage-sidebar__link', {
              'InstanceSettingsPage-sidebar__link--is-active':
                category === SETTINGS_CATEGORY_INTEGRATIONS,
            })}
            onClick={(event) => {
              if (category === SETTINGS_CATEGORY_INTEGRATIONS) {
                event.preventDefault()
              }
            }}
            tabIndex={category === SETTINGS_CATEGORY_INTEGRATIONS ? -1 : null}
            title={integrationsLabel}
            to={`${basePath}/${SETTINGS_CATEGORY_INTEGRATIONS}`}
          >
            Integrations
          </Link>
        </li>
        <li className="InstanceSettingsPage-sidebar__list-item">
          <Link
            aria-label={examLabel}
            className={cx('InstanceSettingsPage-sidebar__link', {
              'InstanceSettingsPage-sidebar__link--is-active':
                category === SETTINGS_CATEGORY_EXAM,
            })}
            onClick={(event) => {
              if (category === SETTINGS_CATEGORY_EXAM) {
                event.preventDefault()
              }
            }}
            tabIndex={category === SETTINGS_CATEGORY_EXAM ? -1 : null}
            title={examLabel}
            to={`${basePath}/${SETTINGS_CATEGORY_EXAM}`}
          >
            Exam
          </Link>
        </li>
        <li className="InstanceSettingsPage-sidebar__list-item">
          <Link
            aria-label={pretestLabel}
            className={cx('InstanceSettingsPage-sidebar__link', {
              'InstanceSettingsPage-sidebar__link--is-active':
                category === SETTINGS_CATEGORY_PRETEST,
            })}
            onClick={(event) => {
              if (category === SETTINGS_CATEGORY_PRETEST) {
                event.preventDefault()
              }
            }}
            tabIndex={category === SETTINGS_CATEGORY_PRETEST ? -1 : null}
            title={pretestLabel}
            to={`${basePath}/${SETTINGS_CATEGORY_PRETEST}`}
          >
            Pre-Test
          </Link>
        </li>
        <li className="InstanceSettingsPage-sidebar__list-item">
          <Link
            aria-label={snapshotLabel}
            className={cx('InstanceSettingsPage-sidebar__link', {
              'InstanceSettingsPage-sidebar__link--is-active':
                category === SETTINGS_CATEGORY_SNAPSHOT,
            })}
            onClick={(event) => {
              if (category === SETTINGS_CATEGORY_SNAPSHOT) {
                event.preventDefault()
              }
            }}
            tabIndex={category === SETTINGS_CATEGORY_SNAPSHOT ? -1 : null}
            title={snapshotLabel}
            to={`${basePath}/${SETTINGS_CATEGORY_SNAPSHOT}`}
          >
            Snapshot
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default InstanceSettingsPageSideBar
