import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import _ from 'lodash'

import { RichTextWrapper, Video } from '@/components'
import { Select } from '@/components/form'
import { ACTIVITY_TYPES, VIDEO_TYPES } from '@/constants'

import Activity from '../Activity'
import CorrectIncorrectIcon from '../CorrectIncorrectIcon'
import './ActivityPhrase.scss'

const ActivityPhraseMessages = defineMessages({
  label: {
    defaultMessage: 'Choose the correct answer for each item.',
    description: 'Instructions label for categorization activity.',
    id: 'ActivityPhrase.label',
  },
  loadingActivityTitleContent: {
    defaultMessage: 'Loading question...',
    description:
      'Description displayed when the Categorization Activity Question content is loading upon receiving an Activity.',
    id: 'ActivityPhrase.loadingActivityTitleContent',
  },
  selectCategory: {
    defaultMessage: 'Select',
    description: 'Default dropdown option for categorization activity.',
    id: 'ActivityPhrase.selectCategory',
  },
})

const EMPTY_CATEGORY = '__EMPTY__'

@injectIntl
export default class ActivityPhrase extends Component {
  static defaultProps = {
    labelIcon: 'arrows',
  }
  state = {
    changedSelections: {},
  }
  componentDidUpdate(prevProps) {
    const { answerResponse, correctAnswers, incorrectAnswers } = this.props
    if (
      answerResponse &&
      (prevProps.correctAnswers !== correctAnswers ||
        prevProps.incorrectAnswers !== incorrectAnswers)
    ) {
      this.setState({ changedSelections: {} })
    }
  }
  get label() {
    const { intl, label } = this.props
    const { formatMessage } = intl
    if (label) {
      return label
    }
    return formatMessage(ActivityPhraseMessages.label)
  }
  get selectedPhrases() {
    const categories = this.props.value || []
    return _.flatten(
      categories.map((c) => (c && c.phrases ? [...c.phrases] : [])),
    )
  }
  isSelected(phrase) {
    return _.includes(this.selectedPhrases, phrase)
  }
  onCategoryChange = (e) => {
    const { answerResponse, hasNextActivity, isSavingActivity } = this.props
    const { changedSelections } = this.state
    const categoryName = e.target.value
    const phrase = e.target.name
    const id = e.target.id
    const categories = this.props.value || []
    if (isSavingActivity) return
    if (answerResponse && !hasNextActivity) {
      this.setState({
        changedSelections: { ...changedSelections, [id]: true },
      })
    }
    const category = _.find(categories, (c) => c.name === categoryName)
    const newCategories = _.compact(
      categories.map((c) => {
        const newPhrases = c.phrases.filter((p) => p !== phrase)
        if (c.name === categoryName) {
          return { ...c, phrases: [...newPhrases, phrase] }
        }
        if (_.isEmpty(newPhrases)) return null
        return { ...c, phrases: newPhrases }
      }),
    )

    // if no category, add one together with the phrase
    if (!category && categoryName !== EMPTY_CATEGORY) {
      return this.props.onChange([
        ...newCategories,
        { name: categoryName, phrases: [phrase] },
      ])
    }

    return this.props.onChange(newCategories)
  }
  isPhraseCorrect = (phrase) => {
    const { answerResponse, correctAnswers, incorrectAnswers } = this.props
    const { changedSelections } = this.state
    if (!answerResponse || changedSelections[phrase.id]) return null

    let isCorrect = null

    for (let i = 0; i < correctAnswers.length; i++) {
      if (phrase.text.trim() === correctAnswers[i].trim()) {
        isCorrect = true
        break
      }
    }

    if (!isCorrect) {
      for (let i = 0; i < incorrectAnswers.length; i++) {
        if (phrase.text.trim() === incorrectAnswers[i].trim()) {
          isCorrect = false
          break
        }
      }
    }

    return isCorrect
  }
  getAdminCorrectAnswerName = (adminCorrectAnswers, text) => {
    const correctAnswer = adminCorrectAnswers.filter(
      (obj) =>
        Object.keys(obj).filter(
          (n) => obj[n] && obj[n].indexOf(text.trim()) !== -1,
        ).length,
    )[0]
    return correctAnswer ? Object.keys(correctAnswer)[0] : ''
  }
  getAdminIncorrectAnswerName = (adminIncorrectAnswers, text) => {
    let incorrectAnswerName = ''
    Object.keys(adminIncorrectAnswers).forEach((key) => {
      adminIncorrectAnswers[key].forEach((n) => {
        if (n.phrase === text) {
          incorrectAnswerName = key
        }
      })
    })
    return incorrectAnswerName
  }
  renderAdminCorrectIncorrectIcon(isCorrect) {
    return (
      <div
        className={cx('ActivityPhrase-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderCorrectIncorrectIcon = (isCorrect) => {
    const { answerResponse } = this.props
    if (!answerResponse || isCorrect === null) return null

    return (
      <div
        className={cx('ActivityPhrase-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderPhrase = (phrase, index) => {
    const { adminCorrectAnswers, adminIncorrectAnswers } = this.props
    if (!phrase.isPhrase) return null
    let isCorrect = adminCorrectAnswers
      ? true
      : adminIncorrectAnswers
      ? false
      : this.isPhraseCorrect(phrase)
    const isSelected = adminCorrectAnswers
      ? true
      : adminIncorrectAnswers
      ? !!Object.keys(adminIncorrectAnswers).filter(
          (incorrectAnswerName) =>
            !!adminIncorrectAnswers[incorrectAnswerName].filter(
              (incorrectAnswer) => {
                if (phrase.text === incorrectAnswer.phrase) {
                  isCorrect = incorrectAnswer.isCorrect
                  return true
                }
                return false
              },
            )[0],
        )[0]
      : this.isSelected(phrase.text)
    const className = cx('ActivityPhrase-phrase', {
      'is-selected': isSelected,
    })
    const id = phrase.id || `ActivityPhrase-item-${index}`
    return (
      <div key={phrase.text} className="ActivityPhrase-wrapper">
        <div className={className}>
          <div className="ActivityPhrase-phraseText">
            <label htmlFor={id}>{phrase.text}</label>
          </div>
          <div className="ActivityPhrase-phraseCategory">
            {this.renderCategories(phrase, isCorrect, id)}
          </div>
        </div>
        {adminCorrectAnswers || adminIncorrectAnswers
          ? this.renderAdminCorrectIncorrectIcon(isCorrect)
          : this.renderCorrectIncorrectIcon(isCorrect)}
      </div>
    )
  }
  renderItemPhrase = (item, index) => {
    if (!item) return null
    return this.renderPhrase({
      id: `ActivityPhrase-item-${index}`,
      isPhrase: true,
      text: item.title,
    })
  }
  renderContentItem = (item, index, arr) => {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      id: activityId,
      intl,
      onOpenImageModal,
    } = this.props
    const { formatMessage } = intl
    const className = cx('ActivityPhrase-contentItem', {
      'is-phrase': item.isPhrase,
      'is-selected':
        adminCorrectAnswers && item.isPhrase
          ? true
          : adminIncorrectAnswers
          ? !!Object.keys(adminIncorrectAnswers).filter(
              (incorrectAnswerName) =>
                !!adminIncorrectAnswers[incorrectAnswerName].filter(
                  (incorrectAnswer) => item.text === incorrectAnswer.phrase,
                )[0],
            )[0]
          : this.isSelected(item.text),
    })
    const key = `${item.text}-${index}`
    if (arr.length === 1) {
      return (
        <RichTextWrapper
          className="ActivityPhrase__rich-text-wrapper"
          content={item.text}
          id={`ActivityPhrase__${activityId}`}
          key={`ActivityPhrase__${activityId}`}
          loadingText={formatMessage(
            ActivityPhraseMessages.loadingActivityTitleContent,
          )}
          onOpenImageModal={onOpenImageModal}
        />
      )
    }
    return (
      <span
        className={className}
        dangerouslySetInnerHTML={{ __html: item.text }}
        key={key}
      />
    )
  }
  renderCategories(phrase, isCorrect = false, id = null) {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      categories,
      hasNextActivity,
      intl,
      value: values,
    } = this.props
    const { formatMessage } = intl
    const { id: phraseId, text } = phrase
    const value = values
      ? values.filter((value) => value.phrases.indexOf(text) !== -1)[0]
      : null
    const name = value ? value.name : ''
    const categoryOptions = [
      {
        text: formatMessage(ActivityPhraseMessages.selectCategory),
        value: EMPTY_CATEGORY,
      },
      ...categories.map((c) => ({ text: c.name, value: c.name })),
    ]
    return (
      <div className="ActivityPhrase-phraseSelect">
        <Select
          id={phraseId || id}
          qa-id="learner-activity-select"
          isFullWidth
          options={categoryOptions}
          name={text}
          onChange={this.onCategoryChange}
          value={
            adminCorrectAnswers
              ? this.getAdminCorrectAnswerName(adminCorrectAnswers, text)
              : adminIncorrectAnswers
              ? this.getAdminIncorrectAnswerName(adminIncorrectAnswers, text)
              : name
          }
          disabled={
            isCorrect ||
            hasNextActivity ||
            adminCorrectAnswers ||
            adminIncorrectAnswers
          }
        />
      </div>
    )
  }
  renderPhrases() {
    const { content, items } = this.props
    return items
      ? items.map(this.renderItemPhrase)
      : content.map(this.renderPhrase)
  }
  renderVideo() {
    const { video } = this.props
    return (
      <div className="Activity-video">
        <Video type={VIDEO_TYPES.KALTURA_VIDEO} entryId={video} />
      </div>
    )
  }
  renderBody = () => {
    const { content, items, video } = this.props
    return (
      <div className="ActivityPhrase">
        <div className="ActivityPhrase-body">
          <div className="ActivityPhrase-content Activity__question-stem">
            {video && this.renderVideo()}
            {content.map(this.renderContentItem)}
          </div>
          <div className="ActivityPhrase-phrases">
            <form
              onSubmit={(event) => {
                event.preventDefault()
              }}
            >
              {this.renderPhrases(items)}
            </form>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { label, ...restProps } = this.props
    return (
      <Activity
        {...restProps}
        className={cx('is-phrase', this.props.className)}
        label={this.label}
        render={this.renderBody}
        type={ACTIVITY_TYPES.dragPhrase}
      />
    )
  }
}
