import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import _ from 'lodash'

import { Button } from '@/components'
import { ACTIVITY_TYPES } from '@/constants'

import Activity from '../Activity'
import CorrectIncorrectIcon from '../CorrectIncorrectIcon'
import './ActivitySort.scss'

const ActivitySortMessages = defineMessages({
  emptyStack: {
    defaultMessage: 'Click/Tap the step you want to add first.',
    description:
      'Description displayed in the sorted column when no answers have been selected for sorting activity.',
    id: 'ActivitySort.emptyStack',
  },
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to zoom in on sorting activity images.',
    id: 'ActivitySort.enlargeImage',
  },
  label: {
    defaultMessage:
      'Click/Tap each item on the left to correctly order on the right.',
    description: 'Instructions label for sorting activity.',
    id: 'ActivitySort.label',
  },
})

const include = (value, item) => {
  if (!value) return false
  return !!_.find(value, (v) => v.id === item.id)
}

@injectIntl
export default class ActivitySort extends Component {
  static defaultProps = {
    // label: 'Click each item on the left in the order that they should appear on the right.',
    labelIcon: 'arrows',
  }
  state = {
    shouldHideAnswerResponseIcons: false,
  }
  get label() {
    const { intl, label } = this.props
    const { formatMessage } = intl
    if (label) {
      return label
    }
    return formatMessage(ActivitySortMessages.label)
  }
  componentDidUpdate(prevProps) {
    const { answerResponse } = this.props
    if (answerResponse && prevProps.answerResponse !== answerResponse) {
      this.setState({
        shouldHideAnswerResponseIcons: false,
      })
    }
  }
  addItem(event, item) {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      hasNextActivity,
      isSavingActivity,
    } = this.props
    event.preventDefault()
    if (
      adminCorrectAnswers ||
      adminIncorrectAnswers ||
      hasNextActivity ||
      isSavingActivity
    )
      return
    const value = this.props.value || []
    if (!this.state.shouldHideAnswerResponseIcons) {
      this.setState({
        shouldHideAnswerResponseIcons: true,
      })
    }
    if (include(value, item)) return
    const newValue = [].concat(value, item)
    this.props.onChange(newValue)
    this.setFocusBody()
  }
  removeItem(event, item) {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      hasNextActivity,
      isSavingActivity,
    } = this.props
    event.preventDefault()
    if (
      adminCorrectAnswers ||
      adminIncorrectAnswers ||
      hasNextActivity ||
      isSavingActivity
    )
      return
    const newValue = this.props.value.filter((v) => v.id !== item.id)
    this.props.onChange(newValue)
    if (!this.state.shouldHideAnswerResponseIcons) {
      this.setState({
        shouldHideAnswerResponseIcons: true,
      })
    }
    this.setFocusBody()
  }
  renderItem = (item) => {
    const hasItem = include(this.props.value, item)
    const className = cx('ActivitySort-item', { 'is-empty': hasItem })
    let child = (
      <a
        aria-hidden={hasItem}
        className={className}
        href="#"
        onClick={(event) => this.addItem(event, item)}
      >
        {this.renderItemContent(item, hasItem)}
      </a>
    )
    if (hasItem) {
      child = (
        <div aria-hidden={hasItem} className={className}>
          {this.renderItemContent(item, hasItem)}
        </div>
      )
    }
    return (
      <div
        key={`ActivitySort-itemOptionWrapper-${item.id}`}
        className="ActivitySort-itemOptionWrapper"
      >
        {child}
      </div>
    )
  }
  setFocusBody = () => {
    if (!this.$body) return
    this.$body.focus()
  }
  setRefBody = (el) => {
    if (!el) return
    this.$body = el
  }
  renderItemContent = (item, hasItem) => {
    const { intl, onOpenActivityModal } = this.props
    const { formatMessage } = intl
    return (
      <Fragment>
        {item.image && <img className="ActivitySort-image" src={item.image} />}
        {item.image && !hasItem && (
          <Button
            aria-label={formatMessage(ActivitySortMessages.enlargeImage)}
            className="Activity-zoomImage"
            onClick={(e) => onOpenActivityModal(e, item.image)}
          >
            <span>{formatMessage(ActivitySortMessages.enlargeImage)}</span>
            <i
              aria-hidden="true"
              className="fa fa-search-plus"
              title={formatMessage(ActivitySortMessages.enlargeImage)}
            />
          </Button>
        )}
        {item.content && (
          <div
            className="ActivitySort-text"
            dangerouslySetInnerHTML={{ __html: item.content }}
            qa-id="learner-activity-sort-text"
          />
        )}
      </Fragment>
    )
  }
  renderAdminCorrectIncorrectIcon(isCorrect) {
    return (
      <div
        className={cx('ActivityChoice-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderCorrectIncorrectIcon(item) {
    const { answerResponse } = this.props
    if (!answerResponse || this.state.shouldHideAnswerResponseIcons) return null
    let isCorrect = null

    for (let i = 0; i < answerResponse.length; i++) {
      if (item.id === answerResponse[i].value) {
        isCorrect = answerResponse[i].isCorrect
        break
      }
    }

    return (
      <div
        className={cx('ActivitySort-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderSortedItem = (item) => {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      intl,
      onOpenActivityModal,
    } = this.props
    const { formatMessage } = intl
    let isCorrect = !!adminCorrectAnswers
    if (adminIncorrectAnswers) {
      let answer = adminIncorrectAnswers.filter(
        ({ label }) => label === item.id,
      )[0]
      isCorrect = answer ? answer.isCorrect : false
    }
    return (
      <div key={item.id} className="ActivitySort-itemAnswerWrapper">
        <a
          className="ActivitySort-item is-sorted"
          href="#"
          onClick={(event) => this.removeItem(event, item)}
        >
          {item.image && (
            <img className="ActivitySort-image" src={item.image} />
          )}
          {item.content && (
            <div
              className="ActivitySort-text"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          )}
          {item.image && (
            <Button
              aria-label={formatMessage(ActivitySortMessages.enlargeImage)}
              className="Activity-zoomImage"
              onClick={(e) => onOpenActivityModal(e, item.image)}
            >
              <span>{formatMessage(ActivitySortMessages.enlargeImage)}</span>
              <i
                aria-hidden="true"
                className="fa fa-search-plus"
                title={formatMessage(ActivitySortMessages.enlargeImage)}
              />
            </Button>
          )}
        </a>
        {adminCorrectAnswers || adminIncorrectAnswers
          ? this.renderAdminCorrectIncorrectIcon(isCorrect)
          : this.renderCorrectIncorrectIcon(item)}
      </div>
    )
  }
  renderEmptyMessage() {
    const { intl } = this.props
    const { formatMessage } = intl
    if (!_.isEmpty(this.props.value)) return
    return (
      <div className="ActivitySort-emptyStack">
        {formatMessage(ActivitySortMessages.emptyStack)}
      </div>
    )
  }
  renderBody = ({ renderTitle, renderImageAfterQuestion }) => {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      imageAlignment,
      items,
      value,
    } = this.props
    const adminAnswers = adminCorrectAnswers || adminIncorrectAnswers
    const adminAnswerValues = adminAnswers
      ? adminAnswers.map((answer) => {
          const { label } = answer
          return items.filter((item) => item.id === label)[0]
        })
      : null
    const values = adminAnswers ? adminAnswerValues : value
    // if there's an item with an image, we display the image layout
    const hasImage = !!_.find(items, (item) => !_.isEmpty(item.image))
    return (
      <div className="ActivitySort">
        {renderTitle()}
        {imageAlignment === 'bottom' ? renderImageAfterQuestion() : null}
        <div
          className={cx('ActivitySort-body columns is-gapless', {
            'with-images': hasImage,
          })}
          ref={this.setRefBody}
          tabIndex="0"
        >
          <div className="column is-half">
            <div className="ActivitySort-items">
              {adminAnswers
                ? adminIncorrectAnswers
                  ? items
                      .filter((item) => {
                        const { id } = item
                        let isNotSelected = true
                        for (let i = 0; i < adminIncorrectAnswers.length; i++) {
                          const { label } = adminIncorrectAnswers[i]
                          if (id === label) {
                            isNotSelected = false
                            break
                          }
                        }
                        return isNotSelected
                      })
                      .map(this.renderItem)
                  : []
                : items.map(this.renderItem)}
            </div>
          </div>
          <div
            className={cx('ActivitySort-stack column is-half', {
              'is-empty': _.isEmpty(values),
            })}
          >
            {values && (
              <div className="ActivitySort-items">
                {values.map(this.renderSortedItem)}
              </div>
            )}
            {adminAnswers ? null : this.renderEmptyMessage()}
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { label, ...restProps } = this.props
    return (
      <Activity
        {...restProps}
        className={cx('is-sort', this.props.className)}
        label={this.label}
        render={this.renderBody}
        type={ACTIVITY_TYPES.sortStack}
      />
    )
  }
}
