import React from 'react'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'

import './Select.scss'

const Select = (props) => {
  const {
    defaultValue,
    getOptionLabel,
    getOptionValue,
    hideSelectedOptions,
    id,
    isAsync,
    isLoading,
    isMulti,
    loadOptions,
    maxMenuHeight,
    name,
    onChange,
    placeholder,
  } = props
  if (isAsync) {
    return (
      <ReactSelectAsync
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        hideSelectedOptions={hideSelectedOptions}
        id={id}
        isLoading={isLoading}
        isMulti={isMulti}
        loadOptions={loadOptions}
        maxMenuHeight={maxMenuHeight}
        name={name}
        onChange={onChange}
        placeholder={placeholder || ''}
      />
    )
  }
  return (
    <ReactSelect
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      hideSelectedOptions={hideSelectedOptions}
      id={id}
      isLoading={isLoading}
      isMulti={isMulti}
      loadOptions={loadOptions}
      maxMenuHeight={maxMenuHeight}
      name={name}
      onChange={onChange}
      placeholder={placeholder || ''}
    />
  )
}

export default Select
