import React, { Component } from 'react'
import cx from 'classnames'
import { Heading, Modal } from '@/components'
import './SaveExistingUserModal.scss'

const SaveExistingUserModalDefaultValues = function (values) {
  if (!values || typeof values !== 'object') {
    values = {}
  }
  return {
    instructor: values.instructor || null,
    roles: values.roles || [],
    user: values.user || null,
  }
}

export default class SaveExistingUserModal extends Component {
  static defaultProps = {
    values: new SaveExistingUserModalDefaultValues(),
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  render() {
    const {
      className,
      courseTitle,
      email,
      error,
      firstName,
      isLoading,
      isOpen,
      lastName,
      onConfirm,
      username,
    } = this.props
    const errorMessageConfirm =
      error && error['confirm'] ? error['confirm'][0] : null
    return (
      <Modal
        className={cx(
          'SaveExistingUserModal',
          {
            'is-error': !!error,
          },
          className,
        )}
        contentLabel="Save Existing User Modal"
        isDanger
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>Add Existing User</Heading>
        </Modal.Header>
        <Modal.Body>
          <div className="SaveExistingUserModal-body">
            <p className="SaveExistingUserModal-prompt">
              {`A user with the email "${email}" already exists. Would you like to add the existing user to "${courseTitle}"?`}
            </p>
            <p className="SaveExistingUserModal-description">
              <span>{`Username: ${username}`}</span>
              <span>{`Email: ${email}`}</span>
              <span>{`First Name: ${firstName}`}</span>
              <span>{`Last Name: ${lastName}`}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button isSecondary onClick={this.onClose}>
            Cancel
          </Modal.Button>
          <Modal.Button isLoading={isLoading} onClick={onConfirm}>
            Confirm
          </Modal.Button>
          {errorMessageConfirm ? (
            <span className="SaveExistingUserModal-confirmError">{`* ${errorMessageConfirm}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { SaveExistingUserModalDefaultValues }
