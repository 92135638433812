import _ from 'lodash'
import qs from 'query-string'
import React, { Component, Fragment } from 'react'
// import { defineMessages, injectIntl } from 'react-intl'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'

import {
  Activity,
  ActivityCompleted,
  Button,
  Heading,
  IncompleteQuestionModal,
  Link,
  Loader,
} from '@/components'
import TimeTracking, {
  TYPE_QUIZ_ACTIVITY,
} from '@/components/TimeTracking/TimeTracking'
import { ACTIVITY_COMPLETED_CORRECT } from '@/constants'

// const QuizMessages = defineMessages({})

const initialState = {
  isActivityQuestionOpen: false,
  isOpenResults: false,
}

@injectIntl
@withRouter
@inject('contentStore')
@observer
export default class CoursePageQuiz extends Component {
  state = { ...initialState }

  get activity() {
    const { activity } = this.quiz
    return activity
  }
  get quiz() {
    const { quiz } = this.props
    return quiz || {}
  }
  get courseId() {
    const { courseId } = this.quiz
    return courseId
  }
  get isActivityFeedbackOpen() {
    const { hasNextActivity, isDone } = this.quiz
    return hasNextActivity || (isDone && this.activity)
  }
  componentDidMount() {
    const { history, location } = this.props
    const { isStarted: isStartedQuiz } = this.quiz
    const { pathname, search } = location || {}
    const querystring = qs.parse(search)
    const { a: qsActivityId } = querystring || {}
    if (qsActivityId && !this.activity) {
      delete querystring['a']
      history.replace(`${pathname}?${qs.stringify(querystring)}`)
    }
    if (isStartedQuiz) {
      this.quiz.loadActivity()
    }
  }
  componentDidUpdate() {
    const { history, location } = this.props
    const { id: activityId } = this.activity || {}
    const { pathname, search } = location || {}
    const querystring = qs.parse(search)
    const { a: qsActivityId } = querystring || {}
    if (qsActivityId && !this.activity) {
      delete querystring['a']
      history.replace(`${pathname}?${qs.stringify(querystring)}`)
    } else if (activityId && (!qsActivityId || qsActivityId !== activityId)) {
      querystring['a'] = activityId
      history.replace(`${pathname}?${qs.stringify(querystring)}`)
    }
  }
  onChange = (value) => {
    if (this.isActivityFeedbackOpen) return
    this.quiz.saveActivityAnswer(value)
  }
  onClickOpenResults = () => {
    return this.setState({ isOpenResults: true })
  }
  onClickCloseResults = () => {
    return this.setState({ isOpenResults: false })
  }
  onClickStartQuiz = () => {
    this.setState({ ...initialState })
    return this.quiz.loadActivity()
  }
  onConfirmIncomplete = (event) => {
    const { onSubmit } = this.props
    onSubmit(event, true)
  }
  renderResultsItem = (item, index) => {
    const {
      learningObjectives: itemLearningObjectives,
      passed: itemHasPassed,
      question: itemQuestion,
    } = item || {}
    return (
      <div
        className="CoursePageQuiz__results-item"
        key={`CoursePageQuiz__results-item-${index}`}
      >
        <Heading className="CoursePageQuiz__results-item-number" is3>
          Question {index + 1}:
        </Heading>
        <div className="CoursePageQuiz__results-item-question">
          <Heading
            className="CoursePageQuiz__results-item-question-title"
            dangerouslySetInnerHTML={{ __html: itemQuestion }}
            is2
          />
          <div className="CoursePageQuiz__results-item-question-icon">
            <span>
              {itemHasPassed ? (
                <i className="fa fa-check" />
              ) : (
                <i className="fa fa-close" />
              )}
            </span>
          </div>
        </div>
        <div className="CoursePageQuiz__results-item-learning-objectives">
          {itemLearningObjectives.map((lo, loIndex) => (
            <p
              className="CoursePageQuiz__results-item-learning-objective"
              key={`CoursePageQuiz__results-item-learning-objective-${loIndex}`}
            >
              * {lo}
            </p>
          ))}
        </div>
      </div>
    )
  }
  renderResults() {
    const { isPassed, results, score } = this.quiz
    return (
      <div
        className={cx('CoursePageQuiz__results', {
          'CoursePageQuiz__results--is-passed': isPassed,
        })}
      >
        <Heading className="CoursePageQuiz__results-heading" is1>
          <Button
            className="CoursePageQuiz__results-back-button"
            isInfo
            onClick={this.onClickCloseResults}
          >
            &lt; Back
          </Button>
          Quiz Results
        </Heading>
        <Heading className="CoursePageQuiz__results-score" is2>
          <span className="CoursePageQuiz__results-score-label">
            Your Score:
          </span>
          &nbsp;{score}%
        </Heading>
        <div className="CoursePageQuiz__results-items">
          {results.map(this.renderResultsItem)}
        </div>
      </div>
    )
  }
  renderDone() {
    const { isUpdatingCourseProgress, requiredQuizScore } = this.props
    const { isOpenResults } = this.state
    const { isLoadingActivity, isPassed, score } = this.quiz

    const isQuizMastery = false

    if (this.isActivityFeedbackOpen && isUpdatingCourseProgress)
      return <Loader />
    if (isOpenResults) return this.renderResults()
    return (
      <div
        className={cx('CoursePageQuiz__done', {
          'CoursePageQuiz__done--is-passed': isPassed,
        })}
      >
        <Heading className="CoursePageQuiz__done-heading" is1>
          {isPassed ? 'You Passed the Quiz!' : 'You Did Not Pass the Quiz'}
        </Heading>
        <Heading className="CoursePageQuiz__done-score" is2>
          <span className="CoursePageQuiz__done-score-label">Your Score: </span>
          {score}%
        </Heading>
        {isPassed ? (
          <p className="CoursePageQuiz__done-description">
            Congratulations!
            <br />
            {isQuizMastery && "You've unlocked the next Section of content. "}
            Click &quot;Next Section&quot; to proceed.
            <br />
            Feel free to retake practice quizzes at anytime by clicking
            &quot;Start New Quiz.&quot;
          </p>
        ) : (
          <p className="CoursePageQuiz__done-description">
            To {isQuizMastery ? 'advance' : 'pass'}, you need a score of&nbsp;
            <span className="CoursePageQuiz__done-description-score">
              {requiredQuizScore}%
            </span>
            &nbsp;or higher.
            <br />
            Click &quot;Retake Quiz&quot; to try again or, if you need to
            refresh your memory, go back to the source materials to increase
            your understanding.
          </p>
        )}
        <div className="CoursePageQuiz__done-buttons">
          <Button
            className="CoursePageQuiz__done-button"
            isInfo
            onClick={this.onClickOpenResults}
          >
            See Results
          </Button>
          <Button
            className="CoursePageQuiz__done-button"
            isLoading={isLoadingActivity}
            isSuccess
            onClick={this.onClickStartQuiz}
          >
            {isPassed ? 'Start New Quiz' : 'Retake Quiz'}
          </Button>
        </div>
      </div>
    )
  }
  renderIntro() {
    const { isMasteredSection, match, requiredQuizScore, sectionTitle } =
      this.props
    const { isLoadingActivity } = this.quiz
    const { params } = match
    const { sectionId: paramsSectionId, unitId: paramsUnitId } = params

    // TODO: implement Quiz reqs
    const isQuizMastery = false
    const isQuizLockout = true
    return (
      <div className="CoursePageQuiz__intro">
        <Heading className="CoursePageQuiz__intro-heading" is1>
          Ready for a Quiz?
        </Heading>
        {isMasteredSection ? (
          <p className="CoursePageQuiz__intro-description">
            Well done on mastering&nbsp;
            <span className="CoursePageQuiz__intro-description-section-title">
              {sectionTitle}
            </span>
            !
            <br />
            You&apos;ve now unlocked a Quiz, which will further challenge your
            understanding of this topic.
            <br />
            To {isQuizMastery ? 'advance to the next learning Section' : 'pass'}
            , you&apos;ll need to achieve a score of {requiredQuizScore}% or
            above.
          </p>
        ) : (
          <p className="CoursePageQuiz__intro-description">
            Quiz, will further challenge your understanding of this topic.
            <br />
            To unlock Quiz, you must first complete the&nbsp;
            <Link
              to={`/units/${paramsUnitId}/sections/${paramsSectionId}/practice`}
            >
              Practice
            </Link>
            &nbsp;content in this Section.
          </p>
        )}
        {isMasteredSection && isQuizLockout ? (
          <span className="CoursePageQuiz__intro-cta">
            Keep in mind the previous study materials will not be accessible
            during Quiz mode. Best of luck!
          </span>
        ) : null}
        <Button
          className="CoursePageQuiz__intro-button"
          isDisabled={!isMasteredSection}
          isLoading={isLoadingActivity}
          isSuccess
          onClick={this.onClickStartQuiz}
        >
          Start Quiz
        </Button>
      </div>
    )
  }
  renderActivity() {
    if (!this.activity) return <Loader />

    const {
      checkIfScrollable,
      onDisableConfirm,
      onOpenActivityModal,
      pageRef,
      scrollY,
    } = this.props
    const { isActivityQuestionOpen } = this.state
    const {
      activityAnswer,
      activityAnswerHasChanged,
      hasNextActivity,
      isDone,
      isSavingActivity,
      numQuizActivitiesRemaining,
      numQuizActivitiesTotal,
    } = this.quiz

    const {
      activityEmbed,
      activityType,
      answerResponse,
      answerResponseLos,
      answersCounter,
      correctAnswers,
      currentHint: currentActivityHint,
      hasHint,
      hasNotPassed,
      hasPassed,
      id: activityId,
      imageAlignment,
      incorrectAnswers,
      isNewActivity,
      question,
      questionImage,
      questionImageDescription,
      questionImageTitle,
      questionVideo,
      replaceActivityMathFormulas,
      title: activityTitle,
      validationError: activityValidationError,
    } = this.activity || {}

    const activitiesCounters = {
      masteryActivities: numQuizActivitiesTotal,
      reattemptActivities: numQuizActivitiesTotal - numQuizActivitiesRemaining,
      sectionActivities: numQuizActivitiesTotal,
    }

    return (
      <Activity
        className="CoursePageQuiz__activity"
        id={activityId}
        activitiesCounters={activitiesCounters}
        activityEmbed={activityEmbed}
        answerHasChanged={activityAnswerHasChanged}
        answerResponse={answerResponse || null}
        answerResponseLos={answerResponseLos || null}
        answersCounter={answersCounter}
        checkIfScrollable={checkIfScrollable}
        correctAnswers={correctAnswers || null}
        courseId={this.courseId}
        currentActivityHint={currentActivityHint}
        currentActivityId={activityId}
        dangerLabel={activityValidationError}
        hasHint={hasHint}
        hasNextActivity={hasNextActivity || isDone}
        image={questionImage}
        imageAlignment={imageAlignment}
        imageAlt={questionImageDescription || activityTitle}
        imageTitle={questionImageTitle || activityTitle}
        incorrectAnswers={incorrectAnswers || null}
        isActivityFeedbackOpen={this.isActivityFeedbackOpen && hasPassed}
        isActivityQuestionOpen={isActivityQuestionOpen}
        isConfirmDisabled={!activityAnswerHasChanged && !isNewActivity}
        isDanger={hasNotPassed}
        isNewActivity={isNewActivity}
        isQuiz
        isSavingActivity={isSavingActivity}
        onChange={this.onChange}
        onDisableConfirm={onDisableConfirm}
        onOpenActivityModal={onOpenActivityModal}
        onSubmit={this.onSubmit}
        pageRef={pageRef}
        renderImageAfterQuestion={() =>
          this.renderImageAfterQuestion(
            questionImage,
            questionImageDescription || activityTitle,
            questionImageTitle || activityTitle,
          )
        }
        replaceActivityMathFormulas={replaceActivityMathFormulas}
        title={question}
        type={activityType}
        scrollY={scrollY}
        value={
          activityAnswer &&
          typeof activityAnswer === 'object' &&
          activityAnswer['$mobx'] &&
          activityAnswer['$mobx'].values
            ? activityAnswer['$mobx'].values
            : activityAnswer
        }
        video={questionVideo}
        // choices activities
        choice={{
          choices: this.activity.cleanedChoices,
        }}
        // input activities
        input={{
          isMultipleInput: this.activity.isMultipleInput,
          textBefore: this.activity.textBeforeInput,
          textAfter: this.activity.textAfterInput,
        }}
        // matching activity
        matching={{
          definitions: this.activity.pairs && this.activity.pairs.definitions,
          labels: this.activity.pairs && this.activity.pairs.labels,
        }}
        // sorting activity
        sortStack={{
          items: this.activity.pairs && this.activity.pairs.labels,
        }}
        // hotspot activity
        hotspot={this.activity.hotSpotData}
        // dragPhrase activity
        dragPhrase={{
          content: this.activity.cleanedContent,
          categories: this.activity.categories,
          items: this.activity.draggableItems,
        }}
      />
    )
  }
  renderActivityFeedback() {
    // const { isActivityQuestionOpen, onToggleActivityQuestion } = this.props
    const { streak } = this.quiz
    const { answerResponseLos, goodToKnow: currentActivityGoodToKnow } =
      this.activity || {}
    return (
      <ActivityCompleted
        goodToKnow={currentActivityGoodToKnow}
        learningObjectives={answerResponseLos}
        // onClickShowQuestion={onToggleActivityQuestion}
        // renderActivity={isActivityQuestionOpen ? this.renderActivity() : null}
        streak={streak}
        type={ACTIVITY_COMPLETED_CORRECT}
      />
    )
  }
  renderActivityTimeTracking() {
    const {
      isOpenIdleModal,
      isOpenIdleSessionModal,
      isOpenMemoryBoosterModal,
      section,
    } = this.props
    const { id: currentActivityId } = this.activity || {}
    const { hasNextActivity, isDone } = this.quiz || {}
    if (
      !section ||
      !this.activity ||
      hasNextActivity ||
      isDone ||
      isOpenMemoryBoosterModal
    )
      return null
    return (
      <TimeTracking
        entityId={currentActivityId}
        isIdle={isOpenIdleModal && !isOpenIdleSessionModal}
        isQuiz
        section={section}
        what={TYPE_QUIZ_ACTIVITY}
      />
    )
  }
  renderQuiz() {
    const { hasPassed: hasPassedActivity } = this.activity || {}
    if (hasPassedActivity) return this.renderActivityFeedback()
    return (
      <div className="CoursePageQuiz__main">
        {this.renderActivityTimeTracking()}
        {this.renderActivity()}
      </div>
    )
  }
  renderMain() {
    const { isDone, isIntro } = this.quiz
    if (isIntro) return this.renderIntro()
    if (isDone && !this.activity) return this.renderDone()
    return this.renderQuiz()
  }
  render() {
    const { closeIncompleteQuestionModal, isOpenIncompleteQuestionModal } =
      this.props
    const { activityAnswer } = this.quiz
    const answersCountTotals =
      this.activity &&
      this.activity.getAnswersCountTotals(
        activityAnswer,
        isOpenIncompleteQuestionModal,
      )
    const { numAnswersIncomplete, numAnswersTotal } = answersCountTotals || {}
    return (
      <Fragment>
        <div className="CoursePageQuiz">{this.renderMain()}</div>
        <IncompleteQuestionModal
          isOpen={isOpenIncompleteQuestionModal}
          numAnswersIncomplete={numAnswersIncomplete}
          numAnswersTotal={numAnswersTotal}
          onClose={closeIncompleteQuestionModal}
          onConfirm={this.onConfirmIncomplete}
        />
      </Fragment>
    )
  }
}
