// TODO: add prop-types

import React, { Component, Fragment } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'

import { Button, RichTextWrapper } from '@/components'
import { ACTIVITY_TYPES } from '@/constants'
import ActivityLabel from './ActivityLabel/ActivityLabel'
import ActivityHint from './ActivityHint/ActivityHint'
import './Activity.scss'

const Messages = defineMessages({
  attemptsLabel: {
    defaultMessage: 'Attempt(s): {attemptsCount}',
    description:
      'Label for the number of attempts used / available displayed with the instructions label for an activity.',
    id: 'Activity.attemptsLabel',
  },
  defaultDangerLabel: {
    defaultMessage: 'Your answer is incorrect.',
    description: 'Default label for when an activity is answered incorrectly.',
    id: 'Activity.defaultDangerLabel',
  },
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to zoom in on activity images.',
    id: 'Activity.enlargeImage',
  },
  hideHint: {
    defaultMessage: 'Hide Hint',
    description: 'Button to hide the activity hint(s) for an activity.',
    id: 'Activity.hideHint',
  },
  loadingActivityHintContent: {
    defaultMessage: 'Loading hint...',
    description:
      'Description displayed when the Activity Hint content is loading upon opening an Activity Hint.',
    id: 'Activity.loadingActivityHintContent',
  },
  loadingActivityTitleContent: {
    defaultMessage: 'Loading question...',
    description:
      'Description displayed when the Activity Question content is loading upon receiving an Activity.',
    id: 'Activity.loadingActivityTitleContent',
  },
  noAttemptsRemainingLabelLineOne: {
    defaultMessage: 'That is incorrect. You do not have any more attempts.',
    description:
      'Label when an activity is answered incorrectly and has no remaining attempts (Line 1)',
    id: 'Activity.noAttemptsRemainingLabelLineOne',
  },
  // This will display only if there are correct answers for Multiple Response
  noAttemptsRemainingLabelLineOneMultipleResponse: {
    defaultMessage:
      'That is only partially correct. You do not have any more attempts.',
    description:
      'Label when a Multiple Response activity is answered incorrectly and has no remaining attempts (Line 1)',
    id: 'Activity.noAttemptsRemainingLabelLineOneMultipleResponse',
  },
  noAttemptsRemainingLabelLineTwo: {
    defaultMessage: `Please move on to the next question when you're ready.`,
    description:
      'Label when an activity is answered incorrectly and has no remaining attempts (Line 2)',
    id: 'Activity.noAttemptsRemainingLabelLineTwo',
  },
  reattemptsCountLabel: {
    defaultMessage:
      'Question: {reattemptActivities} / {totalReattemptActivities}',
    description:
      'Label displayed with the instructions label for an activity to show the number of activities answered / required to complete a reattempt of a section.',
    id: 'Activity.reattemptsCountLabel',
  },
  showHint: {
    defaultMessage: 'Show Hint',
    description: 'Button to show the activity hint(s) for an activity.',
    id: 'Activity.showHint',
  },
})

const DEFAULT_DANGER_LABEL = Messages.defaultDangerLabel

const initialState = {
  isActivityHintOpen: false,
  isNewHint: false,
  prevActivitiesCounters: {},
}

function __compareHints(prevHints, nextHints) {
  let hasChanged = false
  nextHints.forEach((nextHint) => {
    // if nextHint does not exist in prevHints
    if (prevHints.indexOf(nextHint) === -1) {
      hasChanged = true
    }
  })
  return hasChanged
}

@injectIntl
export default class Activity extends Component {
  static defaultProps = {
    // dangerLabel: DEFAULT_DANGER_LABEL,
    hintTitle: 'Hint',
    forceOnlyBody: false,
  }
  state = { ...initialState }

  get ownProps() {
    return {
      ...this.props,
      renderTitle: this.renderTitle,
    }
  }

  componentDidMount() {
    const {
      checkIfScrollable,
      isActivityFeedbackOpen,
      replaceActivityMathFormulas,
    } = this.props
    if (isActivityFeedbackOpen) return
    const $images = this.$ref.querySelectorAll('img')
    for (let i = 0; i < $images.length; i++) {
      $images[i].addEventListener('load', checkIfScrollable)
    }
    replaceActivityMathFormulas()
  }

  componentDidUpdate(prevProps) {
    const {
      currentActivityHint,
      hasHint,
      activitiesCounters,
      type: activityType,
    } = this.props
    const { isNewHint } = this.state
    const {
      currentActivityHint: prevActivityHint,
      hasHint: prevHasHint,
      activitiesCounters: prevActivitiesCounters,
    } = prevProps
    if (!isNewHint && ((!prevHasHint && hasHint) || (prevHasHint && hasHint))) {
      if (
        prevActivityHint.length !== currentActivityHint.length ||
        prevActivityHint[0] !== currentActivityHint[0] ||
        (activityType === ACTIVITY_TYPES['multipleChoice'] &&
          __compareHints(prevActivityHint, currentActivityHint))
      ) {
        this.setState({ isActivityHintOpen: false, isNewHint: true })
      }
    }
    if (
      activitiesCounters &&
      prevActivitiesCounters &&
      JSON.stringify(activitiesCounters) !==
        JSON.stringify(prevActivitiesCounters)
    ) {
      this.setState({ prevActivitiesCounters })
    }
  }

  onToggleActivityHint = () => {
    const { isActivityHintOpen } = this.state
    return this.setState({
      isActivityHintOpen: !isActivityHintOpen,
      isNewHint: false,
    })
  }

  setRef = (el) => {
    this.$ref = el
  }

  renderTitle = (title = this.props.title) => {
    const { id: activityId, intl, onOpenActivityModal } = this.props
    const { formatMessage } = intl
    if (!title) return null

    return (
      <div className="Activity-title Activity__question-stem">
        <RichTextWrapper
          className="Activity__rich-text-wrapper"
          content={title}
          id={`Activity__${activityId}`}
          key={`Activity__${activityId}`}
          loadingText={formatMessage(Messages.loadingActivityTitleContent)}
          onOpenImageModal={onOpenActivityModal}
        />
      </div>
    )
  }

  renderLabel = () => {
    const {
      adminHideLabel,
      attempt,
      correctAnswers,
      courseActivityAttempts,
      dangerLabel,
      hasNextActivity,
      hasHint,
      id,
      intl,
      isDanger,
      isConfirmDisabled,
      label: propLabel,
      labelIcon,
    } = this.props

    const { formatMessage } = intl
    let label = propLabel
    if (isDanger || isConfirmDisabled) {
      if (hasNextActivity) {
        label = (
          <Fragment>
            <div>
              {formatMessage(
                correctAnswers && correctAnswers.length
                  ? Messages.noAttemptsRemainingLabelLineOneMultipleResponse
                  : Messages.noAttemptsRemainingLabelLineOne,
              )}
            </div>
            <div>{formatMessage(Messages.noAttemptsRemainingLabelLineTwo)}</div>
          </Fragment>
        )
      } else {
        label = dangerLabel || formatMessage(DEFAULT_DANGER_LABEL)
      }
    }

    if (adminHideLabel) return null

    return (
      <ActivityLabel
        attempt={attempt}
        courseActivityAttempts={courseActivityAttempts}
        hasHint={hasHint}
        hasNextActivity={hasNextActivity}
        icon={labelIcon}
        id={id}
        isDanger={isDanger || isConfirmDisabled}
        text={label}
      />
    )
  }

  renderLearningObjectives = () => {
    const { answerResponseLos } = this.props
    if (!answerResponseLos || !answerResponseLos.length) return
    return (
      <div className="Activity__learning-objectives">
        <span className="Activity__learning-objectives-label">
          Learning Objectives:
        </span>
        <ul className="Activity__learning-objectives-list">
          {answerResponseLos.map((lo, index) => (
            <li
              className="Activity__learning-objectives-list-item"
              key={`Activity__learning-objectives-list-item-${index}`}
            >
              * {lo}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderBody = () => {
    const { hasNextActivity, isDanger } = this.props
    return (
      <div
        className={cx('Activity-body', {
          'has-no-attempts': isDanger && hasNextActivity,
        })}
      >
        {this.props.render(this.ownProps)}
      </div>
    )
  }

  renderBodyAndImage = () => {
    const {
      image,
      imageAlignment,
      imageAlt,
      imageTitle,
      intl,
      onOpenActivityModal,
    } = this.props
    const { formatMessage } = intl
    const alignImageLeft = !imageAlignment || imageAlignment === 'left'
    return (
      <div className={cx({ 'columns is-gapless': alignImageLeft })}>
        {alignImageLeft || imageAlignment === 'top' ? (
          <Fragment>
            <div
              className={cx('Activity-colImage', {
                'column is-one-third': alignImageLeft,
              })}
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              <img
                alt={imageAlt}
                className="Activity-image"
                src={image}
                title={imageTitle}
              />
              <Button
                aria-label={formatMessage(Messages.enlargeImage)}
                className="Activity-zoomImage"
                onClick={(e) => onOpenActivityModal(e, image)}
              >
                <span>{formatMessage(Messages.enlargeImage)}</span>
                <i
                  aria-hidden="true"
                  className="fa fa-search-plus"
                  title={formatMessage(Messages.enlargeImage)}
                />
              </Button>
            </div>
            <div className={cx('Activity-colBody', { column: alignImageLeft })}>
              {this.renderBody()}
            </div>
          </Fragment>
        ) : (
          <div className="Activity-colBody">{this.renderBody()}</div>
        )}
      </div>
    )
  }

  renderEmbed = () => {
    const { activityEmbed } = this.props
    if (!activityEmbed) return
    return (
      <div className="Activity__embed-wrapper">
        <iframe className="Activity__embed" src={activityEmbed} />
      </div>
    )
  }

  renderContainer = () => {
    const { forceOnlyBody, image: activityImage } = this.props
    const renderBody = () => {
      if (forceOnlyBody) {
        return this.renderBody()
      }
      return activityImage ? this.renderBodyAndImage() : this.renderBody()
    }
    return (
      <div className="Activity-container">
        {this.renderLearningObjectives()}
        {this.renderEmbed()}
        {renderBody()}
      </div>
    )
  }
  renderActivityHint = () => {
    const {
      attempt,
      currentActivityHint,
      currentActivityId,
      id,
      intl,
      isActivityFeedbackOpen,
      onOpenActivityModal,
    } = this.props
    const { isActivityHintOpen, isNewHint } = this.state
    const { formatMessage } = intl
    return (
      <ActivityHint
        attempt={attempt}
        currentActivityHint={currentActivityHint}
        currentActivityId={currentActivityId}
        hideHintText={formatMessage(Messages.hideHint)}
        id={id}
        isActivityFeedbackOpen={isActivityFeedbackOpen}
        isActivityHintOpen={isActivityHintOpen}
        isNewHint={isNewHint}
        loadingText={formatMessage(Messages.loadingActivityHintContent)}
        onOpenImageModal={onOpenActivityModal}
        onToggleActivityHint={this.onToggleActivityHint}
        showHintText={formatMessage(Messages.showHint)}
      />
    )
  }
  renderAttemptIcons = (incorrectAttempts, remainingAttempts) => {
    let attemptIcons = []
    for (let i = 0; i < incorrectAttempts; i++) {
      attemptIcons.push(
        <i
          className="Activity-incorrect fa fa-close"
          aria-hidden="true"
          key={`i${i}`}
        />,
      )
    }
    for (let i = 0; i < remainingAttempts; i++) {
      attemptIcons.push(
        <i
          className={cx('Activity-remaining', 'fa fa-close', {
            'Activity-current': i === 0,
          })}
          aria-hidden="true"
          key={`r${i}`}
        />,
      )
    }
    return attemptIcons
  }
  renderAttempts = (
    attempt,
    courseActivityAttempts,
    isDanger,
    skipped,
    hasNextActivity,
    isMemoryBooster = false,
  ) => {
    const { intl, isQuiz } = this.props
    const { formatMessage } = intl
    if (!isDanger || courseActivityAttempts === 1 || isQuiz) return null
    const remainingAttempts =
      courseActivityAttempts === attempt || attempt === 0 || hasNextActivity
        ? 0
        : courseActivityAttempts - attempt
    const incorrectAttempts =
      attempt === 0 || hasNextActivity ? courseActivityAttempts : attempt
    const renderOnlyText = courseActivityAttempts >= 4
    return !skipped ? (
      <div
        className={cx('Activity-attempts', {
          'Activity-attemptsOnlyText': renderOnlyText,
          'Activity-memoryBoosterAttempts': isMemoryBooster,
        })}
      >
        <span>
          {formatMessage(Messages.attemptsLabel, {
            attemptsCount: renderOnlyText
              ? `${incorrectAttempts} / ${courseActivityAttempts}`
              : '',
          })}
        </span>
        {!renderOnlyText
          ? this.renderAttemptIcons(incorrectAttempts, remainingAttempts)
          : null}
      </div>
    ) : null
  }
  renderQuestionCounts = () => {
    const {
      activitiesCounters,
      hasNextActivity,
      intl,
      isActivityFeedbackOpen,
      isQuiz,
    } = this.props
    const { prevActivitiesCounters } = this.state
    const { formatMessage } = intl
    const { masteryActivities, reattemptActivities, sectionActivities } =
      activitiesCounters
    const {
      masteryActivities: prevMasteryActivities,
      reattemptActivities: prevReattemptActivities,
      sectionActivities: prevSectionActivities,
    } = prevActivitiesCounters
    const denominator =
      masteryActivities > sectionActivities
        ? sectionActivities
        : masteryActivities
    let prevDenominator
    if (prevMasteryActivities) {
      prevDenominator =
        prevMasteryActivities > prevSectionActivities
          ? prevSectionActivities
          : prevMasteryActivities
    }
    return !isActivityFeedbackOpen || isQuiz ? (
      <div className="Activity-reattemptCounts">
        <span>
          {formatMessage(Messages.reattemptsCountLabel, {
            totalReattemptActivities: hasNextActivity
              ? prevDenominator || 0
              : denominator || 0,
            reattemptActivities: hasNextActivity
              ? prevReattemptActivities + 1 || 0
              : reattemptActivities + 1 || 0,
          })}
        </span>
      </div>
    ) : null
  }
  render() {
    const {
      activitiesCounters,
      attempt,
      courseActivityAttempts,
      className,
      hasHint,
      hasNextActivity,
      currentActivityId,
      id,
      isConfirmDisabled,
      isDanger,
      isQuiz,
      isReview,
      isReviewOverride,
      isReviewSection,
      skipped,
    } = this.props
    const activityId = id ? `activity-${id}` : null
    return (
      <div
        id={activityId}
        className={cx(
          'Activity',
          {
            'is-danger':
              isDanger &&
              (isConfirmDisabled || hasNextActivity) &&
              id === currentActivityId,
          },
          className,
        )}
        qa-id="learner-activity-layout"
        ref={this.setRef}
      >
        {this.renderLabel()}
        {((!isReview && isReviewSection && !isReviewOverride) || isQuiz) &&
        activitiesCounters
          ? this.renderQuestionCounts()
          : null}
        <div
          className={cx('Activity-hintsAttemptWrapper', {
            'Activity-noHint': !hasHint,
          })}
        >
          {this.renderAttempts(
            attempt,
            courseActivityAttempts,
            isDanger,
            skipped,
            hasNextActivity,
          )}
          {this.renderActivityHint()}
        </div>
        {this.renderContainer()}
      </div>
    )
  }
}
