const DASHBOARD_NAME = 'learningMasteryRequirements'

const EXAM_CHOICES = [
  {
    value: true,
    text: 'Enable',
  },
  {
    value: false,
    text: 'Disable',
  },
]
const EXAM_NAME = 'exam'
const EXAM_PASS_DEFAULT = '70'
const EXAM_PASS_NAME = 'examPass'

const LTI_NAME = 'lti'

const SETTINGS_CATEGORY_GENERAL = 'general'
const SETTINGS_CATEGORY_INTEGRATIONS = 'integrations'
const SETTINGS_CATEGORY_EXAM = 'exam'
const SETTINGS_CATEGORY_PRETEST = 'pretest'
const SETTINGS_CATEGORY_SNAPSHOT = 'snapshot'

const SETTINGS_CATEGORIES = [
  SETTINGS_CATEGORY_GENERAL,
  SETTINGS_CATEGORY_INTEGRATIONS,
  SETTINGS_CATEGORY_EXAM,
  SETTINGS_CATEGORY_PRETEST,
  SETTINGS_CATEGORY_SNAPSHOT,
]

// Pre-Test
const SNAPSHOT_CHOICES = [
  {
    value: true,
    text: 'Enable',
  },
  {
    value: false,
    text: 'Disable',
  },
]
const SNAPSHOT_NAME = 'snapshot'
const SNAPSHOT_TEST_OUT_CHOICES = [
  {
    value: true,
    text: 'Enable Testing Out',
  },
  {
    value: false,
    text: 'Disable Testing Out',
  },
]
const SNAPSHOT_TEST_OUT_NAME = 'snapshotTestOut'

// Snapshot
const SNAPSHOT_STANDALONE_NAME = 'snapshotStandalone'
const SNAPSHOT_STANDALONE_CHOICES = [
  {
    value: true,
    text: 'Enable Snapshot Standalone',
  },
  {
    value: false,
    text: 'Disable Snapshot Standalone',
  },
]

export {
  DASHBOARD_NAME,
  EXAM_CHOICES,
  EXAM_NAME,
  EXAM_PASS_DEFAULT,
  EXAM_PASS_NAME,
  LTI_NAME,
  SETTINGS_CATEGORY_GENERAL,
  SETTINGS_CATEGORY_INTEGRATIONS,
  SETTINGS_CATEGORY_EXAM,
  SETTINGS_CATEGORY_PRETEST,
  SETTINGS_CATEGORY_SNAPSHOT,
  SETTINGS_CATEGORIES,
  SNAPSHOT_CHOICES,
  SNAPSHOT_NAME,
  SNAPSHOT_TEST_OUT_CHOICES,
  SNAPSHOT_TEST_OUT_NAME,
  SNAPSHOT_STANDALONE_CHOICES,
  SNAPSHOT_STANDALONE_NAME,
}
