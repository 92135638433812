import { observable, computed, action } from 'mobx'
import { toCamelCase } from '@/utils/helpers'
import _ from 'lodash'
import { ENCOURAGE_MASTERY, RANK_UNENGAGED, REQUIRE_MASTERY } from '@/constants'

import Unit from './unit'

import defaultLogo from '@/assets/fulcrum-logomark.png'

export default class Course {
  // from practice
  @observable nextUnitId
  @observable nextSectionId

  // from contentStore.convertFullCourseResponse
  @observable units

  // from session
  @observable user

  // from fields
  @observable id
  @observable customAccessCodeMessage
  @observable displayTitle
  @observable faq
  @observable glossary
  @observable logo
  @observable title

  // from settings
  @observable learnerDashboard
  @observable pretestMastered
  @observable snapshotTestOut

  @observable shouldShowCompletionScreen = false

  constructor(data) {
    const { units, ...restData } = data
    this.init(restData)
    this.units = units.map(
      (unit, index) =>
        new Unit({
          ...unit,
          course: this,
          courseId: this.id,
          index,
        }),
    )
    this.logo = this.logo || defaultLogo
  }

  findSectionByActivityId(id) {
    const units = this.units
    let target = null
    for (let u = 0; u < units.length; u++) {
      let sections = units[u].sections
      for (let s = 0; s < sections.length; s++) {
        let section = sections[s]
        let activityIds = section.activityIds
        for (let a = 0; a < activityIds.length; a++) {
          let activityId = activityIds[a]
          if (activityId === id) {
            target = section
            break
          }
        }
      }
    }
    return target
  }

  findSectionById(contentfulId) {
    let target
    for (let n = 0; n < this.units.length; n++) {
      let unit = this.units[n]
      for (let x = 0; x < unit.sections.length; x++) {
        let section = unit.sections[x]
        if (contentfulId === section.id) {
          target = section
          break
        }
      }
    }
    return target
  }
  findUnitById(id) {
    return _.find(this.units, (unit) => unit.id === id)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get nextUnit() {
    return this.findUnitById(this.nextUnitId)
  }

  @computed get nextSection() {
    return this.nextUnit.findSectionById(this.nextSectionId)
  }

  @computed get numSections() {
    let count = 0
    if (this.units) {
      this.units.forEach((unit) => {
        count = count + unit.sections.length
      })
    }
    return count
  }

  @computed get score() {
    return this.units.reduce((score, section) => score + section.score, 0)
  }

  @computed get isCompleted() {
    return _.every(this.units, (unit) => unit.isCompleted)
  }

  @computed get isMastered() {
    return _.every(this.units, (unit) => unit.isMastered)
  }

  @computed get isMasteredWithPretest() {
    return (
      (this.snapshotTestOut && !!this.pretestMastered) ||
      _.every(
        this.units,
        (unit) => unit.isMastered || unit.isMasteredWithPretest,
      )
    )
  }

  @computed get isMasteredWithPretestAndReattempts() {
    return (
      (this.snapshotTestOut && !!this.pretestMastered) ||
      _.every(this.units, (unit) => unit.isMasteredWithPretestAndReattempts)
    )
  }

  @computed get isEncourageMastery() {
    return this.learnerDashboard === ENCOURAGE_MASTERY
  }

  @computed get isRequireMastery() {
    return this.learnerDashboard === REQUIRE_MASTERY
  }

  @computed get sections() {
    let totalSections = []
    this.units.forEach((unit) => {
      const { sections } = unit
      totalSections = [...totalSections, ...sections]
    })
    return totalSections
  }

  @computed get sectionsAtRisk() {
    const { sections } = this
    return sections.filter((section) => {
      const {
        mastered,
        pretestMastered,
        rank: sectionRank,
        reattemptRank,
      } = section
      return (
        mastered &&
        !pretestMastered &&
        (!sectionRank ||
          (sectionRank === RANK_UNENGAGED && !reattemptRank) ||
          reattemptRank === RANK_UNENGAGED)
      )
    })
  }

  @computed get totalScore() {
    return this.units.reduce((score, section) => score + section.totalScore, 0)
  }

  @computed get progress() {
    return Math.round((this.score / this.totalScore) * 100)
  }

  @action hideCompletionScreen() {
    this.shouldShowCompletionScreen = false
  }

  @action showCompletionScreen() {
    this.shouldShowCompletionScreen = true
  }
}
