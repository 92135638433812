import React, { Component } from 'react'
import { inject } from 'mobx-react'
import { Heading, Modal } from '@/components'
import './ReattemptIntroModal.scss'
@inject('uiStore')
export default class ReattemptIntroModal extends Component {
  onConfirm = () => {
    const { onConfirm, uiStore } = this.props
    if (onConfirm) {
      onConfirm()
    }
    uiStore.scrollToTop()
  }
  render() {
    const { isOpen, readTabURL, watchTabURL } = this.props
    return (
      <Modal
        className="ReattemptIntroModal"
        contentLabel="Reattempt Introduction Modal"
        isOpen={isOpen}
      >
        <Modal.Header>
          <Heading>A section you recently completed can be improved.</Heading>
        </Modal.Header>
        <Modal.Body>
          <p className="ReattemptIntroModal-description">
            This means you rushed through or you didn’t quite grasp some higher
            level concepts.
          </p>
          <p className="ReattemptIntroModal-description">
            This is an opportunity to answer more questions correctly and have
            an improved score for the skills covered in the section.
          </p>
          <p className="ReattemptIntroModal-description">
            Whenever you’re ready, click <span className="is-bold">Review</span>{' '}
            under <span className="is-bold">Pathway to Mastery</span>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          {readTabURL ? (
            <Modal.Button
              isLink
              key="ReattemptIntroModal-readButton"
              onClick={this.onConfirm}
              to={readTabURL}
            >
              Read
            </Modal.Button>
          ) : null}
          {watchTabURL ? (
            <Modal.Button
              isLink
              key="ReattemptIntroModal-watchButton"
              onClick={this.onConfirm}
              to={watchTabURL}
            >
              Watch
            </Modal.Button>
          ) : null}
          <Modal.Button
            key="ReattemptIntroModal-confirmButton"
            onClick={this.onConfirm}
          >
            OK
          </Modal.Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
