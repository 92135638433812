import React, { Component } from 'react'
import cx from 'classnames'
import { Button, CheckboxCustom, HoverTooltip, Toggler } from '@/components'
import { __sortByOrder } from './InstanceContentPage'

export default class InstanceContentPageSidebar extends Component {
  state = { isOpenFiltersMobile: false }
  onToggleFilters = (value) => {
    this.setState({ isOpenFiltersMobile: value })
  }
  renderFilters() {
    const { filters, filterOptions, onChangeFilterValue } = this.props
    const filterOptionKeys = Object.keys(filterOptions)
    if (!filterOptionKeys.length)
      return (
        <span className="InstanceAnalyticsPage__filter-empty">
          No filters available
        </span>
      )
    return filterOptionKeys
      .sort((a, b) => __sortByOrder(filterOptions[a], filterOptions[b]))
      .map((filterKey) => {
        const { label: filterLabel, values: filterValues } =
          filterOptions[filterKey]
        const isRanksFilter = filterKey === 'ranks'
        const isUnitsFilter = filterKey === 'units'
        const numFilterValues = filterValues.length
        return (
          <div
            className="InstanceContentPage__filter"
            key={`InstanceContentPage-filter-${filterKey}`}
          >
            <h2 className="InstanceContentPage__filter-label">
              <span>{filterLabel}</span>
              {isRanksFilter ? (
                <HoverTooltip
                  id="InstanceOverviewPage__learner-card--is-content-performance"
                  getTooltipContainer={() =>
                    document.querySelector('.InstanceContentPage__filter-label')
                  }
                  text="Question Status is based on an analysis of student engagement. Effective questions correlate with better performance."
                  trigger={['focus', 'hover']}
                >
                  <Button
                    aria-describedby="InstanceOverviewPage__learner-card--is-content-performance"
                    isText
                  >
                    <i className="fa fa-info-circle" />
                  </Button>
                </HoverTooltip>
              ) : null}
            </h2>
            <div
              className={cx('InstanceContentPage__filter-values', {
                'InstanceContentPage__filter-values--is-units': isUnitsFilter,
              })}
            >
              {numFilterValues ? (
                filterValues.map((filterValue) => {
                  const {
                    count: filterValueCount,
                    key: filterValueKey,
                    label: filterValueLabel,
                    value,
                  } = filterValue
                  return (
                    <div
                      className={cx('InstanceContentPage__filter-value', {
                        'InstanceContentPage__filter-value--is-all-star':
                          filterValueKey === 'all-star',
                        'InstanceContentPage__filter-value--is-at-risk':
                          filterValueKey === 'at-risk',
                        'InstanceContentPage__filter-value--is-good':
                          filterValueKey === 'good',
                      })}
                      key={`InstanceContentPage__filter-value--is-${filterValueKey}`}
                    >
                      <CheckboxCustom
                        checked={
                          !!(
                            filters[filterKey] &&
                            filters[filterKey].indexOf(value) !== -1
                          )
                        }
                        className="InstanceAnalyticsPage__filter-option-checkbox"
                        name={filterValueKey}
                        onChange={() => onChangeFilterValue(filterKey, value)}
                        secondaryText={`(${filterValueCount})`}
                        isBlock
                        isRadio={isUnitsFilter}
                      >
                        <h3>{filterValueLabel}</h3>
                      </CheckboxCustom>
                    </div>
                  )
                })
              ) : (
                <span>No filters available</span>
              )}
            </div>
          </div>
        )
      })
  }
  render() {
    const { isOpenFiltersMobile } = this.state
    return (
      <Toggler
        icon="tasks"
        className="InstanceContentPage__toggler"
        text="Close filter criteria"
        altText="Show filter criteria"
        onChange={this.onToggleFilters}
        isActive={isOpenFiltersMobile}
      >
        <div className="InstanceContentPage__filters">
          {this.renderFilters()}
        </div>
      </Toggler>
    )
  }
}
