import { extendObservable, action } from 'mobx'
import {
  CONTRAST_MODE,
  DEVICE_WIDTH_PX_DESKTOP,
  DEVICE_WIDTH_PX_TABLET,
  MEDIA_QUERY_DESKTOP,
  MEDIA_QUERY_MOBILE,
  MEDIA_QUERY_TABLET,
  THEMES,
  THEME_COLORS,
} from '@/constants'
import { getCookie, setCookie } from '@/utils/helpers'
export const SHOULD_FIRST_LOGIN_MESSAGE_OPEN = 'shouldFirstLoginMessageOpen'
export const SHOULD_FIRST_SECTION_MESSAGE_OPEN = 'shouldFirstSectionMessageOpen'
export const SHOULD_INCOMPLETE_QUESTION_MODAL_OPEN =
  'shouldIncompleteQuestionModalOpen'
export const SHOULD_NEGATIVE_STREAK_MODAL_OPEN = 'negative_streak'
export const SHOULD_REATTEMPT_INTRO_MODAL_OPEN = 'shouldReattemptIntroModalOpen'
export const SHOULD_RUSH_INTERVENTION_MODAL_OPEN = 'rush_intervention'

const initialStateMemoryBoosterModal = {
  isOpen: false,
  sectionId: null,
  unitId: null,
}

const initialState = {
  hasIOS11ModalBug: false,
  isIOS11: false,
  isLoadingUpdates: false,
  isOpenFirstLoginMessage: false,
  isOpenFirstSectionMessage: false,
  isOpenIdleModal: false,
  isOpenIdleSessionModal: false,
  isOpenIncompleteQuestionModal: false,
  isOpenLocaleSelectorModal: false,
  isOpenMemoryBoosterPanel: false,
  isOpenMemoryBoosterPanelModal: false,
  isOpenModal: false,
  isOpenNegativeStreakModal: false,
  isOpenReattemptIntroModal: false,
  isOpenRushInterventionModal: false,
  mediaQuery: null,
  memoryBoosterModal: { ...initialStateMemoryBoosterModal },
  message: null,
  messageType: null,
  session: null,
  timeoutId: null,
  updates: null,
  showMobileNavMenu: false,
}

export default class UIStore {
  scrollTop = 0

  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
    // Position fixed with form elements bug for < iOS 11.3
    // https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
    // https://stackoverflow.com/questions/46339063/ios-11-safari-bootstrap-modal-text-area-outside-of-cursor
    let ua = navigator && navigator.userAgent
    if (ua) {
      let iOS = /iPad|iPhone|iPod/.test(ua)
      if (iOS) {
        let iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua)
        this.addClass(document.documentElement, 'iOS')
        this.addClass(document.body, 'iOS')
        this.isIOS11 = true
        if (iOS11) {
          this.addClass(document.documentElement, 'iOS11ModalBug')
          this.addClass(document.body, 'iOS11ModalBug')
          this.hasIOS11ModalBug = true
        }
      }
    }
    window.addEventListener('resize', this.getMediaQuery)
    this.getMediaQuery()
  }

  @action init(session) {
    this.session = session
    // NOTE / TODO / WIP - disabled due to https://headware4.jira.com/browse/FM-2942
    // this.openFirstLoginMessage()
    // this.openFirstSectionMessage()
  }

  @action reset() {
    extendObservable(this, { ...initialState })
  }

  @action updateTitle(title) {
    if (title) {
      document.title = `Fulcrum Labs - ${title}`
    } else {
      document.title = 'Fulcrum Labs'
    }
  }

  @action toggleMobileNavMenu() {
    this.showMobileNavMenu = !this.showMobileNavMenu
    if (this.showMobileNavMenu) {
      this.setScrollTop()
      this.addClass(document.documentElement, 'MobileNavMenu-NoScroll')
      this.addClass(document.body, 'MobileNavMenu-NoScroll')
    } else {
      this.removeClass(document.documentElement, 'MobileNavMenu-NoScroll')
      this.removeClass(document.body, 'MobileNavMenu-NoScroll')
      this.scrollY(null, this.scrollTop)
    }
  }

  @action resetScrollTop() {
    this.scrollTop = 0
  }
  @action setScrollTop(scrollTop) {
    if (scrollTop) {
      this.scrollTop = scrollTop
    } else {
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop || 0
    }
  }
  @action scrollY = (el, scrollTop, scrollToTop, behavior) => {
    if (!el) {
      setTimeout(() => {
        document.documentElement.scrollTo(0, scrollTop || 0)
        document.body.scrollTo(0, scrollTop || 0)
        this.setScrollTop()
      }, 0)
    } else {
      el.scrollTo({
        left: 0,
        top: scrollToTop ? 0 : scrollTop || 0,
        behavior: behavior || 'auto',
      })
    }
  }
  @action scrollToTop(el) {
    this.scrollY(el, 0, true)
  }

  @action getMediaQuery = () => {
    if (window.innerWidth < DEVICE_WIDTH_PX_TABLET) {
      this.mediaQuery = MEDIA_QUERY_MOBILE
    } else if (
      window.innerWidth < DEVICE_WIDTH_PX_DESKTOP &&
      window.innerWidth >= DEVICE_WIDTH_PX_TABLET
    ) {
      this.mediaQuery = MEDIA_QUERY_TABLET
    } else if (this.mediaQuery !== MEDIA_QUERY_DESKTOP) {
      this.mediaQuery = MEDIA_QUERY_DESKTOP
    }
  }
  get isMobile() {
    return this.mediaQuery === MEDIA_QUERY_MOBILE
  }
  get isTablet() {
    return this.mediaQuery === MEDIA_QUERY_TABLET
  }
  get isDesktop() {
    return this.mediaQuery === MEDIA_QUERY_DESKTOP
  }

  get currentDomain() {
    if (window.location.hostname.match(/fulcrumlabs.ai/)) {
      return 'fulcrumlabs.ai'
    } else if (window.location.hostname.match(/the-fulcrum.com/)) {
      return 'the-fulcrum.com'
    } else {
      return 'fulcrumwebapp.com:3000'
    }
  }

  addClass(el, className) {
    let classNames = []
    let elClassNames = el.className ? el.className.split(' ') : []
    elClassNames.forEach((elClassName) => {
      if (elClassName !== className) {
        classNames.push(elClassName)
      }
    })
    classNames.push(className)
    el.className = classNames.join(' ').trim()
  }
  removeClass(el, className) {
    let classNames = []
    let elClassNames = el.className ? el.className.split(' ') : []
    elClassNames.forEach((elClassName) => {
      if (elClassName !== className) {
        classNames.push(elClassName)
      }
    })
    el.className = classNames.length ? classNames.join(' ').trim() : ''
  }

  getTheme() {
    if (this.session) {
      const { orgId, user } = this.session
      const isStudent = user && user.isStudent
      return isStudent ? THEMES[orgId] : null
    }
    return null
  }

  getThemeColor(color) {
    const theme = this.getTheme() || 'default'
    const colors = THEME_COLORS[theme]
    return colors[color]
  }

  showMessage({
    message,
    autoClose = true,
    timeout = 10,
    type = 'danger',
  } = {}) {
    if (!message) return

    this.message = message
    this.messageType = type

    if (autoClose) {
      this.timeoutId = setTimeout(() => this.hideMessage(), timeout * 1000)
    }
  }

  hideMessage() {
    this.message = null
    this.messageType = null

    if (this.timeoutId) {
      this.timeoutId = clearTimeout(this.timeoutId)
    }
  }

  @action openFirstLoginMessage = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldFirstLoginMessageOpenValues = localStorage.getItem(
        SHOULD_FIRST_LOGIN_MESSAGE_OPEN,
      )
      let shouldFirstLoginMessageOpenJSON
      // let shouldFirstLoginMessageOpenValue
      if (shouldFirstLoginMessageOpenValues) {
        shouldFirstLoginMessageOpenJSON = JSON.parse(
          shouldFirstLoginMessageOpenValues,
        )
        if (
          shouldFirstLoginMessageOpenJSON &&
          typeof shouldFirstLoginMessageOpenJSON === 'object' &&
          shouldFirstLoginMessageOpenJSON[id]
        ) {
          // if value exists and current timestamp is greater than value
          // reset value and open first login message banner
          // shouldFirstLoginMessageOpenValue = shouldFirstLoginMessageOpenJSON[id]
          // if (shouldFirstLoginMessageOpenValue && !isNaN(shouldFirstLoginMessageOpenValue) && Date.now() > shouldFirstLoginMessageOpenValue) {
          //   this.resetFirstLoginMessage()
          //   this.isOpenFirstLoginMessage = true
          //   return true
          // } else {
          // expiration has not been met, do not open first login message banner
          this.isOpenFirstLoginMessage = false
          return false
          // }
        }
      }
      this.isOpenFirstLoginMessage = true
      return true
    } catch (error) {
      this.resetFirstLoginMessage()
      this.isOpenFirstLoginMessage = true
      return false
    }
  }
  @action closeFirstLoginMessage = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldFirstLoginMessageOpenValues = localStorage.getItem(
        SHOULD_FIRST_LOGIN_MESSAGE_OPEN,
      )
      let shouldFirstLoginMessageOpenJSON
      if (shouldFirstLoginMessageOpenValues) {
        shouldFirstLoginMessageOpenJSON = JSON.parse(
          shouldFirstLoginMessageOpenValues,
        )
      }
      if (
        !shouldFirstLoginMessageOpenJSON ||
        typeof shouldFirstLoginMessageOpenJSON !== 'object'
      ) {
        shouldFirstLoginMessageOpenJSON = {}
      }
      // set expiration for 7 days after current timestamp
      shouldFirstLoginMessageOpenJSON[id] =
        Date.now() + 1000 * 60 * 60 * 24 * 7 /* 1000ms * 60s * 60m * 24h * 7d */
      localStorage.setItem(
        SHOULD_FIRST_LOGIN_MESSAGE_OPEN,
        JSON.stringify(shouldFirstLoginMessageOpenJSON),
      )
      this.isOpenFirstLoginMessage = false
      return true
    } catch (error) {
      this.resetFirstLoginMessage()
      this.isOpenFirstLoginMessage = false
      return false
    }
  }
  @action resetFirstLoginMessage = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldFirstLoginMessageOpenValues = localStorage.getItem(
        SHOULD_FIRST_LOGIN_MESSAGE_OPEN,
      )
      let shouldFirstLoginMessageOpenJSON
      if (shouldFirstLoginMessageOpenValues) {
        shouldFirstLoginMessageOpenJSON = JSON.parse(
          shouldFirstLoginMessageOpenValues,
        )
        if (
          shouldFirstLoginMessageOpenJSON &&
          typeof shouldFirstLoginMessageOpenJSON === 'object'
        ) {
          if (shouldFirstLoginMessageOpenJSON[id]) {
            delete shouldFirstLoginMessageOpenJSON[id]
            localStorage.setItem(
              SHOULD_FIRST_LOGIN_MESSAGE_OPEN,
              JSON.stringify(shouldFirstLoginMessageOpenJSON),
            )
          }
        } else {
          localStorage.removeItem(SHOULD_FIRST_LOGIN_MESSAGE_OPEN)
        }
      }
      return true
    } catch (error) {
      localStorage.removeItem(SHOULD_FIRST_LOGIN_MESSAGE_OPEN)
      return false
    }
  }

  @action openFirstSectionMessage = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const shouldFirstSectionMessageOpenValue = getCookie(
      `${SHOULD_FIRST_SECTION_MESSAGE_OPEN}-${internalCourseId}-${userId}`,
    )
    if (!shouldFirstSectionMessageOpenValue) {
      this.isOpenFirstSectionMessage = true
    }
  }
  @action closeFirstSectionMessage = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    setCookie(
      `${SHOULD_FIRST_SECTION_MESSAGE_OPEN}-${internalCourseId}-${userId}`,
      1,
      1000 * 60 * 60 * 24 * 90 /* 1000ms * 60s * 60m * 24h * 90d */,
    )
    this.isOpenFirstSectionMessage = false
  }
  @action resetFirstSectionMessage = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldFirstSectionMessageOpenValues = localStorage.getItem(
        SHOULD_FIRST_SECTION_MESSAGE_OPEN,
      )
      let shouldFirstSectionMessageOpenJSON
      if (shouldFirstSectionMessageOpenValues) {
        shouldFirstSectionMessageOpenJSON = JSON.parse(
          shouldFirstSectionMessageOpenValues,
        )
        if (
          shouldFirstSectionMessageOpenJSON &&
          typeof shouldFirstSectionMessageOpenJSON === 'object'
        ) {
          if (shouldFirstSectionMessageOpenJSON[id]) {
            delete shouldFirstSectionMessageOpenJSON[id]
            localStorage.setItem(
              SHOULD_FIRST_SECTION_MESSAGE_OPEN,
              JSON.stringify(shouldFirstSectionMessageOpenJSON),
            )
          }
        } else {
          localStorage.removeItem(SHOULD_FIRST_SECTION_MESSAGE_OPEN)
        }
      }
      return true
    } catch (error) {
      localStorage.removeItem(SHOULD_FIRST_SECTION_MESSAGE_OPEN)
      return false
    }
  }

  @action openIdleModal = () => {
    this.isOpenIdleModal = true
  }
  @action closeIdleModal = () => {
    this.isOpenIdleModal = false
  }

  @action openIdleSessionModal = () => {
    this.isOpenIdleSessionModal = true
  }
  @action closeIdleSessionModal = () => {
    this.isOpenIdleModal = false
    this.isOpenIdleSessionModal = false
  }

  @action openIncompleteQuestionModal = () => {
    const { user } = this.session
    const { id: userId } = user
    const shouldIncompleteQuestionModalOpenValue = getCookie(
      `${SHOULD_INCOMPLETE_QUESTION_MODAL_OPEN}-${userId}`,
    )
    if (!shouldIncompleteQuestionModalOpenValue) {
      this.isOpenIncompleteQuestionModal = true
      return true
    }
  }
  @action closeIncompleteQuestionModal = (dontShowAgain = false) => {
    const { user } = this.session
    const { id: userId } = user
    if (dontShowAgain) {
      setCookie(
        `${SHOULD_INCOMPLETE_QUESTION_MODAL_OPEN}-${userId}`,
        1,
        1000 * 60 * 60 * 24 * 90 /* 1000ms * 60s * 60m * 24h * 90d */,
      )
    }
    this.isOpenIncompleteQuestionModal = false
  }

  @action openLocaleSelectorModal = () => {
    this.isOpenLocaleSelectorModal = true
  }
  @action closeLocaleSelectorModal = () => {
    this.isOpenLocaleSelectorModal = false
  }

  @action openMemoryBoosterModal = ({ sectionId, unitId }) => {
    this.memoryBoosterModal = {
      isOpen: true,
      sectionId,
      unitId,
    }
  }
  @action closeMemoryBoosterModal = () => {
    this.memoryBoosterModal = { ...initialStateMemoryBoosterModal }
  }

  @action openMemoryBoosterPanelModal = () => {
    this.isOpenMemoryBoosterPanelModal = true
  }
  @action closeMemoryBoosterPanelModal = () => {
    this.isOpenMemoryBoosterPanelModal = false
  }

  @action openNegativeStreakModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const shouldNegativeStreakModalOpenValue = getCookie(
      `${SHOULD_NEGATIVE_STREAK_MODAL_OPEN}-${internalCourseId}-${userId}`,
    )
    if (!shouldNegativeStreakModalOpenValue) {
      this.isOpenNegativeStreakModal = true
    }
  }
  @action closeNegativeStreakModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    setCookie(
      `${SHOULD_NEGATIVE_STREAK_MODAL_OPEN}-${internalCourseId}-${userId}`,
      1,
      1000 * 60 * 60 * 24 * 7 /* 1000ms * 60s * 60m * 24h * 7d */,
    )
    this.isOpenNegativeStreakModal = false
  }

  @action openReattemptIntroModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldReattemptIntroModalOpenValues = localStorage.getItem(
        SHOULD_REATTEMPT_INTRO_MODAL_OPEN,
      )
      let shouldReattemptIntroModalOpenJSON
      let shouldReattemptIntroModalOpenValue
      if (shouldReattemptIntroModalOpenValues) {
        shouldReattemptIntroModalOpenJSON = JSON.parse(
          shouldReattemptIntroModalOpenValues,
        )
        if (
          shouldReattemptIntroModalOpenJSON &&
          typeof shouldReattemptIntroModalOpenJSON === 'object' &&
          shouldReattemptIntroModalOpenJSON[id]
        ) {
          shouldReattemptIntroModalOpenValue =
            shouldReattemptIntroModalOpenJSON[id]
          // if value exists and current timestamp is greater than value
          // reset value and open reattempt intro modal
          if (
            shouldReattemptIntroModalOpenValue &&
            !isNaN(shouldReattemptIntroModalOpenValue) &&
            Date.now() > shouldReattemptIntroModalOpenValue
          ) {
            this.resetReattemptIntroModal()
            this.isOpenReattemptIntroModal = true
            return true
          } else {
            // expiration has not been met, do not open reattempt intro modal
            this.isOpenReattemptIntroModal = false
            return false
          }
        }
      }
      this.isOpenReattemptIntroModal = true
      return true
    } catch (error) {
      this.resetReattemptIntroModal()
      this.isOpenReattemptIntroModal = true
      return false
    }
  }
  @action closeReattemptIntroModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldReattemptIntroModalOpenValues = localStorage.getItem(
        SHOULD_REATTEMPT_INTRO_MODAL_OPEN,
      )
      let shouldReattemptIntroModalOpenJSON
      if (shouldReattemptIntroModalOpenValues) {
        shouldReattemptIntroModalOpenJSON = JSON.parse(
          shouldReattemptIntroModalOpenValues,
        )
      }
      if (
        !shouldReattemptIntroModalOpenJSON ||
        typeof shouldReattemptIntroModalOpenJSON !== 'object'
      ) {
        shouldReattemptIntroModalOpenJSON = {}
      }
      // set expiration for 7 days after current timestamp
      shouldReattemptIntroModalOpenJSON[id] =
        Date.now() + 1000 * 60 * 60 * 24 * 7 /* 1000ms * 60s * 60m * 24h * 7d */
      localStorage.setItem(
        SHOULD_REATTEMPT_INTRO_MODAL_OPEN,
        JSON.stringify(shouldReattemptIntroModalOpenJSON),
      )
      this.isOpenReattemptIntroModal = false
      return true
    } catch (error) {
      this.resetReattemptIntroModal()
      this.isOpenReattemptIntroModal = false
      return false
    }
  }
  @action resetReattemptIntroModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const id = `${internalCourseId}-${userId}`
    try {
      let shouldReattemptIntroModalOpenValues = localStorage.getItem(
        SHOULD_REATTEMPT_INTRO_MODAL_OPEN,
      )
      let shouldReattemptIntroModalOpenJSON
      if (shouldReattemptIntroModalOpenValues) {
        shouldReattemptIntroModalOpenJSON = JSON.parse(
          shouldReattemptIntroModalOpenValues,
        )
        if (
          shouldReattemptIntroModalOpenJSON &&
          typeof shouldReattemptIntroModalOpenJSON === 'object'
        ) {
          if (shouldReattemptIntroModalOpenJSON[id]) {
            delete shouldReattemptIntroModalOpenJSON[id]
            localStorage.setItem(
              SHOULD_REATTEMPT_INTRO_MODAL_OPEN,
              JSON.stringify(shouldReattemptIntroModalOpenJSON),
            )
          }
        } else {
          localStorage.removeItem(SHOULD_REATTEMPT_INTRO_MODAL_OPEN)
        }
      }
      return true
    } catch (error) {
      localStorage.removeItem(SHOULD_REATTEMPT_INTRO_MODAL_OPEN)
      return false
    }
  }

  @action openRushInterventionModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    const shouldRushInterventionModalOpenValue = getCookie(
      `${SHOULD_RUSH_INTERVENTION_MODAL_OPEN}-${internalCourseId}-${userId}`,
    )
    if (!shouldRushInterventionModalOpenValue) {
      this.isOpenRushInterventionModal = true
    }
  }
  @action closeRushInterventionModal = () => {
    const { internalCourseId, user } = this.session
    const { id: userId } = user
    setCookie(
      `${SHOULD_RUSH_INTERVENTION_MODAL_OPEN}-${internalCourseId}-${userId}`,
      1,
      1000 * 60 * 60 * 24 * 7 /* 1000ms * 60s * 60m * 24h * 7d */,
    )
    this.isOpenRushInterventionModal = false
  }

  @action toggleMemoryBoosterPanel = () => {
    this.isOpenMemoryBoosterPanel = !this.isOpenMemoryBoosterPanel
  }

  @action getContrastMode = () => {
    const { user } = this.session
    const { id: userId } = user
    const isContrastMode = getCookie(`${CONTRAST_MODE}-${userId}`)
    if (isContrastMode) {
      this.setContrastMode(true)
    }
    return !!isContrastMode
  }
  @action setContrastMode = (contrastMode) => {
    const { user } = this.session
    const { id: userId } = user
    if (contrastMode) {
      document.body.setAttribute(CONTRAST_MODE, 'TRUE')
      setCookie(
        `${CONTRAST_MODE}-${userId}`,
        1,
        1000 * 60 * 60 * 24 * 90 /* 1000ms * 60s * 60m * 24h * 90d */,
      )
    } else {
      document.body.removeAttribute(CONTRAST_MODE)
      setCookie(`${CONTRAST_MODE}-${userId}`, 1, 0)
    }
  }
}
