import React, { Component } from 'react'
import cx from 'classnames'
import './AnimatedActivityIcon.scss'

function AnimatedActivityIconBackgroundShape(props) {
  const { isBolt, isStar, isTriangle, rotation } = props
  const className = cx('shape-icon', {
    fa: isBolt || isStar || isTriangle,
    'fa-bolt': isBolt,
    'fa-play': isTriangle,
    'fa-star': isStar,
  })
  const sizes = ['is-small', 'is-medium', 'is-large']
  const size = sizes[Math.floor(Math.random() * 3)]
  return (
    <div className={`shape ${size}`}>
      <div className="scale">
        <div
          className="rotation"
          style={{
            transform: `rotate(${rotation}deg)`,
          }}
        >
          <div className="vector">
            <i aria-hidden="true" className={className} />
          </div>
        </div>
      </div>
    </div>
  )
}

function AnimatedActivityIconBackgroundShapes(props) {
  const { count, isBolt, isCircle, isStar, isTriangle } = props
  const className = cx('shapes', {
    bolt: isBolt,
    circle: isCircle,
    star: isStar,
    triangle: isTriangle,
  })
  const children = []
  for (let n = 0; n < count; n++) {
    children.push(
      <AnimatedActivityIconBackgroundShape
        isBolt={isBolt}
        isCircle={isCircle}
        isStar={isStar}
        isTriangle={isTriangle}
        rotation={(360 / count) * n}
        key={`AnimatedActivityIcon-${className.split(' ').join('-')}-${n + 1}`}
      />,
    )
  }
  return <div className={className}>{children}</div>
}

class AnimatedActivityIconBackground extends Component {
  renderShapes() {
    const { numBolts, numCircles, numStars, numTriangles } = this.props
    const shapes = []
    if (numBolts && !isNaN(numBolts) && numBolts > 0) {
      shapes.push(
        <AnimatedActivityIconBackgroundShapes
          isBolt
          count={numBolts}
          key={'AnimatedActivityIcon-shapes-bolt'}
        />,
      )
    }
    if (numCircles && !isNaN(numCircles) && numCircles > 0) {
      shapes.push(
        <AnimatedActivityIconBackgroundShapes
          isCircle
          count={numCircles}
          key={'AnimatedActivityIcon-shapes-circle'}
        />,
      )
    }
    if (numStars && !isNaN(numStars) && numStars > 0) {
      shapes.push(
        <AnimatedActivityIconBackgroundShapes
          isStar
          count={numStars}
          key={'AnimatedActivityIcon-shapes-stars'}
        />,
      )
    }
    if (numTriangles && !isNaN(numTriangles) && numTriangles > 0) {
      shapes.push(
        <AnimatedActivityIconBackgroundShapes
          isTriangle
          count={numTriangles}
          key={'AnimatedActivityIcon-shapes-triangles'}
        />,
      )
    }
    return shapes
  }
  renderPulses() {
    const { numPulses } = this.props
    const pulses = []
    for (let n = 0; n < numPulses; n++) {
      pulses.push(
        <div className="pulse" key={`AnimatedActivityIcon-pulse-${n + 1}`}>
          {this.renderShapes()}
        </div>,
      )
    }
    return pulses
  }
  render() {
    return (
      <div className="AnimatedActivityIcon-background">
        {this.renderPulses()}
      </div>
    )
  }
}

export default class AnimatedActivityIcon extends Component {
  renderBackground() {
    const { streak } = this.props
    if (streak >= 5) {
      return (
        <AnimatedActivityIconBackground
          numBolts={6}
          numCircles={8}
          numStars={6}
          numTriangles={9}
          numPulses={5}
        />
      )
    } else if (streak === 4) {
      return (
        <AnimatedActivityIconBackground
          numBolts={4}
          numCircles={6}
          numStars={3}
          numTriangles={6}
          numPulses={4}
        />
      )
    } else if (streak === 3) {
      return (
        <AnimatedActivityIconBackground
          numBolts={4}
          numCircles={6}
          numStars={3}
          numTriangles={6}
          numPulses={3}
        />
      )
    } else if (streak === 2) {
      return (
        <AnimatedActivityIconBackground
          numBolts={4}
          numCircles={6}
          numStars={3}
          numPulses={2}
        />
      )
    } else if (streak === 1) {
      return (
        <AnimatedActivityIconBackground
          numBolts={3}
          numCircles={6}
          numPulses={1}
        />
      )
    }
    return null
  }
  renderIcon() {
    const { isWrong, streak } = this.props
    const icon = isWrong ? 'times' : 'check'
    const className = 'AnimatedActivityIcon__icon'
    if (streak >= 5) {
      return (
        <div className={className}>
          <i aria-hidden="true" className="fa fa-trophy" />
        </div>
      )
    } else if (streak === 4) {
      return (
        <div className={className}>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 54 42">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-542.000000, -332.000000)" fill="#FFFFFF">
                <g transform="translate(535.000000, 321.000000)">
                  <path
                    d="M24.7481481,35.0703125 C24.7481481,35.2176347 24.6610954,35.3783473 24.4869874,35.5524554 L20.8407821,39.1082589 L21.7046214,44.1305804 C21.7113178,44.1774556 21.714666,44.2444192 21.714666,44.3314732 C21.714666,44.4720989 21.6795101,44.5909593 21.6091973,44.688058 C21.5388844,44.7851567 21.4367649,44.8337054 21.3028356,44.8337054 C21.1756029,44.8337054 21.0416756,44.7935272 20.9010499,44.7131696 L16.3910053,42.3426339 L11.8809606,44.7131696 C11.7336385,44.7935272 11.5997113,44.8337054 11.4791749,44.8337054 C11.3385492,44.8337054 11.2330815,44.7851567 11.1627687,44.688058 C11.0924558,44.5909593 11.0572999,44.4720989 11.0572999,44.3314732 C11.0572999,44.2912944 11.0639963,44.2243308 11.0773892,44.1305804 L11.9412285,39.1082589 L8.28497851,35.5524554 C8.11756695,35.3716509 8.03386243,35.2109382 8.03386243,35.0703125 C8.03386243,34.8225434 8.22136056,34.6685271 8.59636243,34.6082589 L13.6387731,33.875 L15.8988178,29.3046875 C16.0260506,29.0301326 16.1901114,28.8928571 16.3910053,28.8928571 C16.5918992,28.8928571 16.75596,29.0301326 16.8831928,29.3046875 L19.1432374,33.875 L24.1856481,34.6082589 C24.56065,34.6685271 24.7481481,34.8225434 24.7481481,35.0703125 Z"
                    transform="translate(16.391005, 36.863281) rotate(-8.000000) translate(-16.391005, -36.863281) "
                  />
                  <path
                    d="M58.8920635,35.0703125 C58.8920635,35.2176347 58.8050108,35.3783473 58.6309028,35.5524554 L54.9846974,39.1082589 L55.8485367,44.1305804 C55.8552332,44.1774556 55.8585813,44.2444192 55.8585813,44.3314732 C55.8585813,44.4720989 55.8234255,44.5909593 55.7531126,44.688058 C55.6827997,44.7851567 55.5806802,44.8337054 55.446751,44.8337054 C55.3195182,44.8337054 55.185591,44.7935272 55.0449653,44.7131696 L50.5349206,42.3426339 L46.024876,44.7131696 C45.8775538,44.7935272 45.7436266,44.8337054 45.6230903,44.8337054 C45.4824646,44.8337054 45.3769969,44.7851567 45.306684,44.688058 C45.2363712,44.5909593 45.2012153,44.4720989 45.2012153,44.3314732 C45.2012153,44.2912944 45.2079116,44.2243308 45.2213046,44.1305804 L46.0851438,39.1082589 L42.4288938,35.5524554 C42.2614823,35.3716509 42.1777778,35.2109382 42.1777778,35.0703125 C42.1777778,34.8225434 42.3652759,34.6685271 42.7402778,34.6082589 L47.7826885,33.875 L50.0427331,29.3046875 C50.1699659,29.0301326 50.3340268,28.8928571 50.5349206,28.8928571 C50.7358145,28.8928571 50.8998754,29.0301326 51.0271081,29.3046875 L53.2871528,33.875 L58.3295635,34.6082589 C58.7045654,34.6685271 58.8920635,34.8225434 58.8920635,35.0703125 Z"
                    transform="translate(50.534921, 36.863281) rotate(8.000000) translate(-50.534921, -36.863281) "
                  />
                  <path d="M46.1603175,20.6054688 C46.1603175,20.826452 46.0297384,21.067521 45.7685764,21.328683 L40.2992684,26.6623884 L41.5950273,34.1958705 C41.605072,34.2661834 41.6100942,34.3666288 41.6100942,34.4972098 C41.6100942,34.7081484 41.5573604,34.886439 41.4518911,35.0320871 C41.3464218,35.1777351 41.1932426,35.250558 40.9923487,35.250558 C40.8014995,35.250558 40.6006087,35.1902908 40.3896701,35.0697545 L33.6246032,31.5139509 L26.8595362,35.0697545 C26.638553,35.1902908 26.4376621,35.250558 26.2568576,35.250558 C26.0459191,35.250558 25.8877175,35.1777351 25.7822483,35.0320871 C25.676779,34.886439 25.6240451,34.7081484 25.6240451,34.4972098 C25.6240451,34.4369417 25.6340897,34.3364962 25.6541791,34.1958705 L26.949938,26.6623884 L21.465563,21.328683 C21.2144457,21.0574763 21.0888889,20.8164073 21.0888889,20.6054688 C21.0888889,20.2338151 21.3701361,20.0027906 21.9326389,19.9123884 L29.496255,18.8125 L32.8863219,11.9570313 C33.0771711,11.5451988 33.3232624,11.3392857 33.6246032,11.3392857 C33.925944,11.3392857 34.1720353,11.5451988 34.3628844,11.9570313 L37.7529514,18.8125 L45.3165675,19.9123884 C45.8790703,20.0027906 46.1603175,20.2338151 46.1603175,20.6054688 Z" />
                  <path d="M42.6730159,42.078125 C42.6730159,42.2418163 42.5762906,42.4203859 42.3828373,42.6138393 L38.331498,46.5647321 L39.2913194,52.1450893 C39.29876,52.1971729 39.3024802,52.2715769 39.3024802,52.3683036 C39.3024802,52.5245544 39.263418,52.6566215 39.1852927,52.7645089 C39.1071673,52.8723964 38.9937011,52.9263393 38.8448909,52.9263393 C38.7035211,52.9263393 38.5547131,52.8816969 38.3984623,52.7924107 L33.3873016,50.1584821 L28.3761409,52.7924107 C28.2124496,52.8816969 28.0636415,52.9263393 27.9297123,52.9263393 C27.7734615,52.9263393 27.6562752,52.8723964 27.5781498,52.7645089 C27.5000244,52.6566215 27.4609623,52.5245544 27.4609623,52.3683036 C27.4609623,52.3236605 27.4684027,52.2492565 27.4832837,52.1450893 L28.4431052,46.5647321 L24.3806052,42.6138393 C24.1945923,42.4129454 24.1015873,42.2343758 24.1015873,42.078125 C24.1015873,41.802826 24.3099186,41.6316968 24.7265873,41.5647321 L30.3292659,40.75 L32.8404266,35.671875 C32.9817963,35.366814 33.1640862,35.2142857 33.3873016,35.2142857 C33.610517,35.2142857 33.7928068,35.366814 33.9341766,35.671875 L36.4453373,40.75 L42.0480159,41.5647321 C42.4646846,41.6316968 42.6730159,41.802826 42.6730159,42.078125 Z" />
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    } else if (streak === 3) {
      return (
        <div className={className}>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 54 30">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-542.000000, -337.000000)" fill="#FFFFFF">
                <g transform="translate(535.000000, 321.000000)">
                  <path
                    d="M24.7481481,35.0703125 C24.7481481,35.2176347 24.6610954,35.3783473 24.4869874,35.5524554 L20.8407821,39.1082589 L21.7046214,44.1305804 C21.7113178,44.1774556 21.714666,44.2444192 21.714666,44.3314732 C21.714666,44.4720989 21.6795101,44.5909593 21.6091973,44.688058 C21.5388844,44.7851567 21.4367649,44.8337054 21.3028356,44.8337054 C21.1756029,44.8337054 21.0416756,44.7935272 20.9010499,44.7131696 L16.3910053,42.3426339 L11.8809606,44.7131696 C11.7336385,44.7935272 11.5997113,44.8337054 11.4791749,44.8337054 C11.3385492,44.8337054 11.2330815,44.7851567 11.1627687,44.688058 C11.0924558,44.5909593 11.0572999,44.4720989 11.0572999,44.3314732 C11.0572999,44.2912944 11.0639963,44.2243308 11.0773892,44.1305804 L11.9412285,39.1082589 L8.28497851,35.5524554 C8.11756695,35.3716509 8.03386243,35.2109382 8.03386243,35.0703125 C8.03386243,34.8225434 8.22136056,34.6685271 8.59636243,34.6082589 L13.6387731,33.875 L15.8988178,29.3046875 C16.0260506,29.0301326 16.1901114,28.8928571 16.3910053,28.8928571 C16.5918992,28.8928571 16.75596,29.0301326 16.8831928,29.3046875 L19.1432374,33.875 L24.1856481,34.6082589 C24.56065,34.6685271 24.7481481,34.8225434 24.7481481,35.0703125 Z"
                    transform="translate(16.391005, 36.863281) rotate(-8.000000) translate(-16.391005, -36.863281) "
                  />
                  <path
                    d="M58.8920635,35.0703125 C58.8920635,35.2176347 58.8050108,35.3783473 58.6309028,35.5524554 L54.9846974,39.1082589 L55.8485367,44.1305804 C55.8552332,44.1774556 55.8585813,44.2444192 55.8585813,44.3314732 C55.8585813,44.4720989 55.8234255,44.5909593 55.7531126,44.688058 C55.6827997,44.7851567 55.5806802,44.8337054 55.446751,44.8337054 C55.3195182,44.8337054 55.185591,44.7935272 55.0449653,44.7131696 L50.5349206,42.3426339 L46.024876,44.7131696 C45.8775538,44.7935272 45.7436266,44.8337054 45.6230903,44.8337054 C45.4824646,44.8337054 45.3769969,44.7851567 45.306684,44.688058 C45.2363712,44.5909593 45.2012153,44.4720989 45.2012153,44.3314732 C45.2012153,44.2912944 45.2079116,44.2243308 45.2213046,44.1305804 L46.0851438,39.1082589 L42.4288938,35.5524554 C42.2614823,35.3716509 42.1777778,35.2109382 42.1777778,35.0703125 C42.1777778,34.8225434 42.3652759,34.6685271 42.7402778,34.6082589 L47.7826885,33.875 L50.0427331,29.3046875 C50.1699659,29.0301326 50.3340268,28.8928571 50.5349206,28.8928571 C50.7358145,28.8928571 50.8998754,29.0301326 51.0271081,29.3046875 L53.2871528,33.875 L58.3295635,34.6082589 C58.7045654,34.6685271 58.8920635,34.8225434 58.8920635,35.0703125 Z"
                    transform="translate(50.534921, 36.863281) rotate(8.000000) translate(-50.534921, -36.863281) "
                  />
                  <path d="M46.1603175,25.6054688 C46.1603175,25.826452 46.0297384,26.067521 45.7685764,26.328683 L40.2992684,31.6623884 L41.5950273,39.1958705 C41.605072,39.2661834 41.6100942,39.3666288 41.6100942,39.4972098 C41.6100942,39.7081484 41.5573604,39.886439 41.4518911,40.0320871 C41.3464218,40.1777351 41.1932426,40.250558 40.9923487,40.250558 C40.8014995,40.250558 40.6006087,40.1902908 40.3896701,40.0697545 L33.6246032,36.5139509 L26.8595362,40.0697545 C26.638553,40.1902908 26.4376621,40.250558 26.2568576,40.250558 C26.0459191,40.250558 25.8877175,40.1777351 25.7822483,40.0320871 C25.676779,39.886439 25.6240451,39.7081484 25.6240451,39.4972098 C25.6240451,39.4369417 25.6340897,39.3364962 25.6541791,39.1958705 L26.949938,31.6623884 L21.465563,26.328683 C21.2144457,26.0574763 21.0888889,25.8164073 21.0888889,25.6054688 C21.0888889,25.2338151 21.3701361,25.0027906 21.9326389,24.9123884 L29.496255,23.8125 L32.8863219,16.9570313 C33.0771711,16.5451988 33.3232624,16.3392857 33.6246032,16.3392857 C33.925944,16.3392857 34.1720353,16.5451988 34.3628844,16.9570313 L37.7529514,23.8125 L45.3165675,24.9123884 C45.8790703,25.0027906 46.1603175,25.2338151 46.1603175,25.6054688 Z" />
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    } else if (streak === 2) {
      return (
        <div className={className}>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 50 25">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-543.000000, -340.000000)" fill="#FFFFFF">
                <g transform="translate(535.000000, 321.000000)">
                  <path
                    d="M31.3195767,29.09375 C31.3195767,29.2901796 31.2035065,29.5044631 30.9713624,29.7366071 L26.1097553,34.4776786 L27.261541,41.1741071 C27.2704696,41.2366075 27.2749339,41.3258923 27.2749339,41.4419643 C27.2749339,41.6294652 27.2280593,41.7879458 27.1343089,41.9174107 C27.0405584,42.0468756 26.904399,42.1116071 26.7258267,42.1116071 C26.556183,42.1116071 26.3776134,42.0580363 26.1901124,41.9508929 L20.1767196,38.7901786 L14.1633267,41.9508929 C13.9668972,42.0580363 13.7883275,42.1116071 13.6276124,42.1116071 C13.4401115,42.1116071 13.2994879,42.0468756 13.2057374,41.9174107 C13.111987,41.7879458 13.0651124,41.6294652 13.0651124,41.4419643 C13.0651124,41.3883926 13.0740409,41.2991078 13.0918981,41.1741071 L14.2436839,34.4776786 L9.36868386,29.7366071 C9.14546846,29.4955345 9.03386243,29.2812509 9.03386243,29.09375 C9.03386243,28.7633912 9.28385993,28.5580361 9.78386243,28.4776786 L16.5070767,27.5 L19.5204696,21.40625 C19.6901133,21.0401767 19.9088611,20.8571429 20.1767196,20.8571429 C20.4445781,20.8571429 20.6633259,21.0401767 20.8329696,21.40625 L23.8463624,27.5 L30.5695767,28.4776786 C31.0695792,28.5580361 31.3195767,28.7633912 31.3195767,29.09375 Z"
                    transform="translate(20.176720, 31.484375) rotate(-8.000000) translate(-20.176720, -31.484375) "
                  />
                  <path
                    d="M57.4634921,28.09375 C57.4634921,28.2901796 57.3474218,28.5044631 57.1152778,28.7366071 L52.2536706,33.4776786 L53.4054563,40.1741071 C53.414385,40.2366075 53.4188492,40.3258923 53.4188492,40.4419643 C53.4188492,40.6294652 53.3719747,40.7879458 53.2782242,40.9174107 C53.1844737,41.0468756 53.0483144,41.1116071 52.8697421,41.1116071 C52.7000984,41.1116071 52.5215287,41.0580363 52.3340278,40.9508929 L46.3206349,37.7901786 L40.3072421,40.9508929 C40.1108125,41.0580363 39.9322429,41.1116071 39.7715278,41.1116071 C39.5840268,41.1116071 39.4434032,41.0468756 39.3496528,40.9174107 C39.2559023,40.7879458 39.2090278,40.6294652 39.2090278,40.4419643 C39.2090278,40.3883926 39.2179563,40.2991078 39.2358135,40.1741071 L40.3875992,33.4776786 L35.5125992,28.7366071 C35.2893838,28.4955345 35.1777778,28.2812509 35.1777778,28.09375 C35.1777778,27.7633912 35.4277753,27.5580361 35.9277778,27.4776786 L42.6509921,26.5 L45.6643849,20.40625 C45.8340286,20.0401767 46.0527764,19.8571429 46.3206349,19.8571429 C46.5884934,19.8571429 46.8072412,20.0401767 46.9768849,20.40625 L49.9902778,26.5 L56.7134921,27.4776786 C57.2134946,27.5580361 57.4634921,27.7633912 57.4634921,28.09375 Z"
                    transform="translate(46.320635, 30.484375) rotate(8.000000) translate(-46.320635, -30.484375) "
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    } else if (streak === 1) {
      return (
        <div className={className}>
          <svg aria-hidden="true" focusable="false" viewBox="0 0 30 30">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-553.000000, -338.000000)" fill="#FFFFFF">
                <g transform="translate(535.000000, 321.000000)">
                  <path d="M47.7481481,28.125 C47.7481481,28.3869061 47.5933878,28.6726175 47.2838624,28.9821429 L40.8017196,35.3035714 L42.3374339,44.2321429 C42.3493387,44.3154766 42.355291,44.434523 42.355291,44.5892857 C42.355291,44.839287 42.2927916,45.0505944 42.167791,45.2232143 C42.0427904,45.3958342 41.8612446,45.4821429 41.6231481,45.4821429 C41.3969565,45.4821429 41.1588637,45.410715 40.9088624,45.2678571 L32.8910053,41.0535714 L24.8731481,45.2678571 C24.6112421,45.410715 24.3731492,45.4821429 24.1588624,45.4821429 C23.9088612,45.4821429 23.7213631,45.3958342 23.5963624,45.2232143 C23.4713618,45.0505944 23.4088624,44.839287 23.4088624,44.5892857 C23.4088624,44.5178568 23.4207671,44.3988104 23.4445767,44.2321429 L24.980291,35.3035714 L18.480291,28.9821429 C18.1826705,28.6607127 18.0338624,28.3750013 18.0338624,28.125 C18.0338624,27.6845216 18.3671924,27.4107148 19.0338624,27.3035714 L27.9981481,26 L32.0160053,17.875 C32.2421969,17.3869023 32.5338606,17.1428571 32.8910053,17.1428571 C33.2481499,17.1428571 33.5398137,17.3869023 33.7660053,17.875 L37.7838624,26 L46.7481481,27.3035714 C47.4148181,27.4107148 47.7481481,27.6845216 47.7481481,28.125 Z" />
                </g>
              </g>
            </g>
          </svg>
        </div>
      )
    }
    return <i aria-hidden="true" className={`fa fa-${icon}`} />
  }
  render() {
    const { className, isWrong, streak } = this.props
    const classNames = cx(
      'AnimatedActivityIcon',
      {
        'is-correct': !isWrong,
        'is-max-streak': !isWrong && streak >= 9,
        'is-eight-streak': !isWrong && streak === 8,
        'is-seven-streak': !isWrong && streak === 7,
        'is-six-streak': !isWrong && streak === 6,
        'is-five-streak': !isWrong && streak === 5,
        'is-four-streak': !isWrong && streak === 4,
        'is-three-streak': !isWrong && streak === 3,
        'is-two-streak': !isWrong && streak === 2,
        'is-one-streak': !isWrong && streak === 1,
        'is-streak': !isWrong && streak > 0,
      },
      className,
    )
    return (
      <div className={classNames}>
        {this.renderBackground()}
        <div className="ActivityCompleted-icon">{this.renderIcon()}</div>
      </div>
    )
  }
}
