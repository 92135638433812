import React from 'react'
import cx from 'classnames'

import './Note.scss'

const Note = (props) => {
  const {
    isPrimary,
    isSimple,
    isInfo,
    isSuccess,
    isWarning,
    isDanger,
    onClose,
    ...rest
  } = props

  const className = cx(
    'note Note',
    {
      'is-primary': isPrimary,
      'is-simple': isSimple,
      'is-info': isInfo,
      'is-success': isSuccess,
      'is-warning': isWarning,
      'is-danger': isDanger,
    },
    props.className,
  )

  const hasHeader = props.title || props.onClose

  return (
    <div {...rest} className={className}>
      {hasHeader && (
        <div className="note-header">
          {props.title && <p>{props.title}</p>}
          {props.onClose && (
            <button className="delete" onClick={props.onClose} />
          )}
        </div>
      )}
      <div className="note-body">{props.children}</div>
    </div>
  )
}

export default Note
