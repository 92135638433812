import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import qs from 'query-string'
import {
  Button,
  Loader,
  Link,
  NewMessageModal,
  ProgressChart,
  Spot,
} from '@/components'
import { DISABLE_DASHBOARD, STATUS_TESTED_OUT } from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import {
  filterMetricLabels,
  fromUTCToLocale,
  getMetricLabel,
  rankLabel,
  sortMetricLabelsFromAtRisk,
  timestampFromNow,
} from '@/utils/helpers'
import './InstanceAnalyticsLearnerPage.scss'

function getTimeSpentText(value) {
  const seconds = Number(value)
  const minutes = Math.round(seconds / 60)
  if (minutes < 60) return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  const hours = Math.floor(minutes / 60)
  const remainderMin = Math.round(minutes % 60)
  return `${hours} ${hours === 1 ? 'hour' : 'hours'} and ${remainderMin} ${
    remainderMin === 1 ? 'minute' : 'minutes'
  }`
}

const TIME_METRICS_KEY_MAPPING = {
  lastActivity: {
    displayOrder: 2,
    render: (value) => {
      if (!value && value !== 0) {
        return (
          <span>
            <span className="is-bold">No available activity</span>
            has been logged
          </span>
        )
      }
      return (
        <span>
          <span className="is-bold">
            {timestampFromNow(fromUTCToLocale(value))}
          </span>
          was the last activity logged
        </span>
      )
    },
  },
  passedFirstAttempt: {
    displayOrder: 1,
    render: (value) => {
      return (
        <span>
          <span className="is-bold">{`${value} question${
            value !== 1 ? 's' : ''
          } aced`}</span>
          on the first try
        </span>
      )
    },
  },
  totalTime: {
    displayOrder: 0,
    render: (value) => {
      return (
        <span>
          <span className="is-bold">
            {value ? getTimeSpentText(value) : '0 minutes'}
          </span>
          spent learning in the platform
        </span>
      )
    },
  },
}

const PRETEST_TIME_METRICS_KEY_MAPPING = {
  lastPretestActivity: {
    displayOrder: 2,
    render: (value) => {
      if (!value && value !== 0) {
        return (
          <span>
            <span className="is-bold">No available activity</span>
            has been logged
          </span>
        )
      }
      return (
        <span>
          <span className="is-bold">
            {timestampFromNow(fromUTCToLocale(value))}
          </span>
          was the last activity logged
        </span>
      )
    },
  },
  totalSections: {
    displayOrder: 1,
    render: (value) => {
      const { count: totalSectionsCount, testedOut: totalSectionsTestedOut } =
        value
      return (
        <span>
          <span className="is-bold">
            {totalSectionsTestedOut} of {totalSectionsCount} Sections
          </span>
          were tested out of
        </span>
      )
    },
  },
  totalPretestTime: {
    displayOrder: 0,
    render: (value) => {
      return (
        <span>
          <span className="is-bold">
            {value ? getTimeSpentText(value) : '0 minutes'}
          </span>
          spent learning in the platform
        </span>
      )
    },
  },
}

const initialSorting = {
  sortBy: 'first_name',
  sortOrientation: 'asc',
}

@inject('adminStore', 'analyticsStore', 'uiStore')
@observer
export default class InstanceAnalyticsLearnerPage extends Component {
  state = {
    newMessageModal: null,
  }
  componentDidMount() {
    const { analyticsStore, match, uiStore } = this.props
    const { filters, report } = analyticsStore
    const { params } = match
    const { instanceId, learnerId } = params
    if (!report) {
      analyticsStore.loadCourse({ courseId: instanceId }).then(() => {
        if (!report) {
          analyticsStore
            .loadLearners({
              courseId: instanceId,
              filters,
              sorting: initialSorting,
            })
            .then(() => {
              analyticsStore.loadLearner({
                courseId: instanceId,
                learnerId: learnerId,
              })
            })
        } else {
          analyticsStore.loadLearner({
            courseId: instanceId,
            learnerId: learnerId,
          })
        }
      })
    } else {
      analyticsStore.loadLearner({
        courseId: instanceId,
        learnerId: learnerId,
      })
    }

    uiStore.updateTitle('Course Learner Analytics Page')
  }
  contactLearner = (event, recipient) => {
    event.preventDefault()
    this.openNewMessageModal(recipient)
  }
  openNewMessageModal = (user = null) => {
    this.setState({
      newMessageModal: {
        error: null,
        recipient: user,
      },
    })
  }
  closeModal = (modal) => {
    const { uiStore } = this.props
    const update = {}
    if (hasOwnProperty(this.state, modal)) {
      update[modal] = null
      this.setState(update)
      uiStore.scrollY(null, uiStore.scrollTop)
    }
  }
  onSaveThread = (recipients, message, reset) => {
    const { adminStore } = this.props
    const { newMessageModal } = this.state
    const { instance, session } = adminStore
    const currentUser = session.user
    const excludeIds = [currentUser.id]
    instance
      .saveThread(recipients, message, null, excludeIds, currentUser)
      .then(() => {
        reset(() => this.closeModal('newMessageModal'))
      })
      .catch((error) => {
        newMessageModal['error'] =
          error && error.message
            ? error.message
            : 'There was an error while saving the message.'
        this.setState({ newMessageModal })
      })
  }
  renderHeader() {
    const { adminStore, analyticsStore } = this.props
    const { instance } = adminStore
    const { learner } = analyticsStore
    const { course } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    const { fullName, userId } = learner || {}
    const recipient = learner ? { id: userId, fullName } : null
    return (
      <div className="InstanceAnalyticsLearnerPage-header">
        <h1 className="InstanceAnalyticsLearnerPage-title">{fullName}</h1>
        {!disableMessaging ? (
          <Button
            className="InstanceAnalyticsLearnerPage-email"
            aria-label="Contact Learner"
            isSuccess
            onClick={(event) => this.contactLearner(event, recipient)}
          >
            <span className="InstanceAnalyticsLearnerPage-email-text">
              Contact learner
            </span>
            <i
              className="fa fa-envelope"
              aria-hidden="true"
              title="Contact Learner"
            />
          </Button>
        ) : null}
      </div>
    )
  }
  renderProgress() {
    const { analyticsStore } = this.props
    const { learner } = analyticsStore
    const { completion, firstName, rank } = learner || {}
    return (
      <div className="InstanceAnalyticsLearnerPage__progress">
        <div className="InstanceAnalyticsLearnerPage__section-header">
          <h1>Course progress</h1>
        </div>
        <div className="InstanceAnalyticsLearnerPage__section-content">
          <ProgressChart
            theme={rank}
            value={completion || 0}
            valueLabel={firstName}
          />
        </div>
      </div>
    )
  }
  renderVideoProgress(section) {
    const { testedOut } = section
    const { hasVideo, watchPercentage } = section
    let percentage = null
    if (!hasVideo) {
      return (
        <span className="InstanceAnalyticsLearnerPage-noVideo">
          {testedOut ? 'N/A' : 'No Video'}
        </span>
      )
    }
    if (!isNaN(watchPercentage)) {
      if (watchPercentage >= 100) {
        percentage = 100
      } else if (watchPercentage <= 0) {
        percentage = 0
      } else {
        percentage = watchPercentage
      }
    }
    if (percentage === null) {
      percentage = 0
    }
    return (
      <div className="InstanceAnalyticsLearnerPage-table-watchPercentage is-info">
        <div style={{ width: `${percentage}%` }} />
      </div>
    )
  }
  renderLearnerRank() {
    const { learner } = this.props.analyticsStore
    const { rank } = learner || {}
    if (rank === 'all-star')
      return (
        <span className="InstanceAnalyticsLearnerPage__learner-rank">
          <Spot isSuccess label="All Star" />
        </span>
      )
    if (rank === 'good')
      return (
        <span className="InstanceAnalyticsLearnerPage__learner-rank">
          <Spot isInfo label="Proficient" />
        </span>
      )
    if (rank === 'at-risk')
      return (
        <span className="InstanceAnalyticsLearnerPage__learner-rank">
          <Spot isWarning label="Unengaged" />
        </span>
      )
    if (rank === STATUS_TESTED_OUT)
      return (
        <span className="InstanceAnalyticsLearnerPage__learner-rank">
          <Spot isSuccess label="Tested Out" />
        </span>
      )
    return null
  }
  renderRank() {
    return (
      <div className="InstanceAnalyticsLearnerPage__rank">
        <div className="InstanceAnalyticsLearnerPage__section-header">
          <h1>Performance</h1>
        </div>
        <div className="InstanceAnalyticsLearnerPage__section-content">
          {this.renderLearnerRank()}
        </div>
      </div>
    )
  }
  renderWhereList() {
    const { adminStore, analyticsStore } = this.props
    const { instance } = adminStore
    const { learner } = analyticsStore
    const { settings: instanceSettings } = instance || {}
    const { learnerDashboard, snapshotTestOut } = instanceSettings || {}
    const areUnitsReversed = learner && learner.areUnitsReversed
    const iconReverseUnits = cx('fa', {
      'fa-sort-amount-desc': areUnitsReversed,
      'fa-sort-amount-asc': !areUnitsReversed,
    })
    const sectionsWithTestedOut = learner.sectionsWithTestedOut(snapshotTestOut)
    return (
      <div className="InstanceAnalyticsLearnerPage-sections">
        <div className="InstanceAnalyticsLearnerPage__section-header">
          <div className="columns">
            <div className="column">
              <h1>KNOWLEDGE</h1>
              <p>
                The learner’s confident subject matter mastery at the
                microlearning section level.
              </p>
            </div>
          </div>
        </div>
        <table className="InstanceAnalyticsLearnerPage-table">
          <thead>
            <tr>
              <th className="is-hidden-mobile">
                Unit
                <Button
                  aria-label={`Sort Units by ${
                    areUnitsReversed ? 'ascending' : 'descending'
                  } order`}
                  className="InstanceAnalyticsLearnerPage-filter-icon pull-right"
                  isIcon
                  isLight
                  onClick={() => learner.reverseUnits()}
                >
                  <i aria-hidden="true" className={iconReverseUnits} />
                </Button>
              </th>
              <th>Section</th>
              <th>Watch</th>
              <th className="has-text-left">
                {learnerDashboard && learnerDashboard !== DISABLE_DASHBOARD
                  ? 'First Attempt'
                  : 'Status'}
              </th>
              {learnerDashboard && learnerDashboard !== DISABLE_DASHBOARD ? (
                <th className="has-text-left">Re-Attempt</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {sectionsWithTestedOut.map((section, i) => {
              const {
                id: sectionId,
                learningObjectives: sectionLearningObjectives,
                rank: sectionRank,
                reattemptRank: sectionReattemptRank,
                title: sectionTitle,
                unitTitle: sectionUnitTitle,
              } = section
              return (
                <tr key={`${sectionId}-${i}`}>
                  <td className="InstanceAnalyticsLearnerPage-table-unit is-hidden-mobile">
                    {sectionUnitTitle}
                  </td>
                  <td className="InstanceAnalyticsLearnerPage-table-section">
                    <span className="is-hidden-tablet">
                      <span className="is-info">Unit:&nbsp;</span>
                      <span>{sectionUnitTitle}</span>
                    </span>
                    <span className="InstanceAnalyticsLearnerPage-table-sectionTitle">
                      <span className="is-hidden-tablet is-info">
                        Section:&nbsp;
                      </span>
                      <span>{sectionTitle}</span>
                    </span>
                    {sectionLearningObjectives &&
                    sectionLearningObjectives.length ? (
                      <div
                        className="InstanceAnalyticsLearnerPage-table-sectionLOs"
                        id={sectionId}
                      >
                        <span className="InstanceAnalyticsLearnerPage__table-section-lo-label">
                          Learning Objectives:
                        </span>
                        <ul>
                          {sectionLearningObjectives.map(
                            (learningObjective, i) => {
                              return (
                                <li
                                  key={`${learningObjective}-${i}`}
                                  className="InstanceAnalyticsLearnerPage-table-sectionLO"
                                >
                                  <span>{learningObjective}</span>
                                </li>
                              )
                            },
                          )}
                        </ul>
                      </div>
                    ) : null}
                  </td>
                  <td className="InstanceAnalyticsLearnerPage-table-watch">
                    {this.renderVideoProgress(section)}
                  </td>
                  <td className="InstanceAnalyticsLearnerPage-table-score">
                    {sectionRank || section.testedOut ? (
                      !section.testedOut ? (
                        <Spot
                          className="InstanceAnalyticsLearnerPage-scoreSpot"
                          isSmall
                          alignLeft
                          label={
                            section.testedOut
                              ? 'Tested Out'
                              : rankLabel(sectionRank)
                          }
                          isWarning={sectionRank === 'at-risk'}
                          isInfo={sectionRank === 'good'}
                          isSuccess={sectionRank === 'all-star'}
                          isOrange={section.testedOut}
                        />
                      ) : (
                        <span>Tested Out</span>
                      )
                    ) : (
                      <span className="InstanceAnalyticsLearnerPage-undefinedSectionRank">
                        Not Enough Data
                      </span>
                    )}
                  </td>
                  {learnerDashboard &&
                  learnerDashboard !== DISABLE_DASHBOARD ? (
                    <td className="InstanceAnalyticsLearnerPage-table-score">
                      {sectionReattemptRank ? (
                        <Spot
                          className="InstanceAnalyticsLearnerPage-scoreSpot"
                          isSmall
                          alignLeft
                          label={rankLabel(sectionReattemptRank)}
                          isWarning={sectionReattemptRank === 'at-risk'}
                          isInfo={sectionReattemptRank === 'good'}
                          isSuccess={sectionReattemptRank === 'all-star'}
                        />
                      ) : (
                        <div className="InstanceAnalyticsLearnerPage-emptyReattempt">
                          -
                        </div>
                      )}
                    </td>
                  ) : null}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  renderMetricsList() {
    const { adminStore, analyticsStore } = this.props
    const { instance } = adminStore
    const { learner } = analyticsStore
    const { settings: instanceSettings } = instance || {}
    const { snapshotTestOut } = instanceSettings || {}
    const filteredLearnerMetrics =
      learner && learner.hasMetrics && learner.metrics && learner.metrics.length
        ? learner.metrics.filter(({ rank }) => rank === 'at-risk')
        : []
    const sectionsWithTestedOut = learner.sectionsWithTestedOut(snapshotTestOut)
    const hasMetrics = filteredLearnerMetrics.length
    return hasMetrics && learner.rank === 'at-risk' ? (
      <div className="InstanceAnalyticsLearnerPage-metricsList">
        <div className="InstanceAnalyticsLearnerPage__section-header">
          <div className="columns">
            <div className="column">
              <h1>Behavior</h1>
              <p>
                The learner’s behavior attributes compared to peers in the same
                course.
              </p>
            </div>
          </div>
        </div>
        <div className="InstanceAnalyticsLearnerPage-metrics">
          {filteredLearnerMetrics
            .filter(filterMetricLabels)
            .sort(sortMetricLabelsFromAtRisk)
            .map((metric) => {
              const { label: key, rank } = metric
              const metricLabel = getMetricLabel(metric)
              const { description, isHidden, label, validation } = metricLabel
              if (
                isHidden ||
                (validation && !validation(sectionsWithTestedOut))
              )
                return
              return (
                <div
                  key={key}
                  className={cx(
                    'InstanceAnalyticsLearnerPage-metric InstanceAnalyticsLearnerPage-metricItem',
                    {
                      'at-risk': rank === 'at-risk',
                    },
                  )}
                >
                  <div className="InstanceAnalyticsLearnerPage-metricItemColumn">
                    <Spot
                      isWarning={rank === 'at-risk'}
                      isInfo={rank === 'good'}
                      isSuccess={rank === 'all-star'}
                    />
                  </div>
                  <div className="InstanceAnalyticsLearnerPage-metricItemColumn">
                    <h1>{label}</h1>
                    {description ? <p>{description}</p> : null}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    ) : null
  }
  renderPretestTimeMetrics() {
    const { adminStore, analyticsStore } = this.props
    const { instance } = adminStore
    const { learner } = analyticsStore
    const { pretestTimeMetrics } = learner
    const { settings: instanceSettings } = instance
    const { snapshot: snapshotSettings } = instanceSettings || {}
    if (
      !pretestTimeMetrics ||
      !Object.keys(pretestTimeMetrics).length ||
      // if snapshot is disabled but pretest time metrics have values
      (!snapshotSettings &&
        Object.keys(pretestTimeMetrics).filter((key) => {
          return !!pretestTimeMetrics[key]
        }).length)
    )
      return null
    return (
      <div className="InstanceAnalyticsLearnerPage__time-metrics-list">
        <div className="InstanceAnalyticsLearnerPage__section-header">
          <div className="columns">
            <div className="column">
              <h1>Time Spent in Pretest</h1>
              <p>
                A high-level view of the learner&apos;s performance in the
                Course Pretest.
              </p>
            </div>
          </div>
        </div>
        <div className="InstanceAnalyticsLearnerPage__time-metrics-list-items columns is-desktop">
          {Object.keys(pretestTimeMetrics)
            .filter((key) => !!PRETEST_TIME_METRICS_KEY_MAPPING[key])
            .sort((a, b) => {
              const pretestTimeMetricsA = PRETEST_TIME_METRICS_KEY_MAPPING[a]
              const pretestTimeMetricsB = PRETEST_TIME_METRICS_KEY_MAPPING[b]
              const { displayOrder: displayOrderA } = pretestTimeMetricsA
              const { displayOrder: displayOrderB } = pretestTimeMetricsB
              return displayOrderA - displayOrderB
            })
            .map((key, i) => {
              return (
                <div
                  key={`InstanceAnalyticsLearnerPage__time-metrics-${key}-${i}`}
                  className="InstanceAnalyticsLearnerPage__time-metrics-list-item column"
                >
                  {PRETEST_TIME_METRICS_KEY_MAPPING[key].render(
                    pretestTimeMetrics[key],
                  )}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
  renderTimeMetrics() {
    const { analyticsStore } = this.props
    const { learner } = analyticsStore
    const { timeMetrics } = learner
    if (!timeMetrics || !Object.keys(timeMetrics).length) return null
    return (
      <div className="InstanceAnalyticsLearnerPage__time-metrics-list">
        <div className="InstanceAnalyticsLearnerPage__section-header">
          <div className="columns">
            <div className="column">
              <h1>Time Spent</h1>
              <p>
                A high-level view of the learner&apos;s engagement so far with
                the material.
              </p>
            </div>
          </div>
        </div>
        <div className="InstanceAnalyticsLearnerPage__time-metrics-list-items columns is-desktop">
          {Object.keys(timeMetrics)
            .filter((key) => !!TIME_METRICS_KEY_MAPPING[key])
            .sort((a, b) => {
              const timeMetricsA = TIME_METRICS_KEY_MAPPING[a]
              const timeMetricsB = TIME_METRICS_KEY_MAPPING[b]
              const { displayOrder: displayOrderA } = timeMetricsA
              const { displayOrder: displayOrderB } = timeMetricsB
              return displayOrderA - displayOrderB
            })
            .map((key, index) => {
              return (
                <div
                  className="InstanceAnalyticsLearnerPage__time-metrics-list-item column"
                  key={`InstanceAnalyticsLearnerPage__time-metrics-list-item-${key}-${index}`}
                >
                  {TIME_METRICS_KEY_MAPPING[key].render(timeMetrics[key])}
                </div>
              )
            })}
        </div>
      </div>
    )
  }
  renderBody() {
    const { learner, loadingError } = this.props.analyticsStore
    if (loadingError) {
      return (
        <div className="has-text-centered" style={{ padding: '80px 20px' }}>
          {loadingError}
        </div>
      )
    }
    if (!learner) return
    return (
      <Fragment>
        {this.renderHeader()}
        <div className="InstanceAnalyticsLearnerPage__learner-status">
          {this.renderRank()}
          {this.renderProgress()}
        </div>
        {this.renderTimeMetrics()}
        {this.renderPretestTimeMetrics()}
        {this.renderMetricsList()}
        {this.renderWhereList()}
      </Fragment>
    )
  }
  render() {
    const { adminStore, analyticsStore, match } = this.props
    const { newMessageModal } = this.state
    const { instance } = adminStore
    const { isLoadingCourse, isLoadingLearner, isLoadingLearners } =
      analyticsStore
    const { analyticsFilters, isSavingThread, isLoadingSettings } = instance
    const { params } = match
    const { instanceId } = params
    const querystring = { ...analyticsFilters }
    Object.keys(querystring).forEach((key) => {
      querystring[key] = Array.isArray(querystring[key])
        ? querystring[key].join(',')
        : querystring[key]
    })
    const backUrl = `/admin/courses/${instanceId}/analytics?${qs.stringify(
      querystring,
    )}`
    return (
      <AdminLayout.Box>
        <Link
          className="InstanceAnalyticsLearnerPage-back"
          aria-label="Back to Analytics"
          to={backUrl}
        >
          <i
            className="fa fa-angle-left"
            aria-hidden="true"
            title="Back to Analytics"
          />
          <span>Back to Analytics</span>
        </Link>
        <div className="InstanceAnalyticsLearnerPage" id="main-content">
          {isLoadingCourse ||
          isLoadingLearner ||
          isLoadingLearners ||
          isLoadingSettings ? (
            <Loader text="Loading learner..." />
          ) : (
            this.renderBody()
          )}
        </div>
        <NewMessageModal
          error={
            newMessageModal && newMessageModal.error
              ? newMessageModal.error
              : null
          }
          exactRecipient
          isOpen={!!newMessageModal}
          isSaving={isSavingThread}
          onClose={() => this.closeModal('newMessageModal')}
          onSave={this.onSaveThread}
          onSaveCallback
          recipient={
            newMessageModal && newMessageModal.recipient
              ? newMessageModal.recipient
              : null
          }
        />
      </AdminLayout.Box>
    )
  }
}
