import React, { Component, Children } from 'react'
import cx from 'classnames'
import _ from 'lodash'
import { TransitionHeight } from '@/components/animations'

import './Accordion.scss'

export default class Accordion extends Component {
  static defaultProps = {
    multi: false,
    active: [],
    isSimple: false,
    onChange: () => {},
  }

  handleItemChange = (item) => {
    let active = []
    if (this.props.multi) {
      active = _.includes(this.props.active || [], item)
        ? this.props.active.filter((id) => id !== item)
        : [...this.props.active, item]
    } else {
      active = this.props.active && this.props.active[0] === item ? [] : [item]
    }

    this.props.onChange(active)
  }

  renderItems = (child) => {
    if (!child) return null
    return React.cloneElement(child, {
      // NOTE / TODO / WIP - isProxy is specifically for making Accordion work with ScrollBar
      // Accordion needs to be refactored
      active: !child.props.isProxy
        ? _.includes(this.props.active || [], child.props.id)
        : this.props.active,
      onChange: this.handleItemChange,
      key: child.props.id,
    })
  }

  render() {
    const accordionClassName = cx(
      'Accordion',
      {
        'is-simple': this.props.isSimple,
      },
      this.props.className,
    )

    return (
      <div className={accordionClassName}>
        {Children.map(this.props.children, this.renderItems)}
      </div>
    )
  }
}

export const AccordionItem = (props) => {
  const className = cx(
    'Accordion-item',
    {
      'is-active': props.active,
      'has-dots': props.hasDots,
    },
    props.className,
  )

  const elId = _.kebabCase(`${props.primaryLabel}-${props.id}`)
  const controlsId = `${elId}-content`
  return (
    <section className={className} id={props.cid || elId}>
      <button
        className="Accordion-button"
        qa-id="learner-unit-accordion-button"
        aria-controls={controlsId}
        aria-expanded={props.active ? 'true' : 'false'}
        aria-label={
          !props.active
            ? props.primaryLabel || 'Expand'
            : props.primaryLabel || 'Collapse'
        }
        onClick={() => props.onChange(props.id)}
      >
        <div className="columns is-mobile">
          <div className="column">
            <div className="Accordion-leftLabel">{props.primaryLabel}</div>
            {props.content && (
              <div className="is-hidden-tablet is-hidden-desktop">
                {props.content && props.content()}
              </div>
            )}
          </div>
          {props.content && (
            <div className="column is-hidden-mobile">
              {props.content && props.content()}
            </div>
          )}
          {props.secondaryLabel && (
            <div className="column has-text-right">
              <div className="Accordion-rightLabel">{props.secondaryLabel}</div>
            </div>
          )}
        </div>

        <i
          className="fa fa-angle-down Accordion-caret"
          aria-hidden="true"
          title={cx({ Expand: !props.active, Collapse: props.active })}
        />
      </button>
      <TransitionHeight in={props.active}>
        <div id={controlsId} className="Accordion-content">
          {props.children}
        </div>
      </TransitionHeight>
    </section>
  )
}
