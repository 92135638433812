import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import { Button, Heading } from '@/components'
import { ACTIVITY_COMPLETED_CORRECT } from '@/constants'
import AnimatedActivityIcon from './AnimatedActivityIcon/AnimatedActivityIcon'
import './ActivityCompleted.scss'

const ActivityCompletedMessages = defineMessages({
  goodToKnow: {
    defaultMessage: 'Feedback:',
    description:
      'Label for relevant information displayed on the activity feedback screen after answering an activity correctly.',
    id: 'ActivityCompleted.goodToKnow',
  },
  hideQuestion: {
    defaultMessage: 'Hide Question',
    description:
      'Button to hide the activity question on the activity feedback screen after answering an activity correctly.',
    id: 'ActivityCompleted.hideQuestion',
  },
  showQuestion: {
    defaultMessage: 'Show Question',
    description:
      'Button to show the activity question on the activity feedback screen after answering an activity correctly.',
    id: 'ActivityCompleted.showQuestion',
  },
  streakText: {
    defaultMessage: 'Correct!',
    description:
      'Title on the activity feedback screen after answering an activity correctly.',
    id: 'ActivityCompleted.streakText',
  },
  streakTextNCorrect: {
    defaultMessage: '{streak} right in a row!',
    description:
      'Title on the activity feedback screen after answering an activity correctly multiple times in a row.',
    id: 'ActivityCompleted.streakTextNCorrect',
  },
  streakTextSecond: {
    defaultMessage: 'Correct again!',
    description:
      'Title on the activity feedback screen after answering an activity correctly two times in a row.',
    id: 'ActivityCompleted.streakTextSecond',
  },
})

@injectIntl
export default class ActivityCompleted extends Component {
  getStreakText() {
    const { intl, streak } = this.props
    const { formatMessage } = intl
    if (streak >= 3) {
      return formatMessage(ActivityCompletedMessages.streakTextNCorrect, {
        streak,
      })
    } else if (streak === 2) {
      return formatMessage(ActivityCompletedMessages.streakTextSecond)
    }
    return formatMessage(ActivityCompletedMessages.streakText)
  }
  renderMain() {
    const { type } = this.props
    if (type === ACTIVITY_COMPLETED_CORRECT) {
      return (
        <div
          aria-live="assertive"
          className="ActivityCompleted-text"
          qa-id="learner-activity-completed-text"
          role="alert"
        >
          <h1>{this.getStreakText()}</h1>
        </div>
      )
    }
    return null
  }
  renderBody() {
    const { goodToKnow, intl, type } = this.props
    const { formatMessage } = intl
    if (type === ACTIVITY_COMPLETED_CORRECT && goodToKnow) {
      return (
        <div className="ActivityCompleted-goodToKnow">
          <span className="ActivityCompleted-goodToKnowPrefix">
            {formatMessage(ActivityCompletedMessages.goodToKnow)}&nbsp;
          </span>
          <div
            className="ActivityCompleted-goodToKnowContent content"
            dangerouslySetInnerHTML={{ __html: goodToKnow }}
          />
        </div>
      )
    }
    return null
  }
  renderIcon() {
    const { isWrong, streak } = this.props
    return <AnimatedActivityIcon isWrong={isWrong} streak={streak} />
  }
  renderAdjustingPath() {
    const { streak, type } = this.props
    if (type !== ACTIVITY_COMPLETED_CORRECT) return null
    return (
      <div
        className={cx('ActivityCompleted__adjusting-path', {
          'is-max-streak': streak >= 9,
          'is-eight-streak': streak === 8,
          'is-seven-streak': streak === 7,
          'is-six-streak': streak === 6,
          'is-five-streak': streak === 5,
          'is-four-streak': streak === 4,
          'is-three-streak': streak === 3,
          'is-two-streak': streak === 2,
          'is-one-streak': streak === 1,
          'is-streak': streak > 0,
        })}
      >
        <Heading is5 role="status">
          <span className="ActivityCompleted__adjusting-path-copy">
            Adjusted learning path
          </span>
        </Heading>
        <div className="ActivityCompleted__adjusting-path-progress" />
      </div>
    )
  }
  renderLearningObjectives() {
    const { learningObjectives } = this.props
    if (!learningObjectives || !learningObjectives.length) return
    return (
      <div className="ActivityCompleted__learning-objectives">
        <span className="ActivityCompleted__learning-objectives-label">
          Learning Objectives:
        </span>
        <ul className="ActivityCompleted__learning-objectives-list">
          {learningObjectives.map((lo, index) => (
            <li
              className="ActivityCompleted__learning-objectives-list-item"
              key={`ActivityCompleted__learning-objectives-list-item-${index}`}
            >
              * {lo}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  render() {
    const { intl, isWrong, onClickShowQuestion, renderActivity } = this.props
    const { formatMessage } = intl
    const className = cx('ActivityCompleted', {
      'is-activity-open': !!renderActivity,
      'is-wrong': isWrong,
    })
    return (
      <div className={className}>
        <div
          className="ActivityCompleted-content"
          qa-id="learner-activity-completed"
        >
          {this.renderMain()}
          {this.renderIcon()}
          {this.renderAdjustingPath()}
          {this.renderBody()}
          {this.renderLearningObjectives()}
          {onClickShowQuestion ? (
            <Button
              className="ActivityCompleted-showQuestion"
              isText
              onClick={onClickShowQuestion}
              qa-id="learner-activity-completed-show-question-button"
              text={
                !renderActivity
                  ? formatMessage(ActivityCompletedMessages.showQuestion)
                  : formatMessage(ActivityCompletedMessages.hideQuestion)
              }
            />
          ) : null}
        </div>
        {renderActivity ? (
          <div className="ActivityCompleted-question">{renderActivity}</div>
        ) : null}
      </div>
    )
  }
}
