import React, { Fragment } from 'react'
import { Provider } from 'mobx-react'
// import DevTools from 'mobx-react-devtools'
import Routes from './Routes'
import AppStore from './stores/appStore'
import NotificationContainer from './NotificationContainer'

// import esLAS from '@/locales/es-LAS'

// initialize vendor scripts
// NOTE: temp disabled - https://headware4.jira.com/browse/FM-4463
// import MathJax from './MathJax'
// MathJax()
// import feedbackify from './feedbackify'
// feedbackify()

import { IS_DEV } from '@/constants'
import GoogleTagManager from '@/utils/tagManager'

const appStore = new AppStore()

if (IS_DEV) {
  window.appStore = appStore
}

const App = () => {
  const useGtm =
    !IS_DEV &&
    process.env.GTM_ID !== undefined &&
    process.env.GTM_ID !== null &&
    process.env.GTM_ID !== ''
  return (
    <Provider {...appStore.stores}>
      <Fragment>
        <NotificationContainer />
        {useGtm && <GoogleTagManager gtmId={process.env.GTM_ID} />}
        {/* { IS_DEV && <DevTools /> } */}
        <Routes />
      </Fragment>
    </Provider>
  )
}

export default App
