import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'

import { LightBulbIcon, Loader } from '@/components'

import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'

import InstanceOverviewPageLearnerCard from './InstanceOverviewPageLearnerCard'
import './InstanceOverviewPage.scss'

const emptyOverviewData = {
  learnerRankCounts: {
    allStar: 0,
    good: 0,
    atRisk: 0,
    testedOut: 0,
  },
  learnerProgressCounts: {
    completed: 0,
    noProgress: 0,
    progress: 0,
  },
  contentMetricCounts: {
    allStar: 0,
    good: 0,
    atRisk: 0,
  },
}

@inject('adminStore', 'uiStore')
@observer
export default class InstanceOverviewPage extends Component {
  get fillColors() {
    const { uiStore } = this.props
    const isContrastMode = uiStore.getContrastMode()
    return {
      allStar: !isContrastMode ? '#00C479' : '#008750',
      atRisk: !isContrastMode ? '#D7272F' : '#c70842',
      good: !isContrastMode ? '#479DD9' : '#017D98',
    }
  }
  componentDidMount() {
    const { adminStore, match, uiStore } = this.props
    const { params } = match
    const { instanceId } = params
    uiStore.updateTitle('Course Overview Page')

    this.courseId = instanceId
    adminStore.loadOverview({ courseId: this.courseId })
  }
  renderEmptyStateBanner() {
    const { adminStore } = this.props
    const { instance, overviewData } = adminStore
    const { learnersCount } = instance
    const { contentMetricCounts, learnerProgressCounts } =
      overviewData || emptyOverviewData
    const { completed: completedCount, progress: inProgressCount } =
      learnerProgressCounts
    const {
      allStar: predictiveCount,
      atRisk: problematicCount,
      good: effectiveCount,
    } = contentMetricCounts
    let emptyBannerCopy = ''
    const hasNoLearnersAndNoProgress =
      learnersCount === 0 && completedCount === 0 && inProgressCount === 0
    const hasLearnersButNoProgress =
      completedCount === 0 && inProgressCount === 0 && learnersCount > 0
    const hasContentMetrics =
      predictiveCount > 0 || effectiveCount > 0 || problematicCount > 0
    const hasContentMetricsButNoLearners =
      learnersCount === 0 && hasContentMetrics
    const hasContentMetricsAndLearnersButNoProgress =
      hasLearnersButNoProgress && hasContentMetrics

    if (hasNoLearnersAndNoProgress) {
      emptyBannerCopy =
        'There are currently no learners in this course. Enroll learners to start viewing analytics.'
    }

    if (!hasNoLearnersAndNoProgress && !hasContentMetrics) {
      emptyBannerCopy =
        'More learners need to engage with the course to calculate Content Performance.'
    }

    if (hasLearnersButNoProgress) {
      emptyBannerCopy =
        'More learners need to engage with the course to calculate Learner Performance and Content Performance.'
    }

    if (hasContentMetricsButNoLearners) {
      emptyBannerCopy =
        'Content Performance are from previously enrolled learners. Add learners to view Learner Performance and Learner Progress.'
    }

    if (hasContentMetricsAndLearnersButNoProgress) {
      emptyBannerCopy =
        'Content Performance are from previously enrolled learners. New learners added have not engaged enough with the course to calculate Learner Performance.'
    }

    if (
      hasNoLearnersAndNoProgress ||
      hasLearnersButNoProgress ||
      !hasContentMetrics
    ) {
      return (
        <div className="InstanceOverviewPage-emptyStateBanner">
          <span>{emptyBannerCopy}</span>
        </div>
      )
    }

    return null
  }
  renderLearnerPerformance() {
    const { adminStore } = this.props
    const { overviewData } = adminStore
    const { learnerRankCounts } = overviewData || emptyOverviewData
    const totalLearners = Object.keys(learnerRankCounts).reduce(
      (acc, rank) => acc + learnerRankCounts[rank],
      0,
    )
    const {
      allStar: allStarCount,
      good: proficientCount,
      atRisk: unengagedCount,
      testedOut: testedOutCount,
    } = learnerRankCounts
    const percentAllStar = Math.round((100 * allStarCount) / totalLearners)
    const percentProficient = Math.round(
      (100 * proficientCount) / totalLearners,
    )
    const percentUnengaged = testedOutCount
      ? Math.round((100 * unengagedCount) / totalLearners)
      : 100 - percentAllStar - percentProficient
    const percentTestedOut =
      100 - percentAllStar - percentProficient - percentUnengaged
    const {
      allStar: allStarFillColor,
      atRisk: atRiskFillColor,
      good: goodFillColor,
    } = this.fillColors
    const hasTestedOutLearners = !!parseInt(testedOutCount)
    const data = [
      {
        className: 'is-success',
        fill: allStarFillColor,
        label: 'All Star',
        name: `${percentAllStar}%`,
        rank: 'All Star',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&rank=all-star`,
        value: allStarCount,
      },
      {
        className: 'is-info',
        fill: goodFillColor,
        label: 'Proficient',
        name: `${percentProficient}%`,
        rank: 'Proficient',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&rank=good`,
        value: proficientCount,
      },
      {
        className: 'is-danger',
        fill: atRiskFillColor,
        label: 'Unengaged',
        name: `${percentUnengaged}%`,
        rank: 'Unengaged',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&rank=at-risk`,
        value: unengagedCount,
      },
    ]
    if (hasTestedOutLearners) {
      data.push({
        className: 'is-danger',
        fill: '#424242',
        label: 'Tested Out',
        name: `${percentTestedOut}%`,
        rank: 'Tested Out',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&rank=`,
        value: testedOutCount,
      })
    }
    return (
      <InstanceOverviewPageLearnerCard
        cardHeading="Learner Performance"
        cardInfo="Scores, confidence and behavior are analyzed to surface the risk of not applying the material covered. Unengaged learners are flagged because of poor performance."
        cardLink={`/admin/courses/${this.courseId}/analytics`}
        data={data}
      />
    )
  }
  renderLearnerProgress() {
    const { adminStore } = this.props
    const { overviewData } = adminStore
    const { learnerProgressCounts, learnerRankCounts } =
      overviewData || emptyOverviewData
    const { testedOut: testedOutCount } = learnerRankCounts
    const totalLearners = Object.keys(learnerProgressCounts).reduce(
      (acc, key) => acc + learnerProgressCounts[key],
      0,
    )
    const {
      completed: completedCount,
      progress: inProgressCount,
      noProgress: noProgressCount,
    } = learnerProgressCounts
    const percentCompleted = Math.round(
      (100 * learnerProgressCounts.completed) / totalLearners,
    )
    const percentInProgress = Math.round(
      (100 * learnerProgressCounts.progress) / totalLearners,
    )
    const percentNoProgress = 100 - percentCompleted - percentInProgress
    const {
      allStar: allStarFillColor,
      atRisk: atRiskFillColor,
      good: goodFillColor,
    } = this.fillColors
    const data = [
      {
        className: 'is-success',
        fill: allStarFillColor,
        label: 'Completed',
        name: `${percentCompleted}%`,
        rank: 'Completed',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&progress=completed`,
        value: completedCount,
      },
      {
        className: 'is-info',
        fill: goodFillColor,
        label: 'In Progress',
        name: `${percentInProgress}%`,
        rank: 'In Progress',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&progress=progress`,
        value: inProgressCount,
      },
      {
        className: 'is-danger',
        fill: atRiskFillColor,
        label: 'No Progress',
        name: `${percentNoProgress}%`,
        rank: 'No Progress',
        to: `/admin/courses/${this.courseId}/analytics?limit=10&offset=0&progress=no-progress`,
        value: noProgressCount,
      },
    ]
    return (
      <InstanceOverviewPageLearnerCard
        cardHeading="Learner Progress"
        cardInfo="Learners are grouped based on their progress in this course."
        cardLink={`/admin/courses/${this.courseId}/analytics`}
        data={data}
        hasPlaceholder={!!parseInt(testedOutCount)}
      />
    )
  }
  renderQuestionMetrics() {
    const { adminStore } = this.props
    const { overviewData } = adminStore
    const { contentMetricCounts, learnerRankCounts } =
      overviewData || emptyOverviewData
    const { testedOut: testedOutCount } = learnerRankCounts
    const totalQuestions = Object.keys(contentMetricCounts).reduce(
      (acc, key) => acc + contentMetricCounts[key],
      0,
    )
    const {
      allStar: predictiveCount,
      atRisk: problematicCount,
      good: effectiveCount,
    } = contentMetricCounts
    const percentsAllStar = Math.round((100 * predictiveCount) / totalQuestions)
    const percentsGood = Math.round((100 * effectiveCount) / totalQuestions)
    const percentsAtRisk = 100 - percentsAllStar - percentsGood
    const {
      allStar: allStarFillColor,
      atRisk: atRiskFillColor,
      good: goodFillColor,
    } = this.fillColors
    const data = [
      {
        className: 'is-success',
        fill: allStarFillColor,
        label: 'Predictive',
        name: `${percentsAllStar}%`,
        rank: 'Predictive',
        to: `/admin/courses/${this.courseId}/content?ranks=all-star`,
        value: predictiveCount,
      },
      {
        className: 'is-info',
        fill: goodFillColor,
        label: 'Effective',
        name: `${percentsGood}%`,
        rank: 'Effective',
        to: `/admin/courses/${this.courseId}/content?ranks=good`,
        value: effectiveCount,
      },
      {
        className: 'is-danger',
        fill: atRiskFillColor,
        label: 'Problematic',
        name: `${percentsAtRisk}%`,
        rank: 'Problematic',
        to: `/admin/courses/${this.courseId}/content?ranks=at-risk`,
        value: problematicCount,
      },
    ]
    return (
      <InstanceOverviewPageLearnerCard
        cardHeading="Content Performance"
        cardInfo="Problematic questions are not moving learners towards mastery. Effective questions are behaving as expected and helping students at various levels. Predictive questions power the AI engine."
        cardLink={`/admin/courses/${this.courseId}/content`}
        data={data}
        hasPlaceholder={!!parseInt(testedOutCount)}
      />
    )
  }
  renderOverviewMain() {
    const { adminStore } = this.props
    const { instance, isLoadingOverview } = adminStore
    const { isLoadingCourse } = instance

    if (isLoadingCourse || isLoadingOverview) {
      return <Loader />
    }

    return (
      <Fragment>
        {this.renderEmptyStateBanner()}
        {this.renderUserCounts()}
        <div className="InstanceOverviewPage__learner-cards">
          {this.renderLearnerPerformance()}
          {this.renderLearnerProgress()}
          {this.renderQuestionMetrics()}
        </div>
      </Fragment>
    )
  }
  renderUserCount(n, userType) {
    const iconClassName =
      userType === 'Instructors' ? 'fa fa-users' : 'fa fa-wrench'
    return (
      <div className="InstanceOverviewPage__user-count">
        <div className="InstanceOverviewPage__user-count-content">
          <div className="InstanceOverviewPage__icon">
            {userType === 'Learners' ? (
              <LightBulbIcon />
            ) : (
              <i aria-hidden="true" className={iconClassName} />
            )}
          </div>
          <div>
            <span className="InstanceOverviewPage__user-count-content-number">
              {n}
            </span>
            <p>
              {n === 1 ? userType.substring(0, userType.length - 1) : userType}
            </p>
          </div>
        </div>
      </div>
    )
  }
  renderUserCounts() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { adminsCount, instructorsCount, learnersCount } = instance
    return (
      <div className="InstanceOverviewPage__user-counts-wrapper">
        {this.renderUserCount(learnersCount, 'Learners')}
        {this.renderUserCount(instructorsCount, 'Instructors')}
        {this.renderUserCount(adminsCount, 'Admins')}
      </div>
    )
  }
  render() {
    return (
      <AdminLayout className="InstanceOverviewPage">
        <AdminLayout.Main id="main-content" isFullWidth>
          {this.renderOverviewMain()}
        </AdminLayout.Main>
      </AdminLayout>
    )
  }
}
