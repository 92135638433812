import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Exam, Loader } from '@/components'
import { StudentLayout } from '@/layouts'
import { Redirect } from 'react-router-dom'
import './ExamPage.scss'

@inject('contentStore', 'uiStore')
@observer
export default class ExamPage extends Component {
  state = {
    isLoading: true,
  }
  componentDidMount() {
    const { contentStore, uiStore } = this.props
    const { exam } = contentStore
    if (!exam) {
      this.loadExamStatus()
    } else {
      this.setState({ isLoading: false })
    }
    uiStore.updateTitle('Exam Page')
  }
  loadExamStatus = () => {
    const { contentStore } = this.props
    contentStore.loadExamStatus().then(() => {
      this.setState({ isLoading: false })
    })
  }
  render() {
    const { contentStore } = this.props
    const { isLoading } = this.state
    const { courseProgress, settings } = contentStore
    const { exam: hasExamSetting } = settings || {}
    if (!hasExamSetting || courseProgress !== 100)
      return <Redirect to="/dashboard" />
    return (
      <StudentLayout.Page className="ExamPage">
        <div className="ExamPage__inner-wrapper">
          {isLoading ? <Loader /> : <Exam />}
        </div>
      </StudentLayout.Page>
    )
  }
}
