import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import { inject } from 'mobx-react'

import { Button } from '@/components'

const LocaleSelectorButtonMessages = defineMessages({
  buttonText: {
    defaultMessage: 'Select a Language',
    description:
      'Button to open the Locale Selector Modal from the Account Page.',
    id: 'LocaleSelectorButton.buttonText',
  },
})

@injectIntl
@inject('uiStore')
export default class LocaleSelectorButton extends Component {
  onClickOpenLocaleSelectorModal = () => {
    const { uiStore } = this.props
    const { isOpenLocaleSelectorModal } = uiStore || {}
    if (isOpenLocaleSelectorModal) return
    uiStore.openLocaleSelectorModal()
  }
  render() {
    const { className, intl, uiStore } = this.props
    const { formatMessage } = intl
    const { isOpenLocaleSelectorModal } = uiStore || {}
    return (
      <Button
        className={cx('LocaleSelectorButton', className)}
        isDisabled={isOpenLocaleSelectorModal}
        onClick={this.onClickOpenLocaleSelectorModal}
      >
        {formatMessage(LocaleSelectorButtonMessages.buttonText)}
      </Button>
    )
  }
}
