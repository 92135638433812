import { observable, action, computed } from 'mobx'
import humps from 'lodash-humps'

import request from '@/utils/request'
import {
  RANK_ALL_STAR,
  RANK_PROFICIENT,
  REATTEMPT_STATUS_FINISHED,
  REATTEMPT_STATUS_READY,
  REATTEMPT_STATUS_STARTED,
} from '@/constants'
import SentryReplay from '@/SentryReplay'
import { toCamelCase } from '@/utils/helpers'
import { replaceMathFormulas } from '@/utils/math'

import Activity from './activity'
import Quiz from './quiz'

export const WATCH_TAB = 'watch'
export const READ_TAB = 'read'
export const PRACTICE_TAB = 'practice'
export const QUIZ_TAB = 'quiz'

const activityNullError = {
  title: 'A course administrator has disabled this activity.',
  message: 'Reach out to your instructor if you have any questions.',
}

export default class Section {
  @observable index

  @observable id
  @observable title
  @observable description
  @observable unitId
  @observable courseId

  @observable hasQuiz = false
  @observable quiz = null
  @observable masteredQuiz = false

  @observable videoId
  @observable video

  @observable activitiesCounters = null
  @observable activityIds

  @observable attempt = null
  @observable completed
  @observable mastered
  @observable pretestMastered
  @observable rank = null
  @observable reattemptRank = null
  @observable reattemptStatus = null
  @observable requiredMasteryLevel
  @observable locked = true
  @observable score = 0
  @observable prevScore = 0
  @observable totalScore = 0

  @observable streak = 0

  @observable activity
  @observable activityTimestamp
  @observable nextActivity
  @observable isLoadingActivity
  @observable error
  @observable isIntro = false
  @observable isLaunching = false
  @observable isReview = false
  @observable isReviewDone = false
  @observable isReviewOverride = false
  @observable isSavingActivity = false
  @observable isSkippingActivity = false
  // NOTE / TODO / WIP - areActivitiesDone is redundant, use isMastered
  // @observable areActivitiesDone

  @observable lectureNotes
  @observable hasReplaceLectureNotesKalturaUrls = false

  @observable unit
  @observable course

  constructor(data) {
    this.init(data)
    // this.resetKeepPracticing()
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get isCompleted() {
    return !!this.completed
  }

  @computed get isMastered() {
    return !!this.mastered
  }

  @computed get isMasteredPretest() {
    return this.course && this.course.snapshotTestOut && !!this.pretestMastered
  }

  @computed get isMasteredWithPretest() {
    return this.isMastered || this.isMasteredPretest
  }

  @computed get isMasteredWithPretestAndReattempts() {
    return (
      this.isMasteredPretest ||
      (this.isMastered &&
        this.isRankPass &&
        this.reattemptStatus !== REATTEMPT_STATUS_STARTED)
    )
  }

  @computed get isRankPass() {
    return (
      this.rank === RANK_PROFICIENT ||
      this.rank === RANK_ALL_STAR ||
      this.reattemptRank === RANK_PROFICIENT ||
      this.reattemptRank === RANK_ALL_STAR
    )
  }

  @computed get isReattempt() {
    const { isEncourageMastery, isRequireMastery } = this.course
    return (
      (isEncourageMastery || isRequireMastery) &&
      this.isMastered && // is mastered
      !this.isMasteredPretest && // but did not master via pre-test
      !this.isRankPass // and does not have passing rank or reattempt rank
    )
  }

  @computed get progress() {
    return Math.round((this.score / this.totalScore) * 100)
  }

  @computed get mastery() {
    const { isEncourageMastery, isRequireMastery } = this.course
    const { masteryActivities, reattemptActivities, sectionActivities } = this
      .activitiesCounters || {
      masteryActivities: 1,
      reattemptActivities: 0,
      sectionActivities: 1,
    }
    if (
      this.pretestMastered ||
      (!isEncourageMastery &&
        this.reattemptStatus === REATTEMPT_STATUS_FINISHED)
    )
      return 100
    const numReattemptActivities =
      masteryActivities > sectionActivities
        ? sectionActivities
        : masteryActivities
    const reattemptActivitiesCount = reattemptActivities || 0
    if (isRequireMastery && this.reattemptStatus === REATTEMPT_STATUS_STARTED)
      return Math.round(
        (100 * reattemptActivitiesCount) / numReattemptActivities,
      )
    return this.mastered
      ? 100
      : Math.round(
          (100 * (this.score || 0)) / Math.min(this.requiredMasteryLevel, 100),
        )
  }

  @computed get partialProgress() {
    return Math.round(((this.score - this.prevScore) / this.totalScore) * 100)
  }

  @computed get hasVideo() {
    return !!this.videoId
  }

  @computed get hasNextActivity() {
    return this.nextActivity && this.nextActivity.isNewActivity
  }

  @computed get tabs() {
    const baseUrl = `/units/${this.unitId}/sections/${this.id}`
    const practiceTab = {
      id: PRACTICE_TAB,
      text: 'Practice',
      href: `${baseUrl}/${PRACTICE_TAB}`,
    }
    const videoTab = {
      id: WATCH_TAB,
      text: 'Watch',
      href: `${baseUrl}/${WATCH_TAB}`,
    }
    const readTab = {
      id: READ_TAB,
      text: 'Read',
      href: `${baseUrl}/${READ_TAB}`,
      'aria-label': 'Reed',
    }
    const quizTab = {
      id: QUIZ_TAB,
      text: 'Quiz',
      href: `${baseUrl}/${QUIZ_TAB}`,
      'aria-label': 'Quiz',
    }
    const tabs = [practiceTab]
    if (this.hasQuiz) tabs.push(quizTab)
    if (this.lectureNotes) tabs.unshift(readTab)
    if (this.hasVideo) tabs.unshift(videoTab)
    return tabs
  }

  @computed get hasPassedQuiz() {
    return !!this.masteredQuiz
  }

  @action launchSection(
    courseContentfulId,
    unitContentfulId,
    sectionContentfulId,
  ) {
    if (this.isLaunching) return
    this.isLaunching = true
    return request
      .get(
        `/content/${courseContentfulId}/units/${unitContentfulId}/sections/${sectionContentfulId}`,
      )
      .then(this.handleLaunchSection)
      .catch(this.handleLaunchSectionError)
  }
  @action handleLaunchSection = (response) => {
    const { data: responseData } = response
    const {
      activities_counters: activitiesCounters,
      attempt,
      quiz: quizData,
    } = responseData || {}
    const {
      attempt: quizAttempt,
      passed: quizPassed,
      results: quizResults,
      score: quizScore,
      scoreRequired: quizScoreRequired,
      status: quizStatus,
    } = (quizData && humps(quizData)) || {}
    this.activitiesCounters =
      activitiesCounters &&
      this.reattemptStatus &&
      this.reattemptStatus !== REATTEMPT_STATUS_FINISHED
        ? humps(activitiesCounters)
        : this.activitiesCounters
    this.attempt = attempt
    if (quizData) {
      this.quiz = new Quiz({
        attempt: quizAttempt,
        courseId: this.courseId,
        courseTitle: this.course.displayTitle || this.course.title,
        passed: quizPassed,
        results: quizResults,
        score: quizScore,
        scoreRequired: quizScoreRequired,
        sectionId: this.id,
        status: quizStatus,
        unitId: this.unitId,
      })
    }
    this.isLaunching = false
    return this
  }
  @action handleLaunchSectionError = (error) => {
    SentryReplay.captureException(error)
    this.isLaunching = false
    return this
  }

  @action replaceLectureNotesMathFormulas() {
    replaceMathFormulas({
      content: this.lectureNotes,
    }).then((content) => {
      // NOTE - this is not modified content
      // not sure what the intention was here
      this.lectureNotes = content
    })
  }

  @action replaceLectureNotesKalturaUrls() {
    if (!this.lectureNotes) return
    this.lectureNotes = this.lectureNotes.replace(
      new RegExp('http://cdnbakmi', 'g'),
      'https://cdnsecakmi',
    )
  }

  @action loadActivity() {
    if (this.isLoadingActivity) return Promise.resolve(false)

    this.nextActivity = null
    this.error = null
    this.isLoadingActivity = true

    return request
      .get(
        `/activities/${this.courseId}/units/${this.unitId}/sections/${
          this.id
        }?d=${Date.now()}`,
      )
      .then(this.handleLoadActivity)
      .catch(this.handleLoadActivityError)
  }
  @action handleLoadActivity = (response) => {
    if (!response.data || !response.data.activity) {
      return this.handleLoadActivityError(activityNullError)
    }

    const { data } = response
    const { activitiesCounters, activity: activityData } = humps(data)
    const newActivity = new Activity({ ...activityData })

    this.activity = newActivity

    const activity = this.activity

    if (
      this.isMastered &&
      this.isReview &&
      !this.isReviewDone &&
      !this.isReviewOverride &&
      activitiesCounters
    ) {
      this.activitiesCounters = activitiesCounters
    }
    if (!this.isMastered || this.isReview) {
      this.activityTimestamp = Date.now()
    }

    this.isLoadingActivity = false
    return Promise.resolve(activity)
  }
  @action handleLoadActivityError = (error) => {
    SentryReplay.captureException(error)
    this.activity = null
    this.nextActivity = null
    this.error = {
      message: error.message || 'Failed to load activity.',
      title: error.title || 'Something went wrong',
    }
    this.isLoadingActivity = false
    return Promise.reject(error)
  }

  @action saveActivity(answer, skip) {
    if (this.isSavingActivity)
      return Promise.reject(new Error('Already saving activity;'))
    if (this.isSkippingActivity)
      return Promise.reject(new Error('Already skipping activity;'))
    if (!skip) {
      this.isSavingActivity = true
    } else {
      this.isSkippingActivity = true
    }
    const activity = this.activity
    const { id: activityContentfulId, isMultipleInput } = activity
    if (!activity || !activityContentfulId) {
      return Promise.reject(new Error('No activity;'))
    }
    if (!answer && !skip) {
      this.isSavingActivity = false
      this.isSkippingActivity = false
      return Promise.reject(new Error('No answer;'))
    }
    let POST = {}
    if (skip) {
      POST = { ...POST, skip: true }
    } else if (isMultipleInput) {
      POST = {
        ...POST,
        answers: activity.adaptAnswer(answer),
        current_activity_id: activityContentfulId,
      }
    } else {
      POST = {
        ...POST,
        answer: activity.adaptAnswer(answer),
        current_activity_id: activityContentfulId,
      }
    }
    this.error = null
    activity.validationError = null
    if (this.isMastered && !this.isReview) return
    return request
      .post(
        `/activities/${this.courseId}/units/${this.unitId}/sections/${this.id}/answer`,
        POST,
      )
      .then((response) => this.handleSaveActivity(response, skip))
      .catch(this.handleSaveActivityError)
  }
  @action handleSaveActivity = (response, skipped) => {
    const activity = this.activity
    const data = humps(response.data)
    let showCompletionScreen = false
    let nextActivity
    const prevMasteredUnit = this.unit.isMasteredWithPretest

    if (data.activity) {
      activity.attempt = data.activity.attempt
      activity.skipped = skipped
      nextActivity = new Activity({ ...data.activity })
      this.nextActivity = nextActivity
    } else if (
      // if data section is not defined
      // or if section was not mastered and is still not mastered
      // or section is in review and review is not done and reattempt status exists and is not finished
      // then display save activity null error
      !data.section ||
      (!this.mastered && !data.section.mastered) ||
      (this.isReview &&
        !this.isReviewDone &&
        data.section.reattemptStatus &&
        data.section.reattemptStatus !== REATTEMPT_STATUS_FINISHED)
    ) {
      return this.handleSaveActivityError(activityNullError)
    }

    if (data.response) {
      activity.answerResponse = data.response.answer
      activity.answerResponseLos = data.responseLos
      activity.correctAnswers = data.response.correctAnswers
      activity.incorrectAnswers = data.response.incorrectAnswers
      activity.hint = data.response.hint
      activity.passed = data.response.passed
      if (!activity.isReview) {
        if (this.isMastered && !this.isReview) {
          this.streak = 0
        } else if (activity.hasPassed) {
          if (this.streak < 0) {
            this.streak = 0
          }
          this.streak++
        } else {
          if (this.streak > 0) {
            this.streak = 0
          }
          this.streak--
        }
      }
      activity.isNewActivity = false
    }

    if (
      this.isMastered &&
      this.isReview &&
      !this.isReviewOverride &&
      data.activitiesCounters
    ) {
      this.activitiesCounters = data.activitiesCounters
    }

    if (data.section && data.section.sectionId === this.id) {
      const { score, completed, mastered, reattemptStatus } = data.section
      if (
        this.isReview &&
        !this.isReviewOverride &&
        reattemptStatus === REATTEMPT_STATUS_FINISHED
      ) {
        this.isReviewDone = true
      }
      this.prevScore = this.score
      this.score = score
      this.completed = completed
      this.reattemptStatus = reattemptStatus
      this.mastered = mastered
    }

    if (data.unit && data.unit.unitId === this.unit.id) {
      const { completed, mastered } = data.unit
      this.unit.completed = completed
      this.unit.mastered = mastered
      const isMasteredWithPretestUnit = this.unit.isMasteredWithPretest

      // if completed changed and course is completed then show course completion screen
      if (
        !prevMasteredUnit &&
        isMasteredWithPretestUnit &&
        this.course.isMasteredWithPretest
      ) {
        showCompletionScreen = true
      }
    }

    if (activity.hint) activity.replaceHintMathFormulas()
    if (activity.goodToKnow) activity.replaceGoodToKnowMathFormulas()

    this.isSavingActivity = false
    this.isSkippingActivity = false

    return { hasPassed: activity.hasPassed, showCompletionScreen }
  }
  @action handleSaveActivityError = (error) => {
    SentryReplay.captureException(error)
    this.nextActivity = null
    this.error = {
      message: error.message || 'Failed to load activity.',
      title: error.title || 'Something went wrong',
      status: error.response && error.response.status,
    }
    this.isSavingActivity = false
    this.isSkippingActivity = false
    this.handleSaveActivityValidationError(error)
  }
  @action handleSaveActivityValidationError(error) {
    const activity = this.activity
    if (error.response) {
      if (
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.message === 'Validation Error'
      ) {
        activity.passed = false
        activity.validationError = error.response.data.errors
      }
    }
    return Promise.reject(error)
  }

  @action resetActivity() {
    this.activity = null
    this.nextActivity = null
  }
  @action skipActivity() {
    const activity = this.activity
    if (!activity) {
      return Promise.reject(
        new Error(
          JSON.stringify({
            skip: [
              'There was an issue loading the current activity, please reload the page and try again.',
            ],
          }),
        ),
      )
    }
    const { isReview: isReviewActivity } = activity
    if (!isReviewActivity) {
      return Promise.reject(
        new Error(
          JSON.stringify({ skip: ['Only review questions may be skipped.'] }),
        ),
      )
    }
    return this.saveActivity(null, true)
  }

  @action moveToNextActivity() {
    if (!this.nextActivity) return false

    this.activity = this.nextActivity

    this.nextActivity = null

    this.activityTimestamp = Date.now()

    return true
  }

  @action startReview(isReviewOverride = false) {
    // NOTE / TODO / WIP - areActivitiesDone is misleading, use isReview
    // trigger section reattempt flag
    // if (this.rank === 'at-risk') {
    this.isReview = true
    this.isReviewDone = false
    if (isReviewOverride) {
      this.isReviewOverride = true
    } else if (
      this.reattemptStatus === REATTEMPT_STATUS_READY ||
      (this.reattemptStatus === REATTEMPT_STATUS_FINISHED &&
        this.reattemptRank !== RANK_PROFICIENT &&
        this.reattemptRank !== RANK_ALL_STAR)
    ) {
      this.reattemptStatus = REATTEMPT_STATUS_STARTED
      const { masteryActivities, reattemptActivities, sectionActivities } = this
        .activitiesCounters || {
        masteryActivities: 1,
        reattemptActivities: 0,
        sectionActivities: 1,
      }
      if (reattemptActivities > 0) {
        this.activitiesCounters = {
          masteryActivities,
          reattemptActivities: 0,
          sectionActivities,
        }
      }
    }
    // }
    // NOTE / TODO / WIP - set activityTimestamp after clicking keep practicing with activity already loaded
    // this.activityTimestamp = Date.now()
  }

  // NOTE / TODO / WIP - areActivitiesDone is redundant, use isMastered
  // @action resetKeepPracticing () {
  //   if (this.isMastered) {
  //     this.areActivitiesDone = true
  //   }
  // }
}
