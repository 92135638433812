import cx from 'classnames'
import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { Button, Dropdown, Link } from '@/components'
import './LearnerHeader.scss'

const LearnerHeaderMessages = defineMessages({
  closeMobileNavigation: {
    defaultMessage: 'Close navigation menu',
    description:
      'Button to close the mobile navigation from the learner header.',
    id: 'LearnerHeader.closeMobileNavigation',
  },
  courses: {
    defaultMessage: 'My courses',
    description:
      'Button to navigate to the course picker page from the learner header.',
    id: 'LearnerHeader.courses',
  },
  faq: {
    defaultMessage: 'FAQ',
    description: 'Button to navigate to the FAQ page from the learner header.',
    id: 'LearnerHeader.faq',
  },
  glossary: {
    defaultMessage: 'Glossary',
    description: 'Button to open the glossary from the learner header.',
    id: 'LearnerHeader.glossary',
  },
  goToAdmin: {
    defaultMessage: 'Go to Admin',
    description:
      'Description for the button to navigate to the admin platform for screen readers from the learner header.',
    id: 'LearnerHeader.goToAdmin',
  },
  goToHome: {
    defaultMessage: 'Go to the Home page',
    description:
      'Description for the button to navigate to the home page for screen readers from the learner header.',
    id: 'LearnerHeader.goToHome',
  },
  goToMessages: {
    defaultMessage: 'Go to the Messages page',
    description:
      'Description for the button to navigate to the messages page for screen readers from the learner header.',
    id: 'LearnerHeader.goToMessages',
  },
  goToSearch: {
    defaultMessage: 'Go to the Search page',
    description:
      'Description for the button to navigate to the search page for screen readers from the learner header.',
    id: 'LearnerHeader.goToSearch',
  },
  home: {
    defaultMessage: 'Home',
    description: 'Button to navigate to the home page from the learner header.',
    id: 'LearnerHeader.home',
  },
  logout: {
    defaultMessage: 'Logout',
    description: 'Button to log the user out from the learner header.',
    id: 'LearnerHeader.logout',
  },
  messages: {
    defaultMessage: 'Messages',
    description:
      'Button to navigate to the messages page from the learner header.',
    id: 'LearnerHeader.messages',
  },
  openMobileNavigation: {
    defaultMessage: 'Open navigation menu',
    description:
      'Button to open the mobile navigation from the learner header.',
    id: 'LearnerHeader.openMobileNavigation',
  },
  profile: {
    defaultMessage: 'My profile',
    description:
      'Button to navigate to the user profile page from the learner header.',
    id: 'LearnerHeader.profile',
  },
  search: {
    defaultMessage: 'Search',
    description:
      'Button to navigate to the search page from the learner header.',
    id: 'LearnerHeader.search',
  },
})

@injectIntl
@inject('contentStore', 'messagesStore', 'uiStore')
@observer
export default class LearnerHeader extends Component {
  componentDidUpdate() {
    const { contentStore, messagesStore, pathname } = this.props
    const { settings } = contentStore
    const { loadUnreadMessagesCountIntervalId } = messagesStore
    const { isDisabledMessaging } = settings || {}
    if (
      settings &&
      !isDisabledMessaging &&
      !loadUnreadMessagesCountIntervalId
    ) {
      if (!pathname.match(/^\/messages/)) {
        messagesStore.startLoadUnreadMessagesCountInterval()
      }
      messagesStore.loadUnreadMessagesCount()
    }
  }
  componentWillUnmount() {
    const { messagesStore } = this.props
    const { loadUnreadMessagesCountIntervalId } = messagesStore
    if (loadUnreadMessagesCountIntervalId) {
      messagesStore.stopLoadUnreadMessagesCountInterval()
    }
  }
  onSearch = () => {
    const { history } = this.props
    history.push('/search')
  }
  toggleMobileNavMenu = () => {
    const { uiStore } = this.props
    uiStore.toggleMobileNavMenu()
  }
  renderFAQ() {
    const { contentStore, intl } = this.props
    const { course, snapshot } = contentStore
    const { faq } = course || {}
    const { isOpen: isOpenSnapshot } = snapshot || {}
    const { formatMessage } = intl
    if (isOpenSnapshot) return null
    if (faq)
      return (
        <Link target="_blank" href={faq}>
          {formatMessage(LearnerHeaderMessages.faq)}
        </Link>
      )
    return <Link to="/faq">{formatMessage(LearnerHeaderMessages.faq)}</Link>
  }
  renderGlossary() {
    const { contentStore, intl } = this.props
    const { course, snapshot } = contentStore
    const { glossary } = course || {}
    const { isOpen: isOpenSnapshot } = snapshot || {}
    const { formatMessage } = intl
    if (isOpenSnapshot) return null
    if (glossary)
      return (
        <Link target="_blank" href={glossary}>
          {formatMessage(LearnerHeaderMessages.glossary)}
        </Link>
      )
    return null
  }
  render() {
    const {
      contentStore,
      intl,
      isAdminAndLearner,
      messagesStore,
      onClickSwitchPlatform,
      onLogout,
      pathname,
      shortName,
      showCoursePicker,
      uiStore,
    } = this.props
    const {
      isInitializingCourse,
      isLoadingCourse,
      isLoadingCourseSettings,
      settings,
      snapshot,
    } = contentStore
    const { showMobileNavMenu } = uiStore
    const { formatMessage } = intl
    const { isDisabledMessaging } = settings || {}
    const { isOpen: isOpenSnapshot } = snapshot || {}
    const { unreadMessagesCount } = messagesStore
    const isHome = pathname === '/' || pathname === '/dashboard'
    const isMessages = pathname === '/messages' || pathname.match('/messages')
    const isSearch = pathname === '/search'
    return (
      <div className="LearnerHeader">
        {!isInitializingCourse &&
        !isLoadingCourse &&
        !isLoadingCourseSettings ? (
          <div className="Header-iconLinks">
            <div
              className="Header-iconLinkContainer Header-hideMobile"
              qa-id="learner-header-home-button"
            >
              <Link
                alt={formatMessage(LearnerHeaderMessages.home)}
                aria-label={formatMessage(LearnerHeaderMessages.goToHome)}
                className={cx('Header-iconLink', {
                  active: isHome,
                })}
                to="/"
              >
                <i
                  aria-hidden="true"
                  className="fa fa-home"
                  title={formatMessage(LearnerHeaderMessages.home)}
                />
              </Link>
            </div>
            {!isOpenSnapshot ? (
              <div
                className="Header-iconLinkContainer"
                qa-id="button-student-header-search"
              >
                <Link
                  alt={formatMessage(LearnerHeaderMessages.search)}
                  aria-label={formatMessage(LearnerHeaderMessages.goToSearch)}
                  className={cx('Header-iconLink', {
                    active: isSearch,
                  })}
                  to="/search"
                >
                  <i
                    aria-hidden="true"
                    className="fa fa-search"
                    title={formatMessage(LearnerHeaderMessages.search)}
                  />
                </Link>
              </div>
            ) : null}
            {!isDisabledMessaging && !isOpenSnapshot ? (
              <div className="LearnerHeader-messagesContainer Header-iconLinkContainer">
                <Link
                  alt={formatMessage(LearnerHeaderMessages.messages)}
                  aria-label={formatMessage(LearnerHeaderMessages.goToMessages)}
                  className={cx('LearnerHeader-messagesLink Header-iconLink', {
                    active: isMessages,
                  })}
                  to="/messages"
                >
                  <i
                    aria-hidden="true"
                    className="fa fa-comment"
                    title={formatMessage(LearnerHeaderMessages.messages)}
                  />
                  {unreadMessagesCount ? (
                    <span>
                      {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                    </span>
                  ) : null}
                </Link>
              </div>
            ) : null}
            <div className="Header-iconLinkContainer Header-hamburger">
              <Button
                aria-label={
                  showMobileNavMenu
                    ? formatMessage(LearnerHeaderMessages.closeMobileNavigation)
                    : formatMessage(LearnerHeaderMessages.openMobileNavigation)
                }
                className="Header-iconLink"
                isText
                onClick={this.toggleMobileNavMenu}
              >
                <i aria-hidden="true" className="fa fa-bars" />
              </Button>
            </div>
          </div>
        ) : null}
        <div className="Header-metaContainer Header-hideMobile">
          <div
            className="Header-userContainer"
            qa-id="learner-header-dropdown-button"
          >
            <Dropdown ariaLabel="profile menu" label={shortName} isSimple>
              {!isOpenSnapshot ? (
                <Link to="/account">
                  {formatMessage(LearnerHeaderMessages.profile)}
                </Link>
              ) : null}
              {showCoursePicker ? (
                <Link to="/courses">
                  {formatMessage(LearnerHeaderMessages.courses)}
                </Link>
              ) : null}
              {this.renderFAQ()}
              {this.renderGlossary()}
              {isAdminAndLearner ? (
                <Link
                  onClick={onClickSwitchPlatform}
                  to="/"
                  qa-id="learner-go-to-admin-link"
                >
                  {formatMessage(LearnerHeaderMessages.goToAdmin)}
                </Link>
              ) : null}
              <Link to="#" onClick={onLogout}>
                {formatMessage(LearnerHeaderMessages.logout)}
              </Link>
            </Dropdown>
          </div>
        </div>
      </div>
    )
  }
}
