import React, { Component } from 'react'
import cx from 'classnames'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { AnimatedActivityIcon, Button, Heading } from '@/components'
import {
  ACTIVITY_COMPLETED_REATTEMPT,
  ACTIVITY_COMPLETED_SECTION,
  ACTIVITY_COMPLETED_SNAPSHOT,
  RANK_ALL_STAR,
  RANK_PROFICIENT,
  RANK_UNENGAGED,
  REATTEMPT_STATUS_FINISHED,
} from '@/constants'

const Messages = defineMessages({
  reattemptSection: {
    defaultMessage: `No thanks, I'd like to reattempt the Section`,
    description:
      'Button to reattempt a section that has been completed and is at risk on the mastered section screen.',
    id: 'SectionCompleted.reattemptSection',
  },
  reviewSection: {
    defaultMessage: 'Review Section',
    description:
      'Button to continue practicing within a mastered section on the mastered section screen.',
    id: 'SectionCompleted.reviewSection',
  },
  sectionCompletedBody: {
    defaultMessage:
      'You are now ready to move on to the next Section. Keep in mind, you can always come back and review this material at anytime.',
    description:
      'Description displayed on the practice tab on the mastered section screen with Proficient or higher rank on the first attempt.',
    id: 'SectionCompleted.sectionCompletedBody',
  },
  sectionCompletedBodyAfterCourseMastered: {
    defaultMessage:
      'Excellent work! Keep in mind, you can always review this material at anytime.',
    description:
      'Description displayed on the practice tab on the mastered section screen with Proficient or higher rank on the first attempt after Course has been mastered.',
    id: 'SectionCompleted.sectionCompletedBodyAfterCourseMastered',
  },
  sectionCompletedBodyAtRisk: {
    defaultMessage: `We're noticing you're having some difficulties with this Section. Research shows it is best to take a break and come back to it later. In the meantime, let's move on to the next Section of the course.`,
    description:
      'Description displayed on the practice tab when completing a section with an unengaged rank.',
    id: 'SectionCompleted.sectionCompletedBodyAtRisk',
  },
  sectionCompletedBodyAtRiskLast: {
    defaultMessage: `You're so close to achieving 100% mastery of this course. We suggest taking a deeper dive into Read and Watch before heading back into Practice. Take a break and then come back to this Section for another go-around.`,
    description:
      'Description displayed on the practice tab on the section completed screen when the section is at risk and is the last section that requires a reattempt.',
    id: 'SectionCompleted.sectionCompletedBodyAtRiskLast',
  },
  sectionCompletedBodyAtRiskAfterCourseMastered: {
    defaultMessage: `We're noticing you're having some difficulties with this Section. Research shows it is best to take a break and come back to it later.`,
    description:
      'Description displayed on the practice tab when completing a section with an unengaged rank and course has been completed.',
    id: 'SectionCompleted.sectionCompletedBodyAtRiskAfterCourseMastered',
  },
  sectionCompletedBodyAtRiskMasteryRequired: {
    defaultMessage: 'This Section must be mastered to complete the course.',
    description:
      'Last sentence of description displayed on the practice tab when completing a section with an unengaged rank with mastery required.',
    id: 'SectionCompleted.sectionCompletedBodyAtRiskMasteryRequired',
  },
  sectionCompletedBodyEncourage: {
    defaultMessage: 'You are now ready to move on to the next Section.',
    description:
      'Description displayed on the practice tab on the mastered section screen with Proficient or higher rank on the first attempt.',
    id: 'SectionCompleted.sectionCompletedBodyEncourage',
  },
  sectionCompletedTitle: {
    defaultMessage: `You've completed {sectionTitle}`,
    description:
      'Title displayed on the practice tab on the mastered section screen.',
    id: 'SectionCompleted.sectionCompletedTitle',
  },
  sectionCompletedAtRiskTitle: {
    defaultMessage: 'Take a break from {sectionTitle}',
    description:
      'Title displayed on the practice tab when completing a section with an unengaged rank.',
    id: 'SectionCompleted.sectionCompletedAtRiskTitle',
  },
  sectionCompletedAtRiskTitleLast: {
    defaultMessage: 'Almost there!',
    description:
      'Title displayed on the practice tab when completing a section with an unengaged rank that is the last section that requires a reattempt.',
    id: 'SectionCompleted.sectionCompletedAtRiskTitleLast',
  },
  sectionReattemptBody: {
    defaultMessage:
      'Excellent job! You have successfully cleared out this Section from your Pathway to Mastery. Your patience and perseverance paid off.',
    description:
      'Description displayed on the practice tab on the reattempt completed screen.',
    id: 'SectionCompleted.sectionReattemptBody',
  },
  sectionReattemptBodyAtRisk: {
    defaultMessage: `It looks like you're still having some difficulties with this Section. We suggest taking a deeper dive into Read and Watch before heading back into Practice. Take a break and then come back to this Section for another go-around.`,
    description:
      'Description displayed on the practice tab on the reattempt completed screen when the reattempt is at risk.',
    id: 'SectionCompleted.sectionReattemptBodyAtRisk',
  },
  sectionReattemptBodyAtRiskEncourage: {
    defaultMessage: `You are now ready to move on to the next Section. However, you may still reattempt this Section from Pathway to Mastery.`,
    description:
      'Description displayed on the practice tab when completing a section with an unengaged rank.',
    id: 'SectionCompleted.sectionReattemptBodyAtRiskEncourage',
  },
  sectionReattemptBodyAtRiskLast: {
    defaultMessage: `You're so close to achieving 100% mastery of this course. We suggest taking a deeper dive into Read and Watch before heading back into Practice. Take a break and then come back to this Section for another go-around.`,
    description:
      'Description displayed on the practice tab on the reattempt completed screen when the reattempt is at risk and is the last section that requires a reattempt.',
    id: 'SectionCompleted.sectionReattemptBodyAtRiskLast',
  },
  sectionReattemptBodyHasAtRisk: {
    defaultMessage:
      'Excellent job! You have successfully cleared out this Section from your Pathway to Mastery. Your patience and perseverance paid off. Please complete any remaining Sections in your Pathway to Mastery to complete the course.',
    description:
      'Description displayed on the practice tab on the reattempt completed screen and there are sections at risk remaining and mastery is required.',
    id: 'SectionCompleted.sectionReattemptBodyHasAtRisk',
  },
  sectionReattemptBodyHasAtRiskEncourage: {
    defaultMessage:
      'Excellent job! You have successfully cleared out this Section from your Pathway to Mastery. Your patience and perseverance paid off.',
    description:
      'Description displayed on the practice tab on the reattempt completed screen and there are sections at risk remaining and mastery is encouraged.',
    id: 'SectionCompleted.sectionReattemptBodyHasAtRiskEncourage',
  },
  sectionReattemptTitle: {
    defaultMessage: `You've completed {sectionTitle}`,
    description:
      'Title displayed on the practice tab on the reattempt completed screen with a rank of proficient or better.',
    id: 'SectionCompleted.sectionReattemptTitle',
  },
  sectionReattemptTitleAtRiskLast: {
    defaultMessage: 'Almost there!',
    description:
      'Title displayed on the practice tab on the reattempt completed screen with a rank of at risk and it is the last section that requires a reattempt.',
    id: 'SectionCompleted.sectionReattemptTitleAtRiskLast',
  },
  sectionReattemptTitleUnengaged: {
    defaultMessage: `Take a break from {sectionTitle}`,
    description:
      'Title displayed on the practice tab when completing a reattempt for a section with an unengaged rank.',
    id: 'SectionCompleted.sectionReattemptTitleUnengaged',
  },
})

@injectIntl
class SectionCompleted extends Component {
  get isReviewOverride() {
    const {
      isPretestMastered,
      isReviewDone,
      sectionRank,
      sectionRankReattempt,
    } = this.props
    if (isPretestMastered) return true
    if (isReviewDone)
      return (
        sectionRankReattempt === RANK_PROFICIENT ||
        sectionRankReattempt === RANK_ALL_STAR
      )
    return sectionRank !== RANK_UNENGAGED
  }
  get needsReattempt() {
    const {
      isEncourageMastery,
      isRequireMastery,
      sectionRank,
      sectionRankReattempt,
      sectionReattemptStatus,
    } = this.props
    return (
      (isEncourageMastery || isRequireMastery) &&
      // section is unengaged and reattempt has not been completed
      ((sectionRank === RANK_UNENGAGED &&
        sectionReattemptStatus !== REATTEMPT_STATUS_FINISHED) ||
        // or reattempt is finished but is still at risk
        (sectionReattemptStatus === REATTEMPT_STATUS_FINISHED &&
          sectionRankReattempt !== RANK_PROFICIENT &&
          sectionRankReattempt !== RANK_ALL_STAR))
    )
  }
  get needsQuiz() {
    const { hasPassedQuiz, hasQuiz, isQuizMastery } = this.props
    return hasQuiz && isQuizMastery && !hasPassedQuiz
  }
  get type() {
    const { isReviewDone: isReviewSectionDone } = this.props
    if (isReviewSectionDone) return ACTIVITY_COMPLETED_REATTEMPT
    return ACTIVITY_COMPLETED_SECTION
  }
  onClickReviewSection = () => {
    const { onClickReviewSection } = this.props
    onClickReviewSection(this.isReviewOverride)
  }
  onClickGoToQuiz = () => {
    const { onClickReviewSection, onClickGoToQuiz } = this.props
    onClickReviewSection(this.isReviewOverride)
    onClickGoToQuiz()
  }
  renderCompletedSection() {
    const {
      intl,
      isEncourageMastery,
      isMasteredWithPretestCourse,
      isRequireMastery,
      numSectionsRemaining,
      sectionRank,
    } = this.props
    const { formatMessage } = intl
    if (isRequireMastery && sectionRank === RANK_UNENGAGED) {
      if (numSectionsRemaining === 1) {
        return <p>{formatMessage(Messages.sectionCompletedBodyAtRiskLast)}</p>
      }
      return (
        <p>
          {isMasteredWithPretestCourse
            ? formatMessage(
                Messages.sectionCompletedBodyAtRiskAfterCourseMastered,
              )
            : formatMessage(Messages.sectionCompletedBodyAtRisk)}
          <span>
            &nbsp;
            {formatMessage(Messages.sectionCompletedBodyAtRiskMasteryRequired)}
          </span>
        </p>
      )
    } else if (isEncourageMastery) {
      return (
        <p>
          {isMasteredWithPretestCourse
            ? formatMessage(Messages.sectionCompletedBodyAfterCourseMastered)
            : formatMessage(Messages.sectionCompletedBodyEncourage)}
        </p>
      )
    }
    return (
      <p>
        {isMasteredWithPretestCourse
          ? formatMessage(Messages.sectionCompletedBodyAfterCourseMastered)
          : formatMessage(Messages.sectionCompletedBody)}
      </p>
    )
  }
  renderCompletedSectionTitle() {
    const { intl, isRequireMastery, numSectionsRemaining, sectionRank } =
      this.props
    const { formatMessage } = intl
    if (isRequireMastery && sectionRank === RANK_UNENGAGED) {
      if (numSectionsRemaining === 1) {
        return (
          <span>{formatMessage(Messages.sectionCompletedAtRiskTitleLast)}</span>
        )
      }
      return (
        <FormattedMessage
          {...Messages.sectionCompletedAtRiskTitle}
          values={{ sectionTitle: this.renderSectionTitle() }}
        />
      )
    }
    return (
      <FormattedMessage
        {...Messages.sectionCompletedTitle}
        values={{ sectionTitle: this.renderSectionTitle() }}
      />
    )
  }
  renderCompletedSectionReattempt() {
    const {
      intl,
      isEncourageMastery,
      isRequireMastery,
      numSectionsAtRisk,
      numSectionsRemaining,
      sectionRankReattempt,
    } = this.props
    const { formatMessage } = intl
    if (
      (isEncourageMastery || isRequireMastery) &&
      sectionRankReattempt !== RANK_PROFICIENT &&
      sectionRankReattempt !== RANK_ALL_STAR
    ) {
      if (isEncourageMastery) {
        return (
          <p>{formatMessage(Messages.sectionReattemptBodyAtRiskEncourage)}</p>
        )
      }
      return (
        <p>
          {numSectionsRemaining === 1
            ? formatMessage(Messages.sectionReattemptBodyAtRiskLast)
            : formatMessage(Messages.sectionReattemptBodyAtRisk)}
        </p>
      )
    } else if (isRequireMastery && numSectionsAtRisk) {
      return <p>{formatMessage(Messages.sectionReattemptBodyHasAtRisk)}</p>
    } else if (isEncourageMastery) {
      return (
        <p>{formatMessage(Messages.sectionReattemptBodyHasAtRiskEncourage)}</p>
      )
    }
    return <p>{formatMessage(Messages.sectionReattemptBody)}</p>
  }
  renderCompletedSectionReattemptTitle() {
    const {
      intl,
      isRequireMastery,
      numSectionsRemaining,
      sectionRankReattempt,
    } = this.props
    const { formatMessage } = intl
    if (
      isRequireMastery &&
      sectionRankReattempt !== RANK_PROFICIENT &&
      sectionRankReattempt !== RANK_ALL_STAR
    ) {
      if (numSectionsRemaining === 1) {
        return (
          <span>{formatMessage(Messages.sectionReattemptTitleAtRiskLast)}</span>
        )
      }
      return (
        <FormattedMessage
          {...Messages.sectionReattemptTitleUnengaged}
          values={{ sectionTitle: this.renderSectionTitle() }}
        />
      )
    }
    return (
      <FormattedMessage
        {...Messages.sectionReattemptTitle}
        values={{ sectionTitle: this.renderSectionTitle() }}
      />
    )
  }
  renderContent() {
    const type = this.type
    if (type === ACTIVITY_COMPLETED_REATTEMPT) {
      return this.renderCompletedSectionReattempt()
    }
    // ACTIVITY_COMPLETED_SECTION
    return this.renderCompletedSection()
  }
  renderContentQuiz() {
    return <p>In order to unlock Exam, you must also complete the Quiz.</p>
  }
  renderIcon() {
    const { isRequireMastery, sectionRank, sectionRankReattempt } = this.props
    const type = this.type
    if (isRequireMastery) {
      if (
        isRequireMastery &&
        ((type === ACTIVITY_COMPLETED_SECTION &&
          sectionRank === RANK_UNENGAGED) ||
          (type === ACTIVITY_COMPLETED_REATTEMPT &&
            sectionRankReattempt !== RANK_PROFICIENT &&
            sectionRankReattempt !== RANK_ALL_STAR))
      ) {
        return null
      }
    }
    if (type === ACTIVITY_COMPLETED_SNAPSHOT) return <AnimatedActivityIcon />
    return <AnimatedActivityIcon />
  }
  renderSectionTitle() {
    const { sectionTitle } = this.props
    return (
      <span className="SectionCompleted__section-title">{sectionTitle}</span>
    )
  }
  renderTitle() {
    const type = this.type
    if (type === ACTIVITY_COMPLETED_REATTEMPT) {
      return this.renderCompletedSectionReattemptTitle()
    }
    // ACTIVITY_COMPLETED_SECTION
    return this.renderCompletedSectionTitle()
  }
  renderButton() {
    const { intl, isEncourageMastery } = this.props
    const needsReattempt = this.needsReattempt
    const { formatMessage } = intl
    if ((isEncourageMastery || !needsReattempt) && this.needsQuiz) {
      return (
        <Button
          className={cx(
            'SectionCompleted__button SectionCompleted__button--is-quiz',
          )}
          isText
          onClick={this.onClickGoToQuiz}
          qa-id="learner-section-completed-quiz-button"
          text="Take Quiz"
        />
      )
    }
    return !isEncourageMastery || !needsReattempt ? (
      <Button
        className={cx('SectionCompleted__button', {
          'SectionCompleted__button--is-reattempt': needsReattempt,
        })}
        isText
        onClick={this.onClickReviewSection}
        qa-id="learner-section-completed-review-button"
        text={
          needsReattempt
            ? formatMessage(Messages.reattemptSection)
            : formatMessage(Messages.reviewSection)
        }
      />
    ) : null
  }
  render() {
    const { isEncourageMastery } = this.props
    const type = this.type
    return (
      <div className="SectionCompleted">
        <div className="SectionCompleted__body">
          <div
            className={cx('SectionCompleted__content', {
              'SectionCompleted__content--is-completed':
                type === ACTIVITY_COMPLETED_SECTION,
              'SectionCompleted__content--is-reattempt':
                type === ACTIVITY_COMPLETED_REATTEMPT,
            })}
            qa-id="learner-section-completed"
          >
            <div
              aria-live="assertive"
              className="SectionCompleted__title"
              role="alert"
            >
              <Heading>{this.renderTitle()}</Heading>
            </div>
            {this.renderIcon()}
            <div className="SectionCompleted__text">
              {this.renderContent()}
              {(isEncourageMastery || !this.needsReattempt) &&
                this.needsQuiz &&
                this.renderContentQuiz()}
            </div>
            {this.renderButton()}
          </div>
        </div>
      </div>
    )
  }
}

export default SectionCompleted
