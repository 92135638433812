import _ from 'lodash'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import IdleTimer from 'react-idle-timer'
import debounce from 'lodash/debounce'
import {
  Activity,
  ActivityModal,
  Button,
  CertificateIcon,
  Heading,
  IdleModal,
  IncompleteQuestionModal,
  Link,
  Loader,
  Modal,
  MoreContentBelowBanner,
  TryAgainIcon,
} from '@/components'
import {
  checkIfScrollable,
  checkIfScrolledToBottom,
} from '@/components/MoreContentBelowBanner/MoreContentBelowBanner'
import TimeTracking, {
  TYPE_EXAM_ACTIVITY,
} from '@/components/TimeTracking/TimeTracking'
import './Exam.scss'

const ExamMessages = defineMessages({
  activityErrorBody: {
    defaultMessage:
      'There was an error while loading the current question. Try refreshing the page. If the issue persists, please contact your learning administrator.',
    description:
      'Body copy displayed when there is an issue loading an Activity.',
    id: 'ExamMessages.activityErrorBody',
  },
  activityErrorHeader: {
    defaultMessage: 'Oops, something went wrong.',
    description:
      'Header copy displayed when there is an issue loading an Activity.',
    id: 'ExamMessages.activityErrorHeader',
  },
  backToDashboardLink: {
    defaultMessage: 'Back to Dashboard',
    description: 'Link to Dashboard on exam outro.',
    id: 'ExamMessages.backToDashboardLink',
  },
  beginExamButton: {
    defaultMessage: 'Begin Exam',
    description: 'Button to begin Exam.',
    id: 'ExamMessages.beginExamButton',
  },
  completeBody: {
    defaultMessage: 'Placeholder',
    description: 'Body for exam complete - TODO success and fail',
    id: 'ExamMessages.completeBody',
  },
  confirmAnswerButton: {
    defaultMessage: 'Confirm Answer',
    description: 'Button for submitting an answer to an activity in Exam.',
    id: 'ExamMessages.confirmAnswerButton',
  },
  downloadCertificateButton: {
    defaultMessage: 'Download Certificate',
    description: 'Button to download exam certificate',
    id: 'ExamMessages.downloadCertificateButton',
  },
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to zoom in on activity images in Exam.',
    id: 'ExamMessages.enlargeImage',
  },
  examCompleteDashboardButton: {
    defaultMessage: 'Back to Dashboard',
    description: 'Button to exit exam after completing',
    id: 'ExamMessages.examCompleteDashboardButton',
  },
  examHeader: {
    defaultMessage: '{courseTitle} Post-Exam',
    description: 'Header for the Exam.',
    id: 'ExamMessages.examHeader',
  },
  examIntroDefault1: {
    defaultMessage: 'Hi {firstName},',
    description: 'Default exam intro copy (1)',
    id: 'ExamMessages.examIntroDefault1',
  },
  examIntroDefault2: {
    defaultMessage:
      'Before you officially complete your course, your organization has required that you take a post-exam to demonstrate your knowledge and mastery of this content. You will go through a series of questions and receive a score at the end. If you do not pass, we encourage you to reattempt the post-exam until you do so.',
    description: 'Default exam intro copy (2)',
    id: 'ExamMessages.examIntroDefault2',
  },
  examIntroDefault3: {
    defaultMessage: `Upon passing the exam, your organization may require a certificate of completion. If this is the case, you'll be able to download this document after passing the exam.`,
    description: 'Default exam intro copy (3)',
    id: 'ExamMessages.examIntroDefault3',
  },
  examIntroDefault4: {
    defaultMessage: 'Good luck!',
    description: 'Default exam intro copy (4)',
    id: 'ExamMessages.examIntroDefault4',
  },
  examOutroFailDefault: {
    defaultMessage:
      'You have not passed your exam. We encourage you to reattempt again.',
    description: 'Default exam outro fail copy',
    id: 'ExamMessages.examOutroFailDefault',
  },
  examOutroFailNoCertificate: {
    defaultMessage:
      'You have not passed your exam. We encourage you to reattempt again.',
    description: 'Default exam outro fail copy when certificates are disabled',
    id: 'ExamMessages.examOutroFailNoCertificate',
  },
  examOutroPassDefault: {
    defaultMessage: `Congratulations, you passed the exam. If your organization has required a certificate of completion you can download it below. Otherwise, you're all done. Nice work!`,
    description: 'Default exam outro pass copy',
    id: 'ExamMessages.examOutroPassDefault',
  },
  examOutroPassNoCertificate: {
    defaultMessage: `Congratulations, you passed the exam. Nice work!`,
    description: 'Default exam outro pass copy when certificates are disabled',
    id: 'ExamMessages.examOutroPassNoCertificate',
  },
  examResultsBack: {
    defaultMessage: 'Back',
    description: 'Back button for the Exam results screen.',
    id: 'ExamMessages.examResultsBack',
  },
  examResultsHeader: {
    defaultMessage: 'Exam Results',
    description: 'Header for the Exam results screen.',
    id: 'ExamMessages.examResultsHeader',
  },
  failedHeading: {
    defaultMessage: 'Try again',
    description: 'Heading in body on done screen when exam is failed.',
    id: 'ExamMessages.failedHeading',
  },
  goToExamResults: {
    defaultMessage: 'Review Exam',
    description: 'Button to see Exam results.',
    id: 'ExamMessages.goToExamResults',
  },
  moreContentBelow: {
    defaultMessage: 'Scroll For More Content',
    description:
      'Banner to show that there is hidden content below the footer on Exam.',
    id: 'ExamMessages.moreContentBelow',
  },
  passedHeading: {
    defaultMessage: `Congrats, you've passed the exam!`,
    description: 'Heading in body on done screen when exam is passed.',
    id: 'ExamMessages.passedHeading',
  },
  doneSubText: {
    defaultMessage: 'Your score',
    description: 'Subtext in body on done screen when exam is done.',
    id: 'ExamMessages.doneSubText',
  },
  reattemptButton: {
    defaultMessage: 'Retake Exam',
    description: 'Button to reattempt exam on outro failed screen',
    id: 'ExamMessages.reattemptButton',
  },
  refreshButton: {
    defaultMessage: 'Refresh Page',
    description: 'Button displayed when there is an issue loading an Activity.',
    id: 'ExamMessages.refreshButton',
  },
  takeSurveyButton: {
    defaultMessage: 'Take survey',
    description: 'Button to take survey',
    id: 'ExamMessages.takeSurveyButton',
  },
})

const initialStateActivity = {
  activityAnswer: null,
  activityModalImage: null,
  isActivityModalOpen: false,
  isScrollable: false,
  isScrolledToBottom: false,
}

const initialStateDone = {
  // Done Screen States
  // - main
  // - results
  doneScreenState: 'main',
}

const initialState = {
  ...initialStateActivity,
  ...initialStateDone,
}

const getIdleTimeThreshold = () => {
  return 5 * 60 * 1000 // 5 minutes
}

@injectIntl
@withRouter
@inject('contentStore', 'sessionStore', 'uiStore', 'xapiStore')
@observer
class Exam extends Component {
  state = { ...initialState }
  componentDidMount() {
    const { contentStore } = this.props
    const { exam } = contentStore
    const { isStarted: isStartedExam } = exam
    if (isStartedExam) {
      this.onNext()
    }
    window.addEventListener('resize', this.debouncedCheckIfScrollable)
    setTimeout(this.checkIfScrollable, 0)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedCheckIfScrollable)
    this.$content.removeEventListener('scroll', this.onScroll)
    this.terminateSession()
  }
  checkIfScrollable = () => {
    const { isScrollable: wasScrollable } = this.state
    const totalHeight = this.$innerContent
      ? this.$innerContent.clientHeight
      : null
    const visibleHeight = this.$content ? this.$content.clientHeight : null
    checkIfScrollable(
      wasScrollable,
      totalHeight,
      visibleHeight,
      (isScrollable) => {
        this.setState({ isScrollable })
      },
    )
  }
  debouncedCheckIfScrollable = debounce(this.checkIfScrollable, 250)
  launchSession = () => {
    const { contentStore, xapiStore } = this.props
    const { exam } = contentStore
    const {
      attempt: examAttempt,
      isDone: isDoneExam,
      status: examStatus,
    } = exam || {}
    return xapiStore.launchSession('exam', {
      attempt:
        !examStatus || isDoneExam ? exam.incrementAttempt() : examAttempt,
    })
  }
  terminateSession = () => {
    const { xapiStore } = this.props
    xapiStore.terminateSession()
  }
  onChangeActivity = (activityAnswer) => {
    this.setState({ activityAnswer })
  }
  onCloseActivityModal = () => {
    this.setState({
      activityModalImage: null,
      isActivityModalOpen: false,
    })
  }
  onCloseIdleModal = () => {
    const { uiStore } = this.props
    uiStore.closeIdleModal()
  }
  onDone = () => {
    const { history } = this.props
    history.push('/dashboard')
  }
  onDownloadCertificate = () => {
    const { contentStore } = this.props
    const { exam } = contentStore
    exam.downloadCertificate()
  }
  onIdle = () => {
    const { uiStore } = this.props
    uiStore.openIdleModal()
  }
  onConfirmIncomplete = (event) => {
    this.onSubmitActivity(event, true)
  }
  onClickExamResults = (event) => {
    event.preventDefault()
    this.setState({ doneScreenState: 'results' })
  }
  onClickExamResultsBack = (event) => {
    event.preventDefault()
    this.setState({ doneScreenState: 'main' })
  }
  onNext = () => {
    const { contentStore } = this.props
    const { exam } = contentStore
    this.launchSession()
      .then(() => exam.loadActivity())
      .then(this.checkIfScrollable)
  }
  onOpenActivityModal = (e, image) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      activityModalImage: image,
      isActivityModalOpen: true,
    })
  }
  onReattemptExam = () => {
    const { contentStore } = this.props
    const { exam } = contentStore
    this.launchSession()
      .then(() => exam.loadActivity())
      .then(this.checkIfScrollable)
  }
  onScroll = debounce(() => {
    const { contentStore } = this.props
    const { isScrolledToBottom } = this.state
    const { exam } = contentStore
    const { isLoadingActivity } = exam || {}
    if (this.$content && this.$innerContent) {
      const scrollTop = this.$content.scrollTop + this.$content.clientHeight
      if (
        !isScrolledToBottom &&
        !isLoadingActivity &&
        checkIfScrolledToBottom(scrollTop, this.$innerContent.clientHeight)
      ) {
        this.setState({ isScrolledToBottom: true })
      }
    }
  }, 200)
  onSubmitActivity = (event, confirmedIncomplete = false) => {
    const { contentStore, uiStore } = this.props
    const { activityAnswer } = this.state
    const { exam } = contentStore
    const { openIncompleteQuestionModal } = uiStore
    const { isDone: wasDoneExam, isSavingActivity } = exam || {}
    if (!activityAnswer || isSavingActivity) return
    if (!confirmedIncomplete) {
      const isValidAnswer = exam.validateAnswer(
        activityAnswer,
        openIncompleteQuestionModal,
      )
      if (!isValidAnswer) return
    }
    const answer = activityAnswer
    this.setState({ activityAnswer: null })
    exam.saveActivity(answer).then(() => {
      const { isDone: isDoneExam, isPassed: isPassedExam } = exam
      if (!wasDoneExam && isDoneExam) {
        this.terminateSession()
        if (isPassedExam) {
          contentStore.sendCourseCompletionMessage()
        }
      }
      this.resetScrollPosition()
    })
  }
  resetScrollPosition = () => {
    this.setState({ isScrolledToBottom: false }, () => {
      this.checkIfScrollable()
    })
  }
  setContentRef = (el) => {
    this.$content = el
    if (!this.hasAddedScrollListener) {
      this.$content.addEventListener('scroll', this.onScroll)
      this.hasAddedScrollListener = true
    }
  }
  setInnerContentRef = (el) => {
    this.$innerContent = el
  }
  __renderActivity(
    activity,
    activityAnswer,
    isSavingActivity = false,
    isActivityModalOpen = false,
    activityModalImage = null,
    onChangeActivity = this.onChangeActivity,
    onSubmitActivity = this.onSubmitActivity,
  ) {
    const { uiStore } = this.props
    const { scrollY } = uiStore
    const {
      activityEmbed,
      activityType,
      answersCounter,
      hasHint,
      hasNotPassed,
      id: activityId,
      imageAlignment,
      isNewActivity,
      question,
      questionImage,
      questionImageDescription,
      questionImageTitle,
      questionVideo,
      replaceActivityMathFormulas,
      title: activityTitle,
      validationError: activityValidationError,
    } = activity || {}
    return !isActivityModalOpen ? (
      <Activity
        id={activityId}
        activityEmbed={activityEmbed}
        answersCounter={answersCounter}
        checkIfScrollable={this.checkIfScrollable}
        currentActivityId={activityId}
        dangerLabel={activityValidationError}
        hasHint={hasHint}
        image={questionImage}
        imageAlignment={imageAlignment}
        imageAlt={questionImageDescription || activityTitle}
        imageTitle={questionImageTitle || activityTitle}
        isDanger={hasNotPassed}
        isNewActivity={isNewActivity}
        isSavingActivity={isSavingActivity}
        onChange={onChangeActivity}
        onOpenActivityModal={this.onOpenActivityModal}
        onSubmit={onSubmitActivity}
        pageRef={this.$content}
        renderImageAfterQuestion={() =>
          this.renderImageAfterQuestion(
            questionImage,
            questionImageDescription || activityTitle,
            questionImageTitle || activityTitle,
          )
        }
        replaceActivityMathFormulas={replaceActivityMathFormulas}
        scrollY={scrollY}
        title={question}
        type={activityType}
        value={activityAnswer}
        video={questionVideo}
        // choices activities
        choice={{
          choices: activity.cleanedChoices,
        }}
        // input activities
        input={{
          isMultipleInput: activity.isMultipleInput,
          textBefore: activity.textBeforeInput,
          textAfter: activity.textAfterInput,
        }}
        // matching activity
        matching={{
          definitions: activity.pairs && activity.pairs.definitions,
          labels: activity.pairs && activity.pairs.labels,
        }}
        // sorting activity
        sortStack={{
          items: activity.pairs && activity.pairs.labels,
        }}
        // hotspot activity
        hotspot={activity.hotSpotData}
        // dragPhrase activity
        dragPhrase={{
          content: activity.cleanedContent,
          categories: activity.categories,
          items: activity.draggableItems,
        }}
      />
    ) : (
      <ActivityModal
        image={activityModalImage}
        isOpen={isActivityModalOpen}
        onClose={this.onCloseActivityModal}
      />
    )
  }
  renderActivity() {
    const { contentStore } = this.props
    const { activityAnswer, activityModalImage, isActivityModalOpen } =
      this.state
    const { exam } = contentStore
    const { isSavingActivity, activity } = exam || {}
    return this.__renderActivity(
      activity,
      activityAnswer,
      isSavingActivity,
      isActivityModalOpen,
      activityModalImage,
    )
  }
  renderActivityBody() {
    const { contentStore, xapiStore } = this.props
    const { exam } = contentStore
    const { isLaunchingSession } = xapiStore
    const { activity, activityError, isLoadingActivity, isSavingActivity } =
      exam || {}
    if (activityError) {
      return this.renderActivityError()
    }
    if (
      !activity ||
      isLaunchingSession ||
      isLoadingActivity ||
      isSavingActivity
    ) {
      return (
        <div className="Exam__loader-wrapper">
          <Loader className="Exam__loader" />
        </div>
      )
    }
    return (
      <Fragment>
        {this.renderActivity()}
        {this.renderActivityTimeTracking()}
      </Fragment>
    )
  }
  renderActivityError() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="Exam__activity-error">
        <Heading className="Exam__activity-error-header">
          {formatMessage(ExamMessages.activityErrorHeader)}
        </Heading>
        <p className="Exam__activity-error-body">
          {formatMessage(ExamMessages.activityErrorBody)}
        </p>
      </div>
    )
  }
  renderActivityButtons() {
    const { contentStore, intl } = this.props
    const { activityAnswer } = this.state
    const { exam } = contentStore
    const { formatMessage } = intl
    const { isLoadingActivity, isSavingActivity } = exam || {}
    return (
      <div className="Exam__footer__activityButtons">
        <div className="Exam__footer__buttonWrapper">
          <Modal.Button
            className="Exam__button Exam__button--is-confirm"
            qa-id="learner-activity-confirm-button"
            disabled={!activityAnswer || _.isEmpty(activityAnswer)}
            isLoading={isLoadingActivity || isSavingActivity}
            isSuccess
            key="Exam__button--is-confirm"
            onClick={this.onSubmitActivity}
          >
            {formatMessage(ExamMessages.confirmAnswerButton)}
          </Modal.Button>
        </div>
      </div>
    )
  }
  renderActivityScreen() {
    const { contentStore, xapiStore } = this.props
    const { exam } = contentStore
    const { isLaunchingSession } = xapiStore
    const {
      activity,
      courseTitle,
      numExamActivitiesRemaining,
      numExamActivitiesTotal,
      isLoadingActivity,
      isSavingActivity,
    } = exam || {}
    const progress = activity
      ? Math.round(
          ((numExamActivitiesTotal - numExamActivitiesRemaining) /
            numExamActivitiesTotal) *
            100,
        )
      : 0
    return (
      <Fragment>
        <div
          className="Exam__header-progressMeter"
          style={{ width: `${progress}%` }}
        />
        <Modal.Header className="Exam__header Exam__header--is-activity">
          <Heading className="Exam__header-title" is2>
            <div className="Exam__header-title-main">
              <FormattedMessage
                {...ExamMessages.examHeader}
                values={{
                  courseTitle: (
                    <span className="Exam__header-course-title">
                      {courseTitle}
                    </span>
                  ),
                }}
              />
            </div>
            {activity ? (
              <div className="Exam__header-counter">
                <span>
                  {numExamActivitiesTotal - numExamActivitiesRemaining + 1}
                </span>
                <span>/</span>
                <span>{numExamActivitiesTotal}</span>
              </div>
            ) : null}
          </Heading>
        </Modal.Header>
        <Modal.Body
          className={cx('Exam__body Exam__body--is-activity', {
            'Exam__body--is-loading':
              !activity ||
              isLaunchingSession ||
              isLoadingActivity ||
              isSavingActivity,
          })}
        >
          {this.renderActivityBody()}
        </Modal.Body>
      </Fragment>
    )
  }
  renderActivityTimeTracking() {
    const { contentStore, uiStore } = this.props
    const { exam } = contentStore
    const { isOpenIdleModal, isOpenIdleSessionModal } = uiStore
    const { activity, courseId } = exam || {}
    const { id: activityId } = activity
    return (
      <Fragment>
        <TimeTracking
          entityId={activityId}
          isIdle={isOpenIdleModal}
          // not actually passing section
          // used to pass courseId
          isExam
          section={{
            courseId,
          }}
          what={TYPE_EXAM_ACTIVITY}
        />
        <IdleTimer
          onIdle={this.onIdle}
          timeout={getIdleTimeThreshold()}
          startOnLoad
        />
        <IdleModal
          isOpen={isOpenIdleModal && !isOpenIdleSessionModal}
          onClose={this.onCloseIdleModal}
          tab="exam"
        />
      </Fragment>
    )
  }
  renderButtons() {
    const { contentStore, intl } = this.props
    const { exam } = contentStore
    const { formatMessage } = intl
    const { activity, activityError, isDone, isIntro } = exam || {}
    if (isDone) return
    if (isIntro) return this.renderIntroButtons()
    if (activity && activityError) {
      return (
        <Modal.Button
          className="Exam__button Exam__button--is-refresh"
          onClick={(event) => {
            event.preventDefault()
            window.location.reload()
          }}
        >
          {formatMessage(ExamMessages.refreshButton)}
        </Modal.Button>
      )
    }
    if (activity) return this.renderActivityButtons()
    return null
  }
  renderContent() {
    const { contentStore } = this.props
    const { exam } = contentStore
    const { isDone, isIntro, isStarted } = exam || {}
    if (isDone) return this.renderDoneScreen()
    if (isIntro) return this.renderIntroScreen()
    if (isStarted) return this.renderActivityScreen()
    return null
  }
  renderDefaultIntro() {
    const { contentStore, intl, sessionStore } = this.props
    const { settings } = contentStore
    const { formatMessage } = intl
    const { user } = sessionStore
    const { examCertificate } = settings || {}
    const { firstName } = user
    return (
      <div>
        <p>{formatMessage(ExamMessages.examIntroDefault1, { firstName })}</p>
        <p>{formatMessage(ExamMessages.examIntroDefault2)}</p>
        {examCertificate ? (
          <p>{formatMessage(ExamMessages.examIntroDefault3)}</p>
        ) : null}
        <p>{formatMessage(ExamMessages.examIntroDefault4)}</p>
      </div>
    )
  }
  renderDefaultOutro() {
    const { contentStore, intl } = this.props
    const { formatMessage } = intl
    const { exam, settings } = contentStore
    const { passed } = exam || {}
    const { examCertificate } = settings || {}
    return (
      <div className="Exam__done-copy">
        <p>
          {examCertificate
            ? formatMessage(
                passed
                  ? ExamMessages.examOutroPassDefault
                  : ExamMessages.examOutroFailDefault,
              )
            : formatMessage(
                passed
                  ? ExamMessages.examOutroPassNoCertificate
                  : ExamMessages.examOutroFailNoCertificate,
              )}
        </p>
      </div>
    )
  }
  renderDoneScreenMain() {
    const { contentStore, intl } = this.props
    const { formatMessage } = intl
    const { exam, settings } = contentStore
    const {
      isDownloadingCertificate,
      isLoadingActivity: isLoadingExamActivity,
      outroCopy,
      passed,
      results: examResults,
      score,
      surveyUrl: examSurveyUrl,
    } = exam || {}
    const { examCertificate } = settings || {}
    return (
      <Fragment>
        <Heading>
          {formatMessage(
            passed ? ExamMessages.passedHeading : ExamMessages.failedHeading,
          )}
        </Heading>
        <div className="Exam__done-icon">
          {passed ? <CertificateIcon /> : <TryAgainIcon />}
        </div>
        <span className="Exam__done-subText">
          {formatMessage(ExamMessages.doneSubText)}
        </span>
        <span className="Exam__done-score">{`${Math.round(score)}%`}</span>
        {outroCopy ? (
          <div
            className="Exam__done-copy"
            dangerouslySetInnerHTML={{ __html: outroCopy }}
          />
        ) : (
          this.renderDefaultOutro()
        )}
        {examResults ? (
          <Modal.Button
            className="Exam__button Exam__button--is-done Exam__results-button"
            isSuccess
            onClick={this.onClickExamResults}
          >
            {formatMessage(ExamMessages.goToExamResults)}
          </Modal.Button>
        ) : null}
        <div className="Exam__done-buttons">
          {passed ? (
            <Fragment>
              {examSurveyUrl ? (
                <Link
                  className="Exam__button Exam__button--is-done Modal__button"
                  href={examSurveyUrl}
                  isButton
                  target="_blank"
                >
                  {formatMessage(ExamMessages.takeSurveyButton)}
                </Link>
              ) : null}
              {examCertificate ? (
                <Modal.Button
                  className="Exam__button Exam__button--is-done"
                  isLoading={isDownloadingCertificate}
                  onClick={this.onDownloadCertificate}
                >
                  {formatMessage(ExamMessages.downloadCertificateButton)}
                </Modal.Button>
              ) : null}
            </Fragment>
          ) : (
            <Modal.Button
              className="Exam__button Exam__button--is-done"
              isLoading={isLoadingExamActivity}
              onClick={this.onReattemptExam}
            >
              {formatMessage(ExamMessages.reattemptButton)}
            </Modal.Button>
          )}
        </div>
        <Link
          className="Exam__done-link"
          to="/dashboard"
          qa-id="learner-exam-back-to-dashboard-link"
        >
          {formatMessage(ExamMessages.backToDashboardLink)}
        </Link>
      </Fragment>
    )
  }
  renderDoneScreenResultsItem = (item, index) => {
    const {
      learningObjectives: itemLearningObjectives,
      passed: itemHasPassed,
      question: itemQuestion,
    } = item || {}
    return (
      <div className="Exam__results-item" key={`Exam__results-item-${index}`}>
        <Heading className="Exam__results-item-number" is3>
          Question {index + 1}:
        </Heading>
        <div className="Exam__results-item-question">
          <Heading
            className="Exam__results-item-question-title"
            dangerouslySetInnerHTML={{ __html: itemQuestion }}
            is2
          />
          <div className="Exam__results-item-question-icon">
            <span>
              {itemHasPassed ? (
                <i className="fa fa-check" />
              ) : (
                <i className="fa fa-close" />
              )}
            </span>
          </div>
        </div>
        <div className="Exam__results-item-learning-objectives">
          {itemLearningObjectives &&
            itemLearningObjectives.map((lo, loIndex) => (
              <p
                className="Exam__results-item-learning-objective"
                key={`Exam__results-item-learning-objective-${loIndex}`}
              >
                * {lo}
              </p>
            ))}
        </div>
      </div>
    )
  }
  renderDoneScreenResults() {
    const { contentStore, intl } = this.props
    const { exam } = contentStore
    const { formatMessage } = intl
    const { results: examResults } = exam || {}
    return (
      <Fragment>
        <Heading>{formatMessage(ExamMessages.examResultsHeader)}</Heading>
        <div className="Exam__results">
          {examResults.map(this.renderDoneScreenResultsItem)}
        </div>
        <Modal.Button
          className="Exam__button Exam__button--is-done Modal__button"
          onClick={this.onClickExamResultsBack}
        >
          {formatMessage(ExamMessages.examResultsBack)}
        </Modal.Button>
      </Fragment>
    )
  }
  renderDoneScreenContent() {
    const { doneScreenState } = this.state
    if (doneScreenState === 'results') {
      return this.renderDoneScreenResults()
    }
    return this.renderDoneScreenMain()
  }
  renderDoneScreen() {
    return (
      <Fragment>
        <div className="Exam__header-progressMeter" style={{ width: '100%' }} />
        <Link to="/dashboard">
          <i className="fa fa-times" />
        </Link>
        <Modal.Body className="Exam__body Exam__body--is-done">
          <div className="Exam__done">{this.renderDoneScreenContent()}</div>
        </Modal.Body>
      </Fragment>
    )
  }
  renderImageAfterQuestion = (image, imageAlt, imageTitle) => {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div
        className="Activity-colImage"
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <img
          className="Activity-image"
          src={image}
          alt={imageAlt}
          title={imageTitle}
        />
        <Button
          aria-label={formatMessage(ExamMessages.enlargeImage)}
          className="Activity-zoomImage"
          onClick={(e) => this.onOpenActivityModal(e, image)}
        >
          <span>{formatMessage(ExamMessages.enlargeImage)}</span>
          <i
            aria-hidden="true"
            className="fa fa-search-plus"
            title={formatMessage(ExamMessages.enlargeImage)}
          />
        </Button>
      </div>
    )
  }
  renderIntroScreen() {
    const { contentStore } = this.props
    const { exam } = contentStore
    const { courseTitle, introCopy } = exam || {}
    return (
      <Fragment>
        <Modal.Header className="Exam__header Exam__header--is-intro">
          <Heading>
            <FormattedMessage
              {...ExamMessages.examHeader}
              values={{
                courseTitle: (
                  <span className="Exam__header-course-title">
                    {courseTitle}
                  </span>
                ),
              }}
            />
          </Heading>
        </Modal.Header>
        <Modal.Body className="Exam__body Exam__body--is-intro">
          {introCopy ? (
            <div dangerouslySetInnerHTML={{ __html: introCopy }} />
          ) : (
            this.renderDefaultIntro()
          )}
        </Modal.Body>
      </Fragment>
    )
  }
  renderIntroButtons() {
    const { contentStore, intl } = this.props
    const { exam } = contentStore
    const { isLoadingActivity } = exam
    const { formatMessage } = intl
    return (
      <Modal.Button
        className="Exam__button Exam__button--is-next"
        isLoading={isLoadingActivity}
        key="Exam__button--is-next"
        onClick={this.onNext}
      >
        {formatMessage(ExamMessages.beginExamButton)}
      </Modal.Button>
    )
  }
  renderMain() {
    const { contentStore } = this.props
    const { isScrollable, isScrolledToBottom } = this.state
    const { exam } = contentStore
    const { isIntro, isDone, isLoadingActivity, isSavingActivity } = exam || {}
    return (
      <Fragment>
        <div className="Exam__content" ref={this.setContentRef}>
          <div ref={this.setInnerContentRef}>{this.renderContent()}</div>
        </div>
        {isIntro || (!isLoadingActivity && !isSavingActivity && !isDone) ? (
          <Modal.Footer className="Exam__footer">
            <MoreContentBelowBanner
              isScrollable={isScrollable}
              isScrolledToBottom={isScrolledToBottom}
              scrollableEl={this.$content}
              visibleHeight={this.$content ? this.$content.clientHeight : 240}
            />
            {this.renderButtons()}
          </Modal.Footer>
        ) : null}
      </Fragment>
    )
  }
  render() {
    const { contentStore, uiStore } = this.props
    const { activityAnswer } = this.state
    const { exam } = contentStore
    const { activity, activityError, isDone, isIntro } = exam || {}
    const { closeIncompleteQuestionModal, isOpenIncompleteQuestionModal } =
      uiStore
    const answersCountTotals =
      activity &&
      activity.getAnswersCountTotals(
        activityAnswer,
        isOpenIncompleteQuestionModal,
      )
    const { numAnswersIncomplete, numAnswersTotal } = answersCountTotals || {}
    return (
      <Fragment>
        <Modal className="Exam" isOpen overlayClassName="Exam__overlay">
          <div
            className={cx('Exam__main', {
              'Exam__main--is-activity-error': activityError,
              'Exam__main--is-done': isDone,
              'Exam__main--is-intro': isIntro,
            })}
            qa-id="learner-exam-modal"
          >
            {this.renderMain()}
          </div>
        </Modal>
        <IncompleteQuestionModal
          isOpen={isOpenIncompleteQuestionModal}
          numAnswersIncomplete={numAnswersIncomplete}
          numAnswersTotal={numAnswersTotal}
          onClose={closeIncompleteQuestionModal}
          onConfirm={this.onConfirmIncomplete}
        />
      </Fragment>
    )
  }
}

export default Exam
