import React, { Component } from 'react'
import InstanceContentPageActivity from './InstanceContentPageActivity'

export default class InstanceContentPageActivities extends Component {
  render() {
    const { activities, onClickOpenActivityModal } = this.props
    return (
      <div className="InstanceContentPage__activities">
        <div className="InstanceContentPage__activities-header">
          <div className="InstanceContentPage__activities-header-column">
            Question Title
          </div>
          <div className="InstanceContentPage__activities-header-column">
            Question Type
          </div>
        </div>
        <div className="InstanceContentPage__activities-content">
          {activities.map((activity, index) => {
            const { id: activityId } = activity
            return (
              <InstanceContentPageActivity
                activity={activity}
                key={`InstanceContentPage__activity-${activityId}-${index}`}
                onClickOpenActivityModal={onClickOpenActivityModal}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
