'use strict'

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable()
  window.Promise = require('promise/lib/es6-extensions.js')
}

// Babel no longer ships with polyfills for Map & Set, and has a separate babel-polyfill library;
// instead we will require specific polyfills as neccessary from core-js to reduce bloat.
// Map & Set are utilized in the React library and requires backwards compatibility
// https://babeljs.io/docs/usage/polyfill/
// https://github.com/zloirock/core-js
if (typeof Map === 'undefined') {
  // https://github.com/zloirock/core-js#map
  window.Map = require('core-js/library/es6/map')
}
if (typeof Set === 'undefined') {
  // https://github.com/zloirock/core-js#set
  window.Set = require('core-js/library/es6/set')
}

// Array.find()
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined')
      }

      var o = Object(this)

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function')
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1]

      // 5. Let k be 0.
      var k = 0

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k]
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue
        }
        // e. Increase k by 1.
        k++
      }

      // 7. Return undefined.
      return undefined
    },
    configurable: true,
    writable: true,
  })
}

// .scrollTo polyfill for IE11
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// fetch() polyfill for making API calls.
require('whatwg-fetch')

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign')

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global)
}

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict'
    if (typeof start !== 'number') {
      start = 0
    }

    if (start + search.length > this.length) {
      return false
    } else {
      return this.indexOf(search, start) !== -1
    }
  }
}

// Add isFinite polyfill to fix IE11 crash for recharts package for Pie Chart
Number.isFinite =
  Number.isFinite ||
  function (value) {
    return typeof value === 'number' && isFinite(value)
  }

// Intl polyfill
// https://formatjs.io/guides/runtime-environments/
if (!window.Intl) {
  window.Intl = require('intl')
}
