import React, { Component } from 'react'
// import cx from 'classnames'

import { Spot, Accordion, AccordionItem } from '@/components'

import './PerformanceList.scss'

export default class PerformanceList extends Component {
  filterActivities(activities) {
    const { ranks } = this.props
    return ranks && ranks.length
      ? activities.filter((activity) => ranks.indexOf(activity.rank) !== -1)
      : activities
  }
  filterSections(sections) {
    const { ranks } = this.props
    return ranks && ranks.length
      ? sections
          .map((section) => {
            const { activities, ...rest } = section
            const filteredActivities = this.filterActivities(activities)
            return {
              activities: filteredActivities,
              ...rest,
            }
          })
          .filter((section) => section.activities.length)
      : sections
  }
  renderActivity = (activity, index) => {
    const {
      avgAttempt: activityAverageAttempt,
      id: activityId,
      rank: activityRank,
      title: activityTitle,
    } = activity
    return (
      <div
        key={`performanceList_${activityId}_${index}`}
        className="PerformanceList-sectionItem columns"
      >
        <div className="column PerformanceList-itemTitle">
          <span>{activityTitle}</span>
        </div>
        <div className="column PerformanceList-itemRating">
          <Spot
            isSmall
            isSuccess={activityRank === 'all-star'}
            isInfo={activityRank === 'good'}
            isWarning={activityRank === 'at-risk'}
          />
        </div>
        <div className="column PerformanceList-itemAverageAttempts">
          <span>{`Average Attempts: ${activityAverageAttempt}`}</span>
        </div>
      </div>
    )
  }
  renderSections() {
    const { activeSection, onSectionChange, unit } = this.props
    const { sections } = unit
    const filteredSections = this.filterSections(sections)
    return (
      <Accordion active={activeSection} onChange={onSectionChange}>
        {filteredSections.map((section, index) => {
          const { activities } = section
          return (
            <AccordionItem
              key={`performanceList_${section.id}_${index}`}
              id={`performanceList_${section.id}_${index}`}
              primaryLabel={section.title}
              secondaryLabel={activities.length}
              content={this.renderItemRatings(section)}
            >
              <div className="PerformanceList-sectionItems">
                {activities.map(this.renderActivity)}
              </div>
            </AccordionItem>
          )
        })}
      </Accordion>
    )
  }
  renderItemRatings = (section) => {
    const { activities } = section
    return () => (
      <div className="PerformanceList-ratings">
        {activities.map((activity, index) => (
          <Spot
            key={`performanceList_${activity.id}_${index}`}
            isSmall
            isSuccess={activity.rank === 'all-star'}
            isInfo={activity.rank === 'good'}
            isWarning={activity.rank === 'at-risk'}
          />
        ))}
      </div>
    )
  }
  renderLegend() {
    return (
      <div className="PerformanceList-legend">
        <span className="PerformanceList-legendItem">
          <Spot isSmall isSuccess label="Very Effective" />
        </span>
        <span className="PerformanceList-legendItem">
          <Spot isSmall isInfo label="Effective" />
        </span>
        <span className="PerformanceList-legendItem">
          <Spot isSmall isWarning label="Problematic" />
        </span>
      </div>
    )
  }
  render() {
    const { hasLegend, unit } = this.props
    return (
      <div className="PerformanceList">
        <div className="PerformanceList-header">
          <div className="level">
            <div className="level-item">
              <div>
                <div className="PerformanceList-subtitle subtitle">
                  Section & Assessment Performance
                </div>
                <div className="PerformanceList-title title">{unit.title}</div>
              </div>
            </div>
            {hasLegend ? (
              <div className="level-right">{this.renderLegend()}</div>
            ) : null}
          </div>
        </div>
        <div className="PerformanceList-sectionsHead">
          <div className="columns is-mobile">
            <div className="column">
              Section{' '}
              <span className="is-hidden-tablet is-hidden-desktop">
                / Rating
              </span>
            </div>
            <div className="column is-hidden-mobile">Rating</div>
            <div className="column has-text-right">Assessments</div>
          </div>
        </div>
        <div className="PerformanceList-sections">{this.renderSections()}</div>
      </div>
    )
  }
}
