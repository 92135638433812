import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import request from '@/utils/request'

export const TYPE_ACTIVITY = 'Activity'
export const TYPE_READ = 'Lecture Notes'
export const TYPE_VIDEO = 'Video'

export const TYPE_EXAM_ACTIVITY = 'Exam Activity'
export const TYPE_QUIZ_ACTIVITY = 'Quiz Activity'

export const TYPE_MEMORY_BOOSTER_ACTIVITY = 'Memory Booster Activity'
export const TYPE_MEMORY_BOOSTER_READ = 'Memory Booster Read'
export const TYPE_MEMORY_BOOSTER_WATCH = 'Memory Booster Watch'

// duration under specified threshold are discarded
export const TIME_THRESHOLD = 2

const getVisibilityEventKey = () => {
  let stateKey, eventKey
  const keys = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  }

  for (stateKey in keys) {
    if (stateKey in document) {
      eventKey = keys[stateKey]
      break
    }
  }

  if (!eventKey) {
    console.warn('Unable to determine visibility API')
    return
  }

  return eventKey
}

export default class TimeTracking extends PureComponent {
  static propTypes = {
    // Start timer on component rendering
    autostart: PropTypes.bool,
    // Type of timer
    what: PropTypes.string,
    // Section associated to timer
    section: PropTypes.object,
    // More granular entity id associated to timer (e.g activity id).
    entityId: PropTypes.string,
    isMemoryBooster: PropTypes.bool,
  }

  static defaultProps = {
    autostart: true,
    // If entityId is omitted we're goint to use section.sectionId
    entityId: null,
    isMemoryBooster: false,
  }

  state = {
    created: null,
    startTimeStamp: null,
    duration: 0,
  }

  componentDidUpdate(prevProps) {
    const { isIdle: wasIdle } = prevProps
    const { isIdle } = this.props
    if (isIdle && !wasIdle) {
      this.reset()
    } else if (!isIdle && wasIdle) {
      this.start()
    }
  }

  onVisibilityChange = () => {
    if (!document.visibilityState) {
      console.warn('Document visibility API not supported.')
      return
    }

    document.visibilityState === 'visible' ? this.resume() : this.pause()
  }

  componentDidMount() {
    this.start()

    window.addEventListener(getVisibilityEventKey(), this.onVisibilityChange)
  }

  componentWillUnmount() {
    window.removeEventListener(getVisibilityEventKey(), this.onVisibilityChange)

    this.finish()
  }

  /**
   * Returns elapsed number of seconds
   * @returns {number}
   */
  getElapsed() {
    if (!this.state.startTimeStamp) return
    return (new Date().getTime() - this.state.startTimeStamp) / 1000
  }

  start() {
    const now = new Date()
    const stateUpdate = {
      created: now.toISOString(),
      startTimeStamp: null,
      duration: 0,
    }
    if (this.props.autostart) {
      stateUpdate.startTimeStamp = now.getTime()
    }
    this.setState(stateUpdate)
  }

  /**
   * Increments duration based on current timestamp and start timestamp
   */
  pause() {
    this.setState((prevState) => {
      return {
        duration: prevState.duration + this.getElapsed(),
        startTimeStamp: null,
      }
    })
  }

  /**
   * Resets time tracking when user is idle
   */
  reset() {
    this.setState({
      created: null,
      startTimeStamp: null,
      duration: 0,
    })
  }

  /**
   * Initialise start timestamp
   */
  resume() {
    if (this.state.startTimeStamp) return

    this.setState({
      startTimeStamp: new Date().getTime(),
    })
  }

  /**
   * Pauses current time and pushes duration to server only if time spents
   * exceeds a specified threshold (ignore user actions where he jumps
   * between tabs)
   */
  finish() {
    // Seconds currently tracking plus previously saved duration
    const duration = parseFloat(this.state.duration + this.getElapsed()) || 0
    const { entityId, isExam, isMemoryBooster, isQuiz, section, what } =
      this.props
    const { created } = this.state

    if (duration < TIME_THRESHOLD) {
      return
    }

    let timeObj = {
      created,
      entity_id: entityId || section.id,
      time: duration,
      type: what,
    }

    if (isQuiz) {
      timeObj['section_id'] = section.id
      timeObj['unit_id'] = section.unitId
    } else if (!isExam) {
      timeObj['is_memory_booster'] = isMemoryBooster
      timeObj['section_id'] = section.id
      timeObj['unit_id'] = section.unitId
    }

    let POST = {
      times: [timeObj],
    }

    if (isQuiz) {
      POST['is_quiz'] = true
    } else if (isExam) {
      POST['is_exam'] = true
    }

    request.post(`/tracking/${section.courseId}/time`, POST).catch(() => {
      // On error re-add timers to closedTimers
      console.warn('Unable to save timer')
    })

    this.setState({
      created: null,
      startTimeStamp: null,
      duration: 0,
    })
  }

  render() {
    return null
  }
}
