import humps from 'lodash-humps'
import { action, computed, observable } from 'mobx'
import Activity from './activity'

import {
  ERROR_QUIZ_LOAD_ACTIVITY,
  QUIZ_FINISHED,
  QUIZ_READY,
  QUIZ_STARTED,
} from '@/constants'
import SentryReplay from '@/SentryReplay'
import { toCamelCase } from '@/utils/helpers'
import request from '@/utils/request'

export default class Quiz {
  @observable activity = null
  @observable activityAnswer = null
  @observable activityAnswerHasChanged = false
  @observable activityError = null
  @observable attempt = 0
  @observable courseId = null
  @observable unitId = null
  @observable sectionId = null
  @observable courseTitle = ''
  @observable isLoadingActivity = false
  @observable isSavingActivity = false
  @observable nextActivity = null
  @observable numQuizActivitiesRemaining = null
  @observable numQuizActivitiesTotal = null
  @observable streak = 0

  @observable passed = null
  @observable results = null
  @observable score = null
  @observable scoreRequired = null
  @observable status = null
  @observable surveyUrl = null

  constructor(data) {
    this.init(data)
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  createNewActivity(activityData) {
    const activity = new Activity({ ...activityData })
    return activity
  }

  @computed get hasNextActivity() {
    return this.nextActivity && this.nextActivity.isNewActivity
  }
  @action moveToNextActivity() {
    if (!this.nextActivity) return false
    this.activity = this.nextActivity
    this.nextActivity = null
    this.activityTimestamp = Date.now()
    this.resetActivityAnswer()
    return this.activity
  }

  @computed get isDone() {
    return this.status === QUIZ_FINISHED
  }

  @computed get isIntro() {
    return !this.status || this.isReady
  }

  @computed get isPassed() {
    return !!this.passed
  }

  @computed get isReady() {
    return this.status === QUIZ_READY
  }

  @computed get isStarted() {
    return this.status === QUIZ_STARTED
  }

  @action incrementAttempt() {
    this.attempt = this.attempt + 1
    return this.attempt
  }

  @action resetActivity() {
    this.activity = null
  }
  @action resetActivityAnswer() {
    this.activityAnswer = null
    this.activityAnswerHasChanged = false
  }

  @action loadActivity() {
    this.isLoadingActivity = true
    this.activityError = null
    return request
      .get(
        `/activities/${this.courseId}/${this.unitId}/${this.sectionId}/quiz/activity`,
        {
          params: {
            d: Date.now(),
          },
        },
      )
      .then(this.handleLoadActivity)
      .catch(this.handleLoadActivityError)
  }
  @action handleLoadActivity = (response) => {
    const { data } = response
    const {
      activity: activityData,
      numQuizActivitiesRemaining,
      numQuizActivitiesTotal,
      passed: quizPassed,
      score: quizScore,
      status: quizStatus,
    } = humps(data)

    this.numQuizActivitiesRemaining = numQuizActivitiesRemaining
    this.numQuizActivitiesTotal = numQuizActivitiesTotal
    this.passed = quizPassed
    this.score = quizScore
    this.status = quizStatus

    if (activityData) {
      this.resetActivity()
      this.resetActivityAnswer()
      this.activity = this.createNewActivity(activityData)
    } else if (this.isStarted) {
      return this.handleLoadActivityError(new Error(ERROR_QUIZ_LOAD_ACTIVITY))
    }
    this.isLoadingActivity = false
    return Promise.resolve()
  }
  @action handleLoadActivityError = (error) => {
    const { message } = error
    if (message === ERROR_QUIZ_LOAD_ACTIVITY) {
      this.activityError = ERROR_QUIZ_LOAD_ACTIVITY
    }
    SentryReplay.captureException(error)
    this.isLoadingActivity = false
    return Promise.reject(error)
  }

  @action saveActivity() {
    const activityAnswer = this.activityAnswer
    if (this.isSavingActivity)
      return Promise.reject(new Error('Already saving activity;'))
    if (!activityAnswer) return Promise.reject(new Error('No answer;'))

    const { id: currentActivityId, isMultipleInput } = this.activity

    this.isSavingActivity = true

    let POST = { current_activity_id: currentActivityId }
    if (isMultipleInput) {
      POST = { ...POST, answers: this.activity.adaptAnswer(activityAnswer) }
    } else {
      POST = { ...POST, answer: this.activity.adaptAnswer(activityAnswer) }
    }

    return request
      .post(
        `/activities/${this.courseId}/${this.unitId}/${this.sectionId}/quiz/answer`,
        POST,
      )
      .then(this.handleSaveActivity)
      .catch(this.handleSaveActivityError)
  }
  @action handleSaveActivity = (response) => {
    const { data: responseData } = response
    const {
      activity: activityData,
      activityResponse,
      activityResponseLos,
      numQuizActivitiesRemaining,
      numQuizActivitiesTotal,
      passed: quizPassed,
      results: quizResults,
      score: quizScore,
      status: quizStatus,
    } = humps(responseData)

    this.passed = quizPassed
    this.results = quizResults
    this.score = quizScore
    this.status = quizStatus

    // this.resetActivity()
    if (activityResponse) {
      this.activity.isNewActivity = false
      this.activity.answerResponse = activityResponse.answer
      this.activity.answerResponseLos = activityResponseLos
      this.activity.correctAnswers = activityResponse.correctAnswers
      this.activity.incorrectAnswers = activityResponse.incorrectAnswers
      this.activity.hint = activityResponse.hint
      this.activity.passed = activityResponse.passed
      if (this.activity.hasPassed) {
        this.streak++
      } else {
        this.streak = 0
      }
    }
    if (activityData) {
      this.nextActivity = this.createNewActivity(activityData)
    }

    this.numQuizActivitiesRemaining = numQuizActivitiesRemaining
    this.numQuizActivitiesTotal = numQuizActivitiesTotal

    this.isSavingActivity = false
    return Promise.resolve(response)
  }
  @action handleSaveActivityError = (error) => {
    const { response } = error || {}
    const { status } = response || {}
    SentryReplay.captureException(error)
    this.isSavingActivity = false
    if (status === 403 || status === 409) {
      return this.loadActivity()
    }
    return Promise.reject(error)
  }
  @action saveActivityAnswer(answer) {
    this.activityAnswer = answer
    this.activityAnswerHasChanged = true
  }

  @action validateAnswer(openIncompleteQuestionModal) {
    return this.activity.validateAnswer(
      this.activityAnswer,
      openIncompleteQuestionModal,
    )
  }
}
