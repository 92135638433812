import cx from 'classnames'
import React from 'react'
import './TrophyIcon.scss'
const TrophyIcon = ({ className }) => {
  return (
    <div className={cx('TrophyIcon', className)}>
      <div className="svg">
        <svg
          width="138px"
          height="125px"
          viewBox="0 0 138 125"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="0%"
              y1="-17.0102416%"
              x2="100%"
              y2="114.850315%"
              id="TrophyIcon__linear-gradient"
            >
              <stop stopColor="#FAD391" offset="0%" />
              <stop stopColor="#EE7B0C" offset="100%" />
            </linearGradient>
          </defs>
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
              d="M130.416667,17.875 L106.222222,17.875 L106.222222,8.578125 C106.222222,5.48691406 103.732986,3 100.638889,3 L37.3611111,3 C34.2670139,3 31.7777778,5.48691406 31.7777778,8.578125 L31.7777778,17.875 L7.58333333,17.875 C4.48923611,17.875 2,20.3619141 2,23.453125 L2,36.46875 C2,51.9248047 20.1225694,67.0787109 41.990625,69.565625 C49.2954861,81.6748047 57.8333333,86.671875 57.8333333,86.671875 L57.8333333,103.40625 L46.6666667,103.40625 C38.4545139,103.40625 31.7777778,108.217383 31.7777778,116.421875 L31.7777778,119.210938 C31.7777778,120.744922 33.0340278,122 34.5694444,122 L103.430556,122 C104.965972,122 106.222222,120.744922 106.222222,119.210938 L106.222222,116.421875 C106.222222,108.217383 99.5454861,103.40625 91.3333333,103.40625 L80.1666667,103.40625 L80.1666667,86.671875 C80.1666667,86.671875 88.7045139,81.6748047 96.009375,69.565625 C117.947222,67.0554688 136,51.8783203 136,36.46875 L136,23.453125 C136,20.3619141 133.510764,17.875 130.416667,17.875 Z M16.8888889,36.46875 L16.8888889,32.75 L31.8243056,32.75 C32.0569444,40.3269531 33.1736111,46.9742188 34.8020833,52.7847656 C23.7517361,48.9730469 16.8888889,41.1869141 16.8888889,36.46875 Z M121.111111,36.46875 C121.111111,41.1636719 114.271528,48.9730469 103.197917,52.7847656 C104.826389,46.9742188 105.943056,40.3269531 106.175694,32.75 L121.111111,32.75 L121.111111,36.46875 Z M91.4961806,37.5611328 L82.2836806,46.5326172 L84.4704861,59.2228516 C84.8659722,61.5005859 82.4465278,63.2205078 80.4225694,62.1513672 L69.0232639,56.1548828 L57.6239583,62.1513672 C55.5767361,63.2205078 53.1805556,61.4773437 53.5760417,59.2228516 L55.7628472,46.5326172 L46.5503472,37.5611328 C44.8986111,35.9574219 45.8059028,33.1451172 48.1090278,32.7964844 L60.8576389,30.9371094 L66.5572917,19.4089844 C67.5809028,17.3404297 70.5354167,17.3404297 71.5590278,19.4089844 L77.2586806,30.9371094 L90.0072917,32.7964844 C92.240625,33.1451172 93.1479167,35.9574219 91.4961806,37.5611328 Z"
              fill="url(#TrophyIcon__linear-gradient)"
              fillRule="nonzero"
            />
          </g>
        </svg>
      </div>
    </div>
  )
}
export default TrophyIcon
