import React, { Component } from 'react'
import './TryAgainIcon.scss'
// Note: Converted HTML to JSX
// Removed xmlns:xlink
// https://github.com/reactjs/react-magic/issues/62
class TryAgainIcon extends Component {
  render() {
    return (
      <div className="TryAgainIcon">
        <svg width="186px" height="138px" version="1.1">
          <g
            id="Artboard"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g id="Group-9">
              <path
                d="M1.29919091,32.6360265 L0.9,8.16063375 C1.3428,7.81488083 31.9721961,9.06063375 32.7724948,9.06063375 L180.620252,9.06063375 C183.260279,9.06063375 185.4,10.1702652 185.4,11.5391494 L185.4,133.482118 C185.4,134.851002 183.2598,135.960634 180.620252,135.960634 L5.67974805,135.960634 C3.03972078,135.960634 0.9,134.851002 0.9,133.482118 L0.9,33.321584 C0.9,33.077698 1.03897403,32.8392648 1.29919091,32.6360265 Z"
                id="Path"
                fill="#E9EFFF"
                fillRule="nonzero"
              />
              <path
                d="M185.4,11.4785156 L185.4,133.421484 C185.4,134.790369 183.812415,135.9 181.854416,135.9 L173.7,135.9 C175.658354,135.9 177.245584,134.790369 177.245584,133.421484 L177.245584,11.4785156 C177.245584,10.1096314 175.657998,9 173.7,9 L181.854416,9 C183.812415,9 185.4,10.1096314 185.4,11.4785156 Z"
                id="Path"
                fill="#D3DCFB"
                fillRule="nonzero"
              />
              <path
                d="M49.5,102.06 L49.5,124.2 L39.6,124.2 L29.7,124.2 L19.8,124.2 L19.8,109.44 C19.8,108.081342 20.9082164,106.98 22.275,106.98 L27.225,106.98 C28.5920318,106.98 29.7,108.081342 29.7,109.44 L29.7,89.76 C29.7,88.401342 30.8082164,87.3 32.175,87.3 L37.125,87.3 C38.4920318,87.3 39.6,88.401342 39.6,89.76 L39.6,102.06 C39.6,100.701342 40.7082164,99.6 42.075,99.6 L47.025,99.6 C48.3917836,99.6 49.5,100.701342 49.5,102.06 Z"
                id="Path"
                fill="#38C674"
                fillRule="nonzero"
              />
              <path
                d="M49.5,102.42 L49.5,125.1 L44.8220482,125.1 L44.8220482,102.42 C44.8220482,101.028204 43.6927653,99.9 42.3,99.9 L46.9779518,99.9 C48.3707171,99.9 49.5,101.028204 49.5,102.42 Z"
                id="Path"
                fill="#24B55E"
                fillRule="nonzero"
              />
              <path
                d="M39.6,89.76 L39.6,124.2 L29.7,124.2 L19.8,124.2 L19.8,109.44 C19.8,108.081342 20.9082164,106.98 22.275,106.98 L27.225,106.98 C28.5920318,106.98 29.7,108.081342 29.7,109.44 L29.7,89.76 C29.7,88.401342 30.8082164,87.3 32.175,87.3 L37.125,87.3 C38.4920318,87.3 39.6,88.401342 39.6,89.76 Z"
                id="Path"
                fill="#73C3F9"
                fillRule="nonzero"
              />
              <path
                d="M29.7,109.542857 L29.7,124.2 L19.8,124.2 L19.8,109.542857 C19.8,108.193667 20.910946,107.1 22.2814518,107.1 L27.2185482,107.1 C28.589054,107.1 29.7,108.193667 29.7,109.542857 Z"
                id="Path"
                fill="#FFB332"
                fillRule="nonzero"
              />
              <g
                id="Group-7"
                transform="translate(63.900000, 108.900000)"
                fillRule="nonzero"
              >
                <path
                  d="M3.6,11.79 L3.6,14.31 C3.6,15.353784 2.79414478,16.2 1.80012031,16.2 C0.805855224,16.2 0,15.353784 0,14.31 L0,11.79 C0,10.746216 0.805855224,9.9 1.80012031,9.9 C2.79414478,9.9 3.6,10.746216 3.6,11.79 Z"
                  id="Path"
                  fill="#43545C"
                />
                <path
                  d="M9,11.79 L9,14.31 C9,15.353784 8.19409091,16.2 7.2,16.2 C6.20590909,16.2 5.4,15.353784 5.4,14.31 L5.4,11.79 C5.4,10.746216 6.20590909,9.9 7.2,9.9 C8.19409091,9.9 9,10.746216 9,11.79 Z"
                  id="Path"
                  fill="#4A7273"
                />
                <path
                  d="M14.4,11.79 L14.4,14.31 C14.4,15.353784 13.5941448,16.2 12.5998797,16.2 C11.6056146,16.2 10.8,15.353784 10.8,14.31 L10.8,11.79 C10.8,10.746216 11.6058552,9.9 12.5998797,9.9 C13.5939042,9.9 14.4,10.746216 14.4,11.79 Z"
                  id="Path"
                  fill="#61AE9F"
                />
                <path
                  d="M20.7,11.79 L20.7,14.31 C20.7,15.353784 19.8941448,16.2 18.9001203,16.2 C17.9060958,16.2 17.1,15.353784 17.1,14.31 L17.1,11.79 C17.1,10.746216 17.9058552,9.9 18.9001203,9.9 C19.8943854,9.9 20.7,10.746216 20.7,11.79 Z"
                  id="Path"
                  fill="#7CC3AC"
                />
                <path
                  d="M25.2,11.79 L25.2,14.31 C25.2,15.353784 24.3941448,16.2 23.4001203,16.2 C22.4060958,16.2 21.6,15.353784 21.6,14.31 L21.6,11.79 C21.6,10.746216 22.4058552,9.9 23.4001203,9.9 C24.3943854,9.9 25.2,10.746216 25.2,11.79 Z"
                  id="Path"
                  fill="#D8D8D8"
                />
                <path
                  d="M3.6,1.89 L3.6,4.41 C3.6,5.453784 2.79414478,6.3 1.80012031,6.3 C0.805855224,6.3 0,5.453784 0,4.41 L0,1.89 C0,0.846216 0.805855224,0 1.80012031,0 C2.79414478,0 3.6,0.846216 3.6,1.89 Z"
                  id="Path"
                  fill="#842020"
                />
                <path
                  d="M9,1.89 L9,4.41 C9,5.453784 8.19414478,6.3 7.20012031,6.3 C6.20609585,6.3 5.4,5.453784 5.4,4.41 L5.4,1.89 C5.4,0.846216 6.20585522,0 7.20012031,0 C8.1943854,0 9,0.846216 9,1.89 Z"
                  id="Path"
                  fill="#AF2B2B"
                />
                <path
                  d="M14.4,1.89 L14.4,4.41 C14.4,5.453784 13.5940909,6.3 12.6,6.3 C11.6059091,6.3 10.8,5.453784 10.8,4.41 L10.8,1.89 C10.8,0.846216 11.6059091,0 12.6,0 C13.5940909,0 14.4,0.846216 14.4,1.89 Z"
                  id="Path"
                  fill="#DB3131"
                />
                <path
                  d="M19.8,1.89 L19.8,4.41 C19.8,5.453784 18.9941448,6.3 17.9998797,6.3 C17.0056146,6.3 16.2,5.453784 16.2,4.41 L16.2,1.89 C16.2,0.846216 17.0058552,0 17.9998797,0 C18.9939042,0 19.8,0.846216 19.8,1.89 Z"
                  id="Path"
                  fill="#FE5E47"
                />
                <path
                  d="M25.2,1.89 L25.2,4.41 C25.2,5.453784 24.3941448,6.3 23.3998797,6.3 C22.4056146,6.3 21.6,5.453784 21.6,4.41 L21.6,1.89 C21.6,0.846216 22.4058552,0 23.3998797,0 C24.3939042,0 25.2,0.846216 25.2,1.89 Z"
                  id="Path"
                  fill="#FF904E"
                />
              </g>
              <path
                d="M79.2,55.35 C79.2,69.01893 68.1189401,80.1 54.45,80.1 C40.7810599,80.1 29.7,69.01893 29.7,55.35 C29.7,41.68107 40.7810599,30.6 54.45,30.6 C68.1189401,30.6 79.2,41.6808225 79.2,55.35 Z"
                id="Path"
                fill="#FFB332"
                fillRule="nonzero"
              />
              <path
                d="M36.9,72.842313 L71.9425594,37.8 C76.4267549,42.2839571 79.2,48.478637 79.2,55.3212804 C79.2,69.0060717 68.1060258,80.1 54.4211555,80.1 C47.5788445,80.1 41.3841955,77.3262701 36.9,72.842313 Z"
                id="Path"
                fill="#73C3F9"
                fillRule="nonzero"
              />
              <path
                d="M78.3,55.3212804 C78.3,69.0060717 67.4123252,80.1 53.9819335,80.1 C53.07544,80.1 52.1808933,80.0477169 51.3,79.9493454 C63.46842,78.5887459 72.936133,68.0825688 72.936133,55.3212804 C72.936133,49.8734311 71.2094554,44.8374042 68.2849288,40.7471809 L71.1772722,37.8 C75.5780813,42.2842049 78.3,48.4788848 78.3,55.3212804 Z"
                id="Path"
                fill="#4FABF7"
                fillRule="nonzero"
              />
              <path
                d="M80.9997177,54.13592 C81.0110777,54.5534729 80.6781788,54.9 80.2583509,54.9 L54.9,54.9 L54.9,28.6381601 C54.9,28.2097937 55.2657443,27.8738344 55.6952036,27.9016057 C69.5850888,28.8008559 80.6223664,40.1408942 80.9997177,54.13592 Z"
                id="Path"
                fill="#FF4155"
                fillRule="nonzero"
              />
              <path
                d="M80.9997302,54.135798 C81.0108122,54.5534176 80.679091,54.9 80.2604377,54.9 L75.6742133,54.9 C75.6852953,42.2020556 67.3178925,31.4607043 55.8,27.9 C69.6350156,28.8158625 80.6239273,40.150091 80.9997302,54.135798 Z"
                id="Path"
                fill="#E80054"
                fillRule="nonzero"
              />
              <path
                d="M66.6,55.8 C66.6,62.758728 61.1601524,68.4 54.45,68.4 C47.7398476,68.4 42.3,62.758728 42.3,55.8 C42.3,48.841272 47.7398476,43.2 54.45,43.2 C61.1601524,43.2 66.6,48.841272 66.6,55.8 Z"
                id="Path"
                fill="#D3DCFB"
                fillRule="nonzero"
              />
              <path
                d="M58.5,55.8 C58.5,56.79408 57.6365837,57.6 56.5712997,57.6 L51.4284425,57.6 C50.3631585,57.6 49.5,56.79408 49.5,55.8 C49.5,54.80592 50.3634163,54 51.4284425,54 L56.5712997,54 C57.6365837,54 58.5,54.80592 58.5,55.8 Z"
                id="Path"
                fill="#3C58A0"
                fillRule="nonzero"
              />
              <path
                d="M55.7941008,125.041765 C55.7941008,126.068006 54.961835,126.9 53.9352331,126.9 L16.2591162,126.9 C15.2322658,126.9 14.4,126.068006 14.4,125.041765 C14.4,124.015523 15.2322658,123.183529 16.2591162,123.183529 L53.9349846,123.183529 C54.961835,123.183529 55.7941008,124.015523 55.7941008,125.041765 Z M78.9046675,78.9247059 L83.8619793,78.9247059 C84.8888298,78.9247059 85.7210956,78.092712 85.7210956,77.0664706 C85.7210956,76.0402292 84.8888298,75.2082353 83.8619793,75.2082353 L78.9046675,75.2082353 C77.877817,75.2082353 77.0457998,76.0402292 77.0457998,77.0664706 C77.0457998,78.092712 77.8780656,78.9247059 78.9046675,78.9247059 Z M81.9588677,37.0164706 L86.9161796,37.0164706 C87.94303,37.0164706 88.7752958,36.1844767 88.7752958,35.1582353 C88.7752958,34.1319939 87.94303,33.3 86.9161796,33.3 L81.9588677,33.3 C80.9320173,33.3 80.1,34.1319939 80.1,35.1582353 C80.1,36.1844767 80.9322658,37.0164706 81.9588677,37.0164706 Z M76.4262601,100.207059 L65.5200746,100.207059 C64.4932241,100.207059 63.6609583,101.039053 63.6609583,102.065294 C63.6609583,103.091536 64.4932241,103.923529 65.5200746,103.923529 L76.4262601,103.923529 C77.4531105,103.923529 78.2851278,103.091536 78.2851278,102.065294 C78.2851278,101.039053 77.452862,100.207059 76.4262601,100.207059 Z M86.3408838,92.7741176 L65.5200746,92.7741176 C64.4932241,92.7741176 63.6609583,93.6061115 63.6609583,94.6323529 C63.6609583,95.6585944 64.4932241,96.4905882 65.5200746,96.4905882 L86.3408838,96.4905882 C87.3677342,96.4905882 88.2,95.6585944 88.2,94.6323529 C88.2,93.6061115 87.3677342,92.7741176 86.3408838,92.7741176 Z M17.1591162,56.8242021 L22.1164281,56.8242021 C23.1432785,56.8242021 23.9755443,55.9922082 23.9755443,54.9659668 C23.9755443,53.9397254 23.1432785,53.1077315 22.1164281,53.1077315 L17.1591162,53.1077315 C16.1322658,53.1077315 15.3,53.9397254 15.3,54.9659668 C15.3,55.9922082 16.1322658,56.8242021 17.1591162,56.8242021 Z"
                id="Shape"
                fill="#BEC8F7"
                fillRule="nonzero"
              />
              <g id="Group-4" transform="translate(108.000000, 32.400000)" />
              <g
                id="Group-6"
                transform="translate(106.200000, 31.500000)"
                fillRule="nonzero"
              >
                <g id="Group-5">
                  <circle
                    id="Oval"
                    fill="#D3DCFB"
                    cx="11.25"
                    cy="11.25"
                    r="11.25"
                  />
                  <path
                    d="M4.52829731,11.2496875 C4.52829731,5.8134375 8.37964397,1.2775 13.5,0.2284375 C12.7687435,0.0784375 12.0112704,0 11.2360074,0 C5.03047047,0 0,5.036875 0,11.2496875 C0,17.463125 5.03047047,22.5 11.2360074,22.5 C12.0115825,22.5 12.7687435,22.42125 13.5,22.2715625 C8.37964397,21.2225 4.52829731,16.6865625 4.52829731,11.2496875 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                  <path
                    d="M10.0719389,17.1 C9.46127288,17.1 8.87547564,16.8450576 8.44380325,16.391002 L4.27444973,12.0085746 C3.37518342,11.0633515 3.37518342,9.53111549 4.27444973,8.58589239 C5.17371604,7.64099199 6.63176169,7.64066928 7.53072097,8.5862151 L10.0716319,11.2569786 L14.9689336,6.10907869 C15.868507,5.16353287 17.3259386,5.16385558 18.2255119,6.10875597 C19.1247782,7.05397908 19.1247782,8.58653781 18.2258189,9.53143821 L11.7003815,16.391002 C11.2684021,16.8450576 10.6829119,17.1 10.0719389,17.1 Z"
                    id="Path"
                    fill="#167CC1"
                  />
                  <path
                    d="M50.5647887,10.135395 L27.6376056,10.135395 C26.325,10.135395 25.2608451,9.07794 25.2608451,7.772895 C25.2608451,6.46785 26.325,5.410395 27.6376056,5.410395 L50.5647887,5.410395 C51.8773944,5.410395 52.9415493,6.46785 52.9415493,7.772895 C52.9415493,9.07794 51.8773944,10.135395 50.5647887,10.135395 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                  <path
                    d="M39.7774648,17.694765 L27.6376056,17.694765 C26.325,17.694765 25.2608451,16.636995 25.2608451,15.332265 C25.2608451,14.02722 26.325,12.969765 27.6376056,12.969765 L39.7774648,12.969765 C41.0900704,12.969765 42.1542254,14.02722 42.1542254,15.332265 C42.1542254,16.63731 41.0900704,17.694765 39.7774648,17.694765 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                </g>
                <g id="Group-5-Copy" transform="translate(0.000000, 35.100000)">
                  <circle
                    id="Oval"
                    fill="#415AA8"
                    cx="11.25"
                    cy="11.25"
                    r="11.25"
                  />
                  <path
                    d="M4.52829731,11.2496875 C4.52829731,5.8134375 8.37964397,1.2775 13.5,0.2284375 C12.7687435,0.0784375 12.0112704,0 11.2360074,0 C5.03047047,0 0,5.036875 0,11.2496875 C0,17.463125 5.03047047,22.5 11.2360074,22.5 C12.0115825,22.5 12.7687435,22.42125 13.5,22.2715625 C8.37964397,21.2225 4.52829731,16.6865625 4.52829731,11.2496875 Z"
                    id="Path"
                    fill="#1C2E7A"
                  />
                  <path
                    d="M10.0719389,17.1 C9.46127288,17.1 8.87547564,16.8450576 8.44380325,16.391002 L4.27444973,12.0085746 C3.37518342,11.0633515 3.37518342,9.53111549 4.27444973,8.58589239 C5.17371604,7.64099199 6.63176169,7.64066928 7.53072097,8.5862151 L10.0716319,11.2569786 L14.9689336,6.10907869 C15.868507,5.16353287 17.3259386,5.16385558 18.2255119,6.10875597 C19.1247782,7.05397908 19.1247782,8.58653781 18.2258189,9.53143821 L11.7003815,16.391002 C11.2684021,16.8450576 10.6829119,17.1 10.0719389,17.1 Z"
                    id="Path"
                    fill="#D3DCFB"
                  />
                  <path
                    d="M59.5647887,10.135395 L27.6376056,10.135395 C26.325,10.135395 25.2608451,9.07794 25.2608451,7.772895 C25.2608451,6.46785 26.325,5.410395 27.6376056,5.410395 L59.5647887,5.410395 C60.8773944,5.410395 61.9415493,6.46785 61.9415493,7.772895 C61.9415493,9.07794 60.8773944,10.135395 59.5647887,10.135395 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                  <path
                    d="M54.1774648,17.694765 L27.6376056,17.694765 C26.325,17.694765 25.2608451,16.636995 25.2608451,15.332265 C25.2608451,14.02722 26.325,12.969765 27.6376056,12.969765 L54.1774648,12.969765 C55.4900704,12.969765 56.5542254,14.02722 56.5542254,15.332265 C56.5542254,16.63731 55.4900704,17.694765 54.1774648,17.694765 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                </g>
                <g id="Group-5-Copy">
                  <circle
                    id="Oval"
                    fill="#415AA8"
                    cx="11.25"
                    cy="11.25"
                    r="11.25"
                  />
                  <path
                    d="M4.52829731,11.2496875 C4.52829731,5.8134375 8.37964397,1.2775 13.5,0.2284375 C12.7687435,0.0784375 12.0112704,0 11.2360074,0 C5.03047047,0 0,5.036875 0,11.2496875 C0,17.463125 5.03047047,22.5 11.2360074,22.5 C12.0115825,22.5 12.7687435,22.42125 13.5,22.2715625 C8.37964397,21.2225 4.52829731,16.6865625 4.52829731,11.2496875 Z"
                    id="Path"
                    fill="#1C2E7A"
                  />
                  <path
                    d="M10.0719389,17.1 C9.46127288,17.1 8.87547564,16.8450576 8.44380325,16.391002 L4.27444973,12.0085746 C3.37518342,11.0633515 3.37518342,9.53111549 4.27444973,8.58589239 C5.17371604,7.64099199 6.63176169,7.64066928 7.53072097,8.5862151 L10.0716319,11.2569786 L14.9689336,6.10907869 C15.868507,5.16353287 17.3259386,5.16385558 18.2255119,6.10875597 C19.1247782,7.05397908 19.1247782,8.58653781 18.2258189,9.53143821 L11.7003815,16.391002 C11.2684021,16.8450576 10.6829119,17.1 10.0719389,17.1 Z"
                    id="Path"
                    fill="#D3DCFB"
                  />
                  <path
                    d="M59.5647887,10.135395 L27.6376056,10.135395 C26.325,10.135395 25.2608451,9.07794 25.2608451,7.772895 C25.2608451,6.46785 26.325,5.410395 27.6376056,5.410395 L59.5647887,5.410395 C60.8773944,5.410395 61.9415493,6.46785 61.9415493,7.772895 C61.9415493,9.07794 60.8773944,10.135395 59.5647887,10.135395 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                  <path
                    d="M54.1774648,17.694765 L27.6376056,17.694765 C26.325,17.694765 25.2608451,16.636995 25.2608451,15.332265 C25.2608451,14.02722 26.325,12.969765 27.6376056,12.969765 L54.1774648,12.969765 C55.4900704,12.969765 56.5542254,14.02722 56.5542254,15.332265 C56.5542254,16.63731 55.4900704,17.694765 54.1774648,17.694765 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                </g>
                <g
                  id="Group-5-Copy-2"
                  transform="translate(0.000000, 71.100000)"
                >
                  <circle
                    id="Oval"
                    fill="#D3DCFB"
                    cx="11.25"
                    cy="11.25"
                    r="11.25"
                  />
                  <path
                    d="M4.52829731,11.2496875 C4.52829731,5.8134375 8.37964397,1.2775 13.5,0.2284375 C12.7687435,0.0784375 12.0112704,0 11.2360074,0 C5.03047047,0 0,5.036875 0,11.2496875 C0,17.463125 5.03047047,22.5 11.2360074,22.5 C12.0115825,22.5 12.7687435,22.42125 13.5,22.2715625 C8.37964397,21.2225 4.52829731,16.6865625 4.52829731,11.2496875 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                  <path
                    d="M59.5647887,10.135395 L27.6376056,10.135395 C26.325,10.135395 25.2608451,9.07794 25.2608451,7.772895 C25.2608451,6.46785 26.325,5.410395 27.6376056,5.410395 L59.5647887,5.410395 C60.8773944,5.410395 61.9415493,6.46785 61.9415493,7.772895 C61.9415493,9.07794 60.8773944,10.135395 59.5647887,10.135395 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                  <path
                    d="M39.7774648,17.694765 L27.6376056,17.694765 C26.325,17.694765 25.2608451,16.636995 25.2608451,15.332265 C25.2608451,14.02722 26.325,12.969765 27.6376056,12.969765 L39.7774648,12.969765 C41.0900704,12.969765 42.1542254,14.02722 42.1542254,15.332265 C42.1542254,16.63731 41.0900704,17.694765 39.7774648,17.694765 Z"
                    id="Path"
                    fill="#BEC8F7"
                  />
                </g>
              </g>
              <g id="Group">
                <path
                  d="M3.6,0 L182.7,0 C184.688225,1.59719458e-14 186.3,1.6117749 186.3,3.6 L186.3,20.7 L186.3,20.7 L0,20.7 L0,3.6 C-2.4348735e-16,1.6117749 1.6117749,1.25340945e-15 3.6,0 Z"
                  id="Rectangle"
                  fill="#2A428C"
                />
                <g
                  id="Group-8"
                  transform="translate(11.700000, 6.300000)"
                  fill="#3C58A0"
                  fillRule="nonzero"
                >
                  <path
                    d="M127.753884,7.2 L86.446116,7.2 C84.9294406,7.2 83.7,5.99120562 83.7,4.5 C83.7,3.00879438 84.9294406,1.8 86.446116,1.8 L127.753884,1.8 C129.270559,1.8 130.5,3.00879438 130.5,4.5 C130.5,5.99120562 129.270559,7.2 127.753884,7.2 Z"
                    id="Path"
                  />
                  <path
                    d="M161.075591,7.2 L157.524409,7.2 C156.019723,7.2 154.8,5.99120562 154.8,4.5 C154.8,3.00879438 156.019723,1.8 157.524409,1.8 L161.075591,1.8 C162.580277,1.8 163.8,3.00879438 163.8,4.5 C163.8,5.99120562 162.580277,7.2 161.075591,7.2 Z"
                    id="Path"
                  />
                  <path
                    d="M8.1,4.05 C8.1,6.28673919 6.28673919,8.1 4.05,8.1 C1.81326081,8.1 0,6.28673919 0,4.05 C0,1.81326081 1.81326081,0 4.05,0 C6.28673919,0 8.1,1.81326081 8.1,4.05 Z"
                    id="Path"
                  />
                  <path
                    d="M19.8,4.05 C19.8,6.28673919 17.9867392,8.1 15.75,8.1 C13.5132608,8.1 11.7,6.28673919 11.7,4.05 C11.7,1.81326081 13.5132608,0 15.75,0 C17.9867392,0.000371798403 19.8,1.81326081 19.8,4.05 Z"
                    id="Path"
                  />
                  <path
                    d="M31.5,4.05 C31.5,6.28673919 29.6867392,8.1 27.45,8.1 C25.2132608,8.1 23.4,6.28673919 23.4,4.05 C23.4,1.81326081 25.2132608,0 27.45,0 C29.6867392,0.000371798403 31.5,1.81326081 31.5,4.05 Z"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
export default TryAgainIcon
