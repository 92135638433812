import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { Input } from '@/components/form'
import { Button } from '@/components'
import './Composer.scss'

const ComposerMessages = defineMessages({
  placeholderReply: {
    defaultMessage: 'Type reply...',
    description:
      'Placeholder text for the input field when replying to messages.',
    id: 'Composer.placeholderReply',
  },
  reply: {
    defaultMessage: 'Reply',
    description: 'Button to send the reply in messages.',
    id: 'Composer.reply',
  },
})

@injectIntl
export default class Composer extends Component {
  state = {
    message: '',
  }
  get readyToSend() {
    return this.state.message !== ''
  }
  onChange = (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }
  onKeyPress = (e) => {
    if (e.charCode === 13 && !e.shiftKey) {
      e.preventDefault()
      this.send()
    }
  }
  send = (e) => {
    e && e.preventDefault()
    if (!this.readyToSend) return
    this.props.onSend(this.state.message)
    this.setState({ message: '' })
  }
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <form onSubmit={this.send} className="Composer">
        <div className="control Composer-control">
          <Input
            autoComplete="off"
            id="message"
            label="Message"
            labelPosition="after"
            name="message"
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            placeholder={formatMessage(ComposerMessages.placeholderReply)}
            value={this.state.message}
          />
        </div>
        <p className="has-text-right">
          <Button disabled={!this.readyToSend} isSuccess submit>
            {formatMessage(ComposerMessages.reply)}
          </Button>
        </p>
      </form>
    )
  }
}
