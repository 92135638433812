import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import { Button, GateCard, GateCardBody } from '@/components'
import './NotFoundPage.scss'

const NotFoundPageMessages = defineMessages({
  backToDashboard: {
    defaultMessage: 'Back to Learner Dashboard',
    description:
      'Button to navigate to the dashboard page from the not found page.',
    id: 'NotFoundPage.backToDashboard',
  },
  title: {
    defaultMessage: `Something's not right.`,
    description: 'Title displayed on the not found page.',
    id: 'NotFoundPage.title',
  },
})

@injectIntl
@inject('uiStore')
@observer
export default class NotFoundPage extends Component {
  componentDidMount() {
    const { uiStore } = this.props
    uiStore.updateTitle('Page Not Found')
  }
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="NotFoundPage PageWrapper">
        <div className="container Container">
          <GateCard
            className="NotFoundPage-gate"
            isTitled
            title={formatMessage(NotFoundPageMessages.title)}
          >
            <GateCardBody>
              <Button
                className="NotFoundPage-backToDashboard"
                isLink
                isPrimary
                isWider
                to="/"
              >
                {formatMessage(NotFoundPageMessages.backToDashboard)}
              </Button>
            </GateCardBody>
          </GateCard>
        </div>
      </div>
    )
  }
}
