import React, { Component } from 'react'
import { Heading } from '@/components'
import { defineMessages, injectIntl } from 'react-intl'

import './ScormLogoutBanner.scss'

const ScormLogoutBannerMessages = defineMessages({
  body: {
    defaultMessage: `You may now close the tab or window. To relaunch this course, please return to your organization's portal.`,
    description: 'Body text on the SCORM Logout Banner page',
    id: 'ScormLogoutBanner.body',
  },
  header: {
    defaultMessage: 'You have been logged out of the course.',
    description: 'Header on the SCORM Logout Banner page',
    id: 'ScormLogoutBanner.header',
  },
})

@injectIntl
export default class ScormLogoutBanner extends Component {
  state = {
    isPlayingVideo: false,
  }
  handleVideoClose = () => {
    this.setState({ isPlayingVideo: false })
  }
  handleVideoOpen = () => {
    this.setState({ isPlayingVideo: true })
  }
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="ScormLogoutBanner">
        <div className="ScormLogoutBanner__text">
          <div className="ScormLogoutBanner__text-content">
            <Heading is1>
              {formatMessage(ScormLogoutBannerMessages.header)}
            </Heading>
            <div className="ScormLogoutBanner__text-body">
              <p>{formatMessage(ScormLogoutBannerMessages.body)}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
