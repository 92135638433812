import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { Heading, Modal } from '@/components'
import './NegativeStreakModal.scss'

const NegativeStreakModalMessages = defineMessages({
  copy1: {
    defaultMessage: `All the answers can be sourced from the material - looking for the answer is a great way to learn.`,
    description:
      'Description displayed within the negative streak modal when a user incorrectly answers consecutive activities (copy 1).',
    id: 'NegativeStreakModal.copy1',
  },
  copy2: {
    defaultMessage: `By the way, this course is adjusting each new Practice question to an optimal level for you. Don't worry about getting a few wrong - the course will fill in those gaps for you.`,
    description:
      'Description displayed within the negative streak modal when a user incorrectly answers consecutive activities (copy 2).',
    id: 'NegativeStreakModal.copy2',
  },
  practice: {
    defaultMessage: 'Stay on current tab',
    description:
      'Button to close the negative streak modal when a user incorrectly answers consecutive activities.',
    id: 'NegativeStreakModal.practice',
  },
  read: {
    defaultMessage: 'Read',
    description:
      'Button to navigate to the Read tab from the negative streak modal when a user incorrectly answers consecutive activities.',
    id: 'NegativeStreakModal.read',
  },
  title: {
    defaultMessage: 'Need help?',
    description:
      'Title displayed within the negative streak modal when a user incorrectly answers consecutive activities.',
    id: 'NegativeStreakModal.title',
  },
  watch: {
    defaultMessage: 'Watch',
    description:
      'Button to navigate to the Watch tab from the negative streak modal when a user incorrectly answers consecutive activities.',
    id: 'NegativeStreakModal.watch',
  },
})

@injectIntl
@inject('uiStore')
export default class NegativeStreakModal extends Component {
  onConfirm = () => {
    const { onConfirm, uiStore } = this.props
    if (onConfirm) {
      onConfirm()
    }
    uiStore.scrollToTop()
  }
  render() {
    const { intl, isOpen, readTabURL, watchTabURL } = this.props
    const { formatMessage } = intl
    return (
      <Modal
        className="NegativeStreakModal"
        contentLabel="Negative Streak Modal"
        isOpen={isOpen}
      >
        <Modal.Header>
          <Heading>{formatMessage(NegativeStreakModalMessages.title)}</Heading>
        </Modal.Header>
        <Modal.Body>
          <p>{formatMessage(NegativeStreakModalMessages.copy1)}</p>
          <br />
          <p>{formatMessage(NegativeStreakModalMessages.copy2)}</p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button
            key="NegativeStreakModal-confirmButton"
            onClick={this.onConfirm}
            isSecondary
          >
            {formatMessage(NegativeStreakModalMessages.practice)}
          </Modal.Button>
          {readTabURL ? (
            <Modal.Button
              key="NegativeStreakModal-readButton"
              onClick={this.onConfirm}
              to={readTabURL}
            >
              {formatMessage(NegativeStreakModalMessages.read)}
            </Modal.Button>
          ) : null}
          {watchTabURL ? (
            <Modal.Button
              key="NegativeStreakModal-watchButton"
              onClick={this.onConfirm}
              to={watchTabURL}
            >
              {formatMessage(NegativeStreakModalMessages.watch)}
            </Modal.Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}
