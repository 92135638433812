import React from 'react'
import cx from 'classnames'

import { Button, CheckboxCustom, Heading, Input } from '@/components'

import {
  SETTINGS_CATEGORY_EXAM,
  EXAM_CHOICES,
  EXAM_NAME,
  EXAM_PASS_DEFAULT,
  EXAM_PASS_NAME,
} from './InstanceSettingsPageConstants'

const InstanceSettingsPageExam = ({
  errorExamPass,
  examPass,
  hasExamSettings,
  isChecked,
  isEnabledExam,
  isLoading,
  onChange,
  onSave,
}) => {
  return (
    <div className="InstanceSettingsPage__setting">
      <div className="InstanceSettingsPage__setting-item">
        <Heading is4 className="InstanceSettingsPage__setting-title">
          Exam
        </Heading>
        <span className="InstanceSettingsPage__setting-subtitle">
          Require Learners to pass an Exam prior to completing the course.
          {!hasExamSettings ? (
            <span className="InstanceSettingsPage__setting-subtitle-contact">
              Please contact Fulcrum Client Success at clients@fulcrumlabs.ai to
              learn more about this product add-on.
            </span>
          ) : null}
        </span>
        {hasExamSettings &&
          EXAM_CHOICES.map((choice, i) => (
            <div
              className={cx('InstanceSettingsPage__exam', {
                'InstanceSettingsPage__setting-disabled':
                  !hasExamSettings || isEnabledExam,
              })}
              key={`InstanceSettingsPage__exam-${i}`}
            >
              <CheckboxCustom
                checked={isChecked(EXAM_NAME, choice.value)}
                className="InstanceSettingsPage-value--is-bold"
                disabled={!hasExamSettings || isEnabledExam}
                isRadio
                name={EXAM_NAME}
                onChange={onChange}
                value={choice.value}
              >
                <span className="InstanceSettingsPage__value-label">
                  <span className="InstanceSettingsPage__value-text">
                    {choice.text}
                  </span>
                </span>
              </CheckboxCustom>
            </div>
          ))}
      </div>
      {hasExamSettings && isChecked(EXAM_NAME, true) ? (
        <div className="InstanceSettingsPage__setting-item">
          <span className="InstanceSettingsPage__setting-subtitle">
            Percentage of questions answered correctly required to pass the
            Exam. Enter an integer between 0 and 100.
          </span>
          <div>
            <div className="InstanceSettingsPage__exam-pass">
              <Input
                className="InstanceSettingsPage__exam-pass-input"
                name={EXAM_PASS_NAME}
                onChange={onChange}
                value={examPass || EXAM_PASS_DEFAULT}
              />
            </div>
            {errorExamPass ? (
              <span className="InstanceSettingsPage-input-error">
                * Exam pass percentage should be between 0 ~ 100
              </span>
            ) : null}
          </div>
        </div>
      ) : null}
      {hasExamSettings && (
        <Button
          className="InstanceSettingsPage__setting-save"
          disabled={!hasExamSettings || errorExamPass}
          isLoading={isLoading}
          isPrimary
          name={SETTINGS_CATEGORY_EXAM}
          onClick={onSave}
        >
          Save
        </Button>
      )}
    </div>
  )
}

export default InstanceSettingsPageExam
