import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import qs from 'query-string'
import { inject, observer } from 'mobx-react'
import { ActivityModal, Loader } from '@/components'
import { ACTIVITY_TYPES } from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import ActivityDetailsModal from '@/admin/modals/ActivityDetailsModal/ActivityDetailsModal'
import InstanceContentPageSidebar from './InstanceContentPageSidebar'
import InstanceContentPageUnits from './InstanceContentPageUnits'
import './InstanceContentPage.scss'

const {
  dragPhrase: dragPhraseType,
  hotspot: hotspotType,
  matching: matchingType,
  multipleChoice: multipleChoiceType,
  numericInput: numericInputType,
  singleChoice: singleChoiceType,
  sortStack: sortStackType,
  textInput: textInputType,
} = ACTIVITY_TYPES

const ACTIVITY_TYPE_LABELS = {
  [dragPhraseType]: 'Categorization',
  [hotspotType]: 'Hotspot',
  [matchingType]: 'Matching',
  [multipleChoiceType]: 'Multiple Response',
  [numericInputType]: 'Numeric Input',
  [singleChoiceType]: 'Multiple Choice',
  [sortStackType]: 'Sort Stack',
  [textInputType]: 'Text Input',
}

const ACTIVITY_TYPE_MAPPING = {
  activityDragPhrase: dragPhraseType,
  hot_spot: hotspotType,
  matching: matchingType,
  multiple_choice: multipleChoiceType,
  single_choice: singleChoiceType,
  sort_stack: sortStackType,
  text_input: textInputType,
  textInputInteger: numericInputType,
}

const RANK_TYPE_LABELS = {
  'all-star': 'Predictive',
  'at-risk': 'Problematic',
  good: 'Effective',
}

export function __getActivityType(activityType, activityChoiceType = null) {
  const type = ACTIVITY_TYPE_MAPPING[activityType]
  if (
    type === ACTIVITY_TYPES.multipleChoice &&
    activityChoiceType === 'radio'
  ) {
    return ACTIVITY_TYPES.singleChoice
  }
  return type || 'null'
}

function __filterUnits(units, filters) {
  const { ranks: rankFilters, types: typeFilters, units: unitFilters } = filters
  const hasActivityFilters = rankFilters || typeFilters
  const hasFilters = hasActivityFilters || unitFilters
  const filteredUnits = []
  if (hasFilters) {
    units.forEach((unit) => {
      const { id: unitId, sections, ...restUnit } = unit
      const filteredSections = []
      if (hasActivityFilters) {
        sections.forEach((section) => {
          const { activitiesByLo: activities, ...restSection } = section
          const filteredSection = { activitiesByLo: {}, ...restSection }
          Object.keys(activities).forEach((loId) => {
            const filteredActivities = []
            activities[loId]['items'].forEach((activity) => {
              const { activityType: type, rank: activityRank } = activity
              const activityType = __getActivityType(type)
              if (
                (!typeFilters &&
                  rankFilters &&
                  rankFilters.indexOf(activityRank) !== -1) ||
                (!rankFilters &&
                  typeFilters &&
                  typeFilters.indexOf(activityType) !== -1) ||
                (rankFilters &&
                  typeFilters &&
                  rankFilters.indexOf(activityRank) !== -1 &&
                  typeFilters.indexOf(activityType) !== -1)
              ) {
                filteredActivities.push(activity)
              }
            })
            if (filteredActivities.length) {
              filteredSection['activitiesByLo'][loId] = {
                items: filteredActivities,
                loTitle: activities[loId]['loTitle'],
              }
            }
          })
          if (Object.keys(filteredSection['activitiesByLo']).length) {
            filteredSections.push(filteredSection)
          }
        })
      }
      if (
        (!unitFilters && filteredSections.length) ||
        (unitFilters &&
          unitFilters.indexOf(unitId) !== -1 &&
          (!hasActivityFilters || filteredSections.length))
      ) {
        filteredUnits.push({
          id: unitId,
          sections: hasActivityFilters ? filteredSections : sections,
          ...restUnit,
        })
      }
    })
  }
  return hasFilters ? filteredUnits : units
}

export function __getActivityTypeLabel(activityType) {
  return ACTIVITY_TYPE_LABELS[activityType]
}

export function __sortByOrder(a, b) {
  const { order: aO } = a
  const { order: bO } = b
  if (aO < bO) return -1
  if (aO > bO) return 1
  return 0
}

function __sortByFilter(a, b) {
  const order = {
    ranks: 2,
    types: 1,
    units: 0,
  }
  if (order[a] < order[b]) return -1
  if (order[a] > order[b]) return 1
  return 0
}

function __getRankLabel(rank) {
  return RANK_TYPE_LABELS[rank]
}

function __getFilterOptions(units, filters = {}) {
  const { ranks: rankFilters, types: typeFilters, units: unitFilters } = filters
  const rankValues = {
    'all-star': {
      count: 0,
      label: __getRankLabel('all-star'),
      order: 2,
      value: 'all-star',
    },
    'at-risk': {
      count: 0,
      label: __getRankLabel('at-risk'),
      order: 0,
      value: 'at-risk',
    },
    good: { count: 0, label: __getRankLabel('good'), order: 1, value: 'good' },
  }
  const typeValues = {
    [dragPhraseType]: {
      count: 0,
      label: __getActivityTypeLabel(dragPhraseType),
      order: 0,
      value: dragPhraseType,
    },
    [hotspotType]: {
      count: 0,
      label: __getActivityTypeLabel(hotspotType),
      order: 1,
      value: hotspotType,
    },
    [matchingType]: {
      count: 0,
      label: __getActivityTypeLabel(matchingType),
      order: 2,
      value: matchingType,
    },
    [multipleChoiceType]: {
      count: 0,
      label: __getActivityTypeLabel(multipleChoiceType),
      order: 3,
      value: multipleChoiceType,
    },
    [numericInputType]: {
      count: 0,
      label: __getActivityTypeLabel(numericInputType),
      order: 4,
      value: numericInputType,
    },
    [singleChoiceType]: {
      count: 0,
      label: __getActivityTypeLabel(singleChoiceType),
      order: 5,
      value: singleChoiceType,
    },
    [sortStackType]: {
      count: 0,
      label: __getActivityTypeLabel(sortStackType),
      order: 6,
      value: sortStackType,
    },
    [textInputType]: {
      count: 0,
      label: __getActivityTypeLabel(textInputType),
      order: 7,
      value: textInputType,
    },
  }
  const unitValues = []
  let uniqueActivitiesCount = 0
  units.forEach((unit, unitIndex) => {
    const { id: unitId, sections, title: unitTitle } = unit
    const unitValue = {
      count: 0,
      key: `unit-${unitIndex}`,
      label: unitTitle,
      order: unitIndex,
      value: unitId,
    }
    sections.forEach((section) => {
      const { activitiesByLo: activities } = section
      const uniqueActivities = {}
      Object.keys(activities).forEach((loId) => {
        activities[loId]['items'].forEach((activity) => {
          const {
            activityType: type,
            id: activityId,
            rank: activityRank,
          } = activity
          const activityType = __getActivityType(type)
          let isMatchUnit = false
          if (!uniqueActivities[activityId]) {
            if (unitFilters && unitFilters.indexOf(unitId) !== -1) {
              isMatchUnit = true
            }
            if (activityRank && hasOwnProperty(rankValues, activityRank)) {
              if (isMatchUnit) {
                rankValues[activityRank].count++
              }
            }
            if (activityType && hasOwnProperty(typeValues, activityType)) {
              if (isMatchUnit) {
                typeValues[activityType].count++
              }
            }
            if (
              isMatchUnit &&
              (!rankFilters || rankFilters.indexOf(activityRank) !== -1) &&
              (!typeFilters || typeFilters.indexOf(activityType) !== -1)
            ) {
              uniqueActivitiesCount++
            }
            unitValue.count++
            uniqueActivities[activityId] = true
          }
        })
      })
    })
    unitValues.push(unitValue)
  })
  return {
    uniqueActivitiesCount,
    filterOptions: {
      ranks: {
        label: 'Question Status',
        order: 1,
        values: Object.keys(rankValues)
          .sort((a, b) => __sortByOrder(rankValues[a], rankValues[b]))
          .map((key) => ({ key, ...rankValues[key] })),
      },
      types: {
        label: 'Question Type',
        order: 2,
        values: Object.keys(typeValues)
          .sort((a, b) => __sortByOrder(typeValues[a], typeValues[b]))
          .map((key) => ({ key, ...typeValues[key] })),
      },
      units: {
        label: 'Unit',
        order: 0,
        values: unitValues,
      },
    },
  }
}

function __getUnitTitleById(units, unitId) {
  const unit = units.filter((unit) => unit.id === unitId)[0]
  return unit ? unit.title : 'Untitled Unit'
}

@inject('adminStore', 'uiStore')
@observer
export default class InstanceContentPage extends Component {
  state = {
    activityDetailsModal: null,
    activityModalImage: null,
    activityDetailsModalError: null,
    isOpenActivityDetailsModal: false,
    isOpenActivityImageModal: false,
    isOpenFiltersMobile: false,
    uniqueActivitiesCount: 0,
  }
  constructor(props) {
    super(props)
    const { adminStore, history, location, match } = this.props
    const { instance } = adminStore
    const { contentFilters } = instance
    const { search } = location
    const filters = qs.parse(search)
    const ranks = filters['ranks']
    const types = filters['types']
    const units = filters['units']
    if (ranks) {
      filters['ranks'] = ranks.split(',')
    }
    if (types) {
      filters['types'] = types.split(',')
    }
    if (hasOwnProperty(filters, 'units')) {
      filters['units'] = units
        .split(',')
        .filter((unitId) => unitId && unitId.trim() !== '' && !isNaN(unitId))
        .map((unitId) => parseInt(unitId))
        .slice(0, 1)
    }
    const updatedFilters = instance.setContentFilters({
      ...contentFilters,
      filters: { ...filters, ...contentFilters.filters },
    })
    const querystring = { ...updatedFilters.filters }
    Object.keys({ ...querystring }).forEach((key) => {
      const filterValue = instance.getContentFilter(key)
      querystring[key] = Array.isArray(filterValue)
        ? filterValue.join(',')
        : filterValue
    })
    history.push(`${match.url}?${qs.stringify(querystring)}`)
  }
  componentDidMount() {
    const { adminStore, uiStore } = this.props
    const { instance } = adminStore
    const { contentFilters, reportUnits } = instance
    const { filters } = contentFilters
    const { units: currentUnitFilterOptions } = filters || {}
    const unitId =
      currentUnitFilterOptions &&
      currentUnitFilterOptions.length &&
      currentUnitFilterOptions[0]
    if (reportUnits && reportUnits.length) return
    instance.loadReportUnits(unitId).then((units) => {
      if (units.length) {
        const { filterOptions, uniqueActivitiesCount } = __getFilterOptions(
          units,
          filters,
        )
        const { units: unitFilterOptions } = filterOptions
        const { values: unitFilterOptionValues } = unitFilterOptions
        if (Object.keys(filters).length) {
          const unitIds = unitFilterOptionValues.map(({ value: v }) => v)
          if (!unitId || unitIds.indexOf(unitId) === -1) {
            filters['units'] = []
          }
          instance.setContentFilters({
            filters,
            filteredUnits: __filterUnits(units, filters),
            filterOptions,
            uniqueActivitiesCount,
          })
        } else {
          filters['units'] = []
          instance.setContentFilters({
            filters,
            filterOptions,
            uniqueActivitiesCount,
          })
        }
        if (
          unitFilterOptionValues.length &&
          (!filters['units'] || !filters['units'].length)
        ) {
          this.onChangeFilterValue('units', unitFilterOptionValues[0].value)
        }
      }
    })

    uiStore.updateTitle('Course Content Analytics Page')
  }
  get units() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { reportUnits } = instance
    return reportUnits || []
  }
  onChangeFilterValue = (key, value, event) => {
    const { adminStore, history, match } = this.props
    const { instance } = adminStore
    const { contentFilters } = instance
    const { filters } = contentFilters
    const { url } = match
    const keyFilters = instance.getContentFilter(key)
    if (event) {
      event.preventDefault()
    }
    if (!keyFilters) {
      // ===== ADDS A FILTER AND VALUE =====
      filters[key] = []
      filters[key].push(value)
    } else if (Array.isArray(keyFilters) && keyFilters.indexOf(value) === -1) {
      // ===== ADDS A VALUE =====
      if (key === 'units') {
        filters[key] = [value]
        instance.setContentFilters({
          filters,
          filteredUnits: __filterUnits(this.units, { units: filters['units'] }),
        })
        instance.loadReportUnits(value).then(() => {
          const {
            filterOptions: _filterOptions,
            uniqueActivitiesCount: _uniqueActivitiesCount,
          } = __getFilterOptions(this.units, filters)
          const _filteredUnits = __filterUnits(this.units, filters)
          instance.setContentFilters({
            filters,
            filteredUnits: _filteredUnits,
            filterOptions: _filterOptions,
            uniqueActivitiesCount: _uniqueActivitiesCount,
          })
          const q = {}
          Object.keys(filters).forEach((filter) => {
            const qValues = filters[filter].join(',')
            q[filter] = qValues
          })
          history.push(`${url}?${qs.stringify(q)}`)
        })
      } else {
        filters[key].push(value)
      }
    } else if (value) {
      // ===== REMOVES A VALUE =====
      // one unit must always be selected
      // therefore only remove filter if key is not units
      // or unit filters selected is greater than 1
      if (key !== 'units' || (key === 'units' && filters['units'].length > 1)) {
        filters[key] = filters[key].filter(
          (filterValue) => filterValue !== value,
        )
        if (!filters[key].length) {
          delete filters[key]
        }
      } else if (key === 'units' && filters['units'].length === 1) {
        return
      }
    }
    if (key !== 'units') {
      const filteredUnits = __filterUnits(this.units, filters)
      const querystring = {}
      Object.keys(filters).forEach((filter) => {
        const querystringValues = filters[filter].join(',')
        querystring[filter] = querystringValues
      })
      const { filterOptions, uniqueActivitiesCount } = __getFilterOptions(
        this.units,
        filters,
      )
      instance.setContentFilters({
        filters,
        filterOptions,
        filteredUnits,
        uniqueActivitiesCount,
      })
      history.push(`${url}?${qs.stringify(querystring)}`)
    }
  }
  onClearFilters = (event) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { contentFilters } = instance
    const { filterOptions } = contentFilters
    const { units: unitFilterOptions } = filterOptions
    const { values: unitFilterOptionValues } = unitFilterOptions
    const filters = { units: [] }
    event.preventDefault()
    instance.setContentFilters({ filters }, { clear: true })
    if (unitFilterOptionValues.length) {
      this.onChangeFilterValue('units', unitFilterOptionValues[0].value)
    }
  }
  onClickCloseActivityModal = () => {
    const { uiStore } = this.props
    this.setState({
      activityDetailsModal: null,
      activityDetailsModalError: null,
      isOpenActivityDetailsModal: false,
    })
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  onClickOpenActivityModal = (activityId) => {
    const { adminStore } = this.props
    const { instance } = adminStore
    this.setState({
      activityDetailsModal: null,
      activityDetailsModalError: false,
      isOpenActivityDetailsModal: true,
      isLoadingActivityDetails: true,
    })
    instance
      .loadActivityDetails(activityId)
      .then((activityDetails) => {
        this.setState({
          activityDetailsModal: activityDetails,
          isLoadingActivityDetails: false,
        })
      })
      .catch((error) => {
        const { message } = error || {
          message: 'Could not load activity details.',
        }
        this.setState({
          activityDetailsModalError: message,
          isLoadingActivityDetails: false,
        })
      })
  }
  onOpenActivityImageModal = (e, image) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      isOpenActivityImageModal: true,
      activityModalImage: image,
    })
  }
  onCloseActivityImageModal = () => {
    const { uiStore } = this.props
    const { scrollY, scrollTop } = uiStore
    this.setState({
      isOpenActivityImageModal: false,
    })
    scrollY(null, scrollTop)
  }
  renderMain() {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { contentFilters, reportUnitIds } = instance
    const { filteredUnits, filterOptions, filters, uniqueActivitiesCount } =
      contentFilters
    const numParentFilters = Object.keys(filters).length
    const units = numParentFilters ? filteredUnits : this.units
    const isClearable =
      numParentFilters > 1 ||
      (numParentFilters === 1 &&
        filters['units'] &&
        filters['units'].length > 1)
    const isUnitClearable = filters['units'] && filters['units'].length > 1
    const isLoadingUnit =
      units && units.length && reportUnitIds.indexOf(units[0]['id']) === -1
    const currentUnitId =
      filters['units'] && filters['units'].length && filters['units'][0]
    const currentUnitFilterCounts =
      filterOptions['units'] &&
      filterOptions['units']['values'] &&
      filterOptions['units']['values'].filter(
        ({ value: filterOptionUnitId }) =>
          currentUnitId && currentUnitId === filterOptionUnitId,
      )[0]
    const currentUnitCount =
      currentUnitFilterCounts && currentUnitFilterCounts['count']
    return (
      <Fragment>
        <div className="InstanceContentPage-header">
          <div className="InstanceContentPage__filtered-by">
            <div className="InstanceContentPage__filtered-by-label">
              <span className="is-bold">Filtered By:&nbsp;</span>
              {isClearable ? (
                <a
                  className="InstanceContentPage__filtered-by-clear"
                  href="#"
                  onClick={this.onClearFilters}
                  role="button"
                >
                  Clear All
                </a>
              ) : null}
            </div>
            <div>
              {Object.keys(filters)
                .sort(__sortByFilter)
                .map((filter) =>
                  filters[filter].map((filterValue) => {
                    const key = `InstanceContentPage__filtered-by-${filter}-${filterValue}`
                    if (filter === 'ranks') {
                      return (
                        <span
                          className="InstanceContentPage__filtered-by-item"
                          key={key}
                        >
                          {__getRankLabel(filterValue)}
                          <a
                            className="InstanceAnalyticsPage__filtered-by-cancel"
                            href="#"
                            onClick={(event) =>
                              this.onChangeFilterValue(
                                filter,
                                filterValue,
                                event,
                              )
                            }
                          >
                            <i className="fa fa-close" />
                          </a>
                        </span>
                      )
                    } else if (filter === 'types') {
                      return (
                        <span
                          className="InstanceContentPage__filtered-by-item"
                          key={key}
                        >
                          {__getActivityTypeLabel(filterValue)}
                          <a
                            className="InstanceAnalyticsPage__filtered-by-cancel"
                            href="#"
                            onClick={(event) =>
                              this.onChangeFilterValue(
                                filter,
                                filterValue,
                                event,
                              )
                            }
                          >
                            <i className="fa fa-close" />
                          </a>
                        </span>
                      )
                    } else if (filter === 'units') {
                      return (
                        <span
                          className="InstanceContentPage__filtered-by-item"
                          key={key}
                        >
                          {__getUnitTitleById(this.units, filterValue)}
                          {isUnitClearable ? (
                            <a
                              className="InstanceAnalyticsPage__filtered-by-cancel"
                              href="#"
                              onClick={(event) =>
                                this.onChangeFilterValue(
                                  filter,
                                  filterValue,
                                  event,
                                )
                              }
                            >
                              <i className="fa fa-close" />
                            </a>
                          ) : null}
                        </span>
                      )
                    }
                  }),
                )}
            </div>
          </div>
          {!isLoadingUnit ? (
            <div className="InstanceContentPage-showing">
              <span className="is-bold">Showing:&nbsp;</span>
              <span>{`${uniqueActivitiesCount} of ${currentUnitCount} Questions`}</span>
            </div>
          ) : null}
        </div>
        <div className="InstanceContentPage-content">
          {!isLoadingUnit ? (
            <InstanceContentPageUnits
              onClickOpenActivityModal={this.onClickOpenActivityModal}
              units={units}
            />
          ) : (
            <Loader />
          )}
        </div>
      </Fragment>
    )
  }
  render() {
    const { adminStore } = this.props
    const {
      activityDetailsModal,
      activityModalImage,
      activityDetailsModalError,
      isLoadingActivityDetails,
      isOpenActivityDetailsModal,
      isOpenActivityImageModal,
    } = this.state
    const { instance } = adminStore
    const { contentFilters, isLoadingReportUnits, reportUnitIds } = instance
    const { filteredUnits, filters, filterOptions } = contentFilters
    const {
      activity,
      correctAnswers,
      incorrectAnswers,
      incorrectAnswerMetrics,
    } = activityDetailsModal || {}
    if (isLoadingReportUnits && _.isEmpty(this.units)) {
      return (
        <AdminLayout className="InstanceContentPage is-loading">
          <AdminLayout.Main isFullWidth>
            <Loader />
          </AdminLayout.Main>
        </AdminLayout>
      )
    }
    if (_.isEmpty(this.units)) {
      return (
        <AdminLayout className="InstanceContentPage">
          <AdminLayout.Main isFullWidth>
            <div className="InstanceContentPage-noUnits">
              Content results are still pending. Check back later.
            </div>
          </AdminLayout.Main>
        </AdminLayout>
      )
    }
    const numParentFilters = Object.keys(filters).length
    const units = numParentFilters ? filteredUnits : this.units
    const isLoadingUnit =
      units && units.length && reportUnitIds.indexOf(units[0]['id']) === -1
    return (
      <AdminLayout className="InstanceContentPage">
        <AdminLayout.Sidebar>
          <InstanceContentPageSidebar
            filters={filters}
            filterOptions={filterOptions}
            isLoadingReportUnits={isLoadingReportUnits}
            onChangeFilterValue={this.onChangeFilterValue}
          />
          {isLoadingUnit ? (
            <div className="InstanceContentPage__loader" />
          ) : null}
        </AdminLayout.Sidebar>
        <AdminLayout.Main id="main-content">
          {this.renderMain()}
        </AdminLayout.Main>
        {isOpenActivityImageModal ? (
          <ActivityModal
            image={activityModalImage}
            isOpen={isOpenActivityImageModal}
            isReview={false}
            onClose={this.onCloseActivityImageModal}
          />
        ) : null}
        {isOpenActivityDetailsModal ? (
          <ActivityDetailsModal
            activity={activity}
            activityModalImage={activityModalImage}
            correctAnswers={correctAnswers}
            error={activityDetailsModalError}
            incorrectAnswers={incorrectAnswers}
            incorrectAnswerMetrics={incorrectAnswerMetrics}
            isLoading={isLoadingActivityDetails}
            isOpen={isOpenActivityDetailsModal}
            onClose={() => this.onClickCloseActivityModal()}
            onOpenActivityImageModal={this.onOpenActivityImageModal}
          />
        ) : null}
      </AdminLayout>
    )
  }
}
