import React, { Component } from 'react'
import cx from 'classnames'
import { Button } from '@/components'
import { TransitionHeight } from '@/components/animations'
import InstanceContentPageLearningObjectives from './InstanceContentPageLearningObjectives'

export default class InstanceContentPageSection extends Component {
  state = { isActive: true }
  componentDidUpdate(prevProps) {
    const { section } = this.props
    const { isActive } = this.state
    const { section: prevSection } = prevProps
    const { id: sectionId } = section
    const { id: prevSectionId } = prevSection
    if (!isActive && sectionId !== prevSectionId) {
      this.setState({ isActive: true })
    }
  }
  onAccordionChange = (event) => {
    const { isActive } = this.state
    event.preventDefault()
    this.setState({ isActive: !isActive })
  }
  render() {
    const { onClickOpenActivityModal, section } = this.props
    const { isActive } = this.state
    const { activitiesByLo: activities, title: sectionTitle } = section
    return (
      <div className="InstanceContentPage__section">
        <Button
          className="InstanceContentPage__section-title"
          isText
          onClick={this.onAccordionChange}
        >
          <i
            className={cx('fa', {
              'fa-caret-down': isActive,
              'fa-caret-up': !isActive,
            })}
          />
          <span>{sectionTitle}</span>
        </Button>
        <TransitionHeight in={isActive}>
          <div className="InstanceContentPage__section-content">
            <InstanceContentPageLearningObjectives
              activities={activities}
              onClickOpenActivityModal={onClickOpenActivityModal}
            />
          </div>
        </TransitionHeight>
      </div>
    )
  }
}
