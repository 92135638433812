import React, { Component, Fragment } from 'react'
import cx from 'classnames'
import { Heading, Link, Modal } from '@/components'
import { CheckboxCustom, Input } from '@/components/form'
import addBulkUsersSingleCourseExample from '@/assets/csv/admin-single-course-add-bulk-users-example.csv'
import addBulkUsersMultiCourseExample from '@/assets/csv/admin-multi-course-add-bulk-users-example.csv'
import './NewUsersModal.scss'

const NewUsersModalDefaultValues = function (file, sendEmails = true) {
  return {
    file: file || null,
    sendEmails,
    url: null,
  }
}

export default class NewUsersModal extends Component {
  static defaultProps = {
    values: new NewUsersModalDefaultValues(),
  }
  onChange = (event) => {
    const { onChange } = this.props
    const { target } = event
    const { files } = target
    const file = files && files.length ? files[0] : null
    onChange(file)
  }
  onChangeSendEmails = () => {
    const { onChangeSendEmails, values } = this.props
    const { sendEmails } = values
    onChangeSendEmails(!sendEmails)
  }
  onClickNotifications = () => {
    const { onClose } = this.props
    onClose()
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  onConfirm = () => {
    const { onConfirm } = this.props
    onConfirm()
  }
  renderUpload = () => {
    const { error, isMultiCourse, isUploading, values } = this.props
    const { file, sendEmails } = values || { sendEmails: true }
    const filename = file && file.name ? file.name : ''
    const errorMessageUpload =
      error && error['upload'] ? error['upload'][0] : null
    const classNameField = cx('NewUsersModal-upload field', {
      'is-error': !!errorMessageUpload,
    })
    const classNameLabel = cx(
      'NewUsersModal-uploadButton Button button is-info',
      {
        'is-loading': isUploading,
      },
    )
    return (
      <div className={classNameField}>
        <Heading is3>Upload File</Heading>
        <form
          className="NewUsersModal-uploadForm"
          action="/content/{course_id}/sign-upload"
          method="POST"
          onSubmit={(event) => event.preventDefault()}
        >
          <div className="NewUsersModal-uploadInput">
            <Input
              placeholder="Select a file to upload..."
              readOnly
              tabIndex={-1}
              value={filename}
            />
          </div>
          <div className="NewUsersModal-uploadLabel">
            <Input
              id="NewUsersModal-uploadInput"
              name="NewUsersModal-uploadInput"
              type="file"
              onChange={(event) => this.onChange(event)}
            />
            <label
              className={classNameLabel}
              htmlFor="NewUsersModal-uploadInput"
            >
              Choose File
            </label>
          </div>
        </form>
        {errorMessageUpload ? (
          <div className="NewUsersModal-uploadError">
            <span>{`* ${errorMessageUpload}`}</span>
          </div>
        ) : null}
        <div className="NewUsersModal-uploadText">
          <span className="is-block">Max. file size is 2mb.</span>
          <span className="is-block">Max. users is 200.</span>
          {!isMultiCourse ? (
            <Fragment>
              <CheckboxCustom
                checked={sendEmails}
                className="NewUsersModal-sendEmails"
                isBlock
                name="NewUsersModal-sendEmails"
                onChange={this.onChangeSendEmails}
                text="Send emails to users upon successful bulk upload."
              />
              {sendEmails ? (
                <p className="NewUsersModal__send-emails-description">
                  Users will receive their username and login link via email. A
                  password reset link will be provided if passwords are not
                  defined in the CSV.
                </p>
              ) : (
                <p className="is-danger">
                  If passwords are not pre-defined and course settings does not
                  have the &quot;Password Reset&quot; field set to
                  &quot;Yes&quot;, credentials must be updated and delivered to
                  the users, or users must reset their passwords manually.
                </p>
              )}
            </Fragment>
          ) : null}
        </div>
      </div>
    )
  }
  renderErrorMultiCourse = () => {
    const { error } = this.props
    const errorMessageMultiCourse =
      error && error['isMulti'] ? error['isMulti'] : null
    return (
      <div className="NewUsersModal-errors">
        <div className="NewUsersModal-errorsRow">
          <Heading is2>{errorMessageMultiCourse}</Heading>
        </div>
      </div>
    )
  }
  renderBody = () => {
    const { error, isMultiCourse, successfulBulkUpload } = this.props
    const errorMessageMultiCourse = error && error['isMulti']

    if (successfulBulkUpload) {
      return (
        <div className="NewUsersModal-body">
          <p className="NewUsersModal-result">
            Bulk upload process has started, please check the{' '}
            <Link to="/admin/notifications" onClick={this.onClickNotifications}>
              Notifications Page
            </Link>{' '}
            for updates. It is located on the top right hand corner (
            <i className="fa fa-bell" />
            ).
          </p>
        </div>
      )
    }

    const csvHref = isMultiCourse
      ? addBulkUsersMultiCourseExample
      : addBulkUsersSingleCourseExample
    const csvDownloadFileName = `${
      isMultiCourse ? 'multi-course' : 'single-course'
    }-add-bulk-users-example.csv`

    return (
      <div className="NewUsersModal-body">
        <div className="NewUsersModal-sample">
          <a
            className="NewUsersModal-sampleButton"
            aria-label="See Sample CSV"
            href={csvHref}
            download={csvDownloadFileName}
          >
            <span className="NewUsersModal-sampleIcon">
              <i
                className="fa fa-file"
                aria-hidden="true"
                title="See Sample CSV"
              />
            </span>
            <span>See Sample CSV</span>
          </a>
        </div>
        {this.renderUpload()}
        {errorMessageMultiCourse ? this.renderErrorMultiCourse() : null}
      </div>
    )
  }
  render() {
    const {
      error,
      isLoading,
      isOpen,
      isUploading,
      successfulBulkUpload,
      values,
    } = this.props
    const { url } = values || {}
    const errorMessageConfirm =
      error && error['confirm'] ? error['confirm'][0] : null
    const errorMessageSave = error && error['save'] ? error['save'][0] : null
    const isDisabled = error || isUploading || !url
    return (
      <Modal
        className={cx('NewUsersModal', {
          'is-error': !!errorMessageSave,
        })}
        contentLabel="New Users Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>
            {!errorMessageSave ? 'Add Bulk Users' : 'Add Bulk Users Errors'}
          </Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          {!successfulBulkUpload ? (
            <Fragment>
              <Modal.Button isSecondary onClick={this.onClose}>
                Cancel
              </Modal.Button>
              <Modal.Button
                disabled={isDisabled}
                isLoading={isLoading}
                onClick={() => this.onConfirm()}
              >
                Add Users
              </Modal.Button>
            </Fragment>
          ) : (
            <Modal.Button onClick={this.onClose}>OK</Modal.Button>
          )}
          {errorMessageConfirm ? (
            <span className="NewUsersModal-confirmError">{`* ${errorMessageConfirm}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { NewUsersModalDefaultValues }
