import React, { Component } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { inject } from 'mobx-react'
import { withRouter } from 'react-router'
import { Heading, Modal } from '@/components'
import IdleTimer from 'react-idle-timer'
import './IdleModal.scss'

const IdleModalMessages = defineMessages({
  continue: {
    defaultMessage: 'Continue',
    description:
      'Button to continue learning and preventing being logged out from the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.continue',
  },
  exam: {
    defaultMessage: 'Are you still making progress in the exam?',
    description:
      'Description displayed while making progress in the exam within the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.exam',
  },
  practicing: {
    defaultMessage:
      'You may have left this page open by accident. You were practicing {sectionTitle} and we stopped seeing activity from you.',
    description:
      'Description displayed while learning on the practice tab within the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.practicing',
  },
  quiz: {
    defaultMessage:
      'You may have left this page open by accident. You were practicing in a quiz for {sectionTitle} and we stopped seeing activity from you.',
    description:
      'Description displayed while learning on the quiz tab within the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.quiz',
  },
  reading: {
    defaultMessage:
      'You may have left this page open by accident. You were reading {sectionTitle} and we stopped seeing activity from you.',
    description:
      'Description displayed while learning on the read tab within the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.reading',
  },
  session: {
    defaultMessage:
      'Your session is about to expire, please click "Continue" if you would like to remain logged in.',
    description:
      'Description displayed while on the platform and the global idle timeout has been reached for a user after being inactive for a specified period of time.',
    id: 'IdleModal.session',
  },
  snapshot: {
    defaultMessage: 'Are you still making progress in the pre-test?',
    description:
      'Description displayed while making progress in the pre-test within the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.snapshot',
  },
  title: {
    defaultMessage: 'Are you still learning?',
    description:
      'Title displayed on the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.title',
  },
  titleAdmin: {
    defaultMessage: 'Are you still working?',
    description:
      'Title displayed on the idle modal when a user has been inactive on Admin for a specified period of time.',
    id: 'IdleModal.titleAdmin',
  },
  watching: {
    defaultMessage:
      'You may have left this page open by accident. You were watching {sectionTitle} and we stopped seeing activity from you.',
    description:
      'Description displayed while learning on the watch tab within the idle modal when a user has been inactive for a specified period of time.',
    id: 'IdleModal.watching',
  },
})

const getTabMessage = (tab) => {
  if (tab === 'quiz') return IdleModalMessages['quiz']
  if (tab === 'practice') return IdleModalMessages['practicing']
  if (tab === 'exam') return IdleModalMessages['exam']
  if (tab === 'read') return IdleModalMessages['reading']
  if (tab === 'session') return IdleModalMessages['session']
  if (tab === 'snapshot') return IdleModalMessages['snapshot']
  if (tab === 'watch') return IdleModalMessages['watching']
  return {}
}

const IDLE_LOGOUT_THRESHOLD = 15 * 60 * 1000 // 15 minutes

@injectIntl
@withRouter
@inject('sessionStore', 'uiStore')
export default class IdleModal extends Component {
  onClose = () => {
    const { uiStore, onClose } = this.props
    if (onClose) {
      onClose()
    }
    uiStore.scrollY(null, uiStore.scrollTop)
  }
  onDoubleIdleLogout = () => {
    const { history, sessionStore } = this.props
    const { session } = sessionStore
    const { logoutUrl } = session || {}
    sessionStore.logout().then(() => {
      if (!logoutUrl) {
        history.push('/login?sessionExpired=true')
      }
    })
  }
  render() {
    const { intl, isOpen, sectionTitle, sessionStore, tab } = this.props
    const { formatMessage } = intl
    const { user } = sessionStore
    const { isCurrentRoleAdmin } = user || {}
    const hasNoSectionTitle =
      tab === 'exam' || tab === 'snapshot' || tab === 'session'
    return (
      <Modal
        className="IdleModal"
        contentLabel="Idle Modal"
        isOpen={isOpen}
        setScrollTop
      >
        <Modal.Header>
          <Heading>
            {isCurrentRoleAdmin
              ? formatMessage(IdleModalMessages.titleAdmin)
              : formatMessage(IdleModalMessages.title)}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <p>
            {hasNoSectionTitle ? (
              <FormattedMessage {...getTabMessage(tab)} />
            ) : (
              <FormattedMessage
                {...getTabMessage(tab)}
                values={{
                  sectionTitle: (
                    <span className="IdleModal-sectionTitle">
                      {sectionTitle}
                    </span>
                  ),
                }}
              />
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Button onClick={this.onClose}>
            {formatMessage(IdleModalMessages.continue)}
          </Modal.Button>
        </Modal.Footer>
        <IdleTimer
          onIdle={this.onDoubleIdleLogout}
          startOnLoad
          timeout={IDLE_LOGOUT_THRESHOLD}
        />
      </Modal>
    )
  }
}
