import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'
import { inject } from 'mobx-react'
import PropTypes from 'prop-types'

import { Label } from '@/components'
import './Pagination.scss'

class PaginationPrev extends Component {
  componentDidMount() {
    if (!this.$parentRef) return
    this.handleAriaLabel()
  }
  componentDidUpdate() {
    if (!this.$parentRef) return
    this.handleAriaLabel()
  }
  handleAriaLabel = () => {
    let isDisabled = this.$parentRef.getAttribute('aria-disabled') === 'true'
    let hasAriaLabel = this.$parentRef.getAttribute('aria-label')
    if (!isDisabled && !hasAriaLabel) {
      this.$parentRef.setAttribute('aria-label', 'Go to previous page')
    } else if (isDisabled) {
      this.$parentRef.removeAttribute('aria-label', 'Go to previous page')
    }
  }
  setRef = (el) => {
    this.$ref = el
    if (el) {
      this.$parentRef = el.parentNode
    }
  }
  render() {
    return (
      <span className="Pagination__label-prev" ref={this.setRef}>
        <span>Previous</span>
        <i className="fa fa-angle-left" />
      </span>
    )
  }
}

class PaginationNext extends Component {
  componentDidMount() {
    if (!this.$parentRef) return
    this.handleAriaLabel()
  }
  componentDidUpdate() {
    if (!this.$parentRef) return
    this.handleAriaLabel()
  }
  handleAriaLabel = () => {
    let isDisabled = this.$parentRef.getAttribute('aria-disabled') === 'true'
    let hasAriaLabel = this.$parentRef.getAttribute('aria-label')
    if (!isDisabled && !hasAriaLabel) {
      this.$parentRef.setAttribute('aria-label', 'Go to next page')
    } else if (isDisabled) {
      this.$parentRef.removeAttribute('aria-label', 'Go to next page')
    }
  }
  setRef = (el) => {
    this.$ref = el
    if (el) {
      this.$parentRef = el.parentNode
    }
  }
  render() {
    return (
      <span className="Pagination__label-next" ref={this.setRef}>
        <span>Next</span>
        <i className="fa fa-angle-right" />
      </span>
    )
  }
}

@inject('uiStore')
class Pagination extends Component {
  static defaultProps = {
    offset: 0,
    limit: 50,
    total: 0,
  }
  get pages() {
    const { limit, total } = this.props
    const p = Math.ceil(total / limit)
    return p > 0 ? p : 1
  }
  get pageIndex() {
    const { limit, offset } = this.props
    return Math.floor((offset + limit) / limit) - 1
  }
  handleChange = (changes) => {
    this.props.onChange({
      total: this.props.total,
      pages: this.pages,
      offset: this.props.offset,
      page: this.pageIndex,
      ...changes,
    })
  }
  onChangeLimit = (e) => {
    const { limit, onChangeLimit } = this.props
    const { target } = e
    const { value: newLimit } = target
    if (limit !== newLimit) {
      onChangeLimit(newLimit)
    }
  }
  onPageClick = ({ selected }) => {
    const { onPageClick } = this.props
    onPageClick(selected)
  }
  renderShowEntries() {
    const { id, limit } = this.props
    return (
      <div className="Pagination-showEntries">
        <Label htmlFor={`${id}__pagination-select`} text="Entries per page" />
        <div className="Pagination-selectWrapper">
          <select
            defaultValue={limit}
            id={`${id}__pagination-select`}
            onChange={this.onChangeLimit}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
            <option value={1000}>1000</option>
          </select>
          <i aria-hidden="true" className="fa fa-angle-down" />
        </div>
      </div>
    )
  }
  render() {
    const { uiStore } = this.props
    const { isMobile } = uiStore
    return (
      <div className="Pagination-wrapper">
        {this.renderShowEntries()}
        <ReactPaginate
          ariaLabelBuilder={(pageNum) => {
            return `Go to page ${pageNum}`
          }}
          containerClassName="Pagination"
          pageLinkClassName="Pagination-page"
          activeLinkClassName="Pagination-active"
          breakLinkClassName="Pagination-break"
          disabledClassName="Pagination-disabled"
          previousLinkClassName="Pagination-prev"
          nextLinkClassName="Pagination-nxt"
          marginPagesDisplayed={isMobile ? 1 : 2}
          pageRangeDisplayed={isMobile ? 1 : 2}
          pageCount={this.pages}
          forcePage={this.pageIndex}
          onPageChange={this.onPageClick}
          breakLabel="..."
          previousLabel={<PaginationPrev />}
          nextLabel={<PaginationNext />}
        />
      </div>
    )
  }
}

Pagination.propTypes = {
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
}

export default Pagination
