import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import debounce from 'lodash/debounce'

import { Button, RichTextWrapper } from '@/components'

import './ReadTabLectureNotes.scss'

const ReadTabLectureNotesMessages = defineMessages({
  backToTop: {
    defaultMessage: 'Scroll Back to Top',
    description:
      'Scroll back to the top of the lecture notes for the current Read Tab',
    id: 'ReadTabLectureNotesMessages.backToTop',
  },
  loadingReadContent: {
    defaultMessage: 'Loading content...',
    description:
      'Description displayed when the Read content is loading upon entering the Course page Read tab.',
    id: 'ReadTabLectureNotesMessages.loadingReadContent',
  },
  printLectureNotes: {
    defaultMessage: 'Print Read Tab',
    description: 'Button for printing lecture notes for screen readers.',
    id: 'ReadTabLectureNotesMessages.printLectureNotes',
  },
})

@injectIntl
@withRouter
export default class ReadTabLectureNotes extends Component {
  state = { backToTopVisible: false }

  get key() {
    const { match } = this.props
    const { params } = match
    const { sectionId, unitId } = params
    return `ReadTabLectureNotes__${unitId}-${sectionId}`
  }
  get scrollableOffset() {
    const { scrollableEl } = this.props
    if (this.$el && scrollableEl) {
      const { top: topEl } = this.$el.getBoundingClientRect()
      const { top: topScrollableEl } = scrollableEl.getBoundingClientRect()
      const { paddingTop: paddingTopScrollableEl } =
        getComputedStyle(scrollableEl)
      if (
        paddingTopScrollableEl &&
        !isNaN(parseFloat(paddingTopScrollableEl))
      ) {
        return topEl - (topScrollableEl + parseFloat(paddingTopScrollableEl))
      }
      return topEl - topScrollableEl
    }
    return 0
  }
  componentDidMount() {
    const { scrollableEl } = this.props
    if (scrollableEl) {
      scrollableEl.addEventListener('scroll', this.debouncedToggleBackToTop)
      scrollableEl.addEventListener('resize', this.debouncedToggleBackToTop)
    }
  }
  componentDidUpdate(prevProps) {
    const { scrollableEl: prevScrollableEl } = prevProps
    const { scrollableEl: currentScrollableEl } = this.props
    if (
      prevScrollableEl &&
      currentScrollableEl &&
      prevScrollableEl !== currentScrollableEl
    ) {
      prevScrollableEl.removeEventListener(
        'scroll',
        this.debouncedToggleBackToTop,
      )
      prevScrollableEl.removeEventListener(
        'resize',
        this.debouncedToggleBackToTop,
      )
      currentScrollableEl.addEventListener(
        'scroll',
        this.debouncedToggleBackToTop,
      )
      currentScrollableEl.addEventListener(
        'resize',
        this.debouncedToggleBackToTop,
      )
    }
  }
  componentWillUnmount() {
    const { scrollableEl } = this.props
    if (scrollableEl) {
      scrollableEl.removeEventListener('scroll', this.debouncedToggleBackToTop)
      scrollableEl.removeEventListener('resize', this.debouncedToggleBackToTop)
    }
  }
  onLoadIframe = () => {
    const { history } = this.props
    const { location } = history || {}
    const { hash } = location || {}
    if (!hash) return
    const id = hash.split('#')[1]
    if (!id || id.trim() === '') return
    this.sendMessage({ action: 'handleScrollToAnchor', payload: id.trim() })
  }
  onPrint = (event) => {
    event.preventDefault()
    this.sendMessage({ action: 'onPrint' })
  }
  onScrollToAnchor = (scrollY) => {
    const { scrollableEl } = this.props
    if (scrollableEl) {
      scrollableEl.scrollTo({
        top: scrollY + this.scrollableOffset,
        behavior: 'smooth',
      })
    }
  }
  scrollToTop(scrollableEl) {
    scrollableEl.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  sendMessage = (message) => {
    if (!this.$iframe) return
    this.$iframe.contentWindow.postMessage(JSON.stringify(message), '*')
  }
  setIframeRef = (el) => {
    this.$iframe = el
  }
  setRef = (el) => {
    this.$el = el
  }
  toggleBackToTop = () => {
    const { backToTopVisible } = this.state
    const { scrollableEl } = this.props
    if (!scrollableEl) {
      return
    }
    if (!backToTopVisible && scrollableEl.scrollTop !== 0) {
      this.setState({
        backToTopVisible: true,
      })
    } else if (backToTopVisible && scrollableEl.scrollTop === 0) {
      this.setState({
        backToTopVisible: false,
      })
    }
  }
  debouncedToggleBackToTop = debounce(this.toggleBackToTop, 250)
  render() {
    const { intl, lectureNotes, onOpenActivityModal, scrollableEl } = this.props
    const { backToTopVisible } = this.state
    const { formatMessage } = intl
    return (
      <div ref={this.setRef} className="ReadTab">
        <Button
          aria-label={formatMessage(
            ReadTabLectureNotesMessages.printLectureNotes,
          )}
          className="ReadTab__print"
          isCircle
          isPrimary
          onClick={this.onPrint}
        >
          <i
            aria-hidden="true"
            className="fa fa-print"
            title={formatMessage(ReadTabLectureNotesMessages.printLectureNotes)}
          />
        </Button>
        <RichTextWrapper
          className="ReadTab__rich-text-wrapper"
          content={lectureNotes}
          id={this.key}
          key={this.key}
          loadingText={formatMessage(
            ReadTabLectureNotesMessages.loadingReadContent,
          )}
          onLoad={this.onLoadIframe}
          onOpenImageModal={onOpenActivityModal}
          onScrollToAnchor={this.onScrollToAnchor}
          setRef={this.setIframeRef}
        />
        {backToTopVisible && (
          <div className="ReadTab__back-to-top">
            <Button
              aria-label={formatMessage(ReadTabLectureNotesMessages.backToTop)}
              className="ReadTab__back-to-top-button"
              isCircle
              isInfo
              onClick={() => this.scrollToTop(scrollableEl)}
            >
              <i
                aria-hidden="true"
                className="fa fa-angle-up"
                title={formatMessage(ReadTabLectureNotesMessages.backToTop)}
              />
            </Button>
          </div>
        )}
      </div>
    )
  }
}
