// TODO: If you want a full width select dropdown, pair control is-expanded with select is-fullwidth.

import React from 'react'
import cx from 'classnames'

const Select = (props) => {
  const {
    options,
    isPrimary,
    isInfo,
    isSuccess,
    isWarning,
    isDanger,
    isSmall,
    isMedium,
    isLarge,
    isLoading,
    isFullWidth,
    ...restProps
  } = props

  const className = cx(
    'select',
    {
      'is-primary': isPrimary,
      'is-info': isInfo,
      'is-success': isSuccess,
      'is-warning': isWarning,
      'is-danger': isDanger,
      'is-small': isSmall,
      'is-medium': isMedium,
      'is-large': isLarge,
      'is-loading': isLoading,
      'is-fullwidth': isFullWidth,
    },
    props.className,
  )

  const defaultProps = {}

  // by default id === name
  if (props.name) defaultProps.id = props.name

  const selectProps = {
    ...defaultProps,
    ...restProps,
  }

  return (
    <span className={className}>
      <select {...selectProps}>
        {options &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
      </select>
    </span>
  )
}

export default Select
