import React from 'react'
import cx from 'classnames'

import { Heading, Input, Label } from '@/components'

const InstanceSettingsPageLti = ({
  hasLtiSettings,
  ltiKey,
  ltiSecret,
  ltiUrl,
}) => {
  return (
    <div className="InstanceSettingsPage__setting InstanceSettingsPage__lti">
      <div className="InstanceSettingsPage__setting-item">
        <Heading is4 className="InstanceSettingsPage__setting-title">
          LTI Integration
        </Heading>
        <span className="InstanceSettingsPage__setting-subtitle">
          Launch the Fulcrum Labs platform using an LTI connection. Copy & paste
          the following fields when configuring the LTI connection within your
          LMS.
          {!hasLtiSettings ? (
            <span className="InstanceSettingsPage__setting-subtitle-contact">
              Please contact Fulcrum Client Success at clients@fulcrumlabs.ai to
              learn more about this product add-on.
            </span>
          ) : (
            <span className="InstanceSettingsPage__setting-subtitle-contact">
              If further assitance is required, please reach out to Fulcrum Labs
              customer support.
            </span>
          )}
        </span>
        {hasLtiSettings && (
          <div
            className={cx({
              'InstanceSettingsPage__setting-disabled': !hasLtiSettings,
            })}
          >
            <Label className="InstanceSettingsPage__integration-label">
              Launch URL:
              <Input
                className="InstanceSettingsPage__integration-value"
                readOnly
                value={ltiUrl}
              />
            </Label>
            <Label className="InstanceSettingsPage__integration-label">
              Consumer Key:
              <Input
                className="InstanceSettingsPage__integration-value"
                readOnly
                value={ltiKey}
              />
            </Label>
            <Label className="InstanceSettingsPage__integration-label">
              Consumer Secret:
              <Input
                className="InstanceSettingsPage__integration-value"
                readOnly
                value={ltiSecret}
              />
            </Label>
          </div>
        )}
      </div>
    </div>
  )
}

export default InstanceSettingsPageLti
