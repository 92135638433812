import _ from 'lodash'
import url from 'url'
import { v4 as uuidV4 } from 'uuid'

import SessionStore from './sessionStore'
import MessagesStore from './messagesStore'
import AccountStore from './accountStore'
import ProfileStore from './profileStore'
import ContentStore from './contentStore'
import ReportingStore from './reportingStore'
import AdminStore from './adminStore'
import AnalyticsStore from './analyticsStore'
import UIStore from './uiStore'
import SearchStore from './searchStore'
import XapiStore from './xapiStore'

import { ERROR_UNDER_MAINTENANCE, LOCALSTORAGE_LOCALE_KEY } from '@/constants'
import SentryReplay from '@/SentryReplay'
import request from '@/utils/request'

// const CURRENT_ROLE = 'current_role'
const LOGOUT_URL_KEY = 'logout_url'
const SHOW_COURSE_PICKER = 'show_course_picker'
const TOKEN_KEY = 'token'
const TOKEN_OFFSET_TIME_KEY = 'token_offset_time'
const USER_KEY = 'user'

export default class AppStore {
  constructor() {
    this.stores = {
      uiStore: new UIStore(this),
      sessionStore: new SessionStore(this),
      messagesStore: new MessagesStore(this),
      accountStore: new AccountStore(this),
      profileStore: new ProfileStore(this),
      contentStore: new ContentStore(this),
      reportingStore: new ReportingStore(this),
      searchStore: new SearchStore(this),

      // admin stores
      adminStore: new AdminStore(this),
      analyticsStore: new AnalyticsStore(this),

      // xapi
      xapiStore: new XapiStore(this),
    }
    this.historyApi = null
    this.uuid = uuidV4()

    // access each store easily using `this`
    _.forEach(this.stores, (store, key) => {
      this[key] = store
    })

    const sessionData = this.loadFromLocalStorage()
    const session = this.sessionStore.createSession(sessionData)
    this.initApi()
    if (window !== window.parent) {
      this.initMessaging()
    }
    this.init(session)
  }

  handleErrors = (error) => {
    if (error) {
      const { response } = error || {}
      const { data: responseData, request, status } = response || {}
      const { message: responseMessage } = responseData || {}
      const { responseURL } = request || {}

      const error401 = status && status === 401
      if (error401) {
        if (responseURL) {
          const { pathname } = url.parse(responseURL) || {}
          // if request was not login then log user out
          if (pathname && !pathname.match(/\/users\/login$/)) {
            this.sessionStore.logout('/login?sessionExpired=true', true)
          }
        }
      }

      const error406 = status && status === 406
      if (error406) {
        if (responseURL) {
          const { pathname } = url.parse(responseURL) || {}
          // if request was not login then log user out
          if (pathname && !pathname.match(/\/users\/login$/)) {
            this.sessionStore.logout('/login?sessionExpired=true', true)
          }
        }
      }

      const isMaintenance =
        status && status === 503 && responseMessage === ERROR_UNDER_MAINTENANCE
      if (isMaintenance) {
        this.sessionStore.logout('/login?underMaintenance=true', true)
      }

      if (status >= 500 && status !== 503) {
        SentryReplay.captureException(error)
      }

      // const error500 = error.response && error.response.status === 500
      // const networkError = !error.response && error.message === 'Network Error'
      // if (error500 || networkError) {
      //   this.uiStore.showMessage({ message: 'Error communicating with the server', autoClose: false })
      // }
    }
    return Promise.reject(error)
  }

  init(session) {
    const NODE_ENV = process.env.NODE_ENV
    if (!session) return
    this.saveToLocalStorage(session)
    this.setApiToken(session.token, session.offsetTime)
    // NOTE / TODO: implement cookie header - https://headware4.jira.com/browse/FD-472
    // this.setCookieHeader()

    if (NODE_ENV !== 'development') {
      this.initGoogleTagManager(session)
    }
    // init each global store with the current session
    // const { internalCourseId, user } = session

    _.forEach(this.stores, (store) => {
      store.init && store.init(session)
    })
    const { uiStore } = this.stores
    uiStore.getContrastMode()
  }
  validateSession(isAdmin, isInstructor, isObserver, isStudent) {
    let currentRole
    if (
      // HAS NO CONFLICTING ROLES
      // if user is not an admin and is not an instructor
      // and user is an observer or is a student
      // then display learner
      !(isAdmin || isInstructor) &&
      (isObserver || isStudent)
    ) {
      currentRole = 'student'
    } else if (
      // HAS NO CONFLICTING ROLES
      // if user is not an observer and is not a student
      // and user is an admin or is an instructor
      // then display admin
      !(isObserver || isStudent) &&
      (isAdmin || isInstructor)
    ) {
      currentRole = 'admin'
    } else if (
      // HAS CONFLICTING ROLES
      // if user is an admin or is an instructor
      // and user is an observer or is a student
      // then display admin
      (isAdmin || isInstructor) &&
      (isObserver || isStudent)
    ) {
      currentRole = 'admin'
    } else {
      currentRole = 'student'
    }
    return currentRole
  }

  initApi() {
    request.options({
      handleErrors: this.handleErrors,
    })
  }
  // NOTE - init messages for SCORM if two way required - https://headware4.jira.com/browse/FM-2801
  initMessaging() {
    window.addEventListener(
      'message',
      (event) => {
        if (event.data) {
          let dataJSON, action, context, payload
          try {
            dataJSON = JSON.parse(event.data)
            action = dataJSON.action
            context = dataJSON.context
            payload = dataJSON.payload
          } catch (ex) {
            console.error(
              `Error: could not receive message${
                ex && ex.message ? ` - ${ex.message}` : ''
              };`,
            )
          }
          if (context && context === 'fulcrumlabs_rich-text-wrapper') return
          switch (action) {
            case 'pong': {
              const { sessionStore } = this.stores
              sessionStore.isScorm = true
              break
            }
            default:
              if (action !== 'xapi') {
                console.warn('Unhandled action:', action, payload)
              }
              break
          }
        }
      },
      false,
    )
    this.sendMessage('ping', 'SCORM: Messaging initialization started.')
  }
  // NOTE - send messages for SCORM - https://headware4.jira.com/browse/FM-2801
  sendMessage(action, payload) {
    if (
      !action ||
      typeof action !== 'string' ||
      !payload ||
      window === window.parent
    )
      return
    let message
    try {
      message = JSON.stringify({ action, payload })
      window.parent.postMessage(message, '*')
    } catch (ex) {
      console.error(
        `Error: could not send message${
          ex && ex.message ? ` - ${ex.message}` : ''
        };`,
      )
    }
  }
  get dataLayer() {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    return window.dataLayer
  }
  initGoogleTagManager(session) {
    const { courseId, internalCourseId, orgId, user } = session
    const { id: userId, roleId } = user
    this.dataLayer.push({
      clientId: orgId,
      courseId: internalCourseId,
      courseContentfulId: courseId,
      roleId,
      userId,
    })
  }

  setApiToken(token, tokenOffsetTime) {
    request.setToken(token, tokenOffsetTime)
  }
  resetApiToken() {
    request.resetToken()
  }

  redirectToLogoutUrl(logoutUrl, useHistoryApi = false) {
    if (logoutUrl) {
      if (useHistoryApi && this.historyApi) {
        if (window.location.href.indexOf(logoutUrl) === -1) {
          this.historyApi.push(logoutUrl)
        }
      } else {
        window.location.href = logoutUrl
      }
    }
  }

  saveToLocalStorage(session) {
    try {
      localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, session.locale)
      localStorage.setItem(TOKEN_KEY, session.token)
      localStorage.setItem(TOKEN_OFFSET_TIME_KEY, session.offsetTime)
      localStorage.setItem(USER_KEY, JSON.stringify(session.user))
      localStorage.setItem(LOGOUT_URL_KEY, JSON.stringify(session.logoutUrl))
      localStorage.setItem(
        SHOW_COURSE_PICKER,
        JSON.stringify(session.showCoursePicker),
      )
      return true
    } catch (err) {
      console.error(
        `Error:${
          session && session.user && session.user.id
            ? ` ${session.user.id}`
            : ''
        } could not save to local storage${
          err && err.message ? ` - ${err.message}` : ''
        };`,
      )
      return false
    }
  }
  loadFromLocalStorage() {
    let user, locale, logoutUrl, token, tokenOffsetTime, showCoursePicker
    try {
      user = JSON.parse(localStorage.getItem(USER_KEY))
      tokenOffsetTime = localStorage.getItem(TOKEN_OFFSET_TIME_KEY)
      if (tokenOffsetTime) {
        tokenOffsetTime = parseInt(tokenOffsetTime)
      }
      token = localStorage.getItem(TOKEN_KEY)
      locale = localStorage.getItem(LOCALSTORAGE_LOCALE_KEY)
      logoutUrl = JSON.parse(localStorage.getItem(LOGOUT_URL_KEY))
      showCoursePicker = JSON.parse(localStorage.getItem(SHOW_COURSE_PICKER))
    } catch (err) {
      return null
    }
    if (!user || !token) return null
    return { locale, logoutUrl, showCoursePicker, token, tokenOffsetTime, user }
  }
  clearLocalStorage() {
    try {
      localStorage.removeItem(USER_KEY)
      localStorage.removeItem(TOKEN_KEY)
      localStorage.removeItem(LOCALSTORAGE_LOCALE_KEY)
      localStorage.removeItem(TOKEN_OFFSET_TIME_KEY)
      localStorage.removeItem(LOGOUT_URL_KEY)
      localStorage.removeItem(SHOW_COURSE_PICKER)
      // localStorage.removeItem(CURRENT_ROLE)
      return true
    } catch (err) {
      return false
    }
  }

  reset(logoutUrl, useHistoryApi = false) {
    this.resetApiToken()
    // NOTE / TODO: implement cookie header - https://headware4.jira.com/browse/FD-472
    // this.resetCookieHeader()
    this.clearLocalStorage()
    this.redirectToLogoutUrl(logoutUrl, useHistoryApi)
    _.forEach(this.stores, (store) => {
      store.reset && store.reset()
    })
  }
}
