import { observable, computed } from 'mobx'
import { toCamelCase, toSnakeCase } from '@/utils/helpers'
import moment from 'moment'

import User from './user'

export default class UserProfile {
  @observable user
  @observable currentUser

  @observable address
  @observable birthdate
  @observable city
  @observable gender
  @observable longDescription
  @observable postalCode
  @observable screenName
  @observable state
  @observable timezoneName
  @observable timezoneOffset
  @observable studystartTime
  @observable studyEndTime

  constructor(data) {
    this.init(data)
    if (data.user && !(data.user instanceof User)) {
      this.user = new User(data.user)
    }

    if (data.currentUser && !(data.currentUser instanceof User)) {
      this.currentUser = new User(data.currentUser)
    }
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get location() {
    if (!this.city && !this.state) return '-'
    if (!this.city) return this.state
    if (!this.state) return this.city
    return `${this.city}, ${this.state}`
  }

  @computed get fbirthday() {
    if (!this.birthdate) return '-'
    return moment(this.birthdate).format('MM/DD/YY')
  }

  @computed get isCurrentUser() {
    return this.user.id === this.currentUser.id
  }

  toSnakeCase() {
    const {
      address,
      birthdate,
      city,
      gender,
      longDescription,
      postalCode,
      screenName,
      state,
    } = this
    return toSnakeCase({
      address,
      birthdate,
      city,
      gender,
      longDescription,
      postalCode,
      screenName,
      state,
      user: this.user.toSnakeCase(),
    })
  }
}
