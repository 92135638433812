import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Loader,
  Messages,
  NewMessageModal,
  ProfileModal,
  Threads,
} from '@/components'
import './MessagesPage.scss'

const MessagesPageMessages = defineMessages({
  backToMessages: {
    defaultMessage: 'Back to messages',
    description:
      'Button to view list of threads when viewing an individual message.',
    id: 'MessagesPage.backToMessages',
  },
  noThreads: {
    defaultMessage: 'No threads. Start a conversation below.',
    description:
      'Description displayed when there are no threads to be viewed.',
    id: 'MessagesPage.noThreads',
  },
  sendMessage: {
    defaultMessage: 'Send Message',
    description:
      'Button to send a new message for mobile on the messages page.',
    id: 'MessagesPage.sendMessage',
  },
  sendNewMessage: {
    defaultMessage: 'Send New Message',
    description:
      'Button to send a new message for desktop on the messages page.',
    id: 'MessagesPage.sendNewMessage',
  },
})

@injectIntl
@inject(
  'contentStore',
  'sessionStore',
  'messagesStore',
  'profileStore',
  'uiStore',
)
@observer
export default class MessagesPage extends Component {
  state = {
    isMessagesOpen: false,
    isNamesOpen: false,
    isNewMessageModalOpen: false,
    isOpenMessages: false,
    isProfileModalOpen: false,
    isProfileUserCurrentUser: false,
    newMessageRecipient: null,
  }
  componentDidMount() {
    const { contentStore, history, match, messagesStore, uiStore } = this.props
    const { settings } = contentStore
    const { thread: existingThread } = messagesStore
    const { isDisabledMessaging } = settings || {}
    const { params } = match || {}
    const { threadId: paramsThreadId } = params || {}
    uiStore.updateTitle('Messages Page')

    // we need to treat 2 cases here:
    // 1. when the user navigates to messages/:threadId
    // 2. when the user navigates to messages
    //    in this case, open the default thread (usually the first in the list)
    const loadMessages = () => {
      // handle /messages/:threadId urls
      const { thread: currentThread, threads: currentThreads } = messagesStore
      if (paramsThreadId) {
        const findParam = currentThreads.map((thread) => {
          const { id: threadId } = thread
          return parseInt(paramsThreadId, 10) === threadId
        })
        if (findParam.indexOf(true) >= 0) {
          messagesStore.loadMessages(paramsThreadId)
        } else {
          history.push('/not-found')
        }
      }
      // handle /messages urls
      else if (currentThread) {
        const { id: currentThreadId } = currentThread
        this.changeMessages(currentThreadId)
      }
    }
    if (!isDisabledMessaging) {
      if (!existingThread) {
        messagesStore.loadThreads().then(loadMessages)
      } else {
        loadMessages()
      }
      messagesStore.stopLoadUnreadMessagesCountInterval()
      messagesStore.startRefreshing()
    }
  }
  componentDidUpdate(prevProps) {
    const { contentStore, match, messagesStore } = this.props
    const { settings } = contentStore
    const { params } = match
    const { threadId: paramsThreadId } = params
    const {
      loadUnreadMessagesCountIntervalId,
      refreshIntervalId,
      thread: currentThread,
    } = messagesStore
    const { id: currentThreadId } = currentThread || {}
    const { isDisabledMessaging } = settings || {}
    const { match: prevMatch } = prevProps
    const { params: prevParams } = prevMatch
    const { threadId: prevParamsThreadId } = prevParams
    if (isDisabledMessaging) {
      if (loadUnreadMessagesCountIntervalId) {
        messagesStore.stopLoadUnreadMessagesCountInterval()
      }
      if (refreshIntervalId) {
        messagesStore.stopRefreshing()
      }
    }
    // We need to treat 2 page changes
    // 1. when the user navigates to messages/:threadId by clicking on a thread from the list
    // 2. when the user navigates to messages (by clicking on the messages nav link)
    //    in this case, open the default thread (usually the first in the list)
    else if (paramsThreadId && paramsThreadId !== prevParamsThreadId) {
      messagesStore.loadMessages(paramsThreadId)
    } else if (!paramsThreadId && currentThread) {
      this.changeMessages(currentThreadId)
    }
  }
  componentWillUnmount() {
    const { contentStore, messagesStore } = this.props
    const { settings } = contentStore
    const { thread } = messagesStore
    const { isDisabledMessaging } = settings || {}
    messagesStore.stopRefreshing()
    if (!isDisabledMessaging) {
      messagesStore.startLoadUnreadMessagesCountInterval()
      if (thread) {
        const { id: threadId, shouldMarkAsRead } = thread
        if (shouldMarkAsRead) {
          messagesStore.markThreadAsRead(threadId)
        }
      }
    }
  }
  openNewMessageModal = (user = null) => {
    this.setState({
      isNewMessageModalOpen: true,
      newMessageRecipient: user,
    })
  }
  closeNewMessageModal = () => {
    this.setState({
      isNewMessageModalOpen: false,
      newMessageRecipient: null,
    })
  }
  openProfileModal = (userId) => {
    const { profileStore, sessionStore } = this.props
    const { user: currentUser } = sessionStore
    const { id: currentUserId } = currentUser || {}
    profileStore.loadUserProfile(userId)
    this.setState({
      isProfileModalOpen: true,
      isProfileUserCurrentUser: userId === currentUserId,
    })
  }
  closeProfileModal = () => {
    const { uiStore } = this.props
    const { scrollTop } = uiStore
    this.setState({
      isProfileModalOpen: false,
      isProfileUserCurrentUser: false,
    })
    uiStore.scrollY(null, scrollTop)
  }
  sendMessage = (user) => {
    this.closeProfileModal()
    this.openNewMessageModal(user)
  }
  changeMessages = (threadId) => {
    const { history } = this.props
    this.setState({
      isNamesOpen: false,
      isMessagesOpen: true,
    })
    history.push(`/messages/${threadId}`)
  }
  saveNewMessageModal = (recipients, message) => {
    this.closeNewMessageModal()
    this.createThread(recipients, message)
  }
  loadThreads() {
    const { messagesStore } = this.props
    messagesStore.loadThreads()
  }
  loadMoreThreads = () => {
    const { messagesStore } = this.props
    messagesStore.loadMoreThreads()
  }
  loadMessages = (threadId) => {
    const { messagesStore } = this.props
    messagesStore.loadMessages(threadId)
  }
  loadMoreMessages = () => {
    const { messagesStore } = this.props
    messagesStore.loadMoreMessages()
  }
  loadRecipients = (input) => {
    const { messagesStore } = this.props
    return messagesStore.loadRecipients(input)
  }
  createThread = (recipients, message) => {
    const { messagesStore } = this.props
    return messagesStore
      .createThread(recipients, message)
      .then((thread) => this.changeMessages(thread.id))
  }
  // aka reply
  createMessage = (message) => {
    const { messagesStore } = this.props
    return messagesStore.createMessage(message)
  }
  deleteMessage = () => {
    const { messagesStore } = this.props
    messagesStore.deleteMessage()
  }
  markMessageAsRead = () => {
    const { messagesStore } = this.props
    messagesStore.markMessageAsRead()
  }
  toggleNames = (e) => {
    e.preventDefault()
    this.setState((state) => ({ isNamesOpen: !state.isNamesOpen }))
  }
  closeOpenMessage = (e) => {
    const { uiStore } = this.props
    const { scrollTop } = uiStore
    e.preventDefault()
    this.setState({ isMessagesOpen: false })
    uiStore.scrollY(null, scrollTop)
  }
  navigateToAccount = () => {
    const { history } = this.props
    return history.push('/account')
  }
  renderLoading() {
    return (
      <div className="container">
        <div className="Messages-empty">
          <Loader />
        </div>
      </div>
    )
  }
  renderEmpty() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="container">
        <div className="Messages-empty">
          <div className="title">
            {formatMessage(MessagesPageMessages.noThreads)}
          </div>
          <Button
            aria-label={formatMessage(MessagesPageMessages.sendNewMessage)}
            className="Messages-ctaNew"
            isFullWidth
            isSuccess
            onClick={() => this.openNewMessageModal()}
          >
            <i
              aria-hidden="true"
              className="fa fa-plus"
              title={formatMessage(MessagesPageMessages.sendNewMessage)}
            />
            <span className="is-hidden-touch">
              {formatMessage(MessagesPageMessages.sendNewMessage)}
            </span>
            <span className="is-hidden-desktop">
              {formatMessage(MessagesPageMessages.sendMessage)}
            </span>
          </Button>
        </div>
      </div>
    )
  }
  renderBody() {
    const { intl, messagesStore } = this.props
    const { formatMessage } = intl

    if (messagesStore.isFirstThreadsLoad) {
      return this.renderLoading()
    }

    if (!messagesStore.thread) {
      return this.renderEmpty()
    }

    let recipients = []
    let messages = []
    let threadId = null

    if (messagesStore.thread) {
      threadId = messagesStore.thread.id
      recipients = messagesStore.thread.recipientsExceptUser
      messages = messagesStore.thread.messages.slice()
    }

    let rowClassName = cx('columns is-tablet', {
      'is-messages-open': this.state.isMessagesOpen,
    })

    return (
      <div className="container container-messages">
        <div className={rowClassName}>
          <div className="column MessagesPage-threads">
            <div />
            <Button
              aria-label="Send New Message"
              className="Messages-ctaNew"
              isFullWidth
              isSuccess
              onClick={() => this.openNewMessageModal()}
            >
              <i
                aria-hidden="true"
                className="fa fa-plus"
                title="Send New Message"
              />
              <span className="is-hidden-touch">
                {formatMessage(MessagesPageMessages.sendNewMessage)}
              </span>
              <span className="is-hidden-desktop">
                {formatMessage(MessagesPageMessages.sendMessage)}
              </span>
            </Button>
            <Button
              className="Messages-back"
              isFullWidth
              isInfo
              onClick={this.closeOpenMessage}
            >
              <i className="fa fa-angle-left" />
              <span>{formatMessage(MessagesPageMessages.backToMessages)}</span>
            </Button>
            <Threads
              thread={messagesStore.thread}
              threads={messagesStore.threads.slice()}
              isLoading={messagesStore.isFirstThreadsLoad}
              isLoadingMore={messagesStore.isLoadingMoreThreads}
              onLoadMore={this.loadMoreThreads}
              onChange={this.changeMessages}
            />
          </div>
          <div className="column is-two-thirds MessagesPage-messages">
            <Messages
              threadId={threadId}
              messages={messages}
              recipients={recipients}
              isLoading={
                messagesStore.isFirstMessagesLoad ||
                messagesStore.isLoadingMoreMessages
              }
              isLoadingMore={messagesStore.isLoadingMoreMessages}
              isNamesOpen={this.state.isNamesOpen}
              onSend={this.createMessage}
              onLoadMore={this.loadMoreMessages}
              onToggleNames={this.toggleNames}
              openProfileModal={this.openProfileModal}
            />
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { contentStore, messagesStore, profileStore } = this.props
    const {
      isNewMessageModalOpen,
      isProfileUserCurrentUser,
      isProfileModalOpen,
      newMessageRecipient,
    } = this.state
    const { settings } = contentStore
    const { isLoadingRecipients } = messagesStore
    const {
      error: profileStoreError,
      isLoadingUserProfile,
      userProfile,
    } = profileStore
    const { isDisabledMessaging } = settings || {}
    if (isDisabledMessaging) {
      return <Redirect to="/" />
    }
    return (
      <div id="main-content" className="MessagesPage PageWrapper">
        {this.renderBody()}
        <NewMessageModal
          isLoading={isLoadingRecipients}
          loadRecipients={this.loadRecipients}
          isOpen={isNewMessageModalOpen}
          onClose={this.closeNewMessageModal}
          onSave={this.saveNewMessageModal}
          recipient={newMessageRecipient}
        />
        <ProfileModal
          isLoading={isLoadingUserProfile}
          userProfile={userProfile}
          isOpen={isProfileModalOpen}
          sendMessage={this.sendMessage}
          onClose={this.closeProfileModal}
          isCurrentUser={isProfileUserCurrentUser}
          loadingError={profileStoreError}
        />
      </div>
    )
  }
}
