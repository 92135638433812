import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { observer } from 'mobx-react'
import _ from 'lodash'
import cx from 'classnames'
import { UserAvatar, Loader } from '@/components'
import './Threads.scss'

const ThreadsMessages = defineMessages({
  noThreads: {
    defaultMessage: 'You have no threads.',
    description:
      'Description displayed when there are on threads to be viewed.',
    id: 'Threads.noThreads',
  },
})

const DEBOUNCED_VALUE = 100

@injectIntl
@observer
export default class Threads extends Component {
  scrollPos = null
  get isScrolledToBottom() {
    const { scrollTop, offsetHeight, scrollHeight } = this.threadsEl
    return scrollTop + offsetHeight >= scrollHeight
  }
  componentDidMount() {
    this.threadsEl.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount() {
    this.threadsEl.removeEventListener('scroll', this.onScroll)
  }
  setThreadsEl = (el) => {
    this.threadsEl = el
  }
  onScroll = _.debounce(() => {
    if (this.isScrolledToBottom) {
      this.props.onLoadMore()
    }
  }, DEBOUNCED_VALUE)
  openThread = (e, threadId) => {
    e.preventDefault()
    this.props.onChange(threadId)
  }
  render() {
    const { intl, isLoading, isLoadingMore, thread, threads } = this.props
    const { formatMessage } = intl
    const activeThreadId = thread ? thread.id : null
    return (
      <div className="Threads" ref={this.setThreadsEl}>
        {isLoading ? (
          <Loader />
        ) : (
          threads.map((thread) => {
            let threadId = thread.id
            let threadHref = `/messages/${threadId}`
            let unreadMessagesCount = thread.unreadCount
            return (
              <a
                className={cx('Thread', {
                  'is-active': threadId === activeThreadId,
                  'is-unread': unreadMessagesCount,
                })}
                href={threadHref}
                key={threadId}
                onClick={(e) => this.openThread(e, threadId)}
              >
                <div className="media">
                  <div className="media-left">
                    <UserAvatar name={thread.summaryFullName} />
                  </div>
                  <div className="media-content">
                    <div className="Thread-title">
                      <span>{thread.title}</span>
                      {unreadMessagesCount ? (
                        <span className="Thread-unreadMessagesCount">
                          {unreadMessagesCount > 9 ? '9+' : unreadMessagesCount}
                        </span>
                      ) : null}
                    </div>
                    <div className="Thread-time">
                      <i aria-hidden="true" className="fa fa-clock-o" />
                      {thread.fmodified}
                    </div>
                    <div className="Thread-summary">{thread.summary}</div>
                    <i className="fa fa-angle-right Thread-caret" />
                  </div>
                </div>
              </a>
            )
          })
        )}
        {!isLoading && _.isEmpty(threads) && (
          <span className="Threads-empty">
            {formatMessage(ThreadsMessages.noThreads)}
          </span>
        )}
        {isLoading && isLoadingMore && (
          <div className="Threads-loader">
            <Loader />
          </div>
        )}
      </div>
    )
  }
}
