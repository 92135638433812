import React, { Component } from 'react'

import { Button } from '@/components'
import { Input } from '@/components/form'
import { isPassword } from '@/utils/helpers'

import form from '../form'

const errors = {
  password: {
    password:
      'The password you entered must be between 5 and 100 characters and contain both letters and numbers.',
  },
  confirmPassword: {
    confirmPassword: 'The passwords you entered do not match.',
    password: true,
  },
}

const formOptions = {
  values: {
    password: '',
    confirmPassword: '',
  },
  validate: (values) => {
    if (!isPassword(values.password)) {
      return { ...errors.password }
    }
    if (values.password !== values.confirmPassword) {
      return { ...errors.confirmPassword }
    }
    return null
  },
}

@form(formOptions)
export default class ResetPasswordForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    const { form } = this.props
    if (!form.isValid()) return
    const { password } = form.values()
    this.props.onSubmit(password)
  }
  render() {
    const { form } = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="field">
          <label htmlFor="ResetPasswordForm-password">New Password:</label>
          <div className="control">
            <Input
              {...form.inputProps('password')}
              id="ResetPasswordForm-password"
              type="password"
              placeholder="New Password"
            />
          </div>
        </div>
        <div className="field">
          <label htmlFor="ResetPasswordForm-confirmPassword">
            Confirm New Password:
          </label>
          <div className="control">
            <Input
              {...form.inputProps('confirmPassword')}
              id="ResetPasswordForm-confirmPassword"
              type="password"
              placeholder="Confirm new password"
            />
          </div>
        </div>
        <div className="help-group">{form.renderErrors()}</div>
        <div className="field field-action is-grouped">
          <Button isDark isFullWidth to="/login">
            Cancel
          </Button>
          <Button
            submit
            isFullWidth
            isSuccess
            isLoading={this.props.isLoading}
            disabled={form.isEmpty() || this.props.isLoading}
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}
