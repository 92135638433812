// import _ from 'lodash'

// NOTE: temp disabled - https://headware4.jira.com/browse/FD-490
// export const getMathFormulas = (content) => {
//   if (!content) return
//   const mathMLRegExp = /<math(.*?)>(.*?)<\/math>/gmi
//   const formulas = content.match(mathMLRegExp)
//   const matches = []
//   if (!formulas) return
//   formulas.forEach((formula, index) => {
//     if (formula) {
//       matches.push({
//         math: formula,
//         match: '__MATHML:' + _.uniqueId() + '__'
//       })
//     }
//   })
//   return matches
// }
export const getMathFormulas = () => {
  return null
}

export const replaceMathFormulas = ({ content }) => {
  // let formulas = getMathFormulas(content)
  // if (!formulas) {
  //   return Promise.resolve(content)
  // }
  // window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub])

  // NOTE: temp disabled - https://headware4.jira.com/browse/FD-490
  return Promise.resolve(content)
}
