import React, { Component } from 'react'
import InstanceContentPageLearningObjective from './InstanceContentPageLearningObjective'

export default class InstanceContentPageLearningObjectives extends Component {
  render() {
    const { activities, onClickOpenActivityModal } = this.props
    return (
      <div className="InstanceContentPage-learningObjectives">
        {Object.keys(activities).map((loId, index) => {
          return (
            <InstanceContentPageLearningObjective
              key={`InstanceContentPage-learningObjective-${loId}-${index}`}
              loId={loId}
              loItems={activities[loId]['items']}
              loTitle={activities[loId]['loTitle']}
              onClickOpenActivityModal={onClickOpenActivityModal}
            />
          )
        })}
      </div>
    )
  }
}
