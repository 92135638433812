import { extendObservable, action } from 'mobx'
import humps from 'lodash-humps'

import { ENABLE_XAPI } from '@/constants'
import SentryReplay from '@/SentryReplay'
import { toSnakeCase } from '@/utils/helpers'
import request from '@/utils/request'

export default class XapiStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...this.initialState })

    this.initMessaging()
    this.initUnloading()
  }

  get initialState() {
    return {
      hasUnloaded: false,
      isLaunchingSection: false,
      isLaunchingSession: false,
      isSubmittingCustomStatement: false,
      isTerminatingSession: false,
      session: null,
      xapiSession: null,
    }
  }

  get initialXapiSession() {
    const { stores } = this.appStore
    const {
      courseId: courseContentfulId,
      internalCourseId,
      user,
    } = this.session
    const { contentStore } = stores
    const { course } = contentStore
    const { title: courseTitle } = course
    const {
      email,
      firstName,
      id: userId,
      lastName,
      roleId: learnerId,
      username,
    } = user
    return {
      auType: null, // 'section' || 'exam' || 'snapshot'
      course: {
        contentfulId: courseContentfulId, // str
        id: internalCourseId, // int
        title: courseTitle, // str
      },
      launchMode: null, // 'Normal' || 'Review'
      learnerId, // str
      moveOn: null, // 'Completed' || 'Passed' || 'CompletedOrPassed'
      // NOTE: This will be added once session is launched
      // sessionId: null, // str
      user: {
        email, // str
        firstName, // str
        id: userId, // str - learner_id
        lastName, // str
        username, // str
      },
      windowId: this.uuid,
    }
  }

  get initialXapiExamSession() {
    return {
      ...this.initialXapiSession,
      attempt: null, // int
      auType: 'exam',
      launchMode: 'Normal',
      moveOn: 'Passed',
    }
  }

  get initialXapiSectionSession() {
    return {
      ...this.initialXapiSession,
      attempt: null, // int
      auType: 'section',
      section: {
        contentfulId: null, // str
        title: null, // str
      },
      unit: {
        contentfulId: null, // str
        title: null, // str
      },
    }
  }

  get initialXapiSnapshotSession() {
    return {
      ...this.initialXapiSession,
      auType: 'snapshot',
      launchMode: 'Normal',
      moveOn: 'Completed',
    }
  }

  get isXapiEnabled() {
    return !!ENABLE_XAPI
  }

  get isPreview() {
    const { preview } = this.session
    return !!preview
  }

  get uuid() {
    return this.appStore.uuid
  }

  initMessaging() {
    window.addEventListener(
      'message',
      (event) => {
        if (!this.isXapiEnabled) return
        if (event.data) {
          let dataJSON, action, context, payload
          try {
            dataJSON = JSON.parse(event.data)
            action = dataJSON.action
            context = dataJSON.context
            payload = dataJSON.payload
          } catch (ex) {
            SentryReplay.captureMessage(
              `Warning: could not receive message - ${
                ex && ex.message ? `${ex.message}` : 'unknown exception'
              }, with event data: ${event.data};`,
              'warning',
              { type: 'XapiStore:eventListener:message' },
            )
          }
          if (context && context === 'fulcrumlabs_rich-text-wrapper') return
          switch (action) {
            case 'xapi':
              SentryReplay.addBreadcrumb({
                category: 'XapiStore:eventListener:message:xapi',
                data: dataJSON,
                message: 'Received xapi message',
                level: 'info',
                type: 'default',
              })
              this.submitCustomStatement(payload)
              break
            default:
              if (action !== 'pong') {
                SentryReplay.captureMessage(
                  `Warning: unhandled action with event data: ${event.data};`,
                  'warning',
                  { type: 'XapiStore:eventListener:message' },
                )
              }
              break
          }
        }
      },
      false,
    )
  }

  initUnloading() {
    window.addEventListener('unload', () => {
      if (this.hasUnloaded) return
      this.terminateSession()
    })
    window.addEventListener('beforeunload', () => {
      this.hasUnloaded = true
      this.terminateSession()
    })
  }

  @action init(session) {
    this.session = session
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...this.initialState })
  }

  @action launchSection(unitContentfulId, sectionContentfulId) {
    const { stores } = this.appStore
    const { contentStore } = stores
    const { course, settings } = contentStore
    const { isRequireMastery } = settings || {}
    const unit = course.findUnitById(unitContentfulId)
    const section = unit.findSectionById(sectionContentfulId)
    const { title: unitTitle } = unit
    const {
      isMastered: isSectionMastered,
      isReattempt: isSectionReattempt,
      title: sectionTitle,
    } = section
    this.isLaunchingSection = true
    // terminate any existing session & prevent termination on launchSession
    if (this.xapiSession) {
      this.terminateSession()
    }
    this.xapiSession = {
      ...this.initialXapiSectionSession,
      launchMode:
        !isSectionMastered || isSectionReattempt ? 'Normal' : 'Review',
      moveOn: isRequireMastery ? 'Passed' : 'Completed',
      section: {
        contentfulId: sectionContentfulId,
        title: sectionTitle,
      },
      unit: {
        contentfulId: unitContentfulId,
        title: unitTitle,
      },
    }
    return this.launchSession(
      'section',
      { ...this.xapiSession, attempt: section.attempt },
      true,
    )
      .then(this.handleLaunchSection)
      .catch(this.handleLaunchSectionError)
  }
  @action handleLaunchSection = () => {
    this.isLaunchingSection = false
  }
  @action handleLaunchSectionError = (error) => {
    SentryReplay.captureException(error)
    this.isLaunchingSection = false
  }

  getInitialXapiSession(auType) {
    if (auType === 'exam') {
      return this.initialXapiExamSession
    } else if (auType === 'section') {
      return this.initialXapiSectionSession
    } else if (auType === 'snapshot') {
      return this.initialXapiSnapshotSession
    } else {
      return { ...this.initialXapiSession, auType }
    }
  }

  @action launchSession(auType, sessionData = {}, preventTerminate = false) {
    if (!this.isXapiEnabled) return Promise.resolve()
    if (this.isPreview) return Promise.resolve()
    this.isLaunchingSession = true
    if (!preventTerminate && this.xapiSession) {
      this.terminateSession()
    }
    const initialXapiSession = this.getInitialXapiSession(auType)
    const sessionToLaunch = { ...initialXapiSession, ...sessionData }
    this.xapiSession = { ...sessionToLaunch }
    return request
      .post('/xapi/launch', toSnakeCase(sessionToLaunch))
      .then((response) => this.handleLaunchSession(response, sessionToLaunch))
      .catch(this.handleLaunchSessionError)
  }
  @action handleLaunchSession = (response, sessionToLaunch) => {
    const { data: responseData } = response
    const { sessionId } = humps(responseData)
    // session was terminated before launch resolved,
    // terminate launched session
    if (this.xapiSession) {
      this.xapiSession = { ...this.xapiSession, sessionId }
    } else {
      this.terminateSession({ ...sessionToLaunch, sessionId })
    }
    this.isLaunchingSession = false
  }
  @action handleLaunchSessionError = (error) => {
    SentryReplay.captureException(error)
    this.isLaunchingSession = false
  }

  @action terminateSession(terminatingSession = null) {
    if (!this.xapiSession && !terminatingSession) return Promise.resolve()
    const sessionToTerminate = terminatingSession
      ? { ...terminatingSession }
      : { ...this.xapiSession }
    const { sessionId: sessionToTerminateId } = sessionToTerminate
    this.isTerminatingSession = true
    this.xapiSession = null
    // if session was terminated prior to launSession resolving,
    // call terminate session from handleLaunchSession
    if (!sessionToTerminateId) return Promise.resolve()
    if (!this.isXapiEnabled) return Promise.resolve()
    if (this.isPreview) return Promise.resolve()
    return request
      .post('/xapi/terminate', toSnakeCase(sessionToTerminate))
      .then(this.handleTerminateSession)
      .catch(this.handleTerminateSessionError)
  }
  @action handleTerminateSession = () => {
    this.isTerminatingSession = false
  }
  @action handleTerminateSessionError = (error) => {
    SentryReplay.captureException(error)
    this.isTerminatingSession = false
  }

  @action submitCustomStatement(statement) {
    if (!this.isXapiEnabled) return Promise.resolve()
    if (this.isPreview) return Promise.resolve()
    const currentSession = toSnakeCase({ ...this.xapiSession })
    this.isSubmittingCustomStatement = true
    return request
      .post('/xapi/custom_statement', {
        ...currentSession,
        statement,
      })
      .then(this.handleSubmitCustomStatement)
      .catch(this.handleSubmitCustomStatementError)
  }
  @action handleSubmitCustomStatement = () => {
    this.isSubmittingCustomStatement = false
  }
  @action handleSubmitCustomStatementError = (error) => {
    SentryReplay.captureException(error)
    this.isSubmittingCustomStatement = false
  }
}
