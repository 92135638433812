import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import cx from 'classnames'
import { observer } from 'mobx-react'
import { Progress } from '@/components'
import {
  RANK_UNENGAGED,
  REATTEMPT_STATUS_READY,
  REATTEMPT_STATUS_STARTED,
  REATTEMPT_STATUS_FINISHED,
} from '@/constants'

import './SectionList.scss'

const SectionListMessages = defineMessages({
  unlockHint: {
    defaultMessage: 'Complete more Sections to unlock!',
    description:
      'Description for the lock icon to explain how to unlock a section in the section list.',
    id: 'SectionList.unlockHint',
  },
})

@injectIntl
@observer
export default class SectionList extends Component {
  onClick = (e, section) => {
    e.preventDefault()
    this.props.onChange && this.props.onChange(section)
  }

  renderSectionItem(section, index) {
    const { active, intl, isQuizEnabled, isQuizMastery, isRequireMastery } =
      this.props
    const { formatMessage } = intl
    const itemClassName = cx('SectionList-item', {
      'is-active': section.id === active,
    })
    const firstTabHref = section.tabs[0].href
    let hint = null

    if (section.locked) {
      hint = formatMessage(SectionListMessages.unlockHint)
    }

    return (
      <a
        className={itemClassName}
        href={firstTabHref}
        key={section.id}
        onClick={(e) => this.onClick(e, section)}
      >
        <div className="level is-mobile">
          <div className="level-left">
            <span className="SectionList-count">{index + 1}</span>
            <span className="SectionList-label">{section.title}</span>
          </div>
          <div className="level-right">
            <Progress
              hint={hint}
              isDone={!section.locked && section.isMastered}
              isLocked={section.locked}
              isReattempt={
                isRequireMastery &&
                section.rank === RANK_UNENGAGED &&
                (!section.reattemptRank ||
                  section.reattemptRank === REATTEMPT_STATUS_READY ||
                  section.reattemptStatus === REATTEMPT_STATUS_STARTED ||
                  (section.reattemptStatus === REATTEMPT_STATUS_FINISHED &&
                    section.reattemptRank === RANK_UNENGAGED))
              }
              isPretestMastered={section.pretestMastered}
              needsQuiz={
                isQuizEnabled &&
                section.hasQuiz &&
                isQuizMastery &&
                !section.hasPassedQuiz
              }
            />
          </div>
        </div>
      </a>
    )
  }

  render() {
    const sectionListClassName = cx('SectionList', {
      'is-small': this.props.isSmall,
    })

    return (
      <div
        className={sectionListClassName}
        qa-id="learner-sections-accordion-button"
      >
        {this.props.sections.map((section, index) =>
          this.renderSectionItem(section, index),
        )}
      </div>
    )
  }
}
