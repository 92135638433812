import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import {
  Button,
  Loader,
  Messages,
  NewMessageModal,
  ProfileModal,
  Threads,
} from '@/components'
import { hasOwnProperty } from '@/utils/helpers'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import './InstanceMessagesPage.scss'

@inject('adminStore', 'profileStore', 'uiStore')
@observer
export default class InstanceMessagesPage extends Component {
  state = {
    isChangingThread: false,
    isLoadingMoreMessages: false,
    isMessagesOpen: false,
    isNamesOpen: false,
    newMessageModal: null,
    thread: null,
  }
  messagesInterval = null
  preventUpdates = false
  componentDidMount() {
    const { adminStore, history, match, uiStore } = this.props
    const { instance } = adminStore || {}
    const { course } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    const { params } = match
    const { instanceId, threadId } = params
    uiStore.updateTitle('Course Messages Page')

    if (!disableMessaging) {
      this.loadThreads().then((threads) => {
        if (this.preventUpdates) return
        if (threads && threads.length) {
          if (
            threadId &&
            !isNaN(threadId) &&
            instance.getThreadById(threadId)
          ) {
            // go to thread
            this.openMessages()
            if (this.preventUpdates) return
            this.loadThread().then(() => {
              this.scrollToHeader()
            })
          } else if (!threadId || (threadId && isNaN(threadId))) {
            // no threadId, go to first thread
            history.push(
              `/admin/courses/${instanceId}/messages/${threads[0].id}`,
            )
          } else {
            // bad threadId, go to messages
            history.push(`/admin/courses/${instanceId}/messages`)
          }
        } else if (threadId) {
          // no threads, go to messages
          history.push(`/admin/courses/${instanceId}/messages`)
        }
      })
      this.startMessagesInterval()
    }
  }
  componentDidUpdate(prevProps) {
    const { adminStore, history, match: currentMatch } = this.props
    const { instance } = adminStore
    const { course, threads } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    const { match: prevMatch } = prevProps
    const { params: currentParams } = currentMatch
    const { params: prevParams } = prevMatch
    const { instanceId: currentInstanceId, threadId: currentThreadId } =
      currentParams
    const { threadId: prevThreadId } = prevParams
    if (this.preventUpdates) return
    if (!disableMessaging) {
      if (threads && threads.length) {
        if (
          currentThreadId &&
          !isNaN(currentThreadId) &&
          prevThreadId !== currentThreadId &&
          instance.getThreadById(currentThreadId)
        ) {
          // go to next thread
          if (prevThreadId && !isNaN(prevThreadId)) {
            this.openMessages()
          }
          this.loadThread(currentThreadId)
        } else if (prevThreadId && !currentThreadId) {
          // go to messages, close messages
          this.closeMessages()
        } else if (currentThreadId && isNaN(currentThreadId)) {
          // bad threadId, go to messages
          history.push(`/admin/courses/${currentInstanceId}/messages`)
        }
      } else if (prevThreadId) {
        // no threads, go to messages
        history.push(`/admin/courses/${currentInstanceId}/messages`)
      }
    }
  }
  componentWillUnmount() {
    const { adminStore, match } = this.props
    const { thread } = this.state
    const { instance } = adminStore
    const { course } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    const { params } = match
    const { threadId } = params
    this.preventUpdates = true
    if (!disableMessaging) {
      this.stopMessagesInterval()
      if (thread) {
        const { shouldMarkAsRead } = thread
        if (
          shouldMarkAsRead &&
          !isNaN(threadId) &&
          thread.id === parseInt(threadId)
        ) {
          instance.markThreadAsRead(threadId)
        }
      }
    }
  }
  closeMessages = () => {
    this.setState({
      isMessagesOpen: false,
      isNamesOpen: false,
    })
  }
  closeModal = (modal) => {
    const { uiStore } = this.props
    const update = {}
    if (hasOwnProperty(this.state, modal)) {
      update[modal] = null
      this.setState(update)
      uiStore.scrollY(null, uiStore.scrollTop)
    }
  }
  loadMoreMessages = () => {
    const { adminStore } = this.props
    const { thread } = this.state
    const { instance, session } = adminStore
    const { user: currentUser } = session
    if (thread) {
      this.setState({ isLoadingMoreMessages: true })
      instance.loadMoreMessages(thread, currentUser).then((result) => {
        if (this.preventUpdates) return
        if (result) {
          let { messages, messagesCount } = result
          thread.messages = messages
          thread.messagesCount = messagesCount
          this.setState({ thread })
        }
        this.setState({ isLoadingMoreMessages: false })
      })
    }
  }
  loadMoreThreads = () => {
    const { adminStore } = this.props
    const { instance, session } = adminStore
    const { user: currentUser } = session
    instance.loadMoreThreads(currentUser)
  }
  loadRecipients = (term) => {
    const { adminStore } = this.props
    const { instance, session } = adminStore
    const currentUser = session.user
    const excludeIds = [currentUser.id]
    return instance.loadRecipients(term, excludeIds)
  }
  loadThread = (nextThreadId) => {
    const { adminStore, match } = this.props
    const { instance, session } = adminStore
    const { params } = match
    const { threadId } = params
    const { user: currentUser } = session
    return instance
      .loadThread(nextThreadId || threadId, currentUser)
      .then((thread) => {
        if (this.preventUpdates) return
        this.setState({ thread })
        return this.state.thread
      })
  }
  loadThreads = () => {
    const { adminStore } = this.props
    const { instance, session } = adminStore
    const { user: currentUser } = session
    return instance.loadThreads(null, null, currentUser)
  }
  onChangeThread = (threadId) => {
    const { history, match } = this.props
    const { isMessagesOpen } = this.state
    const { params } = match
    const { instanceId, threadId: prevThreadId } = params
    if (threadId !== parseInt(prevThreadId) || !isMessagesOpen) {
      this.openMessages()
      this.setState({ isChangingThread: true }, () => {
        this.loadThread(threadId).then((thread) => {
          if (this.preventUpdates) return
          this.setState({ isChangingThread: false })
          history.push(`/admin/courses/${instanceId}/messages/${threadId}`)
          this.scrollToHeader()
          return thread
        })
      })
    }
  }
  onClickBack = () => {
    this.closeMessages()
  }
  onSaveMessage = (message, attachments) => {
    const { adminStore } = this.props
    const { thread } = this.state
    const { instance, session } = adminStore
    const currentUser = session.user
    instance
      .saveMessage(thread, message, attachments, currentUser)
      .then((thread) => {
        if (this.preventUpdates) return
        this.loadThreads()
        this.setState({ thread })
      })
  }
  onSaveThread = (recipients, message, reset) => {
    const { adminStore, history, match } = this.props
    const { newMessageModal } = this.state
    const { instance, session } = adminStore
    const { params } = match
    const { instanceId, threadId } = params
    const { user: currentUser } = session
    const excludeIds = [currentUser.id]
    instance
      .saveThread(recipients, message, null, excludeIds, currentUser)
      .then((thread) => {
        if (this.preventUpdates) return
        reset(() => this.closeModal('newMessageModal'))
        if (thread.id !== parseInt(threadId)) {
          history.push(`/admin/courses/${instanceId}/messages/${thread.id}`)
        } else {
          this.loadThreads()
          this.loadThread()
        }
      })
      .catch((error) => {
        if (this.preventUpdates) return
        newMessageModal['error'] =
          error && error.message
            ? error.message
            : 'There was an error while saving the message.'
        this.setState({ newMessageModal })
      })
  }
  onSendMessage = (user) => {
    this.closeModal('profileModal')
    this.openNewMessageModal(user)
  }
  onToggleNames = (event) => {
    const { isNamesOpen } = this.state
    event.preventDefault()
    this.setState({ isNamesOpen: !isNamesOpen })
  }
  openMessages = () => {
    this.setState({
      isMessagesOpen: true,
      isNamesOpen: false,
    })
  }
  openNewMessageModal = (user = null) => {
    this.setState({
      newMessageModal: {
        error: null,
        recipient: user,
      },
    })
  }
  openProfileModal = (userId) => {
    const { adminStore, profileStore } = this.props
    const { instance, session } = adminStore
    const { contentfulId } = instance
    const { user: currentUser } = session
    profileStore.loadUserProfile(userId, contentfulId)
    this.setState({
      profileModal: {
        isProfileUserCurrentUser: userId === currentUser.id,
      },
    })
  }
  scrollToHeader = () => {
    if (window.innerWidth <= 768) {
      window.scrollTo(0, 140)
    }
  }
  startMessagesInterval = () => {
    const { adminStore } = this.props
    const { instance } = adminStore
    const { refreshRate } = instance
    if (this.messagesInterval) {
      this.stopMessagesInterval()
      this.startMessagesInterval()
    } else {
      this.messagesInterval = setInterval(() => {
        let { match } = this.props
        let { isLoadingMoreMessages, thread } = this.state
        let { params } = match
        let { threadId } = params
        let { isLoadingThread, isLoadingThreads, isMarkingThreadAsRead } =
          instance
        if (threadId && !isLoadingThread && !isLoadingMoreMessages) {
          this.loadThread()
        }
        if (!isLoadingThreads) {
          this.loadThreads()
        }
        if (!isMarkingThreadAsRead && thread) {
          let { shouldMarkAsRead } = thread
          if (
            shouldMarkAsRead &&
            !isNaN(threadId) &&
            thread.id === parseInt(threadId)
          ) {
            instance.markThreadAsRead(threadId)
          }
        }
      }, refreshRate)
    }
  }
  stopMessagesInterval = () => {
    clearInterval(this.messagesInterval)
    this.messagesInterval = null
  }
  renderMessagesLayout = () => {
    const { adminStore } = this.props
    const { isMessagesOpen } = this.state
    const { instance } = adminStore
    const { isThreadsLoaded, threads } = instance
    const className = cx('InstanceMessagesPage', {
      'is-messages-open': isMessagesOpen,
    })
    if (!isThreadsLoaded) {
      return (
        <AdminLayout className={className}>
          <div className="Messages-empty" id="main-content">
            <Loader />
          </div>
        </AdminLayout>
      )
    }
    if (isThreadsLoaded && !threads.length) {
      return (
        <AdminLayout className={className}>
          <div className="Messages-empty" id="main-content">
            <div className="title">No threads. Start a conversation below.</div>
            <Button
              isSuccess
              isFullWidth
              aria-label="Send New Message"
              onClick={() => this.openNewMessageModal()}
              className="Messages-ctaNew"
            >
              <i
                className="fa fa-plus"
                aria-hidden="true"
                title="Send New Message"
              />
              Send <span className="is-hidden-touch">New</span> Message
            </Button>
          </div>
        </AdminLayout>
      )
    }
    return (
      <AdminLayout className={className}>
        <AdminLayout.Sidebar>
          {this.renderMessagesSidebar()}
        </AdminLayout.Sidebar>
        <AdminLayout.Main id="main-content">
          {this.renderMessagesMain()}
        </AdminLayout.Main>
      </AdminLayout>
    )
  }
  renderMessagesMain() {
    const { adminStore } = this.props
    const { isChangingThread, isLoadingMoreMessages, isNamesOpen, thread } =
      this.state
    const { instance } = adminStore
    const { isLoadingMessages, isLoadingThread, isLoadingThreads, threads } =
      instance
    if (
      isChangingThread ||
      (!thread && threads.length) ||
      (!thread && isLoadingThread) ||
      (!thread && isLoadingThreads)
    ) {
      return <Loader />
    }
    return (
      <Messages
        isLoading={
          (thread && !thread.messages.length && isLoadingMessages) ||
          isLoadingMoreMessages
        }
        isLoadingMore={isLoadingMoreMessages}
        isNamesOpen={isNamesOpen}
        messages={thread.messages}
        onLoadMore={this.loadMoreMessages}
        onSend={this.onSaveMessage}
        onToggleNames={this.onToggleNames}
        openProfileModal={this.openProfileModal}
        recipients={thread.recipientsExceptUser}
        threadId={thread.id}
      />
    )
  }
  renderMessagesSidebar() {
    return (
      <div className="InstanceMessagesPage-sidebar">
        <Button
          className="Messages-ctaNew"
          isSuccess
          isFullWidth
          aria-label="Send New Message"
          onClick={() => this.openNewMessageModal()}
        >
          <i
            className="fa fa-plus"
            aria-hidden="true"
            title="Send New Message"
          />
          <span>
            Send <span className="is-hidden-touch">New</span> Message
          </span>
        </Button>
        <Button
          className="Messages-back"
          isFullWidth
          isInfo
          aria-label="Back to Messages"
          onClick={this.onClickBack}
        >
          <i
            className="fa fa-angle-left"
            aria-hidden="true"
            title="Back to Messages"
          />
          <span>Back to messages</span>
        </Button>
        {this.renderThreads()}
      </div>
    )
  }
  renderThreads() {
    const { adminStore } = this.props
    const { thread } = this.state
    const { instance } = adminStore
    const { isLoadingThreads, isLoadingMoreThreads, threads } = instance
    return (
      <Threads
        isLoading={!threads.length && isLoadingThreads}
        isLoadingMore={isLoadingMoreThreads}
        onChange={this.onChangeThread}
        onLoadMore={this.loadMoreThreads}
        thread={thread}
        threads={threads}
      />
    )
  }
  render() {
    const { adminStore, match, profileStore } = this.props
    const { newMessageModal, profileModal } = this.state
    const { instance } = adminStore
    const { params } = match
    const { instanceId } = params
    const { isLoadingUserProfile, userProfile } = profileStore
    const { course, isLoadingRecipients, isSavingThread } = instance || {}
    const { settings } = course || {}
    const { disableMessaging } = settings || {}
    if (disableMessaging) {
      return <Redirect to={`/admin/courses/${instanceId}`} />
    }
    return (
      <Fragment>
        {this.renderMessagesLayout()}
        <NewMessageModal
          error={
            newMessageModal && newMessageModal.error
              ? newMessageModal.error
              : null
          }
          isLoading={isLoadingRecipients}
          isOpen={!!newMessageModal}
          isSaving={isSavingThread}
          loadRecipients={this.loadRecipients}
          onClose={() => this.closeModal('newMessageModal')}
          onSave={this.onSaveThread}
          onSaveCallback
          recipient={
            newMessageModal && newMessageModal.recipient
              ? newMessageModal.recipient
              : null
          }
        />
        <ProfileModal
          isCurrentUser={
            profileModal && profileModal.isProfileUserCurrentUser
              ? profileModal.isProfileUserCurrentUser
              : null
          }
          isLoading={isLoadingUserProfile}
          isOpen={!!profileModal}
          loadingError={
            profileStore && profileStore.error ? profileStore.error : null
          }
          onClose={() => this.closeModal('profileModal')}
          sendMessage={this.onSendMessage}
          userProfile={userProfile}
        />
      </Fragment>
    )
  }
}
