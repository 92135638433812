import _ from 'lodash'
import React, { Component } from 'react'
import SimpleBar from 'simplebar-react'
import './ScrollBar.scss'
export default class ScrollBar extends Component {
  renderChildren = (child) => {
    if (!child) return null
    const { active, autoHide, children, isProxy, ...rest } = this.props
    const { props: childProps } = child
    const { id, ...restChildProps } = childProps
    // NOTE / TODO / WIP - active prop is specifically for Accordion
    // Accordion needs to be refactored
    return React.cloneElement(child, {
      active: _.includes(active || [], id),
      ...restChildProps,
      ...rest,
    })
  }
  render() {
    // If isProxy, pass any non-ScrollBar props to its children
    const { autoHide, children, isProxy } = this.props
    return (
      <SimpleBar
        className="ScrollBar"
        data-simplebar-auto-hide={autoHide ? 'true' : 'false'}
      >
        {isProxy ? React.Children.map(children, this.renderChildren) : children}
      </SimpleBar>
    )
  }
}
