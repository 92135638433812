import React, { Component } from 'react'
import cx from 'classnames'
import { TransitionWidth } from '@/components'
import './ProgressChart.scss'

export default class ProgressChart extends Component {
  static defaultProps = {
    averageLabel: 'Average',
  }
  state = {
    width: 0,
  }
  componentDidMount() {
    this.setState({
      width: this.props.value,
    })
  }
  componentDidUpdate() {
    if (this.state.width !== this.props.value) {
      this.setState({
        width: this.props.value,
      })
    }
  }
  renderAlternative() {
    return null
  }
  renderDefault() {
    const {
      average,
      averageLabel,
      isHiddenAverage,
      isSimple,
      value,
      valueLabel,
    } = this.props
    const { width } = this.state
    const valueLabelClass = cx('ProgressChart-label', {
      'is-left': value < 50,
    })
    const averageLabelClass = cx('ProgressChart-label', {
      'is-left': average < 50,
    })
    return (
      <div className="ProgressChart-background">
        <TransitionWidth
          className="ProgressChart-value ProgressChart-progress"
          in={!!width}
          value={`${width}%`}
        >
          {isSimple ? (
            <span className={valueLabelClass}>{valueLabel}</span>
          ) : (
            <span className={valueLabelClass}>
              {valueLabel} ({value}%)
            </span>
          )}
        </TransitionWidth>
        {(average || average === 0) && !isHiddenAverage ? (
          <div
            className="ProgressChart-value is-alt"
            style={{ width: `${average}%` }}
          >
            {isSimple ? (
              <span className={averageLabelClass}>{averageLabel}</span>
            ) : (
              <span className={averageLabelClass}>
                {averageLabel} ({average}%)
              </span>
            )}
          </div>
        ) : null}
      </div>
    )
  }
  render() {
    const className = cx(
      'ProgressChart',
      {
        'is-alt': this.props.isAlt,
        'is-danger': this.props.theme === 'at-risk',
        'is-success': this.props.theme === 'all-star',
      },
      this.props.className,
    )
    return (
      <div className={className}>
        {this.props.isAlt ? this.renderAlternative() : this.renderDefault()}
      </div>
    )
  }
}
