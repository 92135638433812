import React from 'react'
import ReactUserAvatar from 'react-user-avatar'
// import cx from 'classnames'

import './UserAvatar.scss'

const defaultColors = ['#479DD9', '#00C479', '#FFAB40', '#00BFA5', '#BCAAA4']

const UserAvatar = (props) => {
  return <ReactUserAvatar {...props} colors={defaultColors} />
}

export default UserAvatar
