import { extendObservable, action } from 'mobx'
import humps from 'lodash-humps'
import _ from 'lodash'
import request from '@/utils/request'

import Report from './models/report'
import Learner from './models/learner'

import { EMPTY_VALUE } from '@/constants'
import SentryReplay from '@/SentryReplay'
import { getMobxArrayValues } from '@/utils/helpers'

const initialState = {
  session: null,
  report: null,
  isLoadingCourse: true,
  isLoadingLearners: false,
  learner: null,
  learnerSearch: '',
  isLoadingLearner: true,
  loadingError: null,
}

const cleanFilters = (filters) =>
  filters.map((filter) => {
    let { values } = filter
    return {
      ...filter,
      values:
        values && Array.isArray(values) && values.length
          ? values.filter((value) =>
              typeof value.value === 'string'
                ? !!value.value.trim()
                : !!value.value,
            )
          : [],
    }
  })

const __formatQueryStringValue = (value) => {
  // Convert array values to a csv
  // Mobx stores array values as an object
  // {
  //   "mobx": {
  //     ...,
  //     "values": [...items]
  //   }
  // }
  return Array.isArray(value) || getMobxArrayValues(value)
    ? value.join(',')
    : value
}

export default class AnalyticsStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...initialState })
  }

  @action init(session) {
    this.session = session
  }

  fetchCourse({ courseId }) {
    return request.get(`/reporting/${courseId}`, {
      params: {
        d: Date.now(),
      },
    })
  }

  fetchLearners({ courseId, filters, sorting }) {
    const cleanedFilters = _.omitBy(
      filters,
      (v) => _.isEmpty(String(v)) || v === EMPTY_VALUE,
    )
    const querystring = { ...cleanedFilters }
    Object.keys(querystring).forEach((key) => {
      querystring[key] = __formatQueryStringValue(querystring[key])
    })
    if (sorting) {
      querystring['sort'] = sorting.sortBy
      querystring['sort_orientation'] = sorting.sortOrientation
    }
    return request.get(`/reporting/${courseId}/learners`, {
      params: {
        ...querystring,
        d: Date.now(),
      },
    })
  }

  @action loadCourse({ courseId, filters } = {}) {
    this.isLoadingCourse = true
    this.loadingError = null
    this.report = null
    return Promise.all([
      this.fetchCourse({ courseId }),
      this.fetchLearners({ courseId, filters }),
    ])
      .then(([courseResponse, learnersResponse]) => {
        const courseData = humps(courseResponse.data)
        const learnersData = humps(learnersResponse.data)
        return this.handleLoadCourse({ courseId, courseData, learnersData })
      })
      .catch(this.handleLoadCourseError)
  }
  @action handleLoadCourse({ courseId, courseData, learnersData }) {
    let { learners, ...learnersMeta } = learnersData || {}
    let meta = null
    learners = learners && learners.map((learner) => new Learner(learner))
    if (!_.isEmpty(learnersMeta)) {
      meta =
        learnersMeta.count > 0
          ? learnersMeta
          : {
              ...learnersMeta,
              filters:
                this.report &&
                this.report.learnersMeta &&
                this.report.learnersMeta.filters &&
                Array.isArray(this.report.learnersMeta.filters) &&
                this.report.learnersMeta.filters.length
                  ? this.report.learnersMeta.filters
                  : learnersMeta.filters || [],
            }
    }
    if (
      meta &&
      meta.filters &&
      Array.isArray(meta.filters) &&
      meta.filters.length
    ) {
      meta.filters = cleanFilters(meta.filters)
    }
    if (!this.report) {
      this.report = new Report({
        courseId: Number(courseId),
        ...courseData,
        filters: meta.filters,
        learners,
        learnersMeta: meta,
      })
    }
    if (learnersData) {
      this.report.learners = learners
      this.report.learnersMeta = meta
    }
    this.isLoadingCourse = false
  }
  @action handleLoadCourseError = (error) => {
    SentryReplay.captureException(error)
    this.loadingError = 'There was an issue while loading the course report.'
    this.isLoadingCourse = false
  }

  @action loadLearners({ courseId, filters, sorting }) {
    const q = filters && filters.q ? filters.q : ''
    const { sortBy, sortOrientation } = sorting || {}
    const sortParams = { sortBy, sortOrientation }
    if (sortBy === 'last_activity') {
      sortParams.sortOrientation = sortOrientation === 'asc' ? 'desc' : 'asc'
    }
    this.learnerSearch = q
    this.isLoadingLearners = true
    this.loadingError = null
    return this.fetchLearners({ courseId, filters, sorting: sortParams })
      .then((response) => this.handleLoadLearners(response, filters, courseId))
      .catch(this.handleLoadLearnersError)
  }
  @action handleLoadLearners = (response, filters, courseId) => {
    const q = filters && filters.q ? filters.q : ''
    let { learners, ...learnersMeta } = humps(response.data)
    if (
      learnersMeta &&
      learnersMeta.filters &&
      Array.isArray(learnersMeta.filters) &&
      learnersMeta.filters.length
    ) {
      const updatedFilters = {}
      learnersMeta.filters = cleanFilters(learnersMeta.filters)
      learnersMeta.filters.forEach((filter) => {
        let { id: filterKey, values: filterValues } = filter
        updatedFilters[filterKey] = {}
        filterValues.forEach((filterValue) => {
          let { count, value } = filterValue
          updatedFilters[filterKey][value] = count
        })
      })
      if (this.report) {
        this.report.learnersMeta.filters.forEach((filter) => {
          let { id: filterKey, values: filterValues } = filter
          let updatedFilter = updatedFilters[filterKey]
          filterValues.forEach((filterValue) => {
            let { value } = filterValue
            filterValue.count =
              !updatedFilter || !updatedFilter[value] ? 0 : updatedFilter[value]
          })
        })
        learnersMeta.filters = this.report.learnersMeta.filters
      }
    }
    if (q === this.learnerSearch) {
      learners = learners && learners.map((learner) => new Learner(learner))
      if (this.report) {
        this.report.filters = learnersMeta.filters
        this.report.learners = learners
        this.report.learnersMeta = learnersMeta
      } else {
        this.report = new Report({
          courseId: Number(courseId),
          filters: learnersMeta.filters,
          learners,
          learnersMeta,
        })
      }
      this.isLoadingLearners = false
    }
    return this.report
  }
  @action handleLoadLearnersError = (error) => {
    SentryReplay.captureException(error)
    // this.loadingError = 'There was an issue while loading the course learners.'
    this.isLoadingLearners = false
  }

  @action loadLearner({ courseId, learnerId }) {
    this.learner = null
    this.isLoadingLearner = true
    this.loadingError = null
    return request
      .get(`/reporting/${courseId}/learners/${learnerId}`, {
        params: {
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadLearner(response, Number(learnerId)))
      .catch(this.handleLoadLearnerError)
  }
  @action handleLoadLearner(response, learnerId) {
    const { averages } = this.report
    const data = humps(response.data)
    const learner = { id: learnerId, ...data }
    this.learner = new Learner({
      averages,
      ...learner,
    })
    this.isLoadingLearner = false
    return this.learner
  }
  @action handleLoadLearnerError = (error) => {
    SentryReplay.captureException(error)
    this.loadingError = 'Analytics results are still pending. Check back later.'
    this.isLoadingLearner = false
  }
}
