import React, { Component } from 'react'
import { Heading, Modal } from '@/components'
// NOTE / TODO / WIP - disable group message type (group / individual), needs BE support
// import { CheckboxCustom, Input } from '@/components/form'
import { Input } from '@/components/form'
import './NewGroupMessageModal.scss'

const NewGroupMessageModalDefaultValues = function (role) {
  return {
    message: '',
    role: role || null,
    // NOTE / TODO / WIP - disable group message type (group / individual), needs BE support
    // type: ''
  }
}

export default class NewGroupMessageModal extends Component {
  static defaultProps = {
    values: new NewGroupMessageModalDefaultValues(),
  }
  getTitle = () => {
    const { isAdmin, isInstructor, isLearner } = this.props
    if (isAdmin) {
      return 'Send message to all admin'
    } else if (isInstructor) {
      return 'Send message to all instructors'
    } else if (isLearner) {
      return 'Send message to all learners'
    }
    return 'Send message to all users'
  }
  onChange = (field, value) => {
    if (this.props.onChange) {
      this.props.onChange(field, value)
    }
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  renderBody = () => {
    const { values } = this.props
    const { message } = values || {}
    return (
      <div className="NewGroupMessageModal-body">
        <div className="field">
          <Heading is2>Message</Heading>
          <Input
            isMultiline
            onChange={(event) =>
              this.onChange('message', event.target.value || '')
            }
            value={message || ''}
          />
        </div>
        {/* NOTE / TODO / WIP - disable group message type (group / individual), needs BE support */}
        {/* <div className='field'>
          <CheckboxCustom
            isBlock
            isRadio
            name='NewGroupMessageModal-type'
            onChange={(event) => this.onChange('type', event.target.value || null)}
            text='Send as a group message'
            value='group'
          />
          <CheckboxCustom
            isBlock
            isRadio
            name='NewGroupMessageModal-type'
            onChange={(event) => this.onChange('type', event.target.value || null)}
            text='Send as individual messages'
            value='individual'
          />
        </div> */}
      </div>
    )
  }
  render() {
    const { error, isLoading, isOpen, onConfirm, values } = this.props
    const { message } = values || {}
    return (
      <Modal
        className="NewGroupMessageModal"
        contentLabel="New Group Message Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>{this.getTitle()}</Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button isSecondary onClick={this.onClose}>
            Cancel
          </Modal.Button>
          <Modal.Button
            disabled={!message}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            Send
          </Modal.Button>
          {error ? (
            <span className="NewGroupMessageModal-footerError">{`* ${error}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { NewGroupMessageModalDefaultValues }
