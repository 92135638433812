import React, { Component, Fragment } from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import cx from 'classnames'

import { Button, Video } from '@/components'
import { ACTIVITY_TYPES, VIDEO_TYPES } from '@/constants'
import Activity from '../Activity'
import CorrectIncorrectIcon from '../CorrectIncorrectIcon'
import './ActivityMatch.scss'

const ActivityMatchMessages = defineMessages({
  clearSelection: {
    defaultMessage: 'Clear',
    description: 'Button to clear matching activity selections (mobile only).',
    id: 'ActivityMatch.clearSelection',
  },
  clearSelectionAria: {
    defaultMessage: 'Remove answer',
    description: 'ARIA label for Button to clear matching activity selections.',
    id: 'ActivityMatch.clearSelectionAria',
  },
  enlargeImage: {
    defaultMessage: 'Enlarge Image',
    description: 'Button to zoom in on matching activity images.',
    id: 'ActivityMatch.enlargeImage',
  },
  label: {
    defaultMessage:
      'Click the plus icon {plusIcon} to view options and select a match for each item.',
    description: 'Instructions label for matching activity.',
    id: 'ActivityMatch.label',
  },
  optionsBack: {
    defaultMessage: 'Back',
    description:
      'Button to return to the categories screen from selectable options in a matching activity.',
    id: 'ActivityMatch.optionsBack',
  },
  optionsInstructions: {
    defaultMessage: 'Select the best match from the available options below:',
    description: 'Description displayed when selectable options are opened.',
    id: 'ActivityMatch.optionsInstructions',
  },
})

@injectIntl
export default class ActivityMatch extends Component {
  static defaultProps = {
    // label: 'Click the plus icon to view options and select a match for each item.',
    labelIcon: 'arrows',
  }
  state = {
    ActivityMatchOptions: null,
    changedSelections: {},
    scrollTop: null,
  }
  get label() {
    const { label } = this.props
    if (label) {
      return label
    }
    return (
      <FormattedMessage
        {...ActivityMatchMessages.label}
        values={{
          plusIcon: (
            <span className="ActivityMatch-selectionButton ActivityMatch-selectionButton-example">
              <span>+</span>
            </span>
          ),
        }}
      />
    )
  }
  componentDidUpdate(prevProps) {
    const { answerResponse, correctAnswers, incorrectAnswers } = this.props
    if (
      answerResponse &&
      (prevProps.correctAnswers !== correctAnswers ||
        prevProps.incorrectAnswers !== incorrectAnswers)
    ) {
      this.setState({
        changedSelections: {},
      })
    }
  }
  getDefinitionById = (definitionId) => {
    const { definitions } = this.props
    const definition =
      definitions && definitions.length
        ? definitions.filter((obj) => obj.id === definitionId)[0]
        : null
    return definition
  }
  getDefinitionRows = () => {
    const { definitions, value } = this.props
    const pairs = value || []
    const definitionIds = pairs.map((obj) => obj.definitionId)
    const rows = []
    definitions
      .map((definition) => ({
        ...definition,
        isSelected: definitionIds.indexOf(definition.id) !== -1,
      }))
      .forEach((definition, index) => {
        if (index % 2 === 0) {
          rows.push([])
        }
        rows[rows.length - 1].push({ definition, index })
      })
    return rows
  }
  getLabelById = (labelId) => {
    const { labels } = this.props
    const label =
      labels && labels.length
        ? labels.filter((obj) => obj.id === labelId)[0]
        : null
    return label
  }
  getPairByCorrectLabel = (label) => {
    const { adminCorrectAnswers } = this.props
    const pair =
      adminCorrectAnswers && adminCorrectAnswers.length
        ? adminCorrectAnswers.filter((obj) => obj.label === label.content)[0]
        : null
    return pair
  }
  getPairByLabelId = (labelId) => {
    const { value } = this.props
    const pair =
      value && value.length
        ? value.filter((obj) => obj.labelId === labelId)[0]
        : null
    return pair
  }
  onClickDefinition = (event, definitionId) => {
    const { answerResponse, isSavingActivity, hasNextActivity, value } =
      this.props
    const { ActivityMatchOptions, changedSelections } = this.state
    const { labelId } = ActivityMatchOptions
    if (isSavingActivity) return
    if (answerResponse && !hasNextActivity) {
      this.setState({
        changedSelections: { ...changedSelections, [definitionId]: true },
      })
    }
    const pairs =
      value && value.length
        ? [].concat(value).filter((obj) => {
            return obj.labelId !== labelId && obj.definitionId !== definitionId
          })
        : []
    event.preventDefault()
    pairs.push({ definitionId, labelId })
    this.props.onChange(pairs)
    this.closeActivityMatchOptions()
  }
  onClickCancel = (definition) => {
    const { value, hasNextActivity, isSavingActivity } = this.props
    if (hasNextActivity || isSavingActivity) return
    const pairs = value || []
    const filteredPairs = pairs.filter(
      (obj) => obj.definitionId !== definition.id,
    )
    this.props.onChange(filteredPairs)
  }
  openActivityMatchOptions = (event, labelId) => {
    const { adminIncorrectAnswers, pageRef, scrollY } = this.props
    event.preventDefault()
    if (adminIncorrectAnswers) return
    this.setState({
      ActivityMatchOptions: {
        labelId,
      },
      scrollTop: pageRef ? pageRef.scrollTop : 0,
    })
    if (scrollY) {
      scrollY(pageRef, 0)
    }
  }
  closeActivityMatchOptions = () => {
    const { pageRef, scrollY } = this.props
    const { scrollTop } = this.state
    if (scrollY) {
      scrollY(pageRef, scrollTop)
    }
    this.setState({
      ActivityMatchOptions: null,
    })
    this.setFocusBody()
  }
  isMatchCorrect = (definition, inSelection) => {
    if (!inSelection) return null
    const { answerResponse, correctAnswers, incorrectAnswers } = this.props
    const { changedSelections } = this.state
    if (!answerResponse || changedSelections[definition.id]) return null
    let isCorrect = null
    for (let i = 0; i < correctAnswers.length; i++) {
      if (definition.id === correctAnswers[i]) {
        isCorrect = true
        break
      }
    }
    if (!isCorrect) {
      for (let i = 0; i < incorrectAnswers.length; i++) {
        if (definition.id === incorrectAnswers[i]) {
          isCorrect = false
          break
        }
      }
    }
    return isCorrect
  }
  setFocusBody = () => {
    if (this.$body) {
      this.$body.focus()
    }
  }
  setRefBody = (el) => {
    if (!el) return
    this.$body = el
  }
  renderAdminCorrectIncorrectIcon(isCorrect) {
    return (
      <div
        className={cx('ActivityMatch-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderCorrectIncorrectIcon(isCorrect) {
    if (isCorrect === null) return null
    return (
      <div
        className={cx('ActivityMatch-answerResponse', {
          'is-success': isCorrect,
        })}
      >
        <CorrectIncorrectIcon isCorrect={isCorrect} />
      </div>
    )
  }
  renderDefinitionBody = (
    definition,
    index,
    isSelected = false,
    isCorrect = false,
  ) => {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      intl,
      onOpenActivityModal,
    } = this.props
    const { formatMessage } = intl
    // const zoomContent = typeof index === 'number' ? index + 1 : (index.content ? index.content : '')
    const image = definition.image
    let content = definition.content
    isCorrect = adminCorrectAnswers
      ? true
      : adminIncorrectAnswers
      ? isCorrect
      : this.isMatchCorrect(definition, isSelected)
    if (!content && !image) {
      content = index + 1
    }
    return (
      <div className="ActivityMatch-definitionBody">
        {image && <img className="ActivityMatch-img" src={image} />}
        {image && (
          <Button
            aria-label={formatMessage(ActivityMatchMessages.enlargeImage)}
            className={cx('Activity-zoomImage', { 'is-selected': isSelected })}
            onClick={(e) => onOpenActivityModal(e, image)}
          >
            <span>{formatMessage(ActivityMatchMessages.enlargeImage)}</span>
            <i
              aria-hidden="true"
              className="fa fa-search-plus"
              title={formatMessage(ActivityMatchMessages.enlargeImage)}
            />
          </Button>
        )}
        {content && (
          <div
            className="ActivityMatch-definitionBodyContent"
            dangerouslySetInnerHTML={{ __html: content }}
            qa-id="learner-activity-matching-content"
          />
        )}
        {adminCorrectAnswers || adminIncorrectAnswers
          ? this.renderAdminCorrectIncorrectIcon(isCorrect)
          : this.renderCorrectIncorrectIcon(isCorrect)}
      </div>
    )
  }
  renderDefinition = (definition, index) => {
    const { id: definitionId } = definition
    const { isSelected } = definition
    const className = cx('ActivityMatch-definition', {
      'is-selected': isSelected,
    })
    return (
      <a
        className={className}
        data-id={definitionId}
        href="#"
        key={definitionId}
        onClick={(event) => this.onClickDefinition(event, definitionId)}
        role="button"
      >
        {this.renderDefinitionBody(definition, index, false)}
      </a>
    )
  }
  renderDefinitions = () => {
    return (
      <div className="ActivityMatch-definitions">
        {this.getDefinitionRows().map((definitions, index) => {
          return (
            <div className="columns" key={`ActivityMatch-definitions_${index}`}>
              {definitions.map(({ definition, index }) => {
                return (
                  <div
                    className="column is-half"
                    key={`ActivityMatch-definition_${index}`}
                  >
                    {this.renderDefinition(definition, index)}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }
  renderOptions = () => {
    const { intl } = this.props
    const { ActivityMatchOptions } = this.state
    const { formatMessage } = intl
    const { labelId } = ActivityMatchOptions
    const label = this.getLabelById(labelId)
    return (
      <div className="ActivityMatch-options">
        <div className="ActivityMatch-optionsHeader">
          {this.renderLabel(label)}
          <span className="ActivityMatch-optionsInstructions">
            {formatMessage(ActivityMatchMessages.optionsInstructions)}
          </span>
        </div>
        <div className="ActivityMatch-optionsBody">
          {this.renderDefinitions()}
        </div>
        <div className="ActivityMatch-optionsBack">
          <Button
            onClick={this.closeActivityMatchOptions}
            text={formatMessage(ActivityMatchMessages.optionsBack)}
            qa-id="learner-activity-matching-back"
          />
        </div>
      </div>
    )
  }
  renderLabel = (label) => {
    const { intl, onOpenActivityModal } = this.props
    const { formatMessage } = intl
    return (
      <div className="ActivityMatch-label" data-id={label.id} key={label.id}>
        {label.image ? (
          <Fragment>
            <img className="ActivityMatch-img" src={label.image} />
            <Button
              aria-label={formatMessage(ActivityMatchMessages.enlargeImage)}
              className="Activity-zoomImage"
              onClick={(e) => onOpenActivityModal(e, label.image)}
            >
              <span>{formatMessage(ActivityMatchMessages.enlargeImage)}</span>
              <i
                aria-hidden="true"
                className="fa fa-search-plus"
                title={formatMessage(ActivityMatchMessages.enlargeImage)}
              />
            </Button>
          </Fragment>
        ) : null}
        {label.content ? (
          <div
            qa-id="learner-activity-matching-label"
            dangerouslySetInnerHTML={{ __html: label.content }}
          />
        ) : null}
      </div>
    )
  }
  renderSelection = (definition, index, isCorrect = false) => {
    const {
      adminCorrectAnswers,
      adminIncorrectAnswers,
      hasNextActivity,
      intl,
    } = this.props
    const { formatMessage } = intl
    isCorrect = adminCorrectAnswers
      ? true
      : adminIncorrectAnswers
      ? isCorrect
      : this.isMatchCorrect(definition, true)
    return (
      <div className="ActivityMatch-selectionDefinition">
        {this.renderDefinitionBody(definition, index, true, isCorrect)}
        {!adminIncorrectAnswers && !isCorrect && !hasNextActivity ? (
          <Button
            aria-label={formatMessage(ActivityMatchMessages.clearSelectionAria)}
            className="ActivityMatch-selectionCancelButton"
            onClick={() => this.onClickCancel(definition)}
            qa-id="learner-activity-matching-clear"
          >
            <span>+</span>
            <span className="is-mobile">
              {formatMessage(ActivityMatchMessages.clearSelection)}
            </span>
          </Button>
        ) : null}
      </div>
    )
  }
  renderRow = (label, index) => {
    const { adminCorrectAnswers, adminIncorrectAnswers, definitions } =
      this.props
    const { id: labelId } = label
    const pair = this.getPairByLabelId(labelId)
    const adminAnswers = adminCorrectAnswers || adminIncorrectAnswers
    let isCorrect = null
    const definition = adminAnswers
      ? definitions.filter(
          (obj) =>
            adminAnswers.filter((adminAnswer) => {
              if (
                obj.id === adminAnswer.definition &&
                labelId === adminAnswer.label
              ) {
                isCorrect = adminAnswer.isCorrect
                return true
              }
            })[0],
        )[0]
      : pair && pair.definitionId
      ? this.getDefinitionById(pair.definitionId)
      : null
    return (
      <div
        className={cx('ActivityMatch-row', {
          'is-empty': !definition,
        })}
        qa-id="learner-activity-matching-row"
        key={`ActivityMatch-row_${index}`}
      >
        <div className="columns">
          <div className="column">{this.renderLabel(label)}</div>
          <div className="column">
            <a
              className="ActivityMatch-selection"
              href="#"
              onClick={(event) =>
                !definition && this.openActivityMatchOptions(event, labelId)
              }
              role="button"
            >
              {definition ? (
                this.renderSelection(definition, label, isCorrect)
              ) : (
                <Button
                  className="ActivityMatch-selectionButton"
                  isCircle
                  qa-id="learner-activity-matching-button"
                >
                  <span>+</span>
                </Button>
              )}
            </a>
          </div>
        </div>
      </div>
    )
  }
  renderRows = () => {
    return (
      <div className="ActivityMatch-rows">
        {this.props.labels.map(this.renderRow)}
      </div>
    )
  }
  renderVideo() {
    return (
      <div className="Activity-video">
        <Video type={VIDEO_TYPES.KALTURA_VIDEO} entryId={this.props.video} />
      </div>
    )
  }
  renderBody = ({ renderTitle, renderImageAfterQuestion }) => {
    const { imageAlignment } = this.props
    return (
      <div className="ActivityMatch">
        {renderTitle()}
        {this.props.video && this.renderVideo()}
        <div className="ActivityMatch-body" ref={this.setRefBody} tabIndex="0">
          {this.state.ActivityMatchOptions
            ? this.renderOptions()
            : this.renderRows()}
        </div>
        {imageAlignment === 'bottom' ? renderImageAfterQuestion() : null}
      </div>
    )
  }
  render() {
    const { label, ...restProps } = this.props
    return (
      <Activity
        {...restProps}
        className={cx('is-match', this.props.className)}
        label={this.label}
        render={this.renderBody}
        type={ACTIVITY_TYPES.matching}
      />
    )
  }
}
