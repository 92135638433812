import { observable, computed } from 'mobx'
import _ from 'lodash'
import moment from 'moment'

import { toCamelCase, timestamp } from '@/utils/helpers'

import User from './user'

export default class Thread {
  @observable id
  @observable summary
  @observable author
  @observable user
  @observable recipients
  @observable courseId
  @observable created
  @observable modified
  @observable summaryAuthor

  @observable messages = []
  @observable messagesCount = 0

  @observable shouldMarkAsRead = false
  @observable unreadCount = null
  @observable unreadMessageIds = []

  constructor(data = {}) {
    this.init(data)
    this.id = data.id ? data.id : _.uniqueId('thread_')
    this.created = moment(data.created)
    this.modified = moment(data.modified)

    if (!(data.author instanceof User)) {
      this.author = new User(data.author)
    }
    // current user
    if (data.user && !(data.user instanceof User)) {
      this.user = new User(data.user)
    }
    if (data.recipients) {
      this.recipients = data.recipients.map((r) => {
        if (r instanceof User) return r
        return new User(r)
      })
    }
  }

  init(data = {}) {
    toCamelCase(this, data)
  }

  @computed get isGroup() {
    return this.recipients.length > 2
  }

  @computed get firstRecipient() {
    return _.find(this.recipients, (r) => r.username !== this.user.username)
  }

  @computed get othersCount() {
    if (!this.isGroup) return 0
    return this.recipients.length - 2
  }

  @computed get recipientsExceptUser() {
    return this.recipients.filter((r) => r.username !== this.user.username)
  }

  @computed get title() {
    if (!this.isGroup) {
      return this.firstRecipient.fullName
    }

    return `${this.firstRecipient.fullName} and ${this.othersCount} ${
      this.othersCount > 1 ? 'others' : 'other'
    }`
  }

  // formatted created
  @computed get fcreated() {
    return timestamp(this.created)
  }

  // formatted modified
  @computed get fmodified() {
    return timestamp(this.modified)
  }

  @computed get summaryFullName() {
    const {
      first_name: summaryAuthorFirstName,
      last_name: summaryAuthorLastName,
    } = this.summaryAuthor || {}
    return `${summaryAuthorFirstName || ''} ${summaryAuthorLastName || ''}`
  }

  @computed get isMine() {
    return this.author.id === this.user.id
  }
}
