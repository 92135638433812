import React from 'react'
import cx from 'classnames'

import './RadialProgress.scss'

const RADIUS = 56
const STROKE = 8
const SIZE = 80

const RadialProgress = (props) => {
  const className = cx(
    'RadialProgress',
    {
      'is-alt': props.isAlt,
      'is-danger': props.isDanger,
      'is-success': props.isSuccess,
      'is-small': props.isSmall,
    },
    props.className,
  )

  const circumference = 2 * Math.PI * RADIUS
  const progress = props.value / 100
  const dashoffset = circumference * (1 - progress)
  const progressText = !props.isText
    ? Number(props.value).toFixed(0, 10)
    : props.value
  const size = props.size || SIZE

  return (
    <div className={className}>
      <svg
        className="RadialProgress-progress"
        width={size}
        height={size}
        viewBox="0 0 120 120"
      >
        <circle
          className="RadialProgress-slice"
          cx="50%"
          cy="50%"
          r={RADIUS / 2}
          strokeWidth={RADIUS}
          strokeDasharray={circumference / 2}
          strokeDashoffset={dashoffset / 2}
        />
        <circle
          className="RadialProgress-meter"
          cx="50%"
          cy="50%"
          r={RADIUS}
          strokeWidth={STROKE}
        />
        <circle
          className="RadialProgress-value"
          cx="50%"
          cy="50%"
          r={RADIUS}
          strokeWidth={progress !== 0 ? STROKE : 0}
          strokeDasharray={circumference}
          strokeDashoffset={dashoffset}
        />
      </svg>
      <span
        className={cx('RadialProgress-text', {
          'is-text': props.isText,
        })}
      >
        {progressText}
      </span>
    </div>
  )
}

export default RadialProgress
