import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import LoginLayout from '../LoginLayout'
import UpdatePasswordForm from './UpdatePasswordForm'
import './UpdatePasswordPage.scss'

@inject('sessionStore', 'uiStore')
@observer
export default class UpdatePasswordPage extends Component {
  state = {
    isLoading: false,
    error: null,
  }
  componentDidMount() {
    const { history, sessionStore, uiStore } = this.props
    const { temporaryUser } = sessionStore
    if (!temporaryUser) history.push('/')

    uiStore.updateTitle('Update Password Page')
  }
  updatePassword = (password, confirmPassword) => {
    const { history, sessionStore } = this.props
    this.setState({ isLoading: true })
    sessionStore
      .updatePassword(password, confirmPassword)
      .then(({ logoutUrl, token, user }) => {
        sessionStore.loginWithDelay(
          {
            logoutUrl,
            token,
            user,
          },
          () => {
            this.setState({ isLoading: false })
            history.push('/')
          },
        )
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.message,
        })
      })
  }
  onFormValidate = () => {
    this.setState({ error: null })
  }
  renderForm() {
    return (
      <UpdatePasswordForm
        onValidate={this.onFormValidate}
        onSubmit={this.updatePassword}
        isLoading={this.state.isLoading}
        error={this.state.error}
      />
    )
  }
  renderSuccessMessage() {
    const { sessionStore } = this.props
    const { loginWithDelayTimer } = sessionStore
    return (
      <div className="LoginLayout-success">
        <p>You have successfully changed your password.</p>
        <p>
          You will automatically be logged in to the platform in{' '}
          {loginWithDelayTimer} seconds...
        </p>
      </div>
    )
  }
  render() {
    const { sessionStore } = this.props
    const { loginWithDelayIntervalId } = sessionStore
    return (
      <LoginLayout className="UpdatePasswordPage" isUpdatePassword>
        <div className="title is-spaced">Create a new password</div>
        {!loginWithDelayIntervalId && (
          <p className="subtitle">
            Please enter your new password. It must be between 5 and 100
            characters and contain both letters and numbers.
          </p>
        )}
        {loginWithDelayIntervalId
          ? this.renderSuccessMessage()
          : this.renderForm()}
      </LoginLayout>
    )
  }
}
