import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { inject, observer } from 'mobx-react'
import { Footer, Header, MobileNavMenu, SkipNavigation } from '@/components'
import './AdminLayout.scss'

@withRouter
@inject('sessionStore')
@observer
class AdminLayout extends Component {
  componentDidMount() {
    const { sessionStore } = this.props
    const { session } = sessionStore
    if (!session) return null
  }
  render() {
    const { children } = this.props
    return (
      <div
        className="AdminLayout"
        ref={(el) => {
          this.el = el
        }}
        qa-id="admin-app-layout"
      >
        <SkipNavigation />
        <Header />
        <MobileNavMenu />
        <div className="PageWrapper">
          <div className="Container container">{children}</div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default AdminLayout
