import { extendObservable, action, computed } from 'mobx'
import _ from 'lodash'
import humps from 'lodash-humps'
import moment from 'moment'
import qs from 'query-string'

import { handleError } from '@/api'
import {
  MESSAGE_READ_STATUS,
  MESSAGE_UNREAD_STATUS,
  PAGE_LIMIT,
  REFRESH_RATE,
  SUMMARY_LENGTH,
} from '@/constants'
import SentryReplay from '@/SentryReplay'
import request from '@/utils/request'

import Thread from './models/thread'
import Message from './models/message'
import User from './models/user'

const initialState = {
  session: null,
  thread: null,
  threads: [],
  isLoadingThreads: false,
  isLoadingMessages: false,
  isLoadingRecipients: false,
  isMarkingThreadAsRead: false,
  isLoadingMoreMessages: false,
  isLoadingMoreThreads: false,
  isLoadingUnreadMessagesCount: false,
  threadsCount: 0,
  unreadMessagesCount: null,
}

export default class MessagesStore {
  refreshIntervalId = null
  loadUnreadMessagesCountIntervalId = null
  // keep track of pending optimistic messages
  // to be able to avoid a refresh until we know for sure we saved them
  pendingOptimitisticMessages = []

  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    this.stopRefreshing()
    this.stopLoadUnreadMessagesCountInterval()
    this.pendingOptimitisticMessages = []
    extendObservable(this, { ...initialState })
  }
  @action init(session) {
    this.session = session
  }

  @computed get isFirstMessagesLoad() {
    return (
      this.isLoadingMessages && this.thread && _.isEmpty(this.thread.messages)
    )
  }
  @computed get isFirstThreadsLoad() {
    return this.isLoadingThreads && _.isEmpty(this.threads)
  }

  @action loadThreads(offset, limit) {
    if (!this.session)
      return Promise.reject(new Error('loadThreads: session is undefined;'))
    this.isLoadingThreads = true
    this.isLoadingMoreThreads = !!(offset && limit)
    const courseId = this.session.courseId
    const queries = qs.stringify({ d: Date.now(), offset, limit })
    return request
      .get(`/messages/${courseId}/threads?${queries}`)
      .then((response) => this.handleLoadThreads(response))
      .catch((error) => this.handleLoadThreadsError(error))
  }
  @action handleLoadThreads(response) {
    if (!this.session)
      return Promise.reject(
        new Error('handleLoadThreads: session is undefined;'),
      )
    let { threads, threads_count: threadsCount } = response.data
    this.setThreads(threads)
    this.threadsCount = threadsCount
    this.isLoadingThreads = false
    this.isLoadingMoreThreads = false
    // set a default thread if we don't have one
    if (!this.thread && this.threads.length > 0) {
      this.thread = this.threads[0]
    }
    return response
  }
  @action handleLoadThreadsError(error) {
    SentryReplay.captureException(error)
    this.isLoadingThreads = false
    this.isLoadingMoreThreads = false
    return Promise.reject(error)
  }
  @action loadMoreThreads() {
    const offset = this.threads.length
    const limit = PAGE_LIMIT
    if (offset >= this.threadsCount || this.isLoadingMoreThreads) return
    return this.loadThreads(offset, limit)
  }

  @action loadMessages(threadId, offset, limit) {
    if (!this.session)
      return Promise.reject(new Error('loadMessages: session is undefined;'))
    this.setThread(threadId)
    this.isLoadingMessages = true
    this.isLoadingMoreMessages = !!(offset && limit)
    // current thread id at the moment of request
    const currentThreadId = this.thread.id
    const queries = qs.stringify({ d: Date.now(), offset, limit })
    return request
      .get(
        `/messages/${this.session.courseId}/threads/${currentThreadId}/messages?${queries}`,
      )
      .then((response) => this.handleLoadMessages(response, currentThreadId))
      .catch((error) => this.handleLoadMessagesError(error))
  }
  @action handleLoadMessages(response, currentThreadId) {
    if (!this.session)
      return Promise.reject(
        new Error('handleLoadMessages: session is undefined;'),
      )
    const hasThreadChanged = currentThreadId !== this.thread.id
    const hasPendingOptimisticMessages =
      this.pendingOptimitisticMessages.length > 0

    // avoid loading if thread has changed in the meantime
    // or if there are optimistic messages pending (to be saved)
    if (hasThreadChanged || hasPendingOptimisticMessages) return

    let { messages, messages_count: messagesCount } = response.data
    this.setMessages(messages)
    this.thread.messagesCount = messagesCount
    this.isLoadingMessages = false
    this.isLoadingMoreMessages = false

    return response
  }
  @action handleLoadMessagesError(error) {
    SentryReplay.captureException(error)
    this.isLoadingMessages = false
    this.isLoadingMoreMessages = false
    return Promise.reject(error)
  }
  @action loadMoreMessages() {
    if (!this.thread) return
    const offset = this.thread.messages.length
    const limit = PAGE_LIMIT
    if (offset >= this.thread.messagesCount || this.isLoadingMoreMessages)
      return
    this.loadMessages(this.thread.id, offset, limit)
  }

  @action setThread(threadId) {
    if (!threadId) return
    const id = Number(threadId)
    // quit if we already set this thread
    if (this.thread && this.thread.id === id) return
    // if the id exists, find it and set it
    let thread = _.find(this.threads, { id })
    if (!thread) return
    this.thread = thread
  }
  @action setThreads(threads = []) {
    const newThreads = []
    threads.forEach((thread) => {
      const existing = _.find(this.threads, (t) => t.id === thread.id)
      const isChanged = existing && !existing.modified.isSame(thread.modified)

      thread.summary = _.truncate(thread.summary, { length: SUMMARY_LENGTH })

      if (!existing) {
        newThreads.push(new Thread({ ...thread, user: this.session.user }))
      } else {
        existing.unreadCount = thread.unread_count
        if (!existing.unreadCount) {
          existing.unreadMessageIds = []
          existing.messages.forEach((message) => {
            message.readStatus = MESSAGE_READ_STATUS
          })
        }
      }
      if (isChanged) {
        existing.summary = thread.summary
        existing.summaryAuthor = thread.summary_author
        existing.modified = moment(thread.modified)
      }
    })
    this.threads = _.sortBy(
      [...newThreads, ...this.threads],
      (t) => -Number(t.modified.format('x')),
    )
  }
  @action setMessages(messages = []) {
    const {
      id: threadId,
      messages: threadMessages,
      unreadCount,
      unreadMessageIds,
    } = this.thread
    const thread = this.threads.filter((thrd) => thrd.id === threadId)[0]
    const newMessages = messages
      // filter new messages
      .filter((message) => !_.find(threadMessages, (m) => m.id === message.id))
      // map them to objects
      .map((message) => new Message({ ...message, user: this.session.user }))
    // remove optimistic messages
    const nonOptimistic = threadMessages.filter(
      (message) => !message.isOptimistic,
    )

    thread.messages = _.sortBy([...nonOptimistic, ...newMessages], (m) =>
      Number(m.modified.format('x')),
    )

    newMessages.forEach((message) => {
      const { id: messageId } = message
      if (
        message.readStatus === MESSAGE_UNREAD_STATUS &&
        unreadMessageIds.indexOf(messageId) === -1
      ) {
        unreadMessageIds.push(messageId)
      }
    })
    if (unreadCount && unreadMessageIds.length === unreadCount) {
      thread.shouldMarkAsRead = true
    }
  }

  @action createThread(recipients, message, attachments) {
    const currentUser = this.session.user
    const recipientsIds = Object.keys(recipients)
      .map((k) => recipients[k].id)
      .filter((recipientId) => recipientId !== currentUser.id)

    if (!recipientsIds.length) {
      return Promise.reject(new Error('No recipients selected'))
    }

    return request
      .post(
        `/messages/${this.session.courseId}/threads`,
        attachments
          ? {
              recipients: recipientsIds,
              message,
              attachments,
            }
          : {
              recipients: recipientsIds,
              message,
            },
      )
      .then(
        action('createThreadSuccess', (response) => {
          const newThread = new Thread({
            ...response.data,
            user: this.session.user,
          })
          this.threads = this.threads.filter(
            (thread) => thread.id !== newThread.id,
          )
          this.threads.unshift(newThread)
          return newThread
        }),
      )
      .catch(handleError)
  }
  @action createMessage(message, attachments) {
    // optimistically create a message
    const newMessage = new Message({
      author: this.session.user,
      user: this.session.user,
      isOptimistic: true,
      message,
    })
    this.thread.messages.push(newMessage)
    this.pendingOptimitisticMessages.push(newMessage.id)

    return request
      .post(
        `/messages/${this.session.courseId}/threads/${this.thread.id}/messages`,
        {
          message,
          attachments,
        },
      )
      .then(() => {
        // alright, refresh will pick this up and add the real message to the list
        this.pendingOptimitisticMessages.pop(newMessage.id)
      })
      .catch(() => {
        this.pendingOptimitisticMessages.pop(newMessage.id)
        handleError()
      })
  }
  @action deleteMessage(messageId) {
    return request
      .delete(
        `/messages/${this.session.courseId}/threads/${this.thread.id}/messages/${messageId}/delete`,
      )
      .catch(handleError)
  }

  @action markMessageAsRead(messageId) {
    return request
      .post(
        `/messages/${this.session.courseId}/threads/${this.thread.id}/messages/${messageId}/read`,
      )
      .catch(handleError)
  }
  @action markThreadAsRead(threadId) {
    if (!this.session)
      return Promise.reject(
        new Error('markThreadAsRead: session is undefined;'),
      )
    const thread = this.threads.filter((thrd) => thrd.id === threadId)[0]
    const { courseId } = this.session
    if (!thread || !thread.shouldMarkAsRead) {
      return Promise.resolve()
    }
    this.isMarkingThreadAsRead = true
    return request
      .post(`/messages/${courseId}/threads/${threadId}/read`)
      .then((response) => this.handleMarkThreadAsRead(response, threadId))
      .catch(this.handleMarkThreadAsReadError)
  }
  @action handleMarkThreadAsRead = (response, threadId) => {
    if (!this.session)
      return Promise.reject(
        new Error('handleMarkThreadAsRead: session is undefined;'),
      )
    const thread = this.threads.filter((thrd) => thrd.id === threadId)[0]
    this.isMarkingThreadAsRead = false
    if (thread) {
      thread.shouldMarkAsRead = false
    }
  }
  @action handleMarkThreadAsReadError = (error) => {
    SentryReplay.captureException(error)
    this.isMarkingThreadAsRead = false
    return Promise.reject(error)
  }

  @action loadRecipients(term) {
    if (_.isEmpty(term)) return Promise.resolve(null)
    this.isLoadingRecipients = true
    return request
      .get(
        `/search/${
          this.session.courseId
        }/users/autocomplete?term=${term}&d=${Date.now()}`,
      )
      .then((response) => this.handleLoadRecipients(response))
      .catch((response) => this.handleLoadRecipientsError(response))
  }
  @action handleLoadRecipients(response) {
    this.isLoadingRecipients = false
    const currentUser = this.session.user
    const results = response.data.results && response.data.results.user
    if (!results) {
      return []
    }
    return results
      .map((user) => new User(user))
      .filter((user) => user.id !== currentUser.id)
  }
  @action handleLoadRecipientsError(error) {
    SentryReplay.captureException(error)
    this.isLoadingRecipients = false
    handleError(error)
  }

  @action loadUnreadMessagesCount() {
    if (!this.session)
      return Promise.reject(
        new Error('loadUnreadMessagesCount: session is undefined;'),
      )
    const { courseId } = this.session
    this.isLoadingUnreadMessagesCount = true
    return request
      .get(`/messages/${courseId}/counts?d=${Date.now()}`)
      .then(this.handleLoadUnreadMessagesCount)
      .catch(this.handleLoadUnreadMessagesCountError)
  }
  @action handleLoadUnreadMessagesCount = (response) => {
    if (!this.session)
      return Promise.reject(
        new Error('handleLoadUnreadMessagesCount: session is undefined;'),
      )
    const { courseId } = this.session
    const { data } = response
    const result = humps(data)
    const courseResult = result.filter(
      (course) => course.courseContentfulId === courseId,
    )[0]
    this.unreadMessagesCount =
      courseResult && courseResult.unreadCount ? courseResult.unreadCount : 0
    this.isLoadingUnreadMessagesCount = false
    return this.unreadMessagesCount
  }
  @action handleLoadUnreadMessagesCountError = (error) => {
    SentryReplay.captureException(error)
    this.isLoadingUnreadMessagesCount = false
    return Promise.reject(error)
  }

  startLoadUnreadMessagesCountInterval = () => {
    if (this.loadUnreadMessagesCountIntervalId) {
      return
    }
    this.loadUnreadMessagesCountIntervalId = setInterval(() => {
      this.loadUnreadMessagesCount()
    }, REFRESH_RATE * 12) // 5000 * 12 === 60000 === 1min
  }
  stopLoadUnreadMessagesCountInterval = () => {
    clearInterval(this.loadUnreadMessagesCountIntervalId)
    this.loadUnreadMessagesCountIntervalId = null
  }

  startRefreshing() {
    // don't start another refresh interval if we already have one
    if (this.refreshIntervalId) return
    // keep trying until we have a thread
    if (!this.thread) {
      setTimeout(() => this.startRefreshing(), REFRESH_RATE)
      return
    }
    this.refreshIntervalId = setInterval(() => this.refresh(), REFRESH_RATE)
  }
  stopRefreshing() {
    clearInterval(this.refreshIntervalId)
    this.refreshIntervalId = null
  }
  refresh() {
    if (this.thread) {
      const { id: threadId, shouldMarkAsRead } = this.thread
      this.loadMessages()
      this.loadThreads().then(() => this.loadUnreadMessagesCount())
      if (!this.isMarkingThreadAsRead && shouldMarkAsRead) {
        this.markThreadAsRead(threadId).then(() =>
          this.loadUnreadMessagesCount(),
        )
      }
    }
  }
}
