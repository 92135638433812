import React from 'react'
import cx from 'classnames'

import { Button, CheckboxCustom, Heading } from '@/components'

import {
  SETTINGS_CATEGORY_PRETEST,
  SNAPSHOT_CHOICES,
  SNAPSHOT_NAME,
  SNAPSHOT_TEST_OUT_CHOICES,
  SNAPSHOT_TEST_OUT_NAME,
} from './InstanceSettingsPageConstants'

const InstanceSettingsPagePretest = ({
  hasPretestSettings,
  isChecked,
  isEnabledPretest,
  isLoading,
  onChange,
  onSave,
}) => {
  return (
    <div className="InstanceSettingsPage__setting">
      <div className="InstanceSettingsPage__setting-item">
        <Heading is4 className="InstanceSettingsPage__setting-title">
          Pre-Test
        </Heading>
        <span className="InstanceSettingsPage__setting-subtitle">
          Snapshot Pre-Test is recommended for courses where Learners have some
          pre-existing knowledge of the subject matter. Based on how they
          perform, they could “test-out” of certain MicroLearning Sections
          upfront, allowing them to complete their course even more efficiently
          without sacrificing proficiency. MicroLearning Sections that are
          tested-out upfront are still accessible but not required to complete
          the course.
          {!hasPretestSettings ? (
            <span className="InstanceSettingsPage__setting-subtitle-contact">
              Please contact Fulcrum Client Success at clients@fulcrumlabs.ai to
              learn more about this product add-on.
            </span>
          ) : null}
        </span>
        {hasPretestSettings &&
          SNAPSHOT_CHOICES.map((choice, i) => (
            <div
              key={`InstanceSettingsPage-snapshot-${i}`}
              className={cx('InstanceSettingsPage-snapshot', {
                'InstanceSettingsPage__setting-disabled':
                  !hasPretestSettings || isEnabledPretest,
              })}
            >
              <CheckboxCustom
                checked={isChecked(SNAPSHOT_NAME, choice.value)}
                className="InstanceSettingsPage-value--is-bold"
                disabled={!hasPretestSettings || isEnabledPretest}
                isRadio
                name={SNAPSHOT_NAME}
                onChange={onChange}
                value={choice.value}
              >
                <span className="InstanceSettingsPage__value-label">
                  <span className="InstanceSettingsPage__value-text">
                    {choice.text}
                  </span>
                </span>
              </CheckboxCustom>
            </div>
          ))}
      </div>
      {hasPretestSettings && isChecked(SNAPSHOT_NAME, true) ? (
        <div className="InstanceSettingsPage__setting-item">
          <span className="InstanceSettingsPage__setting-subtitle">
            When Snapshot Pre-Test is enabled, the following setting will
            default to Enable Test Out. You can override this function by
            selecting Disable Test Out, which is not recommended.
          </span>
          {SNAPSHOT_TEST_OUT_CHOICES.map((choice, i) => (
            <div
              key={`InstanceSettingsPage-snapshot-test-out-${i}`}
              className={cx('InstanceSettingsPage-snapshot-test-out', {
                'InstanceSettingsPage__setting-disabled':
                  !hasPretestSettings || isEnabledPretest,
              })}
            >
              <CheckboxCustom
                checked={isChecked(SNAPSHOT_TEST_OUT_NAME, choice.value)}
                className="InstanceSettingsPage-value--is-bold"
                disabled={!hasPretestSettings || isEnabledPretest}
                isRadio
                name={SNAPSHOT_TEST_OUT_NAME}
                onChange={onChange}
                value={choice.value}
              >
                <span className="InstanceSettingsPage__value-label">
                  <span className="InstanceSettingsPage__value-text">
                    {choice.text}
                  </span>
                </span>
              </CheckboxCustom>
            </div>
          ))}
        </div>
      ) : null}
      {hasPretestSettings && (
        <Button
          className="InstanceSettingsPage__setting-save"
          disabled={!hasPretestSettings || isEnabledPretest}
          isLoading={isLoading}
          isPrimary
          name={SETTINGS_CATEGORY_PRETEST}
          onClick={onSave}
        >
          Save
        </Button>
      )}
    </div>
  )
}

export default InstanceSettingsPagePretest
