import React, { Component } from 'react'
import cx from 'classnames'

import { Loader } from '@/components'

import './RichTextWrapper.scss'

export default class RichTextWrapper extends Component {
  state = {
    height: 'auto',
    isLoading: true,
    isMounted: false,
  }

  componentDidMount() {
    this.initMessaging()
    this.setState({ isMounted: true })
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.messagingListener)
  }
  initMessaging() {
    window.addEventListener('message', this.messagingListener, false)
  }
  messagingListener = (event) => {
    const {
      content,
      id,
      onChangeMultipleInput,
      onClickAnchor,
      onLoad,
      onOpenImageModal,
      onScrollToAnchor,
      onSubmitMultipleInput,
    } = this.props
    const { isLoading } = this.state
    let action, context, dataJSON, eventId, payload
    try {
      if (event && event.data) {
        const { data } = event
        dataJSON = JSON.parse(data)
        action = dataJSON.action
        context = dataJSON.context
        eventId = dataJSON.id
        payload = dataJSON.payload
      }
    } catch (ex) {
      console.error(
        `Error: could not receive message${
          ex && ex.message ? ` - ${ex.message}` : ''
        };`,
      )
    }
    if (
      !context ||
      context !== 'fulcrumlabs_rich-text-wrapper' ||
      (action !== 'ping' && eventId !== id)
    )
      return
    switch (action) {
      case 'load':
        if (onLoad) {
          onLoad(payload)
        }
        break
      case 'onChangeMultipleInput':
        if (onChangeMultipleInput) {
          onChangeMultipleInput(payload)
        }
        break
      case 'onClickImg':
        if (onOpenImageModal) {
          onOpenImageModal(null, payload)
        }
        break
      case 'onClickAnchor':
        if (onClickAnchor) {
          onClickAnchor(payload)
        }
        break
      case 'onScrollToAnchor':
        if (payload && onScrollToAnchor) {
          onScrollToAnchor(payload)
        }
        break
      case 'onSubmitMultipleInput':
        if (onSubmitMultipleInput) {
          onSubmitMultipleInput()
        }
        break
      case 'ping':
        if (isLoading) {
          this.sendMessage({ action: 'content', id, payload: content })
          this.setState({ isLoading: false })
        }
        break
      case 'resize':
        this.setState({ height: `${payload}px` })
        break
      default:
        if (action !== 'xapi') {
          console.warn('Unhandled action:', action, payload)
        }
        break
    }
  }
  sendMessage = (message) => {
    this.$iframe.contentWindow.postMessage(JSON.stringify(message), '*')
  }
  setIframeRef = (el) => {
    const { setRef } = this.props
    this.$iframe = el
    if (setRef) setRef(el)
  }
  render() {
    const { loadingText } = this.props
    const { className, height, isLoading, isMounted } = this.state
    return (
      <div className={cx('RichTextWrapper', className)}>
        {isLoading && (
          <Loader ariaLive="assertive" role="status" text={loadingText} />
        )}
        {isMounted && (
          <iframe
            className={cx('RichTextWrapper__content', {
              'RichTextWrapper__content--is-loading': isLoading,
            })}
            height={height}
            ref={this.setIframeRef}
            src="https://embeds.fulcrumlabs.ai/rte/0.1.0/index.html"
            style={{ height: height }}
          />
        )}
      </div>
    )
  }
}
