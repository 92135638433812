import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import {
  Loader,
  Accordion,
  AccordionItem,
  CourseCompletionMessageBanner,
  // NOTE / TODO / WIP - make first login message banner client specific
  // FirstLoginMessageBanner,
  SectionList,
  Button,
  RadialProgress,
} from '@/components'
import './SummaryPage.scss'

const SummaryPageMessages = defineMessages({
  continue: {
    defaultMessage: 'Continue',
    description:
      'Button to continue in a course where the user has already made progress from the summary page.',
    id: 'SummaryPage.continue',
  },
  labelUpNext: {
    defaultMessage: 'Up next:',
    description:
      'Label to display the section that the user is currently making progress in on the summary page.',
    id: 'SummaryPage.labelUpNext',
  },
  startLearning: {
    defaultMessage: 'Start Learning',
    description:
      'Button to begin making progress on the course where the user has not made any progress from the summary page.',
    id: 'SummaryPage.startLearning',
  },
  unitMastered: {
    defaultMessage: 'Unit Mastered',
    description:
      'Unit description for when unit is mastered on the summary page',
    id: 'SummaryPage.unitMastered',
  },
  unitSectionsLeft: {
    defaultMessage: '{sectionsLeft} left',
    description: 'Unit description for sections left on the summary page.',
    id: 'SummaryPage.unitSectionsLeft',
  },
})

@injectIntl
@inject(
  'contentStore',
  'reportingStore',
  'sessionStore',
  'uiStore',
  'xapiStore',
)
@observer
export default class SummaryPage extends Component {
  state = {
    activeUnit: null,
    isLoading: false,
  }
  get sectionsAsObject() {
    const { contentStore } = this.props
    const { course } = contentStore
    const { units } = course
    const sections = {}
    units.forEach((unit) => {
      unit.sections.forEach((section) => {
        sections[section.id] = section
      })
    })
    return sections
  }
  componentDidMount() {
    const { contentStore, reportingStore, sessionStore, uiStore } = this.props
    const { settings } = contentStore
    const { learner } = reportingStore
    const { isDisabledDashboard } = settings || {}
    if (isDisabledDashboard || !learner) {
      this.setState({ isLoading: true })
      reportingStore.createLearner({
        firstName: sessionStore.user.firstName,
        lastName: sessionStore.user.lastName,
      })
      reportingStore.refreshReport()
      reportingStore
        .loadCourse()
        .then(() => reportingStore.loadLearner(sessionStore.user.roleId))
        .then(() => {
          this.setState({ isLoading: false })
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    }

    uiStore.updateTitle('Summary Page')
  }
  onAccordionChange = (activeUnit) => {
    this.setState({ activeUnit })
  }
  onCloseFirstLoginMessage = () => {
    const { uiStore } = this.props
    uiStore.closeFirstLoginMessage()
  }
  onContinue = () => {
    const { contentStore, xapiStore } = this.props
    const { course } = contentStore
    const { isLaunchingSection } = xapiStore
    const firstTabHref = course.nextSection.tabs[0].href
    if (isLaunchingSection) return
    this.props.history.push(firstTabHref)
  }
  onSectionChange = (section) => {
    const { xapiStore } = this.props
    const { isLaunchingSection } = xapiStore
    if (section.locked || isLaunchingSection) return
    const firstTabHref = section.tabs[0].href
    this.props.history.push(firstTabHref)
  }
  renderBanner() {
    const {
      contentStore,
      reportingStore,
      // uiStore
    } = this.props
    const { course, courseProgress } = contentStore
    // NOTE / TODO / WIP - make first login message banner client specific
    // const { isOpenFirstLoginMessage } = uiStore
    // NOTE / TODO / WIP - make first login message banner client specific
    // const { mastery: courseMastery, numSections, title: courseTitle } = course || {}
    const { numSections, pretestMasteredCourse } = course || {}
    if (courseProgress === 100) {
      const courseSections = this.sectionsAsObject
      const { settings } = contentStore
      const { isNotFoundLearner, learner } = reportingStore
      const { sections } = learner || {}
      const { courseCompletionCopy, courseMasteryCopy } = settings || {}
      return (
        <CourseCompletionMessageBanner
          courseCompletionCopy={courseCompletionCopy}
          courseMasteryCopy={courseMasteryCopy}
          isNotFoundLearner={isNotFoundLearner}
          numSections={numSections}
          pretestMasteredCourse={pretestMasteredCourse}
          sectionsCompleted={
            sections &&
            sections.filter((section) => {
              const { contentfulId: sectionContentfulId } = section
              const { mastered } = courseSections[sectionContentfulId] || {}
              return !!mastered
            })
          }
        />
      )
    }
    // return <Fragment>
    //  {/* NOTE / TODO / WIP - make first login message banner client specific */}
    //  {/* <FirstLoginMessageBanner courseTitle={courseTitle} isOpen={!isLoadingUpdates && isOpenFirstLoginMessage} onClick={this.onCloseFirstLoginMessage} /> */}
    // </Fragment>
    return null
  }
  renderBody() {
    const { contentStore, intl } = this.props
    const { activeUnit } = this.state
    const { course, courseProgress, settings } = contentStore || {}
    const { formatMessage } = intl
    const {
      displayTitle: courseDisplayTitle,
      nextSection,
      nextSectionId: courseNextSectionId,
      nextUnitId,
      title: courseTitle,
      units: courseUnits,
    } = course || {}
    const { title: nextSectionTitle } = nextSection || {}
    if (!course) {
      return null
    }

    const hasProgress = course && courseProgress > 0
    const active = activeUnit || [nextUnitId]
    const {
      quiz: isQuizEnabled,
      quizMastery: isQuizMastery,
      isRequireMastery,
    } = settings || {}

    return (
      <div>
        {this.renderBanner()}
        <div className="SummaryPage-body">
          <div className="SummaryPage-progress">
            <div className="SummaryPage-header level">
              <div className="level-left">
                <div className="media">
                  <div className="media-left">
                    <div className="SummaryPage-graph">
                      <RadialProgress isAlt value={courseProgress} />
                    </div>
                  </div>
                  <div className="media-content">
                    <h1 className="title">
                      {courseDisplayTitle || courseTitle}
                    </h1>
                    <p className="SummaryPage-upNext">
                      <strong>
                        {formatMessage(SummaryPageMessages.labelUpNext)}&nbsp;
                      </strong>
                      {nextSectionTitle}
                    </p>
                  </div>
                </div>
              </div>
              <div className="level-right">
                <Button
                  className="SummaryPage-action"
                  qa-id="learner-start-learning-button"
                  isFullWidth
                  isSuccess
                  isWide
                  onClick={this.onContinue}
                >
                  {hasProgress
                    ? formatMessage(SummaryPageMessages.continue)
                    : formatMessage(SummaryPageMessages.startLearning)}
                </Button>
              </div>
            </div>
          </div>

          <div className="SummaryPage-accordion">
            <Accordion active={active} onChange={this.onAccordionChange}>
              {courseUnits.map((unit) => (
                <AccordionItem
                  cid={unit.cid}
                  id={unit.id}
                  key={unit.id}
                  primaryLabel={unit.title}
                  secondaryLabel={
                    unit.sectionsLeft === 0
                      ? formatMessage(SummaryPageMessages.unitMastered)
                      : formatMessage(SummaryPageMessages.unitSectionsLeft, {
                          sectionsLeft: unit.sectionsLeft,
                        })
                  }
                >
                  <SectionList
                    active={courseNextSectionId}
                    isQuizEnabled={isQuizEnabled}
                    isQuizMastery={isQuizMastery}
                    isRequireMastery={isRequireMastery}
                    onChange={this.onSectionChange}
                    sections={unit.sections}
                  />
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { contentStore } = this.props
    const { isLoading } = this.state
    const { isInitializingCourse, isLoadingCourse, isLoadingCourseSettings } =
      contentStore
    return (
      <div id="main-content" className="SummaryPage PageWrapper">
        <div className="container Container">
          {isLoading ||
          isInitializingCourse ||
          isLoadingCourse ||
          isLoadingCourseSettings ? (
            <Loader />
          ) : (
            this.renderBody()
          )}
        </div>
      </div>
    )
  }
}
