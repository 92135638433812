import _ from 'lodash'
import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import qs from 'query-string'
import { Button, Link, SortablePaginatedSearchTable, Tabs } from '@/components'
import { ROLE_STATUS } from '@/constants'
import { hasOwnProperty } from '@/utils/helpers'
import AdminPage from '@/admin/components/AdminPage/AdminPage'
import AdminLayout from '@/admin/components/AdminLayout/AdminLayout'
import NewInstanceModal, {
  NewInstanceModalDefaultValues,
} from '@/admin/modals/NewInstanceModal/NewInstanceModal'
import NewUsersModal, {
  NewUsersModalDefaultValues,
} from '@/admin/modals/NewUsersModal/NewUsersModal'
import WithdrawUserModal, {
  WithdrawUserModalDefaultValues,
} from '@/admin/modals/WithdrawUserModal/WithdrawUserModal'
import { capitalizeFirstLetter } from '@/utils/helpers'
import './InstancesPage.scss'

const coursesTableHeaders = [
  {
    sortBy: 'course_title',
    headerText: 'Course Title',
  },
  {
    sortBy: 'course_id',
    headerText: 'Course ID',
  },
  {
    sortBy: 'rank',
    headerText: 'Status',
  },
  {
    sortBy: 'learners_count', // TODO - add sorting
    headerText: 'Learners',
  },
]

const usersTableHeaders = [
  {
    sortBy: 'name',
    headerText: 'Name',
  },
  {
    sortBy: 'email',
    headerText: 'Email',
  },
  {
    sortBy: 'course_title',
    headerText: 'Course Title',
  },
  {
    sortBy: 'role', // TODO - add sorting
    headerText: 'Type',
  },
]

@inject('adminStore', 'sessionStore', 'uiStore')
@observer
export default class InstancesPage extends Component {
  state = {
    newInstanceModal: null,
    newUsersModal: null,
    filters: {
      limit: 25,
      offset: 0,
      term: '',
    },
    successfulBulkUpload: null,
    unreadMessagesCount: [],
    view: 'courses',
  }
  constructor(props) {
    super(props)
    const { location, match } = this.props
    const { search } = location
    const { url } = match
    const isCourses = url.match(/\/courses$/)
    const querystring = qs.parse(search)
    this.state.filters = {
      ...this.state.filters,
      ...querystring,
    }
    this.state.view = isCourses ? 'courses' : 'users'
  }
  componentDidMount() {
    const { adminStore, uiStore } = this.props
    const { view } = this.state
    adminStore.loadUnreadMessagesCount().then((unreadMessagesCount) => {
      adminStore.startLoadUnreadMessagesCountInterval(
        this.setUnreadMessagesCount,
      )
      if (unreadMessagesCount && Array.isArray(unreadMessagesCount)) {
        this.setState({ unreadMessagesCount })
      }
    })

    uiStore.updateTitle(`${capitalizeFirstLetter(view)} Page`)

    this.loadView()
  }
  componentDidUpdate() {
    const { uiStore } = this.props
    const { view } = this.state
    uiStore.updateTitle(`${capitalizeFirstLetter(view)} Page`)
  }
  componentWillUnmount() {
    const { adminStore } = this.props
    adminStore.stopLoadUnreadMessagesCountInterval()
  }
  closeModal = (modal) => {
    const { uiStore } = this.props
    const update = {}
    if (hasOwnProperty(this.state, modal)) {
      update[modal] = null
      this.setState(update)
      uiStore.scrollY(null, uiStore.scrollTop)
    }
  }
  loadView() {
    const { view } = this.state
    if (view === 'courses') {
      this.loadCourses()
    } else if (view === 'users') {
      this.loadUsers()
    }
  }
  loadCourses() {
    const { adminStore, history } = this.props
    const { filters, view } = this.state
    const { limit, offset, term } = filters
    const apiFilters = {
      offset,
      limit,
      term: term.trim(),
    }
    const query = qs.stringify(filters)
    history.push(`/admin/${view}?${query}`)
    return adminStore.loadCourses({ filters: apiFilters })
  }
  loadInstructors = (input) => {
    const { adminStore } = this.props
    const { withdrawUserModal } = this.state
    const { instance, session } = adminStore
    const { courseId } = withdrawUserModal || {}
    const { user: currentUser } = session
    const { id: currentUserId } = currentUser
    const excludeIds = [currentUserId]
    return instance.loadInstructors(input, excludeIds, false, courseId)
  }
  loadUsers() {
    const { adminStore, history } = this.props
    const { filters, view } = this.state
    const { limit, offset, term } = filters
    const apiFilters = {
      offset,
      limit,
      term: term.trim(),
    }
    const query = qs.stringify(filters)
    history.push(`/admin/${view}?${query}`)
    return adminStore.loadUsers({ filters: apiFilters })
  }
  onChangeLimit = (limit) => {
    const { filters } = this.state
    filters.limit = limit
    this.setState({ filters }, this.loadView)
  }
  onChangeNewInstanceModal = (field, value) => {
    const { newInstanceModal } = this.state
    const { values } = newInstanceModal
    if (hasOwnProperty(values, field)) {
      values[field] = value
      newInstanceModal['values'] = values
      this.setState({ newInstanceModal })
    }
  }
  onChangeNewUsersModal = (file) => {
    const { adminStore } = this.props
    const { newUsersModal } = this.state
    const { instance } = adminStore
    const values = new NewUsersModalDefaultValues(file)
    newUsersModal['error'] = null
    newUsersModal['values'] = values
    instance
      .uploadUsers(file)
      .then((url) => {
        values['url'] = url
        newUsersModal['error'] = null
        newUsersModal['values'] = values
        this.setState({ newUsersModal })
      })
      .catch((error) => {
        values['url'] = null
        if (error && error.message) {
          const errorJSON = JSON.parse(error)
          if (errorJSON) {
            newUsersModal['error'] = errorJSON
          } else {
            newUsersModal['error'] = {
              upload: ['There was an error while uploading bulk users.'],
            }
          }
        }
        this.setState({ newUsersModal })
      })
  }
  onChangeSearch = (event) => {
    const { target } = event
    const { value } = target
    const { filters } = this.state
    filters.offset = 0
    filters.term = value
    this.setState({ filters }, this.searchView)
  }
  onChangeView = (value) => {
    const { filters } = this.state
    filters.offset = 0
    filters.term = ''
    this.setState(
      {
        filters,
        view: value,
      },
      this.loadView,
    )
  }
  onChangeWithdrawUserModal = (field, value) => {
    const { withdrawUserModal } = this.state
    const { values } = withdrawUserModal
    if (hasOwnProperty(values, field)) {
      values[field] = value
      return this.setState({ withdrawUserModal })
    }
  }
  onClearSearch = () => {
    const { filters } = this.state
    filters.offset = 0
    filters.term = ''
    this.setState({ filters }, this.searchView)
  }
  onCopyInstance = () => {
    const { adminStore } = this.props
    const { newInstanceModal } = this.state
    const { values } = newInstanceModal
    const { courseId, courseName, integrationType, ssoEndpoint } = values
    adminStore
      .copyInstance(courseId, courseName, integrationType, ssoEndpoint)
      .then(() => {
        this.loadCourses()
        this.closeModal('newInstanceModal')
      })
      .catch((error) => {
        newInstanceModal['error'] =
          error && error.message
            ? JSON.parse(error.message)
            : { confirm: 'There was an error while copying the course.' }
        this.setState({ newInstanceModal })
      })
  }
  onCloseBulkUploadModal = () => {
    this.closeModal('newUsersModal')
    this.setState({ successfulBulkUpload: null })
  }
  onPageClick = (pageIndex) => {
    const { filters } = this.state
    const { limit } = filters
    filters.offset = pageIndex * limit
    this.setState({ filters }, this.loadView)
  }
  onSaveUsers = () => {
    const { adminStore } = this.props
    const {
      newUsersModal,
      // pagination,
      // search
    } = this.state
    const { instance } = adminStore
    const { values } = newUsersModal
    const { sendEmails, url } = values
    instance
      .saveUsers(url, sendEmails, true)
      .then((result) => {
        // TODO - Loading users crashes the app on instances page because {this.id} in instance.loadUsers is undefined.
        // instance.loadUsers({ ...pagination, term: search })
        this.setState({ successfulBulkUpload: result })
      })
      .catch((error) => {
        values['url'] = null
        if (error && error.message) {
          const errorJSON = JSON.parse(error.message)
          if (errorJSON) {
            newUsersModal['error'] = errorJSON
          } else {
            newUsersModal['error'] = {
              confirm: ['There was an error while saving bulk users.'],
            }
          }
        }
        this.setState({ newUsersModal })
      })
  }
  onWithdrawUser = () => {
    const { adminStore } = this.props
    const { withdrawUserModal } = this.state
    const { instance } = adminStore
    const { courseId, values } = withdrawUserModal
    const { dstInstructor, id: userId, roleId, roles } = values
    if (dstInstructor) {
      const { instructorId: dstInstructorId } = dstInstructor
      return instance
        .transferStudents(roleId, dstInstructorId, courseId)
        .then(() => instance.withdrawUser(userId, roles, courseId))
        .then(() => {
          this.loadUsers()
          this.closeModal('withdrawUserModal')
        })
        .catch((error) => {
          console.error('onWithdrawUserError transferStudents', error)
          if (error && error.message) {
            const errorJSON = JSON.parse(error.message)
            withdrawUserModal['error'] = errorJSON
            this.setState({ withdrawUserModal })
          }
        })
    }
    return instance
      .withdrawUser(userId, roles, courseId)
      .then(() => {
        this.loadUsers()
        this.closeModal('withdrawUserModal')
      })
      .catch((error) => {
        console.error('onWithdrawUserError', error)
        if (error && error.message) {
          const errorJSON = JSON.parse(error.message)
          withdrawUserModal['error'] = errorJSON
          this.setState({ withdrawUserModal })
        }
      })
  }
  openNewInstanceModal = (course) => {
    const { id: courseId, items } = course
    const [originalCourseName] = items
    this.setState({
      newInstanceModal: {
        error: null,
        values: new NewInstanceModalDefaultValues({
          courseId,
          originalCourseName,
        }),
      },
    })
  }
  openNewUsersModal = () => {
    this.setState({
      newUsersModal: {
        error: null,
        values: new NewUsersModalDefaultValues(),
      },
    })
  }
  openWithdrawUserModal = (user, roles, courseId, courseTitle) => {
    const { email, firstName, id, lastName, roleId } = user
    return this.setState({
      withdrawUserModal: {
        courseId: courseId,
        courseTitle: courseTitle,
        error: null,
        values: new WithdrawUserModalDefaultValues({
          email,
          firstName,
          id,
          lastName,
          roleId,
          roles,
        }),
      },
    })
  }
  searchView = _.debounce(this.loadView, 250, {
    leading: true,
    trailing: true,
  })
  setUnreadMessagesCount = (unreadMessagesCount) => {
    this.setState({ unreadMessagesCount })
  }
  renderEmptyState = () => {
    const { view } = this.state
    return (
      <div className="InstancesPage-emptyState">{`No ${
        view === 'courses' ? 'courses' : 'users'
      }`}</div>
    )
  }
  renderTable = () => {
    const { adminStore } = this.props
    const { filters, view } = this.state
    const {
      coursesAsTableData,
      coursesTotal,
      isLoadingCourses,
      isLoadingUsers,
      usersAsTableData,
      usersTotal,
    } = adminStore
    const { limit, offset, term } = filters
    const isCourses = view === 'courses'
    const headers = isCourses ? coursesTableHeaders : usersTableHeaders
    const data = isCourses ? coursesAsTableData : usersAsTableData
    const isLoading = isCourses ? isLoadingCourses : isLoadingUsers
    const total = isCourses ? coursesTotal : usersTotal
    const showingText = isCourses ? 'Courses' : 'Users'
    const searchLabel = `Search ${showingText}`
    const searchPlaceholder = `Search ${showingText}`
    return (
      <div id="main-content">
        <SortablePaginatedSearchTable
          className="InstancesPage__search-table"
          headers={headers}
          id={`InstancesPage${showingText}Table`}
          isLoading={isLoading}
          offset={parseInt(offset)}
          onChangeLimit={this.onChangeLimit}
          onChangeSearch={this.onChangeSearch}
          onClearSearch={this.onClearSearch}
          onPageClick={this.onPageClick}
          onSort={null} // TODO
          limit={parseInt(limit)}
          rows={data.map((d) => ({
            isDisabled: d.roleStatus === ROLE_STATUS.disabled,
            items: this.renderAdminTableRowItems(d, headers),
          }))}
          searchLabel={searchLabel}
          searchLabelPosition="before"
          searchPlaceholder={searchPlaceholder}
          searchTerm={term}
          showingText={showingText}
          sortBy={null} // TODO
          sortAscending={null} // TODO
          total={parseInt(total)}
        />
      </div>
    )
  }
  renderAdminTableRowItems = (d, headers) => {
    const rowItems = d.items.map((item, index) =>
      this.renderAdminTableItem({
        data: d,
        item: item,
        header: headers[index].headerText,
      }),
    )
    rowItems.push(
      <div className="InstancesPage-tableActions">{this.renderActions(d)}</div>,
    )
    return rowItems
  }
  renderAdminTableItem = ({ data, item, header }) => {
    const search = this.props.location.search

    const location = {
      pathname: `/admin/courses/${data.courseId || data.id}/overview`,
      state: qs.parse(search),
    }
    if (header === 'Course Title') {
      return data.liveStatus === 'Offline' ? (
        <span>{item}</span>
      ) : (
        <Link to={location}>{item}</Link>
      )
    }
    return <div>{item}</div>
  }
  renderActions = (data) => {
    const { sessionStore } = this.props
    const { session } = sessionStore
    const { user } = session
    const { isAdmin } = user
    const { view } = this.state
    if (view === 'courses') {
      const { isAdmin } = data
      return isAdmin
        ? this.renderAdminTableActions(data)
        : this.renderInstructorTableActions(data)
    } else if (view === 'users' && isAdmin) {
      const {
        courseId,
        courseTitle,
        email,
        firstName,
        id,
        lastName,
        role,
        roleId,
      } = data
      return (
        <div
          className="InstancesPage-usersActions buttons is-right"
          style={{ flexWrap: 'nowrap' }}
        >
          <Button
            disabled={data.roleStatus === ROLE_STATUS.disabled}
            isDanger
            onClick={() =>
              this.openWithdrawUserModal(
                {
                  email,
                  firstName,
                  id,
                  lastName,
                  roleId,
                },
                [role],
                courseId,
                courseTitle,
              )
            }
          >
            <span>
              <i className="fa fa-close" />
            </span>
          </Button>
        </div>
      )
    } else return null
  }
  renderAdminTableActions = (course) => {
    const { liveStatus } = course || {}
    const isOffline = liveStatus === 'Offline'
    return (
      <div className="buttons is-right" style={{ flexWrap: 'nowrap' }}>
        <Button
          className="is-copy"
          disabled={isOffline}
          isInfo
          isSmall
          isTight
          onClick={() => this.openNewInstanceModal(course)}
        >
          Copy
        </Button>
        {isOffline ? (
          <Button className="is-orange" disabled isSmall isPrimary isTight>
            Users
          </Button>
        ) : (
          <Button
            className="is-orange"
            isSmall
            isPrimary
            isTight
            nav
            to={`${this.props.match.url}/${course.id}/users`}
          >
            Users
          </Button>
        )}
        {this.renderMessagesButton(course)}
      </div>
    )
  }
  renderTabs = () => {
    return (
      <Tabs isAlt>
        <Link
          nav
          to="/admin/courses"
          onClick={() => this.onChangeView('courses')}
        >
          Courses
        </Link>
        <Link nav to="/admin/users" onClick={() => this.onChangeView('users')}>
          Users
        </Link>
      </Tabs>
    )
  }
  renderHeader = () => {
    const { sessionStore } = this.props
    const { session } = sessionStore
    const { user } = session
    const { isAdmin } = user
    return (
      <div className="InstancesPage-header">
        {isAdmin ? (
          <div className="InstancesPage-bulkUploadButton">
            <Button
              text="Add Bulk Users"
              isSuccess
              onClick={this.openNewUsersModal}
            />
          </div>
        ) : null}
      </div>
    )
  }
  renderInstructorTableActions = (course) => {
    const { liveStatus } = course || {}
    const isOffline = liveStatus === 'Offline'
    return (
      <div className="buttons is-right" style={{ flexWrap: 'nowrap' }}>
        {isOffline ? (
          <Button disabled={isOffline} isSmall isPrimary isTight nav to={null}>
            Overview
          </Button>
        ) : (
          <Button
            disabled={isOffline}
            isSmall
            isPrimary
            isTight
            nav
            to={`${this.props.match.url}/${course.id}/overview`}
          >
            Overview
          </Button>
        )}
        {isOffline ? (
          <Button className="is-orange" disabled isSmall isPrimary isTight>
            Analytics
          </Button>
        ) : (
          <Button
            className="is-orange"
            isSmall
            isPrimary
            isTight
            nav
            to={`${this.props.match.url}/${course.id}/analytics`}
          >
            Analytics
          </Button>
        )}
        {this.renderMessagesButton(course)}
      </div>
    )
  }
  renderMessagesButton = (course) => {
    const { unreadMessagesCount } = this.state
    const { isDisabledMessaging, liveStatus } = course || {}
    const hasUnread =
      unreadMessagesCount && Array.isArray(unreadMessagesCount)
        ? unreadMessagesCount.filter(
            (unread) => unread.courseId === course.id,
          )[0]
        : false
    const unreadCount =
      hasUnread && hasUnread.unreadCount ? hasUnread.unreadCount : 0
    return liveStatus === 'Offline' ? (
      <Button
        className="InstancesPage-messagesButton"
        disabled
        isSmall
        isSuccess
        isTight
      >
        <span>Messages</span>
      </Button>
    ) : (
      <Button
        className="InstancesPage-messagesButton"
        disabled={isDisabledMessaging}
        isSmall
        isSuccess
        isTight
        nav
        to={
          !isDisabledMessaging
            ? `${this.props.match.url}/${course.id}/messages`
            : null
        }
      >
        <span>Messages</span>
        {unreadCount && !isDisabledMessaging ? (
          <span className="InstancesPage-messagesButtonUnreadCount">
            {unreadCount > 9 ? '9+' : unreadCount}
          </span>
        ) : null}
      </Button>
    )
  }
  renderPage = () => {
    const { adminStore } = this.props
    const { view } = this.state
    const {
      coursesAsTableData,
      errorLoadingCourses,
      isLoadingCourses,
      isLoadingUsers,
      usersAsTableData,
    } = adminStore
    const isLoading =
      (view === 'courses' && isLoadingCourses) ||
      (view === 'users' && isLoadingUsers)
    const hasData =
      (view === 'courses' &&
        coursesAsTableData &&
        coursesAsTableData.length > 0) ||
      (view === 'users' && usersAsTableData && usersAsTableData.length > 0)
    if (errorLoadingCourses) {
      return (
        <div className="InstancesPage-loadCoursesError">
          {errorLoadingCourses}
        </div>
      )
    }
    return (
      <Fragment>
        {this.renderTable()}
        {!isLoading && !hasData ? this.renderEmptyState() : null}
      </Fragment>
    )
  }
  render() {
    const { adminStore } = this.props
    const {
      newInstanceModal,
      newUsersModal,
      successfulBulkUpload,
      withdrawUserModal,
    } = this.state
    const { instance, isCopyingInstance } = adminStore
    const {
      isLoadingInstructors,
      isSavingUsers,
      isTransferringStudents,
      isUploadingUsers,
      isWithdrawingUser,
    } = instance
    return (
      <Fragment>
        <AdminPage className="InstancesPage" renderHeader={this.renderTabs}>
          <AdminLayout.Box>
            {this.renderHeader()}
            {this.renderPage()}
          </AdminLayout.Box>
          <NewInstanceModal
            error={
              newInstanceModal && newInstanceModal.error
                ? newInstanceModal.error
                : null
            }
            isLoading={isCopyingInstance}
            isOpen={!!newInstanceModal}
            onChange={this.onChangeNewInstanceModal}
            onClose={() => this.closeModal('newInstanceModal')}
            onConfirm={this.onCopyInstance}
            values={
              newInstanceModal && newInstanceModal.values
                ? newInstanceModal.values
                : null
            }
          />
          <NewUsersModal
            error={
              newUsersModal && newUsersModal.error ? newUsersModal.error : null
            }
            isLoading={isSavingUsers}
            isMultiCourse
            isOpen={!!newUsersModal}
            isUploading={isUploadingUsers}
            onChange={this.onChangeNewUsersModal}
            onClose={this.onCloseBulkUploadModal}
            onConfirm={this.onSaveUsers}
            successfulBulkUpload={successfulBulkUpload}
            values={
              newUsersModal && newUsersModal.values
                ? newUsersModal.values
                : null
            }
          />
          <WithdrawUserModal
            courseTitle={
              withdrawUserModal ? withdrawUserModal.courseTitle : null
            }
            error={withdrawUserModal ? withdrawUserModal.error : null}
            isLoading={isTransferringStudents || isWithdrawingUser}
            isLoadingInstructors={isLoadingInstructors}
            isOpen={!!withdrawUserModal}
            isTransfer={
              withdrawUserModal &&
              withdrawUserModal.error &&
              withdrawUserModal.error['transfer']
            }
            loadInstructors={this.loadInstructors}
            onChange={this.onChangeWithdrawUserModal}
            onClose={() => this.closeModal('withdrawUserModal')}
            onConfirm={this.onWithdrawUser}
            title={
              withdrawUserModal
                ? `Withdraw ${withdrawUserModal.values.firstName} ${withdrawUserModal.values.lastName}`
                : null
            }
            values={withdrawUserModal ? withdrawUserModal.values : null}
          />
        </AdminPage>
      </Fragment>
    )
  }
}
