import React, { Component } from 'react'
import './CertificateIcon.scss'
// Note: Converted HTML to JSX
// Removed xmlns:xlink
// https://github.com/reactjs/react-magic/issues/62
class CertificateIcon extends Component {
  render() {
    return (
      <div className="CertificateIcon">
        <svg
          aria-hidden="true"
          focusable="false"
          width="240px"
          height="136px"
          viewBox="0 0 240 135"
          version="1.1"
        >
          <g
            id="Certificate-"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g id="Certificate" transform="translate(-1.000000, -1.000000)">
              <g id="diploma" transform="translate(0.216216, 0.009009)">
                <rect
                  id="Rectangle"
                  fill="#FFFFFF"
                  x="0.783783784"
                  y="0.990990991"
                  width={240}
                  height={135}
                />
                <path
                  d="M182.711113,112.828829 L58.4960938,112.828829 C55.4602446,112.828829 53,110.384563 53,107.36843 L53,25.4603988 C53,22.4442663 55.4602446,20 58.4960938,20 L182.711113,20 C185.746963,20 188.207207,22.4442663 188.207207,25.4603988 L188.207207,107.36843 C188.207207,110.384563 185.746963,112.828829 182.711113,112.828829 Z"
                  id="Path"
                  fill="#445EA1"
                  fillRule="nonzero"
                />
                <path
                  d="M183.531504,20 L183.531504,105.725545 C183.531504,107.234198 182.299861,108.457735 180.781212,108.457735 L53,108.457735 C53.5100015,110.951987 55.7286119,112.828829 58.3901181,112.828829 L182.706623,112.828829 C185.744953,112.828829 188.207207,110.38278 188.207207,107.364448 L188.207207,25.3966911 C188.207207,22.6583472 186.177525,20.3969061 183.531504,20 Z"
                  id="Path"
                  fill="#2E4D89"
                  fillRule="nonzero"
                />
                <path
                  d="M178.117117,41.6333696 L178.117117,92.2044682 C171.76385,92.2044682 166.614414,97.3710376 166.614414,103.747748 L74.5927927,103.747748 C74.5927927,97.3710376 69.4433569,92.2044682 63.0900901,92.2044682 L63.0900901,41.6333696 C69.4433569,41.6333696 74.5927927,36.4657687 74.5927927,30.0900901 L166.614414,30.0900901 C166.614414,36.4657687 171.76385,41.6333696 178.117117,41.6333696 L178.117117,41.6333696 Z"
                  id="Path"
                  fill="#E5F1FE"
                  fillRule="nonzero"
                />
                <path
                  d="M92.3513514,81.5490234 C92.3513514,84.3358703 90.0923916,86.5945946 87.3063063,86.5945946 C84.520221,86.5945946 82.2612613,84.3358703 82.2612613,81.5490234 C82.2612613,78.7632285 84.520221,76.5045045 87.3063063,76.5045045 C90.0923916,76.5045045 92.3513514,78.7632285 92.3513514,81.5490234 L92.3513514,81.5490234 Z"
                  id="Path"
                  fill="#FFD10D"
                  fillRule="nonzero"
                />
                <g
                  id="Group"
                  transform="translate(82.261261, 44.216216)"
                  fill="#B5D4EA"
                  fillRule="nonzero"
                >
                  <path
                    d="M38.6070605,4.15033784 L2.07558126,4.15033784 C0.929752227,4.15033784 2.4158453e-13,3.22180867 2.4158453e-13,2.07516879 C2.4158453e-13,0.929567725 0.929752227,2.22044605e-15 2.07558126,2.22044605e-15 L38.6070605,2.22044605e-15 C39.7528896,2.22044605e-15 40.6826418,0.929567725 40.6826418,2.07516879 C40.6826418,3.22180867 39.7528896,4.15033784 38.6070605,4.15033784 Z"
                    id="Path"
                  />
                  <path
                    d="M74.356245,15.2504755 L2.32843972,15.2504755 C1.04301964,15.2504755 0,14.3209078 0,13.1742679 C0,12.0286668 1.04185433,11.0990991 2.32843972,11.0990991 L74.356245,11.0990991 C75.6428304,11.0990991 76.6846847,12.0286668 76.6846847,13.1742679 C76.6846847,14.3209078 75.6428304,15.2504755 74.356245,15.2504755 Z"
                    id="Path"
                  />
                  <path
                    d="M74.356245,26.348536 L2.32843972,26.348536 C1.04301964,26.348536 0,25.4189683 0,24.2733673 C0,23.1267274 1.04185433,22.1981982 2.32843972,22.1981982 L74.356245,22.1981982 C75.6428304,22.1981982 76.6846847,23.1267274 76.6846847,24.2733673 C76.6846847,25.4189683 75.6428304,26.348536 74.356245,26.348536 Z"
                    id="Path"
                  />
                  <path
                    d="M26.1640827,39.4666917 L16.2017074,39.4666917 C15.0558784,39.4666917 14.1261261,38.537124 14.1261261,37.3904841 C14.1261261,36.2448828 15.0548396,35.3153153 16.2017074,35.3153153 L26.1640827,35.3153153 C27.3109505,35.3153153 28.240703,36.2448828 28.240703,37.3904841 C28.240703,38.537124 27.3109505,39.4666917 26.1640827,39.4666917 Z"
                    id="Path"
                  />
                </g>
                <g
                  id="Group-4"
                  transform="translate(134.729730, 78.522523)"
                  fillRule="nonzero"
                >
                  <path
                    d="M26.2342342,46.2594538 L32.0363066,50.2423357 C33.1196733,50.9858761 34.3063063,49.6454233 34.3063063,47.6768606 L34.3063063,28.2522523 L18.1621622,28.2522523 L18.1621622,47.6768606 C18.1621622,49.6454233 19.3487951,50.9858761 20.4321619,50.2423357 L26.2342342,46.2594538 Z"
                    id="Path-Copy"
                    fill="#DC2639"
                    transform="translate(26.234234, 39.351351) scale(-1, 1) rotate(13.000000) translate(-26.234234, -39.351351) "
                  />
                  <path
                    d="M11.0990991,46.2594538 L16.9011714,50.2423357 C17.9845382,50.9858761 19.1711712,49.6454233 19.1711712,47.6768606 L19.1711712,28.2522523 L3.02702703,28.2522523 L3.02702703,47.6768606 C3.02702703,49.6454233 4.21365999,50.9858761 5.29702678,50.2423357 L11.0990991,46.2594538 Z"
                    id="Path"
                    fill="#FE3845"
                    transform="translate(11.099099, 39.351351) rotate(13.000000) translate(-11.099099, -39.351351) "
                  />
                  <path
                    d="M36.3243243,17.6576578 C36.3243243,27.4102772 28.4192862,35.3153153 18.6666667,35.3153153 C8.91404718,35.3153153 1.00900901,27.4102772 1.00900901,17.6576578 C1.00900901,7.90607395 8.91404718,0 18.6666667,0 C28.4192862,0 36.3243243,7.90607395 36.3243243,17.6576578 Z"
                    id="Path"
                    fill="#FFD10D"
                  />
                  <path
                    d="M18.6666667,7.06306306 C12.8247001,7.06306306 8.07207207,11.8156911 8.07207207,17.6576577 C8.07207207,23.4996242 12.8247001,28.2522523 18.6666667,28.2522523 C24.5086333,28.2522523 29.2612613,23.4996242 29.2612613,17.6576577 C29.2612613,11.8156911 24.5086333,7.06306306 18.6666667,7.06306306 Z M18.6666667,24.2289621 C15.0428008,24.2289621 12.095362,21.2804861 12.095362,17.6576577 C12.095362,14.0337915 15.0428008,11.086353 18.6666667,11.086353 C22.2905325,11.086353 25.2379714,14.0337915 25.2379714,17.6576577 C25.2379714,21.2804861 22.2905325,24.2289621 18.6666667,24.2289621 Z"
                    id="Shape"
                    fill="#F38D17"
                  />
                  <path
                    d="M29.4113538,4.03603604 C31.6163618,6.95121812 32.92651,10.5826958 32.92651,14.5202832 C32.92651,24.128037 25.1370462,31.9175011 15.5292923,31.9175011 C11.5917049,31.9175011 7.96022689,30.6073528 5.04504505,28.4023446 C8.22144025,32.6011453 13.2559195,35.3153153 18.9271065,35.3153153 C28.5348604,35.3153153 36.3243243,27.5258515 36.3243243,17.9180977 C36.3243243,12.247931 33.6101545,7.21243148 29.4113538,4.03603604 L29.4113538,4.03603604 Z"
                    id="Path"
                    fill="#FFAF10"
                  />
                </g>
              </g>
              <path
                d="M208.099265,78.5315315 C207.673157,78.5315315 207.294394,78.2597097 207.158318,77.8561156 C205.252585,72.2097706 203.826265,70.783451 198.180252,68.8780496 C197.776326,68.7416421 197.504505,68.3628794 197.504505,67.9367714 C197.504505,67.5106634 197.776326,67.1319007 198.17992,66.9958243 C203.825934,65.0904229 205.252254,63.6637722 207.157986,58.0177583 C207.294394,57.6141642 207.672826,57.3423423 208.098934,57.3423423 C208.525042,57.3423423 208.903804,57.6141642 209.039881,58.0177583 C210.945613,63.6637722 212.371933,65.0904229 218.017947,66.9958243 C218.421541,67.1322318 218.693694,67.5106634 218.693694,67.9367714 C218.693694,68.3628794 218.421872,68.7416421 218.017947,68.8780496 C212.371933,70.783451 210.945613,72.2097706 209.039881,77.8561156 C208.904135,78.2597097 208.525373,78.5315315 208.099265,78.5315315"
                id="Fill-1"
                fill="#FFD00D"
              />
              <path
                d="M47.6668322,40.1891892 C47.2407242,40.1891892 46.8619615,39.9173674 46.7258851,39.5137732 C44.8201526,33.8674282 43.393833,32.4411087 37.7478191,30.5357073 C37.3438939,30.3992997 37.0720721,30.0205371 37.0720721,29.5944291 C37.0720721,29.168321 37.3438939,28.7895584 37.747488,28.6534819 C43.3935019,26.7480805 44.8198215,25.3214299 46.725554,19.675416 C46.8619615,19.2718218 47.2403931,19 47.6665011,19 C48.0926091,19 48.4713718,19.2718218 48.6074483,19.675416 C50.5131807,25.3214299 51.9395003,26.7480805 57.5855142,28.6534819 C57.9891084,28.7898895 58.2612613,29.168321 58.2612613,29.5944291 C58.2612613,30.0205371 57.9894394,30.3992997 57.5855142,30.5357073 C51.9395003,32.4411087 50.5131807,33.8674282 48.6074483,39.5137732 C48.4717029,39.9173674 48.0929402,40.1891892 47.6668322,40.1891892"
                id="Fill-1"
                fill="#F9C646"
              />
              <path
                d="M34.5164646,68.0554895 C33.4274746,64.8290066 32.6124348,64.0139669 29.3861412,62.9251661 C29.1553268,62.8472189 29,62.6307831 29,62.3872928 C29,62.1438025 29.1553268,61.9273667 29.385952,61.8496087 C32.6122456,60.7608079 33.4272854,59.945579 34.5162754,56.7192853 C34.5942225,56.4886601 34.8104692,56.3333333 35.0539595,56.3333333 C35.2974497,56.3333333 35.5138856,56.4886601 35.5916435,56.7192853 C36.6806335,59.945579 37.4956733,60.7608079 40.7219669,61.8496087 C40.9525922,61.9275559 41.1081081,62.1438025 41.1081081,62.3872928 C41.1081081,62.6307831 40.9527814,62.8472189 40.7219669,62.9251661 C37.4956733,64.0139669 36.6806335,64.8290066 35.5916435,68.0554895 C35.5140748,68.2861147 35.2976389,68.4414414 35.0541487,68.4414414 C34.8106584,68.4414414 34.5942225,68.2861147 34.5164646,68.0554895 Z"
                id="Fill-1"
                fill="#FFD00D"
              />
            </g>
          </g>
        </svg>
      </div>
    )
  }
}
export default CertificateIcon
