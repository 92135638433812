import { extendObservable, action } from 'mobx'
import humps from 'lodash-humps'
import _ from 'lodash'
import qs from 'query-string'
import SentryReplay from '@/SentryReplay'
import request from '@/utils/request'

import Report from './models/report'
import Learner from './models/learner'

import { ACTIVITY_PERFORMANCE_MINIMUM, EMPTY_VALUE } from '@/constants'

const initialState = {
  session: null,
  report: null,
  learner: null,
  isLoadingCourse: true,
  isLoadingExport: false,
  isLoadingUnits: false,
  isLoadingUnit: false,
  isLoadingLearners: false,
  isLoadingLearner: false,
  isNotFoundLearner: false,
  loadingError: null,
  updateError: null,
}

export default class ReportingStore {
  constructor(appStore) {
    this.appStore = appStore
    extendObservable(this, { ...initialState })
  }

  // reset state as stores are singletons of global state
  // make sure to call this method from sessionStore reset
  @action reset() {
    extendObservable(this, { ...initialState })
  }

  @action init(session) {
    this.session = session
    this.courseId = this.session.internalCourseId
  }

  // This will only get called after
  // settings have loaded in StudentLayout on componentDidMount
  fetchCourse() {
    const { courseId } = this.session
    const { contentStore } = this.appStore || {}
    const { settings } = contentStore || {}
    const { isDisabledDashboard } = settings || {}
    if (this.report) {
      return Promise.resolve(this.report)
    } else if (isDisabledDashboard) {
      return Promise.resolve({})
    }
    return request.get(`/activities/${courseId}/averages`, {
      params: {
        d: Date.now(),
      },
    })
  }

  @action createLearner(data) {
    this.learner = new Learner(data)
  }

  fetchLearners(filters) {
    const cleanedFilters = _.omitBy(
      filters,
      (v) => _.isEmpty(String(v)) || v === EMPTY_VALUE,
    )
    const queries = qs.stringify({ d: Date.now(), ...cleanedFilters })
    return request.get(`/reporting/${this.courseId}/learners?${queries}`)
  }

  @action loadCourse({ withLearners, learnersFilters } = {}) {
    this.isLoadingCourse = true
    this.loadingError = null
    return Promise.all([
      this.fetchCourse(),
      withLearners && this.fetchLearners(learnersFilters),
    ])
      .then(([reportResponse, learnersResponse, unitsResponse]) => {
        const courseData = humps(reportResponse.data)
        const learnersData = learnersResponse && humps(learnersResponse.data)
        const unitsData = unitsResponse && humps(unitsResponse.data.units)
        this.handleLoadCourse({ courseData, learnersData, unitsData })
      })
      .catch((response) => this.handleLoadCourseError(response))
  }
  @action handleLoadCourse({ courseData, learnersData, unitsData }) {
    this.isLoadingCourse = false
    let { learners, ...learnersMeta } = learnersData || {}
    learners = learners && learners.map((learner) => new Learner(learner))
    let meta = null
    if (!_.isEmpty(learnersMeta)) {
      meta =
        learnersMeta.count > 0
          ? learnersMeta
          : { ...learnersMeta, filters: this.report.learnersMeta.filters }
    }

    if (!this.report) {
      this.report = new Report({
        ...courseData,
        learners,
        learnersMeta: meta,
        units: unitsData,
      })
    }
    if (learnersData) {
      this.report.learners = learners
      this.report.learnersMeta = meta
    }
    if (unitsData) {
      this.report.units = unitsData
    }
  }
  @action handleLoadCourseError(error) {
    SentryReplay.captureException(error)
    this.loadingError = 'Failed to load course report'
    this.isLoadingCourse = false
  }

  @action loadLearners(filters) {
    this.isLoadingLearners = true
    this.loadingError = null

    return this.fetchLearners(filters)
      .then((response) => this.handleLoadLearners(response))
      .catch((response) => this.handleLoadLearnersError(response))
  }
  @action handleLoadLearners(response) {
    this.isLoadingLearners = false
    let { learners, ...learnersMeta } = humps(response.data)

    const meta =
      learnersMeta.count > 0
        ? learnersMeta
        : { ...learnersMeta, filters: this.report.learnersMeta.filters }

    learners = learners && learners.map((learner) => new Learner(learner))
    this.report.learners = learners
    this.report.learnersMeta = meta
    return this.report
  }
  @action handleLoadLearnersError(error) {
    SentryReplay.captureException(error)
    this.loadingError = 'Failed to load course learners'
    this.isLoadingLearners = false
  }

  @action loadLearner(learnerId) {
    const { courseId } = this.session
    this.isLoadingLearner = true
    this.loadingError = null
    return request
      .get(`/activities/${courseId}/performance`, {
        params: {
          d: Date.now(),
        },
      })
      .then((response) => this.handleLoadLearner(response, Number(learnerId)))
      .catch((response) => this.handleLoadLearnerError(response))
  }
  @action handleLoadLearner(response, learnerId) {
    this.isLoadingLearner = false
    this.isNotFoundLearner = false
    const data = humps(response.data)
    const learner = { id: learnerId, ...data }
    const learners = (this.report && this.report.learners) || []
    const { id, firstName, lastName, rank, ...meta } =
      _.find(learners, { id: learnerId }) || {}
    this.learner = new Learner({
      id,
      firstName,
      lastName,
      rank,
      meta,
      averages: this.report && this.report.averages,
      ...learner,
    })
  }
  @action handleLoadLearnerError(error) {
    const { response } = error || {}
    const { status } = response || {}
    if (!status || status !== 404) {
      SentryReplay.captureException(error)
    }
    if (response) {
      if (status === 404) {
        this.isNotFoundLearner = true
      }
    } else {
      this.loadingError = 'Failed to load learner'
    }
    this.isLoadingLearner = false
  }

  @action loadExport() {
    if (this.report.downloadUrls) {
      return Promise.resolve(this.report.firstDownloadUrl)
    }

    this.isLoadingExport = true
    this.loadingError = null

    return request
      .get(`/reporting/${this.courseId}/export?d=${Date.now()}`)
      .then((response) => this.handleLoadExport(response))
      .catch((response) => this.handleLoadExportError(response))
  }
  @action handleLoadExport(response) {
    this.isLoadingExport = false
    this.report.downloadUrls = response.data.download_urls
    return this.report.firstDownloadUrl
  }
  @action handleLoadExportError(error) {
    SentryReplay.captureException(error)
    this.loadingError = 'Failed to generate export'
    this.isLoadingExport = false
  }

  @action refreshReport() {
    this.updateError = null
    return request
      .put(`/content/${this.courseId}/update-report`)
      .then((response) => this.handleRefreshReport(response))
      .catch((response) => this.handleRefreshReportError(response))
  }
  @action handleRefreshReport() {}
  @action handleRefreshReportError(error) {
    SentryReplay.captureException(error)
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      this.updateError = error.response.data.message
    }
  }

  get activityPerformanceAverage() {
    const { averages } = this.learner || {}
    const { firstAttempt } = averages || {}
    if (!this.learner || !averages || !firstAttempt) return 0
    return firstAttempt
  }
  get activityPerformanceMinimum() {
    if (!this.learner) {
      return ACTIVITY_PERFORMANCE_MINIMUM
    }
    const { averages } = this.learner
    if (!averages) {
      return ACTIVITY_PERFORMANCE_MINIMUM
    }
    const { firstAttempt } = averages
    if (!firstAttempt && firstAttempt !== 0) {
      return ACTIVITY_PERFORMANCE_MINIMUM
    }
    if (firstAttempt < ACTIVITY_PERFORMANCE_MINIMUM) {
      return firstAttempt
    }
    return firstAttempt < ACTIVITY_PERFORMANCE_MINIMUM
      ? firstAttempt
      : ACTIVITY_PERFORMANCE_MINIMUM
  }
  get activityPerformanceTheme() {
    if (!this.learner) {
      return null
    }
    const { firstAttempt } = this.learner
    if (!firstAttempt && firstAttempt !== 0) {
      return null
    }
    const performanceAtRisk = this.activityPerformanceMinimum * 0.8
    const performanceGood = this.activityPerformanceMinimum * 1.2
    if (firstAttempt < performanceAtRisk) {
      return 'at-risk'
    } else if (firstAttempt < performanceGood) {
      return 'good'
    } else {
      return 'all-star'
    }
  }
}
