import React from 'react'
import cx from 'classnames'

import { Button, Heading, HoverTooltip, Link, PieChart } from '@/components'

const InstanceOverviewPageLearnerCard = ({
  cardHeading,
  cardInfo,
  cardLink,
  data,
  hasPlaceholder,
}) => {
  return (
    <div className="InstanceOverviewPage__learner-card">
      <Heading is2>
        {cardHeading}
        <span>
          <HoverTooltip
            id="InstanceOverviewPage__learner-card-tooltip--is-learner-performance"
            text={cardInfo}
            trigger={['focus', 'hover']}
          >
            <Button
              aria-describedby="InstanceOverviewPage__learner-card-tooltip--is-learner-performance"
              isText
            >
              <i className="fa fa-info-circle" />
            </Button>
          </HoverTooltip>
        </span>
      </Heading>
      <p className="InstanceOverviewPage__learner-card-mobile">{cardInfo}</p>
      <hr />
      <div>
        <PieChart data={data.filter((n) => n.value > 0)} />
        <ul>
          {data.map((d, i) => {
            const { className, label, to, value } = d
            return (
              <li key={`InstanceOverviewPage__card-row-${i}`}>
                <div className="InstanceOverviewPage__card-row">
                  <span
                    className={cx(
                      'InstanceOverviewPage__card-count',
                      className,
                    )}
                  >
                    {value}
                  </span>
                  <Link to={to}>{label}</Link>
                  &nbsp;
                  <i aria-hidden="true" className="fa fa-angle-right" />
                </div>
                <hr />
              </li>
            )
          })}
          {hasPlaceholder && (
            <li className="is-tablet">
              <div className="InstanceOverviewPage__card-row">&nbsp;</div>
              <hr />
            </li>
          )}
          <li>
            <div className="InstanceOverviewPage-digDeeper">
              <Link className="InstanceOverviewPage__card-link" to={cardLink}>
                Dig Deeper&nbsp;
                <i aria-hidden="true" className="fa fa-angle-right" />
                <i aria-hidden="true" className="fa fa-angle-right" />
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default InstanceOverviewPageLearnerCard
