import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router'
import cx from 'classnames'
import {
  BookAndTrophyIcon,
  Button,
  CertificateIcon,
  Heading,
  Link,
  Loader,
} from '@/components'
import './ExamBanner.scss'

const ExamBannerMessages = defineMessages({
  bodyPassed: {
    defaultMessage:
      'You can download your proof of certification below. Please don’t forget to take the Course Survey, if you have not already.',
    description: 'Exam Banner Body - Passed state',
    id: 'ExamBanner.bodyPassed',
  },
  bodyPassedCPE: {
    defaultMessage:
      "You are eligible to receive {nasbaCpeCredits} CPE credit for this course. You may also download your certification below for your own records. Please don't forget to take the Course Survey, if you have not already.",
    description: 'Exam Banner Body - Passed state with CPE credits call out',
    id: 'ExamBanner.bodyPassedCPE',
  },
  bodyPassedCPENoCertificate: {
    defaultMessage:
      "You are eligible to receive {nasbaCpeCredits} CPE credit for this course. Please don't forget to take the Course Survey, if you have not already.",
    description:
      'Exam Banner Body - Passed state with CPE credits call out and no certificate',
    id: 'ExamBanner.bodyPassedCPENoCertificate',
  },
  bodyPassedCPENoCertificateAndSurvey: {
    defaultMessage:
      'You are eligible to receive {nasbaCpeCredits} CPE credit for this course.',
    description:
      'Exam Banner Body - Passed state with CPE credits call out and no certificate and survey',
    id: 'ExamBanner.bodyPassedCPENoCertificateAndSurvey',
  },
  bodyPassedCPENoSurvey: {
    defaultMessage:
      'You are eligible to receive {nasbaCpeCredits} CPE credit for this course. You may also download your certification below for your own records.',
    description:
      'Exam Banner Body - Passed state with CPE credits call out and no survey',
    id: 'ExamBanner.bodyPassedCPENoSurvey',
  },
  bodyPassedNoCertificate: {
    defaultMessage:
      'Please don’t forget to take the Course Survey, if you have not already.',
    description: 'Exam Banner Body - Passed state with no certificate',
    id: 'ExamBanner.bodyPassedNoCertificate',
  },
  // NOTE: uncomment if there is specific copy required when the Exam
  // has no certificate or survey
  // bodyPassedNoCertificateAndSurvey: {
  //   defaultMessage: '',
  //   description: 'Exam Banner Body - Passed state with no certificate and no survey',
  //   id: 'ExamBanner.bodyPassedNoCertificateAndSurvey'
  // },
  bodyPassedNoSurvey: {
    defaultMessage: 'You can download your proof of certification below.',
    description: 'Exam Banner Body - Passed state with no survey',
    id: 'ExamBanner.bodyPassedNoSurvey',
  },
  bodyReady: {
    defaultMessage:
      'You will need to take the Post Exam and score a minimum of {scoreRequired}% or higher. When finished, please take the Course Survey to leave your feedback.',
    description: 'Exam Banner Body - Ready state',
    id: 'ExamBanner.bodyReady',
  },
  bodyReadyCPE: {
    defaultMessage:
      'In order to receive CPE credits for the course, you will need to take the Post Exam and score a minimum of {scoreRequired}% or higher. When finished, please take the Course Survey to leave your feedback.',
    description: 'Exam Banner Body - Ready state with CPE credits call out',
    id: 'ExamBanner.bodyReadyCPE',
  },
  bodyReadyCPENoSurvey: {
    defaultMessage:
      'In order to receive CPE credits for the course, you will need to take the Post Exam and score a minimum of {scoreRequired}% or higher. When finished, please take the Course Survey to leave your feedback.',
    description:
      'Exam Banner Body - Ready state with CPE credits call out and no survey',
    id: 'ExamBanner.bodyReadyCPENoSurvey',
  },
  bodyReadyNoSurvey: {
    defaultMessage:
      'You will need to take the Post Exam and score a minimum of {scoreRequired}% or higher.',
    description: 'Exam Banner Body - Ready state with no survey',
    id: 'ExamBanner.bodyReadyNoSurvey',
  },
  bodyReattempt: {
    defaultMessage: `You have not passed your Post Exam. You will need to retake your Post Exam and score a minimum of {scoreRequired}% or higher for a passing score. When finished, please take the Course Survey to leave your feedback.`,
    description: 'Exam Banner Body - Reattempt state',
    id: 'ExamBanner.bodyReattempt',
  },
  bodyReattemptCPE: {
    defaultMessage: `You have not passed your Post Exam. In order to receive CPE credits for this course, you will need to retake your Post Exam and score a minimum of {scoreRequired}% or higher. When finished, please take the Course Survey to leave your feedback.`,
    description: 'Exam Banner Body - Reattempt state with CPE credits call out',
    id: 'ExamBanner.bodyReattemptCPE',
  },
  bodyReattemptCPENoSurvey: {
    defaultMessage: `You have not passed your Post Exam. In order to receive CPE credits for this course, you will need to retake your Post Exam and score a minimum of {scoreRequired}% or higher.`,
    description:
      'Exam Banner Body - Reattempt state with CPE credits call out and no survey',
    id: 'ExamBanner.bodyReattemptCPENoSurvey',
  },
  bodyReattemptNoSurvey: {
    defaultMessage: `You have not passed your Post Exam. You will need to retake your Post Exam and score a minimum of {scoreRequired}% or higher for a passing score.`,
    description: 'Exam Banner Body - Reattempt state with no survey',
    id: 'ExamBanner.bodyReattemptNoSurvey',
  },
  buttonBeginExam: {
    defaultMessage: 'Take Post Exam',
    description: 'Exam Banner Button — Begin Exam',
    id: 'ExamBanner.buttonBeginExam',
  },
  buttonContinueExam: {
    defaultMessage: 'Continue Post Exam',
    description: 'Exam Banner Button — Continue Exam',
    id: 'ExamBanner.buttonContinueExam',
  },
  buttonDownloadCertificate: {
    defaultMessage: 'Download Certificate',
    description: 'Exam Banner Button — Continue Exam',
    id: 'ExamBanner.buttonDownloadCertificate',
  },
  buttonReattemptExam: {
    defaultMessage: 'Retake Post Exam',
    description: 'Exam Banner Button — Reattempt Exam',
    id: 'ExamBanner.buttonReattemptExam',
  },
  buttonSurvey: {
    defaultMessage: 'Take Course Survey',
    description: 'Exam Banner Button — Take survey',
    id: 'ExamBanner.buttonSurvey',
  },
  headingReady: {
    defaultMessage:
      'Now it is time to take and pass the Post Exam to complete the course.',
    description: 'Exam Banner Heading - Ready/Started state',
    id: 'ExamBanner.headingReady',
  },
  headingReattempt: {
    defaultMessage: 'Retake and pass the Post Exam to complete the course.',
    description: 'Exam Banner Heading - Reattempt state',
    id: 'ExamBanner.headingReattempt',
  },
  headingPassed: {
    defaultMessage:
      'Congratulations, you completed the course and you passed your Post Exam!',
    description: 'Exam Banner Heading - Passed state',
    id: 'ExamBanner.headingPassed',
  },
  labelCompleted: {
    defaultMessage: 'Completed!',
    description: 'Exam Banner Label 1',
    id: 'ExamBanner.labelCompleted',
  },
  labelCourse: {
    defaultMessage: 'Course: {courseTitle}!',
    description: 'Exam Banner Label 2',
    id: 'ExamBanner.labelCourse',
  },
})

function __bodyPassed(examSurveyUrl, examCertificate) {
  // NOTE: uncomment if there is specific copy required when the Exam
  // has no certificate or survey
  // if (!examSurveyUrl && !examCertificate) {
  //   return ExamBannerMessages.bodyPassedNoCertificateAndSurvey
  // }
  if (!examSurveyUrl) {
    return ExamBannerMessages.bodyPassedNoSurvey
  }
  if (!examCertificate) {
    return ExamBannerMessages.bodyPassedNoCertificate
  }
  return ExamBannerMessages.bodyPassed
}

function __bodyPassedCpe(examSurveyUrl, examCertificate) {
  if (!examSurveyUrl && !examCertificate) {
    return ExamBannerMessages.bodyPassedCPENoCertificateAndSurvey
  }
  if (!examSurveyUrl) {
    return ExamBannerMessages.bodyPassedCPENoSurvey
  }
  if (!examCertificate) {
    return ExamBannerMessages.bodyPassedCPENoCertificate
  }
  return ExamBannerMessages.bodyPassedCPE
}

function __bodyReady(examSurveyUrl) {
  if (!examSurveyUrl) {
    return ExamBannerMessages.bodyReadyNoSurvey
  }
  return ExamBannerMessages.bodyReady
}

function __bodyReadyCpe(examSurveyUrl) {
  if (!examSurveyUrl) {
    return ExamBannerMessages.bodyReadyCPENoSurvey
  }
  return ExamBannerMessages.bodyReadyCPE
}

function __bodyReattempt(examSurveyUrl) {
  if (!examSurveyUrl) {
    return ExamBannerMessages.bodyReattemptNoSurvey
  }
  return ExamBannerMessages.bodyReattempt
}

function __bodyReattemptCpe(examSurveyUrl) {
  if (!examSurveyUrl) {
    return ExamBannerMessages.bodyReattemptCPENoSurvey
  }
  return ExamBannerMessages.bodyReattemptCPE
}

@injectIntl
@withRouter
@inject('contentStore')
@observer
class ExamBanner extends Component {
  componentDidMount() {
    const { contentStore } = this.props
    contentStore.loadExamStatus()
  }
  onDownloadCertificate = () => {
    const { contentStore } = this.props
    const { exam } = contentStore
    exam.downloadCertificate()
  }
  onOpenExam = () => {
    const { history } = this.props
    history.push('/exam')
  }
  render() {
    const { courseTitle, contentStore, intl } = this.props
    const { formatMessage } = intl
    const { exam, isLoadingExamStatus, settings } = contentStore
    const {
      isDone: isDoneExam,
      isDownloadingCertificate,
      isPassed: isPassedExam,
      isStarted: isStartedExam,
      nasbaCpeCredits,
      scoreRequired,
      surveyUrl: examSurveyUrl,
    } = exam
    const { examCertificate } = settings || {}
    let bodyText
    let buttonText
    let headingText
    if (isStartedExam) {
      // started
      bodyText = formatMessage(
        nasbaCpeCredits
          ? __bodyReadyCpe(examSurveyUrl)
          : __bodyReady(examSurveyUrl),
        {
          scoreRequired,
        },
      )
      buttonText = formatMessage(ExamBannerMessages.buttonContinueExam)
      headingText = formatMessage(ExamBannerMessages.headingReady)
    } else if (isDoneExam && isPassedExam) {
      // isPassedExam
      bodyText = nasbaCpeCredits
        ? formatMessage(__bodyPassedCpe(examSurveyUrl, examCertificate), {
            nasbaCpeCredits,
          })
        : examSurveyUrl || examCertificate
        ? formatMessage(__bodyPassed(examSurveyUrl, examCertificate))
        : null
      // NOTE: replace above secondary ternary with below if there is specific copy required
      // when the Exam has no certificate or survey
      // formatMessage(__bodyPassed(examSurveyUrl, examCertificate))
      buttonText = formatMessage(ExamBannerMessages.buttonDownloadCertificate)
      headingText = formatMessage(ExamBannerMessages.headingPassed)
    } else if (isDoneExam && !isPassedExam) {
      // failed
      bodyText = formatMessage(
        nasbaCpeCredits
          ? __bodyReattemptCpe(examSurveyUrl)
          : __bodyReattempt(examSurveyUrl),
        {
          scoreRequired,
        },
      )
      buttonText = formatMessage(ExamBannerMessages.buttonReattemptExam)
      headingText = formatMessage(ExamBannerMessages.headingReattempt)
    } else {
      // ready
      bodyText = formatMessage(
        nasbaCpeCredits
          ? __bodyReadyCpe(examSurveyUrl)
          : __bodyReady(examSurveyUrl),
        {
          scoreRequired,
        },
      )
      buttonText = formatMessage(ExamBannerMessages.buttonBeginExam)
      headingText = formatMessage(ExamBannerMessages.headingReady)
    }
    return (
      <div className={cx('ExamBanner', { 'is-loading': isLoadingExamStatus })}>
        {isLoadingExamStatus ? (
          <Loader />
        ) : (
          <Fragment>
            {isPassedExam ? <BookAndTrophyIcon /> : <CertificateIcon />}
            <div className="ExamBanner__content">
              <span className="ExamBanner__label ExamBanner__label-check">
                <i className="ExamBanner__label-check-icon fa fa-check" />
                {formatMessage(ExamBannerMessages.labelCompleted)}
              </span>
              <span className="ExamBanner__label">
                <FormattedMessage
                  {...ExamBannerMessages.labelCourse}
                  values={{
                    courseTitle: courseTitle,
                  }}
                />
              </span>
              <Heading is2>{headingText}</Heading>
              <p>{bodyText}</p>
              <div className="ExamBanner__buttons">
                {!isPassedExam ? (
                  <Button
                    isSuccess
                    onClick={this.onOpenExam}
                    text={buttonText}
                  />
                ) : null}
                {examSurveyUrl ? (
                  <Link href={examSurveyUrl} isButton isInfo target="_blank">
                    {formatMessage(ExamBannerMessages.buttonSurvey)}
                  </Link>
                ) : null}
                {examCertificate && isPassedExam ? (
                  <Button
                    className="ExamBanner__certificate-button"
                    isLoading={isDownloadingCertificate}
                    isSuccess
                    onClick={this.onDownloadCertificate}
                    text={buttonText}
                  />
                ) : null}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    )
  }
}

export default ExamBanner
