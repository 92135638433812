import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'

const CorrectIncorrectIconMessages = defineMessages({
  correctAriaLabel: {
    defaultMessage: 'Correct answer',
    description: 'ARIA label for Icon for correct answers.',
    id: 'CorrectIncorrectIconMessages.correctAriaLabel',
  },
  incorrectAriaLabel: {
    defaultMessage: 'Incorrect answer',
    description: 'ARIA label for Icon for incorrect answers.',
    id: 'CorrectIncorrectIconMessages.incorrectAriaLabel',
  },
})

@injectIntl
class CorrectIncorrectIcon extends Component {
  render() {
    const { intl, isCorrect } = this.props
    const { formatMessage } = intl
    const ariaLabel = isCorrect
      ? formatMessage(CorrectIncorrectIconMessages.correctAriaLabel)
      : formatMessage(CorrectIncorrectIconMessages.incorrectAriaLabel)
    return (
      <i
        aria-label={ariaLabel}
        className={`fa fa-${isCorrect ? 'check' : 'close'}`}
      />
    )
  }
}

export default CorrectIncorrectIcon
