import React, { Component } from 'react'
// import cx from 'classnames'

import './ActivityMastered.scss'

export default class ActivityMastered extends Component {
  render() {
    return (
      <div className="ActivityMastered">
        <div className="title is-spaced">Section Complete!</div>
        <div className="subtitle">You have unlocked unit 5: Fruits</div>
        <div className="ActivityIcon">
          <i className="fa fa-check" />
        </div>
        <ul className="ActivityMastered-units">
          <li>Function and variables</li>
          <li>Classes and structure</li>
          <li>Logic statements</li>
        </ul>
      </div>
    )
  }
}
