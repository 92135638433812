import React, { Component, Fragment } from 'react'
import { UpdatesBanner } from '@/components'
import './FirstLoginMessageBanner.scss'
class FirstLoginMessageBanner extends Component {
  renderHeader() {
    const { courseTitle } = this.props
    return (
      <Fragment>
        <h1 className="FirstLoginMessageBanner-headline">
          Welcome to your Fulcrum Labs adaptive learning experience!
        </h1>
        <h2 className="FirstLoginMessageBanner-subheadline">
          <span>Looks like you’ll be covering&nbsp;</span>
          <span className="is-bold">{courseTitle}</span>
          <span>&nbsp;today.</span>
        </h2>
      </Fragment>
    )
  }
  render() {
    const { isOpen, onClick } = this.props
    return (
      <UpdatesBanner
        className="FirstLoginMessageBanner"
        isOpen={isOpen}
        onClick={onClick}
        renderHeader={() => this.renderHeader()}
      >
        <ul className="FirstLoginMessageBanner-list">
          <li className="FirstLoginMessageBanner-listItem">
            <div className="FirstLoginMessageBanner-listItemIcon">
              <i className="fa fa-book" />
            </div>
            <div className="FirstLoginMessageBanner-listItemContent">
              <h3>Choose How You Learn</h3>
              <p>
                How do you learn best? Read the material, Watch a video or run
                through Practice questions. You&apos;re in control.
              </p>
            </div>
          </li>
          <li className="FirstLoginMessageBanner-listItem">
            <div className="FirstLoginMessageBanner-listItemIcon">
              <i className="fa fa-trophy" />
            </div>
            <div className="FirstLoginMessageBanner-listItemContent">
              <h3>Your Pace is Personalized</h3>
              <p>
                The more you show <span className="is-bold">mastery</span> and{' '}
                <span className="is-bold">confidence</span>, the faster you’ll
                finish this course.
              </p>
            </div>
          </li>
          <li className="FirstLoginMessageBanner-listItem">
            <div className="FirstLoginMessageBanner-listItemIcon">
              <i className="fa fa-map-o" />
            </div>
            <div className="FirstLoginMessageBanner-listItemContent">
              <h3>Own your Learning Journey</h3>
              <p>
                You can come back to your dashboard by clicking the home icon
                above. Your dashboard will keep you in the loop about your
                progress and performance.
              </p>
            </div>
          </li>
        </ul>
      </UpdatesBanner>
    )
  }
}
export default FirstLoginMessageBanner
