import React, { Component } from 'react'
import cx from 'classnames'
import { TransitionHeight } from '@/components/animations'
import './Toggler.scss'

export default class Toggler extends Component {
  onChange = (e) => {
    e.preventDefault()
    const { isActive } = this.props
    this.props.onChange(!isActive)
  }

  renderText() {
    const { text, altText, isActive } = this.props
    if (!altText) return text
    return isActive ? text : altText
  }

  render() {
    const { altText, text } = this.props
    const className = cx(
      'Toggler',
      {
        'is-active': this.props.isActive,
      },
      this.props.className,
    )
    const iconClassName = this.props.icon ? `fa fa-${this.props.icon}` : ''
    const headClassName = cx(
      'Toggler-head button is-white',
      this.props.headClassName,
    )
    const contentClassName = cx('Toggler-content', this.props.bodyClassName)
    const ariaLabel = this.props.isActive ? text : altText
    return (
      <div className={className}>
        <button
          aria-label={ariaLabel}
          className={headClassName}
          onClick={this.onChange}
        >
          <div className="level is-mobile">
            <div className="level-left">
              {this.props.icon && (
                <span className="icon">
                  <i className={iconClassName} aria-hidden="true" />
                </span>
              )}
              <span className="Toggler-text">{this.renderText()}</span>
            </div>
            <div className="level-right">
              <span className="icon">
                <i
                  aria-hidden="true"
                  className="fa fa-angle-down"
                  title={ariaLabel}
                />
              </span>
            </div>
          </div>
        </button>
        <TransitionHeight className="Toggler-body" in={this.props.isActive}>
          <div className={contentClassName}>{this.props.children}</div>
        </TransitionHeight>
      </div>
    )
  }
}
