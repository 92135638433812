import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Button, Heading, Modal } from '@/components'
import { CheckboxCustom, Input } from '@/components/form'
import './NewInstanceModal.scss'

const NewInstanceModalDefaultValues = function (values) {
  if (!values || typeof values !== 'object') {
    values = {}
  }
  return {
    courseId: values.courseId || null,
    courseName: values.courseName || '',
    integrationType: values.integrationType || 'none',
    originalCourseName: values.originalCourseName || '',
    ssoEndpoint: values.ssoEndpoint || '',
  }
}

@inject('uiStore')
@observer
export default class NewInstanceModal extends Component {
  static defaultProps = {
    values: new NewInstanceModalDefaultValues(),
  }
  onChange = (field, value) => {
    if (this.props.onChange) {
      this.props.onChange(field, value)
    }
  }
  onClose = () => {
    const { onClose } = this.props
    if (onClose) {
      onClose()
    }
  }
  onSubmit = (event) => {
    const { onConfirm } = this.props
    if (onConfirm) {
      event.preventDefault()
      onConfirm()
    }
  }
  renderBody = () => {
    const { error, uiStore, values } = this.props
    const { currentDomain } = uiStore
    const { courseName, integrationType, originalCourseName, ssoEndpoint } =
      values || {}
    const isErrorCourseName =
      error && error['courseName'] ? error['courseName'][0] : null
    const isErrorIntegrationType =
      error && error['integrationType'] ? error['integrationType'][0] : null
    const isErrorSSO =
      error && error['ssoEndpoint'] ? error['ssoEndpoint'][0] : null
    const classNameCourseName = cx('field', {
      'is-error': isErrorCourseName,
    })
    const classNameIntegrationType = cx('field', {
      'is-error': isErrorIntegrationType,
    })
    const classNameSSO = cx('field', {
      'is-error': isErrorSSO,
    })
    return (
      <form className="NewInstanceModal-body" onSubmit={this.onSubmit}>
        <Heading is3 className="NewInstanceModal-title">
          <span>You are creating a copy of:</span>
          <span className="NewInstanceModal-courseName">
            {originalCourseName}
          </span>
        </Heading>
        <div className={classNameCourseName}>
          <Heading is5>New course name:</Heading>
          <Input
            placeholder="Enter course name..."
            onChange={(event) =>
              this.onChange('courseName', event.target.value || '')
            }
            value={courseName || ''}
          />
          {isErrorCourseName ? <span>{`* ${isErrorCourseName}`}</span> : null}
        </div>
        <div className={classNameIntegrationType}>
          <Heading is4>Integration type:</Heading>
          {isErrorIntegrationType ? (
            <span>{`* ${isErrorIntegrationType}`}</span>
          ) : null}
          <CheckboxCustom
            checked={integrationType === 'none'}
            isBlock
            isRadio
            name="NewInstanceModal-integrationType"
            onChange={(event) =>
              this.onChange('integrationType', event.target.value || null)
            }
            text={`No integration. Learners will sign on through <pre>https://learner.${currentDomain}</pre>`}
            value="none"
          />
          <CheckboxCustom
            checked={integrationType === 'ssoEndpoint'}
            isBlock
            isRadio
            name="NewInstanceModal-integrationType"
            onChange={(event) =>
              this.onChange('integrationType', event.target.value || null)
            }
            text="Single Sign-On (SSO)"
            value="ssoEndpoint"
          />
        </div>
        {integrationType === 'ssoEndpoint' ? (
          <div className={classNameSSO}>
            <Heading is4>SSO source URL:</Heading>
            <Input
              placeholder="Enter URL..."
              onChange={(event) =>
                this.onChange('ssoEndpoint', event.target.value || '')
              }
              value={ssoEndpoint || ''}
            />
            {isErrorSSO ? <span>{`* ${isErrorSSO}`}</span> : null}
          </div>
        ) : null}
        <Button className="NewInstanceModal-submit" type="submit" />
      </form>
    )
  }
  render() {
    const { error, isLoading, isOpen, onConfirm } = this.props
    const errorMessageConfirm =
      error && error['confirm'] ? error['confirm'][0] : null
    return (
      <Modal
        className="NewInstanceModal"
        contentLabel="New Instance Modal"
        isOpen={isOpen}
        onClose={this.onClose}
        setScrollTop
      >
        <Modal.Header>
          <Heading>Copy Course</Heading>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Modal.Button isSecondary onClick={this.onClose}>
            Cancel
          </Modal.Button>
          <Modal.Button isLoading={isLoading} onClick={onConfirm}>
            Create
          </Modal.Button>
          {errorMessageConfirm ? (
            <span className="NewInstanceModal-confirmError">{`* ${errorMessageConfirm}`}</span>
          ) : null}
        </Modal.Footer>
      </Modal>
    )
  }
}

export { NewInstanceModalDefaultValues }
