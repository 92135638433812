import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import cx from 'classnames'

import './TransitionWidth.scss'

export default class TransitionWidth extends Component {
  render() {
    return (
      <CSSTransition
        {...this.props}
        className={cx('TransitionWidth', this.props.className)}
        classNames="TransitionWidth"
        timeout={400}
        onEnter={(node) => {
          node.style.width = 0
        }}
        onEntering={(node) => {
          node.style.width = this.props.value
        }}
        onEntered={(node) => {
          node.style.width = this.props.value
        }}
        onExit={(node) => {
          node.style.width = this.props.value
        }}
        onExiting={(node) => {
          node.style.width = 0
        }}
        onExited={(node) => {
          node.style.width = 0
        }}
      >
        <div>{this.props.children}</div>
      </CSSTransition>
    )
  }
}
