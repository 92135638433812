import React, { Component } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Loader, Pagination, SearchField } from '@/components'

import './SortablePaginatedSearchTable.scss'

class SortablePaginatedSearchTable extends Component {
  get hasData() {
    const { rows } = this.props
    return rows.length > 0
  }
  onSort(event, sortBy) {
    const { onSort } = this.props
    event.preventDefault()
    if (!onSort) return
    onSort(sortBy)
  }
  renderSearch() {
    const {
      id,
      onChangeSearch,
      onClearSearch,
      searchLabel,
      searchLabelPosition,
      searchPlaceholder,
      searchTerm,
    } = this.props
    return (
      <SearchField
        id={`${id}__search-field`}
        label={searchLabel}
        labelPosition={searchLabelPosition}
        name="q"
        onChange={onChangeSearch}
        onClear={onClearSearch}
        placeholder={searchPlaceholder}
        value={searchTerm}
      />
    )
  }
  renderShowing() {
    const { offset, rows, showingText, total } = this.props
    const hasRows = rows && rows.length
    const nStart = offset + 1
    const nEnd = hasRows ? offset + rows.length : 0
    const showingDescription = hasRows
      ? `${nStart}-${nEnd} of ${total} ${showingText}`
      : `0 ${showingText}`
    return (
      <div className="SortablePaginatedSearchTable-showing">
        <strong className="SortablePaginatedSearchTable-showingLabel">
          Showing:
        </strong>
        <span>{showingDescription}</span>
      </div>
    )
  }
  renderSortIconHeader({ sortBy: sortHeader, headerText }) {
    const { sortBy, sortAscending } = this.props
    const boldSortAsc = sortHeader === sortBy && sortAscending
    const boldSortDesc = sortHeader === sortBy && !sortAscending
    return (
      <div className="SortablePaginatedSearchTable-tableSortWrapper">
        <span
          className={cx({
            'SortablePaginatedSearchTable-boldSort':
              boldSortAsc || boldSortDesc,
          })}
        >
          {headerText}
        </span>
        <div className="SortablePaginatedSearchTable-tableSort">
          <div>
            <i
              aria-hidden="true"
              className={cx('fa fa-sort-up', {
                'SortablePaginatedSearchTable-boldSort': boldSortAsc,
              })}
            />
          </div>
          <div>
            <i
              aria-hidden="true"
              className={cx('fa fa-sort-down', {
                'SortablePaginatedSearchTable-boldSort': boldSortDesc,
              })}
            />
          </div>
        </div>
      </div>
    )
  }
  render() {
    const {
      className,
      headers,
      id,
      isLoading,
      offset,
      onChangeLimit,
      onPageClick,
      onSort,
      limit,
      rows,
      sortAscending,
      sortBy,
      tableId,
      total,
    } = this.props
    const classNames = cx('SortablePaginatedSearchTable', className)
    return (
      <div className={classNames}>
        <div className="SortablePaginatedSearchTable-header">
          {this.renderSearch()}
          {!isLoading ? this.renderShowing() : null}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          this.hasData && (
            <div className="SortablePaginatedSearchTable__content">
              <Pagination
                id={id}
                limit={limit}
                offset={offset}
                onChangeLimit={onChangeLimit}
                onPageClick={onPageClick}
                total={total}
              />
              <div className="SortablePaginatedSearchTable-tableWrapper">
                <table
                  className="SortablePaginatedSearchTable-table"
                  id={tableId}
                >
                  <thead>
                    <tr>
                      {headers.map((header) => (
                        <th
                          key={`SortablePaginatedSearchTable-${header.sortBy}`}
                        >
                          {onSort ? (
                            <div>
                              <a
                                aria-label={`Sort table by ${
                                  header.headerText
                                } in ${
                                  header.sortBy !== sortBy || !sortAscending
                                    ? 'ascending'
                                    : 'descending'
                                } order`}
                                href="#"
                                onClick={(event) =>
                                  this.onSort(event, header.sortBy)
                                }
                                role="button"
                              >
                                {this.renderSortIconHeader(header)}
                              </a>
                            </div>
                          ) : (
                            <div className="SortablePaginatedSearchTable-headerTextOnly">
                              {header.headerText}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, i) => {
                      const { isDisabled, items, onRowClick } = row
                      return (
                        <tr
                          className={cx(
                            'SortablePaginatedSearchTable-tableRow',
                            {
                              'is-clickable': !!onRowClick,
                              'is-disabled': isDisabled,
                            },
                          )}
                          key={`SortablePaginatedSearchTable-row-${i}`}
                          onClick={onRowClick}
                        >
                          {items.map((data, i) => (
                            <td
                              className="SortablePaginatedSearchTable__row-data"
                              key={`SortablePaginatedSearchTable__row-data-${i}`}
                            >
                              {data}
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <Pagination
                limit={limit}
                offset={offset}
                onChangeLimit={onChangeLimit}
                onPageClick={onPageClick}
                total={total}
              />
            </div>
          )
        )}
      </div>
    )
  }
}

SortablePaginatedSearchTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.array.isRequired, // [{ sortBy: 'string', headerText: 'string' }, ...]
  isLoading: PropTypes.bool.isRequired,
  offset: PropTypes.number.isRequired,
  onChangeLimit: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
  onSort: PropTypes.func,
  limit: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired, // [ { items: ['col1 markup or data', 'col2 markup or data', ...], isDisabled: bool, onRowClick: func }, ...]
  searchPlaceholder: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  showingText: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
  sortAscending: PropTypes.bool,
  total: PropTypes.number.isRequired,
}

export default SortablePaginatedSearchTable
