import React, { Component } from 'react'
import { defineMessages, injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'

import { GateCard, Button } from '@/components'
import { Input } from '@/components/form'

import './AccessCodePage.scss'

const AccessCodePageMessages = defineMessages({
  accessCodePlaceholder: {
    defaultMessage: 'Enter your access code',
    description:
      'Placeholder for the input field to enter an access code to unlock a unit on the access code page.',
    id: 'AccessCodePage.accessCodePlaceholder',
  },
  codeRequired: {
    defaultMessage:
      'The access code is required. Please enter an access code and try again.',
    description:
      'Error message displayed when the input field to enter an access code to unlock a unit is left empty upon submission on the access code page.',
    id: 'AccessCodePage.codeRequired',
  },
  continue: {
    defaultMessage: 'Continue',
    description:
      'Button to submit the access code to unlock a unit is left empty upon submission on the access code page.',
    id: 'AccessCodePage.continue',
  },
  title: {
    defaultMessage: 'This content is locked.',
    description:
      'Title displayed on the access code page used to unlock a unit.',
    id: 'AccessCodePage.title',
  },
})

@injectIntl
@inject('contentStore', 'uiStore')
@observer
export default class AccessCodePage extends Component {
  state = { code: '', codeError: null, hasChanged: false }
  componentDidMount() {
    const { uiStore } = this.props
    uiStore.updateTitle('Enter an Access Code')
  }
  onChange = (e) => {
    const { target } = e
    const { value } = target
    this.setState({ code: value, hasChanged: true })
  }
  onSubmit = (e) => {
    const { contentStore, loadCourse, unit } = this.props
    const { code } = this.state
    const { isUnlockingUnit } = contentStore
    const { id: unitId } = unit
    if (isUnlockingUnit) return
    e.preventDefault()
    this.setState({ codeError: null })
    contentStore
      .unlockUnit(unitId, code)
      .then(() => {
        unit.needsAccessCode = false
        loadCourse()
      })
      .catch((error) => {
        this.setState({ codeError: error })
      })
  }
  renderForm = () => {
    const { accessCodeMessage, contentStore, intl } = this.props
    const { code, codeError, hasChanged } = this.state
    const { isUnlockingUnit } = contentStore
    const { formatMessage } = intl
    const isEmpty = code === ''
    const hasError = (isEmpty && hasChanged) || codeError
    const helpClass = cx('help', {
      'is-danger': hasError,
    })
    return (
      <form onSubmit={this.onSubmit}>
        {accessCodeMessage && (
          <div className="AccessCodePage-info">{accessCodeMessage}</div>
        )}
        <div className="field is-grouped">
          <div className="control is-expanded">
            <Input
              isDanger={hasError}
              name="code"
              onChange={this.onChange}
              placeholder={formatMessage(
                AccessCodePageMessages.accessCodePlaceholder,
              )}
              value={code}
            />
            {hasError ? (
              <p className={helpClass}>
                {isEmpty
                  ? formatMessage(AccessCodePageMessages.codeRequired)
                  : codeError}
              </p>
            ) : null}
          </div>
          <div className="control">
            <Button
              disabled={isEmpty || hasError || isUnlockingUnit}
              isSuccess
              isWide
              submit
            >
              {formatMessage(AccessCodePageMessages.continue)}
            </Button>
          </div>
        </div>
      </form>
    )
  }
  render() {
    const { intl } = this.props
    const { formatMessage } = intl
    return (
      <div className="AccessCodePage PageWrapper">
        <GateCard
          className="AccessCodePage-gate"
          title={formatMessage(AccessCodePageMessages.title)}
        >
          {this.renderForm()}
        </GateCard>
      </div>
    )
  }
}
